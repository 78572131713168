import {
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  ModalContent,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { HiX } from 'react-icons/hi';

import { useModal } from '../../../../context/ModalContext';
import WithAnimOnLayout from '../../../../hocs/WithAnimOnLayout';
import useIsMobile from '../../../../hooks/useIsMobile';
import useLocationPermission, {
  LocationPermissionStatus,
} from '../../../../hooks/useLocationPermission';
import { Listing } from '../../../../utils/types';
import getFirstName from '../../../../utils/user';
import BaseModal from '../../../BaseModal';

interface Props {
  isOpen: boolean;
  listing: Pick<Listing, 'id' | 'proName'>;
  onClose: () => void;
  onGoToChooseYourLocation(): void;
}

const CONTENT_WIDTH = 479;

export default function ChooseLessonLocationModal({
  isOpen,
  listing,
  onClose: onCloseModal,
  onGoToChooseYourLocation,
}: Props): JSX.Element {
  const { handleOpen } = useModal();
  const { isMobile } = useIsMobile();
  const {
    locationPermissionStatus,
    isGettingGeolocation,
    tryGetUserGeolocation,
  } = useLocationPermission();

  const onClose = () => {
    if (isGettingGeolocation) return;
    onCloseModal();
  };

  useEffect(() => {
    // Close modal when user accepts/declines
    if (locationPermissionStatus !== LocationPermissionStatus.UNDETERMINED) {
      onCloseModal();
    }
  }, [locationPermissionStatus, onCloseModal]);

  const onClickLessonAtHome = () => {
    if (locationPermissionStatus === LocationPermissionStatus.UNDETERMINED) {
      handleOpen(
        'locationPermission',
        undefined,
        'has-requested-at-home',
        true,
      );
    } else {
      // eslint-disable-next-line no-console
      tryGetUserGeolocation(true).then(onCloseModal).catch(console.error);
    }
  };

  const Content = (
    <Flex alignItems="center" flexDir="column" justifyContent="center">
      <WithAnimOnLayout style={{ alignSelf: 'flex-end' }}>
        <IconButton
          alignSelf="flex-end"
          aria-label="Go back"
          icon={<HiX size={24} />}
          onClick={onClose}
          size="sm"
          variant="ghost"
        />
      </WithAnimOnLayout>

      <WithAnimOnLayout>
        <Heading mt="2" px="8" textAlign="center" variant="h3">
          Where would you like your lesson?
        </Heading>
      </WithAnimOnLayout>

      <WithAnimOnLayout>
        <Spinner
          h={isGettingGeolocation ? undefined : 0}
          w={isGettingGeolocation ? undefined : 0}
          mt="8"
          overflow="hidden"
          opacity={isGettingGeolocation ? 1 : 0}
          position={isGettingGeolocation ? undefined : 'absolute'}
        />
      </WithAnimOnLayout>

      <WithAnimOnLayout
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Button
          fontWeight="800"
          isDisabled={isGettingGeolocation}
          mt="8"
          onClick={onGoToChooseYourLocation}
          size="lg"
          w="full"
          maxW={CONTENT_WIDTH - (isMobile ? 24 : 40)}
        >
          <Text color="white" noOfLines={1}>
            {listing?.proName
              ? `See all of ${getFirstName(listing?.proName)}'s locations`
              : 'See all locations'}
          </Text>
        </Button>
      </WithAnimOnLayout>

      <WithAnimOnLayout
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Button
          fontWeight="800"
          isDisabled={isGettingGeolocation}
          mt="2"
          onClick={onClickLessonAtHome}
          size="lg"
          w="full"
          maxW={CONTENT_WIDTH - (isMobile ? 24 : 40)}
        >
          <Text color="white" noOfLines={1}>
            Get a lesson at your home
          </Text>
        </Button>
      </WithAnimOnLayout>

      <WithAnimOnLayout>
        <Button
          color="muted"
          fontWeight="800"
          fontSize="1rem"
          isDisabled={isGettingGeolocation}
          mt={isMobile ? '6' : '8'}
          onClick={onClose}
          variant="link"
        >
          Nevermind
        </Button>
      </WithAnimOnLayout>
    </Flex>
  );

  return isMobile ? (
    <Drawer isOpen={isOpen} placement="bottom" size="md" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent borderTopRadius="3xl" p="6">
        {Content}
      </DrawerContent>
    </Drawer>
  ) : (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <ModalContent
        alignItems="center"
        borderRadius="3xl"
        justifyContent="center"
        p="8"
        w={CONTENT_WIDTH}
      >
        {Content}
      </ModalContent>
    </BaseModal>
  );
}
