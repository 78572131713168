import { useLocalStorageValue } from '@react-hookz/web';

import { CurrentLocation } from '../utils/types';

type UseCurrentLocation = {
  /**
   * Always return coordinates(lat, long). If we have location permission ON it returns based on the user's current location.
   * If we don't have location permissions is going to get coordinates based on the user's IP address.
  */
  currentLocation: CurrentLocation;
  setCurrentLocation: (location: CurrentLocation) => void;
}

export default function useCurrentLocation(): UseCurrentLocation {
  const [currentLocation, setCurrentLocation] =
    useLocalStorageValue<CurrentLocation>('currentLocation', {
      address: '',
      city: '',
      country: '',
      latitude: 0,
      longitude: 0,
      postalCode: '',
      state: '',
      stateShortCode: '',
    });

  return { currentLocation, setCurrentLocation };
}
