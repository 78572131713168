import { ReactNode } from 'react';

interface Props {
  condition: boolean;
  children: ReactNode;
}

export function Show({ condition, children }: Props) {
  if (condition) {
    return <>{children}</>;
  }

  return null;
}
