import { Box, Flex, Spinner, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import useIsMobile from '../../../hooks/useIsMobile';
import useProTitle from '../../../hooks/useProTitle';
import EmojiLoading from '../../EmojiLoading/EmojiLoading';

type Props = {
  onClose: () => void;
};

function Finding() {
  const proTitle = useProTitle({ isPlural: false });
  return (
    <EmojiLoading emoji="🔍" title={`Finding the perfect ${proTitle}...`} />
  );
}

function Coffee() {
  return <EmojiLoading emoji="☕️" title="Taking a sip of coffee..." />;
}

export default function QuizFinish({ onClose }: Props) {
  const [showCoffee, setShowCoffee] = useState(false);
  const { isMobile } = useIsMobile();
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCoffee(true);
    }, 1800);

    const closeTimer = setTimeout(() => {
      onClose();
    }, 3600);

    return () => {
      clearTimeout(timer);
      clearTimeout(closeTimer);
    };
  }, [onClose]);

  if (isMobile) {
    return (
      <Flex
        direction="column"
        align="center"
        justifyContent="center"
        position="absolute"
        top="0"
        left="0"
        right="0"
        h="90%"
      >
        {showCoffee ? <Coffee /> : <Finding />}
        <Box pt="4">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="defaultText"
            size="lg"
          />
        </Box>
      </Flex>
    );
  }

  return (
    <VStack w="full">
      {showCoffee ? <Coffee /> : <Finding />}
      <Box pt="4">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="defaultText"
          size="lg"
        />
      </Box>
    </VStack>
  );
}
