import {
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  ModalContent,
  ScaleFade,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { HiX } from 'react-icons/hi';
import { useNavigate, useSearchParams } from 'react-router-dom';

import BaseModal from '../../../components/BaseModal';
import LocationAutocomplete from '../../../components/LocationAutocomplete';
import WithAnimOnLayout from '../../../hocs/WithAnimOnLayout';
import useCurrentLocation from '../../../hooks/useCurrentLocation';
import useIsMobile from '../../../hooks/useIsMobile';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import useLocationPermission, {
  LocationPermissionStatus,
} from '../../../hooks/useLocationPermission';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
  selectIsAtHome,
  selectSelectedSkill,
  setIsAtHome,
} from '../../../state/slices/filtersSlice';
import { Listing } from '../../../utils/types';
import getFirstName from '../../../utils/user';

interface Props {
  isInServiceArea: boolean;
  isForcedOpen?: boolean;
  listing: Pick<Listing, 'locations' | 'proName' | 'slug'>;
  onCloseModal?(): void;
}

const CONTENT_WIDTH = 479;

export default function NotInServiceAreaModal({
  isInServiceArea,
  isForcedOpen = false,
  listing,
  onCloseModal,
}: Props): JSX.Element {
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();
  const [, setSearchParams] = useSearchParams();
  const { isOpen, onClose: onCloseDisclosure, onOpen } = useDisclosure();
  const selectedSkill = useAppSelector(selectSelectedSkill);
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const { isGettingGeolocation, setLocationPermissionStatus } =
    useLocationPermission();
  const dispatch = useAppDispatch();
  const { currentLocation } = useCurrentLocation();
  const isAtHome = useAppSelector(selectIsAtHome);

  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (isForcedOpen) {
      onOpen();
    }
  }, [isForcedOpen, onOpen]);

  useEffect(() => {
    if (isAtHome && !isInServiceArea) {
      onOpen();
    }
  }, [isAtHome, isInServiceArea, onOpen]);

  const onClose = () => {
    onCloseDisclosure();
    dispatch(setIsAtHome(false));
    const listingSearchParams = getListingSearchParamsWithPartialInput({
      isAtHome: false,
    });
    setSearchParams(new URLSearchParams(listingSearchParams));
  };

  function getProType(isPlural = false): string {
    if (selectedSkill?.category === 'music') {
      return isPlural ? 'teachers' : 'teacher';
    }
    return isPlural ? 'coaches' : 'coach';
  }

  const [hasUserChosenALocation, setHasUserChosenALocation] =
    useState<boolean>(false);
  const onLocationChange = () => {
    setLocationPermissionStatus(LocationPermissionStatus.ACCEPTED);
    dispatch(setIsAtHome(true));
    setHasUserChosenALocation(true);
  };

  useEffect(() => {
    if (
      isOpen &&
      (currentLocation?.latitude || currentLocation?.longitude) &&
      !isInServiceArea &&
      hasUserChosenALocation
    ) {
      setHasUserChosenALocation(false);
      setErrorMessage("This location is not within the coach's travel radius");
    }
  }, [
    hasUserChosenALocation,
    isOpen,
    isInServiceArea,
    currentLocation?.latitude,
    currentLocation?.longitude,
  ]);

  // Close once in service area
  useEffect(() => {
    if (isOpen && isInServiceArea) {
      onCloseDisclosure();
    }
  }, [isInServiceArea, isOpen, onCloseDisclosure]);

  useEffect(() => {
    if (!isOpen) {
      setErrorMessage('');
    }
  }, [isOpen]);

  const proFirstName =
    getFirstName(listing?.proName) || `your ${getProType(false)}`;

  const Content = (
    <Flex
      alignItems="center"
      flexDir="column"
      justifyContent="center"
      p={{ base: '4', md: '8' }}
    >
      <WithAnimOnLayout
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: 0,
          width: '100%',
        }}
      >
        <IconButton
          aria-label="Close modal"
          icon={<HiX size={24} />}
          onClick={onClose}
          pos="absolute"
          right={{ base: '2', md: '4' }}
          top={{ base: '2', md: '4' }}
          variant="ghost"
        />
      </WithAnimOnLayout>

      <WithAnimOnLayout>
        <Heading mt="2" px="8" textAlign="center" variant="h3">
          You are outside of {proFirstName}
          &apos;s travel radius.
        </Heading>
      </WithAnimOnLayout>

      <Text color="muted" mt="6" textStyle="body" textAlign="center">
        If you want to check if another location is inside of their travel
        radius, you can change the location address below.
      </Text>

      <WithAnimOnLayout
        style={{
          marginTop: 32,
          pointerEvents: isGettingGeolocation ? 'none' : undefined,
          width: '100%',
        }}
      >
        <LocationAutocomplete onLocationChange={onLocationChange} />
      </WithAnimOnLayout>

      <WithAnimOnLayout>
        <Button
          fontSize="14px"
          fontWeight="800"
          isDisabled={isGettingGeolocation}
          mt="9"
          onClick={() => navigate('/choose-a-pro')}
          textStyle="smallLink"
          variant="link"
        >
          View other {getProType(true)} who can travel to me
        </Button>
      </WithAnimOnLayout>

      <WithAnimOnLayout
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Button
          fontSize="14px"
          fontWeight="800"
          isDisabled={isGettingGeolocation}
          mt="9"
          onClick={() => {
            if (isForcedOpen) {
              onClose();
            } else if (
              window.location.pathname.startsWith('/choose-your-location')
            ) {
              onClose();
            } else {
              dispatch(setIsAtHome(false));
              navigate(
                `/choose-your-location/${listing?.slug}?pathname=${window.location.pathname}`,
              );
            }
          }}
          textStyle="smallLink"
          variant="link"
        >
          View {proFirstName}&apos;s other lesson locations
        </Button>
      </WithAnimOnLayout>

      <ScaleFade
        in={!!errorMessage}
        transition={{ enter: { duration: 0.5, delay: 0.3 } }}
      >
        <Text
          color="error"
          mt={errorMessage ? '8' : '0'}
          textAlign="center"
          textStyle="smallBold"
        >
          {errorMessage}
        </Text>
      </ScaleFade>
    </Flex>
  );

  return isMobile ? (
    <Drawer isOpen={isOpen} placement="bottom" size="md" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent borderTopRadius="2xl" p="4">
        {Content}
      </DrawerContent>
    </Drawer>
  ) : (
    <BaseModal isOpen={isOpen} onClose={onClose} onCloseComplete={onCloseModal}>
      <ModalContent
        alignItems="center"
        borderRadius="2xl"
        justifyContent="center"
        p={{ base: '2', md: '4' }}
        w={CONTENT_WIDTH}
      >
        {Content}
      </ModalContent>
    </BaseModal>
  );
}
