/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../interfaces';

export interface AppState {
  currentlyPlayingVideoSrc: string;
  mainScrollPercent: 0 | 35;
}

const initialState: AppState = {
  currentlyPlayingVideoSrc: undefined,
  mainScrollPercent: 0,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCurrentlyPlayingVideoSrc: (
      state,
      action: PayloadAction<AppState['currentlyPlayingVideoSrc']>,
    ) => {
      state.currentlyPlayingVideoSrc =
        action.payload || initialState.currentlyPlayingVideoSrc;
    },
    setMainScrollPercent: (
      state,
      action: PayloadAction<AppState['mainScrollPercent']>,
    ) => {
      state.mainScrollPercent =
        action.payload || initialState.mainScrollPercent;
    },
  },
});

// export actions
export const { setCurrentlyPlayingVideoSrc, setMainScrollPercent } =
  slice.actions;

// export selectors
export const selectCurrentlyPlayingVideoSrc = (state: RootState) =>
  state.app.currentlyPlayingVideoSrc;
export const selectMainScrollPercent = (state: RootState) =>
  state.app.mainScrollPercent;

// export reducer
export default slice.reducer;
