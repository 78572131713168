import { Box, Flex, Portal, SlideFade, Stack, Text } from '@chakra-ui/react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { HiX } from 'react-icons/hi';
import Map, { MapRef, NavigationControl } from 'react-map-gl';

import ProLocationsPins from './components/ProLocationsPins';

import useIsAtHomeOrListingExistsWithNoLocations from '../../hooks/useIsAtHomeOrListingExistsWithNoLocations';
import useIsMobile from '../../hooks/useIsMobile';
import useMapInitialViewState from '../../hooks/useMapInitialViewState';
import { MAPBOX_ACCESS_TOKEN } from '../../utils/constants';
import { SelectedListing, SelectedLocation } from '../../utils/types';
import { Show } from '../Show';

type Props = {
  listing: SelectedListing;
  mapHeight?: string;
};

export default function ProLocationsMap({ listing, mapHeight }: Props) {
  const mapRef = useRef<MapRef>();
  const { isMobile } = useIsMobile();
  const isAtHomeOrListingExistsWithNoLocations =
    useIsAtHomeOrListingExistsWithNoLocations();
  const portalRef = useRef();
  const { center, initialViewState } = useMapInitialViewState({ listing });

  const [viewState, setViewState] = useState(initialViewState);
  const [selectedHit, setSelectedHit] = useState<SelectedLocation>();

  const onDeselectHit = useCallback(() => {
    setSelectedHit(undefined);
  }, [setSelectedHit]);

  useEffect(() => {
    if (!center) return;
    setViewState((prevViewState) => ({
      ...prevViewState,
      latitude: center.latitude || 0,
      longitude: center.longitude || 0,
    }));
  }, [center]);

  useEffect(() => {
    if (isAtHomeOrListingExistsWithNoLocations) {
      onDeselectHit();
    }
  }, [isAtHomeOrListingExistsWithNoLocations, onDeselectHit]);

  const handleMapMove = (event: any) => {
    event?.originalEvent?.stopPropagation();
    setViewState(event?.viewState);
  };

  useEffect(() => {
    function updateMapSize() {
      if (mapRef.current && mapRef.current.getMap()) {
        mapRef.current.getMap().resize();
      }
    }
    window.addEventListener('resize', updateMapSize);
    return () => {
      window.removeEventListener('resize', updateMapSize);
    };
  }, [mapHeight]);

  return (
    <Box h={mapHeight} width="full" position="relative">
      <Map
        attributionControl={false}
        crossSourceCollisions
        key={+isMobile}
        initialViewState={initialViewState}
        latitude={viewState?.latitude || 0}
        longitude={viewState?.longitude || 0}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        mapStyle="mapbox://styles/tmt-clayton/clbf9voiu000a15qvkk0gzgcq"
        minZoom={5}
        onMove={handleMapMove}
        onClick={onDeselectHit}
        ref={mapRef}
      >
        <Show condition={!isMobile}>
          <NavigationControl style={{ position: 'absolute', right: 24 }} />
        </Show>
        <ProLocationsPins listing={listing} setSelectedHit={setSelectedHit} />

        <Portal containerRef={portalRef}>
          <Stack
            bg="white"
            borderRadius="16px"
            mb={isMobile ? '8' : undefined}
            px="4"
            py="3"
            spacing={0}
          >
            <Flex alignItems="center" justifyContent="space-between" w="full">
              <Text noOfLines={2} textStyle="bold">
                {selectedHit?.placeName}
              </Text>

              <Box>
                <HiX cursor="pointer" onClick={onDeselectHit} size="18px" />
              </Box>
            </Flex>

            <Text
              fontWeight="bold"
              noOfLines={3}
              textStyle="caption"
              fontSize="12px"
            >
              {selectedHit?.address}
            </Text>
          </Stack>
        </Portal>
      </Map>

      <Box
        bottom="0"
        left="0"
        mx="0"
        position="absolute"
        right="24px"
        zIndex="4"
      >
        <SlideFade in={!!selectedHit}>
          <Stack
            pointerEvents={selectedHit ? 'auto' : 'none'}
            bottom="0"
            left="0"
            right="0"
            display="flex"
            m="4"
            position="absolute"
            ref={portalRef}
          />
        </SlideFade>
      </Box>
    </Box>
  );
}
