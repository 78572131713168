import { Box, useDisclosure, VStack } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';

import { QuestionProps } from './interface';

import useLocationPermission, {
  LocationPermissionStatus,
} from '../../../hooks/useLocationPermission';
import useSelectedLocation from '../../../hooks/useSelectedLocation';
import useSelectedSkill from '../../../hooks/useSelectedSkill';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
  FiltersState,
  selectIsAtHome,
  setIsAtHome,
} from '../../../state/slices/filtersSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import {
  DANCE_SKILL_SLUG,
  GUITAR_SKILL_SLUG,
  PIANO_SKILL_SLUG,
  SINGING_SKILL_SLUG,
  VIOLIN_SKILL_SLUG,
  YOGA_SKILL_SLUG,
} from '../../../utils/constants';
import getLocationAnswerFromSkill from '../../../utils/getLocationAnswerFromSkill';
import PosthogEvent from '../../../utils/posthogEvents';
import LocationPermissionModal from '../../LocationPermissionModal/LocationPermissionModal';
import QuizButton from '../QuizButton';

enum LocationOptions {
  AT_HOME = 'atHome',
  AT_STUDIO = 'atStudio',
  AT_LOCATION = 'atLocation',
}

export default function QuizLocation({ onSelectAnswer }: QuestionProps) {
  const {
    isOpen: isLocationPermissionsOpen,
    onOpen: onOpenLocationPermissions,
    onClose: onCloseLocationPermissions,
  } = useDisclosure();

  const [selectedSkill] = useSelectedSkill();
  const { locationPermissionStatus } = useLocationPermission();

  const answers = useMemo(() => {
    const possibleAnswers = [];
    const { atLocationAnswer, atHomeAnswer } = getLocationAnswerFromSkill(
      selectedSkill?.slug,
      true,
    );

    let answerId = LocationOptions.AT_LOCATION;

    switch (selectedSkill?.slug) {
      case PIANO_SKILL_SLUG:
      case GUITAR_SKILL_SLUG:
      case SINGING_SKILL_SLUG:
      case YOGA_SKILL_SLUG:
      case VIOLIN_SKILL_SLUG:
      case DANCE_SKILL_SLUG:
        answerId = LocationOptions.AT_STUDIO;
        break;
      default:
        break;
    }

    if (selectedSkill?.category === 'music') {
      possibleAnswers.push(
        {
          id: LocationOptions.AT_HOME,
          name: atHomeAnswer,
        },
        {
          id: answerId,
          name: atLocationAnswer,
        },
      );
    } else {
      possibleAnswers.push(
        {
          id: answerId,
          name: atLocationAnswer,
        },
        {
          id: LocationOptions.AT_HOME,
          name: atHomeAnswer,
        },
      );
    }

    return possibleAnswers;
  }, [selectedSkill]);

  const [, setSelectedLocation] = useSelectedLocation();

  const isAtHome = useAppSelector(selectIsAtHome);
  const dispatch = useAppDispatch();

  const [localAnswer, setLocalSelectedAnswer] =
    useState<Partial<FiltersState['localSelectedBadges'][0]>>();

  useEffect(() => {
    if (isAtHome) {
      setSelectedLocation(undefined);
    }
  }, [isAtHome, setSelectedLocation]);

  const onClick = (
    badge: Pick<FiltersState['localSelectedBadges'][0], 'id'>,
  ) => {
    setLocalSelectedAnswer(badge);
    const options = answers.map((opt) => opt.name).join(';');
    const answer = answers.find((a) => a.id === badge.id);
    onSelectAnswer(answer.name, options);
    if (badge.id === LocationOptions.AT_HOME) {
      if (locationPermissionStatus !== LocationPermissionStatus.UNDETERMINED) {
        dispatch(setIsAtHome(true));
      } else {
        capturePostHogEvent('locationPermissionModal');
        onOpenLocationPermissions();
      }
    } else {
      dispatch(setIsAtHome(false));
    }
  };

  return (
    <>
      <Box w="full">
        <VStack alignItems="flex-start" spacing="2" w="full">
          {answers.map((badge) => (
            <QuizButton
              badge={badge as FiltersState['localSelectedBadges'][0]}
              key={badge.id}
              isSelected={localAnswer?.id === badge.id}
              onToggleBadge={() => onClick(badge)}
              w="full"
            />
          ))}
        </VStack>
      </Box>
      <LocationPermissionModal
        disableNavigate={false}
        heading="has-requested-at-home"
        isOpen={isLocationPermissionsOpen}
        onClose={onCloseLocationPermissions}
        onOpen={() => capturePostHogEvent(PosthogEvent.RequestLocation)}
      />
    </>
  );
}
