import { Image, VStack } from '@chakra-ui/react';

import TMTLogo from '../../assets/risk-free/tmt-logo-white.svg';

export const MembersPriceBadgeSizes = ['sm', 'r', 'md', 'lg'] as const;

export type MembersPriceBadgeProps = {
  size: (typeof MembersPriceBadgeSizes)[number];
  shadowed?: boolean;
};

const sizes: Record<
  (typeof MembersPriceBadgeSizes)[number],
  {
    container: number;
    logo: number;
    radius: number;
  }
> = {
  sm: {
    container: 18,
    logo: 13,
    radius: 2,
  },
  r: {
    container: 24,
    logo: 17,
    radius: 4,
  },
  md: {
    container: 64,
    logo: 43,
    radius: 7,
  },
  lg: {
    container: 96,
    logo: 63,
    radius: 10,
  },
};

export default function MembersPriceBadge({
  size,
  shadowed,
}: MembersPriceBadgeProps) {
  return (
    <VStack
      flexShrink={0}
      w={`${sizes[size].container}px`}
      h={`${sizes[size].container}px`}
      pos="relative"
      borderRadius={`${sizes[size].radius}px`}
      alignItems="center"
      justifyContent="center"
      bgGradient="linear-gradient(225deg, #D4AF37 0%, #E7CF80 52%, #AA8921 100%)"
      boxShadow={
        shadowed ? '0px 0px 10.667px 0px rgba(212, 149, 55, 0.85)' : undefined
      }
    >
      <Image
        alt="TMTShield"
        h="auto"
        src={TMTLogo}
        width={`${sizes[size].logo}px`}
      />
    </VStack>
  );
}
