import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { IconType } from 'react-icons';
import {
  HiOutlineCurrencyDollar,
  HiOutlineHeart,
  HiOutlineSparkles,
} from 'react-icons/hi';

import useIsMobile from '../../hooks/useIsMobile';
import RiskFreeBadge from '../RiskFreeBadge';

interface Props extends Omit<ModalProps, 'children'> {
  isOpen: boolean;
  onClose: () => void;
  bg?: string;
}

const AnimatedModalContent = motion(ModalContent);

function Benefit({
  Icon,
  title,
  description,
}: {
  Icon: IconType;
  title: string;
  description: string;
}) {
  const { isMobile } = useIsMobile();

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      px="6"
      py="4"
      borderRadius="16"
      bgColor="#EBF8FF"
    >
      <HStack w="100%">
        <Text
          fontSize="20"
          color="#2B6CB0"
          fontWeight="extrabold"
          flexGrow={1}
          flexBasis={0}
        >
          {title}
        </Text>
        <Icon color="#2B6CB0" size="32" />
      </HStack>
      <Text color="#2B6CB0" fontSize={isMobile ? '14' : '16'}>
        {description}
      </Text>
    </VStack>
  );
}

function RiskFreeModalContent({ onClose }: { onClose: () => void }) {
  const { isMobile } = useIsMobile();

  const benefits = [
    {
      Icon: HiOutlineSparkles,
      title: 'Try any coach risk free.',
      description:
        "If you don't love your first lesson, we'll switch you to another coach or give you a full refund. That's just one of the many benefits of working with TeachMe.To.",
    },
    {
      Icon: HiOutlineCurrencyDollar,
      title: "We're here to help.",
      description:
        "If you ever need support, you're not alone. Our customer service team is here to support you (almost) around the clock. And we're real people, not AI.",
    },
    {
      Icon: HiOutlineHeart,
      title: 'Free lesson insurance with every purchase.',
      description:
        "We provide insurance for every lesson scheduled through TeachMe.To so you're protected by our $1,000,000 policy!",
    },
  ];

  return (
    <VStack
      alignItems="flex-start"
      w="100%"
      gap={0}
      overflow="hidden"
      pt={{
        base: '32px',
        md: '40px',
      }}
    >
      <HStack
        alignItems="center"
        w="full"
        px={{
          base: '24px',
          md: '40px',
        }}
      >
        <VStack
          alignItems="flex-start"
          gap={0}
          flexGrow={1}
          flexBasis={0}
          flexShrink={1}
        >
          <Text
            color="#2D3748"
            fontWeight="extrabold"
            fontSize="medium"
            lineHeight="18px"
          >
            TeachMe.To
          </Text>
          <Text color="#2D3748" fontWeight="extrabold" fontSize="x-large">
            {isMobile ? '' : 'Risk free '}Guarantee
          </Text>
          {!isMobile && (
            <Text color="#2D3748" fontSize="16px">
              We’re here for you every step of the way.
            </Text>
          )}
        </VStack>
        <RiskFreeBadge size={isMobile ? 'md' : 'lg'} />
      </HStack>
      {isMobile && (
        <Text
          px={{
            base: '24px',
            md: '40px',
          }}
          color="#2D3748"
          fontSize="16px"
        >
          We’re here for you every step of the way.
        </Text>
      )}
      <VStack
        overflow="auto"
        flexGrow={1}
        px={{
          base: '24px',
          md: '40px',
        }}
      >
        <VStack
          alignItems="flex-start"
          gap={{
            base: '2',
            md: '4',
          }}
          mt={{
            base: '24px',
            md: '32px',
          }}
        >
          {benefits.map((benefit) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Benefit {...benefit} />
          ))}
        </VStack>
        <Flex
          w="full"
          pb={{
            base: '32px',
            md: '40px',
          }}
        >
          <Box
            bgGradient="linear-gradient(225deg, #3182CE 1.86%, #5EA1D2 40.76%, #182C93 81.24%), #3182CE"
            borderRadius="full"
            w="100%"
            mt="6"
          >
            <Button
              w="100%"
              bg="transparent"
              size="xl"
              rounded="full"
              onClick={onClose}
            >
              Got it
            </Button>
          </Box>
        </Flex>
      </VStack>
    </VStack>
  );
}

export default function RiskFreeModal({ isOpen, onClose, bg, ...rest }: Props) {
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: 'full', md: 'auto' }}
      scrollBehavior="inside"
      motionPreset="slideInBottom"
      onOverlayClick={onClose}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <ModalOverlay bg={bg} />
      <AnimatedModalContent
        borderRadius="24px"
        maxH={window.innerHeight}
        maxW={{
          base: '100%',
          md: 615,
        }}
      >
        <RiskFreeModalContent onClose={onClose} />
      </AnimatedModalContent>
    </Modal>
  );
}
