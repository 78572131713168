import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../state/hooks';
import {
  selectSelectedLocation,
  setSelectedLocation,
} from '../state/slices/locationsSlice';
import type { SelectedLocation } from '../utils/types';

export default function useSelectedLocation(): [
  SelectedLocation,
  React.Dispatch<SelectedLocation>,
] {
  const selectedLocation = useAppSelector(selectSelectedLocation);
  const dispatch = useAppDispatch();

  const dispatchSelectedLocation = useCallback(
    (location: SelectedLocation) => {
      // This empty object is needed for when a user clicks on a pin,
      // then clicks elsewhere on the map, then on that same pin again.
      // The appropriate popup will re-render when selectedLocation changes.
      dispatch(setSelectedLocation(location || ({} as SelectedLocation)));
    },
    [dispatch],
  );

  return [selectedLocation, dispatchSelectedLocation];
}
