import { HStack, Skeleton, SkeletonText, Text } from '@chakra-ui/react';

import { type AlgoliaListingPayloadReview } from '../../api';
import calculateRating from '../../utils/calculateRating';
import NoRating from '../NoRating';
import { Show } from '../Show';
import StarRating from '../StarRating';

const getFinalRating = (
  rating: number,
  reviews?: Pick<AlgoliaListingPayloadReview, 'rating'>[],
): string | number => {
  if (reviews?.length) {
    return calculateRating(reviews);
  }

  return rating;
};

interface Props {
  rating?: string | number;
  reviews?: Pick<AlgoliaListingPayloadReview, 'rating'>[];
  numOfStars?: number;
  showReviewCount?: boolean;
  showRatingNum?: boolean;
  isLoading?: boolean;
  size?: 'sm' | 'lg';
}

export default function Rating({
  size = 'sm',
  rating = 0,
  numOfStars = 1,
  reviews,
  showRatingNum = true,
  showReviewCount = false,
  isLoading = false,
}: Props) {
  const fontSize = size === 'lg' ? '24' : '16';

  const numOfReviews = reviews?.length || 0;

  const showRatingOrReview = showRatingNum || showReviewCount;

  const finalRating = getFinalRating(Number(rating), reviews);

  if (finalRating === 0 || finalRating === '0' || !finalRating) {
    return (
      <SkeletonText noOfLines={1} isLoaded={!isLoading}>
        <NoRating />
      </SkeletonText>
    );
  }

  return (
    <Skeleton isLoaded={!isLoading}>
      <HStack spacing="1" flexWrap="wrap" align="center">
        <StarRating
          numOfStars={numOfStars}
          rating={Math.round(Number(finalRating))}
        />
        <Show condition={showRatingOrReview}>
          <Show condition={showRatingNum}>
            <Text
              fontSize={fontSize}
              fontWeight={size === 'lg' ? 'bold' : 'normal'}
            >
              {finalRating}
            </Text>
          </Show>
          <Show condition={showReviewCount}>
            <Text color="muted" fontSize={fontSize}>
              ({numOfReviews})
            </Text>
          </Show>
        </Show>
      </HStack>
    </Skeleton>
  );
}
