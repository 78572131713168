import { SelectedLocation } from './types';

import { Bounds } from '../hooks/useGeoSearch';
import { FiltersState } from '../state/slices/filtersSlice';

/**
 * @note Duplicated on the api
 */
export enum ListingSearchParam {
  SelectedListingLocation = 'selected_location',
  Participants = 'participants',
  LessonLength = 'lesson_length',
  IsAtHome = 'is_at_home',
  IsRecurring = 'is_recurring',
  DateTime = 'date_time',
  Pkg = 'pkg',
  Origin = 'origin',
  AtHomeLocation = 'at_home_location',
  ConfirmName = 'confirm_name',
  ConfirmEmail = 'confirm_email',
  ConfirmPhoneNumber = 'confirm_phone',
  SearchBoundingBox = 'search_bounding_box',
}

export type ListingSearchParamsInput = {
  selectedLocation: Pick<SelectedLocation, 'id'>;
  participants: FiltersState['participants'];
  lessonLength: number;
  isAtHome: boolean;
  isRecurring: boolean;
  dateTime: FiltersState['dateTime'];
  pkg: FiltersState['pkg'];
  origin: string;
  atHomeLocation: FiltersState['atHomeLocation'];
  searchBoundingBox: Bounds;
};

/**
 * @note Duplicated on the api
 */
export default function getListingSearchParams(
  input: ListingSearchParamsInput,
): string {
  const {
    selectedLocation,
    participants,
    lessonLength,
    isAtHome,
    isRecurring,
    dateTime,
    pkg,
    origin,
    atHomeLocation,
    searchBoundingBox,
  } = input;

  return [
    selectedLocation?.id
      ? `${ListingSearchParam.SelectedListingLocation}=${encodeURIComponent(
          JSON.stringify({ locationId: selectedLocation.id }),
        )}`
      : '',
    `${ListingSearchParam.IsRecurring}=${isRecurring}`,
    participants ? `${ListingSearchParam.Participants}=${participants}` : '',
    lessonLength ? `${ListingSearchParam.LessonLength}=${lessonLength}` : '',
    isAtHome ? `${ListingSearchParam.IsAtHome}=${isAtHome}` : '',
    dateTime ? `${ListingSearchParam.DateTime}=${dateTime}` : '',
    pkg ? `${ListingSearchParam.Pkg}=${pkg}` : '',
    origin ? `${ListingSearchParam.Origin}=${encodeURIComponent(origin)}` : '',
    atHomeLocation
      ? `${ListingSearchParam.AtHomeLocation}=${encodeURIComponent(
          JSON.stringify(atHomeLocation),
        )}`
      : '',
    searchBoundingBox
      ? `${ListingSearchParam.SearchBoundingBox}=${encodeURIComponent(
          JSON.stringify(searchBoundingBox),
        )}`
      : '',
  ]
    .filter(Boolean)
    .join('&');
}
