/* eslint-disable import/prefer-default-export */
import { FormControl, FormLabel, Select, SelectProps } from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';

interface Props extends SelectProps {
  label?: string;
  info?: string;
  error?: string;
  labelFontSize?: string;
  labelFontWeight?: string;
  selectProps: SelectProps;
  values: string[];
}

export const SelectField = forwardRef(
  (
    {
      label,
      error,
      name,
      info,
      labelFontSize,
      labelFontWeight,
      isRequired,
      selectProps,
      values,
      // eslint-disable-next-line react/jsx-props-no-spreading
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLSelectElement>,
  ) => (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      {label ? (
        <FormLabel
          fontSize={labelFontSize}
          htmlFor={name}
          fontWeight={labelFontWeight}
          mb="0"
          textStyle="caption"
          color={error ? 'error' : 'defaultText'}
        >
          {label}
        </FormLabel>
      ) : null}

      <Select
        size="lg"
        borderRadius="lg"
        borderColor="gray.300"
        isRequired
        icon={<IoMdArrowDropdown />}
        placeholder="Select"
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...selectProps}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {values.map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </Select>
    </FormControl>
  ),
);
