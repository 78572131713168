import { Flex, Icon, Image } from '@chakra-ui/react';
import { memo } from 'react';
import { BsRecordCircle } from 'react-icons/bs';
import { HiOutlineLocationMarker } from 'react-icons/hi';

function getSize(
  isCurrentLocation: boolean,
  isActive: boolean,
  src: any,
): string {
  if (isCurrentLocation) return src ? '24px' : '16px';
  if (isActive) return src ? '60px' : '48px';
  return src ? '48px' : '32px';
}

type Props = {
  isActive: boolean;
  activeColor?: string;
  inactiveColor?: string;
  isCurrentLocation?: boolean;
  src?: any;
};

const Pin = memo(
  ({
    isActive = false,
    activeColor = 'accent',
    inactiveColor = 'white',
    isCurrentLocation = false,
    src,
  }: Props) => {
    const size = getSize(isCurrentLocation, isActive, src);
    return (
      <Flex
        borderRadius="full"
        h={size}
        w={size}
        p="3"
        align="center"
        justify="center"
        mx="auto"
        bg={isActive || isCurrentLocation ? activeColor : inactiveColor}
        overflow="hidden"
        boxShadow="0px 5px 10px rgba(0, 0, 0, 0.2)"
        _hover={{
          bg: src ? '#5B636C' : 'accent',
          svg: {
            color: inactiveColor,
          },
        }}
      >
        {src ? (
          <Image alt="Affiliate Logo" h="auto" src={src} w={size} />
        ) : (
          <Icon
            as={isCurrentLocation ? BsRecordCircle : HiOutlineLocationMarker}
            display="block"
            fontSize={isCurrentLocation ? '28px' : '20px'}
            color={isActive || isCurrentLocation ? inactiveColor : activeColor}
          />
        )}
      </Flex>
    );
  },
);

export default Pin;
