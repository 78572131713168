import { Box } from '@chakra-ui/react';

import { SelectedLocation } from '../../utils/types';
import LocationFeeIcon from '../LocationFeeIcon';

type Props = {
  isActive?: boolean;
  location: Pick<Partial<SelectedLocation>, 'fee'>;
};

export default function LocationFeeMarkerIcon({
  isActive = false,
  location,
}: Props): JSX.Element {
  if (!location?.fee) return null;
  return (
    <Box
      pos="absolute"
      right={isActive ? '-4px' : '-8px'}
      top={isActive ? '-4px' : '-8px'}
    >
      <LocationFeeIcon />
    </Box>
  );
}
