import axios from "axios";
import { useCallback } from "react";

import useCurrentLocation from "../../../hooks/useCurrentLocation";
import { VITE_API_URL } from "../../../utils/constants";

export default function useGetAtHomeListingBySlug(slug: string) {
    const { currentLocation } = useCurrentLocation();

    const getAtHomeListingBySkillId = useCallback(
        async (skillId: string) => {
            const baseUrl = VITE_API_URL.replace('/graphql', '');
            const url = `${baseUrl}/listings/at-home`;

            const queryParams = {
                skillId,
                latitude: currentLocation?.latitude || 0,
                longitude: currentLocation?.longitude || 0,
                badgeIds: [],
                environmentSettings: [],
                listingSlugs: [slug].filter(Boolean),
            };

            const response = await axios.post(url, queryParams);
            return response.data?.listings?.[0];
        },
        [currentLocation?.latitude, currentLocation?.longitude, slug],
    );


    return {
        getAtHomeListingBySkillId
    };
}
