import LocationFeeModalDesktop from './components/LocationFeeModalDesktop';
import LocationFeeModalMobile from './components/LocationFeeModalMobile';

import useIsMobile from '../../hooks/useIsMobile';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function LocationFeeModal({ isOpen, onClose }: Props) {
  const { isMobile } = useIsMobile();

  return isMobile ? (
    <LocationFeeModalMobile isOpen={isOpen} onClose={onClose} />
  ) : (
    <LocationFeeModalDesktop isOpen={isOpen} onClose={onClose} />
  );
}
