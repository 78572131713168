import { Box, Checkbox, Heading, Link, Stack, VStack } from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
  selectConfirmEmail,
  selectConfirmName,
  selectConfirmPhoneNumber,
  setConfirmEmail,
  setConfirmName,
  setConfirmPhoneNumber,
} from '../../../state/slices/filtersSlice';
import { Country } from '../../../types';
import countries from '../../../utils/countries.json';
import { InputField } from '../../InputField';
import PhoneNumberInput from '../../PhoneNumberInput';

type Props = {
  country: Country;
  setCountry: (country: Country) => void;
  setFormValidation: (validation: {
    isValid: boolean;
    errors: Record<string, string>;
  }) => void;
};

export default function StudentInfo({
  country,
  setCountry,
  setFormValidation,
}: Props) {
  const dispatch = useAppDispatch();
  const confirmEmail = useAppSelector(selectConfirmEmail);
  const confirmName = useAppSelector(selectConfirmName);
  const confirmPhoneNumber = useAppSelector(selectConfirmPhoneNumber);
  const [isTermsChecked, setIsTermsChecked] = useState(true);

  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string()
          .email('Invalid email')
          .required('Email is required'),
        phoneNumber: Yup.string()
          // @ts-ignore
          .phone(country?.code, true, 'Invalid phone number')
          .required('Phone number is required'),
        isTermsChecked: Yup.bool().oneOf([true], 'You must agree to the terms'),
      }),
    [country?.code],
  );

  const getUserCountry = useCallback(async () => {
    try {
      const res = await axios(
        'https://api.ipregistry.co/?key=0lajp4q8lyh4jzb9',
      );
      const nextCountry = (countries as Country[]).find(
        (node) => node.code === res?.data?.location?.country?.code,
      );
      setCountry(nextCountry);
    } catch (error) {
      console.error('Failed to fetch country:', error);
    }
  }, [setCountry]);

  useEffect(() => {
    getUserCountry();
  }, [getUserCountry]);

  const validateForm = useCallback(
    (user) => {
      schema
        .validate(user, { abortEarly: false })
        .then(() => {
          setFormValidation({ isValid: true, errors: {} });
        })
        .catch((validationErrors) => {
          const errors = validationErrors.inner.reduce(
            (acc, error) => ({ ...acc, [error.path]: error.message }),
            {},
          );
          setFormValidation({ isValid: false, errors });
        });
    },
    [schema, setFormValidation],
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const updatedUser = {
        name: name === 'name' ? value : confirmName,
        email: name === 'email' ? value : confirmEmail,
        phoneNumber: name === 'phoneNumber' ? value : confirmPhoneNumber,
        isTermsChecked,
      };

      if (name === 'name') dispatch(setConfirmName(value));
      if (name === 'email') dispatch(setConfirmEmail(value));
      if (name === 'phoneNumber') dispatch(setConfirmPhoneNumber(value));

      validateForm(updatedUser);
    },
    [
      confirmEmail,
      confirmName,
      confirmPhoneNumber,
      dispatch,
      isTermsChecked,
      validateForm,
    ],
  );

  const handleTermsChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsTermsChecked(e.target.checked);
      validateForm({
        name: confirmName,
        email: confirmEmail,
        phoneNumber: confirmPhoneNumber,
        isTermsChecked: e.target.checked,
      });
    },
    [confirmEmail, confirmName, confirmPhoneNumber, validateForm],
  );

  return (
    <VStack mb="8" px="6" alignItems="flex-start">
      <Heading variant="h4" pb="6">
        Your info
      </Heading>
      <Stack spacing="4" width="full">
        <InputField
          name="name"
          size="lg"
          borderRadius="lg"
          label="Name"
          autoComplete="name"
          defaultValue={confirmName}
          placeholder="First and last"
          onChange={handleOnChange}
        />
        <InputField
          name="email"
          size="lg"
          borderRadius="lg"
          label="Email"
          autoComplete="email"
          defaultValue={confirmEmail}
          inputMode="email"
          placeholder="example@email.com"
          onChange={handleOnChange}
        />
        <PhoneNumberInput
          country={country}
          setCountry={setCountry}
          onChange={(value) =>
            handleOnChange({ target: { name: 'phoneNumber', value } } as any)
          }
          label="Phone"
        />
      </Stack>
      <Box paddingX={2} marginTop={6} alignSelf="center">
        <Checkbox isChecked={isTermsChecked} onChange={handleTermsChange}>
          I agree to the TeachMe.To{' '}
          <Link href="/terms" isExternal color="#3182CE">
            Terms of Service
          </Link>
        </Checkbox>
      </Box>
    </VStack>
  );
}
