import { useLocalStorageValue } from "@react-hookz/web";
import axios from "axios";
import { useCallback, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../state/hooks";
import { selectIsTermsChecked, setIsTermsChecked } from "../../../../../state/slices/checkoutSlice";
import { selectConfirmEmail, selectConfirmName, selectConfirmPhoneNumber, setConfirmEmail, setConfirmName, setConfirmPhoneNumber } from "../../../../../state/slices/filtersSlice";
import { Country } from "../../../../../types";
import countries from '../../../../../utils/countries.json';
import studentInfoValidation from "../utils/studentInfoValidation";

export default function useStudentInfoForm() {
    const dispatch = useAppDispatch();
    const confirmEmail = useAppSelector(selectConfirmEmail);
    const confirmName = useAppSelector(selectConfirmName);
    const confirmPhoneNumber = useAppSelector(selectConfirmPhoneNumber);
    const isTermsChecked = useAppSelector(selectIsTermsChecked);

    const [country, setCountry] = useLocalStorageValue<Country>('country', {
        name: 'United States',
        flag: '🇺🇸',
        code: 'US',
        dialCode: '+1',
    });
    const [formValidation, setFormValidation] = useState<{
        isValid: boolean,
        errors: {
            name?: string,
            email?: string,
            phoneNumber?: string,
            isTermsChecked?: string,
        },
    }>({
        isValid: false,
        errors: {},
    });

    const schema = useMemo(
        () => studentInfoValidation(country?.code),
        [country?.code],
    );

    const getUserCountry = useCallback(async () => {
        try {
            const res = await axios(
                'https://api.ipregistry.co/?key=0lajp4q8lyh4jzb9',
            );
            const nextCountry = (countries as Country[]).find(
                (node) => node.code === res?.data?.location?.country?.code,
            );
            setCountry(nextCountry);
        } catch (error) {
            console.error('Failed to fetch country:', error);
        }
    }, [setCountry]);


    const validateForm = useCallback(
        (user) => {
            schema
                .validate(user, { abortEarly: false })
                .then(() => {
                    setFormValidation({ isValid: true, errors: {} });
                })
                .catch((validationErrors) => {
                    const errors = validationErrors.inner.reduce(
                        (acc, error) => ({ ...acc, [error.path]: error.message }),
                        {},
                    );
                    setFormValidation({ isValid: false, errors });
                });
        },
        [schema, setFormValidation],
    );


    const handleOnChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = e.target;
            const updatedUser = {
                name: name === 'name' ? value : confirmName,
                email: name === 'email' ? value : confirmEmail,
                phoneNumber: name === 'phoneNumber' ? value : confirmPhoneNumber,
                isTermsChecked,
            };

            if (name === 'name') dispatch(setConfirmName(value));
            if (name === 'email') dispatch(setConfirmEmail(value));
            if (name === 'phoneNumber') dispatch(setConfirmPhoneNumber(value));

            validateForm(updatedUser);
        },
        [
            confirmEmail,
            confirmName,
            confirmPhoneNumber,
            dispatch,
            isTermsChecked,
            validateForm,
        ],
    );

    const handleTermsChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(setIsTermsChecked(e.target.checked));
            validateForm({
                name: confirmName,
                email: confirmEmail,
                phoneNumber: confirmPhoneNumber,
                isTermsChecked: e.target.checked,
            });
        },
        [confirmEmail, confirmName, confirmPhoneNumber, dispatch, validateForm],
    );

    return {
        handleTermsChange,
        handleOnChange,
        validateForm,
        getUserCountry,
        schema,
        formValidation,
        setFormValidation,
        country,
        setCountry,
        email: confirmEmail,
        name: confirmName,
        phoneNumber: confirmPhoneNumber,
        isTermsChecked,
        setIsTermsChecked
    }
}
