import { ModalCloseButton, ModalContent } from '@chakra-ui/react';

import LocationFeeModalContent from './LocationFeeModalContent';

import BaseModal from '../../BaseModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function LocationFeeModalDesktop({ isOpen, onClose }: Props) {
  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <ModalContent
        alignItems="center"
        borderRadius="2xl"
        justifyContent="center"
        overflow="hidden"
        py={{ base: '2', md: '4' }}
        px={{ base: '4', md: '8' }}
        w={479}
      >
        <ModalCloseButton />
        <LocationFeeModalContent />
      </ModalContent>
    </BaseModal>
  );
}
