/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ListingsAndLocationsInBoundingBox } from '../../api';
import { SelectedListing } from '../../utils/types';
import type { RootState } from '../interfaces';

export interface ListingsState {
  allListingsInBoundingBox: ListingsAndLocationsInBoundingBox['listings'];
  isSearchingListingsInBoundingBox: boolean;
  listingsInBoundingBox: ListingsAndLocationsInBoundingBox['listings'];
  selectedListing: SelectedListing;
}

const initialState: ListingsState = {
  allListingsInBoundingBox: [],
  isSearchingListingsInBoundingBox: true,
  listingsInBoundingBox: [],
  selectedListing: undefined,
};

const slice = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    setAllListingsInBoundingBox: (
      state,
      action: PayloadAction<ListingsState['allListingsInBoundingBox']>,
    ) => {
      state.allListingsInBoundingBox =
        action.payload || initialState.allListingsInBoundingBox;
    },
    setIsSearchingListingsInBoundingBox: (
      state,
      action: PayloadAction<ListingsState['isSearchingListingsInBoundingBox']>,
    ) => {
      state.isSearchingListingsInBoundingBox =
        action.payload ?? initialState.isSearchingListingsInBoundingBox;
    },
    setListingsInBoundingBox: (
      state,
      action: PayloadAction<ListingsState['listingsInBoundingBox']>,
    ) => {
      state.listingsInBoundingBox = (
        action.payload || initialState.listingsInBoundingBox
      ).map((listing) => {
        // Keep same random travelAddress coords from previous search
        const prevListing = (state.listingsInBoundingBox || []).find(
          (l) => l.id === listing.id,
        );
        return {
          ...listing,
          travelAddressLatitude:
            prevListing?.travelAddressLatitude ?? listing.travelAddressLatitude,
          travelAddressLongitude:
            prevListing?.travelAddressLongitude ??
            listing.travelAddressLongitude,
        };
      });
    },
    setSelectedListing: (
      state,
      action: PayloadAction<ListingsState['selectedListing']>,
    ) => {
      state.selectedListing = action.payload || initialState.selectedListing;
    },
  },
});

// export actions
export const {
  setAllListingsInBoundingBox,
  setIsSearchingListingsInBoundingBox,
  setListingsInBoundingBox,
  setSelectedListing,
} = slice.actions;

// export selectors
export const selectAllListingsInBoundingBox = (state: RootState) =>
  state.listings.allListingsInBoundingBox;
export const selectIsSearchingListingsInBoundingBox = (state: RootState) =>
  state.listings.isSearchingListingsInBoundingBox;
export const selectListingsInBoundingBox = (state: RootState) =>
  state.listings.listingsInBoundingBox;
export const selectSelectedListing = (state: RootState) =>
  state.listings.selectedListing;

// export reducer
export default slice.reducer;
