import { useAppSelector } from '../state/hooks';
import { selectIsAtHome } from '../state/slices/filtersSlice';
import { selectSelectedListing } from '../state/slices/listingsSlice';

export default function useIsAtHomeOrListingExistsWithNoLocations(): boolean {
  const isAtHome = useAppSelector(selectIsAtHome);
  const selectedListing = useAppSelector(selectSelectedListing);

  const isAtHomeOrListingExistsWithNoLocations = Boolean(
    isAtHome ||
      (selectedListing?.locations && !selectedListing.locations.length),
  );

  return isAtHomeOrListingExistsWithNoLocations;
}
