import { useCallback, useMemo } from 'react';

import { useAppSelector } from '../../../../../state/hooks';
import { selectSelectedListing } from '../../../../../state/slices/listingsSlice';
import { selectSelectedLocation } from '../../../../../state/slices/locationsSlice';
import capturePostHogEvent from '../../../../../utils/capturePostHogEvent';
import getLocationTypeBySkillSlug from '../../../../../utils/getLocationTypeBySkillSlug';
import PosthogEvent from '../../../../../utils/posthogEvents';
import CheckoutItem, { CheckoutItemAction } from '../../CheckoutItem';

type Props = {
  skillSlug: string;
  isAtHome: boolean;
  onPress: () => void;
};

export default function CheckoutLocation({
  skillSlug,
  isAtHome,
  onPress,
}: Readonly<Props>) {
  const locationType = getLocationTypeBySkillSlug(skillSlug, false);
  const selectedLocation = useAppSelector(selectSelectedLocation);
  const selectedListing = useAppSelector(selectSelectedListing);

  const handleEdit = useCallback(() => {
    capturePostHogEvent(PosthogEvent.ClickedEditLocation, {
      ...selectedListing,
      selectedLocation,
    });

    onPress();
  }, [onPress, selectedListing, selectedLocation]);

  const locationText = useMemo(() => {
    if (!selectedLocation) {
      return 'Select with coach after checkout';
    }

    return isAtHome
      ? `At your favorite ${locationType}`
      : selectedLocation?.placeName;
  }, [isAtHome, locationType, selectedLocation]);

  return (
    <CheckoutItem
      title="Location"
      subtitle={locationText}
      isLoading={!locationType}
    >
      <CheckoutItemAction.Button
        hasSelected={Boolean(selectedLocation)}
        onPress={handleEdit}
      />
    </CheckoutItem>
  );
}
