import useTapfiliate from './useTapfiliate';

import { useAppSelector } from '../state/hooks';
import { selectSelectedSkill } from '../state/slices/filtersSlice';
import { PICKLEBALL_SKILL_SLUG } from '../utils/constants';

export default function usePickleheadsDiscount(): {
  pickleheadsDiscountAmount: number;
  isPickleheadsReferral: boolean;
} {
  const { tapfiliateReferralCode } = useTapfiliate();
  const selectedSkill = useAppSelector(selectSelectedSkill);

  const isPickleheadsReferral =
    tapfiliateReferralCode === 'pickleheads' &&
    selectedSkill?.slug === PICKLEBALL_SKILL_SLUG;

  // no discount for subscriptions
  const pickleheadsDiscountAmount = isPickleheadsReferral ? 50 : 0;

  return { pickleheadsDiscountAmount, isPickleheadsReferral };
}
