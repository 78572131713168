/* eslint-disable react/jsx-props-no-spreading */
import { Heading, VStack } from '@chakra-ui/react';

import DateAndTime from './DateAndTime';
import LessonLength from './LessonLength';
import Location from './Location';
import NeedEquipment from './NeedEquipment';
import NumberOfLessons from './NumberOfLessons';
import Participants from './Participants';

export default function YourLessons() {
  return (
    <VStack alignItems="flex-start" px="6">
      <Heading variant="h4" pb="6">
        Your lessons
      </Heading>

      <Location />
      <DateAndTime />
      <NumberOfLessons />
      <NeedEquipment />
      <LessonLength />
      <Participants />
    </VStack>
  );
}
