import { useDisclosure } from '@chakra-ui/react';
import { createContext, useContext, useState } from 'react';

import PolicyDrawer from '../components/PolicyDrawer';

interface IDrawerContext {
  isOpen: boolean;
  handleOpen: (term: string) => () => void;
  handleClose: () => void;
}

export const DrawerContext = createContext<Partial<IDrawerContext>>({});

export function DrawerProvider({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [initialPolicy, setInitialPolicy] = useState(null);

  const handleOpen = (term: string) => () => {
    setInitialPolicy(term);
    onOpen();
  };

  return (
    <DrawerContext.Provider
      value={{ isOpen, handleOpen, handleClose: onClose }}
    >
      <PolicyDrawer
        isOpen={isOpen}
        onClose={onClose}
        initialPolicy={initialPolicy}
      />
      {children}
    </DrawerContext.Provider>
  );
}

export const useDrawer = () => useContext(DrawerContext);
