import { toDate } from 'date-fns-tz';
import { useCallback } from 'react';

import { useAppDispatch } from '../state/hooks';
import { setDateTime } from '../state/slices/filtersSlice';

export default function useDispatchDateTime(): {
  dispatchDateTime(nextDate: Date | string | number): string;
  resetDateTime: () => void;
} {
  const dispatch = useAppDispatch();

  const dispatchDateTime = useCallback(
    (nextDate: Date | string | number) => {
      if (!nextDate) return '';
      const newDateTime = toDate(nextDate, { timeZone: 'UTC' }).toISOString();
      dispatch(setDateTime(newDateTime));
      return newDateTime;
    },
    [dispatch],
  );

  const resetDateTime = useCallback(() => {
    dispatch(setDateTime(undefined));
  }
    , [dispatch]);

  return { dispatchDateTime, resetDateTime };
}
