export default {
  body: {
    fontSize: '1rem',
    lineHeight: '150%',
    color: 'defaultText',
  },
  bold: {
    fontWeight: 800,
    fontSize: '1rem',
    lineHeight: '150%',
    color: 'defaultText',
  },
  smallBold: {
    fontWeight: 700,
    fontSize: '0.875rem',
    lineHeight: '150%',
    color: 'defaultText',
  },
  link: {
    fontWeight: 800,
    fontSize: '1rem',
    lineHeight: '150%',
    textDecorationLine: 'underline',
    color: '#3182CE',
  },
  smallLink: {
    fontWeight: 800,
    fontSize: '0.875rem',
    lineHeight: '150%',
    textDecorationLine: 'underline',
    color: '#3182CE',
  },
  caption: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '175%',
    color: 'muted',
  },
  small: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    color: 'muted',
  },
  overline: {
    fontWeight: 700,
    fontSize: '0.6875rem',
    lineHeight: '175%',
    letterSpacing: '0.035em',
    textTransform: 'uppercase',
    color: 'defaultText',
  },
  overlineBold: {
    fontWeight: 900,
    fontSize: '0.6875rem',
    lineHeight: '175%',
    letterSpacing: '0.035em',
    textTransform: 'uppercase',
    color: 'defaultText',
  },
};
