import { Box, Button, Center, Spinner, Stack, Text } from '@chakra-ui/react';
import {
  PaymentRequestButtonElement,
  Elements as StripeElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  PaymentRequest,
  PaymentRequestOptions,
  PaymentRequestUpdateOptions,
} from '@stripe/stripe-js';
import { useEffect, useMemo, useState } from 'react';
import { HiLockClosed } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { PricePayload } from '../../../api';
import WithAnimOnLayout from '../../../hocs/WithAnimOnLayout';
import { useError } from '../../../hooks/useError';
import usePickleheadsDiscount from '../../../hooks/usePickleheadsDiscount';
import usePricing from '../../../hooks/usePricing';
import useProReferralCode from '../../../hooks/useProReferralCode';
import useSentryWithContext from '../../../hooks/useSentryWithContext';
import useStudentReferral from '../../../hooks/useStudentReferral';
import { useSuccess } from '../../../hooks/useSuccess';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
  resetBooking,
  selectClientSecret,
  selectSuccessUrl,
} from '../../../state/slices/bookingSlice';
import {
  selectIsRecurring,
  selectPkg,
} from '../../../state/slices/filtersSlice';
import loadStripePromise from '../../../utils/stripe';
import { Listing } from '../../../utils/types';

type Props = {
  validation: { isValid: boolean; errors: Record<string, string> };
  isSubmitting: boolean;
  listing: Pick<Listing, 'id' | 'title'>;
  onCheckoutWithoutPaymentRequest(ev: any): void;
  onCheckoutWithPaymentRequest(): void;
};

function CheckoutButtonsWithStripeListingPayments({
  validation,
  isSubmitting,
  listing,
  onCheckoutWithoutPaymentRequest,
  onCheckoutWithPaymentRequest,
}: Readonly<Props>): JSX.Element {
  const { captureException } = useSentryWithContext();
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>(null);
  const pkg = useAppSelector(selectPkg);
  const { handleError } = useError();
  const { handleSuccess } = useSuccess();
  const clientSecret = useAppSelector(selectClientSecret);
  const successUrl = useAppSelector(selectSuccessUrl);
  const isRecurring = useAppSelector(selectIsRecurring);
  const { resetProReferralCode } = useProReferralCode();
  const { resetReferralCode } = useStudentReferral();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [prices, setPrices] = useState<PricePayload[] | null>(null);
  const {
    getPrices,
    getSubscriptionPrice,
    isLoading: isLoadingPrices,
  } = usePricing();
  const { pickleheadsDiscountAmount } = usePickleheadsDiscount();
  const price: PricePayload =
    prices?.find((node) => node?.package === pkg) || prices?.[0];
  const totalPrice = Math.max(
    0,
    (price?.price || 0) - pickleheadsDiscountAmount,
  );
  const isLoading = Boolean(isSubmitting || isLoadingPrices);
  const isDisabled = !validation?.isValid;
  const hasPointerEventsNone = isDisabled || isLoading;

  useEffect(() => {
    if (!listing?.id) return;
    const listingId = listing.id;
    if (isRecurring) {
      getSubscriptionPrice({ listingId })
        .then((subscriptionPrice) => setPrices([subscriptionPrice]))
        .catch((error) => {
          captureException(error, {
            context: {
              name: 'GetSubscriptionPrice',
              data: {
                listingId,
              },
            },
          });
          setPrices(null);
        });
    } else {
      getPrices({ listingId })
        .then(setPrices)
        .catch((error) => {
          captureException(error, {
            context: {
              name: 'GetPrices',
              data: {
                listingId,
              },
            },
          });
          setPrices(null);
        });
    }
  }, [
    listing?.id,
    getPrices,
    getSubscriptionPrice,
    isRecurring,
    pkg,
    setPrices,
    captureException,
  ]);

  const paymentRequestOptions: PaymentRequestOptions = useMemo(() => {
    if (totalPrice) {
      return {
        country: 'US',
        currency: 'usd',
        requestPayerEmail: true,
        requestPayerName: true,
        total: {
          // A name that the browser shows the customer in the payment interface.
          label: 'TeachMe.To',
          // The amount in the currency's subunit (e.g. cents, yen, etc.)
          amount: Math.floor(totalPrice * 100),
        },
      };
    }
    return undefined;
  }, [totalPrice]);

  const doesListingExist = !!listing;
  const doesPaymentRequestExist = !!paymentRequest;

  /**
   * Create/Update payment request.
   *
   * If the user does not have an active payment method,
   * `PaymentRequestButtonElement` should not be rendered.
   */
  useEffect(() => {
    if (!doesListingExist || isRecurring || !stripe || !paymentRequestOptions) {
      setPaymentRequest(null);
      return;
    }

    // Update payment request
    if (doesPaymentRequestExist) {
      const paymentRequestUpdateOptions: PaymentRequestUpdateOptions = {
        currency: paymentRequestOptions.currency,
        displayItems: paymentRequestOptions.displayItems,
        total: paymentRequestOptions.total,
        shippingOptions: paymentRequestOptions.shippingOptions,
        applePay: paymentRequestOptions.applePay,
      };
      paymentRequest?.update(paymentRequestUpdateOptions);
    }
    // Create payment request
    else {
      const pr = stripe.paymentRequest(paymentRequestOptions);

      // Check the availability of the Payment Request API.
      pr.canMakePayment()
        .then((result) => {
          if (result) {
            setPaymentRequest(pr);
          } else {
            setPaymentRequest(null);
          }
        })
        .catch(() => setPaymentRequest(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    doesListingExist,
    doesPaymentRequestExist,
    isRecurring,
    paymentRequestOptions,
    stripe,
  ]);

  const onPaymentRequestSuccess = () => {
    handleSuccess('Payment successful!');

    resetProReferralCode();
    resetReferralCode();

    if (successUrl) {
      window.location.assign(successUrl);
    } else {
      navigate('/');
    }

    dispatch(resetBooking());
  };

  /**
   * Stripe.js automatically creates a `PaymentMethod` after the customer is done
   * interacting with the browser’s payment interface.
   *
   * To access the created `PaymentMethod`, listen for this event.
   */
  useEffect(() => {
    const paymentRequestHandler = async (ev: any) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false },
        );

      if (confirmError) {
        handleError(
          'There was an error with your payment. Please contact our customer support.',
        );
        ev.complete('fail');
      } else {
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and, if so,
        // let Stripe.js handle the flow.
        if (paymentIntent.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            handleError('Payment failed. Do you have another payment method?');
          } else {
            onPaymentRequestSuccess();
          }
        } else {
          onPaymentRequestSuccess();
        }
      }
    };

    paymentRequest?.on('paymentmethod', paymentRequestHandler);

    return () => {
      paymentRequest?.off('paymentmethod', paymentRequestHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSecret, handleError, paymentRequest, stripe]);

  return (
    <Box
      onClick={() => {
        if (validation.errors) {
          handleError(validation.errors);
        }
      }}
      w="full"
    >
      <WithAnimOnLayout
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          opacity: isDisabled ? 0.5 : 1,
          pointerEvents: hasPointerEventsNone ? 'none' : undefined,
          width: '100%',
        }}
      >
        {isLoading && (
          <Center
            bg="rgba(255, 255, 255, 0.5)"
            pos="absolute"
            bottom="0"
            left="0"
            right="0"
            top="0"
            zIndex={1}
          >
            <Spinner color="muted" size="xl" />
          </Center>
        )}

        {doesPaymentRequestExist && (
          <Text color="#718096" mb="2" textAlign="center" textStyle="overline">
            HOW WOULD YOU LIKE TO PAY?
          </Text>
        )}

        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          w="full"
        >
          {doesPaymentRequestExist && (
            <Box w="full">
              <PaymentRequestButtonElement
                onClick={() => {
                  if (!clientSecret) {
                    onCheckoutWithPaymentRequest();
                  }
                }}
                options={{
                  paymentRequest,
                  style: {
                    paymentRequestButton: {
                      height: '60px',
                      theme: 'dark',
                      type: isRecurring ? 'subscribe' : 'default',
                    },
                  },
                }}
              />
            </Box>
          )}

          <Button
            isDisabled={isDisabled}
            variant="primary"
            w="full"
            size="xl"
            py="4"
            fontSize={doesPaymentRequestExist ? '24px' : undefined}
            rounded={doesPaymentRequestExist ? '8px' : 'full'}
            rightIcon={
              <HiLockClosed
                size={doesPaymentRequestExist ? '26px' : undefined}
              />
            }
            onClick={onCheckoutWithoutPaymentRequest}
          >
            {doesPaymentRequestExist ? 'Checkout' : 'Secure checkout'}
          </Button>
        </Stack>
      </WithAnimOnLayout>
    </Box>
  );
}

const stripePromise = loadStripePromise();

export default function ConfirmListingPayments({
  validation,
  isSubmitting,
  listing,
  onCheckoutWithoutPaymentRequest,
  onCheckoutWithPaymentRequest,
}: Props): JSX.Element {
  return (
    <StripeElements stripe={stripePromise}>
      <CheckoutButtonsWithStripeListingPayments
        validation={validation}
        isSubmitting={isSubmitting}
        listing={listing}
        onCheckoutWithoutPaymentRequest={onCheckoutWithoutPaymentRequest}
        onCheckoutWithPaymentRequest={onCheckoutWithPaymentRequest}
      />
    </StripeElements>
  );
}
