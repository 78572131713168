import { Tag, TagLabel, TagLabelProps, TagProps } from '@chakra-ui/react';

type Props = {
  bgColor?: TagProps['bgColor'];
  colorScheme?: TagProps['colorScheme'];
  label: string;
  labelColor: TagLabelProps['color'];
  labelTextTransform?: TagLabelProps['textTransform'];
  fontSize?: TagLabelProps['fontSize'];
  fontWeight?: TagLabelProps['fontWeight'];
  justifyContent?: TagProps['justifyContent'];
  borderRadius?: TagProps['borderRadius'];
  px?: TagProps['px'];
  py?: TagProps['py'];
  w?: TagProps['w'];
};

export default function BadgeTag({
  bgColor,
  colorScheme,
  label,
  labelColor,
  labelTextTransform,
  justifyContent,
  borderRadius = 'full',
  px = '4',
  py = '2',
  fontSize = 'xs',
  fontWeight = 'black',
  w
}: Props): JSX.Element {
  return (
    <Tag
      w={w}
      bgColor={bgColor}
      borderRadius={borderRadius}
      colorScheme={colorScheme}
      flexShrink="0"
      px={px}
      py={py}
      flex="1"
      justifyContent={justifyContent}
    >
      <TagLabel
        color={labelColor}
        textStyle="overlineBold"
        userSelect="none"
        textTransform={labelTextTransform}
        fontSize={fontSize}
        fontWeight={fontWeight}
      >
        {label}
      </TagLabel>
    </Tag>
  );
}
