import posthog from 'posthog-js';

/**
 * Capture a PostHog event, while including `super properties`
 * that might change over time.
 *
 * If there are `super properties` that should be included in all
 * events that do _not_ change over time, consider using one of:
 * - `posthog.register`
 * - `posthog.register_once`
 * - `posthog.setPersonProperties`
 * - `posthog.identify`
 */
const capturePostHogEvent: typeof posthog.capture = (
  eventName,
  properties,
  options,
) => {
  let connection: Record<string, any>;
  if ((navigator as any).connection) {
    const { downlink, downlinkMax, effectiveType, rtt, saveData, type } = (
      navigator as any
    ).connection;
    connection = { downlink, downlinkMax, effectiveType, rtt, saveData, type };
  }
  return posthog.capture(
    eventName,
    {
      ...properties,
      connection,
    },
    options,
  );
};

export default capturePostHogEvent;
