import { useLocalStorageValue } from '@react-hookz/web';
import { useCallback, useEffect, useState } from 'react';

import useInAppMember from './useInAppMember';
import useLessonLength from './useLessonLength';
import useSelectedLocation from './useSelectedLocation';

import {
  CalculateInputPriceType,
  Listing,
  PricePayload,
  usePricingLazyQuery,
} from '../api';
import { useAppSelector } from '../state/hooks';
import { selectParticipants, selectPkg } from '../state/slices/filtersSlice';
import { selectSelectedListing } from '../state/slices/listingsSlice';

type GetPricesParams = {
  listingId?: Listing['id'];
  participants?: number;
  package?: number;
  lessonLength?: number;
  locationId?: string;
};

export default function usePricing(listingId?: string) {
  const { isInAppMember } = useInAppMember();
  const [prices, setPrices] = useState<PricePayload[] | null>(null);
  const [getPricingQuery, { loading: isLoading }] = usePricingLazyQuery();
  const { lessonLength } = useLessonLength();
  const [selectedLocation] = useSelectedLocation();
  const selectedListing = useAppSelector(selectSelectedListing);
  const participants = useAppSelector(selectParticipants);
  const pkg = useAppSelector(selectPkg);
  const [posthogUserId] = useLocalStorageValue<string>('posthogUserId');

  const getSubscriptionPrice = useCallback(
    async (params: GetPricesParams): Promise<PricePayload> => {
      try {
        const response = await getPricingQuery({
          variables: {
            data: {
              posthogUserId,
              listingId: params.listingId,
              participants: params.participants || participants,
              lessonLength: params.lessonLength || lessonLength,
              locationId: params.locationId || selectedLocation?.id,
              type: CalculateInputPriceType.Subscription,
            },
          },
        });
        return response?.data?.getPricing?.[0];
      } catch (error) {
        return null;
      }
    },
    [
      getPricingQuery,
      lessonLength,
      participants,
      posthogUserId,
      selectedLocation,
    ],
  );

  const getPrices = useCallback(
    async (params: GetPricesParams): Promise<PricePayload[] | null> => {
      const response = await getPricingQuery({
        variables: {
          data: {
            posthogUserId,
            listingId: params.listingId || selectedListing?.id,
            package: params.package || pkg,
            participants: params.participants || participants,
            lessonLength: params.lessonLength || lessonLength,
            locationId: params.locationId || selectedLocation?.id,
            type: CalculateInputPriceType.Package,
            isMember: isInAppMember,
          },
        },
      });
      return response.data?.getPricing;
    },
    [
      getPricingQuery,
      pkg,
      participants,
      lessonLength,
      posthogUserId,
      selectedLocation,
      selectedListing?.id,
      isInAppMember,
    ],
  );

  useEffect(() => {
    if (!selectedListing?.id || !listingId) return;
    getPrices({ listingId: listingId || selectedListing?.id }).then(setPrices);
  }, [selectedListing?.id, listingId, getPrices]);

  const price = prices?.find((node) => node?.package === pkg);

  return { prices, price, getPrices, getSubscriptionPrice, isLoading };
}
