import { Flex } from '@chakra-ui/react';

import BreakdownRow from './BreakdownRow';

import { PricePayload } from '../../../api';
import { useModal } from '../../../context/ModalContext';
import useDiscounts from '../../../hooks/useDiscounts';
import useLessonLength from '../../../hooks/useLessonLength';
import usePickleheadsDiscount from '../../../hooks/usePickleheadsDiscount';
import usePricing from '../../../hooks/usePricing';
import useStudentReferral from '../../../hooks/useStudentReferral';
import { useAppSelector } from '../../../state/hooks';
import {
  selectParticipants,
  selectPkg,
} from '../../../state/slices/filtersSlice';
import { Listing } from '../../../utils/types';

type Props = {
  listing: Pick<Listing, 'id'>;
};

export default function ConfirmBreakdown({ listing }: Props): JSX.Element {
  const { hasFreeLesson } = useStudentReferral();
  const participants = useAppSelector(selectParticipants);
  const pkg = useAppSelector(selectPkg);
  const { lessonLength } = useLessonLength();
  const { handleOpen } = useModal();
  const { prices, isLoading: isLoadingPrices } = usePricing(listing?.id);
  const { pickleheadsDiscountAmount } = usePickleheadsDiscount();

  const price: PricePayload = prices?.find((node) => node?.package === pkg);
  const priceOnePkg: PricePayload = prices?.find((node) => node?.package === 1);
  const pkgWithLessonFree = pkg + (hasFreeLesson ? 1 : 0);

  const { packDiscountAmount, freeLessonDiscount } = useDiscounts(price, pkg);

  const isOneLessonPkg = pkgWithLessonFree === 1;
  const packageText = isOneLessonPkg
    ? `${pkgWithLessonFree} x ${lessonLength} minute lesson`
    : `${pkgWithLessonFree} x ${lessonLength} minute lessons`;
  const participantText =
    participants === 1 ? '1 student' : `${participants} students`;
  const onePkgNoDiscountTotal =
    (priceOnePkg?.pricePerLesson || 0) * pkg + freeLessonDiscount;

  return (
    <Flex flexDir="column">
      <BreakdownRow
        isLoading={isLoadingPrices}
        label={
          participants === 1
            ? `${packageText}`
            : `${packageText} (${participantText})`
        }
        value={`$${onePkgNoDiscountTotal}`}
      />

      {!!packDiscountAmount && (
        <BreakdownRow
          isDiscount
          isLoading={isLoadingPrices}
          label="Lesson pack discount"
          value={`- $${packDiscountAmount}`}
        />
      )}

      {!!pickleheadsDiscountAmount && (
        <BreakdownRow
          isDiscount
          isLoading={isLoadingPrices}
          label="Pickleheads discount"
          value={`- $${pickleheadsDiscountAmount}`}
        />
      )}

      {hasFreeLesson && (
        <BreakdownRow
          isDiscount
          isLoading={isLoadingPrices}
          label="Free lesson discount"
          value={`- $${freeLessonDiscount}`}
        />
      )}

      {Boolean(hasFreeLesson && !!price?.locationFee) && (
        <BreakdownRow
          isDiscount
          isLoading={isLoadingPrices}
          label="Reservation fee discount (lesson free)"
          value={`- $${price.locationFee}`}
        />
      )}

      <BreakdownRow
        isLoading={isLoadingPrices}
        label="One-time introduction fee"
        value={`$${price?.introFee}`}
      />

      <BreakdownRow
        isLoading={isLoadingPrices}
        label="Taxes and fees"
        value={`$${price?.checkoutFee}`}
      />

      {!!price?.locationFee && (
        <BreakdownRow
          isLoading={isLoadingPrices}
          label={`Reservation fee${
            isOneLessonPkg ? '' : ` (${pkgWithLessonFree} lessons)`
          }`}
          onClick={() => handleOpen('locationFee')}
          textDecorationLine="underline"
          value={`$${price.locationFee * pkgWithLessonFree}`}
        />
      )}
    </Flex>
  );
}
