import {
  Collapse,
  Divider,
  Flex,
  Heading,
  HStack,
  SkeletonText,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import ConfirmBreakdown from './ConfirmBreakdown';
import RecurringBreakdown from './RecurringBreakdown';

import { PricePayload } from '../../../api';
import useDiscounts from '../../../hooks/useDiscounts';
import usePickleheadsDiscount from '../../../hooks/usePickleheadsDiscount';
import usePricing from '../../../hooks/usePricing';
import { useAppSelector } from '../../../state/hooks';
import {
  selectIsRecurring,
  selectParticipants,
  selectPkg,
} from '../../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import BadgeTag from '../../BadgeTag';
import { Show } from '../../Show';

type Props = {
  hideBreakdown?: boolean;
  title?: string;
  hasBreakdownDivider?: boolean;
};

export default function YourTotal({
  hideBreakdown,
  title = 'Your total',
  hasBreakdownDivider = false,
}: Props): JSX.Element {
  const [recurringPrice, setRecurringPrice] = useState<PricePayload | null>();
  const listing = useAppSelector(selectSelectedListing);
  const participants = useAppSelector(selectParticipants);
  const pkg = useAppSelector(selectPkg);
  const isRecurring = useAppSelector(selectIsRecurring);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { prices, getSubscriptionPrice } = usePricing(listing?.id);
  const { isPickleheadsReferral, pickleheadsDiscountAmount } =
    usePickleheadsDiscount();

  const showPickleheadDiscount = isPickleheadsReferral;

  useEffect(() => {
    if (!listing?.id || !isRecurring) return;
    getSubscriptionPrice({ listingId: listing.id }).then(setRecurringPrice);
  }, [listing, participants, getSubscriptionPrice, isRecurring]);

  const onToggleIsExpanded = () => {
    setIsExpanded((prevIsExpanded) => {
      const newIsExpanded = !prevIsExpanded;
      capturePostHogEvent('toggleBreakdown', {
        newIsExpanded,
      });
      return newIsExpanded;
    });
  };

  const price: PricePayload = prices?.find((node) => node?.package === pkg);

  const { totalDiscountAmount, totalFreeLessonDiscount } = useDiscounts(
    price,
    pkg,
    true,
  );

  // Non-Recurring Price Calculations
  const totalPrice = Math.max(
    0,
    (price?.price || 0) - pickleheadsDiscountAmount,
  );
  const rawPrice = (price?.rawPrice || 0) + totalFreeLessonDiscount;

  // Recurring Price Calculations
  const totalRecurringPrice = Math.max(
    0,
    (recurringPrice?.price || 0) + (recurringPrice?.introFee || 0),
  );

  const totalDiscountedRecurringPrice = Math.max(
    0,
    (recurringPrice?.price || 0) +
      (recurringPrice?.introFee || 0) -
      pickleheadsDiscountAmount,
  );

  // Eventually we'd want a total discount handler here but for now we'll be using only the pickleheads discount
  const hasDiscount = isPickleheadsReferral && pickleheadsDiscountAmount > 0;

  return (
    <Flex flexDir="column" px="6">
      <HStack
        justify="space-between"
        alignItems="center"
        pb={isExpanded ? '6' : '4'}
      >
        <Heading variant="h4">{title}</Heading>
        {!hideBreakdown && (
          <Text
            _hover={{ cursor: 'pointer' }}
            onClick={onToggleIsExpanded}
            textStyle="smallLink"
          >
            {isExpanded ? 'Hide breakdown' : 'View breakdown'}
          </Text>
        )}
      </HStack>
      {showPickleheadDiscount && (
        <BadgeTag
          justifyContent="center"
          labelTextTransform="none"
          bgColor="#E9D8FD"
          borderRadius="lg"
          label={`$${pickleheadsDiscountAmount} Pickleheads discount applied!`}
          labelColor="#553C9A"
          fontWeight="extrabold"
          fontSize="14px"
        />
      )}

      <Collapse in={isExpanded || hideBreakdown}>
        {isRecurring ? (
          <RecurringBreakdown listing={listing} />
        ) : (
          <ConfirmBreakdown listing={listing} />
        )}
      </Collapse>

      {hasBreakdownDivider && <Divider mt="6" />}
      <Flex
        alignItems="center"
        flexDir="row"
        justifyContent="space-between"
        mt="4"
      >
        {isRecurring ? (
          <>
            <Flex flexDir="column">
              <Text textStyle="bold">Total (USD)</Text>
            </Flex>

            <Flex alignItems="flex-end" flexDir="column">
              <SkeletonText isLoaded={!!recurringPrice?.price} noOfLines={1}>
                <Stack direction="row" align="center">
                  {!!hasDiscount && (
                    <Text
                      textDecoration="line-through"
                      textStyle="bold"
                      color="muted"
                    >
                      ${totalRecurringPrice}
                    </Text>
                  )}
                  <Text textStyle="bold" color="accent">
                    ${totalDiscountedRecurringPrice} today
                  </Text>
                </Stack>
              </SkeletonText>

              <SkeletonText isLoaded={!!recurringPrice?.price} noOfLines={1}>
                <Text
                  color="accent"
                  textStyle="small"
                  fontWeight="bold"
                  noOfLines={1}
                >
                  {`then $${recurringPrice?.price} / month after`}
                </Text>
              </SkeletonText>
            </Flex>
          </>
        ) : (
          <>
            <Flex flexDir="column">
              <Text textStyle="bold">Total (USD)</Text>
            </Flex>

            <Flex alignItems="flex-end" flexDir="column">
              <Stack direction="row" align="center">
                <SkeletonText isLoaded={!!price?.price} noOfLines={1}>
                  <Text textStyle="bold" color="accent">
                    ${totalPrice}
                  </Text>
                </SkeletonText>

                <Show condition={!!totalDiscountAmount}>
                  <SkeletonText isLoaded={!!rawPrice} noOfLines={1}>
                    <Text
                      color="muted"
                      textDecoration="line-through"
                      textStyle="bold"
                    >
                      ${rawPrice}
                    </Text>
                  </SkeletonText>
                </Show>
              </Stack>

              <SkeletonText isLoaded={!!price?.price} noOfLines={1}>
                <Text color="accent" textStyle="smallBold" noOfLines={1}>
                  {totalDiscountAmount
                    ? `You're saving $${totalDiscountAmount}!`
                    : ' '}
                </Text>
              </SkeletonText>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
}
