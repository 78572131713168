import { Flex, HStack, Skeleton } from '@chakra-ui/react';

export default function ListingContentMobileHeader(): JSX.Element {

  return (
    <Flex
      bg="transparent"
      pos="absolute"
      top="0"
      left="0"
      right="0"
      margin="auto"
      zIndex="9"
      alignItems="center"
      justifyContent="space-between"
      pt="4"
      px="4"
    >
      <Skeleton
        h="40px"
        rounded="full"
      >
        Back to coaches X
      </Skeleton>

      <HStack alignItems="center" justifyContent="space-between" spacing="2">
        <Skeleton rounded="full" p="5" />
        <Skeleton rounded="full" p="5" />
      </HStack>
    </Flex>
  );
}
