import { MotionValue, useScroll } from 'framer-motion';
import { createContext, useContext, useMemo, useRef } from 'react';

import useIsMobile from '../hooks/useIsMobile';

interface IScrollContext {
  mainScrollRef: React.MutableRefObject<HTMLDivElement>;
  scroll: {
    scrollX: MotionValue<number>;
    scrollY: MotionValue<number>;
    scrollXProgress: MotionValue<number>;
    scrollYProgress: MotionValue<number>;
  };
  shouldAddScrollRef: boolean;
}

const MainScrollContext = createContext<IScrollContext>({
  mainScrollRef: undefined,
  scroll: undefined,
  shouldAddScrollRef: false,
});

export function MainScrollProviver({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isMobile } = useIsMobile();
  const mainScrollRef = useRef<HTMLDivElement>();
  const scroll = useScroll({ container: mainScrollRef });

  const shouldAddScrollRef = isMobile;

  const value: IScrollContext = useMemo(
    () => ({ mainScrollRef, scroll, shouldAddScrollRef }),
    [mainScrollRef, scroll, shouldAddScrollRef],
  );

  return (
    <MainScrollContext.Provider value={value}>
      {children}
    </MainScrollContext.Provider>
  );
}

export const useMainScroll = () => useContext(MainScrollContext);
