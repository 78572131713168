import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocalStorageValue } from '@react-hookz/web';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HiX } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import 'yup-phone-lite';

import { useCreateLeadMutation, useUniqueListingQuery } from '../../../api';
import useCurrentLocation from '../../../hooks/useCurrentLocation';
import { useError } from '../../../hooks/useError';
import useIsMobile from '../../../hooks/useIsMobile';
import useSelectedLocation from '../../../hooks/useSelectedLocation';
import useTapfiliate from '../../../hooks/useTapfiliate';
import { useAppSelector } from '../../../state/hooks';
import {
  selectAvailabilityFilter,
  selectDateTime,
  selectIsAtHome,
  selectParticipants,
  selectSelectedBadges,
  selectSelectedSkill,
} from '../../../state/slices/filtersSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import getTimezone from '../../../utils/getTimezone';
import isWithinAvailableHours from '../../../utils/isWithinAvailableHours';
import BaseModal from '../../BaseModal';
import { InputField } from '../../InputField';
import PhoneNumberInput from '../../PhoneNumberInput';
import { SelectField } from '../../SelectField';
import { Show } from '../../Show';
import { TextArea } from '../../TextArea';
import '../styles/index.css';
import { Props } from '../types';

interface FormValues {
  name: string;
  phoneNumber: string;
  message: string;
  userType: 'New customer' | 'Returning customer' | 'Coach';
}

type View = 'ask-question' | 'contact' | 'success';

function AskQuestionView({
  onNext,
  onClose,
}: {
  onNext?: (message: string) => void;
  onClose?: () => void;
}) {
  const schema = Yup.object().shape({
    message: Yup.string().required('Required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  return (
    <ModalContent
      display="flex"
      justifyContent="space-between"
      borderRadius="2xl"
      borderBottomRadius={{ base: 0, md: '2xl' }}
      position={{ base: 'absolute', md: 'relative' }}
      bottom={{ base: 0, md: 'auto' }}
      margin={{ base: 0, md: 'auto' }}
      p={{ base: '2', md: '4' }}
    >
      <ModalHeader>
        <VStack align="flex-start" spacing="0">
          <HStack
            alignItems="center"
            justifyContent="space-between"
            w="full"
            mb="2"
          >
            <Heading variant="h4">Ask a question</Heading>
            <IconButton
              pos="absolute"
              aria-label="Close modal"
              right="8"
              icon={<HiX size={24} />}
              variant="ghost"
              onClick={onClose}
            />
          </HStack>
          <HStack alignItems="center" gap="2">
            <Show condition={isWithinAvailableHours()}>
              <Box borderRadius="full" w="8px" h="8px" bg="#38A169" />
            </Show>
            <Text textAlign="left" fontSize="14px" color="#718096">
              Average response time:{' '}
              <Text as="span" color="#718096" fontWeight="bold">
                3 minutes
              </Text>
            </Text>
          </HStack>
        </VStack>
      </ModalHeader>

      <form>
        <ModalBody w="full">
          <Stack spacing="4">
            <TextArea
              container={{ isRequired: true }}
              labelFontSize="11px"
              fontSize="16px"
              p="4"
              labelFontWeight="700"
              rows={4}
              label="YOUR MESSAGE"
              placeholder="For example: Do you provide group lessons? How do lesson pack discounts work?"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('message')}
              showError={false}
              error={errors?.message?.message as any}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            size="xl"
            w="full"
            mt="4"
            isLoading={isSubmitting}
            onClick={handleSubmit((d) => onNext?.(d.message))}
            backgroundColor={isValid ? 'bg-accent' : 'bg-disabled'}
          >
            Next
          </Button>
        </ModalFooter>
      </form>
    </ModalContent>
  );
}

function SuccessView({ onClose }: { onClose?: () => void }) {
  return (
    <ModalContent
      display="flex"
      justifyContent="space-between"
      borderRadius="2xl"
      borderBottomRadius={{ base: 0, md: '2xl' }}
      position={{ base: 'absolute', md: 'relative' }}
      bottom={{ base: 0, md: 'auto' }}
      margin={{ base: 0, md: 'auto' }}
      p={{ base: '2', md: '4' }}
    >
      <ModalHeader>
        <HStack
          alignItems="center"
          justifyContent="space-between"
          w="full"
          mb="2"
        >
          <Heading variant="h4">We&apos;ll reach out shortly!</Heading>
          <IconButton
            pos="absolute"
            right="8"
            aria-label="Close modal"
            icon={<HiX size={24} />}
            variant="ghost"
            onClick={onClose}
          />
        </HStack>
        <HStack alignItems="center" gap="2">
          <Box borderRadius="full" w="8px" h="8px" bg="#38A169" />
          <Text textAlign="left" fontSize="14px" color="#718096">
            Average response time:{' '}
            <Text as="span" color="#718096" fontWeight="bold">
              3 minutes
            </Text>
          </Text>
        </HStack>
      </ModalHeader>
      <ModalBody>
        <Text textStyle="body">
          If this is outside of our business hours, we&apos;ll respond first
          thing in the morning.
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button variant="primary" w="full" size="xl" onClick={onClose}>
          Done
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}

export default function ChatRequestModal({ proName, isOpen, onClose }: Props) {
  const { handleError } = useError();
  const [createLead] = useCreateLeadMutation();
  const inputUsesVStack = useBreakpointValue({ base: true, md: false });
  const { isMobile } = useIsMobile();

  const [country, setCountry] = useState({
    name: 'United States',
    flag: '🇺🇸',
    code: 'US',
    dialCode: '+1',
  });
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [view, setView] = useState<View>('ask-question');

  const schema = Yup.object().shape({
    name: Yup.string().required('Required'),
    phoneNumber: Yup.string()
      // @ts-ignore
      .phone(country?.code, true, 'Invalid')
      .required('Required'),
    message: Yup.string().required('Required'),
    userType: Yup.string().required('Required'),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const dateTime = useAppSelector(selectDateTime);
  const { currentLocation } = useCurrentLocation();
  const selectedBadges = useAppSelector(selectSelectedBadges);
  const availabilityFilter = useAppSelector(selectAvailabilityFilter);
  const isAtHome = useAppSelector(selectIsAtHome);
  const participants = useAppSelector(selectParticipants);
  const [selectedLocation] = useSelectedLocation();
  const selectedSkill = useAppSelector(selectSelectedSkill);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [posthogUserId] = useLocalStorageValue<string>('posthogUserId');
  const { tapfiliateReferralCode } = useTapfiliate();

  // Get listing from params (possibly)
  const params = useParams();
  const { data } = useUniqueListingQuery({
    variables: {
      where: {
        slug: params.slug,
      },
    },
    skip: !params.slug,
  });
  const listing = data?.findUniqueListing;

  const handleOpenTooltip = () => {
    setIsTooltipOpen(true);
  };

  const handleCloseTooltip = () => {
    setIsTooltipOpen(false);
  };

  const currValues = getValues();
  const hasFilledAllValues: boolean = useMemo(() => {
    const hasFilledAll = Object.values(currValues).reduce(
      (prevValue, currValue) => {
        if (!currValue) return false;
        return prevValue ?? true;
      },
      true,
    );
    return hasFilledAll;
  }, [currValues]);

  useEffect(() => {
    if (isOpen) {
      capturePostHogEvent('viewChatRequestModal');
    }
  }, [isOpen]);

  const onSubmit = async (values: FormValues, type: string) => {
    try {
      setIsSubmitting(true);

      await createLead({
        variables: {
          data: {
            type,
            name: values?.name,
            phoneNumber: parsePhoneNumber(
              values?.phoneNumber,
              country?.code as any,
            ).number,
            message: values?.message ?? '',
            userType: values?.userType,
            timeZone: getTimezone(),
            country: country?.code,
            skill: selectedSkill?.label,
            url: window?.location?.href,
            currentLocation,
            location: {
              placeName: selectedLocation?.placeName,
              address: selectedLocation?.address,
            },
            proName: proName || listing?.proName,
            device: isMobile ? 'Mobile' : 'Desktop',
            posthogUserId,
            dateTime,
            tapfiliateReferralCode,
            filters: {
              selectedBadges,
              availabilityFilter,
              participants,
              isAtHome,
            },
          },
        },
      });

      capturePostHogEvent('chatRequestSubmitted');

      setView('success');
      reset(); // reset form values
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setView('ask-question');
    onClose();
  };

  if (view === 'ask-question') {
    return (
      <BaseModal
        isOpen={isOpen}
        onClose={handleClose}
        size={{ base: undefined, md: '2xl' }}
      >
        <AskQuestionView
          onClose={handleClose}
          onNext={(message) => {
            setValue('message', message);
            setView('contact');
          }}
        />
      </BaseModal>
    );
  }

  if (view === 'success') {
    return (
      <BaseModal
        isOpen={isOpen}
        onClose={handleClose}
        size={{ base: undefined, md: '2xl' }}
      >
        <SuccessView onClose={handleClose} />
      </BaseModal>
    );
  }
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={handleClose}
      scrollBehavior="outside"
      size={{ base: undefined, md: '2xl' }}
    >
      <ModalContent
        display="flex"
        justifyContent="space-between"
        borderRadius="2xl"
        borderBottomRadius={{ base: 0, md: '2xl' }}
        position={{ base: 'absolute', md: 'relative' }}
        bottom={{ base: 0, md: 'auto' }}
        margin={{ base: 0, md: 'auto' }}
        p={{ base: '2', md: '4' }}
      >
        <ModalHeader>
          <VStack align="flex-start" spacing="0">
            <HStack
              alignItems="center"
              justifyContent="space-between"
              w="full"
              mb="2"
            >
              <Heading variant="h4">Get an answer fast</Heading>
              <IconButton
                pos="absolute"
                right="8"
                aria-label="Close modal"
                icon={<HiX size={24} />}
                variant="ghost"
                onClick={handleClose}
              />
            </HStack>
            <HStack alignItems="center" gap="2">
              <Show condition={isWithinAvailableHours()}>
                <Box borderRadius="full" w="8px" h="8px" bg="#38A169" />
              </Show>
              <Text textAlign="left" fontSize="14px" color="#718096">
                Average response time:{' '}
                <Text as="span" color="#718096" fontWeight="bold">
                  3 minutes
                </Text>
              </Text>
            </HStack>
          </VStack>
        </ModalHeader>

        <form>
          <ModalBody w="full">
            <Stack spacing="4">
              <InputField
                py="6"
                borderRadius="lg"
                placeholder="John Doe"
                type="name"
                autoComplete="name"
                label="NAME"
                labelFontSize="11px"
                labelFontWeight="700"
                isRequired
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('name')}
                error={errors?.name?.message as any}
              />
              <Flex flexDirection={inputUsesVStack ? 'column' : 'row'} gap="2">
                <Flex flex={1}>
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field: { onChange } }) => (
                      <PhoneNumberInput
                        country={country}
                        setCountry={setCountry}
                        onChange={onChange}
                        error={errors?.phoneNumber?.message as any}
                        label="PHONE NUMBER"
                        labelFontSize="11px"
                        labelFontWeight="700"
                        isRequired
                      />
                    )}
                  />
                </Flex>

                <Flex flex={1}>
                  <SelectField
                    className="chat-request-select-field"
                    isRequired
                    label="I AM A..."
                    labelFontSize="11px"
                    fontSize="16px"
                    labelFontWeight="700"
                    selectProps={{
                      ...register('userType'),
                    }}
                    values={
                      [
                        'New customer',
                        'Returning customer',
                        'Coach',
                      ] as FormValues['userType'][]
                    }
                    error={errors?.userType?.message as any}
                  />
                </Flex>
              </Flex>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Tooltip
              hasArrow
              placement="top"
              label="Please fill out all fields to continue."
              textAlign="center"
              p="2"
              isOpen={Boolean(isTooltipOpen && !hasFilledAllValues)}
            >
              <Button
                size="xl"
                w="full"
                mt="4"
                isLoading={isSubmitting}
                onClick={handleSubmit((d) => onSubmit(d as FormValues, 'Text'))}
                onMouseOver={handleOpenTooltip}
                onMouseLeave={handleCloseTooltip}
                backgroundColor={
                  hasFilledAllValues ? 'bg-accent' : 'bg-disabled'
                }
              >
                Submit
              </Button>
            </Tooltip>
          </ModalFooter>
        </form>
      </ModalContent>
    </BaseModal>
  );
}
