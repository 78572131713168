import { Button, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

import useBookingWidgetHeight from '../../../hooks/useBookingWidgetHeight';
import useIsMobile from '../../../hooks/useIsMobile';
import { BottomBar } from '../../BottomBar';
import { Show } from '../../Show';

interface Props {
  isBottomCtaClickable?: boolean;
  bottomCtaLabel?: string | ReactNode;
  bottomCtaVariant?: string;
  isBottomCtaDisabled?: boolean;
  buttonType?: 'button' | 'submit' | 'reset';
  isSubmitting?: boolean;
  loadingText?: string;
  children?: ReactNode;
  position?: 'sticky' | 'relative';
  onClick?: () => void;
}

export default function BookingWidgetFooter({
  isBottomCtaClickable,
  bottomCtaLabel,
  bottomCtaVariant,
  isBottomCtaDisabled = false,
  buttonType = 'button',
  isSubmitting = false,
  loadingText = 'Loading...',
  children,
  position = 'sticky',
  onClick,
}: Props) {
  const { isBookingWidgetInnerContentScrollable } = useBookingWidgetHeight();
  const { isMobile } = useIsMobile();

  return (
    <BottomBar
      borderTopColor="border-default"
      borderTopWidth={isBookingWidgetInnerContentScrollable ? 1 : 0}
      boxShadow={isMobile ? 'none' : '0px -4px 12px 0px rgba(0, 0, 0, 0.12)'}
      position={position}
      w="full"
      bottom="0"
      left="0"
      right="0"
      align="center"
      justify="center"
      borderBottomRadius="2xl"
      mt="0 !important;"
    >
      <Show condition={!!children}>{children}</Show>
      <Show condition={!children}>
        <Show condition={!isBottomCtaClickable}>
          <Text textStyle="small" textAlign="center" color="muted">
            {bottomCtaLabel}
          </Text>
        </Show>
        <Show condition={isBottomCtaClickable}>
          <Button
            variant={bottomCtaVariant}
            w="full"
            size="lg"
            py="4"
            isLoading={isSubmitting}
            loadingText={loadingText}
            spinnerPlacement="end"
            type={buttonType}
            onClick={onClick}
            isDisabled={isBottomCtaDisabled || isSubmitting}
          >
            {bottomCtaLabel}
          </Button>
        </Show>
      </Show>
    </BottomBar>
  );
}
