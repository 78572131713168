import {
  Box,
  Flex,
  GridItem,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react';

import ListingContentMobileHeader from './ListingContentMobileHeader';

import useIsMobile from '../../../hooks/useIsMobile';

type Props = {
  columnWidth: number;
  numOfColumns: number;
  pageMargin: number;
};

export default function ListingContentSingleColumn({
  columnWidth,
  numOfColumns,
  pageMargin,
}: Props): JSX.Element {
  const { isMobile } = useIsMobile();

  const MEDIA_RATIO_W_OVER_H = 360 / 477;
  const mediaHeight =
    numOfColumns > 1 ? columnWidth : columnWidth / MEDIA_RATIO_W_OVER_H;

  const Header = (
    <Flex alignItems="flex-start" flexDir="column" justifyContent="center" mt={4}>
      <SkeletonText w="full" skeletonHeight="3" noOfLines={3}>
          Lessons with ...
      </SkeletonText>
    </Flex>
  );

  return (
    <GridItem pb="8" position="relative" w={columnWidth}>
      {isMobile && (<ListingContentMobileHeader />)}

      {numOfColumns > 1 ? Header : null}

      <Box
        position="relative"
        w={columnWidth}
        h={mediaHeight}
        alignItems="center"
        justifyContent="center"
        display="flex"
        borderRadius={numOfColumns === 1 ? undefined : '16px'}
        overflow="hidden"
      >
        <Skeleton h={mediaHeight} w={columnWidth}/>
      </Box>

      <Flex flexDir="column" px={`${pageMargin}px`}>
        {numOfColumns <= 1 ? Header : null}
      </Flex>

    </GridItem>
  );
}
