import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';

import { VITE_API_URL } from '../utils/constants';

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    jitter: true,
    max: Infinity,
  },
  attempts: {
    max: 2,
    retryIf: (error) => !!error,
  },
});

export default new ApolloClient({
  link: ApolloLink.from(
    [retryLink, new HttpLink({ uri: VITE_API_URL })].filter(Boolean),
  ),
  cache: new InMemoryCache(),
});
