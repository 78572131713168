import { Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import NotInServiceAreaModal from '../../../screens/ListingDetails/components/NotInServiceAreaModal';
import { Listing } from '../../../utils/types';

type Props = {
  listing: Pick<Listing, 'proName' | 'slug'> & { isInServiceArea?: boolean };
};

export default function ChangeYourLocationLink({
  listing,
}: Props): JSX.Element {
  const [isForcedOpen, setIsForcedOpen] = useState<boolean>(false);

  useEffect(() => {
    if (listing?.isInServiceArea) {
      setIsForcedOpen(false);
    }
  }, [listing?.isInServiceArea]);

  return (
    <>
      <Text
        cursor="pointer"
        noOfLines={1}
        onClick={(e) => {
          e.stopPropagation();
          setIsForcedOpen(true);
        }}
        textStyle="smallLink"
      >
        Change location
      </Text>

      <NotInServiceAreaModal
        isForcedOpen={isForcedOpen}
        isInServiceArea={!isForcedOpen || listing?.isInServiceArea}
        listing={listing}
        onCloseModal={() => setIsForcedOpen(false)}
      />
    </>
  );
}
