import { VStack } from '@chakra-ui/react';
import { HiOutlineSparkles } from 'react-icons/hi';

export const StudentFavoriteModalBadgeSizes = ['md', 'lg'] as const;

export type StudentFavoriteModalBadgeProps = {
  size: (typeof StudentFavoriteModalBadgeSizes)[number];
};

const sizes: Record<
  (typeof StudentFavoriteModalBadgeSizes)[number],
  {
    container: number;
    icon: number;
  }
> = {
  md: {
    container: 59,
    icon: 32,
  },
  lg: {
    container: 96,
    icon: 64,
  },
};

export default function StudentFavoriteModalBadge({
  size,
}: StudentFavoriteModalBadgeProps) {
  return (
    <VStack
      flexShrink={0}
      w={`${sizes[size].container}px`}
      h={`${sizes[size].container}px`}
      pos="relative"
      borderRadius={6}
      alignItems="center"
      justifyContent="center"
      bgGradient="linear-gradient(225deg, #3182CE 1.86%, #5EBDD2 40.76%, #182C93 81.24%);"
      boxShadow="0px 0px 16.857px 0px rgba(49, 130, 206, 0.50);"
    >
      <HiOutlineSparkles color="#FFFFFF" size={sizes[size].icon} />
    </VStack>
  );
}
