import { Box, Flex, HStack, SkeletonText, Stack, Text } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PricePayload } from '../../api';
import useListingSearchParams from '../../hooks/useListingSearchParams';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  selectIsRecurring,
  setIsRecurring,
  setPkg,
} from '../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../state/slices/listingsSlice';
import capturePostHogEvent from '../../utils/capturePostHogEvent';
import { BookingStep } from '../../utils/types';
import { Show } from '../Show';

interface Props {
  isInteractive?: boolean;
  participants: number;
  price: PricePayload;
  isLoading: boolean;
  isTop?: boolean;
  isBottom?: boolean;
  isMiddle?: boolean;
  onSelectPackage?: ({
    packageSize,
    isRecurring,
  }: {
    packageSize: number;
    isRecurring: boolean;
  }) => void;
}

export default function RecurringPrice({
  isInteractive = true,
  participants,
  price,
  isLoading,
  isTop = false,
  isBottom = false,
  isMiddle = false,
  onSelectPackage,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isRecurring = useAppSelector(selectIsRecurring);
  const selectedListing = useAppSelector(selectSelectedListing);
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const handleSelectPackage = () => {
    capturePostHogEvent('clickSubscription');
    dispatch(setIsRecurring(true));
    dispatch(setPkg(1));

    if (onSelectPackage) {
      onSelectPackage({ isRecurring: true, packageSize: 1 });
      return;
    }

    const listingSearchParams = getListingSearchParamsWithPartialInput({
      pkg: 1,
      isRecurring: true,
    });
    const listingUrl = `/listings/${selectedListing?.skill.slug}/${selectedListing?.slug}`;
    navigate(`${listingUrl}/${BookingStep.Packages}?${listingSearchParams}`, {
      replace: true,
    });
  };

  const renderVariantsRight = useCallback(
    () => (
      <Stack spacing="0" align="flex-end">
        <SkeletonText noOfLines={1} isLoaded={!isLoading && !!price}>
          <Stack align="flex-end" justify="center" spacing="0">
            <HStack spacing="1">
              <Text textStyle="bold" fontSize="sm" color="accent">
                ${price?.pricePerLesson}
              </Text>
              <Text textStyle="normal" fontSize="sm" color="accent">
                per lesson
              </Text>
            </HStack>
            <Show condition={participants > 1}>
              <Text textStyle="caption" fontWeight="normal" fontSize="sm">
                ${price?.pricePerLessonPerStudent} x {participants}
              </Text>
            </Show>
          </Stack>
        </SkeletonText>
      </Stack>
    ),
    [price, participants, isLoading],
  );

  const renderVariants = () => (
    <Flex w="full" h="full" align="center" justify="space-between">
      <Stack spacing="0" flex="1" mr="4">
        <Text textStyle="bold">Weekly recurring</Text>
        <Text color="muted" fontSize="sm" textStyle="caption">
          Minimum 1 month
        </Text>
      </Stack>
      {renderVariantsRight()}
    </Flex>
  );

  const isSelected = isInteractive && isRecurring;
  const hasBorderBottom = isTop || isMiddle;
  const hasBorderTop = isMiddle || isBottom;
  const boxShadow = `0px 0px 0px ${isSelected ? '3px' : '1px'} ${
    isSelected ? '#3182CE' : '#D9D9D9'
  } inset`;

  return (
    <Box
      px="4"
      height="20"
      borderRadius="2xl"
      cursor="pointer"
      _focus={{ bg: 'bg-muted' }}
      _active={{ bg: 'bg-muted' }}
      transition="all 150ms ease"
      bg={isSelected ? 'on-accent-muted' : 'transparent'}
      boxShadow={boxShadow} // inner border
      borderBottomRadius={hasBorderBottom ? 'none' : undefined}
      borderTopRadius={hasBorderTop ? 'none' : undefined}
      onClick={handleSelectPackage}
    >
      {renderVariants()}
    </Box>
  );
}
