import { PaymentRequestPaymentMethodEvent, Stripe } from "@stripe/stripe-js";
import { format } from "date-fns";
import { toDate } from "date-fns-tz";
import { v4 as uuidv4 } from 'uuid';

import { CreateBookingInput, CreateBookingInputMode, Package } from "../../api";
import { FiltersState } from "../../state/slices/filtersSlice";
import capturePostHogEvent from "../../utils/capturePostHogEvent";
import { DEFAULT_PACKAGES_MAPPER } from "../../utils/constants";
import PosthogEvent from "../../utils/posthogEvents";
import { SelectedLocation } from "../../utils/types";


type CreateBookingInputForCheckoutParams = {
    userId: string;
    userName: string;
    withPaymentRequest: boolean;
    hasLocation?: boolean;
    isRecurring?: boolean;
    dateTime?: string;
    posthogUserId?: string;
    participants?: number
    pkg: number
    selectedLocation: SelectedLocation;
    gear: string;
    isMobile: boolean;
    listingId: string;
    tapfiliateReferralCode?: string;
    atHomeLocation?: FiltersState['atHomeLocation'] & {
        placeName?: string;
        skillId?: string;
    };
    skillId: string;
    lessonLength?: number;
    pricingExperimentVariant?: string;
    proReferralCode?: string;
    referralCode?: string;
    confirmEmail?: string;
    confirmName?: string;
    confirmPhoneNumber?: string;
    tracking?: any;
    origin?: string;
}

export function getBookingInputMode({ isRecurring }: { isRecurring: boolean }): CreateBookingInputMode {
    return isRecurring
        ? CreateBookingInputMode.Subscription
        : CreateBookingInputMode.Payment
}

export function createBookingInputForCheckout(
    {
        userId,
        userName,
        withPaymentRequest,
        dateTime,
        posthogUserId,
        participants,
        isRecurring,
        pkg,
        selectedLocation,
        gear,
        isMobile,
        listingId,
        tapfiliateReferralCode,
        atHomeLocation,
        skillId,
        lessonLength,
        pricingExperimentVariant,
        proReferralCode,
        referralCode,
        confirmEmail,
        confirmName,
        confirmPhoneNumber,
        tracking,
        origin,
    }: CreateBookingInputForCheckoutParams
): CreateBookingInput {
    const createBookingInputMode = getBookingInputMode({ isRecurring });

    const createBookingInput: CreateBookingInput & {
        atHomeLocation: FiltersState['atHomeLocation'] & {
            placeName: string;
            skillId: string;
        };
    } = {
        posthogUserId,
        trackNumber: uuidv4(),
        startAt: dateTime
            ? format(toDate(new Date(dateTime)), 'yyyy-MM-dd HH:mm:ss')
            : undefined,
        dateTime,
        participants,
        package: isRecurring ? Package.One : DEFAULT_PACKAGES_MAPPER[pkg],
        location: { locationId: selectedLocation?.id }, // regular location
        flowMeta: { gear, device: isMobile ? 'Mobile' : 'Desktop' },
        origin,
        listingId,
        userId,
        tapfiliateReferralCode,
        atHomeLocation: atHomeLocation
            ? {
                ...atHomeLocation,
                placeName: `SEC Private Residence Student ${userName}`,
                skillId,
            }
            : undefined,
        isRecurring,
        lessonLength,
        mode: createBookingInputMode,
        pricingExperimentVariant,
        proReferralCode,
        referralCode,
        confirmEmail,
        confirmName,
        confirmPhoneNumber,
        tracking,
    };

    capturePostHogEvent(PosthogEvent.CreateBooking, {
        ...createBookingInput,
        withPaymentRequest,
    });

    return createBookingInput;
}

export const paymentRequestHandler = async ({
    stripe,
    clientSecret,
    event,
    onError,
    onSuccess,
}: {
    stripe: Stripe,
    clientSecret: string,
    event: PaymentRequestPaymentMethodEvent,
    onError: (message: string) => void,
    onSuccess: () => void,
}) => {
    // Confirm the PaymentIntent without handling potential next actions (yet).
    const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
            clientSecret,
            { payment_method: event.paymentMethod.id },
            { handleActions: false },
        );

    if (confirmError) {
        onError(
            'There was an error with your payment. Please contact our customer support.',
        );
        event.complete('fail');
        return;
    }

    event.complete('success');
    // Check if the PaymentIntent requires any actions and, if so,
    // let Stripe.js handle the flow.
    if (paymentIntent.status === 'requires_action') {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
            onError('Payment failed. Do you have another payment method?');
            return;
        }
    }

    onSuccess();
};
