import { Button, Flex } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { UniqueListingQuery } from '../../../api';
import useIsMobile from '../../../hooks/useIsMobile';
import useLessonLength from '../../../hooks/useLessonLength';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import { SelectedLocation } from '../../../utils/types';
import BookingWidgetBody from '../BookingWidgetBody';
import BookingWidgetHeader from '../BookingWidgetHeader';

interface Props {
  listing: Pick<
    UniqueListingQuery['findUniqueListing'],
    'id' | 'proName' | 'slug'
  > & {
    locations?: SelectedLocation[];
    skill?: Pick<
      UniqueListingQuery['findUniqueListing']['skill'],
      'category' | 'slug'
    >;
  };
  nextUrl: string;
}

export default function LessonLength({ listing, nextUrl }: Props) {
  const { isMobile } = useIsMobile();
  const { setSelectedLessonLengthForCurrentCategory, lessonLengthOptions } =
    useLessonLength();
  const options = lessonLengthOptions.possibleLengths;
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    capturePostHogEvent('viewBookingWidgetLessonLength', {
      listingSlug: listing?.slug,
    });
  }, [listing?.slug]);

  const onHandlePickLength = (length: number) => {
    capturePostHogEvent('clickContinue', {
      action: 'BookingWidgetLessonLength',
      listingSlug: listing?.slug,
    });

    setSelectedLessonLengthForCurrentCategory(length);

    const listingSearchParams = getListingSearchParamsWithPartialInput({
      lessonLength: length,
    });
    navigate(`${nextUrl}?${listingSearchParams}`);
  };

  return (
    <>
      <BookingWidgetHeader heading="How long would you like each lesson to be?" />

      <BookingWidgetBody flexGrow={2} isFullWidth={isMobile} pb="4">
        <Flex
          alignItems="center"
          flexDir="column"
          flexGrow={1}
          justifyContent="flex-end"
          px="4"
          pb="2"
        >
          {options.map((lengthItem, index) => (
            <Button
              variant="outline"
              borderColor="border-default"
              _hover={{
                borderColor: 'gray.700',
                bg: 'gray.50',
              }}
              size="xl"
              w="full"
              rounded="full"
              mt="0"
              mb="2"
              key={lengthItem.shortLabel}
              onClick={() => onHandlePickLength(lengthItem.minutes)}
            >
              {`${lengthItem.minutes} minutes${
                index === 0 ? ' (suggested)' : ''
              }`}
            </Button>
          ))}
        </Flex>
      </BookingWidgetBody>
    </>
  );
}
