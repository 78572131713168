import { Button, Divider, HStack, VStack } from '@chakra-ui/react';
import { useLocalStorageValue } from '@react-hookz/web';
import { format } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { HiLockClosed } from 'react-icons/hi';
import { v4 as uuidv4 } from 'uuid';
import 'yup-phone-lite';

import CoachInfo from './CoachInfo';
import ConfirmFooter from './ConfirmFooter';
import OurPromise from './OurPromise';
import StudentInfo from './StudentInfo';
import YourLessons from './YourLessons';
import YourTotal from './YourTotal';

import {
  CreateBookingInput,
  CreateBookingInputMode,
  Package,
  useCreateBookingMutation,
  useCreatePaymentIntentForBookingMutation,
  useCreateUserMutation,
} from '../../../api';
import { useError } from '../../../hooks/useError';
import useInAppMember from '../../../hooks/useInAppMember';
import useIsAtHomeOrListingExistsWithNoLocations from '../../../hooks/useIsAtHomeOrListingExistsWithNoLocations';
import useIsMobile from '../../../hooks/useIsMobile';
import useLessonLength from '../../../hooks/useLessonLength';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import useOrigin from '../../../hooks/useOrigin';
import usePosthogCustomEvents from '../../../hooks/usePosthogCustomEvents';
import usePricing from '../../../hooks/usePricing';
import useProReferralCode from '../../../hooks/useProReferralCode';
import useSelectedLocation from '../../../hooks/useSelectedLocation';
import useSentryWithContext from '../../../hooks/useSentryWithContext';
import useStudentReferral from '../../../hooks/useStudentReferral';
import useTapfiliate from '../../../hooks/useTapfiliate';
import useTracking from '../../../hooks/useTracking';
import { captureStartCheckout } from '../../../infra/gtmClient';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
  setClientSecret,
  setSuccessUrl,
} from '../../../state/slices/bookingSlice';
import {
  FiltersState,
  selectAtHomeLocation,
  selectConfirmEmail,
  selectConfirmName,
  selectConfirmPhoneNumber,
  selectDateTime,
  selectIsRecurring,
  selectParticipants,
  selectPkg,
} from '../../../state/slices/filtersSlice';
import { Country } from '../../../types';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import { DEFAULT_PACKAGES_MAPPER } from '../../../utils/constants';
import { customerIoIdentifyWithPH } from '../../../utils/customerIO';
import getTimezone from '../../../utils/getTimezone';
import PosthogEvent from '../../../utils/posthogEvents';
import { BookingStep, Listing } from '../../../utils/types';
import BookingWidgetBody from '../BookingWidgetBody';
import BookingWidgetFooter from '../BookingWidgetFooter';
import BookingWidgetHeader from '../BookingWidgetHeader';

interface Props {
  listing: Pick<
    Listing,
    'id' | 'locations' | 'proAvatar' | 'proName' | 'slug' | 'title'
  > & {
    skill?: Pick<Listing['skill'], 'id' | 'label' | 'slug'>;
  };
}

export default function Confirm({ listing }: Readonly<Props>) {
  const { captureException } = useSentryWithContext();
  const { proReferralCode, resetProReferralCode } = useProReferralCode();
  const { referralCode, resetReferralCode } = useStudentReferral();
  const { createAlias, identify } = usePosthogCustomEvents();
  const { tracking } = useTracking();
  const { tapfiliateReferralCode } = useTapfiliate();
  const { handleError } = useError();
  const [createUser] = useCreateUserMutation();
  const [createBooking] = useCreateBookingMutation();
  const { isMobile } = useIsMobile();
  const pkg = useAppSelector(selectPkg);
  const dateTime = useAppSelector(selectDateTime);
  const [posthogUserId] = useLocalStorageValue<string>('posthogUserId');
  const { origin } = useOrigin();
  const [selectedLocation] = useSelectedLocation();
  const participants = useAppSelector(selectParticipants);
  const [gear] = useLocalStorageValue<string>('gear', 'no');
  const atHomeLocation = useAppSelector(selectAtHomeLocation);
  const isAtHomeOrListingExistsWithNoLocations =
    useIsAtHomeOrListingExistsWithNoLocations();
  const { lessonLength } = useLessonLength();
  const isRecurring = useAppSelector(selectIsRecurring);
  const dispatch = useAppDispatch();
  const confirmEmail = useAppSelector(selectConfirmEmail);
  const confirmName = useAppSelector(selectConfirmName);
  const confirmPhoneNumber = useAppSelector(selectConfirmPhoneNumber);
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();
  const { isInAppMember, createInAppCheckout } = useInAppMember();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { price } = usePricing(listing?.id);
  const [createPaymentIntentForBooking] =
    useCreatePaymentIntentForBookingMutation();
  const [country, setCountry] = useLocalStorageValue<Country>('country', {
    name: 'United States',
    flag: '🇺🇸',
    code: 'US',
    dialCode: '+1',
  });

  const proName = listing?.proName;
  const skill = listing?.skill?.label;
  const listingId = listing?.id;
  const selectedAtHomeLocation = isAtHomeOrListingExistsWithNoLocations
    ? atHomeLocation
    : undefined;

  const [formValidation, setFormValidation] = useState({
    isValid: false,
    errors: {},
  });

  // Go back when pressing the browser's back button
  useEffect(() => {
    function onPopState() {
      window.history.back();
    }
    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, []);

  function goToBookingStepLocation() {
    if (!listing?.skill?.slug) return;

    const listingUrl = `/listings/${listing.skill.slug}/${listing.slug}`;
    const bookingStepLocationUrl = `${listingUrl}/${
      BookingStep.Location
    }?${getListingSearchParamsWithPartialInput()}`;

    window.location.replace(bookingStepLocationUrl);
  }

  // Must have either regular location or at-home location, and listing must exist
  const hasRegularLocation: boolean = !!selectedLocation?.id;
  const hasAtHomeLocation: boolean = !!(
    selectedAtHomeLocation?.address && listing?.skill?.id
  );
  const hasLocation: boolean = hasRegularLocation || hasAtHomeLocation;

  const createBookingInputMode: CreateBookingInputMode = isRecurring
    ? CreateBookingInputMode.Subscription
    : CreateBookingInputMode.Payment;

  const checkoutBase = async (
    userId: string,
    userName: string,
    withPaymentRequest: boolean,
  ): Promise<CreateBookingInput> => {
    if (!hasLocation) {
      handleError('Please choose a location');
      goToBookingStepLocation();
      return null;
    }

    const createBookingInput: CreateBookingInput & {
      atHomeLocation: FiltersState['atHomeLocation'] & {
        placeName: string;
        skillId: string;
      };
    } = {
      posthogUserId,
      trackNumber: uuidv4(),
      startAt: dateTime
        ? format(toDate(new Date(dateTime)), 'yyyy-MM-dd HH:mm:ss')
        : undefined,
      dateTime,
      participants,
      package: isRecurring ? Package.One : DEFAULT_PACKAGES_MAPPER[pkg],
      location: { locationId: selectedLocation?.id }, // regular location
      flowMeta: { gear, device: isMobile ? 'Mobile' : 'Desktop' },
      origin: isInAppMember ? 'In-App Browser (Student App)' : origin,
      listingId,
      userId,
      tapfiliateReferralCode,
      atHomeLocation: selectedAtHomeLocation
        ? {
            ...selectedAtHomeLocation,
            placeName: `SEC Private Residence Student ${userName}`,
            skillId: listing.skill.id,
          }
        : undefined,
      isRecurring,
      lessonLength,
      mode: createBookingInputMode,
      proReferralCode,
      referralCode,
      confirmEmail,
      confirmName,
      confirmPhoneNumber,
      tracking,
    };

    capturePostHogEvent(PosthogEvent.CreateBooking, {
      ...createBookingInput,
      withPaymentRequest,
    });

    return createBookingInput;
  };

  const createBookingWithoutPaymentRequest = async (
    createBookingInput: CreateBookingInput,
  ) => {
    const { data, errors } = await createBooking({
      variables: {
        data: createBookingInput,
      },
    });
    if (errors?.length) {
      errors.forEach((error) =>
        captureException(error, {
          context: {
            name: 'CreateBookingWithoutPaymentRequest',
            data: createBookingInput,
          },
        }),
      );

      throw new Error(
        `We aren't sure what happened. Please try again or contact customer support for help.`,
      );
    }
    resetProReferralCode();
    resetReferralCode();
    window.location.assign(data?.createBooking?.url);
  };

  const checkoutWithoutPaymentRequest = async (
    userId: string,
    userName: string,
  ) => {
    if (!hasLocation) {
      handleError('Please choose a location');
      goToBookingStepLocation();
      return;
    }

    const createBookingInput = await checkoutBase(userId, userName, false);
    if (!createBookingInput) return;

    await createBookingWithoutPaymentRequest(createBookingInput);
    captureStartCheckout({
      ecommerce: {
        currencyCode: 'USD',
        value: price?.price || 0,
        add: {
          products: [
            {
              quantity: 1,
              name: listing?.title,
              id: listing?.id,
              price: price?.price || 0,
            },
          ],
        },
      },
      user_data: {
        email: confirmEmail,
        phone_number: confirmPhoneNumber,
      },
    });
  };

  const createBookingWithPaymentRequest = async (
    createBookingInput: CreateBookingInput,
  ) => {
    const { data, errors } = await createPaymentIntentForBooking({
      variables: {
        data: createBookingInput,
      },
    });
    if (errors?.length) {
      errors.forEach((error) =>
        captureException(error, {
          context: {
            name: 'CreateBookingWithPaymentRequest',
            data: createBookingInput,
          },
        }),
      );

      throw new Error(
        `We aren't sure what happened. Please try again or our contact customer support for help.`,
      );
    }

    dispatch(
      setClientSecret(data?.createPaymentIntentForBooking?.clientSecret),
    );
    dispatch(setSuccessUrl(data?.createPaymentIntentForBooking?.successUrl));
  };

  const checkoutWithPaymentRequest = async (
    userId: string,
    userName: string,
  ) => {
    if (!hasLocation) {
      handleError('Please choose a location');
      goToBookingStepLocation();
      return;
    }

    const createBookingInput = await checkoutBase(userId, userName, true);
    if (!createBookingInput) return;

    await createBookingWithPaymentRequest(createBookingInput);

    captureStartCheckout({
      ecommerce: {
        currencyCode: 'USD',
        value: price?.price || 0,
        add: {
          products: [
            {
              quantity: 1,
              name: listing?.title,
              id: listing?.id,
              price: price?.price || 0,
            },
          ],
        },
      },
      user_data: {
        email: confirmEmail,
        phone_number: confirmPhoneNumber,
      },
    });
  };

  const handleRegistrationBase = async (): Promise<{
    userId: string;
    userName: string;
  }> => {
    if (!hasLocation) {
      handleError('Please choose a location');
      goToBookingStepLocation();
      return { userId: '', userName: '' };
    }

    // capture posthog event
    capturePostHogEvent(PosthogEvent.StartCheckout, undefined, {
      send_instantly: true,
    });

    let formattedSelectedLocation = 'No selected location';
    if (selectedAtHomeLocation) {
      formattedSelectedLocation = `At home: ${selectedAtHomeLocation.address} (${selectedAtHomeLocation.latitude}, ${selectedAtHomeLocation.longitude})`;
    } else if (selectedLocation) {
      formattedSelectedLocation = `${selectedLocation.placeName} | ${selectedLocation.address}`;
    }
    const name = confirmName || '';
    const email = confirmEmail?.toLowerCase()?.trim() || '';
    const phoneNumber = confirmPhoneNumber
      ? parsePhoneNumber(confirmPhoneNumber, country?.code).number
      : '';

    const { data: createdUser } = await createUser({
      variables: {
        data: {
          name,
          email,
          phoneNumber,
          timeZone: getTimezone(),
          metadata: {
            skill,
            proName,
            pkg,
            date: dateTime,
            pricingExperimentFlag: 'control',
            createBookingInputMode,
            tapfiliateReferralCode,
            formattedSelectedLocation,
            gclid: tracking.gclid,
            msclkid: tracking.msclkid,
          },
        },
      },
    });
    const userId = createdUser?.createUser?.id;
    const userName = createdUser?.createUser?.name || '';

    if (userId) {
      const userEmail = createdUser?.createUser?.email || '';

      /**
       * Identify the user with Posthog and create an alias for the user
       * to track the user's activity across devices
       *
       * Doing this async to await before redirecting to the Stripe checkout page
       */
      await identify(userName, userEmail, userId);
      await createAlias(userId);

      if (userEmail) {
        customerIoIdentifyWithPH(posthogUserId, userEmail, userId);
      }
    }

    return { userId, userName };
  };

  const handleRegistrationWithoutPaymentRequest = async () => {
    setIsSubmitting(true);
    try {
      const { userId, userName } = await handleRegistrationBase();
      await checkoutWithoutPaymentRequest(userId, userName);
    } catch (error) {
      console.error('Error creating booking without payment request', error);
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRegistrationWithPaymentRequest = async () => {
    setIsSubmitting(true);
    try {
      const { userId, userName } = await handleRegistrationBase();
      await checkoutWithPaymentRequest(userId, userName);
    } catch (error) {
      console.error('Error creating booking with payment request', error);
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInAppBrowseCheckout = async () => {
    setIsSubmitting(true);
    try {
      const params = await checkoutBase(null, confirmName, false);
      await createInAppCheckout(params);
      setTimeout(() => setIsSubmitting(false), 1000);
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <VStack w="full" spacing={0}>
      <BookingWidgetHeader
        headingVariant="h5"
        heading="Confirm and pay"
        showChatButton={isMobile}
        hideBackButton={!isMobile}
      />
      <BookingWidgetBody isFullWidth>
        <Divider mb="4" />
        <CoachInfo />
        <Divider borderWidth="3px" mb="6" mt="4" />
        <YourLessons />
        <Divider borderWidth="3px" my="6" />
        <YourTotal />

        <Divider borderWidth="3px" my="6" />
        <OurPromise />
        {!isInAppMember ? (
          <>
            <Divider borderWidth="3px" my="6" />
            <StudentInfo
              country={country}
              setCountry={setCountry}
              setFormValidation={setFormValidation}
            />
          </>
        ) : (
          <HStack pb="6" />
        )}
      </BookingWidgetBody>

      {isInAppMember ? (
        <BookingWidgetFooter>
          <Button
            py="4"
            w="full"
            rounded="full"
            variant="primary"
            isLoading={isSubmitting}
            rightIcon={<HiLockClosed />}
            onClick={handleInAppBrowseCheckout}
          >
            Secure checkout
          </Button>
        </BookingWidgetFooter>
      ) : (
        <ConfirmFooter
          validation={formValidation}
          isSubmitting={isSubmitting}
          listing={listing}
          onCheckoutWithoutPaymentRequest={
            handleRegistrationWithoutPaymentRequest
          }
          onCheckoutWithPaymentRequest={handleRegistrationWithPaymentRequest}
        />
      )}
    </VStack>
  );
}
