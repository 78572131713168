import Axios from 'axios';

import type { CurrentLocation } from './types';

import sentryClient from '../infra/sentryClient';

export default async function getUserLocationFromIp(): Promise<CurrentLocation> {
  try {
    const res = await Axios('https://api.ipregistry.co/?key=0lajp4q8lyh4jzb9');
    if (!res?.data?.location) throw new Error('No location data');

    const userLocation: CurrentLocation = {
      address: '',
      city: res.data.location.city || '',
      country: res.data.location.country?.name || '',
      state: res.data.location.region?.name || '',
      latitude: res.data.location.latitude || 0,
      longitude: res.data.location.longitude || 0,
      postalCode: res.data.location.postal || '',
      stateShortCode: res.data.location.region?.code || '',
    };

    return userLocation;
  } catch (error) {
    sentryClient.captureException(error, {
      context: {
        name: 'GetUserLocationFromIp',
        data: {}
      }
    });
    return null;
  }
}
