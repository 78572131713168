import { Select as ChakraReactSelect } from 'chakra-react-select';

import { AvailabilityTimeOption } from '../../../hooks/useAvailableRecommendations';

type Props = {
  onChange(value: AvailabilityTimeOption): void;
  options: AvailabilityTimeOption[];
  placeholder: string;
  value: AvailabilityTimeOption;
};

export default function AvailabilityTime({
  onChange,
  options,
  placeholder,
  value,
}: Props): JSX.Element {
  return (
    <ChakraReactSelect
      useBasicStyles
      isSearchable={false}
      isClearable={false}
      selectedOptionColorScheme="blue"
      chakraStyles={{
        container: (provided) => ({
          ...provided,
          borderColor: 'transparent',
          borderRadius: 'full',
          cursor: 'pointer',
        }),
        control: (provided, { isFocused }) => ({
          ...provided,
          borderColor: isFocused ? '#3182ce' : '#CBD5E0',
          borderRadius: '8px',
          borderWidth: 1,
        }),
        valueContainer: (provided) => ({
          ...provided,
          paddingRight: 0,
        }),
      }}
      blurInputOnSelect
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      options={options}
      key={value?.value}
    />
  );
}
