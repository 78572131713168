/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LocationAffiliateName } from '../../api';
import { Bounds } from '../../hooks/useGeoSearch';
import { CurrentLocation, SelectedLocation } from '../../utils/types';
import type { RootState } from '../interfaces';

export interface LocationsState {
  includedAffiliateName?: LocationAffiliateName;
  mapLocation: CurrentLocation;
  searchBoundingBox: Bounds;
  selectedLocation: SelectedLocation;
  askToShareLocation: boolean;
}

const initialMapLocation: CurrentLocation = {
  address: '',
  country: '',
  city: '',
  latitude: 0,
  longitude: 0,
  postalCode: '',
  state: '',
  stateShortCode: '',
};

const initialState: LocationsState = {
  includedAffiliateName: undefined,
  mapLocation: initialMapLocation,
  searchBoundingBox: undefined,
  selectedLocation: undefined,
  askToShareLocation: true
};

const slice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setIncludedAffiliateName: (
      state,
      action: PayloadAction<LocationsState['includedAffiliateName']>,
    ) => {
      state.includedAffiliateName =
        action.payload || initialState.includedAffiliateName;
    },
    setMapLocation: (
      state,
      action: PayloadAction<LocationsState['mapLocation']>,
    ) => {
      state.mapLocation = action.payload || initialState.mapLocation;
    },
    setSearchBoundingBox: (
      state,
      action: PayloadAction<LocationsState['searchBoundingBox']>,
    ) => {
      state.searchBoundingBox =
        action.payload || initialState.searchBoundingBox;
    },
    setSelectedLocation: (
      state,
      action: PayloadAction<LocationsState['selectedLocation']>,
    ) => {
      state.selectedLocation = action.payload || initialState.selectedLocation;
    },
    setAskToShareLocation: (
      state,
      action: PayloadAction<LocationsState['askToShareLocation']>,
    ) => {
      state.askToShareLocation = action.payload
    },
  },
});

// export actions
export const {
  setIncludedAffiliateName,
  setMapLocation,
  setSearchBoundingBox,
  setSelectedLocation,
  setAskToShareLocation
} = slice.actions;

// export selectors
export const selectIncludedAffiliateName = (state: RootState) =>
  state.locations.includedAffiliateName;
export const selectMapLocation = (state: RootState) =>
  state.locations.mapLocation;
export const selectSearchBoundingBox = (state: RootState) =>
  state.locations.searchBoundingBox;
export const selectSelectedLocation = (state: RootState) =>
  state.locations.selectedLocation;
export const selectAskToShareLocation = (state: RootState) =>
  state.locations.askToShareLocation;

// export reducer
export default slice.reducer;
