import { Box, Input, List, ListItem, Text } from '@chakra-ui/react';
import { useState } from 'react';

import { Country } from '../../types';

interface Props {
  data: Country[];
  onChange: (args: Country) => void;
}

export default function CountryList({ data, onChange }: Props) {
  const [filteredList, setFilteredList] = useState(data);
  const [selectedItem, setSelectedItem] = useState<Country | undefined>(
    undefined,
  );

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value.toLowerCase();
    const result =
      data?.filter((item) => item.name.toLowerCase().includes(value)) || [];
    setFilteredList(result);
  };

  return (
    <Box
      my={1}
      maxH="xs"
      bg="bg-subtle"
      width="full"
      zIndex={999}
      height="auto"
      overflow="auto"
      borderRadius="lg"
      position="absolute"
      boxShadow="0px 1px 30px rgba(0, 0, 0, 0.1)"
    >
      <Box position="sticky" top="0" padding={4} bg="bg-subtle">
        <Input
          size="sm"
          type="search"
          borderRadius="md"
          autoComplete="off"
          placeholder="Select your country"
          onChange={(event) => handleSearch(event)}
          _focusWithin={{ borderColor: 'secondary' }}
          _invalid={{ bg: 'white', borderColor: 'gray.50' }}
        />
      </Box>

      <List>
        {filteredList?.map((item: Country) => (
          <ListItem
            key={item.code}
            paddingY={2}
            color="#ACB9C4"
            cursor="pointer"
            fontWeight="500"
            textTransform="capitalize"
            onClick={() => {
              onChange(item);
              setSelectedItem(item);
            }}
            style={{ transition: 'all .125s ease' }}
            _hover={{ bg: 'gray.50', color: '#396070' }}
            sx={
              item?.flag === selectedItem?.flag
                ? { backgroundColor: 'gray.50', color: '#396070' }
                : {}
            }
          >
            <Text as="span" mx={4}>
              {item?.flag}
            </Text>
            <Text as="span">{item?.name}</Text>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
