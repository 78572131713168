import axios from 'axios';

import { MAPBOX_ACCESS_TOKEN, MAPBOX_API_URL } from './constants';
import constructLocationFromFeature from './constructLocationFromFeature';
import getLocationFromCoords from './getLocationFromCoords';
import type { CurrentLocation, MapBoxResponse } from './types';

import sentryClient from '../infra/sentryClient';

/**
 * @param currentLocation *Optional* Bias the response to favor results that are closer to this location.
 */
export default async function getLocationFromAddress(
  address: string,
  currentLocation?: Pick<CurrentLocation, 'latitude' | 'longitude'>,
): Promise<CurrentLocation | undefined> {
  try {
    if (!address) throw new Error('No address provided');

    const proximityParam =
      currentLocation?.latitude || currentLocation?.longitude
        ? `&proximity=${currentLocation.longitude || 0},${
            currentLocation.latitude || 0
          }`
        : '';
    const addressParam = encodeURIComponent(
      address.replaceAll(';', '').slice(0, 256),
    );

    const res = await axios.get<MapBoxResponse>(
      `${MAPBOX_API_URL}/forward?q=${addressParam}&access_token=${MAPBOX_ACCESS_TOKEN}&limit=1${proximityParam}`,
    );

    const feature = res?.data?.features?.[0];
    if (!feature) throw new Error('No feature found');

    // If no postal code is found, get a location with one
    if (!feature?.properties?.context?.postcode?.name) {
      const locationWithPostalCode = await getLocationFromCoords({
        address,
        latitude: feature.properties?.coordinates?.latitude,
        longitude: feature.properties?.coordinates?.longitude,
      });

      return (
        locationWithPostalCode || constructLocationFromFeature(feature, address)
      );
    }

    return constructLocationFromFeature(feature, address);
  } catch (err) {
    sentryClient.captureException(err, {
      context: {
        name: 'GetLocationFromAddress',
        data: { address, currentLocation },
      }
    });
    return undefined;
  }
}
