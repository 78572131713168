import { Stack } from '@chakra-ui/react';
import { useDebouncedCallback } from '@react-hookz/web';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Address from './Address/Address';
import LessonLength from './BookingWidgetLessonLength';
import Participants from './BookingWidgetParticipants';
import SchedulePreference from './BookingWidgetSchedulePreference';
import Calendar from './Calendar';
import Confirm from './Confirm/Confirm';
import DayTimes from './DayTimes';
import ExposedTimes from './ExposedTimes';
import Packages from './Packages';

import { useModal } from '../../context/ModalContext';
import useIsMobile from '../../hooks/useIsMobile';
import useLessonLength from '../../hooks/useLessonLength';
import useSelectedLocation from '../../hooks/useSelectedLocation';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  selectScrollPosition,
  setScrollPosition,
} from '../../state/slices/checkoutSlice';
import {
  selectDateTime,
  selectIsAtHome,
  selectIsRecurring,
  selectParticipants,
  selectPkg,
} from '../../state/slices/filtersSlice';
import capturePostHogEvent from '../../utils/capturePostHogEvent';
import { BookingStep, SelectedListing } from '../../utils/types';
import getFirstName from '../../utils/user';

interface Params {
  skill?: string;
  slug?: string;
  step?: BookingStep;
}

interface Props {
  columnWidth?: number;
  isRebooking?: boolean;
  listing: SelectedListing;
  listingUrl: string;
}

export default function BookingWidget({
  columnWidth,
  listing,
  listingUrl,
  isRebooking,
}: Props) {
  const dispatch = useAppDispatch();
  const params: Params = useParams();
  const { isMobile } = useIsMobile();
  const { handleOpen } = useModal();
  const scrollPosition = useSelector(selectScrollPosition);
  const [selectedLocation] = useSelectedLocation();
  const participants = useAppSelector(selectParticipants);
  const dateTime = useAppSelector(selectDateTime);
  const { lessonLength } = useLessonLength();
  const isAtHome = useAppSelector(selectIsAtHome);
  const isRecurring = useAppSelector(selectIsRecurring);
  const pkg = useAppSelector(selectPkg);
  const stackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    capturePostHogEvent('viewBookingWidgetStep', {
      dateTime,
      isAtHome,
      isMobile,
      isRebooking,
      isRecurring,
      lessonLength,
      listingSlug: listing?.slug,
      participants,
      pkg,
      selectedLocation: selectedLocation?.id,
      step: params.step,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.step]);

  const Content = useMemo(() => {
    switch (true) {
      case params.step === BookingStep.Participants && !!listing?.id:
        return (
          <Participants
            listing={listing}
            nextUrl={
              isRebooking
                ? `${listingUrl}/${
                    isAtHome ? BookingStep.Address : BookingStep.Confirm
                  }`
                : `${listingUrl}/${BookingStep.LessonLength}`
            }
          />
        );

      case params.step === BookingStep.LessonLength && !!listing?.id:
        return (
          <LessonLength
            listing={listing}
            nextUrl={
              isRebooking
                ? `${listingUrl}/${
                    isAtHome ? BookingStep.Address : BookingStep.Confirm
                  }`
                : `${listingUrl}/${BookingStep.SchedulePreference}`
            }
          />
        );

      case params.step === BookingStep.SchedulePreference && !!listing?.id:
        return <SchedulePreference listing={listing} listingUrl={listingUrl} />;

      case params.step === BookingStep.Exposed && !!listing?.id:
        return (
          <ExposedTimes
            listingUrl={listingUrl}
            listing={listing}
            nextUrl={
              isRebooking || isRecurring
                ? `${listingUrl}/${
                    isAtHome ? BookingStep.Address : BookingStep.Confirm
                  }`
                : `${listingUrl}/${BookingStep.Packages}`
            }
            onOpen={() => handleOpen('requestTime', listing?.proName)}
            proName={getFirstName(listing?.proName)}
            variant={isRebooking ? 'rebook' : 'normal'}
          />
        );

      case params.step === BookingStep.Datepicker && !!listing?.id:
        return (
          <Calendar
            listingId={listing?.id}
            listingUrl={listingUrl}
            onOpen={() => handleOpen('requestTime', listing?.proName)}
          />
        );

      case params.step === BookingStep.Times && !!listing?.id:
        return (
          <DayTimes
            listing={listing}
            nextUrl={
              isRebooking || isRecurring
                ? `${listingUrl}/${
                    isAtHome ? BookingStep.Address : BookingStep.Confirm
                  }`
                : `${listingUrl}/${BookingStep.Packages}`
            }
            onOpen={() => handleOpen('requestTime', listing?.proName)}
            variant="normal"
          />
        );

      case params.step === BookingStep.Packages:
        return <Packages listing={listing} listingUrl={listingUrl} />;

      case params.step === BookingStep.Address:
        return <Address listing={listing} />;

      case params.step === BookingStep.Confirm:
        return <Confirm listing={listing} />;

      default:
        return <Confirm listing={listing} />;
    }
  }, [
    handleOpen,
    isAtHome,
    isRebooking,
    isRecurring,
    listing,
    listingUrl,
    params.step,
  ]);

  const handleScrollOnConfirm = useDebouncedCallback(
    (e: any) => {
      if (params.step !== BookingStep.Confirm) return;
      dispatch(setScrollPosition(e?.target?.scrollTop || 0));
    },
    [dispatch, params.step],
    250,
  );

  const resumeScrollPosition = useCallback(() => {
    if (!stackRef.current || !scrollPosition) return;
    stackRef.current.scrollTop = scrollPosition;
  }, [scrollPosition]);

  useEffect(() => {
    if (params.step === BookingStep.Confirm) resumeScrollPosition();
  }, [params.step, resumeScrollPosition]);

  return (
    <motion.div
      initial={{
        opacity: 0,
        translateX: '100%',
        transformOrigin: '100% 50%',
      }}
      animate={{
        opacity: 1,
        translateX: 0,
        transformOrigin: '100% 50%',
      }}
      style={{
        flexGrow: 1,
        overflow: 'auto',
      }}
      transition={{ duration: 0.35, ease: 'easeOut' }}
    >
      <Stack
        h="full"
        bg="bg-surface"
        spacing={0}
        flexGrow={1}
        w={columnWidth - 1 || 'full'}
        position="relative"
        overflow="auto"
        style={{
          scrollbarWidth: 'none',
        }}
        ref={stackRef}
        onScroll={handleScrollOnConfirm}
      >
        {Content}
      </Stack>
    </motion.div>
  );
}
