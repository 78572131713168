import { ModalContent } from '@chakra-ui/react';

import CoachGuaranteeContent from './CoachGuaranteeContent';

import BaseModal from '../../BaseModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  proName: string;
}

export default function CoachGuaranteeDesktop({
  isOpen,
  onClose,
  proName,
}: Props) {
  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <ModalContent
        alignItems="center"
        borderRadius="2xl"
        justifyContent="center"
        overflow="hidden"
        p={{ base: '2', md: '4' }}
        w={479}
      >
        <CoachGuaranteeContent onClose={onClose} proName={proName} />
      </ModalContent>
    </BaseModal>
  );
}
