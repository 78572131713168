import { useMemo } from 'react';
import { MapProps } from 'react-map-gl';
import { LngLatBoundsLike } from 'react-map-gl/dist/esm/types';

import useIsAtHomeOrListingExistsWithNoLocations from './useIsAtHomeOrListingExistsWithNoLocations';

import { ListingInBoundingBox } from '../api';
import { useAppSelector } from '../state/hooks';
import { selectMapLocation } from '../state/slices/locationsSlice';

const MIN_LAT = -90;
const MAX_LAT = 90;
const MIN_LNG = -180;
const MAX_LNG = 180;

type MapBoundsCoordinates = {
  minLat: number;
  maxLat: number;
  minLng: number;
  maxLng: number;
};

type MapInitialViewState = {
  center: { latitude: number; longitude: number };
  initialViewState: MapProps['initialViewState'];
};

type Props = {
  listing:
    | undefined
    | Partial<
        Pick<
          ListingInBoundingBox,
          'travelAddressLatitude' | 'travelAddressLongitude' | 'travelMiles'
        > & {
          locations: Omit<ListingInBoundingBox['locations'][0], '__typename'>[];
        }
      >;
};

/**
 * Initial view state for map includes values for:
 * - bounds
 * - center
 * - padding
 * - zoom
 */
export default function useMapInitialViewState({
  listing,
}: Props): MapInitialViewState {
  const mapLocation = useAppSelector(selectMapLocation);
  const isAtHomeOrListingExistsWithNoLocations =
    useIsAtHomeOrListingExistsWithNoLocations();

  const mapInitialViewState: MapInitialViewState = useMemo(() => {
    const hasRetrievedListing = Boolean(listing?.locations);
    if (!hasRetrievedListing) {
      return {
        center: { latitude: 0, longitude: 0 },
        initialViewState: undefined,
      };
    }

    const getBoundsCoordinates = (): MapBoundsCoordinates => {
      let boundsCoordinates: MapBoundsCoordinates;

      // If at home or no locations, bounding box will be centered on travel address and extend `travelMiles` out
      if (isAtHomeOrListingExistsWithNoLocations) {
        // Convert travel miles to kilometers
        const radiusKm = (listing.travelMiles || 0) * 1.60934;

        // Earth's radius in kilometers
        const R = 6371.0;

        // Convert travel address latitude to radians
        const latRad = (listing.travelAddressLatitude || 0) * (Math.PI / 180);

        // Latitude bounds
        const maxLat =
          (listing.travelAddressLatitude || 0) +
          (radiusKm / R) * (180 / Math.PI);
        const minLat =
          (listing.travelAddressLatitude || 0) -
          (radiusKm / R) * (180 / Math.PI);

        // Longitude bounds (adjust for latitude)
        const maxLng =
          (listing.travelAddressLongitude || 0) +
          (radiusKm / (R * Math.cos(latRad))) * (180 / Math.PI);
        const minLng =
          (listing.travelAddressLongitude || 0) -
          (radiusKm / (R * Math.cos(latRad))) * (180 / Math.PI);

        boundsCoordinates = {
          minLat,
          maxLat,
          minLng,
          maxLng,
        };
      }
      // Else, bounding box will be the mins/maxes of the listing locations
      else {
        boundsCoordinates = listing.locations.reduce(
          (total, currLocation) => ({
            minLat: Math.max(
              Math.min(currLocation.latitude, Math.min(total.minLat, MAX_LAT)),
              MIN_LAT,
            ),
            minLng: Math.max(
              Math.min(currLocation.longitude, Math.min(total.minLng, MAX_LNG)),
              MIN_LNG,
            ),
            maxLat: Math.min(
              Math.max(currLocation.latitude, Math.max(total.maxLat, MIN_LAT)),
              MAX_LAT,
            ),
            maxLng: Math.min(
              Math.max(currLocation.longitude, Math.max(total.maxLng, MIN_LNG)),
              MAX_LNG,
            ),
          }),
          {
            minLat: MAX_LAT,
            minLng: MAX_LNG,
            maxLat: MIN_LAT,
            maxLng: MIN_LNG,
          },
        );
      }

      return boundsCoordinates;
    };

    // bounds
    const boundsCoordinates: MapBoundsCoordinates = getBoundsCoordinates();
    const bounds: LngLatBoundsLike = [
      { lat: boundsCoordinates.minLat, lng: boundsCoordinates.minLng },
      { lat: boundsCoordinates.maxLat, lng: boundsCoordinates.maxLng },
    ];

    // center
    let center: MapInitialViewState['center'] = {
      latitude: (boundsCoordinates.minLat + boundsCoordinates.maxLat) / 2,
      longitude: (boundsCoordinates.minLng + boundsCoordinates.maxLng) / 2,
    };
    if (!listing.locations.length) {
      center =
        mapLocation?.latitude || mapLocation?.longitude
          ? {
              latitude: mapLocation.latitude || 0,
              longitude: mapLocation.longitude || 0,
            }
          : {
              latitude: listing.travelAddressLatitude || 0,
              longitude: listing.travelAddressLongitude || 0,
            };
    }

    // initial state
    const initialViewState: MapInitialViewState['initialViewState'] = {
      bounds,
      fitBoundsOptions: {
        padding: 32,
        minZoom: 7.5,
        maxZoom: isAtHomeOrListingExistsWithNoLocations ? 7.5 : 8.5,
      },
    };

    return { center, initialViewState };
  }, [isAtHomeOrListingExistsWithNoLocations, listing, mapLocation]);

  return mapInitialViewState;
}
