import { useEffect, useMemo } from 'react';

import { useBadgeGroupsQuery } from '../api';
import type { SelectedBadgeGroup } from '../interfaces';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import {
  removeSelectedBadges,
  selectSelectedBadges,
  selectSelectedSkill,
} from '../state/slices/filtersSlice';
import {
  selectAllListingsInBoundingBox,
  selectListingsInBoundingBox,
} from '../state/slices/listingsSlice';

export default function useExperienceBadges(): {
  experienceBadgeGroups: SelectedBadgeGroup[];
  experienceBadgeGroupsInAllListings: SelectedBadgeGroup[];
  loading: boolean;
} {
  const selectedSkill = useAppSelector(selectSelectedSkill);
  const listingsInBoundingBox = useAppSelector(selectListingsInBoundingBox);
  const selectedBadges = useAppSelector(selectSelectedBadges);
  const dispatch = useAppDispatch();
  const allListingsInBoundingBox = useAppSelector(
    selectAllListingsInBoundingBox,
  );

  const { data, loading } = useBadgeGroupsQuery({
    variables: {
      where: {
        AND: [
          {
            badges: {
              some: { isExperience: { equals: true } },
            },
          },
          {
            OR: [
              {
                skillId: { equals: selectedSkill?.id },
              },
              {
                skillId: { equals: null },
              },
            ],
          },
        ],
      },
    },
  });

  const experienceBadgeGroups = useMemo(() => {
    const badgeGroups = data?.findManyBadgeGroup || [];

    return badgeGroups.map((badgeGroup) => ({
      ...badgeGroup,
      badges: badgeGroup.badges.filter((badge) => badge.isExperience),
    }));
  }, [data?.findManyBadgeGroup]);

  const experienceBadgeGroupsInListings = useMemo(
    () =>
      experienceBadgeGroups.map((badgeGroup) => ({
        ...badgeGroup,
        badges: badgeGroup.badges.filter((badge) =>
          listingsInBoundingBox.some((l) =>
            l.badges.some((b) => b.id === badge.id),
          ),
        ),
      })),
    [experienceBadgeGroups, listingsInBoundingBox],
  );

  const experienceBadgeGroupsInAllListings = useMemo(
    () =>
      experienceBadgeGroups.map((badgeGroup) => ({
        ...badgeGroup,
        badges: badgeGroup.badges.filter((badge) =>
          allListingsInBoundingBox.some((l) =>
            l.badges.some((b) => b.id === badge.id),
          ),
        ),
      })),
    [experienceBadgeGroups, allListingsInBoundingBox],
  );

  // Do there exist selected experience badges that are not in any of the listing results?
  // If so, de-select them.
  useEffect(() => {
    if (!experienceBadgeGroupsInListings.length || !selectedBadges.length) {
      return;
    }

    const deselectedBadges = selectedBadges.filter(
      (selectedBadge) =>
        selectedBadge.isExperience &&
        !experienceBadgeGroupsInListings.some((expBadgeGroup) =>
          expBadgeGroup.badges.some((b) => b.id === selectedBadge.id),
        ),
    );

    if (!deselectedBadges.length) return;

    dispatch(removeSelectedBadges(deselectedBadges));
  }, [dispatch, experienceBadgeGroupsInListings, selectedBadges]);

  return {
    experienceBadgeGroups,
    experienceBadgeGroupsInAllListings,
    loading,
  };
}
