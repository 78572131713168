import { Review } from '../api';

export default function calculateRating(
  reviews: { rating?: Review['rating'] }[] = [],
): string {
  try {
    if (!reviews.length) {
      return '0';
    }

    const sum = reviews.reduce(
      (acc, { rating }) => acc + (Number(rating) || 0),
      0,
    );

    return Number(sum / reviews.length) === 5
      ? '5.00'
      : Number(sum / reviews.length)?.toFixed(2) || '0';
  } catch (error) {
    return '0';
  }
}
