import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiOutlineX } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { PricePayload } from '../../../../../api';
import BadgeTag from '../../../../../components/BadgeTag';
import LessonLengthPicker from '../../../../../components/LessonLengthPicker';
import PackagePrice from '../../../../../components/PackagePrice';
import ParticipantPicker from '../../../../../components/ParticipantPicker';
import RecurringPrice from '../../../../../components/RecurringPrice';
import ReferralRewardPriceTag from '../../../../../components/ReferralRewardPriceTag';
import useLessonLength from '../../../../../hooks/useLessonLength';
import useListingSearchParams from '../../../../../hooks/useListingSearchParams';
import usePickleheadsDiscount from '../../../../../hooks/usePickleheadsDiscount';
import usePricing from '../../../../../hooks/usePricing';
import useStudentReferral from '../../../../../hooks/useStudentReferral';
import { useAppSelector } from '../../../../../state/hooks';
import {
  selectIsRecurring,
  selectParticipants,
  selectPkg,
} from '../../../../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../../../utils/capturePostHogEvent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function NumberOfLessonsModal({ isOpen, onClose }: Props) {
  const selectedListing = useAppSelector(selectSelectedListing);
  const isRecurring = useAppSelector(selectIsRecurring);
  const participants = useAppSelector(selectParticipants);
  const navigate = useNavigate();
  const {
    getPrices,
    isLoading: isLoadingPrices,
    getSubscriptionPrice,
  } = usePricing();
  const { hasFreeLesson } = useStudentReferral();
  const { isPickleheadsReferral, pickleheadsDiscountAmount } =
    usePickleheadsDiscount();
  const pkg = useAppSelector(selectPkg);
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();
  const { lessonLength } = useLessonLength();

  const [prices, setPrices] = useState<PricePayload[] | null>(null);
  const [recurringPrice, setRecurringPrice] = useState<PricePayload | null>(
    null,
  );

  const exclusivePackPricePayload: PricePayload =
    selectedListing?.exclusivePack &&
    prices?.find((node) => node?.package === selectedListing.exclusivePack);

  useEffect(() => {
    if (!selectedListing?.id) return;
    getPrices({ listingId: selectedListing?.id }).then(setPrices);
    getSubscriptionPrice({ listingId: selectedListing?.id }).then(
      setRecurringPrice,
    );
  }, [selectedListing?.id, getPrices, getSubscriptionPrice]);

  const label = useMemo(
    () =>
      isRecurring ? 'weekly recurring' : `${pkg} lesson${pkg > 1 ? 's' : ''}`,
    [isRecurring, pkg],
  );

  useEffect(() => {
    capturePostHogEvent('viewPackages');
  }, []);

  const updateUrl = useCallback(
    ({ packageSize, isRecurring: isRecurringParam }) => {
      navigate(
        `/checkout/${selectedListing?.skill?.slug}/${
          selectedListing?.slug
        }/?${getListingSearchParamsWithPartialInput({
          pkg: packageSize,
          lessonLength,
          participants,
          isRecurring: isRecurringParam,
        })}`,
      );
    },
    [
      getListingSearchParamsWithPartialInput,
      lessonLength,
      navigate,
      participants,
      selectedListing?.skill?.slug,
      selectedListing?.slug,
    ],
  );

  const onClickChoose = useCallback(() => {
    capturePostHogEvent('clickChoosePack', { pkg });

    updateUrl({ packageSize: pkg, isRecurring });
    onClose();
  }, [isRecurring, onClose, pkg, updateUrl]);

  return (
    <Modal
      isCentered
      scrollBehavior="inside"
      motionPreset="slideInBottom"
      onOverlayClick={onClose}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        position="relative"
        borderRadius="24px"
        maxH="max"
        maxW="lg"
      >
        <VStack p="6">
          <HStack>
            <Box
              as="nav"
              position="absolute"
              top="4"
              left="4"
              borderRadius="full"
              bg="bg-surface"
              borderColor="border-default"
            >
              <IconButton
                isRound
                variant="ghost"
                aria-label="Back"
                size="lg"
                onClick={onClose}
                icon={<HiOutlineX color="#2D3748" size="24px" />}
              />
            </Box>
            <Heading variant="h4" flex="1" textAlign="center">
              Pick your lesson pack
            </Heading>
          </HStack>

          <Flex
            flexDir="column"
            flexGrow={1}
            w="full"
            overflow="hidden"
            columnGap="4"
            mt="8"
          >
            <HStack
              py="2"
              alignItems="space-between"
              width="full"
              columnGap="4"
            >
              <LessonLengthPicker />
              <ParticipantPicker />
            </HStack>

            <Box w="full" mt="4">
              <Box borderRadius="2xl" borderWidth={0} w="full">
                {exclusivePackPricePayload ? (
                  <PackagePrice
                    packageSize={selectedListing.exclusivePack}
                    participants={participants}
                    price={exclusivePackPricePayload}
                    isLoading={isLoadingPrices}
                    isExclusivePack
                    onSelectPackage={updateUrl}
                  />
                ) : (
                  <>
                    <PackagePrice
                      packageSize={1}
                      participants={participants}
                      price={prices?.find((node) => node?.package === 1)}
                      isLoading={isLoadingPrices}
                      isTop
                      onSelectPackage={updateUrl}
                    />
                    <PackagePrice
                      packageSize={3}
                      participants={participants}
                      price={prices?.find((node) => node?.package === 3)}
                      isLoading={isLoadingPrices}
                      isMiddle
                      onSelectPackage={updateUrl}
                    />
                    <PackagePrice
                      packageSize={10}
                      participants={participants}
                      price={prices?.find((node) => node?.package === 10)}
                      isLoading={isLoadingPrices}
                      isMiddle
                      onSelectPackage={updateUrl}
                    />
                    <RecurringPrice
                      participants={participants}
                      price={recurringPrice}
                      isLoading={isLoadingPrices}
                      isBottom
                      onSelectPackage={updateUrl}
                    />
                  </>
                )}
              </Box>

              {exclusivePackPricePayload ? (
                <Text mt="4" textAlign="center" textStyle="caption">
                  This is the only option this coach offers.
                </Text>
              ) : null}
            </Box>
          </Flex>
        </VStack>
        <Box p="6" borderTopWidth="1px">
          {hasFreeLesson && (
            <HStack justifyContent="center" pb="4">
              <ReferralRewardPriceTag />
            </HStack>
          )}
          {isPickleheadsReferral && (
            <HStack pb="4">
              <BadgeTag
                justifyContent="center"
                labelTextTransform="none"
                bgColor="#E9D8FD"
                borderRadius="lg"
                label={`$${pickleheadsDiscountAmount} Pickleheads discount applied at checkout!`}
                labelColor="#553C9A"
                fontWeight="extrabold"
              />
            </HStack>
          )}
          <Button
            w="full"
            h="56px"
            rounded="full"
            variant="primary"
            onClick={onClickChoose}
            fontSize="16px"
            fontWeight="800"
          >
            Choose {label}
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
}
