import { Avatar, type AvatarProps } from '@chakra-ui/react';

import useTMTImage from '../../hooks/useTMTImage';

type AvatarSize = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

interface Props
  extends Omit<AvatarProps, 'h' | 'height' | 'size' | 'w' | 'width'> {
  h?: number;
  size?: AvatarSize;
  w?: number;
}

function getNumberFromSize(size: AvatarSize): number {
  switch (size) {
    case '2xs':
      return 16;
    case 'xs':
      return 24;
    case 'sm':
      return 32;
    case 'lg':
      return 64;
    case 'xl':
      return 96;
    case '2xl':
      return 128;
    case 'md':
    default:
      return 48;
  }
}

/**
 * Use ImageKit for an Avatar's `src`
 */
export default function TMTAvatar({ h, src, size = 'md', w, ...rest }: Props) {
  const height = (h || getNumberFromSize(size)) * 2 || 48 * 2;
  const width = (w || getNumberFromSize(size)) * 2 || 48 * 2;

  const { imageSrc } = useTMTImage({
    isAvatar: true,
    height,
    src,
    width,
  });

  return (
    <Avatar
      size={size}
      src={imageSrc}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}
