import { Box, Flex, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import useBookingWidgetViewport from '../../../hooks/useBookingWidgetSize';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import MediaMiniMap from '../../../screens/ListingDetails/components/MediaMiniMap';
import { useAppSelector } from '../../../state/hooks';
import {
  selectIsAtHome,
  selectSelectedSkill,
} from '../../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../../state/slices/listingsSlice';
import { selectSelectedLocation } from '../../../state/slices/locationsSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import getLocationTypeBySkillSlug from '../../../utils/getLocationTypeBySkillSlug';
import PosthogEvent from '../../../utils/posthogEvents';

export default function Location(): JSX.Element {
  const navigate = useNavigate();
  const isAtHome = useAppSelector(selectIsAtHome);
  const selectedListing = useAppSelector(selectSelectedListing);
  const { columnWidth } = useBookingWidgetViewport();
  const selectedLocation = useAppSelector(selectSelectedLocation);
  const selectedSkill = useAppSelector(selectSelectedSkill);
  const locationType = getLocationTypeBySkillSlug(selectedSkill?.slug, false);
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const paddingX = 48;
  const mapWidth = columnWidth - paddingX;

  const handleEdit = () => {
    capturePostHogEvent(PosthogEvent.ClickedEditLocation, {
      ...selectedListing,
      selectedLocation,
    });

    navigate(
      `/choose-your-location/${
        selectedListing?.slug
      }?${getListingSearchParamsWithPartialInput()}`,
    );
  };

  return (
    <VStack>
      <HStack justifyContent="space-between" width="full">
        <VStack alignItems="flex-start" justifyContent="center">
          <Heading variant="h6">LOCATION</Heading>
          <Text color="slate.500">
            {isAtHome
              ? `At your favorite ${locationType}`
              : selectedLocation?.placeName}
          </Text>
        </VStack>
        <Text
          _hover={{ cursor: 'pointer' }}
          textStyle="link"
          color="slate.500"
          onClick={handleEdit}
        >
          Edit
        </Text>
      </HStack>
      <Flex alignItems="center" flexDir="column">
        <Box maxH={{ base: '100dvh', md: '70dvh' }} w={mapWidth}>
          <MediaMiniMap
            height={(columnWidth * 100) / 294}
            listing={selectedListing}
            width={mapWidth}
            key={selectedListing?.id}
            parentComponent='CheckoutWidget'
          />
        </Box>
      </Flex>
    </VStack>
  );
}
