/* eslint-disable react/jsx-props-no-spreading */
import { BoxProps, Flex, FlexProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

import useIsSafari from '../../hooks/useIsSafari';
import Footer from '../Footer';
import Header from '../Header';
import { Show } from '../Show';

interface Props extends BoxProps {
  expandedAskAQuestionButton?: boolean;
  heading?: string;
  hideCoachesLocationButton?: boolean;
  iconBorderColor?: string;
  isLoading?: boolean;
  logoColor?: string;
  showHeader?: boolean;
  showFooter?: boolean;
  footerProps?: FlexProps;
  backLabel?: string;
  onBackPress?: () => void;
  children: ReactNode;
  showMobileLogo?: boolean;
  showHeaderMenu?: boolean;
}

export default function Page({
  bg,
  borderBottomColor = 'border',
  expandedAskAQuestionButton = false,
  heading = '',
  hideCoachesLocationButton = false,
  iconBorderColor,
  isLoading,
  logoColor,
  showHeader = true,
  showFooter = true,
  children,
  footerProps = {},
  backLabel,
  onBackPress,
  showMobileLogo = false,
  showHeaderMenu = true,
  ...rest
}: Props) {
  const { isSafari } = useIsSafari();

  return (
    <Flex
      align={{ base: 'flex-start', md: 'center' }}
      bg={bg || 'bg-surface'}
      className="full"
      direction="column"
      flex={1}
      minH={isSafari ? undefined : '100dvh'}
      mx="auto"
    >
      <Show condition={showHeader}>
        <Header
          backLabel={backLabel}
          borderColor={borderBottomColor}
          expandedAskAQuestionButton={expandedAskAQuestionButton}
          heading={heading}
          hideCoachesLocationButton={hideCoachesLocationButton}
          iconBorderColor={iconBorderColor}
          isLoading={isLoading}
          logoColor={logoColor}
          onBackPress={onBackPress}
          showMobileLogo={showMobileLogo}
          showHeaderMenu={showHeaderMenu}
        />
      </Show>

      <Flex
        align="center"
        direction="column"
        flex="1"
        mx="auto"
        w="full"
        {...rest}
      >
        {children}
      </Flex>

      <Show condition={showFooter}>
        <Footer {...footerProps} />
      </Show>
    </Flex>
  );
}
