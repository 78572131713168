import { Elements as StripeElements } from '@stripe/react-stripe-js';
import { ReactNode } from 'react';

import loadStripePromise from '../../../../../utils/stripe';

type Props = {
  children?: ReactNode;
};

const stripePromise = loadStripePromise();

export default function PaymentProvider({ children }: Props) {
  return <StripeElements stripe={stripePromise}>{children}</StripeElements>;
}
