import {
  Button,
  Heading,
  HStack,
  Icon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { HiCheck, HiSparkles } from 'react-icons/hi';
import 'yup-phone-lite';

import useStudentReferral from '../../hooks/useStudentReferral';
import BaseModal from '../BaseModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function ReferralRewardModal({ isOpen, onClose }: Props) {
  const iconColor = useColorModeValue('slate.500', 'white');
  const { redeemed, redeemReferral } = useStudentReferral();

  const ctaLabel = redeemed ? 'Free lesson redeemed!' : 'Redeem my free lesson';
  const ctaBgColor = redeemed ? 'green.500' : 'bg-accent-subtle';
  const ctaOnHoverBgColor = redeemed ? 'green.500' : 'bg-accent';

  return (
    <BaseModal
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      motionPreset="slideInBottom"
    >
      <ModalContent
        display="flex"
        justifyContent="space-between"
        p={{ base: '4', md: '10' }}
        position={{ base: 'fixed', md: 'unset' }}
        bottom="0px"
        mb={{ base: '0', md: 'auto' }}
        borderRadius={{ base: '1.75rem 1.75rem 0px 0px', md: '2xl' }}
      >
        <ModalHeader p={1}>
          <VStack align="center" spacing="6" w="full">
            <Icon as={HiSparkles} color={iconColor} fontSize="40px" />
            <Heading variant="h3" textAlign="center">
              {`You're getting a free extra lesson!`}
            </Heading>
            <HStack
              px="4"
              py="2"
              bg="#EDF2F7"
              borderRadius="8px"
              textAlign="center"
            >
              <Icon as={HiCheck} color={iconColor} fontSize="18px" />
              <Text
                color="slate.500"
                textAlign="center"
                textStyle="overlineBold"
              >
                Automatically applied at checkout
              </Text>
            </HStack>
            <Text
              textStyle="small"
              textAlign="center"
              color="muted"
              fontSize="16px"
            >
              {`Since you're booking through a referral link, you're getting one extra lesson added to your purchase. No strings attached!`}
            </Text>
          </VStack>
        </ModalHeader>
        <ModalFooter pt="8" justifyContent="center">
          <VStack spacing="4">
            <Button
              size="xl"
              type="submit"
              backgroundColor={ctaBgColor}
              disabled={redeemed}
              cursor={redeemed ? 'default' : 'pointer'}
              _hover={{ backgroundColor: ctaOnHoverBgColor }}
              onClick={redeemReferral}
            >
              {redeemed && (
                <Icon as={HiCheck} color="white" fontSize="22px" mr="2" />
              )}
              {ctaLabel}
            </Button>
            {redeemed && (
              <Button size="sm" variant="link" onClick={onClose}>
                Close this window
              </Button>
            )}
          </VStack>
        </ModalFooter>
      </ModalContent>
    </BaseModal>
  );
}
