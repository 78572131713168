import { Box, Flex, Text } from '@chakra-ui/react';

import ProLocationItem from './components/ProLocationItem';

import useIsMobile from '../../hooks/useIsMobile';
import { SelectedListing, SelectedLocation } from '../../utils/types';
import getFirstName from '../../utils/user';
import TMTAvatar from '../TMTAvatar';

type Props = {
  listing: SelectedListing;
  onClose?(): void;
};

export default function ProLocationsList({
  listing,
  onClose,
}: Props): JSX.Element {
  const { isMobile } = useIsMobile();

  const noOfLocationsTaught = listing?.locations?.length || 0;
  return (
    <Flex flexDir="column">
      <Flex
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
        mb="2"
        px="4"
      >
        <Flex flexDir="column" mr="2">
          <Text textStyle="smallBold" noOfLines={1}>
            {getFirstName(listing?.proName)} teaches at&nbsp;
            {noOfLocationsTaught}&nbsp;
            {noOfLocationsTaught === 1 ? 'location' : 'locations'}
          </Text>
          <Text textStyle="caption" fontSize="12px" noOfLines={1}>
            Or you can request a location later
          </Text>
        </Flex>

        <TMTAvatar name={listing?.proName} src={listing?.proAvatar} size="md" />
      </Flex>

      <Box maxH="200px" overflowY="scroll" px="4">
        {listing?.locations?.map((location) => (
          <ProLocationItem
            key={location.id}
            isMobile={isMobile}
            listing={listing}
            location={location as SelectedLocation}
            onClose={onClose}
          />
        ))}
      </Box>
    </Flex>
  );
}
