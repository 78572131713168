import useIsMapView from './useIsMapView';
import useIsMobile from './useIsMobile';

// 24px = chakra 6 padding
const mainHeaderHeight = 115 + 24 + 24;
const mainHeaderHeightMobile = 97 + 24 + 24 + 50;
const mainHeaderHeightMobileMap = 97 + 24 + 24 + 50 - (35 + 16); // minus (filter bar + vertical margin)

export default function useHeaderHeight(): { headerHeight: number } {
  const { isMobile } = useIsMobile();
  const { isMapView } = useIsMapView();

  // eslint-disable-next-line no-nested-ternary
  const headerHeight = isMobile
    ? isMapView
      ? mainHeaderHeightMobileMap
      : mainHeaderHeightMobile
    : mainHeaderHeight;

  return { headerHeight };
}
