import { Button, Divider, Stack, Text } from '@chakra-ui/react';

interface Props {
  hasDivider?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick(): void;
  time: string;
}

export default function ExposedTime({
  hasDivider = true,
  isDisabled,
  isLoading = false,
  onClick,
  time,
}: Props) {
  if (!time) {
    return null;
  }

  return (
    <>
      <Stack py="2" px="6">
        <Stack direction="row" align="center" justify="space-between">
          <Stack spacing={0}>
            <Text
              color={isDisabled ? 'muted' : 'defaultText'}
              textDecoration={isDisabled ? 'line-through' : 'none'}
            >
              {time}
            </Text>
          </Stack>

          <Button
            w="99px"
            h="37px"
            variant="solid"
            size="sm"
            colorScheme={isDisabled ? 'gray' : 'blue'}
            isDisabled={isDisabled || isLoading}
            fontWeight={isDisabled ? '800' : null}
            isLoading={isLoading}
            onClick={onClick}
          >
            {isDisabled ? 'Booked' : 'Choose'}
          </Button>
        </Stack>
      </Stack>

      <Divider opacity={hasDivider ? 1 : 0} color="gray.300" />
    </>
  );
}
