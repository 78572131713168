import { configureStore } from '@reduxjs/toolkit';

import appSlice from './slices/appSlice';
import bookingSlice from './slices/bookingSlice';
import checkoutSlice from './slices/checkoutSlice';
import filtersSlice from './slices/filtersSlice';
import listingsSlice from './slices/listingsSlice';
import locationsSlice from './slices/locationsSlice';
import mapSlice from './slices/mapSlice';
import sortingSlice from './slices/sortingSlice';

const store = configureStore({
  reducer: {
    app: appSlice,
    booking: bookingSlice,
    filters: filtersSlice,
    listings: listingsSlice,
    locations: locationsSlice,
    sorting: sortingSlice,
    checkout: checkoutSlice,
    map: mapSlice
  },
});

export default store;
