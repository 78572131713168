import { useDisclosure } from '@chakra-ui/react';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import LightBox from '../../../components/LightBox';

export interface ReviewMediaGalleryRef {
  open: (media: { src: string; id: string }[], defaultIndex: number) => void;
}

type State = {
  media: { src: string; id: string }[];
  defaultIndex: number;
};

const DEFAULT_PARAMS: State = {
  media: [],
  defaultIndex: 0,
};

const ReviewMediaGallery = forwardRef<ReviewMediaGalleryRef, {}>((_, ref) => {
  const gallery = useDisclosure();
  const [params, setParams] = useState(DEFAULT_PARAMS);

  useImperativeHandle(
    ref,
    () => ({
      open: (media, defaultIndex = 0) => {
        setParams({
          defaultIndex,
          media,
        });
        gallery.onOpen();
      },
    }),
    [setParams, gallery],
  );

  useEffect(() => {
    if (gallery.isOpen) return;

    setParams({
      defaultIndex: 0,
      media: [],
    });
  }, [gallery.isOpen]);

  return (
    <LightBox
      defaultIndex={params.defaultIndex}
      isOpen={gallery.isOpen}
      media={params.media}
      onClose={gallery.onClose}
    />
  );
});

export default ReviewMediaGallery;
