import { createContext, useContext, useMemo, useState } from 'react';

type HoverType = 'listing' | 'location' | 'pro';

export interface State {
  type: HoverType;
  id: string;
}

interface IHoverContext {
  isHovering: boolean;
  hoverState: State;
  onMouseEnter: (type: HoverType, id: string) => void;
  onMouseLeave: () => void;
}

export const HoverContext = createContext<IHoverContext>({} as IHoverContext);

export function HoverProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<State>({ type: null, id: null });

  const value = useMemo(() => {
    const onMouseEnter = (incomingType: HoverType | null, id?: string) => {
      setState({ type: incomingType, id });
    };

    const onMouseLeave = () => {
      setState({ type: null, id: null });
    };

    return {
      isHovering: !!state?.id,
      hoverState: state,
      onMouseEnter,
      onMouseLeave,
    };
  }, [state]);

  return (
    <HoverContext.Provider value={value}>{children}</HoverContext.Provider>
  );
}

export const useHover = () => useContext(HoverContext);
