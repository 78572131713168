import { Box, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { add, format, set } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  LessonStatus,
  useGetSlotsForDayQuery,
  useScheduleLessonMutation,
} from '../../../api';
import useDispatchDateTime from '../../../hooks/useDispatchDateTime';
import useLessonLength from '../../../hooks/useLessonLength';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import { useAppSelector } from '../../../state/hooks';
import {
  selectDateTime,
  selectIsRecurring,
} from '../../../state/slices/filtersSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import PosthogEvent from '../../../utils/posthogEvents';
import { Listing } from '../../../utils/types';
import ExposedTime from '../../ExposedTime';
import { Show } from '../../Show';
import BookingWidgetBody from '../BookingWidgetBody';
import BookingWidgetFooter from '../BookingWidgetFooter';
import BookingWidgetHeader from '../BookingWidgetHeader';
import ConfirmationSheet from '../ExposedTimes/ConfirmationSheet';

interface Props {
  variant: 'normal' | 'schedule';
  listing: {
    id: Listing['id'];
    slug: Listing['slug'];
    skill?: Pick<Listing['skill'], 'slug'>;
  };
  lessonId?: string;
  nextUrl: string;
  onOpen: () => void;
}

export default function DayTimes({
  variant = 'normal',
  listing,
  lessonId,
  nextUrl,
  onOpen,
}: Props) {
  const { isOpen, onOpen: onSheetOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { lessonLength } = useLessonLength();
  const dateTime = useAppSelector(selectDateTime);
  const isRecurring = useAppSelector(selectIsRecurring);
  const { dispatchDateTime } = useDispatchDateTime();
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const selectedDate = toDate(dateTime ? new Date(dateTime) : new Date(), {
    timeZone: 'UTC',
  });

  const { data, loading: isLoading } = useGetSlotsForDayQuery({
    variables: {
      listingId: listing.id,
      date: selectedDate.toISOString(),
    },
  });
  const [scheduleLesson, { loading: isSubmitting }] =
    useScheduleLessonMutation();

  const [slots, setSlots] = useState([]);

  useEffect(() => {
    if (data?.getDaySlots) {
      setSlots(Object.values(data?.getDaySlots)?.[0] as any);
    }
  }, [data?.getDaySlots]);

  const handleSelect = useCallback(
    async (nextDate?: Date) => {
      dispatchDateTime(nextDate);
      onSheetOpen();
    },
    [dispatchDateTime, onSheetOpen],
  );

  const handleConfirm = useCallback(async () => {
    const listingUrlWithSearchParams = getListingSearchParamsWithPartialInput({
      dateTime,
    });
    const url = `${nextUrl}?${listingUrlWithSearchParams}`;

    if (variant === 'normal') {
      capturePostHogEvent(PosthogEvent.ChooseExposedTime, {
        date: dateTime,
      });

      navigate(url);
      return;
    }

    if (variant === 'schedule') {
      const endTime = !dateTime
        ? undefined
        : add(toDate(dateTime), {
            minutes: lessonLength,
          });

      await scheduleLesson({
        variables: {
          where: {
            id: lessonId,
          },
          data: {
            status: LessonStatus.Scheduled,
            startAt: !dateTime
              ? undefined
              : format(new Date(dateTime), 'yyyy-MM-dd HH:mm:ss'),
            endAt: !endTime
              ? undefined
              : format(endTime, 'yyyy-MM-dd HH:mm:ss'),
            lessonLength,
          },
        },
      });
      navigate(url);
    }
  }, [
    getListingSearchParamsWithPartialInput,
    lessonId,
    navigate,
    nextUrl,
    scheduleLesson,
    variant,
    lessonLength,
    dateTime,
  ]);

  const onConfirmConfirmationSheet = () => {
    onClose();
    setTimeout(() => {
      handleConfirm();
    }, 200);
  };

  return (
    <>
      <BookingWidgetHeader heading={format(selectedDate, 'E, MMMM do')} />

      <BookingWidgetBody h="full" pt="2">
        <Box h="full" pb={{ base: '4', lg: '8' }}>
          <Show condition={isLoading}>
            <Stack h="92vh" align="center" justify="center">
              <Spinner size="lg" />
              <Text>Loading times...</Text>
            </Stack>
          </Show>
          <Show condition={!isLoading}>
            {slots?.map((slot) => {
              const [hours, minutes] = slot?.time?.split(':') ?? ['', ''];
              const startAt = set(selectedDate, {
                hours: parseInt(hours, 10),
                minutes: parseInt(minutes, 10),
              });

              return (
                <ExposedTime
                  key={format(startAt, 'h:mm a')}
                  time={format(startAt, 'h:mm a')}
                  isDisabled={!slot?.available}
                  isLoading={isSubmitting}
                  onClick={() => handleSelect(startAt)}
                />
              );
            })}
          </Show>
        </Box>
      </BookingWidgetBody>

      <BookingWidgetFooter
        isBottomCtaClickable
        bottomCtaLabel="Request a time (92% success)"
        bottomCtaVariant="outline"
        onClick={onOpen}
      />
      <ConfirmationSheet
        listing={listing}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onConfirmConfirmationSheet}
        isRecurring={isRecurring}
      />
    </>
  );
}
