import { useMemo } from 'react';

import useLessonLength from './useLessonLength';

import { ListingInBoundingBox, useGetAvailableListingsByIdQuery } from '../api';
import { useAppSelector } from '../state/hooks';
import {
  FiltersState,
  ScheduleWeekday,
  selectAvailabilityFilter,
} from '../state/slices/filtersSlice';

const HOURS_AM = [6, 7, 8, 9, 10, 11];
const HOURS_PM = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export type AvailabilityTimeOption = { label: string; value: string };

export const AVAILABILITY_OPTIONS_FROM: AvailabilityTimeOption[] =
  HOURS_AM.flatMap((hour) => [
    {
      label: `${hour || 12}:00 am`,
      value: `${String(hour).padStart(2, '0')}:00`,
    },
    {
      label: `${hour || 12}:30 am`,
      value: `${String(hour).padStart(2, '0')}:30`,
    },
  ]).concat(
    HOURS_PM.flatMap((hour) => [
      {
        label: `${hour || 12}:00 pm`,
        value: `${String(hour + 12).padStart(2, '0')}:00`,
      },
      ...(hour === HOURS_PM[HOURS_PM.length - 1] // no 11:30 pm
        ? []
        : [
            {
              label: `${hour || 12}:30 pm`,
              value: `${String(hour + 12).padStart(2, '0')}:30`,
            },
          ]),
    ]),
  );

export const AVAILABILITY_OPTIONS_TO: AvailabilityTimeOption[] = HOURS_AM.slice(
  1,
)
  .flatMap((hour) => [
    ...(hour
      ? [
          {
            label: `${hour || 12}:00 am`,
            value: `${String(hour).padStart(2, '0')}:00`,
          },
          {
            label: `${hour || 12}:30 am`,
            value: `${String(hour).padStart(2, '0')}:30`,
          },
        ]
      : []),
  ])
  .concat(
    HOURS_PM.flatMap((hour) => [
      {
        label: `${hour || 12}:00 pm`,
        value: `${String(hour + 12).padStart(2, '0')}:00`,
      },
      ...(hour === HOURS_PM[HOURS_PM.length - 1] // no 11:30 pm
        ? []
        : [
            {
              label: `${hour || 12}:30 pm`,
              value: `${String(hour + 12).padStart(2, '0')}:30`,
            },
          ]),
    ]),
  );

function canFilterByDateRange(
  availabilityFilter: FiltersState['availabilityFilter'],
): boolean {
  return Boolean(
    availabilityFilter?.to ||
      availabilityFilter?.from ||
      availabilityFilter?.startAt,
  );
}

function canFilterByWeekDays(
  availabilityFilter: FiltersState['availabilityFilter'],
): boolean {
  return Boolean(availabilityFilter?.to || availabilityFilter?.from);
}

const FULL_WEEK_LENGTH = Object.keys(ScheduleWeekday).length;

/**
 * Is the filter button visually active, regardless of whether or not
 * filtering should occur?
 */
export function hasVisuallyActiveAvailabilityFilter(
  availabilityFilter: FiltersState['availabilityFilter'],
): boolean {
  if (canFilterByDateRange(availabilityFilter)) return true;

  if (
    availabilityFilter?.weekDays?.length &&
    availabilityFilter.weekDays.length < FULL_WEEK_LENGTH
  ) {
    return true;
  }

  return false;
}

function shouldFilterByAvailability(
  availabilityFilter: FiltersState['availabilityFilter'],
): boolean {
  return (
    canFilterByDateRange(availabilityFilter) ||
    canFilterByWeekDays(availabilityFilter)
  );
}

export function useAvailableRecommendations<T extends ListingInBoundingBox>(
  recommendations: T[],
): {
  availableRecommendations: T[];
  loadingAvailableListingsById: boolean;
} {
  const availabilityFilter = useAppSelector(selectAvailabilityFilter);

  const { lessonLength } = useLessonLength();

  const {
    data: dataAvailableListingsById,
    loading: loadingAvailableListingsById,
  } = useGetAvailableListingsByIdQuery({
    variables: {
      listingIds: recommendations.map((r) => r.id),
      startAt: availabilityFilter.startAt,
      endAt: availabilityFilter.endAt || availabilityFilter.startAt,
      weekDays: availabilityFilter.weekDays,
      from:
        availabilityFilter.from?.value ||
        AVAILABILITY_OPTIONS_FROM[0]?.value ||
        '00:00',
      to:
        availabilityFilter.to?.value ||
        AVAILABILITY_OPTIONS_TO[AVAILABILITY_OPTIONS_TO.length - 1]?.value ||
        '23:00',
      lessonLength,
    },
    skip:
      !recommendations.length ||
      !shouldFilterByAvailability(availabilityFilter),
  });

  const availableRecommendations = useMemo(
    () =>
      dataAvailableListingsById?.getAvailableListingsById?.listingIds?.map(
        (listingId) => recommendations.find((r) => r.id === listingId),
      ) || recommendations,
    [
      dataAvailableListingsById?.getAvailableListingsById?.listingIds,
      recommendations,
    ],
  );

  return {
    availableRecommendations,
    loadingAvailableListingsById,
  };
}
