import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    backgroundColor: 'bg-surface',
  },
});

const variants = {
  outline: {
    container: {
      bg: 'bg-surface',
      borderColor: 'border',
      borderRadius: '16px',
      borderWidth: { base: 'none', lg: '1px' },
    },
  },
};

const cardTheme = defineMultiStyleConfig({ baseStyle, variants });

export default cardTheme;
