import { IconProps, TagLabel } from '@chakra-ui/react';

import FeeTag from './components/FeeTag';

import { useModal } from '../../context/ModalContext';
import capturePostHogEvent from '../../utils/capturePostHogEvent';
import { SelectedLocation } from '../../utils/types';
import LocationFeeIcon from '../LocationFeeIcon';

type Props = Pick<IconProps, 'px' | 'py'> & {
  isAbbreviated?: boolean;
  isSelected?: boolean;
  location?: Pick<SelectedLocation, 'fee'>;
  disabled?: boolean;
  forceVisible?: boolean;
};

export default function LocationFeeTag({
  isAbbreviated,
  isSelected,
  location,
  px,
  py,
  disabled,
  forceVisible,
}: Props): JSX.Element {
  const { handleOpen } = useModal();

  const onClick: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    if (disabled) {
      return;
    }

    e.stopPropagation();
    capturePostHogEvent('locationFeeIcon');
    handleOpen('locationFee');
  };

  if (!location?.fee && !forceVisible) {
    return null;
  }

  return (
    <FeeTag
      isSelected={isSelected}
      px={px}
      py={py}
      _hover={{ cursor: disabled ? 'default' : 'pointer' }}
      onClick={onClick}
    >
      <LocationFeeIcon />
      <TagLabel color="#718096" textStyle="overlineBold" userSelect="none">
        &nbsp;{location?.fee ? `$${location.fee}` : ''}{' '}
        {isAbbreviated ? '' : 'RESERVATION '}FEE
      </TagLabel>
    </FeeTag>
  );
}
