import useLessonLength from "../../../hooks/useLessonLength";
import useSelectedLocation from "../../../hooks/useSelectedLocation";
import { useAppSelector } from "../../../state/hooks";
import { selectConfirmEmail, selectConfirmName, selectConfirmPhoneNumber, selectDateTime, selectIsRecurring, selectParticipants, selectPkg } from "../../../state/slices/filtersSlice";
import { selectSelectedListing } from "../../../state/slices/listingsSlice";
import useNeedGear from "../components/Gear/hooks/useNeedGear";

export default function useCheckoutFormValues() {
    const [selectedLocation] = useSelectedLocation();
    const listing = useAppSelector(selectSelectedListing);
    const dateTime = useAppSelector(selectDateTime);
    const participants = useAppSelector(selectParticipants);
    const { gear } = useNeedGear();
    const isRecurring = useAppSelector(selectIsRecurring);
    const pkg = useAppSelector(selectPkg);
    const { lessonLength } = useLessonLength();
    const confirmEmail = useAppSelector(selectConfirmEmail);
    const confirmName = useAppSelector(selectConfirmName);
    const confirmPhoneNumber = useAppSelector(selectConfirmPhoneNumber);


    return {
        listing,
        selectedLocation,
        dateTime,
        participants,
        gear,
        isRecurring,
        pkg,
        lessonLength,
        confirmEmail,
        confirmName,
        confirmPhoneNumber
    }
}
