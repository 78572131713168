import useCurrentLocation from './useCurrentLocation';

import { useAppSelector } from '../state/hooks';
import { selectIsAtHome } from '../state/slices/filtersSlice';
import { CurrentLocation } from '../utils/types';

type AtHomeCurrentLocation = undefined | CurrentLocation;

export default function useAtHomeCurrentLocation(): AtHomeCurrentLocation {
  const { currentLocation } = useCurrentLocation();
  const isAtHome = useAppSelector(selectIsAtHome);

  const atHomeCurrentLocation: AtHomeCurrentLocation =
    (isAtHome && currentLocation) || undefined;

  return atHomeCurrentLocation;
}
