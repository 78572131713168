import {
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Progress,
  Text,
  VStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { HiX } from 'react-icons/hi';
import { IoArrowBackOutline } from 'react-icons/io5';

import {
  QuestionAges,
  QuestionContent,
  QuestionDateTime,
  QuestionEquipment,
  QuestionExperience,
  QuestionLocation,
  QuestionSkillLevel,
  QuizFinish,
} from './Questions';
import QuizPrimer from './Questions/Primer';
import QuizUserLocation from './Questions/UserLocation';
import useQuiz from './useQuiz';

import useIsMobile from '../../hooks/useIsMobile';
import useLocationPermission, {
  LocationPermissionStatus,
} from '../../hooks/useLocationPermission';
import useProTitle from '../../hooks/useProTitle';
import useSelectedSkill from '../../hooks/useSelectedSkill';
import capturePostHogEvent from '../../utils/capturePostHogEvent';
import PosthogEvent from '../../utils/posthogEvents';

interface Props {
  onClose: () => void;
}

const AnimatedModalHeading = motion(Heading);
// Animate opacity in case question needs to get initially skipped over
const AnimatedModalContent = motion(ModalContent);

export default function QuizContentWrapper({ onClose }: Props) {
  const {
    currentQuestion,
    goToNextQuestion,
    goToPreviousQuestion,
    hasNextStep,
    hasPreviousStep,
    totalSteps,
    isLastQuestion,
    progressStep,
  } = useQuiz();
  const { isGettingGeolocation, setLocationPermissionStatus } =
    useLocationPermission();
  const [selectedSkill] = useSelectedSkill();

  const { isMobile } = useIsMobile();
  const userLocationHasChanged = useRef(false);
  const currentAnswer = useRef('');
  const currentOptions = useRef('');

  const handleClose = () => {
    onClose();
  };

  const onDismiss = () => {
    capturePostHogEvent(PosthogEvent.QuizDismissed, {
      question: currentQuestion.title,
    });
    handleClose();
  };

  const captureNextEvent = () => {
    const postHogPayload = {
      question: currentQuestion.title,
      answer: currentAnswer.current,
      options: currentOptions.current,
      skill: selectedSkill?.slug,
    };

    capturePostHogEvent(PosthogEvent.QuizNext, { payload: postHogPayload });
    currentAnswer.current = '';
    currentOptions.current = '';
  };

  const onHandleNext = () => {
    if (
      currentQuestion.content === QuestionContent.UserLocation &&
      userLocationHasChanged.current
    ) {
      setLocationPermissionStatus(LocationPermissionStatus.ACCEPTED);
      captureNextEvent();
      return;
    }
    captureNextEvent();
    goToNextQuestion();
  };

  const handleOnChangeUserLocation = () => {
    userLocationHasChanged.current = true;
  };

  const onSelectAnswer = (answer: string, options?: string) => {
    currentAnswer.current = answer;
    currentOptions.current = options || '';
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      capturePostHogEvent(PosthogEvent.QuizLeaveSite, {
        question: currentQuestion?.title,
        skill: selectedSkill?.slug,
      });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [currentQuestion?.title, selectedSkill?.slug]);

  const renderContent = () => {
    switch (currentQuestion.content) {
      case QuestionContent.UserLocation:
        return (
          <QuizUserLocation onChangeLocation={handleOnChangeUserLocation} />
        );
      case QuestionContent.Primer:
        return <QuizPrimer />;
      case QuestionContent.SkillLevel:
        return <QuestionSkillLevel onSelectAnswer={onSelectAnswer} />;
      case QuestionContent.Ages:
        return <QuestionAges onSelectAnswer={onSelectAnswer} />;
      case QuestionContent.Equipment:
        return <QuestionEquipment onSelectAnswer={onSelectAnswer} />;
      case QuestionContent.Location:
        return <QuestionLocation onSelectAnswer={onSelectAnswer} />;
      case QuestionContent.DateAndTime:
        return <QuestionDateTime onSelectAnswer={onSelectAnswer} />;
      case QuestionContent.Experience:
        return (
          <QuestionExperience
            goToNextQuestion={goToNextQuestion}
            onSelectAnswer={onSelectAnswer}
          />
        );
      case QuestionContent.Finish:
        return <QuizFinish onClose={onClose} />;
      default:
        return null;
    }
  };

  const proTitle = useProTitle({ isPlural: true });

  // eslint-disable-next-line no-nested-ternary
  const linkButtonTitle = !hasPreviousStep
    ? isMobile
      ? 'Skip to results'
      : `or browse all nearby ${proTitle}`
    : 'Skip to results';

  const actionButtonTitle = isLastQuestion ? 'Finish' : 'Next';

  return (
    <AnimatedModalContent
      display="flex"
      justifyContent="space-between"
      pt={{ base: '1', md: '0' }}
      pb={{ base: '2', md: '0' }}
      px={{ base: '0', md: '6' }}
      position={{ base: 'fixed', md: 'unset' }}
      bottom="0px"
      mb={{ base: '0', md: 'auto' }}
      borderRadius={{ base: '1.75rem 1.75rem 0px 0px', md: '3xl' }}
      maxH={window.innerHeight}
      maxW={{
        base: '100%',
        md:
          currentQuestion?.content === QuestionContent.Experience ? 'xl' : 'md',
      }}
      initial={{ opacity: isMobile ? 1 : 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.3,
      }}
      key={currentQuestion.content}
    >
      {currentQuestion?.title && (
        <ModalHeader py={2} px={0} pt={{ base: 0, md: 4 }}>
          <VStack align="flex-start" spacing="0">
            <HStack w="full" justifyContent="space-between">
              {hasPreviousStep ? (
                <IconButton
                  aria-label="Back"
                  icon={<IoArrowBackOutline size={24} />}
                  variant="ghost"
                  onClick={goToPreviousQuestion}
                  tabIndex={-1}
                />
              ) : (
                <Box h={isMobile ? 0 : '12'} />
              )}
              {isMobile && (
                <IconButton
                  aria-label="Close modal"
                  icon={<HiX size={24} />}
                  variant="ghost"
                  onClick={onDismiss}
                  tabIndex={-1}
                />
              )}
            </HStack>

            <VStack
              align="center"
              spacing="4"
              w="full"
              px={{ base: 12, md: 6 }}
            >
              <AnimatedModalHeading
                variant="h3"
                textAlign="center"
                initial={{ scale: 0.92, opacity: isMobile ? 0 : 0.8 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  duration: 0.3,
                  type: 'spring',
                  stiffness: 300,
                }}
                key={currentQuestion.content}
              >
                {currentQuestion.title}
              </AnimatedModalHeading>
              {currentQuestion.subtitle && (
                <Text textStyle="small" textAlign="center" color="muted">
                  {currentQuestion.subtitle}
                </Text>
              )}
            </VStack>
          </VStack>
        </ModalHeader>
      )}

      <ModalBody
        w="full"
        mt="6"
        mb={isMobile ? '6' : '8'}
        pb="6"
        pt="4"
        px="8"
        display="flex"
        overflowY={
          currentQuestion?.content === QuestionContent.DateAndTime
            ? 'auto'
            : 'visible'
        }
      >
        {renderContent()}
      </ModalBody>

      {hasNextStep && (
        <ModalFooter
          pb={{ base: '4', md: '10' }}
          pt="0"
          justifyContent="center"
        >
          <VStack spacing="6" w="full">
            <Progress
              value={progressStep}
              w={{ base: '100%', md: '50%' }}
              size="xs"
              min={0}
              max={totalSteps}
            />
            <Button
              size={{ base: 'xl', md: 'lg' }}
              w="100%"
              type="submit"
              isLoading={isGettingGeolocation}
              onClick={onHandleNext}
              backgroundColor="bg-accent"
            >
              {actionButtonTitle}
            </Button>

            <Button
              variant="link"
              color="#718096"
              onClick={onDismiss}
              fontSize="16px"
            >
              {linkButtonTitle}
            </Button>
          </VStack>
        </ModalFooter>
      )}
    </AnimatedModalContent>
  );
}
