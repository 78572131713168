import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  ModalCloseButton,
} from '@chakra-ui/react';

import LocationFeeModalContent from './LocationFeeModalContent';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function LocationFeeModalMobile({ isOpen, onClose }: Props) {
  return (
    <Drawer isOpen={isOpen} placement="bottom" size="md" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent borderTopRadius="2xl" p="4">
        <ModalCloseButton />
        <LocationFeeModalContent />
      </DrawerContent>
    </Drawer>
  );
}
