import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Skeleton,
  SkeletonText,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import { motion, useTransform } from 'framer-motion';
import { HiChevronDown } from 'react-icons/hi';

import { useMainScroll } from '../../context/MainScrollContext';
import useHeaderHeight from '../../hooks/useHeaderHeight';
import LogoIcon from '../LogoIcon';

export default function SearchHeaderSkeleton(): JSX.Element {
  const { colorMode } = useColorMode();

  const isLight = colorMode === 'light';
  const {
    scroll: { scrollY },
  } = useMainScroll();
  const { headerHeight } = useHeaderHeight();
  const opacitySkill = useTransform(scrollY, [0, headerHeight / 12], [1, 0]);
  const heightLearn = useTransform(scrollY, [0, headerHeight / 6], [20, 0]);
  const widthSkill = useTransform(
    scrollY,
    [0, headerHeight / 3],
    ['100%', '0%'],
  );

  return (
    <Flex flexDir="column" alignItems="flex-start" w="full">
      <Flex
        align="center"
        justifyContent="space-between"
        maxW="full"
        w="full"
        zIndex="103"
      >
        <Flex align="center" justifyContent="center">
          <Box>
            <LogoIcon size="xl" isLight={!isLight} />
          </Box>

          <Center height="40px" px="6">
            <Divider borderColor="border-default" orientation="vertical" />
          </Center>

          <Flex align="center" justifyContent="center">
            <motion.div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                position: 'relative',
              }}
            >
              <Flex
                alignItems="flex-start"
                cursor="pointer"
                flexDir="column"
                justifyContent="center"
                w="full"
              >
                <motion.div
                  style={{ opacity: opacitySkill, height: heightLearn }}
                >
                  <Text color="#718096" textStyle="overlineBold" noOfLines={1}>
                    LEARN
                  </Text>
                </motion.div>

                <Flex
                  alignItems="center"
                  flexDir="row"
                  justifyContent="flex-start"
                >
                  <Skeleton fontSize="24px" fontWeight="900">
                    Pickleball
                  </Skeleton>
                  <motion.div
                    style={{ opacity: opacitySkill, width: widthSkill }}
                  >
                    <HiChevronDown fontSize="20px" />
                  </motion.div>
                </Flex>
              </Flex>
            </motion.div>
            <Flex
              alignItems="flex-start"
              cursor="pointer"
              flexDir="column"
              justifyContent="center"
              ml="6"
            >
              <motion.div
                style={{ opacity: opacitySkill, height: heightLearn }}
              >
                <Text color="#718096" textStyle="overlineBold">
                  NEAR
                </Text>
              </motion.div>

              <Flex alignItems="center" flexDir="row" justifyContent="center">
                <Skeleton fontSize="24px" fontWeight="900">
                  12345
                </Skeleton>

                <motion.div
                  style={{ opacity: opacitySkill, height: heightLearn }}
                >
                  <HiChevronDown fontSize="20px" />
                </motion.div>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <HStack align="center" spacing="2">
          <Skeleton h="40px" rounded="full" px="6">
            Ask a question
          </Skeleton>
          <Skeleton h="40px" rounded="full" px="6">
            Get Matched
          </Skeleton>

          <Skeleton h="40px" rounded="full" px="6" />
        </HStack>
      </Flex>

      <Flex mt="6" w="full">
        <HStack>
          <SkeletonText noOfLines={1}>Sort</SkeletonText>
          <Skeleton>X Recommended X</Skeleton>
        </HStack>

        <Flex
          alignItems="center"
          flexDir="row"
          justifyContent="flex-end"
          flexGrow={1}
          ml="2"
        >
          <Skeleton noOfLines={1} w="full">
            T
          </Skeleton>
        </Flex>
      </Flex>
    </Flex>
  );
}
