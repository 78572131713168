/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */

export const customerIoIdentify = (email: string) => {
  const _cio = (window as any)._cio || [];
  (window as any)._cio = _cio;

  _cio.identify({ id: email });
};

export const customerIoTrack = (eventData: { name: string; data: any }) => {
  const _cio = (window as any)._cio || [];
  (window as any)._cio = _cio;

  _cio.track(eventData.name, eventData.data);
};

export const customerIoTrackEvent = (eventName: string) => {
  const _cio = (window as any)._cio || [];
  (window as any)._cio = _cio;

  _cio.track(eventName);
};

export const customerIoIdentifyWithPH = (
  postHogUserId: string,
  email: string,
  tmtUserId?: string,
) => {
  if (!postHogUserId || !email) {
    return;
  }
  const _cio = (window as any)._cio || [];
  (window as any)._cio = _cio;
  const userId = tmtUserId || postHogUserId;
  _cio.identify({ id: postHogUserId, email, userId });
};
