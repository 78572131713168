import { useToast } from '@chakra-ui/react';
import { useLocalStorageValue, useSessionStorageValue } from '@react-hookz/web';
import { INextState } from '@react-hookz/web/cjs/util/resolveHookState';
import { useCallback } from 'react';

import useCurrentLocation from './useCurrentLocation';

import { useAppDispatch } from '../state/hooks';
import { setIsAtHome } from '../state/slices/filtersSlice';
import { setAskToShareLocation, setMapLocation } from '../state/slices/locationsSlice';
import capturePostHogEvent from '../utils/capturePostHogEvent';
import getLocationFromCoords from '../utils/getLocationFromCoords';
import PosthogEvent from '../utils/posthogEvents';

export enum LocationPermissionStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  UNDETERMINED = 'UNDETERMINED',
}

type Props = {
  locationPermissionStatus: LocationPermissionStatus;
  isGettingGeolocation: boolean;
  setLocationPermissionStatus(
    val: INextState<LocationPermissionStatus, LocationPermissionStatus>,
  ): void;
  tryGetUserGeolocation(
    hasRequestedAtHome: boolean,
  ): Promise<LocationPermissionStatus>;
};

export default function useLocationPermission(): Props {
  const [locationPermissionStatus, setLocationPermissionStatus] =
    useLocalStorageValue<LocationPermissionStatus>(
      'locationPermissionStatus',
      LocationPermissionStatus.UNDETERMINED,
    );
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [isGettingGeolocation, setIsGettingGeolocation] =
    useSessionStorageValue<boolean>('isGettingGeolocation', false);
  const { setCurrentLocation } = useCurrentLocation();


  const tryGetUserGeolocation: Props['tryGetUserGeolocation'] = useCallback(
    async (hasRequestedAtHome) =>
      new Promise<LocationPermissionStatus>((resolve, reject) => {
        // check if the device has geolocation
        const geo = navigator.geolocation;
        if (!geo) {
          resolve(locationPermissionStatus);
          return;
        }

        const handleDecline = () => {
          const newPermissionStatus = LocationPermissionStatus.DECLINED;
          capturePostHogEvent(PosthogEvent.DeclinedLocation);
          setLocationPermissionStatus(newPermissionStatus);
          setIsGettingGeolocation(false);
          dispatch(setAskToShareLocation(false));
          reject(newPermissionStatus);
          toast({
            title: 'We couldn’t detect your location',
            description: "Tap the zip code above to manually search for a location.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        };

        const handleAccept = async ({ coords }) => {
          try {
            capturePostHogEvent(PosthogEvent.AcceptedLocation);

            const location = await getLocationFromCoords({
              address: '',
              latitude: coords?.latitude,
              longitude: coords?.longitude,
            });

            if (location) {
              setLocationPermissionStatus(LocationPermissionStatus.ACCEPTED);
              setCurrentLocation(location);
              if (hasRequestedAtHome) dispatch(setIsAtHome(true));
              else dispatch(setMapLocation(location));
            }
            dispatch(setAskToShareLocation(false));
            resolve(LocationPermissionStatus.ACCEPTED);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error('useLocationPermission', err);
            reject(LocationPermissionStatus.DECLINED);
          } finally {
            setIsGettingGeolocation(false);
          }
        };

        setIsGettingGeolocation(true);
        geo.getCurrentPosition(handleAccept, handleDecline);
      }),
    [dispatch, locationPermissionStatus, setCurrentLocation, setIsGettingGeolocation, setLocationPermissionStatus, toast],
  );


  return {
    locationPermissionStatus,
    isGettingGeolocation,
    setLocationPermissionStatus,
    tryGetUserGeolocation,
  };
}
