import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { HiMenu } from 'react-icons/hi';

import { useDrawer } from '../../context/DrawerContext';
import useClosestSkills from '../../hooks/useClosestSkills';
import { FiltersState } from '../../state/slices/filtersSlice';
import capturePostHogEvent from '../../utils/capturePostHogEvent';
import {
  DEFAULT_SKILL_SLUG,
} from '../../utils/constants';
import LogoIcon from '../LogoIcon';
import { RouterLink } from '../RouterLink';

type Props = {
  bg?: string;
  borderColor?: string;
  color?: string;
  isGroupedWithLogo?: boolean;
};

export default function HeaderMenu({
  bg,
  borderColor,
  color,
  isGroupedWithLogo = false,
}: Props) {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const { handleOpen } = useDrawer();
  const { closestSkills } = useClosestSkills();

  const skills = useMemo(
    () =>
      [...(closestSkills || [])].sort(
        (a, b) =>
          // Pickleball goes first
          (b.slug === DEFAULT_SKILL_SLUG ? 1 : 0) -
          (a.slug === DEFAULT_SKILL_SLUG ? 1 : 0),
      ) as FiltersState['selectedSkill'][],
    [closestSkills],
  );

  const defaultColor = useColorModeValue('#171923', 'white');

  const onClickMenuButton = () => {
    capturePostHogEvent('clickMenu');
    onToggle();
  };

  return (
    <Menu isOpen={isOpen} onClose={onClose} colorScheme="gray">
      {isGroupedWithLogo ? (
        <MenuButton
          alignItems="center"
          bg="#FFF"
          borderRadius="full"
          justifyContent="center"
          onClick={onClickMenuButton}
          px="2"
        >
          <HStack>
            <LogoIcon color="#2D3748" size="sm" isLight={false} />
            <Box
              aria-label="Options"
              bg={bg}
              borderColor={borderColor}
              color={color || defaultColor}
              rounded="full"
            >
              {isOpen ? <AiOutlineClose size={20} /> : <HiMenu size={20} />}
            </Box>
          </HStack>
        </MenuButton>
      ) : (
        <MenuButton
          as={IconButton}
          aria-label="Options"
          bg={bg}
          borderColor={borderColor}
          color={color || defaultColor}
          colorScheme="gray"
          icon={isOpen ? <AiOutlineClose size={20} /> : <HiMenu size={20} />}
          onClick={onClickMenuButton}
          rounded="full"
          size="md"
          variant="outline"
        />
      )}

      <MenuList
        maxH="90dvh"
        overflowY="scroll"
        w={{ base: '90dvw', sm: 'auto' }}
        zIndex="modal"
      >
        <MenuGroup
          title="Learn:"
          ml="3"
          color="gray.500"
          fontSize="xs"
          fontWeight="black"
          textTransform="uppercase"
        >
          {skills.map((skill) => (
            <RouterLink to={`/browse/${skill.slug}`} key={skill.slug}>
              <MenuItem>{skill.label}</MenuItem>
            </RouterLink>
          ))}
        </MenuGroup>
        <MenuDivider my="6" />
        <MenuGroup
          title="More:"
          ml="3"
          color="gray.500"
          fontSize="xs"
          fontWeight="black"
          textTransform="uppercase"
        >
          <RouterLink to="/about">
            <MenuItem>About</MenuItem>
          </RouterLink>
          <RouterLink to="/blog" isExternal>
            <MenuItem>Blog</MenuItem>
          </RouterLink>
        </MenuGroup>
        <MenuDivider my="6" />
        <MenuGroup
          title="Help:"
          ml="3"
          color="gray.500"
          fontSize="xs"
          fontWeight="black"
          textTransform="uppercase"
        >
          <RouterLink to="/faq">
            <MenuItem>FAQ</MenuItem>
          </RouterLink>
          <RouterLink to="/pro-faq">
            <MenuItem>Coach FAQ</MenuItem>
          </RouterLink>
          <RouterLink to="/gift">
            <MenuItem>Gifting</MenuItem>
          </RouterLink>
          <RouterLink to="/become-a-pro">
            <MenuItem>Become a pro</MenuItem>
          </RouterLink>
          <MenuItem onClick={handleOpen(null)}>Help</MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
