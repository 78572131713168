import {
  Box,
  Center,
  FormControl,
  HStack,
  IconButton,
  IconButtonProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { HiMinus, HiPlus, HiUser, HiUserGroup, HiUsers } from 'react-icons/hi';

import { useModal } from '../../context/ModalContext';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  selectParticipants,
  selectSelectedSkill,
  setParticipants,
} from '../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../state/slices/listingsSlice';

function QuantityPickerButton(props: IconButtonProps) {
  return (
    <IconButton
      size="sm"
      fontSize="md"
      _focus={{ boxShadow: 'none' }}
      _focusVisible={{ boxShadow: 'outline' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

type Props = {
  handleChange?: (value: number) => void | undefined;
};

export default function ParticipantPicker({ handleChange }: Props) {
  const { handleOpen } = useModal();
  const selectedParticipants = useAppSelector(selectParticipants);
  const [participants, setParticipantsCount] = useState(selectedParticipants);
  const skill = useAppSelector(selectSelectedSkill);
  const listing = useAppSelector(selectSelectedListing);
  const dispatch = useAppDispatch();

  const maxStudents = useMemo(
    () => listing?.maxStudents || skill?.maxStudents || 3,
    [skill?.maxStudents, listing?.maxStudents],
  );

  const handleDecrement = useCallback(() => {
    const participantsCount =
      participants === 1 ? participants : participants - 1;
    setParticipantsCount(participantsCount);

    if (handleChange) {
      handleChange(participantsCount);
    } else {
      dispatch(setParticipants(participantsCount));
    }
  }, [dispatch, participants, handleChange]);

  const handleIncrement = useCallback(() => {
    if (participants >= maxStudents) {
      handleOpen(
        'chatRequest',
        null,
        `Request a lesson for ${maxStudents}+ people`,
      );
      return;
    }

    const participantsCount =
      participants === maxStudents ? maxStudents : participants + 1;
    setParticipantsCount(participantsCount);

    if (handleChange) {
      handleChange(participantsCount);
    } else {
      dispatch(setParticipants(participantsCount));
    }
  }, [participants, maxStudents, handleChange, handleOpen, dispatch]);

  const ParticipantsIcon = useMemo(() => {
    switch (participants) {
      case 1:
        return <HiUser size={15} />;
      case 2:
        return <HiUsers size={16} />;
      default:
        return <HiUserGroup size={16} />;
    }
  }, [participants]);

  return (
    <FormControl>
      <VStack>
        <Text
          as="span"
          textStyle="overline"
          textColor="muted"
          userSelect="none"
        >
          Participants
        </Text>
        <HStack
          alignItems="center"
          borderRadius="full"
          py="2"
          px="4"
          borderWidth={1}
          borderColor="gray.300"
          h="48px"
          spacing={0}
          width="full"
        >
          <QuantityPickerButton
            m={0}
            variant="ghost"
            aria-label="Decrement"
            isDisabled={participants === 1}
            icon={<HiMinus />}
            onClick={handleDecrement}
          />
          <Center minW="14" textAlign="center" flex={1}>
            <HStack align="center" spacing={1}>
              <Text as="span" textStyle="bold" userSelect="none">
                {participants}
              </Text>
              <Box pb={0.5}>{ParticipantsIcon}</Box>
            </HStack>
          </Center>
          <QuantityPickerButton
            m={0}
            variant="ghost"
            aria-label="Increment"
            isDisabled={participants === maxStudents + 1}
            icon={<HiPlus />}
            onClick={handleIncrement}
          />
        </HStack>
      </VStack>
    </FormControl>
  );
}
