import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  SkeletonText,
  Stack,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { memo } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { GrList } from 'react-icons/gr';
import { HiArrowLeft, HiChatAlt2, HiLocationMarker } from 'react-icons/hi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import HeaderMenu from './HeaderMenu';
import HeaderProps from './interfaces';

import { useUniqueListingQuery } from '../../api';
import { useModal } from '../../context/ModalContext';
import useCurrentLocation from '../../hooks/useCurrentLocation';
import capturePostHogEvent from '../../utils/capturePostHogEvent';
import CoachesLocationButton from '../CoachesLocationButton';
import { IconShare } from '../Icons/IconShare';
import LogoIcon from '../LogoIcon';
import { RouterLink } from '../RouterLink';
import { Show } from '../Show';

const Header = memo(
  ({
    backLabel,
    borderColor = 'border',
    expandedAskAQuestionButton = false,
    heading = 'teachme.to',
    hideCoachesLocationButton = false,
    iconBorderColor,
    isLoading = false,
    logoColor,
    onBackPress,
    showMobileLogo = false,
    showHeaderMenu = true,
    ...rest
  }: HeaderProps) => {
    const { currentLocation } = useCurrentLocation();
    const navigate = useNavigate();
    const modals = useModal();
    const { handleOpen: handleOpenModal } = useModal();
    const { colorMode } = useColorMode();
    const {
      isOpen: filterIsOpen,
      onClose: filterOnClose,
      onToggle: filterOnToggle,
    } = useDisclosure();
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const isLight = colorMode === 'light';
    const isListing = window.location.pathname.includes('listings');
    const showShare = isListing;
    const isPaidLandingPage = window.location.search.includes('utm_source');

    const location = useLocation();
    const isBecomeAProPage = location.pathname === '/become-a-pro';

    // Get listing from params
    const params = useParams();
    const { data } = useUniqueListingQuery({
      variables: {
        where: {
          slug: params.slug,
        },
      },
      skip: !params.slug,
    });
    const listing = data?.findUniqueListing;

    return (
      <Box
        borderBottom="1px"
        borderBottomColor={borderColor}
        w="full"
        shadow={showShare ? 'sm' : 'none'}
      >
        <Flex
          /* eslint-disable react/jsx-props-no-spreading */
          {...rest}
          align="center"
          as="nav"
          // borderBottom="1px"
          // borderBottomColor={borderColor}
          // maxW="full"
          minH="100%"
          px={{ base: '4', md: '12' }}
          py="4"
          shadow={showShare ? 'sm' : 'none'}
          w="full"
          maxW="1200px"
          mx="auto"
        >
          <Flex
            align="center"
            justify={{ base: 'flex-start', md: 'flex-start' }}
            w="full"
            maxW="1200px"
            mx="auto"
            px={{ base: '4' }}
          >
            <Show condition={!backLabel && !isSmallScreen}>
              <RouterLink to="/" w="auto">
                <Flex align="center">
                  <LogoIcon color={logoColor} isLight={!isLight} size="lg" />
                  <Heading color={logoColor} ml="2" variant="h4">
                    TeachMe.To
                  </Heading>
                </Flex>
              </RouterLink>
            </Show>
            <Show
              condition={
                isSmallScreen &&
                (isPaidLandingPage || showMobileLogo || isBecomeAProPage)
              }
            >
              <RouterLink to="/" w="auto">
                <Flex align="center">
                  <LogoIcon color={logoColor} size="md" isLight={!isLight} />
                  <Heading
                    color={logoColor || 'defaultText'}
                    fontSize={18}
                    ml="2"
                    variant="h4"
                  >
                    TeachMe.To
                  </Heading>
                </Flex>
              </RouterLink>
            </Show>
            <Show condition={!!backLabel}>
              <Stack direction="row">
                <Button
                  color="slate.500"
                  _hover={{ textDecorationLine: 'underline' }}
                  variant="link"
                  leftIcon={<HiArrowLeft color="#2D3748" />}
                  onClick={() => (onBackPress ? onBackPress?.() : navigate(-1))}
                  textDecorationLine="none"
                >
                  {backLabel}
                </Button>
              </Stack>
            </Show>
            <Show
              condition={
                !showMobileLogo &&
                isSmallScreen &&
                !isPaidLandingPage &&
                !isBecomeAProPage
              }
            >
              <Menu
                isOpen={filterIsOpen}
                onClose={filterOnClose}
                colorScheme="gray"
              >
                <MenuButton
                  as={Button}
                  aria-label="Options"
                  leftIcon={filterIsOpen ? <AiOutlineClose /> : <GrList />}
                  color={useColorModeValue('#171923', 'white')}
                  variant="outline"
                  rounded="full"
                  colorScheme="gray"
                  size="md"
                  onClick={() => {
                    capturePostHogEvent('clickMenu');
                    filterOnToggle();
                  }}
                >
                  Filters
                </MenuButton>

                <MenuList zIndex="modal">
                  <MenuGroup title="Location">
                    <MenuItem
                      icon={<HiLocationMarker />}
                      onClick={() => {
                        capturePostHogEvent('clickHeadingToggle', {
                          action: 'location',
                        });
                        handleOpenModal(
                          'locationPermission',
                          undefined,
                          undefined,
                          true,
                        );
                      }}
                    >
                      {currentLocation?.state && currentLocation?.city
                        ? `${currentLocation.city}, ${currentLocation.state}`
                        : 'Anywhere'}
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>
            </Show>
          </Flex>
          <Flex align="center" flexGrow={1} justify="center" w="full">
            <SkeletonText isLoaded={!isLoading} noOfLines={1}>
              <Heading variant="h4" noOfLines={1}>
                {heading}
              </Heading>
            </SkeletonText>
          </Flex>
          <Flex
            align="center"
            justify={{ base: 'flex-end' }}
            w="full"
            maxW="1200px"
            mx="auto"
            pr={{ base: '1', md: '5' }}
          >
            <Stack direction="row" align="center" spacing="4">
              <Show
                condition={
                  !hideCoachesLocationButton &&
                  !isSmallScreen &&
                  !isPaidLandingPage &&
                  !isBecomeAProPage
                }
              >
                <CoachesLocationButton handleOpen={handleOpenModal} />
              </Show>

              <Show condition={showShare}>
                <Button
                  colorScheme="slate"
                  size="md"
                  variant="link"
                  mr="2"
                  leftIcon={<IconShare />}
                  onClick={() => {
                    modals.handleOpen('share');
                    capturePostHogEvent('clickShare', { action: 'header' });
                  }}
                >
                  Share
                </Button>
              </Show>

              {expandedAskAQuestionButton ? (
                <Button
                  bg="#EBF8FF"
                  color="#3182CE"
                  _hover={{
                    bg: 'transparent',
                    color: 'defaultText',
                  }}
                  leftIcon={<HiChatAlt2 fontSize="20px" />}
                  onClick={() => {
                    capturePostHogEvent('clickContact', { action: 'header' });
                    handleOpenModal('chatRequest', listing?.proName);
                  }}
                  size="md"
                  w="full"
                  variant="ghost"
                >
                  Ask a question
                </Button>
              ) : (
                <IconButton
                  aria-label="Request to chat"
                  bg="transparent"
                  borderColor={iconBorderColor}
                  color={logoColor || 'defaultText'}
                  colorScheme="gray"
                  icon={<HiChatAlt2 fontSize="20px" />}
                  onClick={() => {
                    capturePostHogEvent('clickContact', { action: 'header' });
                    modals.handleOpen('chatRequest', listing?.proName);
                  }}
                  rounded="full"
                  size="md"
                  variant="outline"
                />
              )}

              <Show condition={showHeaderMenu}>
                <HeaderMenu
                  bg="transparent"
                  borderColor={iconBorderColor}
                  color={logoColor}
                />
              </Show>
            </Stack>
          </Flex>
        </Flex>
      </Box>
    );
  },
);

export default Header;
