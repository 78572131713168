import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';

import Day from './Day';
import { DayStyleConfig } from './type';

import dayListGenerator, { DateRulesFn } from '../utils/dayListGenerator';


type CalendarProps = {
  year: number;
  month: number;
  onSetDate?: (date: Date) => void;
  onGetDayTypeRulesFn: DateRulesFn;
  dayStyleConfig?: Partial<DayStyleConfig>;
} & SimpleGridProps;

export default function Calendar({
  year,
  month,
  dayStyleConfig,
  onSetDate,
  onGetDayTypeRulesFn,
  ...props
}: CalendarProps) {
  const dayList = dayListGenerator(year, month, onGetDayTypeRulesFn);

  return (
    <SimpleGrid {...props} columns={7} rowGap={2} w="full">
      {dayList.map((dateObj) => (
          <Day
            key={dateObj.date.toISOString()}
            onSetDate={onSetDate}
            {...dateObj}
            dayStyleConfig={dayStyleConfig}
          />
        ))}
    </SimpleGrid>
  );
}
