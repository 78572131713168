import { useMemo } from 'react';

import useCurrentLocation from './useCurrentLocation';

import { getDistance } from '../utils/getDistanceAway';
import { SelectedLocation } from '../utils/types';

export default function useSortedLocationsByDistance(
  locations: SelectedLocation[],
) {
  const { currentLocation } = useCurrentLocation();

  const sortedLocationsByDistance: SelectedLocation[] = useMemo(
    () =>
      [...(locations || [])].sort(
        (locationA, locationB) =>
          getDistance(
            [currentLocation?.latitude || 0, currentLocation?.longitude || 0],
            [locationA.latitude || 0, locationA.longitude || 0],
          ) -
          getDistance(
            [currentLocation?.latitude || 0, currentLocation?.longitude || 0],
            [locationB.latitude || 0, locationB.longitude || 0],
          ),
      ),
    [currentLocation?.latitude, currentLocation?.longitude, locations],
  );

  return { sortedLocationsByDistance };
}
