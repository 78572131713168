import { Tag } from '@chakra-ui/react';

import { type LocationEnvironmentSetting } from '../../api';

export default function EnvironmentSettingTags({
  environmentSettings,
  isSelected,
}: {
  environmentSettings: LocationEnvironmentSetting[];
  isSelected?: boolean;
}): JSX.Element {
  return !environmentSettings?.length ? null : (
    <>
      {environmentSettings.map((setting) => (
        <Tag
          key={setting}
          bg={isSelected ? 'white' : undefined}
          borderRadius="full"
          colorScheme={isSelected ? undefined : 'gray'}
          color="#718096"
          fontWeight="900"
          fontSize="11px"
          flexShrink="0"
          minH="28px"
        >
          {setting.toUpperCase()}
        </Tag>
      ))}
    </>
  );
}
