import { Stack, StackProps, useColorModeValue } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props extends StackProps {
  children: ReactNode;
}

export function BottomBar({ children, ...rest }: Props) {
  return (
    <Stack
      direction="row"
      bottom="0"
      bg={useColorModeValue('white', 'gray.900')}
      zIndex="100"
      w="full"
      p="6"
      boxShadow="0 -4px 8px rgba(0,0,0,0.10)"
      spacing={4}
      {...rest}
    >
      {children}
    </Stack>
  );
}
