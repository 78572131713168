import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history;
    if (canControlScrollRestoration) {
      const mainDiv = document.getElementById('main-container');
      if (mainDiv) {
        mainDiv.scrollTo({
          top: 0,
          left: 0,
        });
      }
    }
  }, [pathname]);

  return null;
}
