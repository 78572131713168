import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import useIsMobile from './useIsMobile';

/**
 * Rendering the map should depend on the 'map' search param
 */
export default function useIsMapView(): {
  isMapView: boolean;
  setIsMapView(newIsMapView: boolean): void;
} {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile } = useIsMobile();

  const isMapView: boolean = useMemo(
    () => searchParams?.get('map') === 'true' || !isMobile,
    [isMobile, searchParams],
  );

  const setIsMapView = useCallback(
    (newIsMapView: boolean) => {
      setSearchParams((prevParams) => {
        const newSesarchParams = new URLSearchParams(prevParams);
        if (newIsMapView && newSesarchParams.get('map') !== 'true') {
          newSesarchParams.append('map', 'true');
        } else {
          newSesarchParams.delete('map');
        }
        return newSesarchParams;
      });
    },
    [setSearchParams],
  );

  return { isMapView, setIsMapView };
}
