import {
  Box,
  Center,
  Flex,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Spinner,
} from '@chakra-ui/react';
import { HiOutlineX } from 'react-icons/hi';

import ProLocationsMap from '../../../../../components/ProLocationsMap';
import useIsAtHomeOrListingExistsWithNoLocations from '../../../../../hooks/useIsAtHomeOrListingExistsWithNoLocations';
import useLocationPermission from '../../../../../hooks/useLocationPermission';
import { useAppSelector } from '../../../../../state/hooks';
import { selectSelectedListing } from '../../../../../state/slices/listingsSlice';
import NotInServiceAreaModal from '../../../../ListingDetails/components/NotInServiceAreaModal';
import ProLocationSlideItems from '../../../../ProLocations/components/ProLocationSlideItems';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isInServiceArea: boolean;
};

const sideBarWidth = 349;

export default function ChooseLocationModal({
  isOpen,
  onClose,
  isInServiceArea,
}: Readonly<Props>) {
  const selectedListing = useAppSelector(selectSelectedListing);
  const isAtHomeOrListingExistsWithNoLocations =
    useIsAtHomeOrListingExistsWithNoLocations();

  const { isGettingGeolocation } = useLocationPermission();

  return (
    <Modal
      isCentered
      scrollBehavior="inside"
      motionPreset="slideInBottom"
      onOverlayClick={onClose}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        position="relative"
        borderRadius="24px"
        maxH={0.8 * window.innerHeight}
        maxW="5xl"
      >
        <Box
          as="nav"
          borderRadius="full"
          position="absolute"
          top="6"
          left="6"
          bg="bg-surface"
          zIndex="1"
          shadow="lg"
          borderColor="border-default"
          boxShadow="0px 4px 16px 0px rgba(0, 0, 0, 0.16)"
        >
          <IconButton
            isRound
            variant="ghost"
            aria-label="Back"
            size="lg"
            onClick={onClose}
            icon={<HiOutlineX color="#2D3748" size="32px" />}
          />
        </Box>

        <Flex
          flexDir="row"
          flexGrow={1}
          w="full"
          overflow="hidden"
          borderRadius="24px"
        >
          <Skeleton
            isLoaded={Boolean(
              selectedListing?.locations?.length ||
                isAtHomeOrListingExistsWithNoLocations,
            )}
            w="full"
          >
            <Box h="full" w="calc(100% + 24px)" overflow="hidden">
              <ProLocationsMap
                key="checkout-location-modal-map"
                listing={selectedListing}
                mapHeight="full"
              />
            </Box>
          </Skeleton>

          <Box h="auto" w="50%" overflow="hidden" display="flex" zIndex={2}>
            <ProLocationSlideItems
              width={sideBarWidth}
              listing={selectedListing}
              onChooseLocation={onClose}
              hideAtHomeListItem
            />
          </Box>
        </Flex>

        {isGettingGeolocation && (
          <Center
            bg="rgba(0, 0, 0, 0.4)"
            bottom="0"
            left="0"
            minH="82px"
            position="absolute"
            right="0"
            top="0"
            zIndex={4}
            borderRadius="24px"
          >
            <Spinner size="xl" />
          </Center>
        )}

        <NotInServiceAreaModal
          isInServiceArea={isInServiceArea}
          listing={selectedListing}
        />
      </ModalContent>
    </Modal>
  );
}
