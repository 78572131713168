import { Icon } from '@chakra-ui/react';

export function IconShare() {
  return (
    <Icon w="24px" h="24px" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.75 14.75v1.5a3 3 0 0 0 3 3h8.5a3 3 0 0 0 3-3v-1.5M12 14.25V5M8.75 8.25 12 4.75l3.25 3.5"
      />
    </Icon>
  );
}
