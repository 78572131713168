import useIsAtHomeOrListingExistsWithNoLocations from './useIsAtHomeOrListingExistsWithNoLocations';

import { useAppSelector } from '../state/hooks';
import { selectAtHomeLocation } from '../state/slices/filtersSlice';

export default function useSelectedAtHomeLocation() {
  const isAtHomeOrListingExistsWithNoLocations =
    useIsAtHomeOrListingExistsWithNoLocations();
  const atHomeLocation = useAppSelector(selectAtHomeLocation);

  const selectedAtHomeLocation = isAtHomeOrListingExistsWithNoLocations
    ? atHomeLocation
    : undefined;

  return { selectedAtHomeLocation };
}
