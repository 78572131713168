/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Badge, BadgeGroup, Skill, SkillCategory } from '../../api';
import type { RootState } from '../interfaces';

type SelectedBadge = Omit<
  Badge,
  | '__typename'
  | '_count'
  | 'badgeGroup'
  | 'displayPriority'
  | 'listings'
  | 'archived'
  | 'archivedAt'
  | 'needsVerification'
  | 'badgeTypeId'
  | 'badgeType'
> & {
  badgeGroup?: Omit<BadgeGroup, '_count' | 'badges' | 'skill' | 'slug'> & {
    skill?: Omit<
      Skill,
      | 'averageNationalPrice'
      | 'BadgeGroup'
      | '_count'
      | 'listings'
      | 'locations'
      | '__typename'
    >;
  };
};

export enum ScheduleWeekday {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

type AvailabilityFilter = {
  weekDays?: ScheduleWeekday[];
  startAt?: string;
  endAt?: string;
  from: { label: string; value: string };
  to: { label: string; value: string };
};

export interface FiltersState {
  atHomeLocation: {
    address: string;
    city: string;
    country: string;
    latitude: number;
    longitude: number;
    placeName?: string;
    skillId?: Skill['id'];
    state: string;
    timezone?: string;
  };
  searchBoundingBox?: {
    southWest: {
      lat: number;
      lng: number;
    };
    northEast: {
      lat: number;
      lng: number;
    };
  };
  availabilityFilter: AvailabilityFilter;
  confirmEmail: string;
  confirmName: string;
  confirmPhoneNumber: string;
  dateTime: string;
  isAtHome: boolean;
  isRecurring: boolean;
  localSelectedBadges: SelectedBadge[];
  participants: number;
  pkg: number;
  selectedBadges: SelectedBadge[];
  selectedLessonLength: Record<SkillCategory, number>;
  selectedSkill: Omit<
    Skill,
    | 'averageNationalPrice'
    | 'BadgeGroup'
    | '_count'
    | 'listings'
    | 'locations'
    | '__typename'
    | 'updatedAt'
    | 'createdAt'
  >;
}

export const initialState: FiltersState = {
  atHomeLocation: undefined,
  availabilityFilter: {
    weekDays: Object.values(ScheduleWeekday),
    startAt: undefined,
    endAt: undefined,
    from: undefined,
    to: undefined,
  },
  confirmEmail: undefined,
  confirmName: undefined,
  confirmPhoneNumber: undefined,
  dateTime: undefined,
  isAtHome: false,
  isRecurring: false,
  localSelectedBadges: [],
  participants: 1,
  pkg: 10,
  selectedBadges: [],
  selectedLessonLength: {
    [SkillCategory.Music]: 30,
    [SkillCategory.Sport]: 60,
  },
  selectedSkill: undefined,
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    addSelectedBadges: (
      state,
      action: PayloadAction<FiltersState['selectedBadges']>,
    ) => {
      const addedBadges = action.payload;
      addedBadges.forEach((addedBadge) => {
        const addedBadgeExists = state.selectedBadges.find(
          (selectedBadge) => selectedBadge.id === addedBadge.id,
        );
        if (!addedBadgeExists) {
          state.selectedBadges.push(addedBadge);
        }
      });
    },
    clearAvailabilityFilterWeekDays: (state) => {
      state.availabilityFilter = {
        ...initialState.availabilityFilter,
        weekDays: [],
      };
    },
    removeSelectedBadges: (
      state,
      action: PayloadAction<FiltersState['selectedBadges']>,
    ) => {
      const removedBadges = action.payload || [];
      state.selectedBadges = state.selectedBadges.filter(
        (b) => !removedBadges.some((removedBadge) => removedBadge.id === b.id),
      );
    },
    resetAvailabilityFilter: (state) => {
      state.availabilityFilter = initialState.availabilityFilter;
    },
    setAtHomeLocation: (
      state,
      action: PayloadAction<FiltersState['atHomeLocation']>,
    ) => {
      state.atHomeLocation = action.payload || initialState.atHomeLocation;
      if (action.payload) {
        state.isAtHome = true;
      }
    },
    setAvailabilityFilter: (
      state,
      action: PayloadAction<FiltersState['availabilityFilter']>,
    ) => {
      state.availabilityFilter =
        action.payload || initialState.availabilityFilter;
    },
    setConfirmEmail: (
      state,
      action: PayloadAction<FiltersState['confirmEmail']>,
    ) => {
      state.confirmEmail = action.payload;
    },
    setConfirmName: (
      state,
      action: PayloadAction<FiltersState['confirmName']>,
    ) => {
      state.confirmName = action.payload;
    },
    setConfirmPhoneNumber: (
      state,
      action: PayloadAction<FiltersState['confirmPhoneNumber']>,
    ) => {
      state.confirmPhoneNumber = action.payload;
    },
    setDateTime: (state, action: PayloadAction<FiltersState['dateTime']>) => {
      state.dateTime = action.payload;
    },
    setIsAtHome: (state, action: PayloadAction<FiltersState['isAtHome']>) => {
      state.isAtHome = action.payload || initialState.isAtHome;
    },
    setIsRecurring: (
      state,
      action: PayloadAction<FiltersState['isRecurring']>,
    ) => {
      state.isRecurring = action.payload || initialState.isRecurring;
    },
    setLocalSelectedBadges: (
      state,
      action: PayloadAction<FiltersState['localSelectedBadges']>,
    ) => {
      state.localSelectedBadges =
        action.payload || initialState.localSelectedBadges;
    },
    setParticipants: (
      state,
      action: PayloadAction<FiltersState['participants']>,
    ) => {
      const participants = action.payload || initialState.participants;
      if (participants < 1) return;

      state.participants = participants;
    },
    setPkg: (state, action: PayloadAction<FiltersState['pkg']>) => {
      state.pkg = action.payload || initialState.pkg;
    },
    setSelectedBadges: (
      state,
      action: PayloadAction<FiltersState['selectedBadges']>,
    ) => {
      state.selectedBadges = action.payload || initialState.selectedBadges;
    },
    setSelectedLessonLength: (
      state,
      action: PayloadAction<FiltersState['selectedLessonLength']>,
    ) => {
      state.selectedLessonLength =
        action.payload || initialState.selectedLessonLength;
    },
    setSelectedSkill: (
      state,
      action: PayloadAction<FiltersState['selectedSkill']>,
    ) => {
      state.selectedSkill = action.payload || initialState.selectedSkill;
    },
    toggleIsAtHome: (state) => {
      state.isAtHome = !state.isAtHome;
    },
    toggleLocalSelectedBadge: (
      state,
      action: PayloadAction<FiltersState['localSelectedBadges'][0]>,
    ) => {
      const localBadge = action.payload;
      if (!localBadge) return;
      if (state.localSelectedBadges.some((b) => b.id === localBadge.id)) {
        state.localSelectedBadges = state.localSelectedBadges.filter(
          (b) => b.id !== localBadge.id,
        );
      } else {
        state.localSelectedBadges = [...state.localSelectedBadges, localBadge];
      }
    },
    toggleSelectedBadge: (
      state,
      action: PayloadAction<FiltersState['selectedBadges'][0]>,
    ) => {
      const badge = action.payload;
      if (!badge) return;
      if (state.selectedBadges.some((b) => b.id === badge.id)) {
        state.selectedBadges = state.selectedBadges.filter(
          (b) => b.id !== badge.id,
        );
      } else {
        state.selectedBadges = [...state.selectedBadges, badge];
      }
    },
  },
});

// export actions
export const {
  addSelectedBadges,
  clearAvailabilityFilterWeekDays,
  removeSelectedBadges,
  resetAvailabilityFilter,
  setAtHomeLocation,
  setAvailabilityFilter,
  setConfirmEmail,
  setConfirmName,
  setConfirmPhoneNumber,
  setDateTime,
  setIsAtHome,
  setIsRecurring,
  setLocalSelectedBadges,
  setParticipants,
  setPkg,
  setSelectedBadges,
  setSelectedLessonLength,
  setSelectedSkill,
  toggleIsAtHome,
  toggleLocalSelectedBadge,
  toggleSelectedBadge,
} = slice.actions;

// export selectors
export const selectAtHomeLocation = (state: RootState) =>
  state.filters.atHomeLocation;
export const selectAvailabilityFilter = (state: RootState) =>
  state.filters.availabilityFilter;
export const selectConfirmEmail = (state: RootState) =>
  state.filters.confirmEmail;
export const selectConfirmName = (state: RootState) =>
  state.filters.confirmName;
export const selectConfirmPhoneNumber = (state: RootState) =>
  state.filters.confirmPhoneNumber;
export const selectDateTime = (state: RootState) => state.filters.dateTime;
export const selectIsAtHome = (state: RootState) => state.filters.isAtHome;
export const selectIsRecurring = (state: RootState) =>
  state.filters.isRecurring;
export const selectLocalSelectedBadges = (state: RootState) =>
  state.filters.localSelectedBadges;
export const selectParticipants = (state: RootState) =>
  state.filters.participants;
export const selectPkg = (state: RootState) => state.filters.pkg;
export const selectSelectedBadges = (state: RootState) =>
  state.filters.selectedBadges;
export const selectSelectedLessonLength = (state: RootState) =>
  state.filters.selectedLessonLength;
export const selectSelectedSkill = (state: RootState) =>
  state.filters.selectedSkill;

// export reducer
export default slice.reducer;
