import { Button, Flex } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { UniqueListingQuery } from '../../../api';
import { useModal } from '../../../context/ModalContext';
import useIsMobile from '../../../hooks/useIsMobile';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
  selectSelectedSkill,
  setParticipants,
} from '../../../state/slices/filtersSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import { SelectedLocation } from '../../../utils/types';
import BookingWidgetBody from '../BookingWidgetBody';
import BookingWidgetHeader from '../BookingWidgetHeader';

interface Props {
  listing: Pick<
    UniqueListingQuery['findUniqueListing'],
    'id' | 'proName' | 'slug' | 'maxStudents'
  > & {
    locations?: SelectedLocation[];
    skill?: Pick<
      UniqueListingQuery['findUniqueListing']['skill'],
      'category' | 'slug'
    >;
  };
  nextUrl: string;
}

const possibleParticipants = [
  { value: 1, label: 'One' },
  { value: 2, label: 'Two' },
  { value: 3, label: 'Three' },
  { value: 4, label: 'Four or more' },
];

export default function Participants({ listing, nextUrl }: Props) {
  const { isMobile } = useIsMobile();
  const selectedSkill = useAppSelector(selectSelectedSkill);
  const { handleOpen } = useModal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  useEffect(() => {
    capturePostHogEvent('viewBookingWidgetParticipants', {
      listingSlug: listing?.slug,
    });
  }, [listing?.slug]);

  const maxParticipants = useMemo(
    () => listing?.maxStudents || selectedSkill?.maxStudents || 3,
    [listing, selectedSkill],
  );

  const onHandleParticipants = (participants: number) => {
    capturePostHogEvent('clickContinue', {
      action: 'BookingWidgetParticipants',
      listingSlug: listing?.slug,
    });
    if (participants <= maxParticipants) {
      dispatch(setParticipants(participants));

      const listingSearchParams = getListingSearchParamsWithPartialInput({
        participants,
      });
      const nextUrlWithSearchParams = `${nextUrl}?${listingSearchParams}`;

      navigate(nextUrlWithSearchParams);
    } else {
      handleOpen('chatRequest', null, `Request a lesson for 4+ people`);
    }
  };

  return (
    <>
      <BookingWidgetHeader heading="How many participants are there?" />

      <BookingWidgetBody flexGrow={2} isFullWidth={isMobile} pb="4">
        <Flex
          alignItems="center"
          flexDir="column"
          flexGrow={1}
          justifyContent="flex-end"
          px="4"
          pb="2"
        >
          {possibleParticipants.map((participant) => (
            <Button
              variant="outline"
              borderColor="border-default"
              _hover={
                isMobile
                  ? undefined
                  : { borderColor: 'gray.700', bg: 'gray.50' }
              }
              size="xl"
              w="full"
              rounded="full"
              mt="0"
              mb="2"
              key={participant.label}
              onClick={() => onHandleParticipants(participant.value)}
            >
              {participant.label}
            </Button>
          ))}
        </Flex>
      </BookingWidgetBody>
    </>
  );
}
