import { Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { IconMapPin } from '../../../assets/svg';
import { useHover } from '../../../context/HoverContext';
import useDistanceAwayFromCurrentLocation from '../../../hooks/useDistanceAwayFromCurrentLocation';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import useSelectedLocation from '../../../hooks/useSelectedLocation';
import { useAppDispatch } from '../../../state/hooks';
import { setSelectedListing } from '../../../state/slices/listingsSlice';
import {
  BookingStep,
  SelectedListing,
  type SelectedLocation,
} from '../../../utils/types';

export default function ProLocationItem({
  isMobile,
  listing,
  location,
  onClose,
}: {
  isMobile: boolean;
  listing: SelectedListing;
  location: SelectedLocation;
  onClose?(): void;
}): JSX.Element {
  const [, setSelectedLocation] = useSelectedLocation();
  const { onMouseLeave } = useHover();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const onClick = () => {
    setSelectedLocation(location);

    if (isMobile) {
      onClose?.();
      dispatch(setSelectedListing(listing));
      navigate(`/listing/map/${listing.slug}/${location.id}`);
    } else {
      onClose?.();
      dispatch(setSelectedListing(listing));
      navigate(
        `/listings/${listing?.skill?.slug}/${listing?.slug}/${
          BookingStep.Confirm
        }?${getListingSearchParamsWithPartialInput()}`,
      );
    }

    onMouseLeave?.();
  };

  const distanceAway = useDistanceAwayFromCurrentLocation([
    [location.latitude || 0, location.longitude || 0],
  ]);

  return (
    <Flex
      key={location.id}
      flexDir="row"
      alignItems="center"
      justifyContent="flex-start"
      bg="bg-muted"
      px="4"
      py="2"
      borderRadius="lg"
      mt="2"
      onClick={onClick}
      cursor="pointer"
    >
      <Flex flexShrink={0}>
        <IconMapPin w={26} />
      </Flex>

      <Flex
        flexDir="column"
        alignItems="flex-start"
        justifyContent="center"
        ml="2"
        flexGrow={1}
      >
        <Text textStyle="smallBold" noOfLines={1}>
          {location.placeName}
        </Text>

        <Flex flexDir="row">
          {location?.environmentSettings?.map((setting, index) => (
            <Text key={setting} textStyle="caption" noOfLines={1}>
              {index !== 0 && ' + '}
              {setting}
            </Text>
          ))}
          <Text textStyle="caption" noOfLines={1}>
            &nbsp;&bull;&nbsp;
            {distanceAway} {distanceAway === '1' ? 'mile' : 'miles'} away
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
