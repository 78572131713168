import {
  Box,
  FormControl,
  FormLabel,
  Icon,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { AsYouType } from 'libphonenumber-js';
import { useEffect, useRef, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  selectConfirmPhoneNumber,
  setConfirmPhoneNumber,
} from '../../state/slices/filtersSlice';
import { Country } from '../../types';
import countriesJson from '../../utils/countries.json';
import CountryList from '../CountryList';
import { InputField } from '../InputField';

interface Props {
  label: string;
  country: any;
  setCountry: any;
  error?: string;
  onChange: (arg: string) => void;
  labelFontSize?: string;
  labelFontWeight?: string;
  isRequired?: boolean;
}

export default function PhoneNumberInput({
  label = 'Phone',
  country,
  setCountry,
  error,
  onChange,
  labelFontSize,
  labelFontWeight,
  isRequired,
}: Props) {
  const ref = useRef(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const confirmPhoneNumber = useAppSelector(selectConfirmPhoneNumber);
  const [phoneNumber, setPhoneNumber] = useState(confirmPhoneNumber || '');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const dispatch = useAppDispatch();

  const location = useLocation();
  const isBecomeAProPage = location.pathname === '/become-a-pro';
  const countries = countriesJson as Country[];

  useOutsideClick({
    ref,
    handler: () => onClose(),
  });

  const onCountryChange = (item: Country) => {
    const nextCountry = countries.find((node) => node.code === item?.code);

    setCountry(nextCountry);
    onClose();
  };

  const handleNewPhoneNumber = (newPhoneNumber = '') => {
    const nextValue = new AsYouType(country?.code).input(newPhoneNumber);

    const nextPhoneNumber =
      nextValue === phoneNumber ? newPhoneNumber : nextValue;
    setPhoneNumber(nextPhoneNumber);
    onChange(newPhoneNumber);
    dispatch(setConfirmPhoneNumber(nextPhoneNumber));
  };

  const confirmPhoneNumberExists = !!confirmPhoneNumber;
  useEffect(() => {
    if (confirmPhoneNumberExists) handleNewPhoneNumber(confirmPhoneNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPhoneNumberExists]);

  const onPhoneNumberChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const { value } = event.target;

    handleNewPhoneNumber(value);
  };

  const handleOpenTooltip = () => {
    setIsTooltipOpen(true);
  };

  const handleCloseTooltip = () => {
    setIsTooltipOpen(false);
  };

  const handleToggleTooltip = () => {
    setIsTooltipOpen((prev) => !prev);
  };

  return (
    <Box ref={ref} as="section" position="relative" w="full">
      <FormControl isRequired={isRequired}>
        <Stack
          direction="row"
          align="center"
          justify="space-between"
          wrap="wrap"
          spacing="0"
          w="full"
        >
          <FormLabel
            htmlFor="phoneNumber"
            mb="0"
            textStyle="caption"
            color={error ? 'error' : 'defaultText'}
            fontSize={labelFontSize}
            fontWeight={labelFontWeight}
          >
            {label}
          </FormLabel>
          {!isBecomeAProPage && (
            <Tooltip
              hasArrow
              placement="top-start"
              label="We'll reach out by phone to help answer your questions! We will never sell or share your private data."
              textAlign="center"
              p="2"
              isOpen={isTooltipOpen}
            >
              <Text
                textStyle="caption"
                textDecoration="underline"
                color="defaultText"
                lineHeight="19px"
                fontSize="12px"
                onMouseOver={handleOpenTooltip}
                onMouseLeave={handleCloseTooltip}
                onClick={handleToggleTooltip}
              >
                Why do you need this?
              </Text>
            </Tooltip>
          )}
        </Stack>
        <InputGroup size="lg">
          <InputLeftElement
            px="8"
            py="6"
            cursor="pointer"
            borderRightWidth="1px"
            borderRightColor="border"
            onClick={onToggle}
          >
            <Stack direction="row" spacing="1" align="center">
              <Text as="span">{country?.flag}</Text>
              {isOpen ? (
                <Icon as={HiChevronUp} boxSize={6} color="muted" />
              ) : (
                <Icon as={HiChevronDown} boxSize={6} color="muted" />
              )}
            </Stack>
          </InputLeftElement>
          <InputField
            name="phoneNumber"
            pl="20"
            type="tel"
            autoComplete="tel"
            inputMode="tel"
            size="lg"
            fontSize="16px"
            borderRadius="lg"
            placeholder="(555) 555-5555"
            value={phoneNumber}
            onChange={onPhoneNumberChange}
            error={error}
          />
        </InputGroup>
      </FormControl>

      {isOpen ? (
        <CountryList data={countries} onChange={onCountryChange} />
      ) : null}
    </Box>
  );
}
