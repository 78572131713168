import { useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../state/hooks';
import {
  selectIsRecurring,
  selectPkg,
  setIsRecurring,
  setPkg,
} from '../../../../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../../../utils/capturePostHogEvent';
import PosthogEvent from '../../../../../utils/posthogEvents';
import CheckoutItem, { CheckoutItemAction } from '../../CheckoutItem';

type Props = {
  onPress: () => void;
};

export default function CheckoutNumberOfLessons({ onPress }: Props) {
  const isRecurring = useAppSelector(selectIsRecurring);
  const pkg = useAppSelector(selectPkg);
  const selectedListing = useAppSelector(selectSelectedListing);
  const dispatch = useAppDispatch();

  const label = useMemo(
    () =>
      isRecurring ? 'Weekly recurring' : `${pkg} lesson${pkg > 1 ? 's' : ''}`,
    [isRecurring, pkg],
  );

  const handleEdit = () => {
    capturePostHogEvent(PosthogEvent.ClickedEditNumberOfLessons, {
      ...selectedListing,
      isRecurring,
      pkg,
    });

    onPress();
  };

  useEffect(() => {
    dispatch(setIsRecurring(true));
    dispatch(setPkg(1));
  }, [dispatch]);

  return (
    <CheckoutItem title="Number of Lessons" subtitle={label}>
      <CheckoutItemAction.Button
        onPress={handleEdit}
        label="Edit"
        hasSelected
      />
    </CheckoutItem>
  );
}
