import {
  Box,
  Button,
  Heading,
  IconButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocalStorageValue } from '@react-hookz/web';
import Axios from 'axios';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HiX } from 'react-icons/hi';
import * as Yup from 'yup';
import 'yup-phone-lite';

import { useCreateLeadMutation } from '../../api';
import useCurrentLocation from '../../hooks/useCurrentLocation';
import { useError } from '../../hooks/useError';
import useIsMobile from '../../hooks/useIsMobile';
import useSelectedLocation from '../../hooks/useSelectedLocation';
import useTapfiliate from '../../hooks/useTapfiliate';
import { useAppSelector } from '../../state/hooks';
import {
  selectAvailabilityFilter,
  selectDateTime,
  selectIsAtHome,
  selectParticipants,
  selectSelectedBadges,
  selectSelectedSkill,
} from '../../state/slices/filtersSlice';
import capturePostHogEvent from '../../utils/capturePostHogEvent';
import getTimezone from '../../utils/getTimezone';
import BaseModal from '../BaseModal';
import { InputField } from '../InputField';
import PhoneNumberInput from '../PhoneNumberInput';
import { TextArea } from '../TextArea';

interface FormValues {
  name: string;
  phoneNumber: string;
  message: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  proName?: string;
}

export default function RequestTimeModal({ proName, isOpen, onClose }: Props) {
  const { handleError } = useError();
  const [createLead] = useCreateLeadMutation();
  const { isMobile } = useIsMobile();

  const [country, setCountry] = useState({
    name: 'United States',
    flag: '🇺🇸',
    code: 'US',
    dialCode: '+1',
  });

  const schema = Yup.object().shape({
    name: Yup.string().required('Required'),
    phoneNumber: Yup.string()
      // @ts-ignore
      .phone(country?.code, true, 'Invalid')
      .required('Required'),
    message: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const dateTime = useAppSelector(selectDateTime);
  const { currentLocation } = useCurrentLocation();
  const selectedBadges = useAppSelector(selectSelectedBadges);
  const availabilityFilter = useAppSelector(selectAvailabilityFilter);
  const isAtHome = useAppSelector(selectIsAtHome);
  const participants = useAppSelector(selectParticipants);
  const [selectedLocation] = useSelectedLocation();
  const selectedSkill = useAppSelector(selectSelectedSkill);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [posthogUserId] = useLocalStorageValue<string>('posthogUserId');
  const { tapfiliateReferralCode } = useTapfiliate();

  useEffect(() => {
    if (isOpen) capturePostHogEvent('viewRequestTimeModal');
  }, [isOpen]);

  const onSubmit = async (values: FormValues, type: string) => {
    try {
      setIsSubmitting(true);

      const res = await Axios(
        'https://api.ipregistry.co/?key=0lajp4q8lyh4jzb9',
      );

      await createLead({
        variables: {
          data: {
            type,
            name: values?.name,
            phoneNumber: parsePhoneNumber(
              values?.phoneNumber,
              country?.code as any,
            ).number,
            message: values?.message ?? '',
            timeZone: getTimezone(),
            country: res?.data?.location?.country?.code,
            skill: selectedSkill?.label,
            url: window?.location?.href,
            location: {
              placeName: selectedLocation?.placeName,
              address: selectedLocation?.address,
            },
            device: isMobile ? 'Mobile' : 'Desktop',
            currentLocation,
            posthogUserId,
            dateTime,
            proName,
            tapfiliateReferralCode,
            filters: {
              selectedBadges,
              availabilityFilter,
              participants,
              isAtHome,
            },
          },
        },
      });

      capturePostHogEvent('chatRequestSubmitted');

      onClose();
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <ModalContent
        display="flex"
        justifyContent="space-between"
        borderRadius={{ base: 'none', lg: '3xl' }}
        p={{ base: '2', md: '4' }}
      >
        <ModalHeader>
          <VStack align="flex-start" spacing="0">
            <Stack
              direction="row"
              align="center"
              justify="space-between"
              w="full"
            >
              <Heading variant="h4">Request a time</Heading>
              <IconButton
                aria-label="Close modal"
                icon={<HiX size={24} />}
                variant="ghost"
                onClick={onClose}
              />
            </Stack>
            <Text textAlign="left" fontSize="14px" color="muted" pb="2">
              Average response time:{' '}
              <Box as="span" fontWeight="bold">
                3 minutes
              </Box>
            </Text>
            <Text textAlign="left" fontSize="14px" color="muted" pb="2">
              We meet requests{' '}
              <Box as="span" fontWeight="bold">
                92% of the time!
              </Box>
            </Text>
          </VStack>
        </ModalHeader>

        <form>
          <ModalBody w="full">
            <Stack spacing="4">
              <InputField
                p="6"
                borderRadius="lg"
                placeholder="John Doe"
                type="name"
                autoComplete="name"
                label="Name"
                borderColor="gray.300"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('name')}
                error={errors?.name?.message as any}
              />
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field: { onChange } }) => (
                  <PhoneNumberInput
                    country={country}
                    setCountry={setCountry}
                    onChange={onChange}
                    error={errors?.phoneNumber?.message as any}
                    label="Phone number"
                  />
                )}
              />

              <TextArea
                rows={4}
                label="Requested date and time*"
                placeholder="3 PM on Friday 4/16/2023"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('message')}
                error={errors?.message?.message as any}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="slate"
              size="xl"
              w="full"
              isLoading={isSubmitting}
              onClick={handleSubmit((d) => onSubmit(d as FormValues, 'Text'))}
            >
              Request Lesson
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </BaseModal>
  );
}
