import { Flex, Text } from '@chakra-ui/react';

export default function CheckoutDetailsError() {
  return (
    <Flex
      alignItems="center"
      flexDir="column"
      flexGrow={1}
      justifyContent="flex-start"
      p="12"
    >
      <Text mb="4" textAlign="center" textStyle="bold">
        This listing is taking way too long to load.
      </Text>
      <Text textAlign="center">
        Sorry about that. Please try refreshing and contact us if the problem
        persists.
      </Text>
    </Flex>
  );
}
