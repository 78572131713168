import { Box, Spinner, Stack } from '@chakra-ui/react';
import { Fragment, lazy, Suspense, useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';

import { Skill } from './api';
import { useMainScroll } from './context/MainScrollContext';
import { useModal } from './context/ModalContext';
import { CAPTURE_EMAIL_SCROLL_PERCENT } from './hooks/useEmailCaptureFlagExperiment';
import useGetUserLocationFromIp from './hooks/useGetUserLocationFromIp';
import useHeaderHeight from './hooks/useHeaderHeight';
import useOrigin from './hooks/useOrigin';
import useStudentReferral from './hooks/useStudentReferral';
import { capturePageView } from './infra/gtmClient';
import Checkout from './screens/Checkout';
import BrowseSkeleton from './skeletons/BrowseSkeleton';
import ChooseAProSkeleton from './skeletons/ChooseAProSkeleton';
import ListingDetailsSkeleton from './skeletons/ListingDetailsSkeleton';
import { useAppDispatch } from './state/hooks';
import { setMainScrollPercent } from './state/slices/appSlice';
import {
  BASEBALL_SKILL_SLUG,
  BASKETBALL_SKILL_SLUG,
  BOXING_SKILL_SLUG,
  DANCE_SKILL_SLUG,
  FOOTBALL_SKILL_SLUG,
  GOLF_SKILL_SLUG,
  GUITAR_SKILL_SLUG,
  PERSONAL_TRAINING_SKILL_SLUG,
  PET_TRAINING_SLUG,
  PIANO_SKILL_SLUG,
  PICKLEBALL_SKILL_SLUG,
  SINGING_SKILL_SLUG,
  SURFING_SKILL_SLUG,
  TENNIS_SKILL_SLUG,
  VIOLIN_SKILL_SLUG,
  YOGA_SKILL_SLUG,
} from './utils/constants';
import { ListingSearchParam } from './utils/getListingSearchParams';

const AlgoliaSearch = lazy(() => import('./components/AlgoliaSearch'));

const About = lazy(() => import('./screens/About'));
const AdvertisementPage = lazy(() => import('./screens/AdvertisementPage'));
const AnnualReport = lazy(() => import('./screens/AnnualReport'));
const AnnualReport2024MidYear = lazy(
  () => import('./screens/AnnualReport/components/AnnualReport2024MidYear'),
);
const AnnualReport2023GolfCourses = lazy(
  () => import('./screens/AnnualReport/components/AnnualReport2023GolfCourses'),
);
const AnnualReport2023TennisCourts = lazy(
  () =>
    import('./screens/AnnualReport/components/AnnualReport2023TennisCourts'),
);
const BecomeAPro = lazy(() => import('./screens/BecomeAPro'));
const BookingSuccess = lazy(() => import('./screens/BookingSuccess'));
const Browse = lazy(() => import('./screens/Browse'));
const BrowseLocations = lazy(() => import('./screens/BrowseLocations'));
const BrowseLocationsChooseCity = lazy(
  () => import('./screens/BrowseLocationsChooseCity'),
);
const BrowseLocationsChooseState = lazy(
  () => import('./screens/BrowseLocationsChooseState'),
);
const ChooseAPro = lazy(() => import('./screens/ChooseAPro'));
const ChooseCity = lazy(() => import('./screens/ChooseCity'));
const FAQ = lazy(() => import('./screens/FAQ'));
const FAQPro = lazy(() => import('./screens/FAQPro'));
const Filters = lazy(() => import('./screens/Filters'));
const Gift = lazy(() => import('./screens/Gift'));
const GiftSuccess = lazy(() => import('./screens/GiftSuccess'));
const Home = lazy(() => import('./screens/Home'));
const ListingDetails = lazy(() => import('./screens/ListingDetails'));
const ListingMap = lazy(() => import('./screens/ListingMap'));
const NotFound = lazy(() => import('./screens/NotFound'));
const PrivacyPolicy = lazy(() => import('./screens/PrivacyPolicy'));
const ProLocations = lazy(() => import('./screens/ProLocations'));
const Rebooking = lazy(() => import('./screens/Rebooking'));
const Review = lazy(() => import('./screens/Review'));
const SkillHub = lazy(() => import('./screens/SkillHub'));
const Terms = lazy(() => import('./screens/Terms'));

const SKILL_SLUGS: Skill['slug'][] = [
  BASEBALL_SKILL_SLUG,
  BASKETBALL_SKILL_SLUG,
  BOXING_SKILL_SLUG,
  DANCE_SKILL_SLUG,
  FOOTBALL_SKILL_SLUG,
  GOLF_SKILL_SLUG,
  GUITAR_SKILL_SLUG,
  PERSONAL_TRAINING_SKILL_SLUG,
  PET_TRAINING_SLUG,
  PIANO_SKILL_SLUG,
  PICKLEBALL_SKILL_SLUG,
  SINGING_SKILL_SLUG,
  SURFING_SKILL_SLUG,
  TENNIS_SKILL_SLUG,
  VIOLIN_SKILL_SLUG,
  YOGA_SKILL_SLUG,
];

export default function Router() {
  const { shouldShowReferralRewardModal } = useStudentReferral();
  const { handleOpen } = useModal();
  const { mainScrollRef, shouldAddScrollRef } = useMainScroll();
  const { headerHeight } = useHeaderHeight();
  const fallbackScrollRef = useRef<HTMLDivElement>();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { origin, setOrigin } = useOrigin();

  useEffect(() => {
    if (!origin) {
      const paramsOrigin: string = searchParams.get(ListingSearchParam.Origin);
      if (paramsOrigin) {
        setOrigin(decodeURIComponent(paramsOrigin));
      } else {
        setOrigin(window.location.href);
      }
    }
  }, [origin, searchParams, setOrigin]);

  useGetUserLocationFromIp();

  // Need to know when the main div has scrolled to 10% for 'emailCapture' modal
  useEffect(() => {
    function onMainScroll() {
      const ref = mainScrollRef.current || fallbackScrollRef.current;
      const hasScrolledFarEnough =
        (ref?.scrollTop || 0) >=
        ((ref.scrollHeight - window.innerHeight) *
          CAPTURE_EMAIL_SCROLL_PERCENT) /
          100;
      if (hasScrolledFarEnough) {
        dispatch(setMainScrollPercent(CAPTURE_EMAIL_SCROLL_PERCENT));
        ref?.removeEventListener('scroll', onMainScroll);
      }
    }

    const ref = mainScrollRef.current || fallbackScrollRef.current;

    ref?.addEventListener('scroll', onMainScroll);

    return () => ref?.removeEventListener('scroll', onMainScroll);
  }, [dispatch, fallbackScrollRef, headerHeight, mainScrollRef]);

  // get the current page URL for GTM and track pageview
  const pageUrl = (window as any)?.location?.href;
  useEffect(() => {
    if (!pageUrl) return;
    capturePageView();
  }, [pageUrl]);

  // referral reward modal
  useEffect(() => {
    if (!shouldShowReferralRewardModal) return;
    handleOpen('referralRewardModal');
  }, [handleOpen, shouldShowReferralRewardModal]);

  return (
    <Box
      overflowX="hidden"
      height="100dvh"
      id="main-container"
      ref={shouldAddScrollRef ? mainScrollRef : fallbackScrollRef}
    >
      <Suspense
        fallback={
          <Routes>
            <Route path="/choose-a-pro" element={<ChooseAProSkeleton />} />
            <Route
              path="/listings/:skill/:slug"
              element={<ListingDetailsSkeleton />}
            />
            <Route
              path="/listings/:skill/:slug/:step"
              element={<ListingDetailsSkeleton />}
            />
            <Route
              path="/browse/:skill/:state/:city"
              element={<BrowseSkeleton />}
            />
            <Route
              path="*"
              element={
                <Stack align="center" justify="center" w="full" h="full">
                  <Spinner size="xl" />
                </Stack>
              }
            />
          </Routes>
        }
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/browse"
            element={<Navigate replace to="/browse/pickleball" />}
          />
          {SKILL_SLUGS.map((slug) => (
            <Fragment key={slug}>
              <Route
                path={`/${slug}`}
                element={<Navigate replace to={`/browse/${slug}`} />}
              />
              <Route
                path={`/${slug}/:tag`}
                element={<Navigate replace to={`/browse/${slug}`} />}
              />
            </Fragment>
          ))}

          <Route path="/:city/:area/:skill" element={<SkillHub />} />
          <Route
            path="/:city/:area/:target/:skill"
            element={<AdvertisementPage />}
          />
          <Route path="/choose-a-pro" element={<ChooseAPro />} />
          <Route path="/filter" element={<Filters />} />
          <Route path="/rebook/:skill/:slug" element={<Rebooking />} />
          <Route path="/listings/:skill/:slug" element={<ListingDetails />} />
          <Route
            path="/listings/:skill/:slug/:step"
            element={<ListingDetails />}
          />
          <Route
            path="/listing/map/:slug/:locationId"
            element={<ListingMap />}
          />
          <Route path="/listing/map/:slug" element={<ListingMap />} />
          <Route path="/bookings/success" element={<BookingSuccess />} />
          <Route path="/leave-a-review/:bookingId/:step" element={<Review />} />

          <Route path="/checkout/:skill/:slug" element={<Checkout />} />

          {/* Gifting */}
          <Route path="/gift" element={<Gift />} />
          <Route path="/gift/:step" element={<Gift />} />
          <Route path="/gift/success" element={<GiftSuccess />} />

          {/* SEO */}
          <Route path="/browse/:skill" element={<SkillHub />} />
          <Route path="/browse/:skill/:state" element={<ChooseCity />} />
          <Route path="/browse/:skill/:state/:city" element={<Browse />} />

          <Route
            path="/locations"
            element={<Navigate replace to="/locations/pickleball" />}
          />
          <Route
            path="/locations/:skill/:state"
            element={<BrowseLocationsChooseCity />}
          />
          <Route
            path="/locations/:skill"
            element={<BrowseLocationsChooseState />}
          />
          <Route
            path="/locations/:skill/:state/:city"
            element={
              <AlgoliaSearch indexName="locations">
                <BrowseLocations />
              </AlgoliaSearch>
            }
          />

          <Route
            path="/choose-your-location/:listingSlug"
            element={<ProLocations />}
          />

          {/* Static */}
          <Route path="/become-a-pro" element={<BecomeAPro />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/pro-faq" element={<FAQPro />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />

          {/* Annual Reports */}
          <Route
            path="/pickleball-data"
            element={<AnnualReport2024MidYear />}
          />
          <Route
            path="/pickleball-data/2023-annual-report"
            element={<AnnualReport />}
          />
          <Route
            path="/golf-data/2023-annual-course-report"
            element={<AnnualReport2023GolfCourses />}
          />
          <Route
            path="/tennis-data/2023-annual-court-report"
            element={<AnnualReport2023TennisCourts />}
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Box>
  );
}
