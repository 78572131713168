import useProTitle from '../../../hooks/useProTitle';

export enum QuestionContent {
  UserLocation = 'UserLocation',
  Experience = 'Experience',
  SkillLevel = 'SkillLevel',
  Ages = 'Ages',
  Equipment = 'Equipment',
  Location = 'Location',
  DateAndTime = 'DateAndTime',
  Finish = 'Finish',
  Primer = 'Primer',
}

export type QuizQuestionProps = {
  title: string | undefined;
  subtitle?: string;
  content: QuestionContent;
};

export const useQuestions = () => {
  const proTitle = useProTitle({ isPlural: false });
  const proTitlePlural = useProTitle({ isPlural: true });

  const allQuestions = {
    UserLocation: {
      title: `Let’s find you the right ${proTitle}!`,
      subtitle: `Is this the right location? We’ll use this location to find you the closest ${proTitlePlural} nearby.`,
      content: QuestionContent.UserLocation,
    },
    Experience: {
      title: 'Are any special skills or qualifications required?',
      subtitle: `Note: this will reduce the number of ${proTitlePlural} you see.`,
      content: QuestionContent.Experience,
    },
    SkillLevel: {
      title: `What's your skill level?`,
      content: QuestionContent.SkillLevel,
    },
    Ages: {
      title: 'How old are you?',
      content: QuestionContent.Ages,
    },
    Equipment_Sports: {
      title: 'Do you have your own equipment?',
      content: QuestionContent.Equipment,
    },
    Equipment_Music: {
      title: 'Do you have your own instrument?',
      content: QuestionContent.Equipment,
    },
    Location: {
      title: 'Where do you want your lessons?',
      content: QuestionContent.Location,
    },
    DateAndTime: {
      title: 'When do you want your first lesson?',
      content: QuestionContent.DateAndTime,
    },
    Finish: {
      title: undefined,
      content: QuestionContent.Finish,
    },
    Primer: {
      title: `Take our 1 minute quiz to find your perfect ${proTitle}`,
      content: QuestionContent.Primer,
    },
  };
  return allQuestions;
};

export { default as QuestionExperience } from './Experience';
export { default as QuestionSkillLevel } from './SkillLevel';
export { default as QuestionAges } from './Ages';
export { default as QuestionEquipment } from './Equipment';
export { default as QuestionLocation } from './Location';
export { default as QuestionDateTime } from './DateTime';
export { default as QuizFinish } from './Finish';
