import {
  BASKETBALL_SKILL_SLUG,
  DEFAULT_SKILL_SLUG,
  GOLF_SKILL_SLUG,
  TENNIS_SKILL_SLUG,
} from './constants';

import { Skill } from '../api';

export default function getLocationTypeBySkillSlug(
  skillSlug: Skill['slug'],
  isPlural = true,
): string {
  switch (skillSlug) {
    case GOLF_SKILL_SLUG:
      return isPlural ? 'courses' : 'course';
    case BASKETBALL_SKILL_SLUG:
    case DEFAULT_SKILL_SLUG:
    case TENNIS_SKILL_SLUG:
      return isPlural ? 'courts' : 'court';
    default:
      return isPlural ? 'locations' : 'location';
  }
}
