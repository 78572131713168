import { Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { addMinutes, format } from 'date-fns';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useLessonLength from '../../../hooks/useLessonLength';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import { useAppSelector } from '../../../state/hooks';
import {
  selectDateTime,
  selectIsRecurring,
} from '../../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import PosthogEvent from '../../../utils/posthogEvents';
import { BookingStep } from '../../../utils/types';

export default function DateAndTime() {
  const { lessonLength } = useLessonLength();
  const dateTime = useAppSelector(selectDateTime);
  const isRecurring = useAppSelector(selectIsRecurring);
  const selectedListing = useAppSelector(selectSelectedListing);
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();
  const navigate = useNavigate();
  const params = useParams();

  const listingUrl = `/listings/${selectedListing?.skill?.slug}/${
    selectedListing?.slug || params?.slug
  }`;

  const handleEdit = () => {
    capturePostHogEvent(PosthogEvent.ClickedEditTime, {
      ...selectedListing,
      dateTime,
    });

    navigate(
      `${listingUrl}/${BookingStep.Exposed}?from=${
        BookingStep.Confirm
      }&${getListingSearchParamsWithPartialInput()}`,
    );
  };

  const lessonStartAt = useMemo(
    () => (dateTime ? new Date(dateTime) : undefined),
    [dateTime],
  );

  const lessonEndAt = useMemo(
    () => (lessonStartAt ? addMinutes(lessonStartAt, lessonLength) : undefined),
    [lessonStartAt, lessonLength],
  );

  const formattedLessonDateAndTime = useMemo(() => {
    if (!lessonStartAt) return 'Schedule with coach after checkout';
    return `${format(lessonStartAt, `eee LLL d 'from' h:mm`)} - ${format(
      lessonEndAt,
      'h:mm a',
    )}`;
  }, [lessonEndAt, lessonStartAt]);

  return (
    <VStack alignItems="flex-start" width="full" mt={6}>
      <HStack justifyContent="space-between" width="full">
        <VStack alignItems="flex-start" justifyContent="center">
          <HStack>
            <Heading variant="h6">DATE AND TIME</Heading>
            {isRecurring && (
              <Text textStyle="small" color="slate.300">
                (first lesson)
              </Text>
            )}
          </HStack>
          <Text color="slate.500">{formattedLessonDateAndTime}</Text>
        </VStack>
        <HStack>
          <Text
            _hover={{ cursor: 'pointer' }}
            textStyle="link"
            color="slate.500"
            onClick={handleEdit}
          >
            Edit
          </Text>
        </HStack>
      </HStack>
    </VStack>
  );
}
