import { Flex, HStack, Image, Stack, Text, VStack } from '@chakra-ui/react';
import { EmojiConvertor } from 'emoji-js';
import { memo } from 'react';

import { type AlgoliaListingPayloadReview } from '../../api';
import ExpandableText from '../ExpandableText';
import StarRating from '../StarRating';

const emoji = new EmojiConvertor();

interface Props {
  hideRating?: boolean;
  review: Pick<
    AlgoliaListingPayloadReview,
    'avatar' | 'body' | 'name' | 'rating'
  > & { media?: { src: string; id: string }[] };
  lessonsCount?: number;
  userExperience?: string;
  expandable?: boolean;
  onImageClick?: (index: number) => void;
  mediaSize?: 'regular' | 'large';
  noOfLines?: number;
  isConsistentHeight?: boolean;
}

const mediaSizes = {
  regular: '56px',
  large: '96px',
};

const ReviewItem = memo(
  ({
    hideRating = false,
    review,
    onImageClick,
    lessonsCount,
    userExperience,
    expandable = false,
    mediaSize = 'regular',
    noOfLines = 3,
    isConsistentHeight = true,
  }: Props) => {
    const experience = userExperience || 'Beginner';
    if (!review?.body) {
      return null;
    }

    return (
      <Stack w="full" p="6" gap={0}>
        <VStack gap={0} align="flex-start" mb="3">
          <Text fontWeight="800" fontSize="16px">
            {review?.name}
          </Text>
          <HStack gap="2">
            <Text
              fontSize="11px"
              fontWeight="900"
              letterSpacing="0.385px"
              textTransform="uppercase"
              color="#718096"
            >
              {experience}
            </Text>
            {lessonsCount && (
              <>
                <Text
                  fontSize="11px"
                  fontWeight="900"
                  letterSpacing="0.385px"
                  textTransform="uppercase"
                  color="#718096"
                >
                  •
                </Text>
                <Text
                  fontSize="11px"
                  fontWeight="900"
                  letterSpacing="0.385px"
                  textTransform="uppercase"
                  color="#718096"
                >
                  {lessonsCount} lesson{lessonsCount > 1 ? 's' : ''}
                </Text>
              </>
            )}
          </HStack>
        </VStack>
        {hideRating ? null : (
          <StarRating
            numOfStars={Number(review?.rating)}
            rating={Number(review?.rating)}
          />
        )}
        {expandable && review.body?.length ? (
          <ExpandableText isConsistentHeight={isConsistentHeight} mt="2" noOfLines={noOfLines}>
            {emoji.replace_colons(review.body)}
          </ExpandableText>
        ) : (
          <Text mt="2" whiteSpace="pre-line">
            {emoji.replace_colons(review.body)}
          </Text>
        )}

        <Flex alignItems="center" gap="2" mt="4" overflow="hidden">
          {(review.media || []).map((media, index) => (
            <Image
              flexShrink={0}
              borderRadius="4"
              key={media.id}
              alt={`review-img-${media.id}`}
              src={media.src}
              h={mediaSizes[mediaSize]}
              w={mediaSizes[mediaSize]}
              objectFit="cover"
              cursor="pointer"
              loading="lazy"
              onClick={() => {
                onImageClick(index);
              }}
            />
          ))}
        </Flex>
      </Stack>
    );
  },
);

export default ReviewItem;
