import { Box } from '@chakra-ui/react';

interface IconAtHomeLessonsProps {
  w: number;
  h: number;
}

export default function IconAtHomeLessons({
  w = 257,
  h = 218,
}: IconAtHomeLessonsProps) {
  return (
    <Box w={w} h={h}>
      <svg
        width={w}
        height={h}
        viewBox="0 0 257 218"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_23718_30894)">
          <path
            d="M197.359 202.236H80.9807C80.2592 202.235 79.5675 201.947 79.0574 201.435C78.5472 200.923 78.2602 200.229 78.2594 199.505V119.746C78.2602 119.022 78.5472 118.328 79.0574 117.816C79.5675 117.304 80.2592 117.016 80.9807 117.015H197.359C198.08 117.016 198.772 117.304 199.282 117.816C199.792 118.328 200.079 119.022 200.08 119.746V199.505C200.079 200.229 199.792 200.923 199.282 201.435C198.772 201.947 198.08 202.235 197.359 202.236Z"
            fill="white"
          />
          <path
            d="M197.359 202.236H80.9807C80.2592 202.235 79.5675 201.947 79.0574 201.435C78.5472 200.923 78.2602 200.229 78.2594 199.505V119.746C78.2602 119.022 78.5472 118.328 79.0574 117.816C79.5675 117.304 80.2592 117.016 80.9807 117.015H197.359C198.08 117.016 198.772 117.304 199.282 117.816C199.792 118.328 200.079 119.022 200.08 119.746V199.505C200.079 200.229 199.792 200.923 199.282 201.435C198.772 201.947 198.08 202.235 197.359 202.236ZM80.9807 117.622C80.4196 117.623 79.8816 117.847 79.4848 118.245C79.088 118.643 78.8648 119.183 78.8642 119.746V199.505C78.8648 200.068 79.088 200.608 79.4848 201.006C79.8816 201.404 80.4196 201.628 80.9807 201.629H197.359C197.92 201.628 198.458 201.404 198.855 201.006C199.252 200.608 199.475 200.068 199.475 199.505V119.746C199.475 119.183 199.252 118.643 198.855 118.245C198.458 117.847 197.92 117.623 197.359 117.622H80.9807Z"
            fill="#3F3D56"
          />
          <path
            d="M152.641 201.756H125.698V156.988C125.699 155.927 126.12 154.909 126.868 154.158C127.616 153.407 128.631 152.985 129.688 152.984H148.651C149.709 152.985 150.723 153.407 151.471 154.158C152.219 154.909 152.64 155.927 152.641 156.988V201.756Z"
            fill="#E6E6E6"
          />
          <path
            d="M191.012 100.014H175.625V74.2055C175.626 73.4814 175.913 72.7871 176.423 72.2751C176.933 71.7631 177.625 71.4751 178.346 71.4742H188.291C189.012 71.4751 189.704 71.7631 190.214 72.2751C190.724 72.7871 191.011 73.4814 191.012 74.2055V100.014Z"
            fill="white"
          />
          <path
            d="M191.012 100.014H175.625V74.2055C175.626 73.4814 175.913 72.7871 176.423 72.2751C176.933 71.7631 177.625 71.4751 178.346 71.4742H188.291C189.012 71.4751 189.704 71.7631 190.214 72.2751C190.724 72.7871 191.011 73.4814 191.012 74.2055V100.014ZM176.23 99.4068H190.407V74.2055C190.407 73.6423 190.183 73.1023 189.787 72.7041C189.39 72.3058 188.852 72.0818 188.291 72.0812H178.346C177.785 72.0818 177.247 72.3058 176.851 72.7041C176.454 73.1023 176.231 73.6423 176.23 74.2055V99.4068Z"
            fill="#3F3D56"
          />
          <path
            d="M104.261 110.881C120.625 110.881 133.891 97.5674 133.891 81.1439C133.891 64.7203 120.625 51.4064 104.261 51.4064C87.8976 51.4064 74.6322 64.7203 74.6322 81.1439C74.6322 97.5674 87.8976 110.881 104.261 110.881Z"
            fill="#F2F2F2"
          />
          <path
            d="M183.78 66.9497C181.111 66.8655 178.736 64.4229 178.717 61.7435C178.698 59.064 181.038 56.5876 183.705 56.4652C189.643 56.1926 194.505 49.7966 193.198 43.9763C191.892 38.1559 184.766 34.471 179.286 36.781C175.081 38.5531 170.832 32.9697 171.898 28.5197C172.964 24.0696 176.996 21.0814 180.727 18.45C184.458 15.8186 188.449 12.729 189.35 8.24236C190.25 3.75573 185.64 -1.61874 181.578 0.460995"
            fill="#E6E6E6"
          />
          <path
            d="M114.318 165.988H91.5591C90.6512 165.987 89.7809 165.624 89.1389 164.98C88.497 164.336 88.1359 163.462 88.1349 162.551V143.719C88.136 142.808 88.497 141.935 89.139 141.29C89.7809 140.646 90.6512 140.284 91.559 140.283H114.318C115.226 140.284 116.096 140.646 116.738 141.291C117.38 141.935 117.741 142.808 117.742 143.719V162.551C117.741 163.462 117.38 164.336 116.738 164.98C116.096 165.624 115.226 165.987 114.318 165.988Z"
            fill="#E6E6E6"
          />
          <path
            d="M187.307 165.988H164.548C163.64 165.987 162.77 165.624 162.128 164.98C161.486 164.336 161.125 163.462 161.124 162.551V143.719C161.125 142.808 161.486 141.935 162.128 141.29C162.77 140.646 163.64 140.284 164.548 140.283H187.307C188.215 140.284 189.085 140.646 189.727 141.29C190.369 141.935 190.73 142.808 190.731 143.719V162.551C190.73 163.462 190.369 164.336 189.727 164.98C189.085 165.624 188.215 165.987 187.307 165.988Z"
            fill="#E6E6E6"
          />
          <path
            d="M69.5705 118.655V106.384C69.5705 105.441 69.8188 104.515 70.2902 103.7C70.7615 102.885 71.4393 102.209 72.2547 101.741L140.189 62.7715C141.032 62.2878 141.991 62.0446 142.962 62.0682C143.933 62.0919 144.879 62.3815 145.698 62.9057L206.306 101.704C207.061 102.188 207.683 102.854 208.113 103.642C208.543 104.43 208.769 105.314 208.769 106.213V118.655C208.769 120.074 208.208 121.434 207.209 122.437C206.209 123.44 204.854 124.003 203.441 124.003H74.8987C73.4855 124.003 72.1303 123.44 71.1311 122.437C70.1319 121.434 69.5705 120.074 69.5705 118.655Z"
            fill="#3182CE"
          />
          <path
            opacity="0.2"
            d="M203.441 118.54H74.8986C73.4855 118.54 72.1303 117.976 71.1311 116.973C70.1319 115.97 69.5705 114.61 69.5705 113.192V118.048C69.5705 119.466 70.1319 120.826 71.1311 121.829C72.1303 122.832 73.4855 123.395 74.8986 123.395H203.441C204.854 123.395 206.209 122.832 207.209 121.829C208.208 120.826 208.769 119.466 208.769 118.048V113.192C208.769 114.61 208.208 115.97 207.209 116.973C206.209 117.976 204.854 118.54 203.441 118.54Z"
            fill="#2D3748"
          />
          <path
            d="M213.878 160.533C213.878 151.122 221.479 128.792 230.855 128.792C240.231 128.792 247.832 151.122 247.832 160.533C247.832 165.052 246.043 169.386 242.859 172.581C239.676 175.776 235.357 177.572 230.855 177.572C226.352 177.572 222.034 175.776 218.85 172.581C215.666 169.386 213.878 165.052 213.878 160.533Z"
            fill="#F2F2F2"
          />
          <path
            d="M230.855 201.874C230.775 201.874 230.698 201.842 230.641 201.785C230.584 201.728 230.552 201.651 230.552 201.57V140.388C230.552 140.307 230.584 140.23 230.641 140.173C230.698 140.116 230.775 140.084 230.855 140.084C230.935 140.084 231.012 140.116 231.069 140.173C231.125 140.23 231.157 140.307 231.157 140.388V201.57C231.157 201.651 231.125 201.728 231.069 201.785C231.012 201.842 230.935 201.874 230.855 201.874Z"
            fill="#3F3D56"
          />
          <path
            d="M230.855 167.52C230.795 167.52 230.738 167.502 230.688 167.469C230.639 167.437 230.6 167.39 230.577 167.336C230.553 167.281 230.547 167.221 230.557 167.162C230.568 167.104 230.595 167.05 230.636 167.007L239.266 157.917C239.293 157.888 239.326 157.865 239.363 157.849C239.399 157.833 239.438 157.824 239.478 157.823C239.517 157.822 239.557 157.829 239.594 157.844C239.631 157.858 239.665 157.879 239.694 157.907C239.722 157.934 239.745 157.967 239.761 158.004C239.777 158.04 239.786 158.079 239.787 158.119C239.788 158.159 239.781 158.199 239.767 158.236C239.753 158.273 239.731 158.307 239.704 158.336L231.074 167.425C231.045 167.455 231.011 167.479 230.974 167.495C230.936 167.511 230.896 167.52 230.855 167.52Z"
            fill="#3F3D56"
          />
          <path
            d="M230.855 152.195C230.779 152.195 230.706 152.167 230.651 152.115L223.193 145.272C223.163 145.245 223.139 145.212 223.122 145.176C223.105 145.14 223.096 145.101 223.094 145.061C223.092 145.021 223.098 144.981 223.112 144.944C223.125 144.906 223.146 144.872 223.173 144.842C223.2 144.813 223.232 144.789 223.268 144.772C223.304 144.755 223.343 144.746 223.383 144.744C223.423 144.742 223.462 144.748 223.5 144.762C223.537 144.776 223.571 144.797 223.601 144.824L231.059 151.668C231.104 151.709 231.135 151.763 231.149 151.822C231.163 151.882 231.159 151.944 231.137 152.001C231.115 152.058 231.076 152.107 231.025 152.142C230.975 152.176 230.916 152.195 230.855 152.195Z"
            fill="#3F3D56"
          />
          <path
            d="M6.66048 141.809C6.66048 127.966 17.8417 95.1168 31.6344 95.1168C45.4271 95.1168 56.6083 127.966 56.6083 141.809C56.6083 148.457 53.9772 154.832 49.2936 159.533C44.6101 164.233 38.2579 166.874 31.6344 166.874C25.0109 166.874 18.6587 164.233 13.9752 159.533C9.29166 154.832 6.66048 148.457 6.66048 141.809Z"
            fill="#3182CE"
          />
          <path
            d="M31.6344 202.481C31.5543 202.481 31.4773 202.449 31.4206 202.392C31.3639 202.335 31.3321 202.258 31.3321 202.177V112.175C31.3321 112.094 31.3639 112.017 31.4206 111.96C31.4773 111.903 31.5542 111.871 31.6344 111.871C31.7146 111.871 31.7915 111.903 31.8482 111.96C31.9049 112.017 31.9368 112.094 31.9368 112.175V202.177C31.9368 202.258 31.9049 202.335 31.8482 202.392C31.7915 202.449 31.7146 202.481 31.6344 202.481Z"
            fill="#3F3D56"
          />
          <path
            d="M31.6344 151.944C31.5752 151.944 31.5173 151.927 31.4679 151.894C31.4185 151.861 31.3798 151.815 31.3565 151.76C31.3331 151.705 31.3263 151.645 31.3368 151.587C31.3473 151.528 31.3747 151.474 31.4156 151.431L44.1108 138.06C44.1661 138.002 44.2423 137.968 44.3224 137.966C44.4026 137.964 44.4803 137.994 44.5383 138.05C44.5964 138.106 44.6301 138.182 44.632 138.262C44.634 138.343 44.604 138.421 44.5486 138.479L31.8533 151.85C31.8251 151.88 31.7911 151.903 31.7535 151.92C31.7159 151.936 31.6753 151.944 31.6344 151.944Z"
            fill="#3F3D56"
          />
          <path
            d="M31.6344 129.401C31.5589 129.401 31.4861 129.373 31.4304 129.322L20.4591 119.254C20.3999 119.2 20.3647 119.124 20.3611 119.043C20.3575 118.963 20.3859 118.885 20.44 118.825C20.4941 118.766 20.5695 118.73 20.6496 118.727C20.7297 118.723 20.808 118.752 20.8672 118.806L31.8385 128.874C31.8834 128.915 31.915 128.969 31.9288 129.028C31.9427 129.088 31.9383 129.15 31.9163 129.207C31.8942 129.265 31.8555 129.313 31.8052 129.348C31.7549 129.383 31.6953 129.401 31.6344 129.401Z"
            fill="#3F3D56"
          />
          <path
            d="M218.995 201.844C219.024 201.866 219.054 201.886 219.083 201.908H243.668C244.15 199.949 244.32 197.988 243.601 196.285C242.575 193.858 239.541 192.343 237.129 193.385C236.557 193.639 236.059 194.034 235.682 194.534C234.739 192.841 233.048 191.564 231.097 191.565C228.651 191.567 226.618 193.574 225.954 195.892C224.886 194.082 222.775 192.817 220.713 193.361C217.262 194.272 216.173 199.655 218.995 201.844Z"
            fill="#E6E6E6"
          />
          <path
            d="M7.78312 202.386C7.84085 202.431 7.90182 202.471 7.96029 202.515H57.5912C58.5637 198.56 58.9071 194.601 57.4545 191.163C55.3847 186.265 49.2595 183.206 44.3898 185.31C43.236 185.822 42.2309 186.62 41.4692 187.63C39.5661 184.211 36.1524 181.633 32.2121 181.636C27.2755 181.639 23.1709 185.691 21.8314 190.371C19.6746 186.717 15.4141 184.163 11.2498 185.262C4.28496 187.099 2.08499 197.966 7.78312 202.386Z"
            fill="#E6E6E6"
          />
          <path
            d="M256.999 201.634L0 202.119L0.00115312 202.726L257 202.241L256.999 201.634Z"
            fill="#3F3D56"
          />
          <path
            d="M86.7225 189.28C86.7838 189.04 86.792 188.789 86.7465 188.546C86.7009 188.303 86.6028 188.072 86.4589 187.871C86.3151 187.67 86.129 187.503 85.9139 187.381C85.6987 187.26 85.4597 187.188 85.2136 187.169L75.6114 175.12L73.2355 177.343L83.3791 188.852C83.3756 189.269 83.5246 189.673 83.7978 189.987C84.071 190.301 84.4496 190.504 84.8617 190.556C85.2738 190.609 85.6909 190.508 86.0338 190.273C86.3768 190.038 86.6218 189.684 86.7225 189.28Z"
            fill="#FFB8B8"
          />
          <path
            d="M77.4535 177.034L74.4189 179.367C74.3325 179.433 74.2329 179.48 74.1269 179.504C74.0209 179.529 73.9109 179.53 73.8044 179.507C73.6979 179.485 73.5975 179.44 73.5099 179.375C73.4224 179.311 73.3497 179.228 73.297 179.132L71.4484 175.788C71.1127 175.342 70.9666 174.782 71.0422 174.229C71.1178 173.675 71.4088 173.175 71.8515 172.836C72.2942 172.498 72.8525 172.35 73.4039 172.424C73.9553 172.498 74.4549 172.789 74.7932 173.232L77.5203 175.886C77.5983 175.962 77.6592 176.054 77.6986 176.156C77.738 176.258 77.7552 176.367 77.7488 176.476C77.7425 176.585 77.7128 176.691 77.6618 176.787C77.6109 176.884 77.5398 176.968 77.4535 177.034Z"
            fill="#3F3D56"
          />
          <path
            d="M72.3571 169.76C74.4884 169.76 76.2161 168.026 76.2161 165.887C76.2161 163.748 74.4884 162.014 72.3571 162.014C70.2259 162.014 68.4982 163.748 68.4982 165.887C68.4982 168.026 70.2259 169.76 72.3571 169.76Z"
            fill="#FFB8B8"
          />
          <path
            d="M80.5944 216.126H82.5208L82.3372 212.508H79.4944L80.5944 216.126Z"
            fill="#FFB8B8"
          />
          <path
            d="M80.1031 215.495L83.8965 215.495C84.214 215.495 84.5285 215.557 84.8218 215.679C85.1151 215.801 85.3816 215.98 85.606 216.205C85.8305 216.431 86.0086 216.698 86.1301 216.992C86.2516 217.287 86.3141 217.602 86.3141 217.921V218L80.1033 218L80.1031 215.495Z"
            fill="#2F2E41"
          />
          <path
            d="M60.0575 213.389L61.6963 214.405L63.4349 211.23L61.452 209.985L60.0575 213.389Z"
            fill="#FFB8B8"
          />
          <path
            d="M59.97 212.593L63.1973 214.594C63.4674 214.761 63.7021 214.98 63.8878 215.239C64.0734 215.497 64.2066 215.79 64.2796 216.1C64.3526 216.41 64.364 216.731 64.3132 217.046C64.2624 217.36 64.1504 217.662 63.9836 217.933L63.9423 218L58.6583 214.724L59.97 212.593Z"
            fill="#2F2E41"
          />
          <path
            d="M80.4552 215.065C80.312 215.065 80.1722 215.021 80.0544 214.94C79.9366 214.858 79.8464 214.742 79.7957 214.608L72.8282 197.888C72.8132 197.848 72.7873 197.812 72.7533 197.785C72.7193 197.758 72.6785 197.741 72.6355 197.735C72.5924 197.73 72.5488 197.737 72.5093 197.755C72.4699 197.773 72.4361 197.802 72.4118 197.838L62.8399 212.971C62.7462 213.112 62.6053 213.215 62.4427 213.26C62.2801 213.306 62.1067 213.291 61.954 213.219L60.6442 212.605C60.4765 212.526 60.3463 212.384 60.2817 212.209C60.217 212.035 60.2229 211.842 60.2981 211.672L68.1401 194.127C67.9689 193.709 66.2987 189.416 68.8649 187.295L68.8828 187.284L75.8414 184.169L76.0168 189.526L82.9526 213.679C82.9787 213.77 82.9864 213.865 82.9754 213.959C82.9643 214.053 82.9346 214.143 82.8881 214.226C82.8415 214.308 82.7791 214.38 82.7044 214.438C82.6297 214.495 82.5442 214.538 82.4531 214.562L80.6364 215.041C80.5773 215.057 80.5164 215.065 80.4552 215.065Z"
            fill="#2F2E41"
          />
          <path
            d="M75.9467 176.332C75.6817 175.265 75.3061 174.189 74.8027 173.692C74.6177 173.511 74.4438 173.319 74.282 173.117C73.9434 172.689 73.5174 172.338 73.0327 172.089C73.2101 171.99 73.3141 171.872 73.3141 171.745C73.3141 171.397 72.5403 171.114 71.5858 171.114C70.6313 171.114 69.8575 171.397 69.8575 171.745C69.8599 171.785 69.8701 171.825 69.8878 171.861C69.9054 171.898 69.93 171.93 69.9602 171.957C69.337 172.212 68.7954 172.634 68.3944 173.177C67.9935 173.719 67.7486 174.362 67.6865 175.035C67.4633 177.434 67.3981 180.501 68.1804 182.441C68.8365 184.045 68.8887 185.834 68.3272 187.474C68.2891 187.589 68.2845 187.711 68.3138 187.828C68.3431 187.945 68.4052 188.051 68.4926 188.133C68.58 188.216 68.6892 188.271 68.8072 188.293C68.9251 188.315 69.0469 188.303 69.1581 188.258L76.0628 185.465C76.1791 185.418 76.2786 185.338 76.3488 185.233C76.4189 185.129 76.4564 185.006 76.4564 184.88V180.639C76.4619 179.188 76.2907 177.741 75.9467 176.332Z"
            fill="#3F3D56"
          />
          <path
            d="M62.7356 193.801C62.976 193.745 63.2012 193.637 63.3953 193.484C63.5895 193.331 63.7479 193.137 63.8594 192.917C63.971 192.696 64.0329 192.453 64.0408 192.205C64.0487 191.958 64.0024 191.712 63.9053 191.484L69.3076 177.185L66.568 176.475L61.5711 190.627C61.2009 190.816 60.9127 191.135 60.7611 191.523C60.6095 191.911 60.6051 192.342 60.7486 192.733C60.8921 193.124 61.1737 193.449 61.5399 193.646C61.9061 193.843 62.3316 193.898 62.7356 193.801Z"
            fill="#FFB8B8"
          />
          <path
            d="M69.2851 179.898L65.8233 178.272C65.7248 178.226 65.6374 178.159 65.5671 178.075C65.4967 177.992 65.4451 177.895 65.4156 177.79C65.3862 177.685 65.3797 177.574 65.3965 177.466C65.4133 177.359 65.4531 177.256 65.5131 177.165L67.617 173.976C67.8562 173.472 68.2845 173.084 68.8081 172.897C69.3318 172.709 69.9081 172.737 70.4109 172.975C70.9137 173.214 71.3021 173.642 71.491 174.167C71.6799 174.691 71.654 175.27 71.4188 175.776L70.331 179.428C70.2998 179.533 70.2465 179.629 70.1748 179.711C70.103 179.793 70.0145 179.859 69.9153 179.904C69.816 179.948 69.7084 179.971 69.5996 179.97C69.4909 179.969 69.3836 179.945 69.2851 179.898Z"
            fill="#3F3D56"
          />
          <path
            d="M75.9017 164.934C76.0758 164.955 76.2521 164.923 76.4079 164.842C76.6926 164.655 76.6646 164.23 76.5683 163.903C76.3282 163.118 75.8951 162.406 75.3087 161.834C74.7224 161.261 74.0018 160.846 73.2135 160.626C72.6641 160.48 71.969 160.485 71.6547 160.961C70.8649 160.433 69.9127 160.206 68.9708 160.322C68.0289 160.439 67.1596 160.89 66.5207 161.594C65.8962 162.329 65.4799 163.219 65.3153 164.171C64.9856 165.793 63.5734 169.137 64.3557 170.594C64.8762 171.563 67.2718 170.808 68.2851 171.23C68.7898 171.451 69.3409 171.543 69.8895 171.498C70.4381 171.454 70.9673 171.274 71.4302 170.975C71.8833 170.662 72.2238 170.21 72.4015 169.687C72.5792 169.164 72.5848 168.598 72.4172 168.072C72.2398 167.685 72.0353 167.311 71.8051 166.953C71.5741 166.591 71.4798 166.158 71.5394 165.732C71.6813 165.085 74.7271 164.91 75.9017 164.934Z"
            fill="#2F2E41"
          />
        </g>
        <defs>
          <clipPath id="clip0_23718_30894">
            <rect width="257" height="218" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}
