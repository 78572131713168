import {
  Center,
  FormControl,
  HStack,
  IconButton,
  IconButtonProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { HiMinus, HiPlus } from 'react-icons/hi';

import useLessonLength from '../../hooks/useLessonLength';

function QuantityPickerButton(props: IconButtonProps) {
  return (
    <IconButton
      size="sm"
      fontSize="md"
      _focus={{ boxShadow: 'none' }}
      _focusVisible={{ boxShadow: 'outline' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

type Props = {
  handleChange?: (value: number) => void | undefined;
};

export default function LessonLengthPicker({ handleChange }: Props) {
  const {
    lessonLength,
    lessonLengthOptions,
    setSelectedLessonLengthForCurrentCategory,
  } = useLessonLength();

  const defaultIndex = lessonLengthOptions.possibleLengths.findIndex(
    (item) => item.minutes === lessonLength,
  );

  const [currentItemIndex, setCurrentItemIndex] = useState(
    defaultIndex > 0 ? defaultIndex : 0,
  );

  const options = lessonLengthOptions.possibleLengths;

  const handlePrev = () => {
    const current = currentItemIndex;
    setCurrentItemIndex((currIndex) => currIndex - 1);
    const length = options[current - 1].minutes;
    if (handleChange) {
      handleChange(length);
    } else {
      setSelectedLessonLengthForCurrentCategory(length);
    }
  };

  const handleNext = () => {
    const current = currentItemIndex;
    setCurrentItemIndex((currIndex) => currIndex + 1);
    const length = options[current + 1].minutes;
    if (handleChange) {
      handleChange(length);
    } else {
      setSelectedLessonLengthForCurrentCategory(length);
    }
  };

  return (
    <FormControl>
      <VStack>
        <Text
          as="span"
          textStyle="overline"
          textColor="muted"
          userSelect="none"
        >
          Lesson Length
        </Text>
        <HStack
          alignItems="center"
          borderRadius="full"
          py="2"
          px="4"
          borderWidth={1}
          borderColor="gray.300"
          h="48px"
          spacing={0}
          width="full"
        >
          <QuantityPickerButton
            variant="ghost"
            aria-label="Decrement"
            isDisabled={currentItemIndex === 0}
            icon={<HiMinus />}
            onClick={handlePrev}
            m={0}
          />
          <Center minW="14" textAlign="center" m={0} flex={1}>
            <Text
              as="span"
              textStyle="bold"
              userSelect="none"
              textAlign="center"
              lineHeight="14px"
            >
              {options[currentItemIndex].shortLabel}
            </Text>
          </Center>
          <QuantityPickerButton
            m={0}
            variant="ghost"
            aria-label="Increment"
            isDisabled={currentItemIndex >= options.length - 1}
            icon={<HiPlus />}
            onClick={handleNext}
          />
        </HStack>
      </VStack>
    </FormControl>
  );
}
