import {
  Button,
  Heading,
  IconButton,
  Image,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  VStack,
} from '@chakra-ui/react';
import { HiX } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import letterOpenCheck from '../../assets/become-a-pro/letter-open-check.svg';
import WithAnimOnLayout from '../../hocs/WithAnimOnLayout';
import BaseModal from '../BaseModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function BecomeAProResultModal({ isOpen, onClose }: Props) {
  const navigate = useNavigate();

  const onClickReturnToHomePage = () => {
    navigate('/');
    onClose();
  };

  return (
    <BaseModal
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      motionPreset="slideInBottom"
    >
      <ModalContent
        display="flex"
        justifyContent="space-between"
        p={{ base: '4', md: '10' }}
        position={{ base: 'fixed', md: 'unset' }}
        bottom="0px"
        mb={{ base: '0', md: 'auto' }}
        borderRadius={{ base: '1.75rem 1.75rem 0px 0px', md: '2xl' }}
      >
        <WithAnimOnLayout style={{ alignSelf: 'flex-end' }}>
          <IconButton
            alignSelf="flex-end"
            aria-label="Go back"
            icon={<HiX size={24} />}
            onClick={onClose}
            size="sm"
            variant="ghost"
          />
        </WithAnimOnLayout>
        <ModalHeader p={1}>
          <VStack align="center" spacing="6" w="full">
            <Image h="101px" src={letterOpenCheck} w="auto" />
            <Heading variant="h3" textAlign="center">
              Application submitted!
            </Heading>
            <Text
              textStyle="small"
              textAlign="center"
              color="muted"
              fontSize="16px"
            >
              Our team is reviewing your instructor application and will reach
              out shortly.
            </Text>
          </VStack>
        </ModalHeader>
        <ModalFooter pt="8" justifyContent="center">
          <VStack spacing="4">
            <Button size="xl" type="submit" onClick={onClickReturnToHomePage}>
              Go to homepage
            </Button>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </BaseModal>
  );
}
