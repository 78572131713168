import { Box, Skeleton, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';

import { QuestionProps } from './interface';

import useFilterBadgeGroups from '../../../hooks/useFilterBadgeGroups';
import useLocalSelectedBadges from '../../../hooks/useLocalSelectedBadges';
import { FiltersState } from '../../../state/slices/filtersSlice';
import QuizButton from '../QuizButton';

export default function QuizSkillLevel({ onSelectAnswer }: QuestionProps) {
  const { localSelectedBadges, toggleLocalSelectedBadge } =
    useLocalSelectedBadges();

  const { filterBadgeGroups, loading: loadingFilterBadgeGroups } =
    useFilterBadgeGroups();

  const skillLevelFilter = filterBadgeGroups?.find(
    (filter) => filter.desktopName === 'Skill levels taught',
  );

  const onToggleLocalBadge = (
    badge: FiltersState['localSelectedBadges'][0],
  ) => {
    toggleLocalSelectedBadge(badge);
  };

  useEffect(() => {
    if (localSelectedBadges) {
      const badgeIds = skillLevelFilter?.badges.map((badge) => badge.id);

      const badgeNames = localSelectedBadges
        .filter((badge) => badgeIds.includes(badge.id))
        .map((badge) => badge.name)
        .join(';');

      const options = skillLevelFilter?.badges
        .map((badge) => badge.name)
        .join(';');

      onSelectAnswer(badgeNames, options);
    }
  }, [localSelectedBadges, onSelectAnswer, skillLevelFilter]);

  return (
    <Skeleton
      isLoaded={!loadingFilterBadgeGroups}
      h={loadingFilterBadgeGroups ? '200px' : undefined}
      w="full"
    >
      <Box w="full">
        <VStack alignItems="flex-start" spacing="2" w="full">
          {skillLevelFilter?.badges.map((badge) => (
            <QuizButton
              badge={badge}
              key={badge.id}
              isSelected={localSelectedBadges.some((b) => b.id === badge.id)}
              onToggleBadge={onToggleLocalBadge}
              w="full"
            />
          ))}
        </VStack>
      </Box>
    </Skeleton>
  );
}
