import { Box } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { Marker } from 'react-map-gl';
import { MarkerEvent } from 'react-map-gl/dist/esm/types';

import { LocationAffiliateName } from '../../../api';
import TopGolfIconWhite from '../../../assets/top-golf/top-golf-icon-white.svg';
import '../../../styles/LocationPopup.css';
import useIsAtHomeOrListingExistsWithNoLocations from '../../../hooks/useIsAtHomeOrListingExistsWithNoLocations';
import useSelectedLocation from '../../../hooks/useSelectedLocation';
import useSelectLessonAtHome from '../../../hooks/useSelectLessonAtHome';
import { useAppDispatch } from '../../../state/hooks';
import { setIsAtHome } from '../../../state/slices/filtersSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import { SelectedListing, SelectedLocation } from '../../../utils/types';
import LocationFeeMarkerIcon from '../../LocationFeeMarkerIcon';
import Pin from '../../Map/components/Pin';
import PinAtHome from '../../Map/components/PinAtHome';
import TravelAddressCircle from '../../Map/components/TravelAddressCircle';

type Props = {
  listing: SelectedListing;
  setSelectedHit(location: SelectedLocation): void;
};

export default function ProLocationsPins({ listing, setSelectedHit }: Props) {
  const isAtHomeOrListingExistsWithNoLocations =
    useIsAtHomeOrListingExistsWithNoLocations();
  const dispatch = useAppDispatch();
  const [selectedLocation, setSelectedLocation] = useSelectedLocation();
  const { selectLessonAtHome } = useSelectLessonAtHome();

  const atHomeLocationPin = useMemo(
    () =>
      ({
        id: listing?.id || '0',
        latitude: listing?.travelAddressLatitude || 0,
        longitude: listing?.travelAddressLongitude || 0,
      } as SelectedLocation),
    [
      listing?.id,
      listing?.travelAddressLatitude,
      listing?.travelAddressLongitude,
    ],
  );

  useEffect(() => {
    if (
      isAtHomeOrListingExistsWithNoLocations ||
      selectedLocation?.id === atHomeLocationPin?.id
    ) {
      setSelectedHit(undefined);
    } else {
      setSelectedHit(selectedLocation);
    }
  }, [
    isAtHomeOrListingExistsWithNoLocations,
    atHomeLocationPin,
    selectedLocation,
    setSelectedHit,
  ]);

  const pins = useMemo(() => {
    const pinLocations = isAtHomeOrListingExistsWithNoLocations
      ? [atHomeLocationPin]
      : listing?.locations || [];

    const handleMarkerClick =
      (location: SelectedLocation) =>
      (event: MarkerEvent<mapboxgl.Marker, MouseEvent>) => {
        event.originalEvent.stopPropagation();

        capturePostHogEvent('clickMapPin', {
          action: 'profileMap',
          location,
          atHomeLocationPin,
        });

        if (location.id === atHomeLocationPin.id) {
          selectLessonAtHome();
        } else {
          setSelectedLocation(location);
          dispatch(setIsAtHome(false));
        }
      };

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {pinLocations.map((location, index) => {
          const isAtHomePin = location.id === atHomeLocationPin.id;
          const isSelected =
            isAtHomePin ||
            (!isAtHomeOrListingExistsWithNoLocations &&
              selectedLocation?.id === location.id);
          const isTopGolfLocation =
            location?.LocationAffiliate?.name === LocationAffiliateName.TopGolf;
          return (
            <Box key={location?.id || index}>
              <Marker
                anchor="center"
                latitude={location?.latitude}
                longitude={location?.longitude}
                offset={[0, 0]}
                onClick={handleMarkerClick(location)}
                style={{ cursor: 'pointer' }}
              >
                {isAtHomePin ? (
                  <>
                    <TravelAddressCircle listing={listing} />
                    <PinAtHome isActive={isSelected} listing={listing} />
                    <LocationFeeMarkerIcon
                      isActive={isSelected}
                      location={location}
                    />
                  </>
                ) : (
                  <>
                    <Pin
                      activeColor={isTopGolfLocation ? '#15212D' : undefined}
                      inactiveColor={isTopGolfLocation ? '#15212D' : undefined}
                      isActive={isSelected}
                      src={isTopGolfLocation ? TopGolfIconWhite : undefined}
                    />
                    <LocationFeeMarkerIcon
                      isActive={isSelected}
                      location={location}
                    />
                  </>
                )}
              </Marker>
            </Box>
          );
        })}
      </>
    );
  }, [
    atHomeLocationPin,
    isAtHomeOrListingExistsWithNoLocations,
    dispatch,
    listing,
    selectedLocation,
    selectLessonAtHome,
    setSelectedLocation,
  ]);

  return pins;
}
