import { Heading, HStack, Switch, Text, VStack } from '@chakra-ui/react';
import { useLocalStorageValue } from '@react-hookz/web';
import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { selectSelectedListing } from '../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import PosthogEvent from '../../../utils/posthogEvents';

export default function NeedEquipment() {
  const selectedListing = useSelector(selectSelectedListing);
  const [gear, setGear] = useLocalStorageValue<string>('gear', 'no');

  const handleEdit = (event: ChangeEvent<HTMLInputElement>) => {
    capturePostHogEvent(PosthogEvent.ClickedEditEquipment, {
      ...selectedListing,
    });

    setGear(event.target.checked ? 'yes' : 'no');
  };

  return (
    <VStack alignItems="flex-start" width="full" mt={6}>
      <HStack justifyContent="space-between" width="full">
        <VStack alignItems="flex-start" justifyContent="center">
          <Heading variant="h6">NEED EQUIPMENT?</Heading>
          <Text color="slate.500">I need equipment for the lesson</Text>
        </VStack>
        <HStack>
          <Switch onChange={handleEdit} isChecked={gear === 'yes'} />
        </HStack>
      </HStack>
    </VStack>
  );
}
