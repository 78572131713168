import { useMemo } from 'react';

import { useBadgeGroupsQuery } from '../api';
import type { SelectedBadgeGroup } from '../interfaces';
import { useAppSelector } from '../state/hooks';
import { selectSelectedSkill } from '../state/slices/filtersSlice';

/**
 * Get all badge groups with at least one badge where `isFilter` is true.
 * Then return only those filter badges within each group.
 */
export default function useFilterBadgeGroups(): {
  filterBadgeGroups: SelectedBadgeGroup[];
  loading: boolean;
} {
  const selectedSkill = useAppSelector(selectSelectedSkill);

  const { data, loading } = useBadgeGroupsQuery({
    variables: {
      where: {
        AND: [
          {
            badges: {
              some: {
                isExperience: { equals: false }, // experience badges go through `useExperienceBadges`
                isFilter: { equals: true },
              },
            },
            // Temporarily remove "Travels to you" badge
            desktopName: { not: { equals: 'Additional offers' } },
          },
          {
            OR: [
              {
                skillId: { equals: selectedSkill?.id },
              },
              {
                skillId: { equals: null },
              },
            ],
          },
        ],
      },
    },
  });

  const filterBadgeGroups = useMemo(() => {
    const badgeGroups = data?.findManyBadgeGroup || [];

    return badgeGroups.map((badgeGroup) => ({
      ...badgeGroup,
      badges: badgeGroup.badges
        .filter((badge) => badge.isFilter)
        .sort((a, b) => a.displayPriority - b.displayPriority),
    }));
  }, [data?.findManyBadgeGroup]);

  return { filterBadgeGroups, loading };
}
