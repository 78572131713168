import { HStack, Icon, StackProps, useColorModeValue } from '@chakra-ui/react';
import { HiStar } from 'react-icons/hi';

interface Props extends StackProps {
  rating?: number;
  max?: number;
  numOfStars?: number;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
}

export default function StarRating({
  rating = 0,
  max = 5,
  numOfStars,
  size = 'md',
  ...rest
}: Props) {
  const color = useColorModeValue('gray.200', 'gray.600');
  const activeColor = 'accent';

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <HStack spacing="0.5" {...rest}>
      {Array.from({ length: numOfStars || max })
        .map((_, index) => index + 1)
        .map((index) => (
          <Icon
            key={index}
            as={HiStar}
            fontSize={size}
            color={index <= rating ? activeColor : color}
          />
        ))}
    </HStack>
  );
}
