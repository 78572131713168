import { Box, Stack, Text } from '@chakra-ui/react';

export default function Cancellation() {
  return (
    <Stack spacing={4}>
      <Text>
        <Box as="span" fontWeight="bold">
          Change your mind?
        </Box>{' '}
        No problem! You can get a full refund as long as you let us know within
        24 hours of booking.
      </Text>
      <Text>
        <Box as="span" fontWeight="bold">
          Didn&apos;t like your lesson?
        </Box>{' '}
        We got you covered. If you don&apos;t like your lesson, just let us know
        within 24 hours of the first lesson with your instructor and we&apos;ll
        provide a full refund.
      </Text>
      <Text>
        <Box as="span" fontWeight="bold">
          Need to reschedule?
        </Box>{' '}
        We&apos;re here to help. Just let us know at least 72 hours before the
        lesson and we&apos;ll help you find another time!
      </Text>
      <Text>
        <Box as="span" fontWeight="bold">
          Bad weather?
        </Box>{' '}
        Don&apos;t worry. We got you covered. All lessons cancelled due to bad
        weather will be rescheduled at no cost to you!
      </Text>
    </Stack>
  );
}
