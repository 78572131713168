/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../interfaces';

export interface BookingState {
  clientSecret: string;
  successUrl: string;
}

const initialState: BookingState = {
  clientSecret: undefined,
  successUrl: undefined,
};

const slice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    resetBooking: (state) => {
      state.clientSecret = initialState.clientSecret;
      state.successUrl = initialState.successUrl;
    },
    setClientSecret: (
      state,
      action: PayloadAction<BookingState['clientSecret']>,
    ) => {
      state.clientSecret = action.payload || initialState.clientSecret;
    },
    setSuccessUrl: (
      state,
      action: PayloadAction<BookingState['successUrl']>,
    ) => {
      state.successUrl = action.payload || initialState.successUrl;
    },
  },
});

// export actions
export const { resetBooking, setClientSecret, setSuccessUrl } = slice.actions;

// export selectors
export const selectClientSecret = (state: RootState) =>
  state.booking.clientSecret;
export const selectSuccessUrl = (state: RootState) => state.booking.successUrl;

// export reducer
export default slice.reducer;
