import { Store } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { RootState } from '../state/interfaces';

enum SentryEnvironment {
  Production = 'production',
  Staging = 'staging',
  Preview = 'deploy-preview',
}

function getEnvironment(): SentryEnvironment {
  if (import.meta.env.VITE_SENTRY_ENV) {
    // Value should always be one of the SentryEnvironment values in netlify env
    return import.meta.env.VITE_SENTRY_ENV as SentryEnvironment;
  }

  // If variable not set we try to infer the environment from the URL
  if (window?.location?.origin?.startsWith('https://staging')) {
    return SentryEnvironment.Staging;
  }

  if (window?.location?.origin?.startsWith('https://deploy-preview')) {
    return SentryEnvironment.Preview;
  }

  return SentryEnvironment.Production;
}

const init = () => {
  Sentry.init({
    dsn: 'https://aa47394aa2852e5441e9bbbffa535489@o1083372.ingest.us.sentry.io/4507805418323968',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    replaysSessionSampleRate: 0.1, // 10% of sessions will have replay
    replaysOnErrorSampleRate: 1.0, // 100% of errors will have replay
    sampleRate: 1.0,
    tracesSampleRate: 0.1,
    environment: getEnvironment(),
    beforeSend: (event) => {
      if (window.location.hostname === 'localhost') {
        return null;
      }
      if (event.request?.headers?.['user-agent']?.toLocaleLowerCase().includes('prerender')) {
        return null;
      }
      if (window.navigator.userAgent.includes('prerender')) {
        return null;
      }
      return event;
    },
  });
};

export type SentryContext = {
  name: string;
  data: {
    [key: string]: any;
  } | null
}

export type CaptureExceptionOptions = {
  context?: SentryContext;
  user?: Sentry.User;
} | null | undefined

function getSentryUserFromRedux(store: Store<RootState>): Sentry.User {
  try {
    const state = store.getState();
    const name = state.filters.confirmName ?? "";
    const email = state.filters.confirmEmail ?? "";

    return {
      email,
      username: name,
    };
  } catch (error) {
    return {}
  }
}

function captureException(exception: Error, options?: CaptureExceptionOptions) {
  try {
    if (options) {
      const { context, user } = options;
      let posthogUserId = localStorage.getItem('posthogUserId');
      const location = localStorage.getItem('currentLocation');
      const locationPermissionStatus = localStorage.getItem('locationPermissionStatus');

      if (posthogUserId) {
        Sentry.setTag('posthogUserId', JSON.parse(posthogUserId));
        posthogUserId = JSON.parse(posthogUserId);
      }

      Sentry.setUser({ id: posthogUserId, ...user });

      if (context) {
        const { name, data } = context
        Sentry.setContext(name, data);
      }

      if (location) {
        Sentry.setContext('CurrentLocation', JSON.parse(location));
      }

      if (locationPermissionStatus) {
        Sentry.setTag('locationPermissionStatus', JSON.parse(locationPermissionStatus));

        Sentry.setContext('Permissions', {
          location: JSON.parse(locationPermissionStatus),
        });
      }
    }
  } catch (error: any) {
    Sentry.setContext("CaptureFailure", { error: error.message });
  } finally {
    Sentry.captureException(exception);
  }
}

export default {
  init,
  captureException,
  getSentryUserFromRedux
};
