import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';
import React, { ComponentProps } from 'react';
import { HiLockClosed } from 'react-icons/hi';

function CheckoutButton({
  isDisabled,
  isLoading,
  onClick,
  children,
  borderRadius = 'full',
}: {
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
  children?: React.ReactNode;
  borderRadius?: ComponentProps<typeof Button>['borderRadius'];
}) {
  return (
    <Button
      py="3"
      w="full"
      h="50px"
      variant="primary"
      isDisabled={isDisabled}
      isLoading={isLoading}
      rightIcon={<HiLockClosed />}
      onClick={onClick}
      fontSize="16px"
      fontWeight="800"
      borderRadius={borderRadius}
    >
      {children}
    </Button>
  );
}

type Props = {
  paymentRequest: PaymentRequest;
  isRecurring?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  onCheckoutWithoutPaymentRequest(): void;
  onCheckoutWithPaymentRequest(): void;
};

export default function PaymentButtons({
  paymentRequest,
  isRecurring = false,
  isDisabled = false,
  isLoading = false,
  onCheckoutWithPaymentRequest,
  onCheckoutWithoutPaymentRequest,
}: Props) {
  return (
    <VStack w="full">
      <HStack w="full">
        {paymentRequest && !isDisabled && (
          <Box w="full">
            <PaymentRequestButtonElement
              onClick={onCheckoutWithPaymentRequest}
              options={{
                paymentRequest,
                style: {
                  paymentRequestButton: {
                    height: '50px',
                    theme: 'dark',
                    type: isRecurring ? 'subscribe' : 'default',
                  },
                },
              }}
            />
          </Box>
        )}
        <CheckoutButton
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={onCheckoutWithoutPaymentRequest}
          borderRadius={paymentRequest && !isDisabled ? '5px' : 'full'}
        >
          {paymentRequest && !isDisabled ? 'Checkout' : 'Secure checkout'}
        </CheckoutButton>
      </HStack>
      <HStack w="full" justifyContent="center" mt="4">
        <Text fontSize="14px" fontWeight="400" color="#718096">
          You won’t be charged yet!
        </Text>
      </HStack>
    </VStack>
  );
}
