import { Image, VStack } from '@chakra-ui/react';

import TMTLogo from '../../assets/risk-free/tmt-logo-white.svg';
import TMTShield from '../../assets/risk-free/tmt-shield-white.svg';

export const RiskFreeBadgeSizes = ['r', 'md', 'lg'] as const;

export type RiskFreeBadgeProps = {
  size: (typeof RiskFreeBadgeSizes)[number];
};

const sizes: Record<
  (typeof RiskFreeBadgeSizes)[number],
  {
    container: number;
    shield: number;
    logo: number;
  }
> = {
  r: {
    container: 42,
    shield: 28,
    logo: 17,
  },
  md: {
    container: 59,
    shield: 36,
    logo: 24,
  },
  lg: {
    container: 96,
    shield: 62,
    logo: 36,
  },
};

export default function RiskFreeBadge({ size }: RiskFreeBadgeProps) {
  return (
    <VStack
      flexShrink={0}
      w={`${sizes[size].container}px`}
      h={`${sizes[size].container}px`}
      pos="relative"
      borderRadius={6}
      alignItems="center"
      justifyContent="center"
      bgGradient="linear-gradient(225deg, #3182CE 1.86%, #5EBDD2 40.76%, #182C93 81.24%);"
      boxShadow="0px 0px 16.857px 0px rgba(49, 130, 206, 0.50);"
    >
      <Image
        alt="TMTShield"
        h="auto"
        src={TMTShield}
        width={`${sizes[size].shield}px`}
      />

      <VStack
        pos="absolute"
        width="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Image
          alt="TMTLogo"
          h="auto"
          src={TMTLogo}
          width={`${sizes[size].logo}px`}
        />
      </VStack>
    </VStack>
  );
}
