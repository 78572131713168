import { useLocalStorageValue } from '@react-hookz/web';
import { useCallback } from 'react';

export default function useTapfiliate() {
  const [tapfiliateReferralCode, setTapfiliateReferralCode] =
    useLocalStorageValue<string>('tapfiliateReferralCode', '');

  const initTapfiliate = useCallback(
    (urlLocationSearch = window.location.search) => {
      const urlParams = new URLSearchParams(urlLocationSearch);
      const referralCode = urlParams.get('ref');
      if (referralCode) setTapfiliateReferralCode(referralCode);
    },
    [setTapfiliateReferralCode],
  );

  const deleteTapfiliateReferralCode = useCallback(() => {
    setTapfiliateReferralCode('');
  }, [setTapfiliateReferralCode]);

  return {
    tapfiliateReferralCode,
    initTapfiliate,
    deleteTapfiliateReferralCode,
  };
}
