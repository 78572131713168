import {
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

interface TermsProps {
  mt?: number;
}

export default function Terms({ mt = 2 }: TermsProps) {
  return (
    <Stack spacing={4} p={{ base: '8', lg: '24' }}>
      <Stack align="center" textAlign="center">
        <Heading as="h1" size="xl">
          Teach Me To Terms of Service
        </Heading>
        <Text mt={2}>Last Updated July 26, 2024</Text>
      </Stack>

      <Text mt={4}>
        Teach Me To, Inc. (together with its parents, subsidiaries, affiliates,
        divisions, brands, representatives, officers, and directors, “Teach Me
        To,” “we,” “us,” or “our,” as the context may require) operates and
        provides the Teach Me To applications, websites, technologies,
        facilities, and platform (collectively, the “Teach Me To Platform”).
      </Text>
      <Text mt={4}>
        These Terms of Service (these “Terms”), together with any documents they
        expressly incorporate by reference, including the Teach Me To Privacy
        Policy, constitute a legally binding agreement between you and Teach Me
        To, and govern your access to and use of the Teach Me To Platform. These
        Terms are entered into by and between you and Teach Me To.
      </Text>
      <Text mt={4}>
        The Teach Me To Platform connects individuals (“Students”) with skilled
        instructors (“Coaches”) who provide coaching or teaching services in
        their area of expertise (“Coach Services”) through private lessons
        (“Lessons”). Students, Coaches, and other users of the Teach Me To
        Platform are referred to collectively as “Members.”
      </Text>
      <Text mt={4}>
        Please read these Terms carefully before you use the Teach Me To
        Platform. By registering, accessing, or using the Teach Me To Platform,
        or by clicking “I accept” or “I agree” to the Terms when this option is
        made available to you, you accept and agree to be bound by these Terms.
        If you do not agree to these Terms, please do not visit, access, use, or
        otherwise interact with the Teach Me To Platform.
      </Text>
      <Text mt={4} fontWeight="bold">
        PLEASE BE ADVISED: THESE TERMS CONTAIN PROVISIONS THAT GOVERN HOW CLAIMS
        BETWEEN YOU AND TEACH ME TO CAN BE BROUGHT (SEE SECTION 28 BELOW). THESE
        PROVISIONS WILL, WITH LIMITED EXCEPTION, REQUIRE YOU TO: (1) WAIVE YOUR
        RIGHT TO A JURY TRIAL, AND (2) SUBMIT CLAIMS YOU HAVE AGAINST TEACH ME
        TO TO BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL BASIS, NOT AS A
        PLAINTIFF OR CLASS MEMBER IN ANY CLASS, GROUP OR REPRESENTATIVE ACTION
        OR PROCEEDING.
      </Text>
      <Heading mt={8} as="h1" variant="h2">
        I. Student Terms
      </Heading>
      <Heading as="h2" size="lg" mt={4}>
        1. Searching and Booking on Teach Me To.
      </Heading>
      <Heading as="h3" size="md" mt={4}>
        1.1 Searching
      </Heading>
      <Text mt={mt}>
        You can search for Coach Services by using criteria like the type of
        Coach Service, location, dates, and number of Students. You can also use
        filters to refine your search results. Search results are based on their
        relevance to your search and other criteria. Relevance considers factors
        like price, availability, reviews, customer service and cancellation
        history, popularity, previous Lessons and saved Lessons, Coach
        requirements, and more.
      </Text>
      <Heading as="h3" size="md" mt={4}>
        1.2 Booking
      </Heading>
      <Text mt={mt}>
        When you book a Lesson, you are agreeing to pay all charges for your
        booking, including the Lesson price, applicable fees like Teach Me
        To&apos;s Service Fees (as defined below), taxes, and any other items
        identified during checkout. You are also agreeing that Teach Me To may
        charge and collect any security deposit identified during checkout. When
        you receive the booking confirmation, a contract for Coach Services is
        formed directly between you and the Coach. The cancellation policy and
        any other rules, standards, policies, or requirements identified during
        checkout form part of your contract with the Coach. Be aware that some
        Coaches work with another Coach (a “co-Coach”) or as part of a team to
        provide their Coach Services.
      </Text>
      <Heading as="h3" size="md" mt={4}>
        1.3 Lessons
      </Heading>
      <Text mt={mt}>
        Booking a Lesson entitles you to participate in, attend, or use that
        Coach Service. You are responsible for confirming that you, and anyone
        you invite, meet minimum age, proficiency, fitness, or other
        requirements. You are responsible for informing the Coach of any medical
        or physical conditions, or other circumstances that may impact your
        ability to participate, attend, or use the Coach Service. Except where
        expressly authorized, you may not allow any person to join a Lesson
        unless they are included as an additional Student during the Lesson
        booking process.
      </Text>
      <Heading as="h2" size="lg" mt={4}>
        2. Cancellations, Refunds and Booking Modifications
      </Heading>
      <Heading as="h3" size="md" mt={4}>
        2.1 Cancellation Policy
      </Heading>
      <Text mt={mt}>
        At Teach Me To, we value our customers. We understand that circumstances
        may change, and we strive to provide a cancellation policy that
        accommodates your needs. Please take a moment to review the details
        below.
      </Text>
      <UnorderedList styleType="'•  '" spacing={3} mt={2}>
        <ListItem>
          <strong>Change of Heart:</strong> We know that sometimes plans can
          take an unexpected turn or personal preferences may shift. If you’ve
          recently made a purchase and find yourself having a change of heart,
          or if your schedule takes an unexpected twist, we’ve got you covered.
          Let us know within 24 hours of your Lesson booking confirmation, and
          if you qualify, we’ll issue a refund, no questions asked. Your
          satisfaction is our top priority, and we are here to help with your
          learning journey.
        </ListItem>
        <ListItem>
          <strong>Lesson Quality:</strong> Our goal is to provide you with an
          enjoyable and rewarding learning experience. If your first Lesson
          doesn’t meet the expectations we set, we want to make it right. Simply
          send us an explanation about what happened within 24 hours of the
          experience, and we will help find the best solution, whether it’s
          upgrading you to a more suitable Coach or refunding part or all of
          your Lesson. Feedback from your experience is valuable to us, and we
          aim to address any concerns promptly.
        </ListItem>
        <ListItem>
          <strong>Extenuating Circumstances:</strong> There may be certain
          unforeseen events beyond your control arise after booking that make it
          impracticable or illegal to complete your reservation (“Extenuating
          Circumstances”). Extenuating Circumstances will be determined in Teach
          Me To’s sole discretion on a case-by-case basis. Please keep in mind
          that refunds beyond the specified 24-hour period are generally not
          provided, except in cases of Extenuating Circumstances, and Lessons
          that have already taken place are not generally eligible for refunds,
          unless there are exceptional circumstances, as determined in Teach Me
          To’s sole discretion.
        </ListItem>
        <ListItem>
          <strong>Unused Lessons:</strong> Purchased lesson can be used for up
          to 12 months after the initial purchase date. After 12 months, any
          unused lessons will expire.
        </ListItem>
      </UnorderedList>
      <Heading as="h3" size="md" mt={4}>
        2.2 Weekly Recurring Subscription Cancellation Policy
      </Heading>
      <Text mt={mt}>
        Teachme.to offers a subscription package to reduce the price of weekly
        lessons. In addition to the cancellation policy outlined above, the
        following policies apply specifically to subscriptions:
      </Text>
      <UnorderedList styleType="'•  '" spacing={3} mt={2}>
        <ListItem>
          <strong>Auto renewals:</strong> Subscriptions will automatically renew
          on the date of the initial purchase. For example, a purchase on
          Feburary 27th will automatically renew on March 27th.
        </ListItem>
        <ListItem>
          <strong>Cancellation:</strong> If you cancel your subscription, the
          cancellation will apply to the NEXT renewal date. Refunds for
          purchases are available for 24 hours after the initial purchase. After
          24 hours, you may cancel anytime to prevent future renewals. Refunds
          will not be provided for late cancellations or if you forget to cancel
          before renewal.
        </ListItem>
        <ListItem>
          <strong>Lesson credits:</strong> Subscriptions cover one lesson per
          week for every week the subscription is active. If for any reason a
          lesson does not occur in a given week, that lesson credit can be
          applied to future or additional lessons with the Pro. Unused lesson
          credits can be rescheduled for up to 12 months after the skipped week,
          after which the lesson credit will expire.
        </ListItem>
        <ListItem>
          <strong>Changing pros or transferring unused lesson credits:</strong>{' '}
          If you are no longer satisfied with your pro, or would like to take
          lessons in a different skill, you can transfer your subscription to
          another pro. If you have canceled your subscription and have unused
          lesson credits you can apply those unused credits to lessons with a
          different pro. Please contact support to help transfer your
          subscription. Note there may be additional costs if the new pro rate
          is higher than the original pro rate.
        </ListItem>
      </UnorderedList>
      <Heading as="h3" size="md" mt={4}>
        2.3 Booking Modifications
      </Heading>
      <Text mt={mt}>
        Students and Coaches are responsible for any Lesson booking
        modifications they mutually agree to make, either via the Teach Me To
        Platform or via Teach Me To customer service (&quot;Booking
        Modifications&quot;), and Students agree to pay any additional amounts,
        fees, or taxes associated with any Booking Modification.
      </Text>
      <Heading as="h2" size="lg" mt={4}>
        3. Your Responsibilities and Assumption of Risk
      </Heading>
      <Heading as="h3" size="md" mt={4}>
        3.1 Your Responsibilities
      </Heading>
      <Text mt={mt}>
        You are responsible and liable for your own acts and omissions and are
        also responsible for the acts and omissions of anyone you invite to join
        or provide access to any Coach Service. For example, this means you must
        act with integrity, treat others with respect, and comply with
        applicable laws at all times. If you are booking for an additional
        Student who is a minor, or if you bring a minor to a Coach Service, you
        must be legally authorized to act on behalf of the minor, and you are
        solely responsible for the supervision of that minor.
      </Text>
      <Heading as="h3" size="md" mt={4}>
        3.2 Your Assumption of Risk
      </Heading>
      <Text mt={mt}>
        You acknowledge participating in the Coach Services carries inherent
        risks, and you agree that, to the maximum extent permitted by applicable
        law, you assume the entire risk arising out of your access to and use of
        the Teach Me To Platform and any Content (as defined in Section 11),
        including your participation in any Lesson, use of any Coach Service, or
        any other interaction you have with other Members, whether online or
        offline. You agree that you have had the opportunity to investigate the
        Teach Me To Platform and any laws, rules, regulations, or obligations
        that may be applicable to your Coach Services or Lessons, and that you
        are not relying upon any statement made by Teach Me To. We do not employ
        any Coaches and are not responsible for the conduct, whether online or
        offline, of any Coach or other Member. We do not refer or recommend
        Coaches, nor do we make any representations about the suitability,
        reliability, timeliness, or accuracy of the Coach Services provided by
        Coaches or the integrity, reliability, or actions of Coaches, whether in
        public, private, or offline interactions. This means it is your
        responsibility to investigate a Coach Service to determine whether it is
        suitable for you. NOTWITHSTANDING THESE RISKS, YOU ACKNOWLEDGE THAT YOU
        ARE VOLUNTARILY PARTICIPATING IN THE TEACH TO ME PLATFORM AND ANY COACH
        SERVICES PROVIDED THROUGH THE TEACH ME TO PLATFORM WITH KNOWLEDGE OF THE
        RISKS INVOLVED. YOU HEREBY AGREE TO ACCEPT AND ASSUME ALL RISKS OF
        PERSONAL OR PSYCHOLOGICAL INJURY, PAIN, SUFFERING, TEMPORARY OR
        PERMANENT DISABILITY, DEATH, PROPERTY DAMAGE, AND/OR FINANCIAL LOSS
        ARISING THEREFROM, WHETHER CAUSED BY THE ORDINARY NEGLIGENCE OF TEACH ME
        TO OR OTHERWISE.
      </Text>
      <Heading mt={8} as="h1" variant="h2">
        II. Coach Terms
      </Heading>
      <Heading as="h2" size="lg" mt={4}>
        4. Teaching on Teach Me To.
      </Heading>
      <Heading as="h3" size="md" mt={4}>
        4.1 Coach
      </Heading>
      <Text mt={mt}>
        As a Coach, Teach Me To offers you the opportunity to share your skills
        with our vibrant community of Students - and earn money doing it.
        It&apos;s easy to create a Lesson, and you are in control of how you
        teach, including setting your availability and rules for each Lesson.
      </Text>
      <Heading as="h3" size="md" mt={4}>
        4.2 Contracting with Students
      </Heading>
      <Text mt={mt}>
        When you accept a booking request for a Lesson, or receive a booking
        confirmation for a Lesson through the Teach Me To Platform, you are
        entering into a contract directly with the Student, and are responsible
        for delivering your Coach Service under the terms and at the price you
        have specified in your Coach Service description. You are also agreeing
        to pay applicable fees, like Teach Me To&apos;s Services Fees (and
        applicable Taxes, as defined below), for each Lesson. Teach Me To will
        deduct amounts you owe from your payout unless we agree to a different
        method. Any terms, policies or conditions that you include in any
        supplemental contract with Students must: (i) be consistent with these
        Terms and the information provided in your Coach Service description,
        and (ii) be prominently disclosed in your Coach Service description.
      </Text>
      <Heading as="h3" size="md" mt={4}>
        4.3 Independence of Coaches
      </Heading>
      <Text mt={mt}>
        As a Coach on the Teach Me To Platform, your relationship with Teach Me
        To is solely that of independent contracting parties. You and Teach Me
        To expressly agree that (i) this is not an employment agreement and does
        not create an employment relationship between you and Teach Me To; and
        (ii) no joint venture, franchisor- franchisee, partnership, or agency
        relationship is intended or created by this Agreement. You have no
        authority to bind Teach Me To, and you undertake not to hold yourself
        out as an employee, agent or authorized representative of Teach Me To.
        Teach Me To does not direct or control your Coach Service, and you agree
        that you have complete discretion whether and when to provide Coach
        Services, and at what price and on what terms to offer them. You
        acknowledge and agree that you have complete discretion to provide the
        Coach Services or otherwise engage in any other business or employment
        activities, including but not limited to providing services similar to
        the Coach Services to other companies, and that Teach Me To does not,
        and shall not be deemed to, restrict you from engaging in any such
        activity.
      </Text>
      <Heading as="h2" size="lg" mt={4}>
        5. Managing Your Coach Service
      </Heading>
      <Heading as="h3" size="md" mt={4}>
        5.1 Creating and Managing Your Coach Service
      </Heading>
      <Text mt={mt}>
        The Teach Me To Platform provides tools that make it easy for you to set
        up and manage your Coach Service. Your Coach Service description must
        include complete and accurate information about your Coach Service, your
        price, other charges, and any rules or requirements that apply to your
        Students or Lessons. You are responsible for keeping your Coach Service
        description (including calendar availability) and content (like photos)
        up-to-date and accurate at all times. We recommend that you obtain
        appropriate insurance for your Coach Services and suggest you carefully
        review policy terms and conditions including coverage details and
        exclusions. You may only maintain one Coach Service per skill, but may
        have multiple Coach Services available across multiple skills.
      </Text>
      <Heading as="h3" size="md" mt={4}>
        5.2 Know Your Legal Obligations
      </Heading>
      <Text mt={mt}>
        You are responsible for understanding and complying with any applicable
        laws, rules, regulations, and contracts with third parties that apply to
        your Coach Services or Lessons. This means, among other things, that you
        are legally eligible to provide Coach Services in the jurisdiction where
        you provide Coach Services; that you have complied and will comply with
        all laws and regulations that are applicable to you; that you have
        obtained all business licenses, business tax registrations, and permits
        necessary to provide Coach Services legally; and that when providing
        Coach Services, you will comply with applicable laws, rules,
        regulations, and contracts with third parties, such as those related to
        providing the Coach Services in a specific location. Any information we
        provide regarding legal requirements is for informational purposes only
        and is not legal advice. You are responsible for independently
        confirming your legal obligations. You are responsible for handling and
        using personal information of Students and others in compliance with
        applicable privacy laws and these Terms. If you have questions about how
        applicable laws, rules, and regulations apply, you should always seek
        legal advice.
      </Text>
      <Heading as="h3" size="md" mt={4}>
        5.3 Search Ranking
      </Heading>
      <UnorderedList styleType="'•  '" spacing={3} mt={2}>
        <ListItem>
          Student search parameters (e.g. number of Students, time and duration
          of the trip, price range),
        </ListItem>
        <ListItem>
          Lesson characteristics (e.g. price, calendar availability, number and
          quality of images, reviews, type of Coach Service, Coach status, age
          of the Lesson, average Student popularity),
        </ListItem>
        <ListItem>
          Student booking experience (e.g. customer service and cancellation
          history of the Coach, ease of booking),
        </ListItem>
        <ListItem>Coach requirements (e.g. booking cut-off time), and</ListItem>
        <ListItem>
          Student preferences (e.g. previous Coach Services used and Lessons,
          saved Coach Services, location from where the Student is searching).
        </ListItem>
      </UnorderedList>
      <Heading as="h3" size="md" mt={4}>
        5.4 Your Responsibilities
      </Heading>
      <Text mt={mt}>
        You are responsible and liable for your own acts and omissions and are
        also responsible for the acts and omissions of anyone you allow to
        participate in providing your Coach Services and Lessons. You are
        responsible for setting your price and establishing rules and
        requirements for your Coach Service. You must describe any and all fees
        and charges in your Coach Service description and may not collect any
        additional fees or charges offline or otherwise outside the Teach Me To
        Platform. You shall not encourage Students to create third-party
        accounts, submit reviews, or provide their contact information outside
        of the Teach Me To Platform, and you shall not take Students off of the
        Teach Me To Platform for new, partial, or future Coach Service
        offerings, such as by contacting potential Students prior to booking on
        the Teach Me To Platform to move booking off of the Teach Me To
        Platform, cancelling existing bookings and having Students rebook off of
        the Teach Me To Platform, or encouraging students to book outside of the
        Teach Me To Platform for repeat or future bookings.
      </Text>
      <Heading as="h3" size="md" mt={4}>
        5.5 Coaching as a Team or Organization
      </Heading>
      <Text mt={mt}>
        If you work with a co-Coach or with other Coaches as part of a team,
        business, or other organization, the entity and each individual who
        participates in providing Coach Services is responsible and liable as a
        Coach under these Terms. By accepting these Terms, you represent and
        warrant that you are authorized to enter into contracts for and bind
        your co-Coach, team, business or other organization, and that any entity
        you use is in good standing under the laws of the place where it is
        established. If you perform other functions, you represent and warrant
        that you are authorized to perform those functions. If you instruct
        Teach Me To to transfer a portion of your payout to a co-Coach or other
        Coaches, or to send payments to someone else, you must be authorized to
        do so, and are responsible and liable for the payment amounts and
        accuracy of any payout information you provide.
      </Text>
      <Heading as="h3" size="md" mt={4}>
        5.6 Your Assumption of Risk
      </Heading>
      <Text mt={mt}>
        You acknowledge that providing the Coach Services carries inherent
        risks, and you agree that you assume, to the maximum extent permitted by
        applicable law, you assume the entire risk arising out of your access to
        and use of the Teach Me To Platform and any Content (as defined in
        Section 11), including offering Coach Services, conducting Lessons, or
        any interaction you have with other Members, whether online or offline.
        You agree that you have had the opportunity to investigate the Teach Me
        To Platform and any laws, rules, regulations, or obligations that may be
        applicable to your Coach Services or Lessons, and that you are not
        relying upon any statement made by Teach Me To. NOTWITHSTANDING THESE
        RISKS, YOU ACKNOWLEDGE THAT YOU ARE VOLUNTARILY PARTICIPATING IN THE
        TEACH ME TO PLATFORM AND VOLUNTARILY PROVIDING LESSONS THROUGH THE TEACH
        TO ME PLATFORM WITH KNOWLEDGE OF THE RISKS INVOLVED. YOU HEREBY AGREE TO
        ACCEPT AND ASSUME ALL RISKS OF PERSONAL OR PSYCHOLOGICAL INJURY, PAIN,
        SUFFERING, TEMPORARY OR PERMANENT DISABILITY, DEATH, PROPERTY DAMAGE,
        AND/OR FINANCIAL LOSS ARISING THEREFROM, WHETHER CAUSED BY THE ORDINARY
        NEGLIGENCE OF TEACH ME TO OR OTHERWISE.
      </Text>
      <Heading as="h2" size="lg" mt={4}>
        6. Cancellations and Booking Modifications
      </Heading>
      <Heading as="h3" size="md" mt={4}>
        6.1 Cancellations
      </Heading>
      <Text mt={mt}>
        In general, if a Student cancels a Lesson, the amount paid to you is
        determined by the cancellation policy that applies to your Coach Service
        and that Lesson. As a Coach, you should not cancel on a Student without
        a valid reason under these Terms or applicable law. If you cancel on a
        Student without such a valid reason, we may impose a cancellation fee
        and other consequences. If an Extenuating Circumstance arises (as
        determined in Teach Me To’s sole discretion on a case-by-case basis) or
        a Lesson is cancelled under Section 15 of these Terms, the amount you
        are paid will be reduced by the amount we refund or otherwise provide to
        the Student, and by any other reasonable costs we incur as a result of
        the cancellation. If a Student receives a refund after you have already
        been paid, or the amount of the refund and other costs incurred by Teach
        Me To exceeds your payout, Teach Me To may recover that amount from you,
        such as by offsetting the refund against your future payouts. You agree
        that these Terms preempt any cancellation policy you set in situations
        where they allow for the cancellation of a Lesson and/or the issuance of
        refunds to Students. If we reasonably expect to provide a refund to a
        Student under one of these policies, we may delay release of any payout
        for that Lesson until a refund decision is made.
      </Text>
      <Heading as="h3" size="md" mt={4}>
        6.2 Booking Modifications
      </Heading>
      <Text mt={mt}>
        Coaches and Students are responsible for any Booking Modifications they
        agree to make, either via the Teach Me To Platform or via Teach Me To
        customer service, and Students agree to pay any additional amounts, fees
        or taxes associated with a Booking Modification.
      </Text>
      <Heading as="h2" size="lg" mt={4}>
        7. Payouts; Payment Terms
      </Heading>
      <Text mt={mt}>
        All payouts are payable net 30 of the date of invoice. Any disputes
        regarding payouts must be communicated to Teach Me To within thirty (30)
        days of receipt of invoice for same. The parties will work in good faith
        to resolve any payout dispute within fifteen (15) days. Coach shall
        reimburse Teach Me To for all costs and expenses (including, without
        limitation, attorneys’ fees) incurred in collecting past due amounts.
        All payouts are net amounts and payable in full, without deduction for
        taxes or duties of any kind. Coach will pay or reimburse Teach Me To for
        all sales, use, VAT and all other taxes and duties (“Tax” or “Taxes”)
        which are levied or imposed arising from the purchases made under this
        Agreement, excluding taxes based on Teach Me To&apos;s gross or net
        income, property taxes, state franchise taxes, or similar taxes levied
        against Teach Me To.
      </Text>
      <Heading as="h2" size="lg" mt={4}>
        8. Taxes
      </Heading>
      <Heading as="h3" size="md" mt={4}>
        8.1 Coach Taxes
      </Heading>
      <Text mt={mt}>
        As a Coach, you are responsible for determining and fulfilling your
        obligations under applicable laws to report, collect, remit, or include
        in your price any applicable Taxes.
      </Text>
      <Heading as="h3" size="md" mt={4}>
        8.2 Collection and Remittance by Teach Me To
      </Heading>
      <Text mt={mt}>
        In jurisdictions where Teach Me To facilitates the collection and/or
        remittance of Taxes on behalf of Coaches, you instruct and authorize
        Teach Me To to collect Taxes on your behalf, and/or to remit such Taxes
        to the relevant Tax authority. Any Taxes that are collected and/or
        remitted by Teach Me To are identified to Coaches on their transaction
        records, as applicable. Teach Me To may seek additional amounts from
        Coaches (including by deducting such amounts from future payouts) in the
        event that the Taxes collected and/or remitted are insufficient to fully
        discharge that Coach’s tax obligations, and you agree that your sole
        remedy for Taxes collected by Teach Me To is a refund from the
        applicable Tax authority. You acknowledge and agree that we retain the
        right, with prior notice to affected Coaches, to cease the collection
        and remittance of Taxes in any jurisdiction for any reason.
      </Text>
      <Heading as="h3" size="md" mt={4}>
        8.3 Tax Information
      </Heading>
      <Text mt={mt}>
        In certain jurisdictions, Tax regulations may require that we collect
        and/or report Tax information about you, or withhold Taxes from payouts
        to you, or both. If you fail to provide us with documentation that we
        determine to be sufficient to support any such obligation to withhold
        Taxes from payouts to you, we may withhold payouts up to the amount as
        required by law, until sufficient documentation is provided. You agree
        that Teach Me To may issue on your behalf invoices or similar
        documentation for Taxes for your Coach Services to facilitate accurate
        Tax reporting by you, our Students, and/or their organizations.
      </Text>
      <Heading as="h2" size="lg" mt={4}>
        9. Insurance
      </Heading>
      <Text mt={mt}>
        As part of these Terms, and as a further benefit to Coach, Coach shall
        receive the full benefit of Teach Me To’s blanket liability and personal
        injury policies, with up to $1,000,000 in Lesson protection. Coach
        agrees and acknowledges that (i) Teach Me To may list Coach as an
        additional insured under Teach Me To&apos;s general insurance policies
        and (ii) Coach shall comply with all reasonable requests in connection
        with the foregoing. Teach Me To reserves the right to change its
        insurance policies in its sole discretion.
      </Text>
      <Heading mt={8} as="h1" variant="h2">
        III. General Terms
      </Heading>
      <Heading as="h2" size="lg">
        10. Reviews.
      </Heading>
      <Text mt={mt}>
        After each Lesson, Students and Coaches will have an opportunity to
        review each other. Your review must be accurate and may not contain any
        discriminatory, offensive, defamatory, or other language that violates
        these Terms. Teach Me To does not endorse any reviews available via the
        Teach Me To Platform. Additionally, reviews are not verified by Teach Me
        To for accuracy and may be incorrect or misleading. Teach Me To reserves
        the right to screen, edit, or remove reviews from the Teach Me To
        Platform at any time.
      </Text>

      <Heading as="h2" size="lg">
        11. Content.
      </Heading>
      <Text mt={mt}>
        The Teach Me To Platform enables Members to post, submit, publish,
        display, transmit, or provide (hereinafter, “post”) reviews (as
        discussed in Section 10), feedback, text, photos, audio, video,
        information, and other content (collectively, “Content”). All Content
        must comply with these Terms. Any Content you post to the Teach Me To
        Platform will be considered non-confidential and non-proprietary. Except
        for the limitations on our use and disclosure of personal information
        described in our Privacy Policy, by providing Content, in whatever form
        and through whatever means, you grant Teach Me To a non-exclusive,
        worldwide, royalty-free, irrevocable, perpetual, sub-licensable and
        transferable license to copy, modify, prepare derivative works of,
        distribute, publish and otherwise exploit that Content, without
        limitation, and to sublicense these rights to third parties. Where Teach
        Me To pays for the creation of Content or facilitates its creation,
        Teach Me To may own that Content, in which case supplemental terms or
        disclosures will say that. You are solely responsible for all Content
        that you provide and warrant that you either own or control all rights
        in or to it or are authorized to grant Teach Me To the rights described
        in these Terms. You are responsible for the legality, reliability,
        accuracy, and appropriateness of the Content, and you are responsible
        and liable if any of your Content violates or infringes the intellectual
        property rights, privacy rights, or any other rights of any third party.
        Teach Me To is not responsible or liable to any third party for the
        content or accuracy of any of the Content posted by you. You agree that
        Teach Me To may make available services or automated tools to translate
        Content and that your Content may be translated using such services or
        tools. Teach Me To does not guarantee the accuracy or quality of
        translations and Members are responsible for confirming the accuracy of
        such translations.
      </Text>

      <Text mt={mt}>
        As Content is primarily generated by Members, Teach Me To does not
        control or vet such Content for accuracy, and we do not assume and
        expressly disclaim any responsibility for the accuracy or reliability of
        any Content provided by Members on or off the Teach Me To Platform,
        including any Content that may include misstatements or
        misrepresentations that may be defamatory or disparaging. Members hereby
        represent, understand, and agree to hold Teach Me To harmless for any
        misstatements, misrepresentations, or other disparaging or defamatory
        statements made by or on behalf of them on the Teach Me To Platform or
        in any other venue.
      </Text>

      <Heading as="h2" size="lg">
        12. Fees.
      </Heading>
      <Text mt={mt}>
        Teach Me To may charge fees (and applicable Taxes) to Coaches and
        Students for use of the Teach Me To Platform (“Service Fees”). Except as
        otherwise provided on the Teach Me To Platform, Service Fees are
        non-refundable. Teach Me To reserves the right to change the Service
        Fees at any time without notice. Service Fee changes will not affect
        Lesson bookings made prior to the effective date of the Service Fee
        change.
      </Text>

      <Heading as="h2" size="lg">
        13. Teach Me To Platform Rules.
      </Heading>
      <Heading as="h3" size="md">
        13.1 Rules.
      </Heading>
      <Text mt={mt}>
        You must follow these rules and must not help or induce others to break
        or circumvent these rules. You agree to:
      </Text>
      <UnorderedList styleType="'•  '" spacing={3} mt={2}>
        <ListItem>
          Act with integrity and treat others with respect in all communications
          and interactions;
        </ListItem>
        <ListItem>Read and comply with these Terms; and</ListItem>
        <ListItem>
          Understand and follow the laws that apply to you, including privacy,
          data protection, and export laws, and honor your legal obligations.
        </ListItem>
      </UnorderedList>
      <Text mt={mt}>You agree not to:</Text>
      <UnorderedList styleType="'•  '" spacing={3} mt={2}>
        <ListItem>
          Lie, misrepresent something or someone, impersonate or pretend to be
          someone else, or otherwise misrepresent an affiliation with any person
          or organization;
        </ListItem>
        <ListItem>
          Access or attempt to access data not meant for you, or intercept or
          attempt to intercept electronic mail or other forms of electronic
          communication not intended for you;
        </ListItem>
        <ListItem>
          Discriminate against others based on race, gender, sex, sexual
          orientation, religion, nationality, disability, or age, harass others,
          or otherwise communicate or upload to or transmit on the Teach Me To
          Platform any deceptive, fraudulent, defamatory, indecent, obscene,
          harassing, violent, or otherwise harmful or objectionable material, or
          any material that contains nudity, sexually explicit or pornographic
          material, violence, or discrimination;
        </ListItem>
        <ListItem>
          Contact or attempt to contact any Coach through any channel except
          through the Teach Me To Platform, or contact or attempt to contact any
          Coach except for the explicit purpose of booking Lessons through the
          Teach Me To Platform, including in an attempt to recruit Coaches for
          other business opportunities or competitive platforms;
        </ListItem>
        <ListItem>
          Scrape, hack, reverse engineer, compromise or impair the Teach Me To
          Platform, including by using bots, crawlers, scrapers, or other
          automated means to access or collect data or other content from or
          otherwise interact with the Teach Me To Platform;
        </ListItem>
        <ListItem>
          Use any automated or manual process to monitor or copy any of the
          material on the Teach Me To Platform, or for any other purpose not
          expressly authorized in these Terms, without our prior written
          consent;
        </ListItem>
        <ListItem>
          Probe, scan, or test the vulnerability of the Teach Me To Platform,
          our network, our security, or authentication measures without proper
          authorization;
        </ListItem>
        <ListItem>
          Hack, avoid, remove, impair, or otherwise attempt to circumvent any
          security or technological measure used to protect the Teach Me To
          Platform or Content, including by introducing any viruses, Trojan
          horses, worms, logic bombs, corrupted data, or other material that is
          malicious or technologically harmful;
        </ListItem>
        <ListItem>
          Decipher, decompile, disassemble, or reverse engineer any of the
          software or hardware used to provide the Teach Me To Platform;
        </ListItem>
        <ListItem>
          Attempt to gain unauthorized access to, interfere with, damage, or
          disrupt any part of the Teach Me To Platform, the server on which the
          Teach Me To Platform is stored, or any server, computer, or database
          connected to the Teach Me To Platform;
        </ListItem>
        <ListItem>
          Use the Teach Me To Platform in any manner that could disable,
          overburden, damage, or impair the Teach Me To Platform or interfere
          with any other party’s use of the Teach Me To Platform, including
          their ability to engage in real time activities through the Teach Me
          To Platform, or take any action that could damage or adversely affect
          the performance or proper functioning of the Teach Me To Platform;
        </ListItem>
        <ListItem>
          Use another Member’s personal information except as necessary to
          facilitate a transaction using the Teach Me To Platform as authorized
          by these Terms;
        </ListItem>
        <ListItem>
          Use the Teach Me To Platform, our messaging tools, or other Members’
          personal information to send commercial messages without the
          recipient’s express consent;
        </ListItem>
        <ListItem>
          Transmit or procure the sending of, any advertising or promotional
          material, including any “junk mail,” “chain letter,” “spam,” or any
          similar solicitation;
        </ListItem>
        <ListItem>
          Use Content made available through the Teach Me To Platform except as
          necessary to enable your use of the Teach Me To Platform as a Student
          or Coach;
        </ListItem>
        <ListItem>
          Use Content unless you have permission from the Content owner or the
          use is authorized by us in these Terms or another agreement you have
          with us;
        </ListItem>
        <ListItem>
          Request, make, or accept a Lesson booking or any payment outside of
          the Teach Me To Platform to avoid paying fees, Taxes, or for any other
          reason;
        </ListItem>
        <ListItem>
          Require or encourage Students to open an account, leave a review,
          complete a survey, or otherwise interact with a third-party website,
          application or service before, during, or after a Lesson, unless
          authorized by Teach Me To;
        </ListItem>
        <ListItem>
          Engage in any practices that are intended to manipulate our search
          algorithm;
        </ListItem>
        <ListItem>
          Book Coach Services unless you are actually using the Coach Services;
        </ListItem>
        <ListItem>
          Use, copy, display, mirror or frame the Teach Me To Platform, any
          Content, any Teach Me To branding, or any page layout or design
          without our consent;
        </ListItem>
        <ListItem>
          Use the name, logo, branding, or trademarks of Teach Me To or others
          without permission, or use or register any domain name, social media
          handle, trade name, trademark, branding, logo, or other source
          identifier that may be confused with Teach Me To branding;
        </ListItem>
        <ListItem>
          Violate these Terms or any other policies or agreements that you enter
          into with us;
        </ListItem>
        <ListItem>
          Violate any applicable federal, state, local, or international law or
          regulation, or violate the legal rights (including intellectual
          property rights or the rights of publicity and privacy) of others;
        </ListItem>
        <ListItem>
          Promote any illegal activity or advocate, promote, or assist any
          unlawful act; or
        </ListItem>
        <ListItem>
          Engage in any other conduct that restricts or inhibits the use of the
          Teach Me To Platform, or which, as determined by us in our sole
          discretion, may harm Teach Me To or other Members.
        </ListItem>
      </UnorderedList>

      <Text mt={mt}>
        If you provide us with someone else’s personal information, you: (i)
        must do so in compliance with applicable law, (ii) must be authorized to
        do so, and (iii) authorize us to process that information under our
        Privacy Policy.
      </Text>
      <Text mt={mt}>
        Your failure to comply with the above provisions may result in the
        termination of your access to the Teach Me To Platform and may expose
        you to civil and/or criminal liability. We reserve the right to take
        appropriate legal action, including without limitation, referral to law
        enforcement, for any illegal or unauthorized use of the Teach Me To
        Platform, and to terminate or suspend your access to all or part of the
        Teach Me To Platform for any or no reason, including without limitation,
        any violation of these Terms. Without limiting the foregoing, we have
        the right to fully cooperate with any law enforcement authorities or
        court order requesting or directing us to disclose the identity or other
        information of anyone posting any materials on or through the Teach Me
        To Platform. YOU WAIVE AND HOLD US AND OUR AFFILIATES, LICENSEES, AND
        SERVICE PROVIDERS HARMLESS FROM ANY CLAIMS RESULTING FROM ANY ACTION
        TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS
        INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF
        INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
      </Text>
      <Heading as="h3" size="md">
        13.2 Reporting Violations.
      </Heading>
      <Text mt={mt}>
        If you believe that a Member, Coach Service, Lesson, or Content poses an
        imminent risk of harm to a person or property, you should immediately
        contact local authorities before contacting Teach Me To. In addition, if
        you believe that a Member, Coach Service, Lesson, or Content violates or
        has violated these Terms, you should report your concerns to Teach Me
        To. If you reported an issue to local authorities, Teach Me To may
        request a copy of that report. Except as required by law, you agree that
        we are not obligated to act in response to any report. We cannot review
        all material before it is posted on the Teach Me To Platform, and we
        cannot ensure prompt removal of objectionable material after it has been
        posted. Accordingly, we assume no liability for any action or inaction
        regarding transmissions, communications, or Content provided by any
        Member or third party. We have no liability or responsibility to you or
        any third party for performance or nonperformance of the activities
        described herein.
      </Text>

      <Heading as="h2" size="lg">
        14. Intellectual Property Rights.
      </Heading>
      <Text mt={mt}>
        The Teach Me To Platform and all related content, features, and
        functionality (including but not limited to all information, software,
        text, displays, images, video, and audio, and the design, selection, and
        arrangement thereof) are owned by Teach Me To, its licensors, or other
        providers of such material and are protected by United States and
        international copyright, trademark, patent, trade secret, and other
        intellectual property or proprietary rights laws. You acknowledge that
        all related intellectual property rights are the exclusive property of
        Teach Me To, its licensors, or other providers of such material, and
        agree that you will not remove, alter or obscure any copyright,
        trademark, service mark or other proprietary rights notices. You may not
        use, copy, adapt, modify, prepare derivative works of, distribute,
        license, sell, transfer, publicly display, publicly perform, transmit,
        broadcast or otherwise exploit any material accessed through the Teach
        Me To Platform except to the extent you are the legal owner of that
        material or as expressly permitted in these Terms. Subject to your
        compliance with these Terms, Teach Me To grants you a limited,
        non-exclusive, non-sublicensable, revocable, non-transferable license to
        (i) download and use the Teach Me To Platform on your personal
        device(s); and (ii) access and view the material made available on or
        through the Teach Me To Platform and accessible to you, solely for your
        personal and non-commercial use.
      </Text>
      <Text mt={mt}>
        If you print, copy, modify, download, or otherwise use or provide any
        other person with access to any part of the Teach Me To Platform in
        breach of these Terms, your right to use the Teach Me To Platform will
        stop immediately and you must, at our option, return or destroy any
        copies of the materials you have made. No right, title, or interest in
        or to the Teach Me To Platform or any content therein is transferred to
        you, and all rights not expressly granted are reserved by Teach Me To.
        Any use of the Teach Me To Platform not expressly permitted by these
        Terms is a breach of these Terms and may violate copyright, trademark,
        and other laws.
      </Text>
      <Text mt={mt}>
        Except as expressly provided, nothing contained in these Terms or
        anywhere on the Teach Me To Platform shall be construed as conferring
        any license under any intellectual property rights of Teach Me To, its
        licensors or any third party.
      </Text>
      <Text mt={mt}>
        Unauthorized attempts to upload information or change information are
        strictly prohibited and may be punishable under the Computer Fraud and
        Abuse Act of 1986.
      </Text>
      <Text mt={mt}>
        We respect the intellectual property rights of others, and it is our
        policy to respond expeditiously to claims of intellectual property
        infringement. We will promptly process and investigate notices of
        alleged infringement and take appropriate actions under the Online
        Copyright Infringement Liability Limitation Act of the Digital
        Millennium Copyright Act and other applicable intellectual property
        laws. If you are aware of or experience any circumstances that appear to
        be a violation of these Terms, please inform us by contacting us as
        provided in Section 29.9.
      </Text>
      <Text mt={mt}>Any such notice must include:</Text>
      <UnorderedList styleType="'• '" spacing={3} mt={2}>
        <ListItem>
          an electronic or physical signature of the owner of the copyright or
          trademark interest or of the person authorized to act on behalf of the
          owner of the copyright or trademark interest;
        </ListItem>
        <ListItem>
          a description of the copyrighted work or trademark that you claim has
          been infringed;
        </ListItem>
        <ListItem>
          a description of the location on the Teach Me To Platform of the
          claimed infringing material;
        </ListItem>
        <ListItem>your address, telephone number, and email address;</ListItem>
        <ListItem>
          a statement by you that you have a good faith belief that the claimed
          infringing use is not authorized by the copyright or trademark owner,
          its duly authorized agent, or the law; and
        </ListItem>
        <ListItem>
          a statement by you that the information in your notice is accurate and
          that you are the copyright or trademark owner or are authorized to act
          on the copyright or trademark owner’s behalf.
        </ListItem>
      </UnorderedList>
      <Text mt={mt}>
        Upon receipt of such notice, we will act expeditiously to remove or
        disable access to any material claimed to be infringing or claimed to be
        the subject of infringing activity and will act expeditiously to remove
        or disable access to any reference or link to material or activity that
        is claimed to be infringing. We will terminate access for a who is a
        repeat infringer.
      </Text>

      <Heading as="h2" size="lg">
        15. Termination, Suspension and other Measures.
      </Heading>
      <Heading as="h3" size="md">
        15.1 Term.
      </Heading>
      <Text mt={mt}>
        The agreement between you and Teach Me To reflected by these Terms is
        effective when you access the Teach Me To Platform (for example to
        create an account) and remains in effect until either you or we
        terminate the agreement in accordance with these Terms.
      </Text>

      <Heading as="h3" size="md">
        15.2 Termination.
      </Heading>
      <Text mt={mt}>
        You may terminate this agreement at any time by sending us an email or
        by deleting your account. Teach Me To may terminate this agreement and
        your access to the Teach Me To Platform or your account for any reason
        or no reason by giving you 30 days’ notice via email or using any other
        contact information you have provided for your account. Teach Me To may
        also terminate this agreement immediately and without notice and stop
        providing access to the Teach Me To Platform if you breach these Terms,
        you violate applicable laws, or we reasonably believe termination is
        necessary to protect Teach Me To, its Members, or third parties. If your
        account has been inactive for more than two years, we may terminate your
        account without prior notice.
      </Text>

      <Heading as="h3" size="md">
        15.3 Member Violations.
      </Heading>
      <Text mt={mt}>
        If (i) you breach these Terms, (ii) you violate applicable laws,
        regulations, or third-party rights, or (iii) Teach Me To believes it is
        reasonably necessary to protect Teach Me To, its Members, or third
        parties, Teach Me To may, with or without prior notice:
      </Text>
      <UnorderedList styleType="'• '" spacing={3} mt={2}>
        <ListItem>
          suspend or limit your access to or use of the Teach Me To Platform
          and/or your account;
        </ListItem>
        <ListItem>
          suspend or remove Coach Services, Lessons, reviews, or other Content;
        </ListItem>
        <ListItem>cancel pending or confirmed Lesson bookings; or</ListItem>
        <ListItem>
          suspend or revoke any special status associated with your account.
        </ListItem>
      </UnorderedList>
      <Text mt={mt}>
        For minor violations or where otherwise appropriate as Teach Me To
        determines in its sole discretion, you will be given notice of any
        intended remediation measure by Teach Me To and an opportunity to
        resolve the issue. You may appeal actions taken by us under this Section
        15 by contacting customer service. If a Lesson is cancelled under this
        Section, the amount paid to the Coach will be reduced by the amount we
        refund or otherwise provide to the Student, and by any other costs we
        incur as a result of the cancellation.
      </Text>

      <Heading as="h3" size="md">
        15.4 Legal Mandates.
      </Heading>
      <Text mt={mt}>
        Teach Me To may take any action it determines is reasonably necessary to
        comply with applicable law, or the order or request of a court, law
        enforcement, or other administrative agency or governmental body,
        including the measures described above in Section 15.3.
      </Text>

      <Heading as="h3" size="md">
        15.5 Effect of Termination.
      </Heading>
      <Text mt={mt}>
        If you are a Coach and terminate your Teach Me To account, any confirmed
        Lesson booking(s) will be automatically cancelled, and your Students
        will receive a full refund. If you terminate your account as a Student,
        any confirmed Lesson booking(s) will be automatically cancelled, and any
        refund will depend upon the terms of the Coach Service cancellation
        policy. When this agreement has been terminated, you are not entitled to
        a restoration of your account or any of your Content. If your access to
        or use of the Teach Me To Platform has been limited, your Teach Me To
        account has been suspended, or this agreement has been terminated by us,
        you may not register a new account or access or use the Teach Me To
        Platform through an account of another Member.
      </Text>

      <Heading as="h3" size="md">
        15.6 Survival.
      </Heading>
      <Text mt={mt}>
        Parts of these Terms that by their nature survive termination will
        survive termination of this agreement.
      </Text>
      <Heading as="h2" size="lg">
        16. Modification.
      </Heading>
      <Text mt={mt}>
        Teach Me To reserves the right to modify these Terms, in its sole
        discretion and without advance notice, at any time. When we make
        material changes to these Terms, we will post the revised Terms on the
        Teach Me To Platform and update the “Last Updated” date at the top of
        these Terms. If you disagree with the revised Terms, you may terminate
        this agreement immediately as provided in these Terms. If you do not
        terminate your agreement before the date the revised Terms become
        effective, your continued access to or use of the Teach Me To Platform
        will constitute acceptance of the revised Terms, so please check this
        page periodically for updates.
      </Text>

      <Heading as="h2" size="lg">
        17. Resolving Complaints and Damage Claims.
      </Heading>
      <Text mt={mt}>
        If a Member provides evidence that another Member damaged their real or
        personal property (“Damage Claim”), the complaining Member can seek
        compensation by contacting us as provided in Section 29.9. If the
        complaining Member escalates a Damage Claim to Teach Me To, the other
        Member will be given an opportunity to respond. If the responding Member
        agrees to pay, or Teach Me To determines in its sole discretion that the
        responding Member is responsible for the Damage Claim, Teach Me To can
        collect any sums required to cover the Damage Claim from the responding
        Member. You agree that Teach Me To may seek to recover from you under
        any insurance policies you maintain and that Teach Me To may also pursue
        against you any remedies it may have available under applicable law. You
        agree to cooperate in good faith, provide any information Teach Me To
        requests, execute documents, and take further reasonable action in
        connection with Damage Claims, Member complaints, claims under insurance
        policies, or other claims related to your provision or use of Coach
        Services.
      </Text>

      <Heading as="h2" size="lg">
        18. Teach Me To’s Role.
      </Heading>
      <Text mt={mt}>
        We offer a platform that enables Members to publish, offer, search for,
        and book Coach Services. While we work hard to ensure our Members have
        great experiences using Teach Me To, Teach Me To is a neutral venue for
        Students and Coaches, and we do not and cannot control the conduct of
        Students and Coaches. We make no representations or warranties regarding
        the quality of Coach Services, or about your interactions and dealings
        with other Members. Coaches listed on the Teach Me To Platform are not
        under the direction or control of Teach Me To, and Coaches determine in
        their own discretion how to provide the Coach Services. Teach Me To does
        not employ, recommend, or endorse Members, nor do we make any
        representations about the suitability, reliability, timeliness, or
        accuracy of the Coach Services, and, to the maximum extent permitted by
        applicable law, we will not be responsible or liable for the performance
        or conduct of Members, whether online or offline. The Teach Me To
        Platform may be used to find and offer Coach Services and to facilitate
        payment, but all transactions conducted via the Teach Me To Platform are
        between Students and Coaches. You acknowledge that Teach Me To has the
        right, but does not have any obligation, to monitor the use of the Teach
        Me To Platform and verify information provided by our Members. For
        example, we may review, disable access to, remove, or edit Content to:
        (i) operate, secure, and improve the Teach Me To Platform (including for
        fraud prevention, risk assessment, investigation and customer support
        purposes); (ii) ensure Members’ compliance with these Terms; (iii)
        comply with applicable law or the order or requirement of a court, law
        enforcement, or other administrative agency or governmental body; (iv)
        address Content that we determine is harmful or objectionable; (v) take
        actions set out in these Terms; and (vi) maintain and enforce any
        quality or eligibility criteria, including by removing Lessons that
        don’t meet quality and eligibility criteria. Members agree to cooperate
        with and assist Teach Me To in good faith, and to provide Teach Me To
        with such information and take such actions as may be reasonably
        requested by Teach Me To with respect to any investigation undertaken by
        Teach Me To regarding the use or abuse of the Teach Me To Platform.
      </Text>

      <Heading as="h2" size="lg">
        19. Member Accounts.
      </Heading>
      <Text mt={mt}>
        You must register an account to access and use many features of the
        Teach Me To Platform. Registration is only permitted for legal entities,
        partnerships and natural persons who are 18 years or older. You
        represent and warrant that you are not a person or entity barred from
        using the Teach Me To Platform under the laws of the United States, your
        place of residence, or any other applicable jurisdiction. You represent
        and warrant that you are not registered or currently required to
        register as a sex offender with any government entity, and that you are
        not the subject of a conviction, arrest, charge, complaint, restraining
        order, or any other legal action involving any felony; any other
        criminal offense involving violence, physical, sexual, or emotional
        abuse or neglect, or theft or drugs; or any offense, violation, or
        unlawful act that involves endangering the safety of others, fraud,
        dishonesty, or reckless or negligent conduct. You must provide accurate,
        current, and complete information during registration and keep your
        account information up to date. You may not register more than one
        account or transfer your account to someone else. You are responsible
        for maintaining the confidentiality and security of your account
        credentials and may not disclose your credentials to any third party.
        You are responsible and liable for activities conducted through your
        account and must immediately notify Teach Me To if you suspect that your
        credentials have been lost, stolen, or your account is otherwise
        compromised. If and as permitted by applicable law, we may, but have no
        obligation to (i) ask you to provide identification or other
        information, (ii) undertake checks designed to help verify your identity
        or background, (iii) screen you against third-party databases or other
        sources and request reports from service providers, and (iv) obtain
        reports from public records of criminal convictions or sex offender
        registrations or their local equivalents.
      </Text>

      <Heading as="h2" size="lg">
        20. Consent to Electronic Communications.
      </Heading>
      <Text mt={mt}>
        When you visit the Teach Me To Platform, submit a request for help, send
        an email to us, create an account, or otherwise communicate with us
        through the Teach Me To Platform or electronically, you expressly
        consent to the use of electronic signatures, electronic receipt of
        records and notices, and electronic communication by Teach Me To or
        third-party service providers. This includes, but is not limited to,
        posting notices on the Teach Me To Platform or responding to your email.
        You agree that all agreements, notices, disclosures, or other
        communications that we provide to you electronically satisfy any legal
        requirement that such communications be in writing. Teach Me To reserves
        the right, in its sole discretion, to communicate with you in paper
        form. You may withdraw your consent to electronic communications by
        contacting us as provided in Section 29.9. You may also receive emails
        from us for marketing purposes. No fee is charged for these emails, but
        third-party data rates could apply. You can control whether you receive
        these emails using the notification preferences in your account
        settings. Please note that you will not be able to take advantage of
        certain promotions if you disable certain communication settings or do
        not have a Teach Me To account. By providing your phone number, you
        consent to receive direct dial calls, auto-dialed calls, and
        pre-recorded message calls from Teach Me To. You may withdraw your
        consent to such calls at any time by contacting us as provided in
        Section 29.9. Additionally, by providing your phone number, you consent
        to receive text messages from Teach Me To, including text messages for
        marketing purposes. You understand that you are not required to provide
        this consent as a condition of purchase or use of the Teach Me To
        Platform. You may withdraw your consent and opt out of text messages by
        texting “STOP” to any text message we send to you or by contacting us as
        provided in Section 29.9. If you opt out of receiving text messages, you
        will receive a single confirmation message confirming that we have
        received your opt-out. Note that opting out of text messages does not
        constitute withdrawing consent to direct dial calls, auto-dialed calls,
        or pre-recorded message calls, which consent must be revoked as set
        forth in the preceding paragraph. If you use a mobile device, please be
        aware that your carrier’s normal rates and fees, such as text messaging
        and data charges, will still apply. You represent that you are the
        account holder for any phone number you provide. In the event you change
        or deactivate your phone number, you agree that you will update your
        account information on the Teach Me To Platform or otherwise notify us
        within forty-eight (48) hours to ensure that your messages are not sent
        to the person who acquires your old number.
      </Text>

      <Heading as="h2" size="lg">
        21. Availability
      </Heading>
      <Text mt={mt}>
        We may periodically schedule system downtime for maintenance and other
        purposes, and the Teach Me To Platform may be unavailable due to
        unplanned system outages or circumstances outside of our control. We
        shall have no liability to any Member or third party whatsoever for the
        resulting unavailability of the Teach Me To Platform, or for any loss of
        data or transactions caused by planned or unplanned system outages, or
        for the resultant delay, misdelivery, or non-delivery of information
        caused by such system outages. We reserve the right to terminate,
        withdraw, or amend the Teach Me To Platform or any portion thereof,
        including any product, service, or material we provide on or through the
        Teach Me To Platform, in our sole discretion without notice. We will not
        be liable if for any reason all or any part of the Teach Me To Platform
        is unavailable at any time or for any period. From time to time, we may
        restrict access to some parts of the Teach Me To Platform, or to the
        Teach Me To Platform altogether.
      </Text>
      <Heading as="h2" size="lg">
        22. Errors
      </Heading>
      <Text mt={2}>
        We attempt to provide the most recent, accurate, and reliable
        information on the Teach Me To Platform. However, there may be occasions
        when information featured on the Teach Me To Platform may contain
        typographical errors, incomplete data, inaccuracies, or items featured
        on the Teach Me To Platform that are no longer available. We do not
        warrant that the information accessible via the Teach Me To Platform is
        accurate, complete, or current. Any errors are unintentional, and we
        apologize if erroneous information is reflected on the Teach Me To
        Platform. We reserve the right to correct errors and to update the Teach
        Me To Platform at any time, but we are under no obligation to update the
        Teach Me To Platform or any related content.
      </Text>

      <Heading as="h2" size="lg">
        23. Disclaimer of Warranties
      </Heading>
      <Text mt={2}>
        We provide the Teach Me To Platform and all Content “as is” and without
        any warranty or condition, express, implied, or statutory. We do not
        guarantee and do not promise any specific results from use of the Teach
        Me To Platform. We reserve the right, for example, to limit or eliminate
        access to the Teach Me To Platform in specific geographic areas and/or
        at specific times based on commercial viability, public health concerns,
        or changes in law. To the fullest extent permitted by law, we
        specifically disclaim any implied warranties of title, merchantability,
        fitness for a particular purpose and non-infringement. Some states do
        not allow the disclaimer of implied warranties, so the foregoing
        disclaimer may not apply to you.
      </Text>
      <Text mt={2}>
        You understand that we cannot and do not guarantee, endorse, or warrant
        the existence, conduct, performance, safety, quality, legality or
        suitability of any Member, Coach Service, or Lesson, and we do not
        warrant that verification, identity or background checks will be
        conducted on Members, or will identify past misconduct or prevent future
        misconduct if conducted. Use of the Teach Me To Platform comes with
        certain inherent risks. We do not provide any training, supervision, or
        monitoring of Members, and we cannot guarantee that all of your
        interactions with other Members will always be 100% safe and respectful.
        You agree to assume all risks when using the Teach Me To Platform,
        including but not limited to injury, illness, death, and all other risks
        associated with any online or offline interactions with Members. You
        also agree not to rely solely on any steps Teach Me To may take to vet
        or screen Members and/or their Content, or otherwise to promote the
        safety of the Teach Me To Platform.
      </Text>
      <Text mt={2}>
        We cannot guarantee that each Member is who he or she claims to be.
        Please use common sense when using the Teach Me To Platform, including
        looking at any photos of the Member you have booked with to make sure it
        is the same individual you see in person. Please note that there are
        also risks of dealing with underage persons or people acting under false
        pretense, and we do not accept responsibility or liability for any
        content, communication or other use or access of the Teach Me To
        Platform by persons under the age of 18 in violation of these Terms.
      </Text>
      <Text mt={2}>
        Teach Me To Platform is not responsible for the conduct, whether online
        or offline, of any Member. You are solely responsible for your
        interactions with other Members. By using the Teach Me To Platform, you
        agree to accept such risks and agree that Teach Me To is not responsible
        for the acts or omissions of Members.
      </Text>
      <Text mt={2}>
        You are responsible for the use of your account and Teach Me To
        expressly disclaims any liability arising from the unauthorized use of
        your account.
      </Text>
      <Text mt={2}>
        It is possible for others to obtain information about you that you
        provide, publish or post to or through the Teach Me To Platform, send to
        other Members, or otherwise share, and to use such information to harass
        or harm you. We are not responsible for the use of any personal
        information that you disclose to other Members on the Teach Me To
        Platform. Please carefully select the type of information that you post
        on the Teach Me To Platform or release to others. We disclaim all
        liability, regardless of the form of action, for the acts or omissions
        of other Members (including unauthorized users, or “hackers”).
      </Text>
      <Text mt={2}>
        We do not warrant that your use of the Teach Me To Platform will be
        accurate, complete, reliable, current, secure, uninterrupted, always
        available, or error-free, or will meet your requirements, that any
        defects in the Teach Me To Platform will be corrected, or that the Teach
        Me To Platform is free of viruses or other harmful components. We
        disclaim liability for, and no warranty is made with respect to,
        connectivity, availability, accuracy, completeness, and reliability of
        the Teach Me To Platform. You are responsible at all times for your
        conduct and the consequences of your conduct while using the Teach Me To
        Platform. The disclaimers in these Terms apply to the maximum extent
        permitted by law. If you have statutory rights or warranties we cannot
        disclaim, the duration of any such statutorily required rights or
        warranties, will be limited to the maximum extent permitted by law.
      </Text>

      <Heading as="h2" size="lg">
        24. Limitations on Liability
      </Heading>
      <Text mt={2}>
        To the maximum extent permitted under applicable law, in no event will
        Teach Me To nor any other party involved in creating, producing, or
        delivering the Teach Me To Platform or any Content be liable for any
        indirect, incidental, special, exemplary, consequential, or punitive
        damages, including lost profits, loss of data, loss of goodwill, service
        interruption, computer damage or system failure or the cost of
        substitute products or services, or for any damages for personal or
        bodily injury or emotional distress arising out of or in connection with
        (i) these Terms, (ii) the use of or inability to use the Teach Me To
        Platform or any Content, (iii) any communications, interactions or
        meetings you may have with someone you interact or meet with through, or
        as a result of, your use of the Teach Me To Platform, or (iv) publishing
        a Coach Service or booking a Lesson, including the provision or use of
        Coach Services, whether based on warranty, contract, tort (including
        negligence), product liability or any other legal theory, and whether or
        not Teach Me To has been informed of the possibility of such damage,
        even if a limited remedy set out in these Terms is found to have failed
        its essential purpose.
      </Text>
      <Text mt={2}>
        Except for our obligation to transmit payments to Coaches under these
        Terms, in no event will Teach Me To&apos;s aggregate liability for any
        claim or dispute arising out of or in connection with these Terms, your
        interaction with any Members, or your use of or inability to use the
        Teach Me To Platform, any Content, or any Coach Service or Lesson,
        exceed: (A) to Students, the amount you paid as a Student during the
        12-month period prior to the event giving rise to the liability, (B) to
        Coaches, the amount paid to you as a Coach in the 12-month period prior
        to the event giving rise to the liability, or (C) to anyone else, one
        hundred U.S. dollars (US$100). TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, IN NO EVENT WILL TEACH ME TO BE LIABLE FOR ANY DAMAGES
        WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY,
        AND/OR CONSEQUENTIAL, ARISING OUT OF OR RELATING TO THE CONDUCT OF YOU
        OR ANYONE ELSE IN CONNECTION WITH THE TEACH ME TO PLATFORM, INCLUDING
        WITHOUT LIMITATION, PROPERTY DAMAGE, THEFT, BODILY INJURY, DEATH,
        EMOTIONAL DISTRESS, AND/OR ANY OTHER DAMAGES RESULTING FROM RELIANCE ON
        INFORMATION OR CONTENT POSTED ON OR TRANSMITTED THROUGH THE TEACH ME TO
        PLATFORM, OR FOR ANY INTERACTIONS WITH OTHER MEMBERS, WHETHER ONLINE OR
        OFFLINE. THIS INCLUDES, BUT IS NOT LIMITED TO, ANY CLAIMS, LOSSES, OR
        DAMAGES ARISING FROM THE CONDUCT OF MEMBERS WHO ATTEMPT TO DEFRAUD OR
        HARM YOU, THE CONDUCT OF ANY OTHER THIRD PARTIES, OR ENVIRONMENTAL
        DANGERS (SUCH AS INCLEMENT WEATHER OR ANIMAL ENCOUNTERS).
      </Text>
      <Text mt={2}>
        These limitations of liability and damages are fundamental elements of
        the agreement between you and Teach Me To. If applicable law does not
        allow the limitations of liability set out in these Terms, the above
        limitations may not apply to you, and you may have additional rights.
      </Text>

      <Heading as="h2" size="lg">
        25. Indemnification
      </Heading>
      <Text mt={2}>
        To the maximum extent permitted by applicable law, you agree to release,
        defend (at Teach Me To&apos;s option), indemnify, and hold Teach Me To,
        its licensors and service providers, and its and their respective
        officers, managers, directors, employees, contractors, agents,
        licensors, suppliers, successors, and assigns harmless from and against
        any claims, liabilities, damages, losses, and expenses, including,
        without limitation, reasonable legal and accounting fees, arising out of
        or in any way connected with: (i) your access, use, or misuse of the
        Teach Me To Platform; (ii) your Content, including any allegation that
        your Content infringes, misappropriates, or otherwise violates the
        copyright, trademark, trade secret or other intellectual property or
        other rights of any third party; (iii) your breach of these Terms
        (including any supplemental or additional terms that apply to a product
        or feature); (iv) your interaction with any Member (online or offline)
        or participation in or use of any Coach Service or Lesson, including
        without limitation any injuries, losses, or damages (whether
        compensatory, direct, incidental, consequential, or otherwise) of any
        kind arising in connection with or as a result of such interaction,
        participation, or use; (v) your failure, or our failure at your
        direction, to accurately report, collect or remit Taxes; (vi) your
        breach of any laws, regulations or third party rights such as
        intellectual property or privacy rights; (vii) your negligent conduct;
        and/or (viii) any other activities in connection with the Teach Me To
        Platform and Coach Services. This indemnity shall be applicable without
        regard to the negligence of any party, including any indemnified person.
        You will not, without Teach Me To’s prior written consent, agree to any
        settlement on behalf of any indemnified party which includes either the
        obligation to pay any monetary amounts, or any admissions of liability,
        whether civil or criminal, on the part of any indemnified party.
      </Text>

      <Heading as="h2" size="lg">
        26. Limitation on Time to File Claims
      </Heading>
      <Text mt={2}>
        Any cause of action or claim you may have arising out of or relating to
        these Terms or the Teach Me To Platform must be commenced within one (1)
        year after the cause of action accrues; otherwise, such cause of action
        or claim is permanently barred.
      </Text>

      <Heading as="h2" size="lg">
        27. United States Governing Law and Venue
      </Heading>
      <Text mt={2}>
        If you reside or have your place of establishment in the United States,
        these Terms will be interpreted in accordance with the laws of the State
        of California and the United States of America, without regard to
        conflict-of-law provisions. Judicial proceedings (other than small
        claims actions) that are excluded from the arbitration agreement in
        Section 28 must be brought in state or federal court in San Francisco,
        California, unless we both agree to some other location. You and we both
        consent to venue and personal jurisdiction in San Francisco, California.
      </Text>
      <Heading as="h2" size="lg">
        28. United States Dispute Resolution and Arbitration Agreement
      </Heading>
      <Text mt={2}>
        <strong>28.1 Application.</strong> This Arbitration Agreement (as
        defined below) only applies to you if your country of residence or
        establishment is the United States. If your country of residence or
        establishment is not the United States, and you nevertheless attempt to
        bring any legal claim against Teach Me To in the United States, this
        Arbitration Agreement will apply for determination of the threshold
        issue of whether this Section 28 applies to you, and all other threshold
        determinations, including residency, arbitrability, venue, and
        applicable law.
      </Text>
      <Text mt={2}>
        <strong>28.2 Overview of Dispute Resolution Process.</strong> Teach Me
        To is committed to participating in a consumer-friendly dispute
        resolution process. To that end, these Terms provide for a two-part
        process for individuals to whom this Section 28 applies: (1) an informal
        negotiation directly with Teach Me To&apos;s customer service team
        (described in Section 28.3, below), and if necessary (2) a binding
        arbitration administered by the American Arbitration Association
        (“AAA”). You and Teach Me To each retain the right to seek relief in
        small claims court as an alternative to arbitration.
      </Text>
      <Text mt={2}>
        <strong>
          28.3 Mandatory Pre-Arbitration Dispute Resolution and Notification.
        </strong>{' '}
        At least 30 days prior to initiating an arbitration, you and Teach Me To
        each agree to notify the other party of the dispute in writing and
        attempt in good faith to negotiate an informal resolution. You must send
        your notice of dispute to Teach Me To by mailing it to Teach Me
        To&apos;s agent for service: Corporation Service Company, 251 Little
        Falls Drive, Wilmington, DE 19808. Teach Me To will send its notice of
        dispute to the email address associated with your Teach Me To account. A
        notice of dispute must include: the party&apos;s name and preferred
        contact information, a brief description of the dispute, and the relief
        sought. If the parties are unable to resolve the dispute within the
        30-day period, only then may either party commence arbitration by filing
        a written Demand for Arbitration (available at www.adr.org) with the AAA
        and providing a copy to the other party as specified in the AAA Rules
        (available at www.adr.org).
      </Text>
      <Text mt={2}>
        <strong>28.4 Agreement to Arbitrate.</strong> You and Teach Me To
        mutually agree that any dispute, claim or controversy arising out of or
        relating to these Terms or the applicability, breach, termination,
        validity, enforcement or interpretation thereof, or any use of the Teach
        Me To Platform, Coach Services, or any Content (collectively,
        “Disputes”) will be settled by binding individual arbitration (the
        “Arbitration Agreement”). If there is a dispute about whether this
        Arbitration Agreement can be enforced or applies to our Dispute, you and
        Teach Me To agree that the arbitrator will decide that issue.
      </Text>
      <Text mt={2}>
        <strong>28.5 Exceptions to Arbitration Agreement.</strong> You and Teach
        Me To each agree that the following causes of action and/or claims for
        relief are exceptions to the Arbitration Agreement and will be brought
        in a judicial proceeding in a court of competent jurisdiction (as
        defined by Section 27): (i) any claim or cause of action alleging actual
        or threatened infringement, misappropriation or violation of a
        party&apos;s copyrights, trademarks, trade secrets, patents, or other
        intellectual property rights; (ii) any claim or cause of action seeking
        emergency injunctive relief based on exigent circumstances (e.g.,
        imminent danger or commission of a crime, hacking, cyber-attack); or
        (iii) a request for the remedy of public injunctive relief. You and
        Teach Me To agree that the remedy of public injunctive relief will
        proceed after the arbitration of all arbitrable claims, remedies, or
        causes of action, and will be stayed pending the outcome of the
        arbitration pursuant to section 3 of the Federal Arbitration Act.
      </Text>
      <Text mt={2}>
        <strong>28.6 Arbitration Rules and Governing Law.</strong> This
        Arbitration Agreement evidences a transaction in interstate commerce and
        the Federal Arbitration Act governs all substantive and procedural
        interpretation and enforcement of this provision. The arbitration will
        be administered by AAA in accordance with the Consumer Arbitration Rules
        and/or other AAA arbitration rules determined to be applicable by the
        AAA (the “AAA Rules“) then in effect, except as modified here. The AAA
        Rules are available at www.adr.org. In order to initiate arbitration, a
        completed written demand (available at www.adr.org) must be filed with
        the AAA and provided to the other party, as specified in the AAA rules.
      </Text>
      <Text mt={2}>
        <strong>
          28.7 Modification to AAA Rules - Arbitration Hearing/Location.
        </strong>{' '}
        In order to make the arbitration most convenient to you, Teach Me To
        agrees that any required arbitration hearing may be conducted, at your
        option: (a) in the U.S. county where you reside; (b) in San Francisco
        County; (c) via phone or video conference; or (d) if all parties agree,
        by solely the submission of documents to the arbitrator.
      </Text>
      <Text mt={2}>
        <strong>
          28.8 Modification of AAA Rules - Attorney&apos;s Fees and Costs.
        </strong>{' '}
        Your arbitration fees and your share of arbitrator compensation shall be
        governed by the AAA Rules and, where appropriate, limited by the AAA
        Consumer Rules. If such costs are determined by the arbitrator to be
        excessive, Teach Me To will pay all arbitration fees and expenses.
        Either party may make a request that the arbitrator award
        attorneys&apos; fees and costs upon proving that the other party has
        asserted a claim, crossclaim or defense that is groundless in fact or
        law, brought in bad faith or for the purpose of harassment, or is
        otherwise frivolous, as allowed by applicable law and the AAA Rules.
      </Text>
      <Text mt={2}>
        <strong>28.9 Arbitrator&apos;s Decision.</strong> The arbitrator&apos;s
        decision will include the essential findings and conclusions upon which
        the arbitrator based the award. Judgment on the arbitration award may be
        entered in any court with proper jurisdiction. The arbitrator may award
        any relief allowed by law or the AAA Rules, but declaratory or
        injunctive relief may be awarded only on an individual basis and only to
        the extent necessary to provide relief warranted by the claimant&apos;s
        individual claim.
      </Text>
      <Text mt={2}>
        <strong>28.10 Jury Trial Waiver.</strong> You and Teach Me To
        acknowledge and agree that we are each waiving the right to a trial by
        jury as to all arbitrable Disputes.
      </Text>
      <Text mt={2}>
        <strong>28.11 No Class Actions or Representative Proceedings.</strong>{' '}
        You and Teach Me To acknowledge and agree that, to the fullest extent
        permitted by law, we are each waiving the right to participate as a
        plaintiff or class member in any purported class action lawsuit,
        class-wide arbitration, private attorney general action, or any other
        representative or consolidated proceeding. Unless we agree in writing,
        the arbitrator may not consolidate more than one party&apos;s claims and
        may not otherwise preside over any form of any class or representative
        proceeding. If there is a final judicial determination that applicable
        law precludes enforcement of the waiver contained in this paragraph as
        to any claim, cause of action or requested remedy, then that claim,
        cause of action or requested remedy, and only that claim, cause of
        action or requested remedy, will be severed from this agreement to
        arbitrate and will be brought in a court of competent jurisdiction. In
        the event that a claim, cause of action or requested remedy is severed
        pursuant to this paragraph, then you and we agree that the claims,
        causes of action or requested remedies that are not subject to
        arbitration will be stayed until all arbitrable claims, causes of action
        and requested remedies are resolved by the arbitrator.
      </Text>
      <Text mt={2}>
        <strong>28.12 Severability.</strong> Except as provided in Section
        28.11, in the event that any portion of this Arbitration Agreement is
        deemed illegal or unenforceable, such provision will be severed and the
        remainder of the Arbitration Agreement will be given full force and
        effect.
      </Text>
      <Text mt={2}>
        <strong>28.13 Changes to Agreement to Arbitrate.</strong> If Teach Me To
        changes this Section 28 after the date you last accepted these Terms (or
        accepted any subsequent changes to these Terms), you may reject that
        change by sending us written notice (including by email) within 30 days
        of the date the change is effective. Rejecting a new change, however,
        does not revoke or alter your prior consent to any earlier agreements to
        arbitrate any Dispute between you and Teach Me To (or your prior consent
        to any subsequent changes thereto), which will remain in effect and
        enforceable as to any Dispute between you and Teach Me To.
      </Text>
      <Text mt={2}>
        <strong>28.14 Survival.</strong> Except as provided in Section 28.12 and
        subject to Section 15.6, this Section 28 will survive any termination of
        these Terms and will continue to apply even if you stop using the Teach
        Me To Platform or terminate your Teach Me To account.
      </Text>
      <Heading as="h2" size="lg">
        29. Miscellaneous
      </Heading>
      <Text mt={2}>
        <strong>29.1 Other Terms Incorporated by Reference.</strong> Any
        supplemental policies and terms linked to in these Terms apply to your
        use of the Teach Me To Platform, are incorporated by reference, and form
        part of your agreement with Teach Me To.
      </Text>
      <Text mt={2}>
        <strong>29.2 Interpreting these Terms.</strong> Except as they may be
        supplemented by additional terms, conditions, policies, guidelines,
        standards, and in-product disclosures, these Terms (including those
        items incorporated by reference) constitute the entire agreement between
        Teach Me To and you pertaining to your access to or use of the Teach Me
        To Platform and supersede any and all prior oral or written
        understandings or agreements between Teach Me To and you. These Terms do
        not and are not intended to confer any rights or remedies upon anyone
        other than you and Teach Me To. If any provision of these Terms is held
        to be invalid or unenforceable, except as otherwise indicated in Section
        28.11 above, such provision will be struck and will not affect the
        validity and enforceability of the remaining provisions. Where the word
        “will” is used in these Terms it connotes an obligation with the same
        meaning as “shall.”
      </Text>
      <Text mt={2}>
        <strong>29.3 No Waiver.</strong> Teach Me To&apos;s failure to enforce
        any right or provision in these Terms will not constitute a waiver of
        such right or provision unless acknowledged and agreed to by us in
        writing. Except as expressly set forth in these Terms, the exercise by
        either party of any of its remedies under these Terms will be without
        prejudice to its other remedies under these Terms or otherwise permitted
        under law.
      </Text>
      <Text mt={2}>
        <strong>29.4 Assignment.</strong> You may not assign, transfer or
        delegate these Terms or your rights and obligations hereunder without
        Teach Me To&apos;s prior written consent. Teach Me To may, without
        restriction, assign, transfer, or delegate this agreement and any rights
        and obligations hereunder, at its sole discretion, with 30 days&apos;
        prior notice.
      </Text>
      <Text mt={2}>
        <strong>29.5 Notice.</strong> Unless specified otherwise, any notices or
        other communications to Members permitted or required under this
        agreement, will be provided electronically and given by Teach Me To via
        email, Teach Me To Platform notification, messaging service (including
        SMS and WeChat), or any other contact method we enable and you provide.
      </Text>
      <Text mt={2}>
        <strong>29.6 Third-Party Services.</strong> The Teach Me To Platform may
        contain links to third-party websites, applications, services or
        resources (“Third-Party Services”) that are subject to different terms
        and privacy practices. Such Third-Party Services are not under our
        control, and we are not responsible for the availability of such
        Third-Party Services or their contents. Any links provided on the Teach
        Me To Platform are provided for your interest or convenience only and do
        not represent or imply any endorsement by Teach Me To of any such linked
        Third-Party Services. You acknowledge that the use of such Third-Party
        Services is governed by the terms and conditions applicable to any such
        Third-Party Service, and if you decide to access any such Third-Party
        Service, you do so entirely at your own risk and subject to the terms
        and conditions of use for each such Third-Party Service.
      </Text>
      <Text mt={2}>
        <strong>29.7 Google Terms.</strong> Some translations on the Teach Me To
        Platform are powered by Google. Google disclaims all warranties related
        to the translations, express or implied, including any warranties of
        accuracy, reliability, and any implied warranties for merchantability,
        fitness for a particular purpose and non-infringement. Some areas of the
        Teach Me To Platform implement Google Maps/Earth mapping services,
        including Google Maps API(s). Your use of Google Maps/Earth is subject
        to the Google Maps/Google Earth Additional Terms of Service.
      </Text>
      <Text mt={2}>
        <strong>29.8 Force Majeure.</strong> Teach Me To shall not be liable for
        any delay or failure to perform resulting from causes outside its
        reasonable control, including, but not limited to, acts of God, natural
        disasters, war, terrorism, riots, embargoes, acts of civil or military
        authorities, fire, floods, accidents, pandemics, epidemics or disease,
        strikes or shortages of transportation facilities, fuel, energy, labor
        or materials.
      </Text>
      <Text mt={2}>
        <strong>29.9 Contact Us.</strong> If you have any questions about these
        Terms, please email us at support@teachme.to or call us at 650-900-3835.
      </Text>
    </Stack>
  );
}
