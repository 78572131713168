import posthog from 'posthog-js';

import { VITE_POSTHOG_API_KEY } from '../utils/constants';

const init = () => {
  // Check if the user-agent contains 'prerender'
  if (navigator.userAgent.includes('prerender')) return;

  posthog.init(VITE_POSTHOG_API_KEY, {
    api_host: 'https://app.posthog.com',
    autocapture: {
      dom_event_allowlist: ['change', 'click', 'submit'],
      element_allowlist: [
        'a',
        'button',
        'form',
        'input',
        'label',
        'select',
        'textarea',
      ],
    },
    persistence: 'localStorage+cookie',
  });
};

export default {
  init,
};
