import { Box, Divider, Flex, HStack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import NextScheduleAsYouGoButton from './components/NextScheduleAsYouGoButton';

import { ListingInBoundingBox, PricePayload } from '../../../api';
import useBookingWidgetHeight from '../../../hooks/useBookingWidgetHeight';
import usePickleheadsDiscount from '../../../hooks/usePickleheadsDiscount';
import usePricing from '../../../hooks/usePricing';
import useStudentReferral from '../../../hooks/useStudentReferral';
import { useAppSelector } from '../../../state/hooks';
import {
  selectParticipants,
} from '../../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../../state/slices/listingsSlice';
import { selectSelectedLocation } from '../../../state/slices/locationsSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import BadgeTag from '../../BadgeTag';
import LessonLengthPicker from '../../LessonLengthPicker';
import PackagePrice from '../../PackagePrice';
import ParticipantPicker from '../../ParticipantPicker';
import RecurringPrice from '../../RecurringPrice';
import ReferralRewardPriceTag from '../../ReferralRewardPriceTag';
import TMTAvatar from '../../TMTAvatar';
import BookingWidgetBody from '../BookingWidgetBody';
import BookingWidgetFooter from '../BookingWidgetFooter';
import BookingWidgetHeader from '../BookingWidgetHeader';

interface PackagesProps {
  listingUrl: string;
  listing?: Pick<ListingInBoundingBox, 'exclusivePack' | 'id'>;
}

export default function Packages({ listingUrl, listing }: PackagesProps) {
  const [prices, setPrices] = useState<PricePayload[] | null>(null);
  const [recurringPrice, setRecurringPrice] = useState<PricePayload | null>(
    null,
  );
  const participants = useAppSelector(selectParticipants);
  const { hasFreeLesson } = useStudentReferral();
  const { isBookingWidgetInnerContentScrollable } = useBookingWidgetHeight();
  const selectedListing = useAppSelector(selectSelectedListing);
  const selectedLocation = useAppSelector(selectSelectedLocation);
  const {
    getPrices,
    isLoading: isLoadingPrices,
    getSubscriptionPrice,
  } = usePricing();
  const { isPickleheadsReferral, pickleheadsDiscountAmount } =
    usePickleheadsDiscount();

  const heading = 'Pick your lesson pack';

  useEffect(() => {
    if (!listing?.id) return;
    getPrices({ listingId: listing?.id }).then(setPrices);
    getSubscriptionPrice({ listingId: listing?.id }).then(setRecurringPrice);
  }, [listing?.id, getPrices, getSubscriptionPrice]);

  const showPickleheadDiscount = isPickleheadsReferral;

  useEffect(() => {
    capturePostHogEvent('viewPackages');
  }, []);

  const exclusivePackPricePayload: PricePayload =
    listing?.exclusivePack &&
    prices?.find((node) => node?.package === listing.exclusivePack);

  return (
    <>
      <BookingWidgetHeader heading={heading} />
      <HStack px="6" pb="4">
        <TMTAvatar
          mr="1"
          size="xs"
          name={selectedListing?.proName}
          src={selectedListing?.proAvatar}
        />
        <Text
          color="primary.500"
          fontWeight="semibold"
          fontSize="small"
          noOfLines={1}
        >
          {selectedListing?.skill?.label} with {selectedListing?.proName} @
          <Text as="span" color="primary.200" fontWeight="normal">
            {selectedLocation?.placeName}
          </Text>
        </Text>
      </HStack>

      <Divider />
      <BookingWidgetBody paddingX={{ base: '0', lg: '4' }}>
        <Flex
          alignItems="center"
          flexDir="column"
          flexGrow={1}
          justifyContent="flex-end"
          mt="2"
          pb="2"
        >
          <HStack py="2" alignItems="space-between" width="full">
            <LessonLengthPicker />
            <ParticipantPicker />
          </HStack>

          <Box w="full">
            <Box borderRadius="2xl" borderWidth={0} w="full">
              {exclusivePackPricePayload ? (
                <PackagePrice
                  packageSize={listing.exclusivePack}
                  participants={participants}
                  price={exclusivePackPricePayload}
                  isLoading={isLoadingPrices}
                  isExclusivePack
                />
              ) : (
                <>
                  <PackagePrice
                    packageSize={1}
                    participants={participants}
                    price={prices?.find((node) => node?.package === 1)}
                    isLoading={isLoadingPrices}
                    isTop
                  />
                  <PackagePrice
                    packageSize={3}
                    participants={participants}
                    price={prices?.find((node) => node?.package === 3)}
                    isLoading={isLoadingPrices}
                    isMiddle
                  />
                  <PackagePrice
                    packageSize={10}
                    participants={participants}
                    price={prices?.find((node) => node?.package === 10)}
                    isLoading={isLoadingPrices}
                    isMiddle
                  />
                  <RecurringPrice
                    participants={participants}
                    price={recurringPrice}
                    isLoading={isLoadingPrices}
                    isBottom
                  />
                </>
              )}
            </Box>

            {exclusivePackPricePayload ? (
              <Text mt="4" textAlign="center" textStyle="caption">
                This is the only option this coach offers.
              </Text>
            ) : null}
          </Box>
          <Box my={isBookingWidgetInnerContentScrollable ? '2' : '6'} />
        </Flex>
      </BookingWidgetBody>
      <BookingWidgetFooter>
        <Box w="full">
          {hasFreeLesson && (
            <HStack justifyContent="center" pb="2">
              <ReferralRewardPriceTag />
            </HStack>
          )}
          {showPickleheadDiscount && (
            <HStack pb="4">
              <BadgeTag
                justifyContent="center"
                labelTextTransform="none"
                bgColor="#E9D8FD"
                borderRadius="lg"
                label={`$${pickleheadsDiscountAmount} Pickleheads discount applied at checkout!`}
                labelColor="#553C9A"
                fontWeight="extrabold"
              />
            </HStack>
          )}
          <NextScheduleAsYouGoButton listingUrl={listingUrl} />
        </Box>
      </BookingWidgetFooter>
    </>
  );
}
