import { Button, Center, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ScheduleLaterSheet from './ScheduleLaterSheet';
import TrialLessonSheet from './TrialLessonSheet';

import { UniqueListingQuery } from '../../../api';
import useIsMobile from '../../../hooks/useIsMobile';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import { useAppDispatch } from '../../../state/hooks';
import {
  setDateTime,
  setIsRecurring,
  setPkg,
} from '../../../state/slices/filtersSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import { BookingStep, SelectedLocation } from '../../../utils/types';
import BookingWidgetBody from '../BookingWidgetBody';
import BookingWidgetHeader from '../BookingWidgetHeader';

interface Props {
  listing: Pick<
    UniqueListingQuery['findUniqueListing'],
    'id' | 'proName' | 'slug'
  > & {
    locations?: SelectedLocation[];
    skill?: Pick<
      UniqueListingQuery['findUniqueListing']['skill'],
      'category' | 'slug'
    >;
  };
  listingUrl: string;
  isRebooking?: boolean;
}

export default function SchedulePreference({
  listing,
  listingUrl,
  isRebooking,
}: Props) {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const {
    isOpen: isOpenTrialSheet,
    onOpen: onOpenTrialSheet,
    onClose: onCloseTrialSheet,
  } = useDisclosure();
  const {
    isOpen: isOpenScheduleLaterSheet,
    onOpen: onOpenScheduleLaterSheet,
    onClose: onCloseScheduleLaterSheet,
  } = useDisclosure();

  useEffect(() => {
    capturePostHogEvent('viewBookingWidgetSchedulePreference', {
      listingSlug: listing?.slug,
    });
  }, [listing?.slug]);

  const captureEvent = (event: string) => {
    capturePostHogEvent(event, {
      action: 'BookingWidgetSchedulePreference',
      listingSlug: listing?.slug,
    });
  };

  const onRecurring = () => {
    captureEvent('clickRecurrent');
    dispatch(setIsRecurring(true));
    dispatch(setPkg(1));

    const listingSearchParams = getListingSearchParamsWithPartialInput({
      isRecurring: true,
      pkg: 1,
    });

    const nextUrl = isRebooking
      ? `${listingUrl}/${BookingStep.Confirm}?${listingSearchParams}`
      : `${listingUrl}/${BookingStep.Exposed}?${listingSearchParams}`;

    navigate(nextUrl);
  };

  const onSchedule = () => {
    captureEvent('clickScheduleNow');
    dispatch(setIsRecurring(false));

    const listingSearchParams = getListingSearchParamsWithPartialInput({
      isRecurring: false,
    });

    const nextUrl = isRebooking
      ? `${listingUrl}/${BookingStep.Confirm}?${listingSearchParams}`
      : `${listingUrl}/${BookingStep.Exposed}?${listingSearchParams}`;

    navigate(nextUrl);
  };

  const onScheduleLater = () => {
    dispatch(setDateTime(''));
    dispatch(setIsRecurring(false));
    captureEvent('clickScheduleLater');
    onOpenScheduleLaterSheet();
  };

  const onCloseScheduleLater = () => {
    onCloseScheduleLaterSheet();
    setTimeout(() => {
      const listingSearchParams = getListingSearchParamsWithPartialInput({
        dateTime: '',
        isRecurring: false,
      });

      const nextUrlWithSearchParams = isRebooking
        ? `${listingUrl}/${BookingStep.Confirm}?${listingSearchParams}`
        : `${listingUrl}/${BookingStep.Packages}?${listingSearchParams}`;

      navigate(nextUrlWithSearchParams);
    }, 200);
  };

  const onClickTrialLesson = () => {
    captureEvent('clickTrialLesson');
    onOpenTrialSheet();
  };

  const isMusicSkill = listing?.skill?.category === 'music';

  return (
    <>
      <BookingWidgetHeader heading="For scheduling, do you prefer to..." />

      <BookingWidgetBody flexGrow={2} isFullWidth={isMobile} pb="4">
        <Flex
          alignItems="center"
          flexDir="column"
          flexGrow={1}
          justifyContent="flex-end"
          pb="4"
          px="4"
        >
          <Button
            variant="outline"
            borderColor="border-default"
            backgroundColor="purple.100"
            _hover={{
              borderColor: 'purple.500',
              bg: 'purple.100',
            }}
            color="purple.600"
            size="xl"
            w="full"
            rounded="full"
            mt="0"
            mb="2"
            key="recurring"
            onClick={onRecurring}
          >
            Take weekly lessons
          </Button>
          <Button
            variant="outline"
            borderColor="border-default"
            _hover={{
              borderColor: 'gray.700',
              bg: 'gray.50',
            }}
            size="xl"
            w="full"
            rounded="full"
            mt="0"
            mb="2"
            key="schedule"
            onClick={onSchedule}
          >
            {isMusicSkill
              ? 'Schedule just one lesson now'
              : 'Schedule my first lesson now'}
          </Button>
          <Button
            variant="outline"
            borderColor="border-default"
            _hover={{
              borderColor: 'gray.700',
              bg: 'gray.50',
            }}
            size="xl"
            w="full"
            rounded="full"
            mt="0"
            mb="2"
            key="later"
            onClick={onScheduleLater}
          >
            Skip and schedule later
          </Button>
          <Center w="full" mt="6">
            <Text
              cursor="pointer"
              _hover={{ textDecorationLine: 'none' }}
              onClick={onClickTrialLesson}
              pt="2"
              textStyle="link"
            >
              Want a trial lesson?
            </Text>
          </Center>
        </Flex>
      </BookingWidgetBody>
      <TrialLessonSheet isOpen={isOpenTrialSheet} onClose={onCloseTrialSheet} />
      <ScheduleLaterSheet
        isOpen={isOpenScheduleLaterSheet}
        onClose={onCloseScheduleLater}
      />
    </>
  );
}
