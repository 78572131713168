import { Box, Skeleton, VStack } from '@chakra-ui/react';
import { useLocalStorageValue } from '@react-hookz/web';
import { useEffect, useState } from 'react';

import { QuestionProps } from './interface';

import { FiltersState } from '../../../state/slices/filtersSlice';
import QuizButton from '../QuizButton';

const localAnswers = [
  {
    id: '1',
    name: 'Yes, I have my own',
  },
  { id: '2', name: 'No, I need it for the lesson' },
];

export default function QuizEquipment({ onSelectAnswer }: QuestionProps) {
  const [, setGear] = useLocalStorageValue<string>('gear', 'no');
  const [localAnswer, setLocalSelectedAnswer] = useState<
    Partial<FiltersState['selectedBadges'][0]>
  >(localAnswers[0]);

  const onToggleLocalBadge = (
    badge: Partial<FiltersState['selectedBadges'][0]>,
  ) => {
    if (badge.id === '1') {
      setGear('no');
    } else {
      setGear('yes');
    }
    setLocalSelectedAnswer(badge);
  };

  useEffect(() => {
    if (localAnswer?.name) {
      const options = localAnswers.map((opt) => opt.name).join(';');
      onSelectAnswer(localAnswer.name, options);
    }
  }, [localAnswer?.name, onSelectAnswer]);

  const loadingFilterBadgeGroups = false;

  return (
    <Skeleton
      isLoaded={!loadingFilterBadgeGroups}
      h={loadingFilterBadgeGroups ? '200px' : undefined}
      w="full"
    >
      <Box w="full">
        <VStack alignItems="flex-start" spacing="2" w="full">
          {localAnswers.map((badge) => (
            <QuizButton
              badge={badge as FiltersState['localSelectedBadges'][0]}
              key={badge.id}
              isSelected={localAnswer.id === badge.id}
              onToggleBadge={onToggleLocalBadge}
              w="full"
            />
          ))}
        </VStack>
      </Box>
    </Skeleton>
  );
}
