import { Flex, Stack } from '@chakra-ui/react';

import { BookingWidgetBodyProps } from './interfaces';

export default function BookingWidgetBody({
  children,
  isFullWidth,
  ...rest
}: BookingWidgetBodyProps) {
  return (
    <Flex
      align="center"
      bg="bg-surface"
      direction="column"
      flex="1"
      justify="flex-end"
      overflowY="scroll"
      sx={{
        '::-webkit-scrollbar': { display: 'none' },
        scrollbarWidth: 'none',
      }}
      w="full"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <Stack
        h="full"
        w={isFullWidth ? 'full' : { base: '88dvw', lg: '96%' }}
        justify={{ base: 'top' }}
        overflowY="scroll"
        overflowX="hidden"
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
        }}
      >
        {children}
      </Stack>
    </Flex>
  );
}
