import { Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AtHomeLocationAutoComplete from './AtHomeLocationAutoComplete';

import useListingSearchParams from '../../../hooks/useListingSearchParams';
import { useAppSelector } from '../../../state/hooks';
import { selectAtHomeLocation } from '../../../state/slices/filtersSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import getLocationTypeBySkillSlug from '../../../utils/getLocationTypeBySkillSlug';
import PosthogEvent from '../../../utils/posthogEvents';
import { BookingStep, Listing } from '../../../utils/types';
import BookingWidgetBody from '../BookingWidgetBody';
import BookingWidgetFooter from '../BookingWidgetFooter';
import BookingWidgetHeader from '../BookingWidgetHeader';

interface Props {
  listing: Pick<Listing, 'id' | 'proAvatar' | 'proName' | 'slug'> & {
    skill?: Pick<Listing['skill'], 'id' | 'label' | 'slug'>;
  };
}

export default function Address({ listing }: Props) {
  const navigate = useNavigate();
  const atHomeLocation = useAppSelector(selectAtHomeLocation);
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const locationType = getLocationTypeBySkillSlug(listing?.skill?.slug, false);

  function goToNextStep(): void {
    if (!listing?.skill?.slug) return;

    const listingUrl = `/listings/${listing.skill.slug}/${listing.slug}`;
    const confirmUrl = `${listingUrl}/${
      BookingStep.Confirm
    }?${getListingSearchParamsWithPartialInput()}`;

    navigate(confirmUrl);
  }

  useEffect(() => {
    capturePostHogEvent(PosthogEvent.ViewAddress);
  }, []);

  const onClickNext = () => {
    capturePostHogEvent('clickNextAtHomeLocation', {
      atHomeLocation,
      listingSlug: listing?.slug,
    });

    goToNextStep();
  };

  return (
    <>
      <BookingWidgetHeader heading="What is the address of the court?" hideBackButton={false} />

      <BookingWidgetBody isFullWidth pt="2">
        <AtHomeLocationAutoComplete
          isLoading={isLoading}
          locationType={locationType}
          setIsLoading={setIsLoading}
        />
      </BookingWidgetBody>

      <BookingWidgetFooter>
        <Button
          isDisabled={!atHomeLocation?.address}
          variant="primary"
          w="full"
          size="xl"
          py="4"
          rounded="full"
          isLoading={isLoading}
          onClick={onClickNext}
        >
          Next
        </Button>
      </BookingWidgetFooter>
    </>
  );
}
