import { useUniqueListingLazyQuery } from "../../../api";

export default function useGetListingBySlug(slug: string) {
    const [findUniqueListing] = useUniqueListingLazyQuery({
        initialFetchPolicy: 'cache-and-network',
        fetchPolicy: 'cache-and-network',
        variables: {
            where: {
                slug,
            },
        },
    });


    return {
        findUniqueListing
    }
}
