import { useLocalStorageValue } from '@react-hookz/web';

import useSentryWithContext from './useSentryWithContext';

import { VITE_POSTHOG_API_KEY } from '../utils/constants';
import fetchWithTimeout from '../utils/fetchWithTimeout';

export default function usePosthogCustomEvents() {
  const { captureException } = useSentryWithContext();
  const [posthogUserId] = useLocalStorageValue<string>('posthogUserId');

  const createAlias = async (aliasId: string) => {
    try {
      const response = await fetchWithTimeout(
        'https://app.posthog.com/capture/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            api_key: VITE_POSTHOG_API_KEY,
            event: '$create_alias',
            distinct_id: posthogUserId,
            properties: {
              alias: aliasId,
              distinct_id: posthogUserId,
              $current_url: window.location.href,
            },
          }),
        },
        2000,
      );
      await response.json();
    } catch (error) {
      captureException(error, {
        context: {
          name: 'UsePosthogCustomEvents',
          data: {
            method: 'createAlias',
            aliasId,
            href: window.location.href
          }
        }
      });
    }
  };

  const identify = async (name: string, email: string, tmtUserId: string) => {
    try {
      const response = await fetchWithTimeout(
        'https://app.posthog.com/capture/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            api_key: VITE_POSTHOG_API_KEY,
            distinct_id: posthogUserId,
            event: '$identify',
            properties: {
              $set: {
                email,
                name,
                tmtUserId,
              },
              $current_url: window.location.href,
            },
          }),
        },
        2000,
      );

      await response.json();
    } catch (error) {
      captureException(error, {
        context: {
          name: 'UsePosthogCustomEvents',
          data: {
            method: 'identify',
            href: window.location.href,
            email,
            name,
            tmtUserId,
          }
        }
      });
    }
  };

  return {
    createAlias,
    identify,
  };
}
