import { Box, HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ChangeYourLocationLink from './components/ChangeYourLocationLink';

import { LocationAffiliateName } from '../../api';
import useAtHomeCurrentLocation from '../../hooks/useAtHomeCurrentLocation';
import useDistanceAwayFromCurrentLocation from '../../hooks/useDistanceAwayFromCurrentLocation';
import useListingSearchParams from '../../hooks/useListingSearchParams';
import useSelectedLocation from '../../hooks/useSelectedLocation';
import useSelectedSkill from '../../hooks/useSelectedSkill';
import useSelectLessonAtHome from '../../hooks/useSelectLessonAtHome';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  setAtHomeLocation,
  setIsAtHome,
} from '../../state/slices/filtersSlice';
import { selectIncludedAffiliateName } from '../../state/slices/locationsSlice';
import getLocationTypeBySkillSlug from '../../utils/getLocationTypeBySkillSlug';
import { SelectedListing, SelectedLocation } from '../../utils/types';
import getFirstName from '../../utils/user';
import AffiliateTags from '../AffiliateTags/AffiliateTags';
import ChooseLessonLocationModal from '../BookingWidget/Location/components/ChooseLessonLocationModal';
import SelectedLocationRadio from '../BookingWidget/Location/components/SelectedLocationRadio';
import EnvironmentSettingTags from '../EnvironmentSettingTags';
import LocationFeeTag from '../LocationFeeTag';

type AtHomeProps = {
  canBeDisabled?: boolean;
  listing: SelectedListing;
  shouldScrollIntoViewWhenSelected?: boolean;
};

function AtHomeSlideItem({
  canBeDisabled = true,
  listing,
  shouldScrollIntoViewWhenSelected,
}: AtHomeProps): JSX.Element {
  const [, setSearchParams] = useSearchParams();
  const includedAffiliateName = useAppSelector(selectIncludedAffiliateName);
  const { isOpen, onClose } = useDisclosure();
  const atHomeCurrentLocation = useAtHomeCurrentLocation();
  const navigate = useNavigate();
  const [selectedSkill] = useSelectedSkill();
  const { selectLessonAtHome } = useSelectLessonAtHome();
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const onGoToChooseYourLocation = () => {
    navigate(
      `/choose-your-location/${listing?.slug}?pathname=${window.location.pathname}`,
    );
  };

  const locationType = getLocationTypeBySkillSlug(selectedSkill?.slug, false);
  const isDisabled = Boolean(canBeDisabled && !listing?.isInServiceArea);
  const isSelected: boolean = atHomeCurrentLocation && !isDisabled;

  const handleSelectLocation = () => {
    selectLessonAtHome(true);
    const listingSearchParams = getListingSearchParamsWithPartialInput({
      isAtHome: true,
    });
    setSearchParams(new URLSearchParams(listingSearchParams));
  };

  // When selected, scroll into view
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    if (isSelected && shouldScrollIntoViewWhenSelected) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isSelected, shouldScrollIntoViewWhenSelected]);

  return (
    <Box
      // eslint-disable-next-line no-nested-ternary
      bg={isSelected ? '#EBF8FF' : isDisabled ? '#EDF2F7' : 'transparent'}
      borderColor={isSelected ? '#3182CE' : '#CBD5E0'}
      borderRadius="16px"
      borderWidth={isSelected ? 2 : 1}
      cursor={isDisabled ? undefined : 'pointer'}
      display="flex"
      flexDir="column"
      flexShrink={0}
      h={includedAffiliateName ? '183px' : '147px'}
      justifyContent="space-between"
      onClick={isDisabled ? null : handleSelectLocation}
      p="6"
      ref={ref}
      w="full"
    >
      <HStack
        alignItems="flex-start"
        flexGrow={1}
        justifyContent="space-between"
        spacing={2}
      >
        <VStack
          alignItems="flex-start"
          justifyContent="space-between"
          h="full"
          spacing={0}
        >
          <VStack
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={0}
          >
            <Text
              color={isDisabled && !isSelected ? '#718096' : undefined}
              noOfLines={1}
              textStyle="smallBold"
            >
              At your favorite {locationType}
            </Text>

            <Text noOfLines={isDisabled ? 2 : 3} textStyle="small">
              {isDisabled
                ? `You are outside of ${getFirstName(
                    listing?.proName,
                  )}'s travel radius.`
                : 'Anywhere in the blue service area shown while selected.'}
            </Text>
          </VStack>

          {isDisabled && <ChangeYourLocationLink listing={listing} />}
        </VStack>

        <SelectedLocationRadio
          isDisabled={isDisabled}
          isSelected={isSelected}
        />
      </HStack>

      <ChooseLessonLocationModal
        isOpen={isOpen}
        listing={listing}
        onClose={onClose}
        onGoToChooseYourLocation={onGoToChooseYourLocation}
      />
    </Box>
  );
}

type NonAtHomeProps = {
  location: SelectedLocation;
  onSetLocation?: (location: SelectedLocation) => void;
  shouldScrollIntoViewWhenSelected?: boolean;
};

function NonAtHomeSlideItem({
  location,
  onSetLocation,
  shouldScrollIntoViewWhenSelected = false,
}: NonAtHomeProps): JSX.Element {
  const includedAffiliateName = useAppSelector(selectIncludedAffiliateName);
  const [selectedLocation, setSelectedLocation] = useSelectedLocation();
  const dispatch = useAppDispatch();
  const atHomeCurrentLocation = useAtHomeCurrentLocation();
  const { selectLessonAtHome } = useSelectLessonAtHome();
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();
  const [, setSearchParams] = useSearchParams();

  const distanceAway = useDistanceAwayFromCurrentLocation([
    [location.latitude || 0, location.longitude || 0],
  ]);

  const updateUrlParams = (newLocation: SelectedLocation) => {
    const listingSearchParams = getListingSearchParamsWithPartialInput({
      isAtHome: !newLocation,
      selectedLocation: newLocation || ({} as SelectedLocation),
    });
    setSearchParams(new URLSearchParams(listingSearchParams));
  };

  const onSetLocationOrAtHome = (): void => {
    if (location) {
      setSelectedLocation(location);
      dispatch(setIsAtHome(false));
      dispatch(setAtHomeLocation(null));
      onSetLocation?.(location);
    } else {
      selectLessonAtHome();
    }

    updateUrlParams(location);
  };

  const isSelected =
    !atHomeCurrentLocation && selectedLocation?.id === location.id;

  // When selected, scroll into view
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    if (isSelected && shouldScrollIntoViewWhenSelected) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isSelected, shouldScrollIntoViewWhenSelected]);

  const isTopGolfLocation =
    (location?.affiliateName || location?.LocationAffiliate?.name) ===
    LocationAffiliateName.TopGolf;

  return (
    <Box
      bg={isSelected ? '#EBF8FF' : 'transparent'}
      borderColor={isSelected ? '#3182CE' : '#CBD5E0'}
      borderRadius="16px"
      borderWidth={isSelected ? 2 : 1}
      cursor="pointer"
      display="flex"
      flexDir="column"
      flexShrink={0}
      h={includedAffiliateName ? '183px' : '147px'}
      justifyContent="space-between"
      onClick={onSetLocationOrAtHome}
      p="6"
      ref={ref}
      w="full"
    >
      <HStack
        alignItems="flex-start"
        flexGrow={1}
        justifyContent="space-between"
        spacing={2}
      >
        <VStack alignItems="flex-start" spacing={0}>
          <Text noOfLines={2} textStyle="smallBold">
            {location.placeName}
          </Text>

          <Text mb="3" noOfLines={1} textStyle="small">
            {distanceAway} {distanceAway === '1' ? 'mile' : 'miles'} away from
            you
          </Text>
        </VStack>

        <SelectedLocationRadio isSelected={isSelected} />
      </HStack>

      <AffiliateTags location={location} pt="4" />

      {!isTopGolfLocation && (
        <HStack
          flexShrink={0}
          mt="2"
          overflow="scroll"
          pr="2"
          spacing="1"
          mb="1"
          sx={{
            '::-webkit-scrollbar': { display: 'none' },
            scrollbarWidth: 'none',
          }}
          w="full"
          zIndex="1"
        >
          <EnvironmentSettingTags
            environmentSettings={location.environmentSettings}
            isSelected={isSelected}
          />

          <LocationFeeTag
            isAbbreviated
            isSelected={isSelected}
            location={location}
          />
        </HStack>
      )}
    </Box>
  );
}

export default {
  AtHomeSlideItem,
  NonAtHomeSlideItem,
};
