import {
  Button,
  Heading,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { HiStar } from 'react-icons/hi';
import { useSelector } from 'react-redux';

import { ReviewStatus, useAggregateReviewsQuery } from '../../../api';
import { selectSelectedListing } from '../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import PosthogEvent from '../../../utils/posthogEvents';
import ListingReviewsModal from '../../ListingReviewsModal';
import NoRating from '../../NoRating';
import { Show } from '../../Show';
import TMTAvatar from '../../TMTAvatar';

export default function CoachInfo() {
  const selectedListing = useSelector(selectSelectedListing);
  const reviewModal = useDisclosure();

  const { data: aggregateReviewsQuery } = useAggregateReviewsQuery({
    variables: {
      where: {
        listingId: {
          equals: selectedListing?.id,
        },
        status: {
          equals: ReviewStatus.Completed,
        },
      },
    },
  });

  const [rating, count] = useMemo(
    () => [
      aggregateReviewsQuery?.aggregateReview?._avg?.rating || 0,
      aggregateReviewsQuery?.aggregateReview?._count?.id || 0,
    ],
    [aggregateReviewsQuery?.aggregateReview],
  );

  const handleReviewClick = useCallback(() => {
    capturePostHogEvent(PosthogEvent.ViewReviews, { action: 'reviews' });
    reviewModal.onOpen();
  }, [reviewModal]);

  return (
    <HStack px="6" w="max">
      <TMTAvatar
        mr="2"
        h={56}
        w={56}
        name={selectedListing?.proName}
        src={selectedListing?.proAvatar}
      />

      <VStack alignItems="flex-start">
        <Heading variant="h5">
          {selectedListing?.skill?.label} with {selectedListing?.proName}
        </Heading>
        <HStack spacing="0">
          <Show condition={rating === 0}>
            <NoRating />
          </Show>
          <Show condition={rating > 0}>
            <HStack spacing="0">
              <Icon as={HiStar} />
              <Text textStyle="bold" fontSize="14px" pl="1">
                {rating.toFixed(rating === 5 ? 1 : 2)}
              </Text>
              <Button
                ml="1"
                variant="link"
                fontSize="14px"
                fontWeight="normal"
                colorScheme="primary"
                textDecoration="underline"
                onClick={handleReviewClick}
              >
                {count} reviews
              </Button>
            </HStack>
          </Show>
        </HStack>
      </VStack>
      <ListingReviewsModal
        listingId={selectedListing?.id}
        isOpen={reviewModal.isOpen}
        onClose={reviewModal.onClose}
      />
    </HStack>
  );
}
