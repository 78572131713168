import { Box, Divider, Flex, HStack, Skeleton, Text } from '@chakra-ui/react';
import { motion, useTransform } from 'framer-motion';
import { HiChevronDown } from 'react-icons/hi';

import { useMainScroll } from '../../context/MainScrollContext';
import useHeaderHeight from '../../hooks/useHeaderHeight';
import usePageLayout from '../../hooks/usePageLayout';
import LogoIcon from '../LogoIcon';

export default function MobileSearchHeaderSkeleton() {
  const { gridWidth } = usePageLayout();
  const { headerHeight } = useHeaderHeight();

  const {
    scroll: { scrollY },
  } = useMainScroll();
  const opacityLogo = useTransform(scrollY, [0, headerHeight / 12], [1, 0]);
  const opacityFilters = useTransform(scrollY, [0, headerHeight / 1.5], [1, 0]);
  const topFilters = useTransform(scrollY, [0, headerHeight / 2], [0, -50]);

  const opacitySkill = useTransform(scrollY, [0, headerHeight / 12], [1, 0]);
  const heightLearn = useTransform(scrollY, [0, headerHeight / 6], [20, 0]);

  return (
    <Flex
      alignItems="center"
      flexDir="column"
      justifyContent="flex-start"
      h="full"
      w="full"
    >
      <Flex
        alignItems="center"
        flexDir="column"
        h="full"
        justifyContent="flex-start"
        w={gridWidth}
        zIndex="1"
      >
        <Flex
          alignItems="center"
          flexDir="row"
          justifyContent="space-between"
          w="full"
        >
          <motion.div style={{ opacity: opacityLogo }}>
            <LogoIcon size="lg" isLight={false} />
          </motion.div>
          <HStack>
            <Skeleton h={8} rounded="full">
              Ask a question
            </Skeleton>
            <Skeleton h={8} rounded="full">
              Match X
            </Skeleton>
          </HStack>
        </Flex>

        <Flex align="center" justifyContent="flex-start" mt="4" w="full">
          <Flex align="center" justifyContent="center">
            <Flex align="center" justifyContent="center">
              <Box
                style={{
                  alignItems: 'flex-start',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  position: 'relative',
                }}
              >
                <Flex
                  alignItems="flex-start"
                  cursor="pointer"
                  flexDir="column"
                  justifyContent="center"
                  w="full"
                >
                  <Text color="#718096" textStyle="overlineBold" noOfLines={1}>
                    LEARN
                  </Text>

                  <Flex
                    alignItems="center"
                    flexDir="row"
                    justifyContent="flex-start"
                  >
                    <Skeleton fontSize="24px" fontWeight="900">
                      Pickleball
                    </Skeleton>
                    <HiChevronDown fontSize="20px" />
                  </Flex>
                </Flex>
              </Box>
              <Flex
                alignItems="flex-start"
                cursor="pointer"
                flexDir="column"
                justifyContent="center"
                ml="6"
              >
                <motion.div
                  style={{ opacity: opacitySkill, height: heightLearn }}
                >
                  <Text color="#718096" textStyle="overlineBold">
                    NEAR
                  </Text>
                </motion.div>

                <Flex alignItems="center" flexDir="row" justifyContent="center">
                  <Skeleton fontSize="24px" fontWeight="900">
                    12345
                  </Skeleton>

                  <motion.div
                    style={{ opacity: opacitySkill, height: heightLearn }}
                  >
                    <HiChevronDown fontSize="20px" />
                  </motion.div>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <motion.div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          opacity: opacityFilters,
          position: 'relative',
          top: topFilters,
          width: '100%',
        }}
      >
        <Divider borderColor="border-default" orientation="horizontal" />
        <Skeleton noOfLines={1} my="4" w={gridWidth}>
          Sort | Filter
        </Skeleton>
      </motion.div>
    </Flex>
  );
}
