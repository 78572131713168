/* eslint-disable no-nested-ternary */
import { useCallback } from 'react';

import useExtractedListingParams from './useExtractedListingParams';
import useLessonLength from './useLessonLength';
import useOrigin from './useOrigin';
import useSearchBoundingBox from './useSearchBoundingBox';
import useSelectedAtHomeLocation from './useSelectedAtHomeLocation';
import useSelectedLocation from './useSelectedLocation';
import useSelectedSkill from './useSelectedSkill';

import { useAppSelector } from '../state/hooks';
import {
  initialState,
  selectDateTime,
  selectIsAtHome,
  selectIsRecurring,
  selectParticipants,
  selectPkg,
} from '../state/slices/filtersSlice';
import { selectSelectedListing } from '../state/slices/listingsSlice';
import getListingSearchParams, {
  ListingSearchParamsInput,
} from '../utils/getListingSearchParams';

type Output = {
  getListingSearchParamsWithPartialInput(
    partialInput?: Partial<ListingSearchParamsInput>,
  ): string;
};

export default function useListingSearchParams(): Output {
  const [selectedLocation] = useSelectedLocation();
  const participants = useAppSelector(selectParticipants);
  const { lessonLength } = useLessonLength();
  const isAtHome = useAppSelector(selectIsAtHome);
  const isRecurring = useAppSelector(selectIsRecurring);
  const dateTime = useAppSelector(selectDateTime);
  const pkg = useAppSelector(selectPkg);
  const { origin } = useOrigin();
  const { selectedAtHomeLocation } = useSelectedAtHomeLocation();
  const selectedListing = useAppSelector(selectSelectedListing);
  const [selectedSkill] = useSelectedSkill();
  const { searchBoundingBox } = useSearchBoundingBox();

  const { extractedListingParams } = useExtractedListingParams();

  const getListingSearchParamsWithPartialInput: Output['getListingSearchParamsWithPartialInput'] =
    useCallback(
      (partialInput) => {
        const selectedLocationId =
          extractedListingParams.selected_location?.locationId ||
          selectedLocation?.id;

        // Use values from extracted params or from redux state
        return getListingSearchParams({
          selectedLocation:
            partialInput?.selectedLocation ||
            (selectedLocationId ? { id: selectedLocationId } : undefined),
          participants:
            partialInput?.participants ||
            extractedListingParams.participants ||
            (participants !== initialState.participants
              ? participants
              : undefined),
          lessonLength:
            partialInput?.lessonLength ||
            extractedListingParams.lesson_length ||
            (lessonLength !==
            initialState.selectedLessonLength[selectedSkill?.category]
              ? lessonLength
              : undefined),
          isAtHome:
            typeof partialInput?.isAtHome === 'boolean'
              ? partialInput.isAtHome
              : typeof extractedListingParams.is_at_home === 'boolean'
              ? extractedListingParams.is_at_home
              : isAtHome,
          isRecurring:
            typeof partialInput?.isRecurring === 'boolean'
              ? partialInput?.isRecurring
              : typeof extractedListingParams.is_recurring === 'boolean'
              ? extractedListingParams.is_recurring
              : isRecurring,
          dateTime:
            partialInput?.dateTime || partialInput?.dateTime === ''
              ? partialInput?.dateTime
              : extractedListingParams.date_time || dateTime,
          pkg:
            partialInput?.pkg ||
            extractedListingParams.pkg ||
            (pkg !== initialState.pkg ? pkg : undefined),
          origin:
            partialInput?.origin || partialInput?.origin === ''
              ? partialInput?.origin
              : extractedListingParams.origin || origin,
          atHomeLocation:
            partialInput?.atHomeLocation ||
            extractedListingParams.at_home_location ||
            (selectedAtHomeLocation && selectedListing?.skill?.id
              ? {
                  ...selectedAtHomeLocation,
                  placeName: `SEC Private Residence Student`,
                  skillId: selectedListing.skill.id,
                }
              : undefined),
          searchBoundingBox:
            partialInput?.searchBoundingBox ||
            extractedListingParams.search_bounding_box ||
            searchBoundingBox,
        });
      },
      [
        extractedListingParams,
        selectedListing?.skill?.id,
        selectedLocation,
        selectedSkill?.category,
        participants,
        lessonLength,
        isAtHome,
        isRecurring,
        dateTime,
        pkg,
        origin,
        selectedAtHomeLocation,
        searchBoundingBox,
      ],
    );

  return { getListingSearchParamsWithPartialInput };
}
