import { Box, Button, Text } from '@chakra-ui/react';

import BookingWidgetBottomSheet from '../BookingWidgetSheet';

type TrialLessonSheetProps = {
  isOpen: boolean;
  onClose: () => void;
};
export default function TrialLessonSheet({
  isOpen,
  onClose,
}: TrialLessonSheetProps): JSX.Element {
  return (
    <BookingWidgetBottomSheet.Root isOpen={isOpen} onClose={onClose}>
      <BookingWidgetBottomSheet.Content>
        <Box px="6" pb="2">
          <Text textStyle="bold" textAlign="center" mb="4">
            Want a trial lesson?
          </Text>
          <Box w="full" p="4" mb="4" bg="on-accent-muted" borderRadius="2xl">
            <Text color="blue.500" textAlign="center">
              You&apos;re already getting one! If you don&apos;t love your first
              lesson, we&apos;ll find you another coach or refund your whole
              purchase. Your choice.
            </Text>
          </Box>
        </Box>
        <BookingWidgetBottomSheet.BottomBar>
          <Button
            variant="primary"
            size="xl"
            w="full"
            rounded="full"
            mt="0"
            mb="0"
            onClick={onClose}
          >
            Got it!
          </Button>
        </BookingWidgetBottomSheet.BottomBar>
      </BookingWidgetBottomSheet.Content>
    </BookingWidgetBottomSheet.Root>
  );
}
