import { Stack } from '@chakra-ui/react';
import { add, isSameDay } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CalendarProps, SlotProps } from './interfaces';

import { useGetMonthAvailabilityLazyQuery } from '../../../api';
import useDispatchDateTime from '../../../hooks/useDispatchDateTime';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import { useAppSelector } from '../../../state/hooks';
import { selectDateTime } from '../../../state/slices/filtersSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import { BookingStep } from '../../../utils/types';
import { DayType } from '../../DatePicker/Calendar/type';
import DateTimePicker from '../../DatePicker/DateTimePicker';
import BookingWidgetBody from '../BookingWidgetBody';
import BookingWidgetFooter from '../BookingWidgetFooter';
import BookingWidgetHeader from '../BookingWidgetHeader';

export default function Calendar({
  listingId,
  listingUrl,
  onOpen,
}: CalendarProps) {
  const navigate = useNavigate();
  const dateTime = useAppSelector(selectDateTime);
  const [getSlots, { data }] = useGetMonthAvailabilityLazyQuery();
  const [slots, setSlots] = useState<SlotProps[]>([]);
  const { dispatchDateTime } = useDispatchDateTime();
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const selectedDate: Date = useMemo(() => {
    if (dateTime) {
      return new Date(dateTime);
    }
    return add(new Date(), {
      days: 1,
    });
  }, [dateTime]);

  useEffect(() => {
    if (!selectedDate || !listingId) return;
    getSlots({
      variables: {
        listingId,
        date: new Date(selectedDate).toISOString(),
      },
    });
  }, [selectedDate, getSlots, listingId]);

  useEffect(() => {
    setSlots(data?.getMonthSlots);
  }, [data?.getMonthSlots]);

  const onDayTypeRulesFn = useCallback(
    (date: Date) => {
      const day = slots?.find((slot: SlotProps) => {
        const [d, isAvailable] = Object.entries(slot)[0];
        return isSameDay(toDate(d), date) && isAvailable;
      });
      if (!day) return DayType.DISABLE;
      if (isSameDay(date, selectedDate)) return DayType.ACTIVE;
      return DayType.NORMAL;
    },
    [selectedDate, slots],
  );

  const handleDateChange = useCallback(
    (nextDate: Date) => {
      const newDateTime = dispatchDateTime(nextDate);
      capturePostHogEvent('clickDate', {
        nextDate: nextDate.toString(),
        newDateTime,
      });
      const listingSearchParams = getListingSearchParamsWithPartialInput({
        dateTime: newDateTime,
      });
      navigate(`${listingUrl}/${BookingStep.Times}?${listingSearchParams}`);
    },
    [
      dispatchDateTime,
      getListingSearchParamsWithPartialInput,
      listingUrl,
      navigate,
    ],
  );

  const handleMonthChange = useCallback(
    async (nextDate: Date) => {
      if (!listingId) return;
      await getSlots({
        variables: {
          date: nextDate.toISOString(),
          listingId,
        },
      });
    },
    [getSlots, listingId],
  );

  return (
    <>
      <BookingWidgetHeader heading="Pick a date" />
      <BookingWidgetBody>
        <Stack pb="6">
          <DateTimePicker
            selectedDate={selectedDate}
            onSetDate={handleDateChange}
            handleMonthChange={handleMonthChange}
            onDayTypeRulesFn={onDayTypeRulesFn}
          />
        </Stack>
      </BookingWidgetBody>
      <BookingWidgetFooter
        isBottomCtaClickable
        bottomCtaLabel="Request a time (92% success)"
        bottomCtaVariant="outline"
        onClick={() => {
          capturePostHogEvent('clickRequestTime');
          onOpen();
        }}
      />
    </>
  );
}
