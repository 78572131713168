import { useLocalStorageValue } from '@react-hookz/web';
import { useCallback, useMemo } from 'react';

type Tracking = {
  gclid: string;
  msclkid: string;
};

export default function useTracking() {
  const [gclid, setGclid] = useLocalStorageValue<string>('gclid', '');
  const [msclkid, setMsclkid] = useLocalStorageValue<string>('msclkid', '');

  const capture = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const gclidParam = urlParams.get('gclid');
    const msclkId = urlParams.get('msclkId');
    setGclid(gclidParam || '');
    setMsclkid(msclkId || '');
  }, [setGclid, setMsclkid]);

  const tracking = useMemo(
    () =>
      ({
        gclid,
        msclkid,
      } as Tracking),
    [gclid, msclkid],
  );

  return {
    capture,
    tracking,
  };
}
