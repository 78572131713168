import { useLocalStorageValue } from "@react-hookz/web";
import { useStripe } from "@stripe/react-stripe-js";
import { PaymentRequest, PaymentRequestOptions, PaymentRequestUpdateOptions } from "@stripe/stripe-js";
import { parsePhoneNumber } from "libphonenumber-js";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import useCheckoutFormValues from "./useCheckoutFormValues";

import { CreateBookingInput, CreateBookingInputMode, PricePayload, useCreateBookingMutation, useCreatePaymentIntentForBookingMutation, useCreateUserMutation } from "../../../api";
import { useError } from "../../../hooks/useError";
import useInAppMember from "../../../hooks/useInAppMember";
import useIsAtHomeOrListingExistsWithNoLocations from "../../../hooks/useIsAtHomeOrListingExistsWithNoLocations";
import useIsMobile from "../../../hooks/useIsMobile";
import usePickleheadsDiscount from "../../../hooks/usePickleheadsDiscount";
import usePosthogCustomEvents from "../../../hooks/usePosthogCustomEvents";
import usePricing from "../../../hooks/usePricing";
import useProReferralCode from "../../../hooks/useProReferralCode";
import useSentryWithContext from "../../../hooks/useSentryWithContext";
import useStudentReferral from "../../../hooks/useStudentReferral";
import { useSuccess } from "../../../hooks/useSuccess";
import useTapfiliate from "../../../hooks/useTapfiliate";
import useTracking from "../../../hooks/useTracking";
import { captureStartCheckout } from "../../../infra/gtmClient";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { resetBooking, selectClientSecret, selectSuccessUrl, setClientSecret, setSuccessUrl } from "../../../state/slices/bookingSlice";
import { selectIsTermsChecked } from "../../../state/slices/checkoutSlice";
import { selectAtHomeLocation } from "../../../state/slices/filtersSlice";
import { Country } from "../../../types";
import capturePostHogEvent from "../../../utils/capturePostHogEvent";
import { customerIoIdentifyWithPH } from "../../../utils/customerIO";
import getTimezone from "../../../utils/getTimezone";
import PosthogEvent from "../../../utils/posthogEvents";
import studentInfoValidation from "../components/StudentInfo/utils/studentInfoValidation";
import { createBookingInputForCheckout, paymentRequestHandler } from "../utils";

export default function useCheckoutSubmit() {
    const { captureException } = useSentryWithContext();
    const { isMobile } = useIsMobile();
    const stripe = useStripe();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>(null);
    const [prices, setPrices] = useState<PricePayload[] | null>(null);

    const {
        listing,
        selectedLocation,
        dateTime,
        participants,
        gear,
        isRecurring,
        pkg,
        lessonLength,
        confirmEmail,
        confirmName,
        confirmPhoneNumber
    } = useCheckoutFormValues();

    const isAtHomeOrListingExistsWithNoLocations =
        useIsAtHomeOrListingExistsWithNoLocations();
    const atHomeLocation = useAppSelector(selectAtHomeLocation);
    const selectedAtHomeLocation = isAtHomeOrListingExistsWithNoLocations
        ? atHomeLocation
        : undefined;
    const { handleError } = useError();
    const { handleSuccess } = useSuccess();
    const [posthogUserId] = useLocalStorageValue<string>('posthogUserId');
    const { tracking } = useTracking();

    const { isInAppMember } = useInAppMember();
    const { tapfiliateReferralCode } = useTapfiliate();
    const { proReferralCode, resetProReferralCode } = useProReferralCode()
    const { referralCode, resetReferralCode } = useStudentReferral();
    const {
        getPrices,
        getSubscriptionPrice,
        isLoading: isLoadingPrices,
    } = usePricing();
    const [createUser] = useCreateUserMutation();
    const [createBooking] = useCreateBookingMutation();
    const [createPaymentIntentForBooking] =
        useCreatePaymentIntentForBookingMutation();
    const clientSecret = useAppSelector(selectClientSecret);
    const [country] = useLocalStorageValue<Country>('country', {
        name: 'United States',
        flag: '🇺🇸',
        code: 'US',
        dialCode: '+1',
    });
    const { createAlias, identify } = usePosthogCustomEvents();
    const isTermsChecked = useAppSelector(selectIsTermsChecked);
    const successUrl = useAppSelector(selectSuccessUrl);

    const listingId = listing?.id;
    const hasRegularLocation: boolean = !!selectedLocation?.id;
    const hasAtHomeLocation: boolean = !!(
        selectedAtHomeLocation?.address && listing?.skill?.id
    );
    const hasLocation: boolean = hasRegularLocation || hasAtHomeLocation;
    const doesPaymentRequestExist = !!paymentRequest;
    const doesListingExist = !!listing;

    const createBookingInputMode: CreateBookingInputMode = isRecurring
        ? CreateBookingInputMode.Subscription
        : CreateBookingInputMode.Payment;

    const canSubmitForm = useMemo(() => isTermsChecked && !!confirmEmail &&
        !!confirmName &&
        !!confirmPhoneNumber, [isTermsChecked, confirmEmail, confirmName, confirmPhoneNumber])

    const { pickleheadsDiscountAmount } = usePickleheadsDiscount();

    const price: PricePayload =
        prices?.find((node) => node?.package === pkg) || prices?.[0];
    const totalPrice = Math.max(
        0,
        (price?.price || 0) - pickleheadsDiscountAmount,
    );

    const paymentRequestOptions: PaymentRequestOptions = useMemo(() => {
        if (totalPrice) {
            return {
                country: 'US',
                currency: 'usd',
                requestPayerEmail: true,
                requestPayerName: true,
                total: {
                    // A name that the browser shows the customer in the payment interface.
                    label: 'TeachMe.To',
                    // The amount in the currency's subunit (e.g. cents, yen, etc.)
                    amount: Math.floor(totalPrice * 100),
                },
            };
        }
        return undefined;
    }, [totalPrice]);

    const checkoutBase = (
        userId: string,
        userName: string,
        withPaymentRequest: boolean,
    ): CreateBookingInput => createBookingInputForCheckout({
        userId,
        userName,
        withPaymentRequest,
        hasLocation,
        dateTime,
        posthogUserId,
        participants,
        isRecurring,
        pkg,
        selectedLocation,
        gear,
        isMobile,
        origin: isInAppMember ? 'In-App Browser (Student App)' : origin,
        listingId,
        tapfiliateReferralCode,
        atHomeLocation: selectedAtHomeLocation,
        confirmEmail,
        confirmName,
        confirmPhoneNumber,
        tracking,
        proReferralCode,
        referralCode,
        skillId: listing?.skill?.id,
        lessonLength,
    });

    const handleRegistrationBase = async ({ withPaymentRequest }: { withPaymentRequest: boolean }): Promise<{
        userId: string;
        userName: string;
    }> => {
        // capture posthog event
        capturePostHogEvent(PosthogEvent.StartCheckout, {
            withPaymentRequest
        }, {
            send_instantly: true,
        });

        let formattedSelectedLocation = 'No selected location';
        if (selectedAtHomeLocation) {
            formattedSelectedLocation = `At home: ${selectedAtHomeLocation.address} (${selectedAtHomeLocation.latitude}, ${selectedAtHomeLocation.longitude})`;
        } else if (selectedLocation) {
            formattedSelectedLocation = `${selectedLocation.placeName} | ${selectedLocation.address}`;
        }
        const name = confirmName || '';
        const email = confirmEmail?.toLowerCase()?.trim() || '';
        const phoneNumber = confirmPhoneNumber
            ? parsePhoneNumber(confirmPhoneNumber, country?.code).number
            : '';

        const { data: createdUser } = await createUser({
            variables: {
                data: {
                    name,
                    email,
                    phoneNumber,
                    timeZone: getTimezone(),
                    metadata: {
                        skill: listing?.skill?.label,
                        proName: listing?.proName,
                        pkg,
                        date: dateTime,
                        pricingExperimentFlag: "control",
                        createBookingInputMode,
                        tapfiliateReferralCode,
                        formattedSelectedLocation,
                        gclid: tracking.gclid,
                        msclkid: tracking.msclkid,
                    },
                },
            },
        });
        const userId = createdUser?.createUser?.id;
        const userName = createdUser?.createUser?.name || '';

        if (userId) {
            const userEmail = createdUser?.createUser?.email || '';

            /**
             * Identify the user with Posthog and create an alias for the user
             * to track the user's activity across devices
             *
             * Doing this async to await before redirecting to the Stripe checkout page
             */
            await identify(userName, userEmail, userId);
            await createAlias(userId);

            if (userEmail) {
                customerIoIdentifyWithPH(posthogUserId, userEmail, userId);
            }
        }

        return { userId, userName };
    };

    const createBookingWithoutPaymentRequest = async (
        createBookingInput: CreateBookingInput,
    ) => {
        const { data, errors } = await createBooking({
            variables: {
                data: createBookingInput,
            },
        });
        if (errors?.length) {
            errors.forEach((error) => captureException(error, {
                context: {
                    name: "CreateBookingWithoutPaymentRequest",
                    data: createBookingInput
                }
            }));

            throw new Error(
                `We aren't sure what happened. Please try again or contact customer support for help.`,
            );
        }
        resetProReferralCode();
        resetReferralCode();
        window.location.assign(data?.createBooking?.url);
    };

    const createBookingWithPaymentRequest = async (
        createBookingInput: CreateBookingInput,
    ) => {
        const { data, errors } = await createPaymentIntentForBooking({
            variables: {
                data: createBookingInput,
            },
        });
        if (errors?.length) {
            errors.forEach((error) =>
                captureException(error, {
                    context: {
                        name: "CreateBookingWithPaymentRequest",
                        data: createBookingInput
                    }
                }),
            );

            throw new Error(
                `We aren't sure what happened. Please try again or our contact customer support for help.`,
            );
        }

        dispatch(
            setClientSecret(data?.createPaymentIntentForBooking?.clientSecret),
        );
        dispatch(setSuccessUrl(data?.createPaymentIntentForBooking?.successUrl));
    };

    const checkoutWithoutPaymentRequest = async (
        userId: string,
        userName: string,
    ) => {
        const createBookingInput = await checkoutBase(userId, userName, false);
        if (!createBookingInput) return;

        await createBookingWithoutPaymentRequest(createBookingInput);
        captureStartCheckout({
            ecommerce: {
                currencyCode: 'USD',
                value: price?.price || 0,
                add: {
                    products: [
                        {
                            quantity: 1,
                            name: listing?.title,
                            id: listing?.id,
                            price: price?.price || 0,
                        },
                    ],
                },
            },
            user_data: {
                email: confirmEmail,
                phone_number: confirmPhoneNumber,
            },
        });
    };

    const checkoutWithPaymentRequest = async (
        userId: string,
        userName: string,
    ) => {
        const createBookingInput = await checkoutBase(userId, userName, true);
        if (!createBookingInput) return;

        await createBookingWithPaymentRequest(createBookingInput);

        captureStartCheckout({
            ecommerce: {
                currencyCode: 'USD',
                value: price?.price || 0,
                add: {
                    products: [
                        {
                            quantity: 1,
                            name: listing?.title,
                            id: listing?.id,
                            price: price?.price || 0,
                        },
                    ],
                },
            },
            user_data: {
                email: confirmEmail,
                phone_number: confirmPhoneNumber,
            },
        });
    };

    const validate = async () => {
        try {
            const validationSchema = studentInfoValidation(country?.code)
            await validationSchema.validate({
                name: confirmName,
                email: confirmEmail,
                phoneNumber: confirmPhoneNumber,
                isTermsChecked
            }, { abortEarly: false });
        } catch (validationErrors) {
            const errors = validationErrors.inner.reduce(
                (acc, error) => ({ ...acc, [error.path]: error.message }),
                {},
            );
            handleError(errors);
            throw new Error();
        }
    }

    const handleCheckoutWithoutPaymentRequest = async () => {
        setIsLoading(true);
        try {
            await validate();
            const { userId, userName } = await handleRegistrationBase({ withPaymentRequest: false });
            await checkoutWithoutPaymentRequest(userId, userName);
        } catch (error) {
            console.error('Error creating booking without payment request', error);
            handleError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckoutWithPaymentRequest = async () => {
        if (clientSecret) {
            return;
        }

        setIsLoading(true);
        try {
            await validate();
            const { userId, userName } = await handleRegistrationBase({ withPaymentRequest: true });
            await checkoutWithPaymentRequest(userId, userName);
        } catch (error) {
            console.error('Error creating booking with payment request', error);
            handleError(error.message);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        if (!listing?.id) return;

        if (isRecurring) {
            getSubscriptionPrice({ listingId: listing?.id })
                .then((subscriptionPrice) => setPrices([subscriptionPrice]))
                .catch((error) => {
                    captureException(error, {
                        context: {
                            name: "GetSubscriptionPrice",
                            data: {
                                listingId: listing?.id
                            }
                        }
                    });
                    setPrices(null);
                });
        } else {
            getPrices({ listingId: listing?.id })
                .then(setPrices)
                .catch((error) => {
                    captureException(error, {
                        context: {
                            name: "GetPrices",
                            data: {
                                listingId: listing?.id
                            }
                        }
                    });
                    setPrices(null);
                });
        }
    }, [listing?.id, getPrices, getSubscriptionPrice, isRecurring, pkg, setPrices, captureException]);


    /**
    * Create/Update payment request.
    *
    * If the user does not have an active payment method,
    * `PaymentRequestButtonElement` should not be rendered.
    */
    useEffect(() => {
        if (!doesListingExist || isRecurring || !stripe || !paymentRequestOptions) {
            setPaymentRequest(null);
            return;
        }

        // Update payment request
        if (doesPaymentRequestExist) {
            const paymentRequestUpdateOptions: PaymentRequestUpdateOptions = {
                currency: paymentRequestOptions.currency,
                displayItems: paymentRequestOptions.displayItems,
                total: paymentRequestOptions.total,
                shippingOptions: paymentRequestOptions.shippingOptions,
                applePay: paymentRequestOptions.applePay,
            };
            paymentRequest?.update(paymentRequestUpdateOptions);
        }
        // Create payment request
        else {
            const pr = stripe.paymentRequest(paymentRequestOptions);

            // Check the availability of the Payment Request API.
            pr.canMakePayment()
                .then((result) => {
                    if (result) {
                        setPaymentRequest(pr);
                    } else {
                        setPaymentRequest(null);
                    }
                })
                .catch(() => {
                    setPaymentRequest(null)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        doesListingExist,
        doesPaymentRequestExist,
        isRecurring,
        paymentRequestOptions,
        stripe,
        isLoadingPrices
    ]);

    const onPaymentRequestSuccess = () => {
        handleSuccess('Payment successful!');

        resetProReferralCode();
        resetReferralCode();

        if (successUrl) {
            window.location.assign(successUrl);
        } else {
            navigate('/');
        }

        dispatch(resetBooking());
    };

    /**
     * Stripe.js automatically creates a `PaymentMethod` after the customer is done
     * interacting with the browser’s payment interface.
     *
     * To access the created `PaymentMethod`, listen for this event.
     */
    useEffect(() => {
        const eventHandler = (event) => paymentRequestHandler({
            stripe,
            clientSecret,
            event,
            onError: handleError,
            onSuccess: onPaymentRequestSuccess,
        })

        paymentRequest?.on('paymentmethod', eventHandler);

        return () => {
            paymentRequest?.off('paymentmethod', eventHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientSecret, handleError, paymentRequest, stripe]);

    return {
        isLoading,
        handleCheckoutWithoutPaymentRequest,
        handleCheckoutWithPaymentRequest,
        canSubmitForm,
        doesPaymentRequestExist,
        clientSecret,
        paymentRequest
    }
}
