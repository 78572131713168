import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
} from '@chakra-ui/react';
import { HiOutlineMap } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../state/hooks';
import { selectSelectedListing } from '../../state/slices/listingsSlice';
import ProLocationsList from '../ProLocationsList';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function ProLocationsModal({ isOpen, onClose }: Props) {
  const selectedListing = useAppSelector(selectSelectedListing);
  const navigate = useNavigate();

  return (
    <Drawer isOpen={isOpen} placement="bottom" size="md" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent borderRadius="2xl" pt="4">
        <DrawerBody>
          <ProLocationsList listing={selectedListing} onClose={onClose} />
        </DrawerBody>

        <Divider />

        <Flex justifyContent="space-between" py="4" px="8">
          <Button
            onClick={onClose}
            variant="outline"
            px="44px"
            mr="2"
            borderColor="border"
            size="lg"
          >
            Back
          </Button>
          <Button
            onClick={() => {
              onClose();
              navigate(`/listing/map/${selectedListing.slug}`);
            }}
            rightIcon={<HiOutlineMap color="white" />}
            size="lg"
          >
            View map
          </Button>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
}
