import { Box, BoxProps, Button, Text } from '@chakra-ui/react';
import { forwardRef, ReactNode, useLayoutEffect, useRef, useState } from 'react';

interface Props extends BoxProps {
  children: ReactNode;
  isConsistentHeight?: boolean;
  noOfLines?: number;
}

const ExpandableText = forwardRef<HTMLDivElement, Props>(
  ({ children, isConsistentHeight, noOfLines = 4, ...rest }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [expandedCount, setExpandedCount] = useState<number | undefined>(
      noOfLines,
    );
    const [isClicked, setIsClicked] = useState(false);
    const [isTextClamped, setIsTextClamped] = useState(false);

    const handleToggle = () => {
      setIsClicked(true);
      setExpandedCount(expandedCount ? undefined : noOfLines);
    };

    useLayoutEffect(() => {
      const checkClamped = () => {
        if (inputRef.current) {
          const isClamped =
            !(inputRef.current.scrollHeight) ||
            inputRef.current.scrollHeight > inputRef.current.clientHeight ||
            isClicked;
          setIsTextClamped(isClamped);
        }
      };

      // Timeout to ensure calculations happen after potential layout shifts
      setTimeout(checkClamped, 0);
    }, [expandedCount, children, isConsistentHeight, isClicked]);


    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Box ref={ref} {...rest}>
        <Box ref={inputRef} noOfLines={expandedCount} textAlign="left">
          {children}
        </Box>
        <Button
          display={
            // eslint-disable-next-line no-nested-ternary
            isConsistentHeight ? undefined : isTextClamped ? 'block' : 'none'
          }
          visibility={
            // eslint-disable-next-line no-nested-ternary
            !isConsistentHeight
              ? undefined
              : isTextClamped
              ? 'visible'
              : 'hidden'
          }
          size="sm"
          variant="link"
          color="defaultText"
          fontWeight="bold"
          textDecoration="underline"
          mt="2"
          onClick={handleToggle}
        >
          <Text>{!expandedCount ? 'Show less' : 'Read more'}</Text>
        </Button>
      </Box>
    );
  },
);
export default ExpandableText;
