import * as Yup from 'yup';

export default function studentInfoValidation(countryCode: string) {
    return Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string()
            .email('Invalid email')
            .required('Email is required'),
        phoneNumber: Yup.string()
            // @ts-ignore
            .phone(countryCode, true, 'Invalid phone number')
            .required('Phone number is required'),
        isTermsChecked: Yup.bool().oneOf([true], 'You must agree to the terms'),
    })
}
