import { Modal, ModalOverlay, ModalProps } from '@chakra-ui/react';
import { useState } from 'react';

import QuizLocationPermissionModal from './LocationPermission/QuizLocationPermissionModal';
import QuizContentWrapper from './QuizContentWrapper';
import QuizProvider from './QuizProvider';
import useQuiz from './useQuiz';

import useLocalSelectedBadges from '../../hooks/useLocalSelectedBadges';
import useSelectedSkill from '../../hooks/useSelectedSkill';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  selectSelectedBadges,
  setSelectedBadges,
} from '../../state/slices/filtersSlice';
import capturePostHogEvent from '../../utils/capturePostHogEvent';
import PosthogEvent from '../../utils/posthogEvents';

interface Props extends Omit<ModalProps, 'children'> {
  isOpen: boolean;
  onClose: () => void;
  bg?: string;
}

function QuizContent(props: Props) {
  const { isOpen, onClose, bg, ...rest } = props;
  const [showQuiz, setShowQuiz] = useState(false);
  const selectedBadges = useAppSelector(selectSelectedBadges);
  const { localSelectedBadges } = useLocalSelectedBadges();
  const [selectedSkill] = useSelectedSkill();

  const dispatch = useAppDispatch();

  const { resetQuiz, hasNextStep, currentQuestion } = useQuiz();
  const handleOnClose = () => {
    if (
      JSON.stringify(localSelectedBadges) !== JSON.stringify(selectedBadges)
    ) {
      dispatch(setSelectedBadges(localSelectedBadges));
    }
    onClose();
  };

  const handleOnCloseComplete = () => {
    if (!hasNextStep) {
      capturePostHogEvent(PosthogEvent.QuizCompleted, {
        skill: selectedSkill?.slug,
      });
      resetQuiz();
    }
  };

  const handleShowQuiz = () => {
    setShowQuiz(true);
  };

  const onOverlayClick = () => {
    capturePostHogEvent(PosthogEvent.QuizDismissedByOverlay, {
      question: currentQuestion?.title,
    });
  };

  return !showQuiz ? (
    <QuizLocationPermissionModal
      isOpen={isOpen}
      onClose={onClose}
      onShowQuiz={handleShowQuiz}
    />
  ) : (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={handleOnClose}
      onCloseComplete={handleOnCloseComplete}
      size={{ base: 'full', md: 'auto' }}
      scrollBehavior="inside"
      motionPreset="slideInBottom"
      onOverlayClick={onOverlayClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <ModalOverlay bg={bg} />

      <QuizContentWrapper onClose={handleOnClose} />
    </Modal>
  );
}
export default function Quiz(props: Props) {
  return (
    <QuizProvider>
      <QuizContent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </QuizProvider>
  );
}
