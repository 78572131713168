/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../interfaces';

export interface MapState {
    showResults: boolean;
}

const initialState: MapState = {
    showResults: true,
};

const slice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setShowMapResults: (
            state,
            action: PayloadAction<MapState['showResults']>,
        ) => {
            state.showResults =
                action.payload
        },
    },
});

// export actions
export const { setShowMapResults } =
    slice.actions;

// export selectors
export const selectShowMapResults = (state: RootState) =>
    state.map.showResults;

// export reducer
export default slice.reducer;
