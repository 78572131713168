import { Box, Flex, Text } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { FaRegStar, FaStar } from 'react-icons/fa';

interface TestimonialCardProps {
  rating: number;
  name: string;
  body: string;
}

export default function TestimonialCard({
  rating,
  name,
  body,
}: TestimonialCardProps) {
  const stars = Array.from({ length: 5 }, (_, index) => {
    const marginLeft = index === 0 ? 0 : '2px';
    if (index < rating) {
      return (
        <Box key={`star-${index + 1}`} ml={marginLeft} display="inline-block">
          <FaStar color="#FFFFFF" size="16px" />
        </Box>
      );
    }
    return (
      <Box key={`star-${index + 1}`} ml={marginLeft} display="inline-block">
        <FaRegStar color="#FFFFFF" size="16px" />
      </Box>
    );
  });

  if (isMobile) {
    return (
      <Box
        width="100%"
        position="absolute"
        bottom="10"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          bg="rgba(0, 0, 0, 0.70)"
          color="white"
          py={1}
          px={3}
          rounded="full"
          shadow="md"
        >
          <Text casing="uppercase" fontSize="sm" fontWeight="black">
            Submitted by {name}
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      bg="rgba(45, 55, 72, 0.9)"
      color="white"
      p={6}
      rounded="2xl"
      shadow="md"
      maxW="sm"
      position="absolute"
      bottom="4"
      right="4"
    >
      <Flex mb={2} alignItems="center" justifyContent="space-between">
        <Text casing="uppercase" fontSize="sm" fontWeight="black">
          {name}
        </Text>
        <Flex>{stars}</Flex>
      </Flex>
      <Text fontSize="md">&quot;{body}&quot;</Text>
    </Box>
  );
}
