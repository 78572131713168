import {
  Box,
  Flex,
  Grid,
  GridItem,
  Skeleton,
  SkeletonText,
  VStack,
} from '@chakra-ui/react';
import { motion, useTransform } from 'framer-motion';
import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import MobileSearchHeaderSkeleton from '../../components/MobileSearchHeaderSkeleton';
import SearchHeaderSkeleton from '../../components/SearchHeaderSkeleton';
import { useMainScroll } from '../../context/MainScrollContext';
import useHeaderHeight from '../../hooks/useHeaderHeight';
import useIsMapView from '../../hooks/useIsMapView';
import useIsMobile from '../../hooks/useIsMobile';
import usePageLayout from '../../hooks/usePageLayout';

export default function BrowseSkeleton(): JSX.Element {
  const { isMobile } = useIsMobile();
  const { isMapView } = useIsMapView();
  const { headerHeight } = useHeaderHeight();
  const { pageMargin, gridWidth ,numOfColumns, mediaSize} = usePageLayout();
  const ref = useRef<HTMLDivElement>();

  const {
    scroll: { scrollY },
  } = useMainScroll();

  const boxShadow = useTransform(
    scrollY,
    [0, headerHeight],
    [
      '0 10px 10px -10px rgba(0, 0, 0, 0)',
      '0 10px 10px -10px rgba(0, 0, 0, 0.1)',
    ],
  );
  const height = useTransform(
    scrollY,
    [0, headerHeight],
    [headerHeight, 55 + pageMargin / 2],
  );

  const totalWidth = `${gridWidth + pageMargin}px`;
  const heightProCards = isMobile && !isMapView ? undefined : `calc(100dvh - ${headerHeight}px)`;
  const isTotalWidth = isMapView;

  return (

    <Flex
      align={{ base: 'flex-start', md: 'center' }}
      bg="bg-surface"
      className="full"
      direction="column"
      flex="1"
      minH="100dvh"
      mx="auto"
    >

      <Flex
        align="center"
        direction="column"
        flex="1"
        mx="auto"
        w="full"
      >
        <motion.div
          style={{
            backgroundColor: 'white',
            boxShadow,
            display: 'flex',
            height,
            justifyContent: 'center',
            overflow: isMobile ? 'hidden' : undefined,
            paddingTop: isMobile ? pageMargin : undefined,
            position: 'sticky',
            top: 0,
            width: '100%',
            zIndex: 9,
          }}
        >
          <Flex
            alignItems="center"
            bg="bg-surface"
            flexDir="column"
            h="full"
            justifyContent="center"
            px={isMapView && !isMobile ? `${pageMargin / 2}px` : undefined}
            w={isMapView || isMobile ? 'full' : gridWidth}
          >
            {isMobile ? (
              <MobileSearchHeaderSkeleton/>
            ) : (
              <SearchHeaderSkeleton/>
            )}
          </Flex>
        </motion.div>
        <Flex h={heightProCards} justifyContent="center" pos="relative" w="full">
          <VStack
            alignItems="center"
            flexDir="column"
            h={heightProCards}
            justifyContent="flex-start"
            maxH={heightProCards}
            maxW={isTotalWidth ? totalWidth : 'full'}
            minH="250px"
            minW={isTotalWidth ? totalWidth : 'full'}
            overflow="scroll"
            sx={{
              '::-webkit-scrollbar': { display: 'none' },
              scrollbarWidth: 'none',
            }}
            w={isTotalWidth ? totalWidth : 'full'}
          >
            <Flex
              alignItems="flex-start"
              justifyContent="center"
              maxW={totalWidth}
              minW={totalWidth}
              pb={`${pageMargin / 2}px`}
              w={totalWidth}
            >

              <Grid
                gap="24px"
                templateColumns={`repeat(${numOfColumns}, 1fr)`}
                w={gridWidth}
              >

                <GridItem colSpan={numOfColumns}>
                  <SkeletonText mt='4' noOfLines={3} spacing='3' skeletonHeight='2'/>
                </GridItem>

                {[...Array(numOfColumns * 2)].map(() =>
                  <GridItem key={uuidv4()} borderRadius="16px" ref={ref} colSpan={1} width={mediaSize}>
                    <Flex
                      alignItems="flex-start"
                      borderRadius="16px"
                      h="full"
                      justifyContent="center"
                      width={mediaSize}
                    >
                      <Box borderRadius="16px" h="full" width={mediaSize} mb="2">
                        <Skeleton borderRadius="16px" h={mediaSize} w={mediaSize} minH={mediaSize} />
                        <SkeletonText mt='4' noOfLines={4} spacing='3' skeletonHeight='2'/>
                      </Box>
                    </Flex>
                  </GridItem>
                )}
              </Grid>
            </Flex>

          </VStack>

          <Box
            w="full"
            h={heightProCards}
           />

          <Box
            bottom="0"
            h={headerHeight}
            left="0"
            pointerEvents="none"
            pos="absolute"
            w="0"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
