import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  UseDisclosureProps,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useMemo, useRef } from 'react';
import { HiStar, HiX } from 'react-icons/hi';

import {
  ReviewStatus,
  useAggregateReviewsQuery,
  useReviewsCountQuery,
  useSummarizedReviewsV2Query,
} from '../../api';
import useIsMobile from '../../hooks/useIsMobile';
import ReviewMediaGallery, {
  ReviewMediaGalleryRef,
} from '../../screens/ListingDetails/components/ReviewMediaGallery';
import ReviewItem from '../ReviewItem';

interface Props extends UseDisclosureProps {
  listingId: string;
}

export default function ListingReviewsModal({
  isOpen,
  onClose,
  listingId,
}: Props) {
  const { isMobile } = useIsMobile();
  const reviewGallery = useRef<ReviewMediaGalleryRef>(null);

  const { data: reviewsCountResponse } = useReviewsCountQuery({
    variables: {
      listingId,
    },
  });

  const { data: aggregateReviewsQuery } = useAggregateReviewsQuery({
    variables: {
      where: {
        listingId: {
          equals: listingId,
        },
        status: {
          equals: ReviewStatus.Completed,
        },
      },
    },
  });

  const { data, fetchMore } = useSummarizedReviewsV2Query({
    variables: {
      listingId,
    },
  });

  const reviewsCount = useMemo(
    () => ({
      all: reviewsCountResponse?.all || 0,
      withBody: reviewsCountResponse?.withBody || 0,
    }),
    [reviewsCountResponse],
  );

  const [rating, ratingCount] = useMemo(
    () => [
      aggregateReviewsQuery?.aggregateReview?._avg?.rating || 0,
      aggregateReviewsQuery?.aggregateReview?._count?.id || 0,
    ],
    [aggregateReviewsQuery?.aggregateReview],
  );

  const allReviews = useMemo(
    () => data?.getSummarizedReviewsV2 || [],
    [data?.getSummarizedReviewsV2],
  );

  const hasMore = useMemo(
    () => allReviews.length < reviewsCount.withBody,
    [allReviews, reviewsCount.withBody],
  );

  const loadMore = useCallback(() => {
    fetchMore({
      variables: {
        skip: allReviews.length,
        take: 20,
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prevResult;
        }

        return {
          ...prevResult,
          getSummarizedReviewsV2: prevResult.getSummarizedReviewsV2.concat(
            fetchMoreResult.getSummarizedReviewsV2,
          ),
        };
      },
    });
  }, [fetchMore, allReviews]);

  const onImageClicked = useCallback(
    (media: { src: string; id: string }[], index: number) => {
      reviewGallery.current?.open(media, index);
    },
    [],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: 'full', lg: '2xl' }}
      scrollBehavior="inside"
    >
      <ModalOverlay bg={isMobile ? 'bg-surface' : undefined} />
      <ModalContent borderRadius="3xl">
        <ModalBody
          padding={0}
          sx={{
            '::-webkit-scrollbar': { display: 'none' },
            scrollbarWidth: 'none',
          }}
        >
          <VStack
            gap={0}
            px="10"
            pt="8"
            pb="6"
            borderBottom={1}
            borderBottomStyle="solid"
            borderColor="#CBD5E0"
          >
            <HStack w="100%">
              <Heading variant="h4" flexGrow={1}>
                My reviews
              </Heading>
              <IconButton
                aria-label="Close modal"
                icon={<HiX />}
                variant="ghost"
                size="md"
                onClick={onClose}
              />
            </HStack>
            <HStack alignItems="center" gap="2" w="100%">
              <HStack alignItems="center" gap="1">
                <Text textStyle="smallBold">
                  {Number(rating)
                    ? rating.toFixed(rating === 5 ? 1 : 2)
                    : 'New'}
                </Text>
                <Icon as={HiStar} color="#3182CE" />
              </HStack>
              <Text ml="1" textStyle="small" color="gray.500">
                {ratingCount} ratings
              </Text>
              <Text mx="2" textStyle="small">
                &bull;
              </Text>
              <Text textStyle="small">
                {reviewsCount.withBody} with reviews
              </Text>
            </HStack>
          </VStack>
          {allReviews?.map((review, index) => (
            <Box key={review.id || index}>
              <Box gap={0} px="4" py="2">
                <ReviewItem
                  key={review.id || index}
                  review={review}
                  lessonsCount={review.lessonsCount}
                  mediaSize="large"
                  onImageClick={(imgIndex) =>
                    onImageClicked(review.media, imgIndex)
                  }
                />
              </Box>
              {index < allReviews.length - 1 && (
                <Divider borderColor="gray.200" />
              )}
            </Box>
          ))}
          {hasMore && (
            <HStack
              overflow="hidden"
              paddingTop="3"
              paddingBottom="3"
              borderTopWidth="1px"
              borderTopColor="gray.200"
            >
              <Button
                onClick={loadMore}
                _hover={{
                  background: 'transparent',
                }}
                size="md"
                textDecoration="underline"
                variant="ghost"
                borderRadius={0}
                w="100%"
                color="#3182CE"
              >
                Show more reviews
              </Button>
            </HStack>
          )}
        </ModalBody>
      </ModalContent>
      <ReviewMediaGallery ref={reviewGallery} />
    </Modal>
  );
}
