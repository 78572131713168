import { Stack } from '@chakra-ui/react';

export default function LessonRequirements() {
  return (
    <Stack spacing={4}>
      <p>
        In addition to behaving in accordance with our Community Standards,
        which apply to all community members, Teach Me To Lesson Pros must meet
        the following quality standards and requirements. Before any Lesson is
        published, it is reviewed based on the below criteria and it must
        continue upholding these standards to remain on Teach Me To.
      </p>
      <h2>Pro requirements</h2>
      <p>
        Pros must provide expert knowledge, active learning, and a good attitude
        to the Lesson. Learn more about these standards.
      </p>
      <h3>Students should always know exactly who will be teaching them</h3>
      <p>
        If a friend, partner, or a team is helping you Pro or manage your
        Lesson, they must be registered as a co-Pro or assistant via the Teams
        tool on your Pro dashboard. Co-Pros must also be assigned to the
        instances they are leading so that Students know their Pro in advance.
        Learn more about co-teaching requirements.
      </p>
      <h3>
        Pros may not hand Students off to 3rd party suppliers, or leave Students
        on their own without a Pro
      </h3>
      <p>
        Pros and co-Pros must personally lead their Students throughout the
        entirety of the Lesson.
      </p>
      <h3>Only Teach Me To Students on an Teach Me To Lesson&nbsp;</h3>
      <p>
        Once a Pro lists a Lesson for a particular time and date on Teach Me To,
        only Teach Me To Students can attend that instance of the Lesson. Pros
        are not permitted to mix Students from Teach Me To and other platforms
        on the same instance of a Lesson.
      </p>
      <h3>Keep to guidelines</h3>
      <p>
        Pros must abide by the health and safety guidelines when teaching
        in-person Lessons. Learn more about these requirements.
      </p>
      <h2>Teaching commitments</h2>
      <h3>Pros must honor all booked reservations&nbsp;</h3>
      <p>
        Pros must honor their reservations, unless the Pro must cancel due to a
        valid extenuating circumstance, safety concerns, or dangerous weather
        conditions. Learn more about our Pro Cancellation policy.
      </p>
      <h3>Student reviews, ratings, and engagement</h3>
      <p>
        Lessons must maintain a high overall rating and Student engagement
        level. Students like to know they can expect a consistent level of
        quality, no matter where they book. Pros with consistently low reviews
        (1-3*), or insufficient page views and bookings, may have their listings
        suspended and/or removed from Teach Me To. Learn more about ratings and
        reviews for Lessons.
      </p>
      <p>
        Pros are expected to abide by these standards and with our Terms of
        Service and Additional Terms for Pros. Lessons that don&apos;t meet any
        of the above standards may be removed from Teach Me To.
      </p>
      <h2>Verification requirements</h2>
      <p>
        Evident ID Verification. If an Lesson includes a technically specialized
        activity where we require proof of a license, certification, or
        insurance, it will not be published if any of the following is
        true:&nbsp;
      </p>
      <p>The Pro does not submit the requested documents&nbsp;</p>
      <p>The required documents are expired</p>
      <p>
        The name(s) on the documents does not match the name listed on the
        Pro&apos;s Teach Me To profile
      </p>
      <p>
        The documents don&apos;t meet our license or insurance
        requirements&nbsp;
      </p>
      <p>
        We cannot process the provided documents for any other reason (e.g.
        blurry photo or unrecognized document type){' '}
      </p>
      <h2>Lesson requirements</h2>
      <p>What we don&apos;t allow:</p>
      <h3>Accommodations&nbsp;</h3>
      <p>
        Lessons that include accommodations or lodging of any kind are not
        permitted.
      </p>
      <h3>Mistreatment of animals</h3>
      <p>
        Teach Me To&apos;s Animal Welfare Guidelines apply to Lessons involving
        wild animals in the wild and in captivity, as well as domesticated
        animals under human care. Violations include direct interactions with
        wild animals (e.g. riding, petting, feeding), dog sledding, the purchase
        or consumption of wild animal products, and certain other activities.
      </p>
      <h3>Intellectual property violations</h3>
      <p>
        We do not permit the use of copyrighted work such as music, videos,
        photography, or literature unless the work was created or properly
        licensed by the Pro, or is in the public domain. We also prohibit the
        unauthorized use of other types of intellectual property such as
        trademarks (e.g. brand names) or individual names (e.g. celebrities)
        that suggest an endorsement of, or affiliation with, a Pro or Lesson.
      </p>
      <h3>Violation of local laws or restrictions&nbsp;</h3>
      <p>
        Pros are responsible for understanding and complying with all applicable
        laws, rules, regulations, and other requirements that apply to their
        Lesson. Pros can find information about some of the legal obligations
        that may apply to their Lesson, including information about food,
        alcohol, the use of public lands, and tour guiding, on our Responsible
        Teaching Pages.
      </p>
      <h3>Inappropriate or discriminatory content</h3>
      <p>
        Lessons must follow all requirements outlined in Teach Me To&apos;s
        Content Policy and Nondiscrimination Policy.
      </p>
      <h3>Lessons that cross country borders</h3>
      <p>
        Lessons must take place in a single country. Lessons that include
        crossing a country border are not permitted.
      </p>
      <h3>Payments outside of Teach Me To&apos;s system.&nbsp;</h3>
      <p>
        Lesson Pros may not solicit an online or offline payment from Students
        in violation of Teach Me To&apos;s offsite payment policy. Read about
        our offsite payment policy.
      </p>
      <h2>Activities with special requirements or restrictions</h2>
      <h3>Services</h3>
      <p>
        Services are generally ineligible. Learn more about what doesn&apos;t
        qualify as a Teach Me To Lesson.
      </p>
      <h3>Technically-specialized outdoor activities</h3>
      <p>
        Certain activities aren&apos;t allowed on the platform. This includes,
        but is not limited to, activities involving extreme heights or caves
        (e.g. bungee, skydiving, heli-skiing, cave diving), certain ocean
        activities (e.g. free diving, shark diving), and certain ice or mountain
        activities (e.g. canyoneering, ice climbing, free climbing).
      </p>
      <h3>Weapons</h3>
      <p>
        We only allow the use of projectile weapons when the Pro has a valid
        license and insurance. Lessons involving firearms must have a minimum
        Student age limit of 18+.
      </p>
      <h2>Listing page requirements</h2>
      <p>
        The Lesson itinerary must be clear, complete, and accurate. Students
        should know exactly what to expect upon booking the Lesson. This applies
        to all Lesson details, including:
      </p>
      <p>What Students will be doing</p>
      <p>Meeting address and instructions for meeting up with the Pro</p>
      <p>
        What&apos;s included in the price (e.g. what the Pro is providing for
        Students)
      </p>
      <p>What Students need to bring with them (e.g. a tennis racket)</p>
      <p>The time and date of the Lesson</p>
      <h3>No &ldquo;open-ended&rdquo; itineraries</h3>
      <p>
        Each Lesson must have a clear, pre-defined itinerary&mdash;we can&apos;t
        accept &ldquo;open-ended&rdquo; plans where Students are asked to define
        a Lesson&apos;s itinerary, or choose activities or locations. It&apos;s
        OK to include minor variations.
      </p>
      <p>
        Not allowed: &quot;I&apos;ll meet Students for coffee and then
        we&apos;ll see where the afternoon takes us!&quot;
      </p>
      <p>
        Allowed: &quot;My baking Lesson is different based on the day of the
        week. On Mondays, we bake bread, and on Tuesday, we bake cookies.&quot;
      </p>
      <p>
        Not allowed: &quot;During the tour, Students can choose from among
        several different activities, including rafting or visiting the temples,
        or many others.&quot;
      </p>
      <p>
        Allowed: &quot;We&apos;ll visit galleries of upcoming artists. Each
        afternoon, I&apos;ll choose a different artist depending on the artists
        generating the most interest at the time.&quot; (This is OK because the
        main activity, gallery tours, remains the same)
      </p>
      <p>Photos must meet Teach Me To&apos;s photo quality standards.</p>
      <p>
        Pros must list their Lesson as an individual, not a business. The
        Pro&apos;s profile photo must be a clear picture of the Pro and not a
        company logo. The Pro&apos;s profile name must be the Pro&apos;s
        personal name and not a business name. Pros should describe themselves
        in the &ldquo;About Me&rdquo; section of the Lesson page.
      </p>
    </Stack>
  );
}
