import { UseDisclosureProps } from '@chakra-ui/react';
import { memo } from 'react';
import { HiChevronLeft } from 'react-icons/hi';
import Lightbox, { SlideImage, SlideVideo } from 'yet-another-react-lightbox';
import Video from 'yet-another-react-lightbox/plugins/video';
import 'yet-another-react-lightbox/styles.css';
import './styles/index.css';

import { type AlgoliaListingPayloadMedia } from '../../api';
import TestimonialCard from '../../screens/ListingDetails/components/TestimonialCard';

interface SlideReview {
  review?: {
    rating: number;
    name: string;
    body: string;
  };
}

interface CustomSlideImage extends SlideImage, SlideReview {}
interface CustomSlideVideo extends SlideVideo, SlideReview {}

type CustomSlide = CustomSlideImage | CustomSlideVideo;

interface Props extends UseDisclosureProps {
  hideNavigation?: boolean;
  initMuted?: boolean;
  media: ExtendedMedia[];
  defaultIndex?: number;
  width?: number;
}

interface ExtendedMedia
  extends Pick<AlgoliaListingPayloadMedia, 'src' | 'type'> {
  review?: {
    rating: number;
    name: string;
    body: string;
  };
}

const LightBox = memo(
  ({
    hideNavigation = false,
    initMuted = false,
    media = [],
    defaultIndex = 0,
    isOpen,
    onClose,
    width = 400,
  }: Props) => {
    const buildSlides = (slideMedia: ExtendedMedia[]): CustomSlide[] =>
      slideMedia.map((m: ExtendedMedia) => {
        if (m?.type === 'video') {
          return {
            autoPlay: true,
            type: 'video',
            width,
            muted: initMuted,
            sources: [
              {
                src: m?.src,
                type: 'video/mp4',
              },
            ],
            review: m?.review,
          } as CustomSlideVideo;
        }
        return {
          alt: 'Slide content',
          type: 'image',
          src: m?.src,
          imageFit: 'contain',
          review: m?.review,
        } as CustomSlideImage;
      });

    return !isOpen ? null : (
      <Lightbox
        open={isOpen}
        close={onClose}
        slides={buildSlides(media)}
        index={defaultIndex}
        plugins={[Video]}
        className={hideNavigation ? 'no-navigation' : undefined}
        carousel={{
          finite: hideNavigation && media.length < 2,
          padding: 0,
        }}
        controller={{
          closeOnPullDown: true,
          closeOnBackdropClick: true,
        }}
        render={{
          iconClose: () => (
            <HiChevronLeft
              style={{
                marginLeft: (window.innerWidth - width) / 4,
              }}
              color="white"
              size={24}
            />
          ),
          slideFooter: ({ slide }) => {
            const customSlide = slide as CustomSlide;
            if (customSlide.review) {
              return (
                <TestimonialCard
                  rating={customSlide.review.rating}
                  name={customSlide.review.name}
                  body={customSlide.review.body}
                />
              );
            }
            return null;
          },
        }}
        toolbar={{
          buttons: ['close'],
        }}
      />
    );
  },
);

export default LightBox;
