import { useBreakpoint } from '@chakra-ui/media-query';
import { useMemo } from 'react';

import type { ChakraBreakpoint } from '../utils/types';

export default function useIsMobile(): { isMobile: boolean } {
  // Breakpoints are measured in pixels
  const breakpoint = useBreakpoint({
    fallback: 'base',
    ssr: false,
  }) as ChakraBreakpoint;

  // Is this a mobile screen size?
  const isMobile: boolean = useMemo(
    () =>
      ['base', '5xs', '4xs', '3xs', '2xs', 'xs', 'sm', 'md'].includes(
        breakpoint,
      ),
    [breakpoint],
  );

  return { isMobile };
}
