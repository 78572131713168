import { useToast } from '@chakra-ui/react';

// eslint-disable-next-line import/prefer-default-export
export const useError = () => {
  const toast = useToast();

  const handleError = (error?: any) => {
    let message;

    if (Array.isArray(error) && error.length > 0) {
      // If error is a non-empty array, format it as a list
      message = error.map(err =>
        String(err?.response?.data?.message || err?.message || err)
      ).join('\n• ');
      message = `• ${message}`;
    } else if (typeof error === 'object' && error !== null && Object.keys(error).length > 0) {
      // If error is a non-empty object, format each property as a list
      message = Object.values(error).map(err =>
        String(err)
      ).join('\n• ');
      message = `• ${message}`;
    } else if (error && typeof error !== 'object') {
      // Handle single error that is not an object
      message = String(
        error?.response?.data?.message || error?.message || error,
      );
    }

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log('handleError', { error, message });
    }

    if (message) {
      toast({
        title: 'Something went wrong',
        description: message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return {
    handleError,
  };
};
