/* eslint-disable import/prefer-default-export */
import {
  Input as BaseInput,
  FormControl,
  FormLabel,
  InputGroup,
  InputProps,
} from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';

interface Props extends InputProps {
  label?: string;
  info?: string;
  error?: string;
  labelFontSize?: string;
  labelFontWeight?: string;
}

export const InputField = forwardRef(
  (
    {
      label,
      error,
      name,
      info,
      labelFontSize,
      labelFontWeight,
      isRequired,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      {label ? (
        <FormLabel
          fontSize={labelFontSize}
          htmlFor={name}
          fontWeight={labelFontWeight}
          mb="0"
          textStyle="caption"
          color={error ? 'error' : 'defaultText'}
        >
          {label}
        </FormLabel>
      ) : null}
      <InputGroup>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <BaseInput size="md" py="3" ref={ref} id={name} name={name} {...rest} />
      </InputGroup>
    </FormControl>
  ),
);
