import useCurrentLocation from './useCurrentLocation';
import useLocationPermission, {
  LocationPermissionStatus,
} from './useLocationPermission';
import useSelectedLocation from './useSelectedLocation';

import { useModal } from '../context/ModalContext';
import { useAppDispatch } from '../state/hooks';
import { setIsAtHome } from '../state/slices/filtersSlice';

export default function useSelectLessonAtHome(): {
  selectLessonAtHome(skipOpenLocationPermissionModal?: boolean): void;
} {
  const { handleOpen } = useModal();
  const { locationPermissionStatus, tryGetUserGeolocation } =
    useLocationPermission();
  const dispatch = useAppDispatch();
  const { currentLocation } = useCurrentLocation();
  const [, seSelectedLocation] = useSelectedLocation();

  const onOpenLocationPermissionModal = () => {
    handleOpen('locationPermission', undefined, 'has-requested-at-home', true);
  };

  const selectLessonAtHome = (skipOpenLocationPermissionModal = false) => {
    seSelectedLocation(undefined);
    dispatch(setIsAtHome(true));

    if (
      locationPermissionStatus === LocationPermissionStatus.UNDETERMINED &&
      !skipOpenLocationPermissionModal
    ) {
      onOpenLocationPermissionModal();
    } else if (!currentLocation) {
      tryGetUserGeolocation(true).catch((err) => {
        // eslint-disable-next-line no-console
        console.error('useSelectLessonAtHome.tryGetUserGeolocation', err);
        if (err?.toString?.() === LocationPermissionStatus.DECLINED) {
          onOpenLocationPermissionModal();
        }
      });
    }
  };

  return { selectLessonAtHome };
}
