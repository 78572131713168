import { useCallback, useEffect, useState } from 'react';

import useClosestSkills from './useClosestSkills';

import { useSkillsLazyQuery } from '../api';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import {
  FiltersState,
  selectSelectedSkill,
  setSelectedSkill,
} from '../state/slices/filtersSlice';
import { DEFAULT_SKILL_SLUG } from '../utils/constants';

type UseSelectedSkill = [
  skill: FiltersState['selectedSkill'],
  setSkill: (skill: FiltersState['selectedSkill']) => void,
  isLoaded: boolean,
];

export default function useSelectedSkill(
  desiredSkillSlug?: string, // might not be an actual skill slug
): UseSelectedSkill {
  const selectedSkill = useAppSelector(selectSelectedSkill);
  const dispatch = useAppDispatch();
  const [findManySkill, { loading, error }] = useSkillsLazyQuery();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { closestSkills } = useClosestSkills();

  const dispatchSelectedSkill = useCallback(
    (skill: FiltersState['selectedSkill']) => {
      dispatch(setSelectedSkill(skill));
    },
    [dispatch],
  );

  useEffect(() => {
    const slug = desiredSkillSlug || selectedSkill?.slug || DEFAULT_SKILL_SLUG;
    if (
      selectedSkill?.slug &&
      selectedSkill.slug === slug &&
      selectedSkill?.id
    ) {
      setIsLoaded(true);
      return;
    }
    if (loading || error) return;

    if (closestSkills?.length) {
      const foundSkill = closestSkills.find((skill) => skill.slug === slug);
      if (foundSkill) {
        dispatchSelectedSkill(foundSkill);
        setIsLoaded(true);
        return;
      }
    }

    findManySkill({
      variables: {
        where: {
          OR: [
            { slug: { equals: slug } },
            { slug: { equals: DEFAULT_SKILL_SLUG } },
          ],
        },
      },
    }).then((res) => {
      const foundSkill =
        res.data?.findManySkill.find((skill) => skill.slug === slug) ||
        res.data?.findManySkill.find(
          (skill) => skill.slug === DEFAULT_SKILL_SLUG,
        ) ||
        res.data?.findManySkill[0];
      if (!foundSkill) return;
      dispatchSelectedSkill(foundSkill);
      setIsLoaded(true);
    });
  }, [
    desiredSkillSlug,
    dispatchSelectedSkill,
    loading,
    error,
    findManySkill,
    selectedSkill?.id,
    selectedSkill?.slug,
    closestSkills,
  ]);

  return [selectedSkill, dispatchSelectedSkill, isLoaded];
}
