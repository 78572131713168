import { Box, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';

import { useAppSelector } from '../../../state/hooks';
import { selectAvailabilityFilter } from '../../../state/slices/filtersSlice';
import AvailabilityDatePicker from '../../AvailabilityFilterButton/components/AvailabilityDatePicker';
import AvailabilityTimePicker from '../../AvailabilityFilterButton/components/AvailabilityTimePicker';

type Props = {
  onSelectAnswer: (answer: string) => void;
};

export default function QuizDateTime({ onSelectAnswer }: Props) {
  const availabilityFilter = useAppSelector(selectAvailabilityFilter);

  useEffect(() => {
    onSelectAnswer(JSON.stringify(availabilityFilter));
  }, [availabilityFilter, onSelectAnswer]);

  return (
    <Box w="full">
      <VStack spacing="4" w="full">
        <AvailabilityTimePicker />
        <AvailabilityDatePicker onClose={() => {}} insideQuiz />
      </VStack>
    </Box>
  );
}
