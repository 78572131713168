const baseStyle = {
  textAlign: 'left',
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: 800,
  color: 'defaultText',
};

const variants = {
  h1: {
    fontSize: '50.52px',
    lineHeight: '130%',
    letterSpacing: '-0.02em',
  },
  h2: {
    fontSize: '37.9px',
    lineHeight: '130%',
    letterSpacing: '-0.015em',
  },
  h3: {
    lineHeight: '130%',
    fontSize: '1.777rem',
  },
  h4: {
    lineHeight: '130%',
    fontSize: '1.333rem',
  },
  h5: {
    lineHeight: '130%',
    fontSize: '1rem',
  },
  h6: {
    lineHeight: '130%',
    fontSize: '0.8rem',
  },
};

const heading = {
  baseStyle,
  variants,
};

export default heading;
