/* eslint-disable import/prefer-default-export */
import { Link, LinkProps } from '@chakra-ui/react';
import { forwardRef, ReactNode } from 'react';
import { Link as RRLink } from 'react-router-dom';

interface Props extends LinkProps {
  to: string;
  children: ReactNode;
}

export const RouterLink = forwardRef<typeof Link, Props>(
  ({ to, children, ...rest }: Props, ref) => (
    <Link
      as={RRLink}
      to={to}
      _hover={{ textDecoration: 'none' }}
      w="full"
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </Link>
  ),
);
