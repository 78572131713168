import { Icon } from '@chakra-ui/react';
import { HiCurrencyDollar } from 'react-icons/hi';

export default function LocationFeeIcon(): JSX.Element {
  return (
    <Icon
      as={HiCurrencyDollar}
      bg="white"
      borderRadius="full"
      color="#3182CE"
      fontSize={20}
    />
  );
}
