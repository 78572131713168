import { useLocalStorageValue } from '@react-hookz/web';
import { useEffect } from 'react';

import useCurrentLocation from './useCurrentLocation';

import { useClosestSkillsLazyQuery } from '../api';
import { FiltersState } from '../state/slices/filtersSlice';

export default function useClosestSkills() {
  const [closestSkills, setClosestSkills] = useLocalStorageValue<{
    skills: FiltersState['selectedSkill'][];
    latitude: number;
    longitude: number;
  }>('closestSkills', { skills: [], latitude: 0, longitude: 0 });
  const { currentLocation } = useCurrentLocation();

  const [getClosestSkills, { loading: loadingClosestSkills }] =
    useClosestSkillsLazyQuery({
      variables: {
        latitude: currentLocation?.latitude || 0,
        longitude: currentLocation?.longitude || 0,
        skillId: '',
      },
    });

  useEffect(() => {
    if (!currentLocation?.latitude) return;

    const isSameLocation =
      currentLocation?.latitude &&
      closestSkills.latitude === currentLocation.latitude &&
      closestSkills.longitude === currentLocation.longitude;
    if (isSameLocation) return;

    getClosestSkills()
      .then((res) => {
        setClosestSkills({
          skills: res.data?.getClosestSkills || [],
          latitude: currentLocation.latitude,
          longitude: currentLocation.longitude,
        });
      })
      .catch((err) => {
        console.error(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentLocation?.latitude,
    currentLocation?.longitude,
    closestSkills.latitude,
    closestSkills.longitude,
    setClosestSkills,
  ]);

  return { closestSkills: closestSkills.skills, loadingClosestSkills };
}
