import { Box, Checkbox, Heading, Link, Stack, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';

import { InputField } from '../../../../../components/InputField';
import PhoneNumberInput from '../../../../../components/PhoneNumberInput';
import useStudentInfoForm from '../hooks/useStudentInfoForm';

export default function CheckoutStudentInfo() {
  const {
    handleTermsChange,
    handleOnChange,
    getUserCountry,
    email,
    name,
    phoneNumber,
    isTermsChecked,
    country,
    setCountry,
    formValidation,
  } = useStudentInfoForm();

  useEffect(() => {
    getUserCountry();
  }, [getUserCountry]);

  return (
    <VStack mb="8" alignItems="flex-start" w="full">
      <Heading variant="h4" pb="6">
        Sign up to book
      </Heading>
      <Stack spacing="4" width="full">
        <InputField
          name="name"
          size="lg"
          borderRadius="lg"
          label="Name"
          autoComplete="name"
          defaultValue={name}
          placeholder="First and last"
          onChange={handleOnChange}
          error={name && formValidation.errors?.name}
        />
        <InputField
          name="email"
          size="lg"
          borderRadius="lg"
          label="Email"
          autoComplete="email"
          defaultValue={email}
          inputMode="email"
          placeholder="example@email.com"
          onChange={handleOnChange}
          error={email && formValidation.errors?.email}
        />
        <PhoneNumberInput
          country={country}
          setCountry={setCountry}
          onChange={(value) =>
            handleOnChange({ target: { name: 'phoneNumber', value } } as any)
          }
          label="Phone"
          error={phoneNumber && formValidation.errors?.phoneNumber}
        />
      </Stack>
      <Box paddingX={2} marginTop={6} alignSelf="center">
        <Checkbox isChecked={isTermsChecked} onChange={handleTermsChange}>
          I agree to the TeachMe.To{' '}
          <Link href="/terms" isExternal color="#3182CE">
            Terms of Service
          </Link>
        </Checkbox>
      </Box>
    </VStack>
  );
}
