import { add, isSameDay } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useGetMonthAvailabilityLazyQuery } from '../../../../../api';
import { SlotProps } from '../../../../../components/BookingWidget/Calendar/interfaces';
import { DayType } from '../../../../../components/DatePicker/Calendar/type';
import useDispatchDateTime from '../../../../../hooks/useDispatchDateTime';
import { useAppSelector } from '../../../../../state/hooks';
import { selectDateTime } from '../../../../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../../../utils/capturePostHogEvent';

export default function useCalendarProps() {
  const selectedListing = useAppSelector(selectSelectedListing);
  const dateTime = useAppSelector(selectDateTime);
  const { dispatchDateTime } = useDispatchDateTime();
  const [getSlots, { data: dataSlots }] = useGetMonthAvailabilityLazyQuery();
  const [monthAvailableSlots, setMonthAvailableSlots] = useState<SlotProps[]>(
    [],
  );

  const selectedDate: Date = useMemo(() => {
    if (dateTime) {
      return new Date(dateTime);
    }
    return add(new Date(), {
      days: 1,
    });
  }, [dateTime]);

  const handleDateChange = useCallback(
    (nextDate: Date) => {
      const newDateTime = dispatchDateTime(nextDate);
      capturePostHogEvent('clickDate', {
        nextDate: nextDate.toString(),
        newDateTime,
      });
    },
    [dispatchDateTime],
  );

  const getMonthAvailableSlots = useCallback(
    async (baseDate: Date) => {
      await getSlots({
        variables: {
          listingId: selectedListing?.id,
          date: baseDate.toISOString(),
        },
      });
    },
    [getSlots, selectedListing?.id],
  );

  const handleMonthChange = useCallback(
    async (nextDate: Date) => {
      if (!selectedListing?.id) return;
      await getMonthAvailableSlots(nextDate);
    },
    [selectedListing?.id, getMonthAvailableSlots],
  );

  const onDayTypeRulesFn = useCallback(
    (date: Date) => {
      const day = monthAvailableSlots?.find((slot: SlotProps) => {
        const [d, isAvailable] = Object.entries(slot)[0];
        return isSameDay(toDate(d), date) && isAvailable;
      });
      if (!day) return DayType.DISABLE;
      if (isSameDay(date, selectedDate)) return DayType.ACTIVE;
      return DayType.NORMAL;
    },
    [selectedDate, monthAvailableSlots],
  );

  useEffect(() => {
    if (!selectedDate || !selectedListing?.id) return;
    getMonthAvailableSlots(new Date(selectedDate));
  }, [selectedDate, getSlots, selectedListing?.id, getMonthAvailableSlots]);

  useEffect(() => {
    setMonthAvailableSlots(dataSlots?.getMonthSlots);
  }, [dataSlots?.getMonthSlots]);

  return {
    selectedDate,
    handleDateChange,
    handleMonthChange,
    onDayTypeRulesFn,
  };
}
