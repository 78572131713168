import { Box } from '@chakra-ui/react';
import React from 'react';

export default function CheckoutCard({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <Box
      borderRadius="24px"
      borderColor="border-default"
      borderWidth="1px"
      py="6"
      justifyContent="flex-start"
      alignItems="flex-start"
      mt="4"
      w={{ base: 'sm', sm: 'xs', md: 'sm', lg: 'md' }}
    >
      {children}
    </Box>
  );
}
