import { useToast } from '@chakra-ui/react';

export const useSuccess = () => {
  const toast = useToast();

  const handleSuccess = (message?: string) => {
    toast({
      title: 'Success',
      description: message,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  return {
    handleSuccess,
  };
};
