import { useAppSelector } from '../state/hooks';
import { selectSelectedSkill } from '../state/slices/filtersSlice';

type Props = {
  isPlural: boolean;
};

export default function useProTitle({ isPlural }: Props): string {
  const selectedSkill = useAppSelector(selectSelectedSkill);

  const proTitle =
    // eslint-disable-next-line no-nested-ternary
    selectedSkill?.category === 'music'
      ? isPlural
        ? 'teachers'
        : 'teacher'
      : isPlural
      ? 'coaches'
      : 'coach';

  return proTitle;
}
