import { Box, Flex, HStack, SkeletonText, Stack, Text } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PricePayload } from '../../api';
import useDiscounts from '../../hooks/useDiscounts';
import useListingSearchParams from '../../hooks/useListingSearchParams';
import usePickleheadsDiscount from '../../hooks/usePickleheadsDiscount';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  FiltersState,
  selectIsRecurring,
  selectPkg,
  setIsRecurring,
  setPkg,
} from '../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../state/slices/listingsSlice';
import capturePostHogEvent from '../../utils/capturePostHogEvent';
import { BookingStep } from '../../utils/types';
import { Show } from '../Show';

interface Props {
  isInteractive?: boolean;
  participants: number;
  packageSize: FiltersState['pkg'] | 0;
  price: PricePayload;
  isLoading: boolean;
  isTop?: boolean;
  isBottom?: boolean;
  isMiddle?: boolean;
  isExclusivePack?: boolean;
  onSelectPackage?: ({
    packageSize,
    isRecurring,
  }: {
    packageSize: number;
    isRecurring: boolean;
  }) => void;
}

export default function PackagePrice({
  isInteractive = true,
  participants,
  price,
  isLoading,
  packageSize,
  isTop = false,
  isBottom = false,
  isMiddle = false,
  isExclusivePack = false,
  onSelectPackage,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pkg = useAppSelector(selectPkg);
  const isRecurring = useAppSelector(selectIsRecurring);
  const selectedListing = useAppSelector(selectSelectedListing);
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const { isPickleheadsReferral } = usePickleheadsDiscount();
  const { totalPackDiscountAmount } = useDiscounts(price, packageSize);

  const cta = `${packageSize} ${packageSize === 1 ? 'lesson' : 'lessons'}`;
  const totalDiscount = totalPackDiscountAmount
    ? `SAVE $${totalPackDiscountAmount}`
    : null;

  const showTotalDiscount = !(isPickleheadsReferral && packageSize === 1);

  const handleSelectPackage = useCallback(() => {
    capturePostHogEvent('clickPack', {
      action: packageSize,
      selectedListingId: selectedListing?.id,
      selectedListingSkillSlug: selectedListing?.skill?.slug,
    });
    dispatch(setPkg(packageSize));
    dispatch(setIsRecurring(false));

    if (onSelectPackage) {
      onSelectPackage({ packageSize, isRecurring: false });
      return;
    }

    const listingSearchParams = getListingSearchParamsWithPartialInput({
      pkg: packageSize,
      isRecurring: false,
    });
    const listingUrl = `/listings/${selectedListing?.skill.slug}/${selectedListing?.slug}`;
    navigate(`${listingUrl}/${BookingStep.Packages}?${listingSearchParams}`, {
      replace: true,
    });
  }, [
    dispatch,
    getListingSearchParamsWithPartialInput,
    navigate,
    onSelectPackage,
    packageSize,
    selectedListing?.id,
    selectedListing?.skill.slug,
    selectedListing?.slug,
  ]);

  const renderVariantsRight = useCallback(
    () => (
      <Stack spacing="0" align="flex-end" flex="1">
        <SkeletonText noOfLines={1} isLoaded={!isLoading && !!price}>
          <Stack align="flex-end" justify="center" spacing="0">
            <HStack spacing="1">
              <Text textStyle="bold" fontSize="sm">
                ${Math.round(price?.pricePerLesson || 0)}
              </Text>
              <Text textStyle="normal" fontSize="sm">
                per lesson
              </Text>
            </HStack>
            <Show condition={participants > 1}>
              <Text textStyle="caption" fontWeight="normal" fontSize="sm">
                ${price?.pricePerLessonPerStudent} x {participants}
              </Text>
            </Show>
          </Stack>
        </SkeletonText>
      </Stack>
    ),
    [isLoading, participants, price],
  );

  const renderVariants = () => (
    <Flex w="full" h="full" align="center" justify="space-between">
      <Stack spacing="0" flex="1">
        <Text textStyle="bold">{cta}</Text>
        {showTotalDiscount && (
          <Text color="accent" fontSize="sm" textStyle="overlineBold">
            {totalDiscount}
          </Text>
        )}
      </Stack>
      {renderVariantsRight()}
    </Flex>
  );

  const isSelected = !isRecurring && isInteractive && packageSize === pkg;
  const hasBorderBottom = isExclusivePack ? false : isTop || isMiddle;
  const hasBorderTop = isExclusivePack ? false : isMiddle || isBottom;
  const boxShadow = `0px 0px 0px ${isSelected ? '3px' : '1px'} ${
    isSelected ? '#3182CE' : '#D9D9D9'
  } inset`;

  return (
    <Box
      px="4"
      height="20"
      borderRadius="2xl"
      cursor="pointer"
      _focus={{ bg: 'bg-muted' }}
      _active={{ bg: 'bg-muted' }}
      transition="all 150ms ease"
      bg={isSelected ? 'on-accent-muted' : 'transparent'}
      boxShadow={boxShadow} // inner border
      borderBottomRadius={hasBorderBottom ? 'none' : undefined}
      borderTopRadius={hasBorderTop ? 'none' : undefined}
      onClick={handleSelectPackage}
    >
      {renderVariants()}
    </Box>
  );
}
