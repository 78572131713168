import { Box, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import useLessonLength from '../../../../hooks/useLessonLength';
import useListingSearchParams from '../../../../hooks/useListingSearchParams';
import { useAppSelector } from '../../../../state/hooks';
import {
  selectIsRecurring,
  selectParticipants,
  selectPkg,
} from '../../../../state/slices/filtersSlice';
import capturePostHogEvent from '../../../../utils/capturePostHogEvent';
import { BookingStep } from '../../../../utils/types';

interface Props {
  listingUrl: string;
}

export default function NextScheduleAsYouGoButton({
  listingUrl,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const { lessonLength } = useLessonLength();
  const pkg = useAppSelector(selectPkg);
  const participants = useAppSelector(selectParticipants);
  const isRecurring = useAppSelector(selectIsRecurring);
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const onClickNext = () => {
    const listingSearchParams = getListingSearchParamsWithPartialInput({
      pkg,
      isRecurring,
      participants,
      lessonLength,
    });
    const nextUrlWithSearchParams = `${listingUrl}/${BookingStep.Confirm}?${listingSearchParams}`;

    capturePostHogEvent('clickChoosePack', { nextUrlWithSearchParams, pkg });
    navigate(nextUrlWithSearchParams, { replace: true });
  };

  return (
    <Box w="full">
      <Button
        size="xl"
        w="full"
        rounded="full"
        variant="primary"
        onClick={onClickNext}
      >
        Continue
      </Button>
    </Box>
  );
}
