import TagManager from 'react-gtm-module';

import { VITE_GTM_ID } from '../utils/constants';

const initGoogleTagManager = () => {
  const tagManagerArgs = {
    gtmId: VITE_GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
};

const capturePageView = () => {
  (window as any)?.dataLayer.push({
    event: 'pageview',
  });
};

type StartCheckoutParams = {
  ecommerce: {
    currencyCode: 'USD';
    value: string | number;
    add: {
      products: [
        {
          name: string;
          id: string;
          price: string | number;
          quantity: string | number;
        },
      ];
    };
  };
  user_data: {
    email: string;
    phone_number: string;
  };
};

const captureStartCheckout = (params?: StartCheckoutParams) => {
  (window as any)?.dataLayer.push({
    ...params,
    event: 'startCheckout',
  });
};

type CompleteCheckoutParams = {
  name?: string;
  email?: string;
  totalValue?: string | number;
  giftValue?: string | number;
  eventId?: string;
};

const captureCompleteCheckout = (params: CompleteCheckoutParams) => {
  (window as any)?.dataLayer.push({
    ...params,
    event: 'completeCheckout',
  });
};

const captureStartSession = () => {
  (window as any)?.dataLayer.push({
    event: 'startSession',
  });
};

type BecomeAProParams = {
  name: string;
  email: string;
  phoneNumber: string;
  skill?: string;
  city?: string;
  referrer?: string;
  tapfiliateReferralCode?: string;
  proReferralCode?: string;
  initialReferrer?: string;
};

const captureBecomeAPro = (params: BecomeAProParams) => {
  (window as any)?.dataLayer.push({
    ...params,
    event: 'becomeAPro',
  });
};

export {
  initGoogleTagManager,
  capturePageView,
  captureStartCheckout,
  captureCompleteCheckout,
  captureStartSession,
  captureBecomeAPro,
};
