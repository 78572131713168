import { Image, StackProps, Tag, VStack } from '@chakra-ui/react';

import { LocationAffiliateName } from '../../api';
import TopGolfIconWhite from '../../assets/top-golf/top-golf-icon-white.svg';
import { SelectedLocation } from '../../utils/types';

type Props = StackProps & {
  location: Pick<SelectedLocation, 'affiliateName' | 'LocationAffiliate'>;
};

export default function AffiliateTags({
  location,
  ...stackProps
}: Props): JSX.Element {
  const isTopGolfLocation =
    (location?.affiliateName || location?.LocationAffiliate?.name) ===
    LocationAffiliateName.TopGolf;

  if (!isTopGolfLocation) return null;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <VStack alignItems="flex-start" spacing={1} {...stackProps}>
      <Tag
        bg="#15212D"
        borderRadius="full"
        color="white"
        fontWeight="900"
        fontSize="11px"
        flexShrink="0"
      >
        <Image
          alt="Affiliate Logo"
          h="auto"
          mr="1"
          src={TopGolfIconWhite}
          w="18px"
        />
        RESERVATION INCLUDED
      </Tag>

      <Tag
        bg="#15212D"
        borderRadius="full"
        color="white"
        fontWeight="900"
        fontSize="11px"
        flexShrink="0"
      >
        <Image
          alt="Affiliate Logo"
          h="auto"
          mr="1"
          src={TopGolfIconWhite}
          w="18px"
        />
        SPECIAL RATES
      </Tag>
    </VStack>
  );
}
