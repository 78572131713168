import { Box, Flex } from '@chakra-ui/react';

interface Props {
  isDisabled?: boolean;
  isSelected: boolean;
}

export default function SelectedLocationRadio({
  isDisabled = false,
  isSelected,
}: Props) {
  return (
    <Flex
      alignItems="center"
      // eslint-disable-next-line no-nested-ternary
      bg={isSelected ? 'accent' : isDisabled ? 'gray.200' : 'transparent'}
      // eslint-disable-next-line no-nested-ternary
      borderColor={isSelected ? '#3182CE' : isDisabled ? 'gray.200' : '#CBD5E0'}
      borderRadius="full"
      borderWidth={2}
      flexShrink={0}
      height="20px"
      justifyContent="center"
      width="20px"
    >
      <Box
        bg={isSelected ? 'white' : 'transparent'}
        borderRadius="full"
        height="8px"
        width="8px"
      />
    </Flex>
  );
}
