/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../interfaces';

export enum ListingSort {
  Distance = 'Recommended',
  MostReviewed = 'Most reviewed',
  BestPrice = 'Best price',
}

export interface SortingState {
  listingSort: ListingSort;
}

const initialState: SortingState = {
  listingSort: ListingSort.Distance,
};

const slice = createSlice({
  name: 'sorting',
  initialState,
  reducers: {
    setListingSort: (
      state,
      action: PayloadAction<SortingState['listingSort']>,
    ) => {
      state.listingSort = action.payload || initialState.listingSort;
    },
  },
});

// export actions
export const { setListingSort } = slice.actions;

// export selectors
export const selectListingSort = (state: RootState) =>
  state.sorting.listingSort;

// export reducer
export default slice.reducer;
