import { useLocalStorageValue, useSessionStorageValue } from '@react-hookz/web';
import { useCallback, useEffect, useState } from 'react';

import useStudentReferral from './useStudentReferral';

import { useModal } from '../context/ModalContext';
import { useAppSelector } from '../state/hooks';
import { selectMainScrollPercent } from '../state/slices/appSlice';

export enum EmailCaptureExperimentGroup {
  Control = 'control',
  ShowModal = 'show-modal',
}

export const CAPTURE_EMAIL_SCROLL_PERCENT = 35;

export default function useEmailCaptureFlagExperiment() {
  const { handleOpen } = useModal();
  const { shouldShowReferralRewardModal, hasFreeLesson } = useStudentReferral();
  const [userSubmittedEmailCaptureForm] = useLocalStorageValue(
    'userSubmittedEmailCaptureForm',
    false,
  );
  const mainScrollPercent = useAppSelector(selectMainScrollPercent);

  const [userSawEmailCaptureModal, setUserSawEmailCaptureModal] =
    useSessionStorageValue('userSawEmailCaptureForm', false);

  const [shouldShowCaptureModal, setShouldShowCaptureModal] = useState(false);

  useEffect(() => {
    // User Already submitted / saw the modal for this session
    if (userSubmittedEmailCaptureForm || userSawEmailCaptureModal) {
      setShouldShowCaptureModal(false);
      return;
    }

    setShouldShowCaptureModal(true);
  }, [userSawEmailCaptureModal, userSubmittedEmailCaptureForm]);

  const setModalShown = useCallback(() => {
    setUserSawEmailCaptureModal(true);
    setShouldShowCaptureModal(false);
  }, [setUserSawEmailCaptureModal, setShouldShowCaptureModal]);

  const shownReferralRewardModal =
    shouldShowReferralRewardModal || hasFreeLesson;

  // Wait until screen is scrolled 10% to show capture modal
  useEffect(() => {
    if (
      shouldShowCaptureModal &&
      mainScrollPercent === CAPTURE_EMAIL_SCROLL_PERCENT &&
      !shownReferralRewardModal
    ) {
      handleOpen('emailCapture');
      setModalShown();
    }
  }, [
    handleOpen,
    mainScrollPercent,
    setModalShown,
    shouldShowCaptureModal,
    shownReferralRewardModal,
  ]);
}
