import { addDays, getDate, getMonth, getYear, startOfWeek } from 'date-fns';
import * as R from 'ramda';

import { DayType } from '../Calendar/type';

export type DateRulesFn = (date: Date) => DayType;

export default function dayListGenerator(
  year: number,
  month: number,
  dateTypeRuleFn: DateRulesFn,
): {
  date: Date;
  day: number;
  dayType: DayType;
}[] {
  const startDate = startOfWeek(new Date(year, month, 1));

  return R.pipe(
    R.times((index) => addDays(startDate, index)),
    R.map((date) => ({
      date,
      year: getYear(date),
      month: getMonth(date),
      day: getDate(date),
      dayType:
        R.equals(getMonth(date), month) && R.equals(getYear(date), year)
          ? dateTypeRuleFn(date)
          : DayType.NONE,
    })),
  )(37);
}
