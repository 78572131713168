import { Modal, ModalOverlay, ModalProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props extends ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  bg?: string;
}

export default function BaseModal({
  isOpen,
  onClose,
  children,
  bg = 'blackAlpha.600',
  // eslint-disable-next-line react/jsx-props-no-spreading
  ...rest
}: Props) {
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: 'full', md: '2xl' }}
      scrollBehavior="inside"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <ModalOverlay bg={bg} />

      {children}
    </Modal>
  );
}
