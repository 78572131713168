import { useEffect, useState } from 'react';

export default function useIsSafari() {
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    setIsSafari(navigator.vendor === 'Apple Computer, Inc.');
  }, []);

  return {
    isSafari,
  };
}
