import { useDisclosure } from '@chakra-ui/react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import BecomeAProResultModal from '../components/BecomeAProResultModal';
import ChatRequestModal from '../components/ChatRequestModal';
import CoachGuaranteeModal from '../components/CoachGuaranteeModal';
import EmailCaptureModal from '../components/EmailCaptureModal';
import LocationFeeModal from '../components/LocationFeeModal';
import LocationPermissionModal, {
  LocationPermissionHeading,
} from '../components/LocationPermissionModal/LocationPermissionModal';
import MembersPriceModal from '../components/MembersPriceModal';
import ProLocationsModal from '../components/ProLocationsModal';
import Quiz from '../components/Quiz/Quiz';
import ReferralRewardModal from '../components/ReferralRewardModal';
import RequestLocationModal from '../components/RequestLocationModal';
import RequestTimeModal from '../components/RequestTimeModal';
import RiskFreeModal from '../components/RiskFreeModal';
import ShareModal from '../components/ShareModal';
import StudentFavoriteModal from '../components/StudentFavoriteModal';
import TermsModal from '../components/TermsModal';
import { ModalType } from '../screens/Home/interfaces';
import capturePostHogEvent from '../utils/capturePostHogEvent';
import PosthogEvent from '../utils/posthogEvents';

interface IModalContext {
  isOpen: boolean;
  handleOpen: (
    type: ModalType,
    proName?: string,
    heading?: LocationPermissionHeading | string,
    disableNavigate?: boolean,
    params?: Record<string, any>,
  ) => void;
  handleClose: () => void;
}

export const ModalContext = createContext<IModalContext>({} as IModalContext);

export function ModalProvider({ children }: { children: JSX.Element }) {
  const [type, setType] = useState<ModalType>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [proName, setProName] = useState<string>('');
  const [params, setParams] = useState<Record<string, any>>({});
  const [heading, setHeading] = useState<LocationPermissionHeading>();
  const [disableNavigate, setDisableNavigate] = useState<boolean>(false);

  const handleOpen = useCallback(
    (
      incomingType: ModalType | null,
      name?: string,
      incomingHeading?: LocationPermissionHeading,
      incomingDisableNavigate?: boolean,
      customParams?: Record<string, any>,
    ) => {
      if (!incomingType) {
        return;
      }
      setType(incomingType);
      setProName(name);
      setHeading(incomingHeading);
      setDisableNavigate(incomingDisableNavigate || false);
      setParams(customParams || {});
      onOpen();
    },
    [onOpen],
  );

  const renderModal = () => {
    switch (type) {
      case 'locationFee':
        return <LocationFeeModal isOpen={isOpen} onClose={onClose} />;
      case 'locationPermission':
        return (
          <LocationPermissionModal
            disableNavigate={disableNavigate}
            heading={heading}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={() => capturePostHogEvent(PosthogEvent.RequestLocation)}
          />
        );
      case 'share':
        return <ShareModal isOpen={isOpen} onClose={onClose} />;
      case 'chatRequest':
        return (
          <ChatRequestModal
            heading={heading}
            isOpen={isOpen}
            onClose={onClose}
          />
        );
      case 'coachGuarantee':
        return (
          <CoachGuaranteeModal
            isOpen={isOpen}
            onClose={onClose}
            proName={proName}
          />
        );
      case 'requestTime':
        return (
          <RequestTimeModal
            isOpen={isOpen}
            onClose={onClose}
            proName={proName}
          />
        );
      case 'requestLocation':
        return <RequestLocationModal isOpen={isOpen} onClose={onClose} />;
      case 'becomeAProResultModal':
        return <BecomeAProResultModal isOpen={isOpen} onClose={onClose} />;
      case 'terms':
        return <TermsModal isOpen={isOpen} onClose={onClose} />;
      case 'proLocations':
        return <ProLocationsModal isOpen={isOpen} onClose={onClose} />;
      case 'emailCapture':
        return <EmailCaptureModal isOpen={isOpen} onClose={onClose} />;
      case 'referralRewardModal':
        return <ReferralRewardModal isOpen={isOpen} onClose={onClose} />;
      case 'getMatched':
        return <Quiz isOpen={isOpen} onClose={onClose} />;
      case 'riskFree':
        return <RiskFreeModal isOpen={isOpen} onClose={onClose} />;
      case 'membersPrice':
        return (
          <MembersPriceModal
            isOpen={isOpen}
            onClose={onClose}
            proName={proName}
            params={params}
          />
        );
      case 'studentFavorite':
        return <StudentFavoriteModal isOpen={isOpen} onClose={onClose} />;
      default:
        return null;
    }
  };

  const value = useMemo(() => {
    function handleClose(): void {
      setType(null);
      setHeading(null);
      setProName(null);
      setDisableNavigate(false);
      setParams({});
      onClose();
    }
    return { isOpen, handleOpen, handleClose, setDisableNavigate, setHeading };
  }, [isOpen, handleOpen, onClose]);

  return (
    <ModalContext.Provider value={value}>
      {renderModal()}
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => useContext(ModalContext);
