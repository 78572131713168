import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import { add, format } from 'date-fns';
import { useEffect, useState } from 'react';

import { Listing, PricePayload } from '../../../api';
import useLessonLength from '../../../hooks/useLessonLength';
import usePricing from '../../../hooks/usePricing';
import { useAppSelector } from '../../../state/hooks';
import { selectDateTime } from '../../../state/slices/filtersSlice';
import BookingWidgetBottomSheet from '../BookingWidgetSheet';

type Props = {
  listing: {
    id: Listing['id'];
    slug: Listing['slug'];
    skill?: Pick<Listing['skill'], 'slug'>;
  };
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isRecurring: boolean;
};
export default function ConfirmationSheet({
  isOpen,
  onClose,
  onConfirm,
  isRecurring,
  listing,
}: Props): JSX.Element {
  const [price, setPrice] = useState<PricePayload | null>();
  const { lessonLength } = useLessonLength();
  const { getSubscriptionPrice } = usePricing();
  const [sheetMode, setSheetMode] = useState<'confirmation' | 'reschedule'>(
    'confirmation',
  );
  const dateTime = useAppSelector(selectDateTime);

  useEffect(() => {
    if (!isRecurring || !listing?.id) return;
    getSubscriptionPrice({ listingId: listing.id }).then(setPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listing, isRecurring, setPrice]);

  if (!dateTime) return null;
  const lessonDate = new Date(dateTime);
  const formattedDate = format(lessonDate, 'iiii, MMMM d');
  const formattedRecurringDate = format(lessonDate, 'MMM dd, yyyy');
  const startTime = format(lessonDate, 'h:mm a');
  const dayOfWeek = format(lessonDate, 'EEEE');

  const endTimeDate = add(lessonDate, {
    minutes: lessonLength,
  });

  const endTime = format(endTimeDate, 'h:mm a');

  const startTimeWithoutMinutes = startTime.replace(':00', '');
  const endTimeWithoutMinutes = endTime.replace(':00', '');

  const timeRange = `from ${startTimeWithoutMinutes} - ${endTimeWithoutMinutes}`;

  const RescheduleContent = (
    <>
      <Box px="6" pb="2">
        <Text textStyle="bold" textAlign="center" mb="4">
          What if I need to reschedule?
        </Text>

        <Box w="full" p="4" mb="4" bg="on-accent-muted" borderRadius="2xl">
          <Text textStyle="small" color="blue.500" textAlign="center">
            It happens all the time! Don&apos;t worry... you won&apos;t lose
            that lesson as long as you reschedule at least 24 hours before your
            start time.
          </Text>
        </Box>
      </Box>
      <BookingWidgetBottomSheet.BottomBar>
        <Button
          variant="primary"
          size="xl"
          w="full"
          rounded="full"
          mt="0"
          mb="0"
          onClick={() => setSheetMode('confirmation')}
        >
          Got it!
        </Button>
      </BookingWidgetBottomSheet.BottomBar>
    </>
  );

  const ScheduleContent = (
    <Box px="6" pb="2">
      <Heading variant="h4" textAlign="center" mb="4">
        Your first lesson will be
      </Heading>

      <Box
        w="full"
        p="4"
        mb="4"
        bg="gray.50"
        borderRadius="2xl"
        borderWidth={1}
        borderColor="border-default"
      >
        <Text textStyle="overlineBold" textAlign="center" fontSize="13px">
          {`${formattedDate}`}
          <br />
          {`${timeRange}`}
        </Text>
      </Box>
      <Text textAlign="center" color="muted" my="6">
        If you purchase a lesson pack on the next page, you&apos;ll schedule
        your remaining lessons with your instructor as you go.
      </Text>
    </Box>
  );

  const RecurringContent = (
    <Box px="6" pb="2">
      <Heading variant="h4" textAlign="center" mb="6">
        Your lessons
      </Heading>

      <Box
        w="full"
        p="4"
        py="6"
        mb="6"
        bg="gray.50"
        borderRadius="2xl"
        borderWidth={1}
        borderColor="border-default"
      >
        <Text textStyle="overlineBold" color="muted">
          First Lesson
        </Text>
        <Text textStyle="body" textAlign="left">
          <b>{`${formattedRecurringDate}`}</b>
          <Text textStyle="body" as="span">{` ${timeRange}`}</Text>
        </Text>
        <Text textStyle="overlineBold" color="muted" mt="4">
          Frequency
        </Text>
        <Text textStyle="body" textAlign="left">
          <b>{`Every ${dayOfWeek}`}</b>
          <Text textStyle="body" as="span">{` ${timeRange}`}</Text>
        </Text>
        <Text textStyle="overlineBold" color="muted" mt="4">
          Pricing
        </Text>
        <Text textStyle="body" textAlign="left">
          <b>{`$${price?.pricePerLesson} per lesson `}</b>
          <Text textStyle="body" as="span">
            billed monthly
          </Text>
        </Text>
      </Box>
    </Box>
  );

  return (
    <BookingWidgetBottomSheet.Root isOpen={isOpen} onClose={onClose}>
      <BookingWidgetBottomSheet.Content>
        {sheetMode === 'reschedule' ? (
          RescheduleContent
        ) : (
          <>
            {isRecurring ? RecurringContent : ScheduleContent}
            <BookingWidgetBottomSheet.BottomBar>
              <VStack w="full" spacing="8" pb="4">
                <Button
                  variant="primary"
                  size="xl"
                  w="full"
                  rounded="full"
                  mt="0"
                  mb="0"
                  onClick={onConfirm}
                >
                  Sounds good!
                </Button>

                <Button
                  variant="link"
                  onClick={() => setSheetMode('reschedule')}
                  fontSize="16px"
                >
                  What if I need to reschedule?
                </Button>
              </VStack>
            </BookingWidgetBottomSheet.BottomBar>
          </>
        )}
      </BookingWidgetBottomSheet.Content>
    </BookingWidgetBottomSheet.Root>
  );
}
