import { isAfter, isBefore, isSameDay, set } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const isWithinAvailableHours = () => {
  // Get the current date/time in PST timezone
  const pacificTimezone = 'America/Los_Angeles';
  const currentDate = new Date();
  const currentTimePST = utcToZonedTime(currentDate, pacificTimezone);

  // Set the start and end times of the valid window in PST timezone
  const startOfDayPST = set(currentTimePST, {
    hours: 7,
    minutes: 0,
    seconds: 0,
  });
  const endOfDayPST = set(currentTimePST, {
    hours: 22,
    minutes: 0,
    seconds: 0,
  });

  // Check if the current time is within the valid window
  return (
    (isSameDay(currentTimePST, startOfDayPST) ||
      isAfter(currentTimePST, startOfDayPST)) &&
    (isSameDay(currentTimePST, endOfDayPST) ||
      isBefore(currentTimePST, endOfDayPST))
  );
};

export default isWithinAvailableHours;
