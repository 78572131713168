import { Flex, Skeleton } from '@chakra-ui/react';

type Props = {
  columnWidth: number;
  numOfColumns: number;
};

const NUM_OF_DISPLAYED_MEDIA = 2;
const SKELETON_ARRAY = [...Array(NUM_OF_DISPLAYED_MEDIA)].map(
  (_, index) => index,
);

export default function ListingDetailsMedia({
  columnWidth,
  numOfColumns,
}: Props): JSX.Element {
  return (
    <Flex
      flexDir={numOfColumns <= NUM_OF_DISPLAYED_MEDIA ? 'column' : 'row'}
      justifyContent="space-between"
      mt="6"
      pos="relative"
    >
      {SKELETON_ARRAY.map((skeletonIndex) => (
        <Skeleton
          key={skeletonIndex}
          h={columnWidth}
          w={columnWidth}
          borderRadius="16px"
        />
      ))}
    </Flex>
  );
}
