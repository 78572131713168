import { useLocalStorageValue } from "@react-hookz/web";

import { useAppSelector } from "../../../../../state/hooks";
import { selectSelectedListing } from "../../../../../state/slices/listingsSlice";
import capturePostHogEvent from "../../../../../utils/capturePostHogEvent";
import PosthogEvent from "../../../../../utils/posthogEvents";

export default function useNeedGear() {
    const selectedListing = useAppSelector(selectSelectedListing);
    const [gear, setGear] = useLocalStorageValue<string>('gear', 'no');

    const toggleNeedGear = (value: boolean) => {
        capturePostHogEvent(PosthogEvent.ClickedEditEquipment, {
            ...selectedListing,
        });

        setGear(value ? 'yes' : 'no');
    };

    return { gear, toggleNeedGear, needGear: Boolean(gear === "yes") };
}
