import { TagProps } from '@chakra-ui/react';
import { useMemo } from 'react';

import { FiltersState } from '../../state/slices/filtersSlice';
import GenericTag from '../GenericTag';

type Props = {
  badge: FiltersState['selectedBadges'][0];
  icon?: React.ReactNode;
  isSelected: boolean;
  onToggleBadge(badge: FiltersState['selectedBadges'][0]): void;
  w?: TagProps['w'];
  h?: TagProps['h'];
};

export default function QuizButton({
  badge,
  icon,
  isSelected,
  onToggleBadge,
  w,
  h = '48px',
}: Props): JSX.Element {
  const onClick = () => {
    onToggleBadge(badge);
  };

  // HARDCODED MAPPING
  const mappedBadgeName = useMemo(() => {
    switch (badge.name) {
      case 'Kids':
        return 'Kid (12 or under)';
      case 'Teenagers':
        return 'Teenager (13 to 17 years old)';
      case 'Adults':
        return 'Adult (18 to 64 years old)';
      case 'Seniors':
        return 'Senior (65 or older)';
      case 'Beginners':
        return 'Beginner';
      case 'Intermediates':
        return 'Intermediate';
      case 'Experts':
        return 'Expert';
      default:
        return badge.name;
    }
  }, [badge.name]);

  return (
    <GenericTag
      bgColor={isSelected ? undefined : 'inverted'}
      colorScheme={isSelected ? 'blue' : undefined}
      isActive={isSelected}
      label={mappedBadgeName}
      onClick={onClick}
      iconRight={icon}
      h={h}
      w={w}
      textStyle="overlineBold"
      fontSize="11px"
    />
  );
}
