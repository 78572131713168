import {
  Button,
  Heading,
  HStack,
  Skeleton,
  Switch,
  Text,
  useToken,
  VStack,
} from '@chakra-ui/react';
import { ComponentProps } from 'react';
import { HiMinus, HiPlus } from 'react-icons/hi';

type Props = {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  width?: ComponentProps<typeof VStack>['w'];
};

type CheckoutItemActionButtonProps = {
  label?: string;
  onPress: () => void;
  hasSelected?: boolean;
};

type CheckoutItemActionSwitchProps = {
  onChange: (value: boolean) => void;
  isChecked?: ComponentProps<typeof Switch>['isChecked'];
};

type CheckoutItemPlusMinusProps = {
  handleDecrement: () => void;
  handleIncrement: () => void;
  isPlusDisabled?: boolean;
  isMinusDisabled?: boolean;
};

function CheckoutItemActionButton({
  label = 'Choose',
  onPress,
  hasSelected = false,
}: Readonly<CheckoutItemActionButtonProps>) {
  return (
    <Button
      w="99px"
      h="37px"
      variant="outline"
      borderColor="border-default"
      size="lg"
      onClick={onPress}
      textAlign="center"
    >
      <Text
        textAlign="center"
        lineHeight="21px"
        fontSize="14px"
        fontWeight="800"
      >
        {hasSelected ? 'Edit' : label}
      </Text>
    </Button>
  );
}

function CheckoutItemActionSwitch({
  onChange,
  isChecked = false,
}: Readonly<CheckoutItemActionSwitchProps>) {
  return (
    <Switch
      onChange={(event) => onChange(event.target.checked)}
      isChecked={isChecked}
    />
  );
}

function CheckoutItemPlusMinus({
  handleDecrement,
  handleIncrement,
  isPlusDisabled = false,
  isMinusDisabled = false,
}: Readonly<CheckoutItemPlusMinusProps>) {
  const [gray300, gray700] = useToken('colors', ['gray.300', 'gray.700']);

  return (
    <HStack>
      <Button
        size="lg"
        variant="outline"
        onClick={handleDecrement}
        disabled={isMinusDisabled}
        borderColor="gray.300"
        padding={0}
      >
        <HiMinus color={isMinusDisabled ? gray300 : gray700} />
      </Button>
      <Button
        size="lg"
        variant="outline"
        onClick={handleIncrement}
        disabled={isPlusDisabled}
        borderColor="gray.300"
        padding={0}
      >
        <HiPlus color={isPlusDisabled ? gray300 : gray700} />
      </Button>
    </HStack>
  );
}

export const CheckoutItemAction = {
  Button: CheckoutItemActionButton,
  Switch: CheckoutItemActionSwitch,
  PlusMinus: CheckoutItemPlusMinus,
};

export default function CheckoutItem({
  title,
  subtitle,
  children,
  isLoading = false,
  width = 'full',
}: Readonly<Props>) {
  return (
    <HStack py="6" justifyContent="space-between" w={width} flex="1">
      <Skeleton isLoaded={!isLoading}>
        <VStack alignItems="flex-start">
          <Heading variant="h6">{title.toUpperCase()}</Heading>
          <Text color="slate.500">{subtitle}</Text>
        </VStack>
      </Skeleton>

      {children}
    </HStack>
  );
}
