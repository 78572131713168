import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { HiArrowRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { LocationAffiliateName } from '../../../api';
import LocationSlideItem from '../../../components/LocationSlideItem/LocationSlideItem';
import useIsMobile from '../../../hooks/useIsMobile';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import useSelectedLocation from '../../../hooks/useSelectedLocation';
import useSortedLocationsByDistance from '../../../hooks/useSortedLocationsByDistance';
import { useAppSelector } from '../../../state/hooks';
import { selectIsAtHome } from '../../../state/slices/filtersSlice';
import { selectIncludedAffiliateName } from '../../../state/slices/locationsSlice';
import { BookingStep, SelectedListing } from '../../../utils/types';
import getFirstName from '../../../utils/user';

type Props = {
  listing: SelectedListing;
  width: number;
  onChooseLocation?: () => void;
  hideAtHomeListItem?: boolean;
};

export default function ProLocationSlideItems({
  listing,
  width,
  onChooseLocation,
  hideAtHomeListItem = false,
}: Props): JSX.Element {
  const includedAffiliateName = useAppSelector(selectIncludedAffiliateName);
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const isAtHome = useAppSelector(selectIsAtHome);
  const [selectedLocation] = useSelectedLocation();
  const { sortedLocationsByDistance } = useSortedLocationsByDistance(
    listing?.locations,
  );
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const onChooseThisLocation = () => {
    if (onChooseLocation) {
      onChooseLocation();
      return;
    }
    const pathname = `/listings/${listing?.skill?.slug}/${listing?.slug}`;
    navigate(
      `${pathname}${
        isAtHome ? `/${BookingStep.Address}` : `/${BookingStep.Confirm}`
      }?${getListingSearchParamsWithPartialInput()}`,
    );
  };

  const isSubmitButtonEnabled = Boolean(
    (!isAtHome && selectedLocation) ||
      (isAtHome &&
        (listing?.travelAddressLatitude || listing?.travelAddressLongitude) &&
        listing?.isInServiceArea),
  );

  const includesTopGolfAffiliate =
    includedAffiliateName === LocationAffiliateName.TopGolf;
  const canTravelToYou =
    listing?.listingTravelAddressPoint?.id ||
    listing?.travelAddressLatitude ||
    listing?.travelAddressLongitude;

  // topgolf affiliates do not get at-home as an option
  const includeAtHomeAsOption = Boolean(
    !includesTopGolfAffiliate && canTravelToYou,
  );

  return isMobile ? (
    <Box zIndex={2}>
      <Box
        bg="white"
        borderColor="border-default"
        borderTopWidth={1}
        bottom="0"
        left="0"
        pos="absolute"
        right="0"
        sx={{
          '::-webkit-scrollbar': { display: 'none' },
          scrollbarWidth: 'none',
        }}
      >
        <HStack
          mt="6"
          overflow="scroll"
          px="6"
          pb="120px"
          spacing={4}
          sx={{
            '::-webkit-scrollbar': { display: 'none' },
            scrollbarWidth: 'none',
          }}
        >
          {sortedLocationsByDistance.map((location) => (
            <LocationSlideItem.NonAtHomeSlideItem
              key={location.id}
              location={location}
              shouldScrollIntoViewWhenSelected
            />
          ))}

          {includeAtHomeAsOption && !hideAtHomeListItem && (
            <LocationSlideItem.AtHomeSlideItem
              listing={listing}
              shouldScrollIntoViewWhenSelected
            />
          )}
        </HStack>
      </Box>

      <Box
        bg="white"
        borderColor="border-default"
        borderTopWidth={1}
        bottom="0"
        left="0"
        p="6"
        pos="absolute"
        right="0"
        w="full"
      >
        <Button
          isDisabled={!isSubmitButtonEnabled}
          onClick={onChooseThisLocation}
          rightIcon={<HiArrowRight />}
          size="lg"
          w="full"
        >
          Choose this location
        </Button>
      </Box>
    </Box>
  ) : (
    <Box
      bg="white"
      borderBottomLeftRadius="24px"
      borderTopLeftRadius="24px"
      boxShadow="-4px 0px 24px rgba(0, 0, 0, 0.04)"
      flexGrow={1}
      overflow="scroll"
      pos="relative"
      sx={{
        '::-webkit-scrollbar': { display: 'none' },
        scrollbarWidth: 'none',
      }}
      w={`${width}px`}
    >
      <Box
        bg="white"
        borderBottomWidth={1}
        borderColor="border-default"
        p="6"
        pos="sticky"
        top="0"
        zIndex={2}
      >
        <Text textAlign="center" textStyle="bold">
          Where {getFirstName(listing?.proName)} can teach
        </Text>
      </Box>

      <VStack mt="6" px="6" pb="6" spacing={4}>
        {sortedLocationsByDistance.map((location) => (
          <LocationSlideItem.NonAtHomeSlideItem
            key={location.id}
            location={location}
            shouldScrollIntoViewWhenSelected
          />
        ))}

        {includeAtHomeAsOption && !hideAtHomeListItem && (
          <LocationSlideItem.AtHomeSlideItem
            listing={listing}
            shouldScrollIntoViewWhenSelected
          />
        )}
      </VStack>

      <Box
        bg="white"
        borderColor="border-default"
        borderTopWidth={1}
        bottom="0"
        p="6"
        pos="sticky"
        right="0"
        zIndex={1}
      >
        <Button
          isDisabled={!isSubmitButtonEnabled}
          onClick={onChooseThisLocation}
          rightIcon={<HiArrowRight />}
          size="lg"
          w="full"
        >
          Choose this location
        </Button>
      </Box>
    </Box>
  );
}
