import { useAppDispatch, useAppSelector } from '../state/hooks';
import {
  FiltersState,
  selectLocalSelectedBadges,
  setLocalSelectedBadges,
  toggleLocalSelectedBadge,
} from '../state/slices/filtersSlice';

export default function useLocalSelectedBadges() {
  const localSelectedBadges = useAppSelector(selectLocalSelectedBadges);
  const dispatch = useAppDispatch();

  const dispatchLocalSelectedBadges = (
    badges: FiltersState['localSelectedBadges'],
  ) => {
    dispatch(setLocalSelectedBadges(badges));
  };

  const dispatchToggleLocalSelectedBadges = (
    badge: FiltersState['localSelectedBadges'][0],
  ) => {
    dispatch(toggleLocalSelectedBadge(badge));
  };

  return {
    localSelectedBadges,
    setLocalSelectedBadges: dispatchLocalSelectedBadges,
    toggleLocalSelectedBadge: dispatchToggleLocalSelectedBadges,
  };
}
