import { Button, Flex, Heading, Image, Text } from '@chakra-ui/react';

import logoShield from '../../../assets/logo-shield.png';

type Props = {
  onClose(): void;
  proName: string;
};

export default function CoachGuaranteeContent({
  onClose,
  proName,
}: Props): JSX.Element {
  return (
    <Flex
      alignItems="center"
      flexDir="column"
      justifyContent="center"
      p={{ base: '4', md: '8' }}
    >
      <Flex
        bg="brand.50"
        alignItems="center"
        borderRadius="full"
        h="100px"
        justifyContent="center"
        w="100px"
      >
        <Image h="56px" src={logoShield} w="56px" />
      </Flex>

      <Heading color="brand.500" mt="4" textAlign="center" variant="h4">
        Looking for a trial lesson?
      </Heading>

      <Text color="emphasized" mt="4" textAlign="center" textStyle="small">
        You&apos;re already getting one! If you don&apos;t love your first
        lesson, we&apos;ll find you another coach or refund the whole pack.
      </Text>

      <Button
        fontSize="14px"
        fontWeight="800"
        minW={168}
        minH={53}
        maxW="95dvw"
        mt="8"
        onClick={onClose}
        size="lg"
        textStyle="smallBold"
      >
        <Text overflow="hidden" textOverflow="ellipsis">
          Back to {proName}
        </Text>
      </Button>
    </Flex>
  );
}
