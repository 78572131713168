import { useBreakpoint } from '@chakra-ui/media-query';

import { useAppSelector } from '../state/hooks';
import { selectSelectedSkill } from '../state/slices/filtersSlice';
import type { ChakraBreakpoint } from '../utils/types';

/**
 * How far the map is zoomed in depends on how large the screen is
 */
export default function useInitialViewStateZoom(): {
  initialViewStateZoom: number;
} {
  const selectedSkill = useAppSelector(selectSelectedSkill);
  const breakpoint = useBreakpoint({
    fallback: 'base',
    ssr: false,
  }) as ChakraBreakpoint;

  const isMusicSkill = selectedSkill?.category === 'music';

  const initialViewStateZoom = (() => {
    switch (breakpoint) {
      case 'base':
      case '5xs':
      case '4xs':
      case '3xs':
      case '2xs':
      case 'xs':
      case 'sm':
      case 'md':
        return isMusicSkill ? 7.5 : 9.5;
      case 'lg':
        return isMusicSkill ? 8 : 10;
      case 'xl':
        return isMusicSkill ? 8.5 : 10.5;
      case '2xl':
      case '3xl':
        return isMusicSkill ? 9 : 11;
      default:
        return isMusicSkill ? 10 : 12;
    }
  })();

  return { initialViewStateZoom };
}
