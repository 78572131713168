import { Button, Text, useColorModeValue } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import useCurrentLocation from '../../hooks/useCurrentLocation';
import useProTitle from '../../hooks/useProTitle';
import type { ModalType } from '../../screens/Home/interfaces';
import capturePostHogEvent from '../../utils/capturePostHogEvent';

type Props = {
  disableNavigate?: boolean;
  handleOpen: (
    type: ModalType,
    proName?: string,
    heading?: string,
    disableNavigate?: boolean,
  ) => void;
};

export default function CoachesLocationButton({
  disableNavigate = false,
  handleOpen,
}: Props): JSX.Element {
  const params = useParams();
  const proTitle = useProTitle({ isPlural: true });
  const { currentLocation } = useCurrentLocation();

  const onClick = () => {
    capturePostHogEvent('clickHomeToggle', { action: 'location' });
    handleOpen('locationPermission', undefined, undefined, disableNavigate);
  };

  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const color = useColorModeValue('brand.600', 'brand.200');

  const isBrowse = window.location.pathname.startsWith('/browse/');

  return isBrowse && !params.city ? null : (
    <Button
      borderColor={borderColor}
      fontSize="14px"
      fontWeight="bold"
      iconSpacing="4"
      onClick={onClick}
      padding="5"
      size="sm"
      variant="outline"
    >
      Showing {proTitle} near&nbsp;
      <Text color={color} textDecoration="underline">
        {currentLocation?.postalCode ?? 'you'}
      </Text>
    </Button>
  );
}
