import {
  Button,
  Heading,
  HStack,
  Text,
  useToken,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { HiMinus, HiPlus } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useModal } from '../../../context/ModalContext';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import { useAppSelector } from '../../../state/hooks';
import {
  selectParticipants,
  selectSelectedSkill,
  setParticipants,
} from '../../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import PosthogEvent from '../../../utils/posthogEvents';
import { BookingStep } from '../../../utils/types';

export default function Participants() {
  const { handleOpen } = useModal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const participants = useAppSelector(selectParticipants);
  const skill = useAppSelector(selectSelectedSkill);
  const listing = useAppSelector(selectSelectedListing);
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const [gray300, gray700] = useToken('colors', ['gray.300', 'gray.700']);

  const listingUrl = `/listings/${listing?.skill?.slug}/${listing?.slug}`;

  const maxStudents = useMemo(
    () => listing?.maxStudents || skill?.maxStudents || 3,
    [skill?.maxStudents, listing?.maxStudents],
  );

  const label = useMemo(
    () => (participants === 1 ? '1 student' : `${participants} students`),
    [participants],
  );

  const handleParticipantsChange = useCallback(
    (value: number) => {
      capturePostHogEvent(PosthogEvent.ClickedEditParticipants, {
        ...listing,
      });

      dispatch(setParticipants(value));
      navigate(
        `${listingUrl}/${
          BookingStep.Confirm
        }?${getListingSearchParamsWithPartialInput({
          participants: value,
        })}`,
      );
    },
    [
      dispatch,
      navigate,
      listingUrl,
      getListingSearchParamsWithPartialInput,
      listing,
    ],
  );

  const handleDecrement = useCallback(() => {
    handleParticipantsChange(
      participants === 1 ? participants : participants - 1,
    );
  }, [handleParticipantsChange, participants]);

  const handleIncrement = useCallback(() => {
    if (participants >= maxStudents) {
      handleOpen(
        'chatRequest',
        null,
        `Request a lesson for ${maxStudents}+ people`,
      );
      return;
    }

    handleParticipantsChange(
      participants === maxStudents ? maxStudents : participants + 1,
    );
  }, [participants, maxStudents, handleParticipantsChange, handleOpen]);

  return (
    <VStack alignItems="flex-start" width="full" mt={6}>
      <HStack justifyContent="space-between" width="full">
        <VStack alignItems="flex-start" justifyContent="center">
          <Heading variant="h6">PARTICIPANTS</Heading>
          <Text color="slate.500">{label}</Text>
        </VStack>
        <HStack>
          <Button
            size="lg"
            variant="outline"
            onClick={handleDecrement}
            disabled={participants === 1}
            borderColor="gray.300"
            padding={0}
          >
            <HiMinus color={participants === 1 ? gray300 : gray700} />
          </Button>
          <Button
            size="lg"
            variant="outline"
            onClick={handleIncrement}
            disabled={participants === maxStudents + 1}
            borderColor="gray.300"
            padding={0}
          >
            <HiPlus
              color={participants === maxStudents + 1 ? gray300 : gray700}
            />
          </Button>
        </HStack>
      </HStack>
    </VStack>
  );
}
