import { Flex, Heading, TagLabel, Text } from '@chakra-ui/react';

import LocationFeeIcon from '../../LocationFeeIcon';
import FeeTag from '../../LocationFeeTag/components/FeeTag';

export default function LocationFeeModalContent(): JSX.Element {
  return (
    <Flex
      alignItems="center"
      flexDir="column"
      justifyContent="center"
      py={{ base: '4', md: '8' }}
    >
      <FeeTag isSelected={false}>
        <LocationFeeIcon />
        <TagLabel color="#718096" textStyle="overlineBold" userSelect="none">
          &nbsp;RESERVATION FEE
        </TagLabel>
      </FeeTag>

      <Heading as="h3" my="4" textAlign="center" variant="h3">
        What are reservation fees?
      </Heading>

      <Text color="#718096" mt="4" textAlign="center" textStyle="body">
        Some locations require a reservation fee set by the owner of the venue.
        This will be added to your total at checkout — we&apos;ll take care of
        the rest!
      </Text>
    </Flex>
  );
}
