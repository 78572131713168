import { Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

import useListingSearchParams from '../../../hooks/useListingSearchParams';
import { useAppSelector } from '../../../state/hooks';
import {
  selectIsRecurring,
  selectPkg,
} from '../../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import PosthogEvent from '../../../utils/posthogEvents';
import { BookingStep } from '../../../utils/types';

export default function NumberOfLessons() {
  const pkg = useAppSelector(selectPkg);
  const isRecurring = useAppSelector(selectIsRecurring);
  const selectedListing = useAppSelector(selectSelectedListing);

  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();
  const navigate = useNavigate();
  const params = useParams();

  const listingUrl = `/listings/${selectedListing?.skill?.slug}/${
    selectedListing?.slug || params?.slug
  }`;

  const handleEdit = () => {
    capturePostHogEvent(PosthogEvent.ClickedEditNumberOfLessons, {
      ...selectedListing,
      isRecurring,
      pkg,
    });

    navigate(
      `${listingUrl}/${
        BookingStep.Packages
      }?${getListingSearchParamsWithPartialInput()}`,
    );
  };

  return (
    <VStack alignItems="flex-start" width="full" mt={6}>
      <HStack justifyContent="space-between" width="full">
        <VStack alignItems="flex-start" justifyContent="center">
          <Heading variant="h6">NUMBER OF LESSONS</Heading>
          <Text color="slate.500">
            {isRecurring
              ? 'Weekly recurring'
              : `${pkg} lesson${pkg > 1 ? 's' : ''}`}
          </Text>
        </VStack>
        <HStack>
          <Text
            _hover={{ cursor: 'pointer' }}
            textStyle="link"
            color="slate.500"
            onClick={handleEdit}
          >
            Edit
          </Text>
        </HStack>
      </HStack>
    </VStack>
  );
}
