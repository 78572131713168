import { Heading, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const AnimatedHeading = motion(Heading);
const AnimatedText = motion(Text);

export default function EmojiLoading({
  emoji,
  title,
  subtitle,
}: {
  emoji: string;
  title: string;
  subtitle?: string;
}) {
  return (
    <>
      <AnimatedHeading
        variant="h2"
        textAlign="center"
        // anim
        initial={{ translateY: 40, opacity: 0 }}
        animate={{ translateY: 0, opacity: 1 }}
        transition={{
          duration: 0.3,
        }}
        key={emoji}
      >
        {emoji}
      </AnimatedHeading>
      <AnimatedHeading
        variant="h3"
        textAlign="center"
        px="8"
        // anim
        initial={{ translateY: 20, opacity: 0 }}
        animate={{ translateY: 0, opacity: 1 }}
        transition={{
          delay: 0.2,
          duration: 0.3,
        }}
        key={title}
      >
        {title}
      </AnimatedHeading>
      {subtitle && (
        <AnimatedText
          textAlign="center"
          mt="2"
          px="8"
          // anim
          initial={{ translateY: 20, opacity: 0 }}
          animate={{ translateY: 0, opacity: 1 }}
          transition={{
            delay: 0.2,
            duration: 0.3,
          }}
          key={subtitle}
        >
          {subtitle}
        </AnimatedText>
      )}
    </>
  );
}
