import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Skeleton,
  SkeletonText,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

import ListingContentSingleColumn from './components/ListingContentSingleColumn';
import ListingDetailsMedia from './components/ListingDetailsMedia';

import LogoIcon from '../../components/LogoIcon';
import TMTAvatar from '../../components/TMTAvatar';
import useBookingWidgetHeight from '../../hooks/useBookingWidgetHeight';
import useIsMobile from '../../hooks/useIsMobile';
import useViewportWidth from '../../hooks/useViewportWidth';

export default function ListingDetailsSkeleton(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();
  const { isMobile } = useIsMobile();
  const { viewportWidth } = useViewportWidth();
  const { bookingWidgetHeight } = useBookingWidgetHeight();

  const headerHeight = isMobile ? 0 : 104;
  const gap = 24;
  const pageMargin = 16;
  const maxContentWidth = Math.min(
    1248,
    isMobile ? viewportWidth : viewportWidth - pageMargin * 2,
  );

  const maxColumnWidth = Math.min(400, maxContentWidth);
  // eslint-disable-next-line no-nested-ternary
  const numOfColumns = isMobile
    ? 1
    : // eslint-disable-next-line no-nested-ternary
    maxContentWidth < maxColumnWidth * 2 + gap
    ? 1
    : maxContentWidth < maxColumnWidth * 3 + gap * 2
    ? 2
    : 3;

  const columnWidth =
    (maxContentWidth - (numOfColumns - 1) * gap) / numOfColumns;
  const contentWidth = numOfColumns * columnWidth + (numOfColumns - 1) * gap;
  const isInBookingStep = !!params.step;
  const isSingleColumn = numOfColumns === 1;

  return (
    <Flex
      align="center"
      bg="bg-surface"
      className="full"
      direction="column"
      flex="1"
      h="100dvh"
      mx="auto"
      w="full"
    >
      {/* Header */}

      {isMobile ? null : (
        <Flex
          alignItems="center"
          h="104px"
          justifyContent="space-between"
          w={`${contentWidth}px`}
          position="relative"
        >
          <Skeleton h="40px" rounded="full" w="177px">
            Back to coaches X
          </Skeleton>

          <Stack direction="row" align="center" onClick={() => navigate('/')}>
            <LogoIcon size="xl" />
            <Heading variant="h5" size="sm">
              TeachMe.To
            </Heading>
          </Stack>

          <HStack spacing="2">
            <Skeleton h="40px" rounded="full">
              Risk-free trial lesson XX
            </Skeleton>

            <Skeleton h="40px" rounded="full" px="6" />

            <Skeleton h="40px" rounded="full" px="6" />
          </HStack>
        </Flex>
      )}

      {/* Content grid */}
      <Grid
        columnGap={`${gap}px`}
        templateColumns={`repeat(${numOfColumns}, 1fr)`}
        h={`calc(100dvh - ${headerHeight}px)`}
        w={`${viewportWidth}px`}
        px={isMobile ? 0 : `${(viewportWidth - contentWidth) / 2}px`}
        overflowY="scroll"
      >
        {/* Listing content */}
        {
          // eslint-disable-next-line no-nested-ternary
          isInBookingStep && numOfColumns === 1 ? null : numOfColumns <= 2 ? (
            <ListingContentSingleColumn
              columnWidth={columnWidth}
              numOfColumns={numOfColumns}
              pageMargin={pageMargin}
            />
          ) : (
            <GridItem
              colSpan={isSingleColumn ? undefined : numOfColumns - 1}
              w={isSingleColumn ? contentWidth : undefined}
              pb="8"
            >
              <Flex
                alignItems="center"
                flexDir="row"
                justifyContent="space-between"
              >
                <Flex
                  alignItems="flex-start"
                  flexDir="column"
                  justifyContent="center"
                  mr="6"
                >
                  <Skeleton minW="300px">Pickleball lessons with ..</Skeleton>

                  <SkeletonText noOfLines={1} mt="2" minW="300px" />
                </Flex>

                <Skeleton h="53px" w="241px" rounded="full" px="6">
                  Ask a question
                </Skeleton>
              </Flex>

              <ListingDetailsMedia
                columnWidth={columnWidth}
                numOfColumns={numOfColumns}
              />

              <Box mt="8" mb={80}>
                <Flex
                  alignItems="flex-start"
                  flexDir="row"
                  justifyContent="space-between"
                  mt="2"
                  overflowX="hidden"
                >
                  <SkeletonText w="full" noOfLines={3}>
                    About me
                  </SkeletonText>

                  <TMTAvatar ml="6" h={72} w={72} />
                </Flex>
              </Box>

              <Divider my="8" orientation="horizontal" />
            </GridItem>
          )
        }

        {/* Booking widget */}
        {(isInBookingStep || !isMobile || numOfColumns > 1) && (
          <GridItem
            colSpan={1}
            h={`calc(100dvh - ${headerHeight}px)`}
            overflowX="hidden"
            position="sticky"
            sx={{
              '::-webkit-scrollbar': { display: 'none' },
              scrollbarWidth: 'none',
            }}
            top="90px"
          >
            <VStack
              borderColor="border-default"
              borderRadius="16px"
              borderWidth={isMobile ? 0 : 1}
              h={bookingWidgetHeight}
              overflowY="scroll"
            />
          </GridItem>
        )}
      </Grid>
    </Flex>
  );
}
