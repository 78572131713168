import { useAppDispatch, useAppSelector } from '../state/hooks';
import {
  LocationsState,
  selectSearchBoundingBox,
  setSearchBoundingBox,
} from '../state/slices/locationsSlice';

export default function useSearchBoundingBox() {
  const searchBoundingBox = useAppSelector(selectSearchBoundingBox);
  const dispatch = useAppDispatch();

  const dispatchSearchBoundingBox = (
    boundingBox: LocationsState['searchBoundingBox'],
  ) => {
    dispatch(setSearchBoundingBox(boundingBox));
  };

  return {
    searchBoundingBox,
    setSearchBoundingBox: dispatchSearchBoundingBox,
  };
}
