import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuestions } from './Questions';
import QuizContext from './QuizContext';

import useSelectedSkill from '../../hooks/useSelectedSkill';
import {
  BASEBALL_SKILL_SLUG,
  BASKETBALL_SKILL_SLUG,
  BOXING_SKILL_SLUG,
  DANCE_SKILL_SLUG,
  DEFAULT_SKILL_SLUG,
  FOOTBALL_SKILL_SLUG,
  GOLF_SKILL_SLUG,
  GUITAR_SKILL_SLUG,
  PERSONAL_TRAINING_SKILL_SLUG,
  PIANO_SKILL_SLUG,
  SINGING_SKILL_SLUG,
  SURFING_SKILL_SLUG,
  TENNIS_SKILL_SLUG,
  VIOLIN_SKILL_SLUG,
  YOGA_SKILL_SLUG,
} from '../../utils/constants';

function QuizProvider({ children }: { children: React.ReactNode }) {
  const [selectedSkill] = useSelectedSkill();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const allQuestions = useQuestions();

  const questions = useMemo(() => {
    const basicQuestions = [allQuestions.SkillLevel, allQuestions.Ages];

    const defaultMusicQuestions = [
      allQuestions.Location,
      ...basicQuestions,
      allQuestions.Equipment_Music,
      allQuestions.DateAndTime,
      allQuestions.Experience,
    ];

    const defaultSportsQuestions = [
      ...basicQuestions,
      allQuestions.Equipment_Sports,
      allQuestions.Location,
      allQuestions.DateAndTime,
      allQuestions.Experience,
    ];

    let skillQuestions = [];
    switch (selectedSkill?.slug) {
      case DANCE_SKILL_SLUG:
        skillQuestions = [
          allQuestions.Location,
          ...basicQuestions,
          allQuestions.DateAndTime,
          allQuestions.Experience,
        ];
        break;
      case GOLF_SKILL_SLUG:
        skillQuestions = [
          ...basicQuestions,
          // ?? Equipment
          allQuestions.Location,
          allQuestions.DateAndTime,
          allQuestions.Experience,
        ];
        break;
      case GUITAR_SKILL_SLUG:
        skillQuestions = defaultMusicQuestions;
        break;
      case PERSONAL_TRAINING_SKILL_SLUG:
        skillQuestions = [
          ...basicQuestions,
          allQuestions.Location,
          allQuestions.DateAndTime,
          allQuestions.Experience,
        ];
        break;
      case PIANO_SKILL_SLUG:
        skillQuestions = [
          allQuestions.Location,
          ...basicQuestions,

          allQuestions.DateAndTime,
          allQuestions.Experience,
        ];
        break;
      case SINGING_SKILL_SLUG:
        skillQuestions = [
          allQuestions.Location,
          ...basicQuestions,
          allQuestions.DateAndTime,
          allQuestions.Experience,
        ];
        break;
      case SURFING_SKILL_SLUG:
        skillQuestions = [
          ...basicQuestions,
          allQuestions.Equipment_Sports,
          allQuestions.DateAndTime,
          allQuestions.Experience,
        ];
        break;
      case VIOLIN_SKILL_SLUG:
        skillQuestions = defaultMusicQuestions;
        break;
      case YOGA_SKILL_SLUG:
        skillQuestions = [
          ...basicQuestions,
          allQuestions.Equipment_Sports,
          allQuestions.Location,
          allQuestions.DateAndTime,
          allQuestions.Experience,
        ];
        break;
      case BASEBALL_SKILL_SLUG:
      case BASKETBALL_SKILL_SLUG:
      case BOXING_SKILL_SLUG:
      case FOOTBALL_SKILL_SLUG:
      case TENNIS_SKILL_SLUG:
      case DEFAULT_SKILL_SLUG:
        skillQuestions = defaultSportsQuestions;
        break;
      default:
        skillQuestions = defaultSportsQuestions;
    }

    skillQuestions.push(allQuestions.Finish);
    return skillQuestions;
  }, [selectedSkill?.slug, allQuestions]);

  const totalQuestions = questions.length;

  const goToNextQuestion = useCallback(() => {
    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  }, [currentQuestionIndex, totalQuestions]);

  const goToPreviousQuestion = useCallback(() => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  }, [currentQuestionIndex]);

  const currentQuestion = questions[currentQuestionIndex];
  const hasNextStep = currentQuestionIndex < totalQuestions - 1;

  const hasPreviousStep = currentQuestionIndex > 0;

  const progressStep = currentQuestionIndex + 1;

  const isLastQuestion = currentQuestionIndex === totalQuestions - 2;

  const resetQuiz = useCallback(() => {
    setCurrentQuestionIndex(0);
  }, []);

  useEffect(() => {
    if (selectedSkill) {
      resetQuiz();
    }
  }, [selectedSkill, resetQuiz]);

  const value = useMemo(
    () => ({
      currentQuestion,
      goToNextQuestion,
      goToPreviousQuestion,
      hasNextStep,
      hasPreviousStep,
      totalSteps: totalQuestions - 1,
      progressStep,
      isLastQuestion,
      resetQuiz,
    }),
    [
      currentQuestion,
      goToNextQuestion,
      goToPreviousQuestion,
      progressStep,
      totalQuestions,
      hasNextStep,
      hasPreviousStep,
      isLastQuestion,
      resetQuiz,
    ],
  );

  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
}

export default QuizProvider;
