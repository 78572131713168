import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useModal } from '../../../../../context/ModalContext';
import { useAppSelector } from '../../../../../state/hooks';
import {
  selectParticipants,
  selectSelectedSkill,
  setParticipants,
} from '../../../../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../../../utils/capturePostHogEvent';
import PosthogEvent from '../../../../../utils/posthogEvents';
import CheckoutItem, { CheckoutItemAction } from '../../CheckoutItem';

export default function CheckoutParticipants() {
  const skill = useAppSelector(selectSelectedSkill);
  const listing = useAppSelector(selectSelectedListing);
  const participants = useAppSelector(selectParticipants);
  const label = useMemo(
    () => (participants === 1 ? '1 student' : `${participants} students`),
    [participants],
  );
  const dispatch = useDispatch();
  const { handleOpen } = useModal();

  const maxStudents = useMemo(
    () => listing?.maxStudents || skill?.maxStudents || 3,
    [skill?.maxStudents, listing?.maxStudents],
  );

  const handleParticipantsChange = useCallback(
    (value: number) => {
      capturePostHogEvent(PosthogEvent.ClickedEditParticipants, {
        ...listing,
      });

      dispatch(setParticipants(value));
    },
    [dispatch, listing],
  );

  const handleDecrement = useCallback(() => {
    handleParticipantsChange(
      participants === 1 ? participants : participants - 1,
    );
  }, [handleParticipantsChange, participants]);

  const handleIncrement = useCallback(() => {
    if (participants >= maxStudents) {
      handleOpen(
        'chatRequest',
        null,
        `Request a lesson for ${maxStudents}+ people`,
      );
      return;
    }

    handleParticipantsChange(
      participants === maxStudents ? maxStudents : participants + 1,
    );
  }, [participants, maxStudents, handleParticipantsChange, handleOpen]);

  return (
    <CheckoutItem
      title="Participants"
      subtitle={label}
      width={{ md: 'full', lg: '250px' }}
    >
      <CheckoutItemAction.PlusMinus
        isMinusDisabled={participants === 1}
        isPlusDisabled={participants === maxStudents + 1}
        handleDecrement={handleDecrement}
        handleIncrement={handleIncrement}
      />
    </CheckoutItem>
  );
}
