import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { format, set } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { useCallback, useEffect, useMemo } from 'react';
import { HiOutlineX } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { DateAvailability } from '../../../../../api';
import DateTimePicker from '../../../../../components/DatePicker/DateTimePicker';
import ExposedTime from '../../../../../components/ExposedTime';
import { Show } from '../../../../../components/Show';
import useDispatchDateTime from '../../../../../hooks/useDispatchDateTime';
import useLessonLength from '../../../../../hooks/useLessonLength';
import useListingSearchParams from '../../../../../hooks/useListingSearchParams';
import { useAppSelector } from '../../../../../state/hooks';
import { selectIsRecurring } from '../../../../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../../../utils/capturePostHogEvent';
import useAvailabilitySlots from '../hooks/useAvailabilitySlots';
import useCalendarProps from '../hooks/useCalendarProps';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function ChooseScheduleModal({ isOpen, onClose }: Props) {
  const selectedListing = useAppSelector(selectSelectedListing);
  const isRecurring = useAppSelector(selectIsRecurring);
  const { lessonLength } = useLessonLength();
  const navigate = useNavigate();

  const { dispatchDateTime, resetDateTime } = useDispatchDateTime();
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const {
    getAvailabilityByListingId,
    getAvailableSlotsByDay,
    error,
    isLoading,
    setIsLoading,
  } = useAvailabilitySlots();

  const {
    selectedDate,
    handleDateChange,
    handleMonthChange,
    onDayTypeRulesFn,
  } = useCalendarProps();

  useEffect(() => {
    capturePostHogEvent('viewExposedTimes');
  }, []);

  useEffect(() => {
    if (!selectedListing?.id) return;
    setIsLoading(true);
    getAvailabilityByListingId({
      variables: {
        listingId: selectedListing?.id,
        date: selectedDate.toISOString(),
        lessonLength,
      },
    });
  }, [
    getAvailabilityByListingId,
    lessonLength,
    selectedDate,
    selectedListing?.id,
    setIsLoading,
  ]);

  const renderHeaderComponent = useCallback(
    () => (
      <Show condition={isRecurring}>
        <Box borderRadius="md" backgroundColor="purple.100" py="1" px="4">
          <Text textStyle="smallBold" textColor="purple.600" textAlign="center">
            Lessons will be repeated weekly on the chosen day and time.
          </Text>
        </Box>
      </Show>
    ),
    [isRecurring],
  );

  const handleSelect = useCallback(
    async (nextDate?: Date) => {
      const newDateTime = dispatchDateTime(nextDate);
      capturePostHogEvent('clickDate', {
        nextDate: nextDate.toString(),
        newDateTime,
      });

      navigate(
        `/checkout/${selectedListing?.skill?.slug}/${
          selectedListing?.slug
        }/?${getListingSearchParamsWithPartialInput({
          dateTime: newDateTime,
        })}`,
      );
      onClose();
    },
    [
      dispatchDateTime,
      getListingSearchParamsWithPartialInput,
      navigate,
      onClose,
      selectedListing?.skill?.slug,
      selectedListing?.slug,
    ],
  );

  const renderItem = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ item }: { item: DateAvailability }) => {
      const { date, availability } = item;

      if (!date) {
        return null;
      }
      const heading = format(toDate(date), 'EEEE, MMMM d');
      if (
        !availability?.length ||
        availability?.every((slot) => !slot.available)
      ) {
        return (
          <Box bg="bg-muted" py="6" px="6" borderRadius="16px" my="2" w="full">
            <Stack direction="row" align="center" justify="space-between">
              <Text textStyle="smallBold" color="muted">
                {heading}
              </Text>
              <Text textStyle="overline" color="muted">
                Fully booked
              </Text>
            </Stack>
          </Box>
        );
      }
      return (
        <Stack
          borderColor="#CBD5E0"
          borderRadius="16px"
          borderWidth={1}
          w="full"
          overflow="scroll"
          sx={{
            '::-webkit-scrollbar': {
              display: 'none',
            },
            'scrollbar-width': 'none',
          }}
        >
          <Stack
            align="center"
            borderBottomColor="gray.300"
            borderBottomWidth={1}
            direction="row"
            justify="center"
            pt="3"
            pb="2"
            px="6"
            bgColor="#EDF2F7"
            borderTopLeftRadius="16px"
            borderTopRightRadius="16px"
          >
            <Text textStyle="smallBold" color="#718096">
              {heading.toUpperCase()}
            </Text>
          </Stack>

          {availability?.map((slot, index, arr) => {
            const [hours, minutes] = slot.time.split(':');
            const startAt = set(toDate(date), {
              hours: parseInt(hours, 10),
              minutes: parseInt(minutes, 10),
            });

            return (
              <ExposedTime
                hasDivider={index < arr.length - 1}
                isDisabled={!slot?.available}
                isLoading={false}
                key={+index}
                onClick={() => handleSelect(startAt)}
                time={format(startAt, 'h:mm a')}
              />
            );
          })}
        </Stack>
      );
    },
    [handleSelect],
  );

  // available slots by day
  const availableSlotsByDay = useMemo(
    () => getAvailableSlotsByDay(selectedDate),
    [getAvailableSlotsByDay, selectedDate],
  );

  return (
    <Modal
      isCentered
      scrollBehavior="inside"
      motionPreset="slideInBottom"
      onOverlayClick={onClose}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        position="relative"
        borderRadius="24px"
        h="620px"
        minH="620px"
        maxW="5xl"
        p="8"
      >
        <Box
          as="nav"
          borderRadius="full"
          position="absolute"
          top="6"
          right="6"
          bg="bg-surface"
          zIndex="1"
          borderColor="border-default"
        >
          <IconButton
            isRound
            variant="ghost"
            aria-label="Back"
            size="lg"
            onClick={onClose}
            icon={<HiOutlineX color="#2D3748" size="24px" />}
          />
        </Box>

        <HStack alignItems="center">
          <Heading variant="h4">Schedule your first lesson</Heading>
          <Box ml="4" w="max">
            {renderHeaderComponent()}
          </Box>
        </HStack>

        <Flex
          flexDir="row"
          flexGrow={1}
          w="full"
          overflow="hidden"
          columnGap="4"
          mt="4"
        >
          <Box
            h="auto"
            w="full"
            display="flex"
            p="1"
            pb="4"
            borderColor="border-default"
            borderRadius="16px"
            borderWidth="1px"
          >
            <DateTimePicker
              selectedDate={selectedDate}
              onSetDate={handleDateChange}
              handleMonthChange={handleMonthChange}
              onDayTypeRulesFn={onDayTypeRulesFn}
            />
          </Box>
          <VStack h="auto" w="full" display="flex">
            <Show condition={isLoading}>
              <Stack h="90dvh" align="center" justify="center">
                {error ? (
                  <>
                    <Text textAlign="center">Error loading schedule.</Text>
                    <Text textAlign="center">
                      Please try again later or contact customer support for
                      help.
                    </Text>
                  </>
                ) : (
                  <>
                    <Spinner size="lg" />
                    <Text textAlign="center">Loading schedule...</Text>
                  </>
                )}
              </Stack>
            </Show>

            <Show
              condition={
                !isLoading && availableSlotsByDay?.availability.length > 0
              }
            >
              {availableSlotsByDay && renderItem({ item: availableSlotsByDay })}
            </Show>
          </VStack>
        </Flex>
        <Box pt="6">
          <Button
            py="3"
            w="full"
            h="50px"
            rounded="full"
            variant="primary"
            onClick={() => {
              resetDateTime();
              onClose();
            }}
            fontSize="16px"
            fontWeight="800"
          >
            Or schedule later
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
}
