import usePickleheadsDiscount from './usePickleheadsDiscount';
import useStudentReferral from './useStudentReferral';

import { PricePayload } from '../api';

/**
 * Get discounts for a given pack
 */
export default function useDiscounts(
  price: PricePayload,
  pack: number,
  isPickleheadsDiscountIncluded = false,
): {
  packDiscountAmount: number;
  totalPackDiscountAmount: number;
  totalDiscountAmount: number;
  freeLessonDiscount: number;
  totalFreeLessonDiscount: number;
} {
  const { hasFreeLesson } = useStudentReferral();
  const { pickleheadsDiscountAmount } = usePickleheadsDiscount();

  const locationFeeDiscount = (hasFreeLesson ? price?.locationFee : 0) || 0;
  const freeLessonDiscount = (hasFreeLesson ? price?.pricePerLesson : 0) || 0;
  const totalFreeLessonDiscount = freeLessonDiscount + locationFeeDiscount;
  const packDiscountAmount =
    (pack || price?.package) > 1 ? price?.packageDiscountAmount || 0 : 0;
  const totalPackDiscountAmount =
    packDiscountAmount +
    (isPickleheadsDiscountIncluded ? pickleheadsDiscountAmount : 0);
  const totalDiscountAmount = totalPackDiscountAmount + totalFreeLessonDiscount;

  return {
    packDiscountAmount,
    totalPackDiscountAmount,
    totalDiscountAmount,
    freeLessonDiscount,
    totalFreeLessonDiscount,
  };
}
