import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { getMonth, getYear } from 'date-fns';
import { range } from 'ramda';
import { ChangeEvent, useState } from 'react';
import DatePicker from 'react-datepicker';
import { HiOutlineCalendar } from 'react-icons/hi';

import 'react-datepicker/dist/react-datepicker.css';

import './AvailabilityDatePicker.css';

import useIsMobile from '../../../hooks/useIsMobile';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
  clearAvailabilityFilterWeekDays,
  ScheduleWeekday,
  selectAvailabilityFilter,
  setAvailabilityFilter,
} from '../../../state/slices/filtersSlice';
import { MONTHS } from '../../../utils/constants';

type DayOfWeek = {
  label: 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat';
  value: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  weekDay: ScheduleWeekday;
};

const RECORD_DAYS_OF_WEEK: Record<
  DayOfWeek['label'],
  Omit<DayOfWeek, 'label'>
> = {
  Sun: { value: 0, weekDay: ScheduleWeekday.Sunday },
  Mon: { value: 1, weekDay: ScheduleWeekday.Monday },
  Tue: { value: 2, weekDay: ScheduleWeekday.Tuesday },
  Wed: { value: 3, weekDay: ScheduleWeekday.Wednesday },
  Thu: { value: 4, weekDay: ScheduleWeekday.Thursday },
  Fri: { value: 5, weekDay: ScheduleWeekday.Friday },
  Sat: { value: 6, weekDay: ScheduleWeekday.Saturday },
};

const DAYS_OF_WEEK: DayOfWeek[] = Object.entries(RECORD_DAYS_OF_WEEK).map(
  ([label, { value, weekDay }]) => ({
    label: label as DayOfWeek['label'],
    value,
    weekDay,
  }),
);

const CURRENT_YEAR = getYear(new Date());
const YEARS = range(CURRENT_YEAR, CURRENT_YEAR + 10);

type Props = {
  onClose(): void;
  insideQuiz?: boolean;
};

export default function AvailabilityDatePicker({
  onClose,
  insideQuiz = false,
}: Props): JSX.Element {
  const { isMobile } = useIsMobile();
  const [viewCalendar, setViewCalendar] = useState<boolean>(false);
  const availabilityFilter = useAppSelector(selectAvailabilityFilter);
  const dispatch = useAppDispatch();

  const onClearAll = () => {
    dispatch(clearAvailabilityFilterWeekDays());
  };

  const onChangeDates = (dates: [Date, Date]) => {
    const [startAt, endAt] = dates;
    dispatch(
      setAvailabilityFilter({
        ...availabilityFilter,
        startAt: startAt?.toISOString(),
        endAt: endAt?.toISOString(),
      }),
    );
  };

  const onChangeDaysOfWeek = (e: ChangeEvent<HTMLInputElement>) => {
    const label = e.target.ariaLabel as DayOfWeek['label'];
    const weekDay = RECORD_DAYS_OF_WEEK[label]?.weekDay;
    if (e.target.checked) {
      dispatch(
        setAvailabilityFilter({
          ...availabilityFilter,
          weekDays: [...availabilityFilter.weekDays, weekDay],
        }),
      );
    } else {
      dispatch(
        setAvailabilityFilter({
          ...availabilityFilter,
          weekDays: (availabilityFilter.weekDays || []).filter(
            (day) => day !== weekDay,
          ),
        }),
      );
    }
  };

  return (
    <>
      <Box
        borderColor="#CBD5E0"
        borderRadius="8px"
        borderWidth="1px"
        p="4"
        w={isMobile || !viewCalendar ? 'full' : undefined}
      >
        {viewCalendar ? (
          <Flex alignItems="center" justifyContent="center">
            <DatePicker
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                // Cannot use Chakra component styling
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <select
                      className="datepicker__header-select"
                      value={MONTHS[getMonth(date)]}
                      onChange={({ target: { value } }) =>
                        changeMonth(MONTHS.indexOf(value))
                      }
                    >
                      {MONTHS.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      className="datepicker__header-select"
                      value={getYear(date)}
                      onChange={({ target: { value } }) => changeYear(+value)}
                    >
                      {YEARS.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div style={{ height: 20, overflow: 'hidden' }}>
                    <span
                      aria-disabled={prevMonthButtonDisabled}
                      onClick={
                        prevMonthButtonDisabled ? undefined : decreaseMonth
                      }
                      // Accessibility
                      onKeyUp={
                        prevMonthButtonDisabled ? undefined : decreaseMonth
                      }
                      style={{
                        color: prevMonthButtonDisabled ? '#CBD5E0' : '#3182ce',
                        cursor: prevMonthButtonDisabled ? 'default' : 'pointer',
                        fontSize: 36,
                        bottom: 16,
                        position: 'relative',
                        marginRight: 12,
                      }}
                    >
                      &lsaquo;
                    </span>

                    <span
                      aria-disabled={nextMonthButtonDisabled}
                      onClick={
                        nextMonthButtonDisabled ? undefined : increaseMonth
                      }
                      // Accessibility
                      onKeyUp={
                        nextMonthButtonDisabled ? undefined : increaseMonth
                      }
                      style={{
                        color: nextMonthButtonDisabled ? '#CBD5E0' : '#3182ce',
                        cursor: nextMonthButtonDisabled ? 'default' : 'pointer',
                        fontSize: 36,
                        bottom: 16,
                        position: 'relative',
                        marginLeft: 12,
                      }}
                    >
                      &rsaquo;
                    </span>
                  </div>
                </div>
              )}
              selected={
                availabilityFilter.startAt
                  ? new Date(availabilityFilter.startAt)
                  : undefined
              }
              onChange={onChangeDates}
              minDate={new Date()}
              startDate={
                availabilityFilter.startAt
                  ? new Date(availabilityFilter.startAt)
                  : undefined
              }
              endDate={
                availabilityFilter.endAt
                  ? new Date(availabilityFilter.endAt)
                  : undefined
              }
              selectsRange
              inline
              showDisabledMonthNavigation
            />
          </Flex>
        ) : (
          <Grid templateColumns="repeat(3, 1fr)" gap={4}>
            {DAYS_OF_WEEK.map((dayOfWeek) => (
              <GridItem
                alignItems="center"
                key={dayOfWeek.value}
                justifyContent="flex-end"
                colSpan={1}
              >
                <Checkbox
                  colorScheme="blue"
                  aria-label={dayOfWeek.label}
                  isChecked={availabilityFilter?.weekDays?.some(
                    (day) => day === dayOfWeek.weekDay,
                  )}
                  onChange={onChangeDaysOfWeek}
                  spacing="3"
                >
                  {dayOfWeek.label}
                </Checkbox>
              </GridItem>
            ))}

            <GridItem
              alignItems="center"
              display="flex"
              justifyContent="flex-end"
              colSpan={2}
            >
              <Text
                cursor="pointer"
                onClick={onClearAll}
                textDecorationLine="underline"
              >
                Clear all
              </Text>
            </GridItem>
          </Grid>
        )}
      </Box>

      <Flex alignItems="center" flexDir="row" justifyContent="center">
        <HiOutlineCalendar style={{ marginBottom: 3 }} />
        <Text
          cursor="pointer"
          ml="2"
          onClick={() => {
            onClearAll();
            setViewCalendar((prevViewCalendar) => !prevViewCalendar);
          }}
          textDecorationLine="underline"
        >
          {viewCalendar
            ? 'Or choose days of the week'
            : 'Or choose a specific date'}
        </Text>
      </Flex>

      {isMobile && !insideQuiz && (
        <Box pb="4" w="full">
          <Button onClick={onClose} w="full">
            Save
          </Button>
        </Box>
      )}
    </>
  );
}
