import { HStack, Tag, TagLabel } from '@chakra-ui/react';

export default function ReferralRewardPriceTag(): JSX.Element {
  return (
    <Tag
      p="4"
      py="2"
      width="full"
      borderRadius="8"
      bgColor="#E9D8FD"
      colorScheme="#6B46C1"
    >
      <HStack justifyContent="space-between" width="full">
        <TagLabel color="#6B46C1" textStyle="overlineBold" userSelect="none">
          +1 free lesson!
        </TagLabel>
        <TagLabel color="#6B46C1" textStyle="overlineBold" userSelect="none">
          REFERRAL BONUS
        </TagLabel>
      </HStack>
    </Tag>
  );
}
