import { Heading, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import {
  HiOutlineHeart,
  HiOutlineShieldCheck,
  HiOutlineSparkles,
} from 'react-icons/hi';

import { Benefit } from '../../../../../components/BookingWidget/Confirm/OurPromise';
import { useAppSelector } from '../../../../../state/hooks';
import { selectSelectedListing } from '../../../../../state/slices/listingsSlice';
import getFirstName from '../../../../../utils/user';

type Props = {
  title?: string;
  centered?: boolean;
};

export default function CheckoutOurPromise({
  title = 'Our promise',
  centered = false,
}: Props) {
  const listing = useAppSelector(selectSelectedListing);
  const firstName = useMemo(() => {
    if (!listing || !listing.proName) {
      return 'your Coach';
    }
    return getFirstName(listing.proName);
  }, [listing]);

  return (
    <VStack alignItems={centered ? 'center' : 'flex-start'} px="6">
      <Heading variant="h4" pb="6">
        {title}
      </Heading>
      <VStack spacing="1rem">
        <Benefit
          Icon={HiOutlineSparkles}
          title={`Try ${firstName} risk free.`}
          description={`If you don't love your first lesson with ${firstName}, we'll switch you to another coach or give you a full refund.`}
          centered={centered}
        />
        <Benefit
          Icon={HiOutlineHeart}
          title="World-class, human support."
          description={`If you ever need help, you're not alone. Our customer service team is here to support you (almost) around the clock. And we're real people, not AI.`}
          centered={centered}
        />
        <Benefit
          Icon={HiOutlineShieldCheck}
          title="Lesson insurance is included for free."
          description="You’re protected by a $1,000,000 policy!"
          centered={centered}
        />
      </VStack>
    </VStack>
  );
}
