import CoachGuaranteeDesktop from './components/CoachGuaranteeDesktop';
import CoachGuaranteeMobile from './components/CoachGuaranteeMobile';

import useIsMobile from '../../hooks/useIsMobile';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  proName: string;
}

export default function CoachGuaranteeModal({
  isOpen,
  onClose,
  proName,
}: Props) {
  const { isMobile } = useIsMobile();

  return isMobile ? (
    <CoachGuaranteeMobile isOpen={isOpen} onClose={onClose} proName={proName} />
  ) : (
    <CoachGuaranteeDesktop
      isOpen={isOpen}
      onClose={onClose}
      proName={proName}
    />
  );
}
