import { motion, MotionStyle } from 'framer-motion';
import { ComponentProps } from 'react';

type Props = {
  children: JSX.Element | JSX.Element[];
  style?: MotionStyle;
  transition?: ComponentProps<typeof motion.div>['transition'];
  animate?: ComponentProps<typeof motion.div>['animate'];
  layoutId?: ComponentProps<typeof motion.div>['layoutId'];
  initial?: ComponentProps<typeof motion.div>['initial'];
  exit?: ComponentProps<typeof motion.div>['exit'];
};

/**
 * Animate given element on layout changes
 */
export default function WithAnimOnLayout({
  children,
  style,
  transition,
  animate,
  layoutId,
  initial,
  exit,
}: Props): JSX.Element {
  return (
    <motion.div
      layout
      style={style}
      transition={transition}
      animate={animate}
      layoutId={layoutId}
      initial={initial}
      exit={exit}
    >
      {children}
    </motion.div>
  );
}
