import { useLocalStorageValue } from '@react-hookz/web';
import { useCallback } from 'react';

export default function useProReferralCode() {
  const [proReferralCode, setProReferralCode] = useLocalStorageValue<string>(
    'proReferralCode',
    '',
  );

  const initProReferralCode = useCallback(
    (urlLocationSearch = window.location.search) => {
      const urlParams = new URLSearchParams(urlLocationSearch);
      const proReferralCodeParam = urlParams.get('pro_referral_code');
      if (proReferralCodeParam) setProReferralCode(proReferralCodeParam);
    },
    [setProReferralCode],
  );

  const resetProReferralCode = useCallback(() => {
    setProReferralCode('');
  }, [setProReferralCode]);

  return {
    proReferralCode,
    initProReferralCode,
    resetProReferralCode,
  };
}
