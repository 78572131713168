/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../interfaces';

export interface CheckoutState {
  scrollPosition: number;
  isTermsChecked: boolean;
}

const initialState: CheckoutState = {
  scrollPosition: 0,
  isTermsChecked: true,
};

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setScrollPosition: (
      state,
      action: PayloadAction<CheckoutState['scrollPosition']>,
    ) => {
      state.scrollPosition = action.payload || initialState.scrollPosition;
    },
    setIsTermsChecked: (
      state,
      action: PayloadAction<CheckoutState['isTermsChecked']>,
    ) => {
      state.isTermsChecked = action.payload;
    }
  },
});

// export actions
export const { setScrollPosition, setIsTermsChecked } = slice.actions;

// export selectors
export const selectScrollPosition = (state: RootState) =>
  state.checkout.scrollPosition;
export const selectIsTermsChecked = (state: RootState) =>
  state.checkout.isTermsChecked;

// export reducer
export default slice.reducer;
