import {
  Box,
  HStack,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { addMonths, format } from 'date-fns';
import * as R from 'ramda';
import { useCallback, useState } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

import Calendar from './Calendar';
import { DatePickerStyleConfig, defaultDatePickerStyle } from './type';
import { DateRulesFn } from './utils/dayListGenerator';
import Week from './Week';

type DateTimePickerProps = {
  selectedDate: Date;
  datePickerStyle?: Partial<DatePickerStyleConfig>;
  onSetDate?: (date: Date) => void;
  onDayTypeRulesFn: DateRulesFn;
  variant?: 'minimal' | 'fullscreen';
  handleMonthChange: (controlDate: Date) => void;
};

export default function DateTimePicker({
  selectedDate,
  datePickerStyle,
  onSetDate,
  onDayTypeRulesFn,
  variant = 'minimal',
  handleMonthChange,
}: DateTimePickerProps) {
  const [controlDate, setControlDate] = useState(new Date(selectedDate));

  const handleNextMonth = useCallback(() => {
    const nextControlDate = addMonths(controlDate, 1);
    setControlDate(nextControlDate);
    handleMonthChange(nextControlDate);
  }, [controlDate, handleMonthChange]);

  const handlePrevMonth = useCallback(() => {
    const nextControlDate = addMonths(controlDate, -1);
    setControlDate(nextControlDate);
    handleMonthChange(nextControlDate);
  }, [controlDate, handleMonthChange]);

  const mergedDatePickerStyle: DatePickerStyleConfig = R.mergeDeepRight(
    defaultDatePickerStyle,
    datePickerStyle || {},
  );

  const isFullscreen = variant === 'fullscreen';

  return (
    <Box
      bgColor={useColorModeValue('white', 'gray.700')}
      borderRadius="lg"
      w="full"
    >
      <Stack direction={{ base: 'column', lg: 'row' }} mt="4">
        <Stack w="full" h={isFullscreen ? 'full' : 'inherit'} flex="2">
          <HStack justify="space-between">
            <IconButton
              variant="ghost"
              aria-label="prev month"
              icon={<HiChevronLeft />}
              onClick={handlePrevMonth}
            />
            <Text
              textAlign="center"
              color={useColorModeValue('black', 'white')}
              fontWeight="semibold"
            >
              {format(new Date(controlDate), 'MMMM Y')}
            </Text>
            <IconButton
              variant="ghost"
              aria-label="next month"
              icon={<HiChevronRight />}
              onClick={handleNextMonth}
            />
          </HStack>
          <Week p={2} weekStyleConfig={mergedDatePickerStyle.weekStyle} />
          <Calendar
            year={controlDate.getFullYear()}
            month={controlDate.getMonth()}
            onSetDate={onSetDate}
            onGetDayTypeRulesFn={onDayTypeRulesFn}
            dayStyleConfig={mergedDatePickerStyle.dayStyle}
            p="2"
          />
        </Stack>
      </Stack>
    </Box>
  );
}
