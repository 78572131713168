import {
  BASEBALL_SKILL_SLUG,
  BASKETBALL_SKILL_SLUG,
  BOXING_SKILL_SLUG,
  DANCE_SKILL_SLUG,
  GOLF_SKILL_SLUG,
  GUITAR_SKILL_SLUG,
  PIANO_SKILL_SLUG,
  PICKLEBALL_SKILL_SLUG,
  SINGING_SKILL_SLUG,
  TENNIS_SKILL_SLUG,
  VIOLIN_SKILL_SLUG,
  YOGA_SKILL_SLUG,
} from './constants';

import { Skill } from '../api';

export default function getLocationAnswerFromSkill(
  skillSlug: Skill['slug'],
  isPrivate = false,
  isFirstPerson = true,
): {
  atLocationAnswer: string;
  atHomeAnswer: string;
} {
  let atLocationAnswer = 'at a location';
  let atHomeAnswer = `at ${isFirstPerson ? 'my' : 'your'} home`;

  switch (skillSlug) {
    case PICKLEBALL_SKILL_SLUG:
      atLocationAnswer = 'at a pickleBall court';
      atHomeAnswer = `at ${isFirstPerson ? 'my' : 'your'}${
        isPrivate ? ' private' : ''
      } court`;
      break;
    case TENNIS_SKILL_SLUG:
      atLocationAnswer = 'at a tennis court';
      atHomeAnswer = `at ${isFirstPerson ? 'my' : 'your'}${
        isPrivate ? ' private' : ''
      } court`;
      break;
    case GOLF_SKILL_SLUG:
      atLocationAnswer = 'at a driving range';
      atHomeAnswer = `at ${isFirstPerson ? 'my' : 'your'}${
        isPrivate ? ' private' : ''
      } golf club`;
      break;
    case BASEBALL_SKILL_SLUG:
      atLocationAnswer = 'at a baseball field';
      atHomeAnswer = `at ${isFirstPerson ? 'my' : 'your'}${
        isPrivate ? ' private' : ''
      } facility`;
      break;
    case BASKETBALL_SKILL_SLUG:
      atLocationAnswer = 'at a basketball court';
      atHomeAnswer = `at ${isFirstPerson ? 'my' : 'your'}${
        isPrivate ? ' private' : ''
      } court`;
      break;
    case BOXING_SKILL_SLUG:
      atLocationAnswer = 'at a boxing gym';
      atHomeAnswer = `at ${isFirstPerson ? 'my' : 'your'}${
        isPrivate ? ' private' : ''
      } facility`;
      break;
    case PIANO_SKILL_SLUG:
    case GUITAR_SKILL_SLUG:
    case SINGING_SKILL_SLUG:
    case YOGA_SKILL_SLUG:
    case VIOLIN_SKILL_SLUG:
    case DANCE_SKILL_SLUG:
      atLocationAnswer = 'at a studio';
      atHomeAnswer = `at ${isFirstPerson ? 'my' : 'your'} home`;
      break;
    default:
      atLocationAnswer = 'at a location';
      atHomeAnswer = `at ${isFirstPerson ? 'my' : 'your'} home`;
      break;
  }

  return { atLocationAnswer, atHomeAnswer };
}
