import { Heading, ListItem, Stack, UnorderedList } from '@chakra-ui/react';

export function StudentRefundPolicy() {
  return (
    <Stack spacing={4}>
      <p>
        This Student Refund Policy governs Teach Me To's policy for Student
        refunds and the Pro obligations associated with this Policy. It applies
        in addition to Teach Me To's Terms of Service and Teach Me To's
        Additional Terms for Pros and is available to Students who book a Lesson
        through the Teach Me To Platform and suffer a Travel Issue.
      </p>
      <p>
        All capitalized terms shall have the meaning set forth in the Teach Me
        To Terms or Payments Terms unless otherwise defined in this Student
        Refund Policy.
      </p>
      <p>
        By using the Teach Me To Platform as a Pro or Student, you are
        indicating that you have read and that you understand and agree to be
        bound by this Student Refund Policy.
      </p>
      <p>Eligible Travel Issues and Refund Policy</p>
      <p>
        The following situations are “Travel Issues” that are eligible for a
        refund under the Student Refund Policy:
      </p>
      <UnorderedList px="8">
        <ListItem>
          The Pro fails to carry out the booked Lesson or arrives more than 15
          minutes past the stated start time, causing the Student to abandon the
          Lesson.
        </ListItem>
        <ListItem>
          The Pro makes significant changes to the Lesson after booking.
        </ListItem>
        <ListItem>
          The Lesson contains a safety or health hazard that would be reasonably
          expected to adversely affect the Student's participation in the
          Lesson.
        </ListItem>
        <ListItem>
          For Lessons that take place online, the Student cannot attend or a Pro
          cannot lead the Lesson because of technical difficulties, including
          audio or visual issues.
        </ListItem>
      </UnorderedList>
      <p>
        If Teach Me To determines that a Student experienced a Travel Issue,
        Teach Me To will, at its discretion, provide the Student with a refund
        up to a maximum of the Total Pricing the Student paid for the Lesson in
        accordance with this Policy. The amount of any refund will depend on the
        nature of the issue suffered.
      </p>
      <p>
        Minimum Quality Standards, Pro Responsibilities, and Reimbursement to
        Student
      </p>
      <p>
        As a Pro, you are responsible for ensuring that the Lesson you list on
        the Teach Me To Platform meets Teach Me To's Lessons Standards and
        Requirements and does not present a Student with Travel Issues. Before
        and during the Lesson, Pros should be available, or make a third-party
        available, in order to try, in good faith, to resolve any Student
        issues.
      </p>
      <p>
        If (i) Teach Me To determines that a Student has suffered a Travel Issue
        related to your Lesson and (ii) Teach Me To reimburses that Student (up
        to their Total Price), you agree to reimburse Teach Me To up to the
        amount paid by Teach Me To within 30 days of Teach Me To's request. You
        authorize Teach Me To Payments to collect any amounts owed to Teach Me
        To by reducing your Payout or as otherwise permitted pursuant to the
        Payments Terms.
      </p>
      <p>
        As a Pro, you understand that the rights of Students under this Student
        Refund Policy will supersede the Lessons Cancellation Policy. If you
        dispute the Travel Issue, you may notify us in writing or via telephone
        and provide us with information (including photographs or other
        evidence) disputing the claims regarding the Travel Issue, provided you
        used reasonable and good faith efforts to try to remedy the Travel Issue
        with the Student prior to disputing the Travel Issue claim.
      </p>
      <Heading size="sm">General Provisions</Heading>
      <p>This Student Refund Policy does not:</p>
      <UnorderedList px="8">
        <ListItem>constitute an offer to insure</ListItem>
        <ListItem>constitute insurance or an insurance contract</ListItem>
        <ListItem>
          take the place of insurance obtained or obtainable by the Student
        </ListItem>
      </UnorderedList>
      <p>
        Furthermore, the Student has not paid any premium in respect of the
        Student Refund Policy. The benefits provided under this Student Refund
        Policy are not assignable or transferable by a Student.
      </p>
      <p>
        All determinations of Teach Me To with respect to the Student Refund
        Policy shall be at Teach Me To's discretion, and final and binding on
        the Students and Pros.
      </p>
      <p>
        Teach Me To reserves the right to modify or terminate this Student
        Refund Policy, at any time, in its sole discretion. If Teach Me To
        modifies this Student Refund Policy, we will post the modification on
        the Teach Me To Platform (including the Teach Me To Help Center) or
        provide you with notice of the modification. Teach Me To will continue
        to process all claims for Travel Issues made prior to the effective date
        of the modification.
      </p>
    </Stack>
  );
}
