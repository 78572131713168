import { useEffect } from 'react';

import useCurrentLocation from './useCurrentLocation';

import getUserLocationFromIp from '../utils/userLocation';

export default function useGetUserLocationFromIp() {
  const { currentLocation, setCurrentLocation } = useCurrentLocation();

  useEffect(() => {
    if (currentLocation?.latitude || currentLocation?.longitude) return;

    async function setCurrentLocationFromUserIp(): Promise<void> {
      const userIpLocation = await getUserLocationFromIp();
      setCurrentLocation(userIpLocation);
    }

    setCurrentLocationFromUserIp();
  }, [
    currentLocation?.latitude,
    currentLocation?.longitude,
    setCurrentLocation,
  ]);
}
