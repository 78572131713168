import useIsMobile from './useIsMobile';
import useViewportWidth from './useViewportWidth';

export default function useBookingWidgetViewport() {
  const { isMobile } = useIsMobile();
  const { viewportWidth } = useViewportWidth();

  const calculateMaxContentWidth = (pageMargin: number) =>
    Math.min(1248, isMobile ? viewportWidth : viewportWidth - pageMargin * 2);

  const calculateColumnWidth = (
    maxContentWidth: number,
    numOfColumns: number,
    gap: number,
  ) => (maxContentWidth - (numOfColumns - 1) * gap) / numOfColumns;

  const calculateNumberOfColumns = (
    maxContentWidth: number,
    maxColumnWidth: number,
    gap: number,
  ): number => {
    if (isMobile) return 1;
    if (maxContentWidth < maxColumnWidth * 2 + gap) return 1;
    if (maxContentWidth < maxColumnWidth * 3 + gap * 2) return 2;
    return 3;
  };

  const gap = 24;
  const pageMargin = 16;
  const maxContentWidth = calculateMaxContentWidth(pageMargin);
  const maxColumnWidth = Math.min(400, maxContentWidth);

  const numOfColumns = calculateNumberOfColumns(
    maxContentWidth,
    maxColumnWidth,
    gap,
  );
  const columnWidth = calculateColumnWidth(maxContentWidth, numOfColumns, gap);

  return { columnWidth, numOfColumns };
}
