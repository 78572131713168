import { Box } from '@chakra-ui/react';

import MapPin from './map-pin.svg';

interface IconMapPinProps {
  w?: number;
  h?: number;
}

export default function IconMapPin({ w, h }: IconMapPinProps) {
  return (
    <Box w={w ?? 'auto'} h={h ?? 'auto'}>
      <img alt="Map pin" height={h} width={w} src={MapPin} />
    </Box>
  );
}
