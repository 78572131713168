import { Tag, TagProps } from '@chakra-ui/react';

type Props = Pick<TagProps, 'children' | '_hover' | 'onClick' | 'px' | 'py'> & {
  isSelected: boolean;
};

export default function FeeTag({
  children,
  _hover,
  isSelected,
  onClick,
  px,
  py,
}: Props): JSX.Element {
  return (
    <Tag
      _hover={_hover}
      onClick={onClick}
      bg={isSelected ? 'white' : undefined}
      borderRadius="full"
      colorScheme={isSelected ? undefined : 'gray'}
      flexShrink="0"
      px={px}
      py={py}
      textStyle="overlineBold"
      minH="28px"
    >
      {children}
    </Tag>
  );
}
