enum PosthogEvent {
  // Initial
  StartSession = 'startSession',

  // Location
  AcceptedLocation = 'acceptedLocation',
  DeclinedLocation = 'declinedLocation',
  DismissLocationModal = 'dismissLocationModal',
  ToggleView = 'toggleView',
  PreventDismissLocationModal = 'preventDismissLocationModal',
  OnLocationInputChange = 'onLocationInputChange',
  LoadingLocation = 'loadingLocation',
  LoadingLocationDone = 'loadingLocationDone',

  // Globals
  ClickedCta = 'clickedCta',

  // Home
  EnterFlow = 'enterFlow',
  ClickedPin = 'clickedPin',
  ClickedSkillSelect = 'clickedSkillSelect',
  ClickedSkill = 'clickedSkill',
  ClickedCitySelect = 'clickedCitySelect',
  ClickedCity = 'clickedCity',

  // Browse
  RequestLocation = 'requestLocation',

  // Flow
  Equipment = 'equipment',
  ChooseLocation = 'chooseLocation',

  // Location Cards
  locationCardsView = 'locationCardsView',

  // Pro Cards
  proCardsView = 'proCardsView',

  // Choose A Pro
  ViewReviews = 'viewReviews',

  // Listing Details
  ViewListingDetails = 'viewListingDetails',

  // Booking Widget
  PickPack = 'pickPack',
  ChooseExposedTime = 'chooseExposedTime',
  ChooseAlternateTime = 'chooseAlternateTime',
  ChangeParticipants = 'changeParticipants',
  ViewDates = 'viewDates',
  TextUs = 'textUs',
  StartCheckout = 'startCheckout',
  ViewConfirm = 'viewConfirm',
  ViewAddress = 'viewAddress',
  CreateBooking = 'createBooking',
  ClickedEditTime = 'clickedEditTime',
  ClickedEditLocation = 'clickedEditLocation',
  ClickedEditEquipment = 'clickedEditEquipment',
  ClickedEditParticipants = 'clickedEditParticipants',
  ClickedEditLessonLength = 'clickedEditLessonLength',
  ClickedEditNumberOfLessons = 'clickedEditNumberOfLessons',
  BookingWidgetChangeLocation = 'bookingWidgetChangeLocation',


  // Checkout
  ProceedToCheckout = 'proceedToCheckout',
  ViewFullScreenCheckout = 'viewFullScreenCheckout',
  GoBackToListing = 'goBackToListing',
  CompleteCheckout = 'completeCheckout',

  // Reviews
  ReviewSessionStart = 'reviewSessionStart',
  ReviewNext = 'reviewNext',
  SelectTip = 'reviewSelectTip',
  SubmitWithoutTip = 'reviewSubmitWithoutTip',
  SubmitWithTip = 'reviewSubmitWithTip',
  ReviewSessionComplete = 'reviewSessionComplete',
  ReviewFinish = 'reviewBackToHome',

  // Quiz
  QuizNext = 'quizNext',
  QuizDismissed = 'quizDismissed',
  QuizDismissedByOverlay = 'quizDismissedByClickOverlay',
  QuizStarted = 'quizStarted',
  QuizCompleted = 'quizCompleted',
  QuizClickShareLocation = 'quizClickShareLocation',
  QuizClickTypeAnAddress = 'quizClickTypeAnAddress',
  QuizDismissedLocationScreen = 'quizDismissedLocationScreen',
  QuizLeaveSite = 'quizLeaveSite',

  // Location
  ClickMapCardChooseLocation= 'clickMapCardChooseLocation',
}

export default PosthogEvent;
