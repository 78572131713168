import {
  Box,
  Tag,
  TagLabel,
  type TagLabelProps,
  type TagProps,
  useColorModeValue,
} from '@chakra-ui/react';

type Props = Pick<
  TagProps,
  'bgColor' | 'borderRadius' | 'colorScheme' | 'h' | 'py' | 'w'
> & {
  iconLeft?: any;
  iconRight?: any;
  isActive: boolean;
  label: string;
  onClick?(): void;
  textStyle?: TagLabelProps['textStyle'];
  fontSize?: string;
};

export default function GenericTag({
  bgColor,
  borderRadius = 'full',
  colorScheme,
  h,
  iconLeft,
  iconRight,
  isActive,
  label,
  onClick,
  py = '2',
  textStyle,
  fontSize = '14px',
  w,
}: Props): JSX.Element {
  const boxShadowColor = useColorModeValue(
    isActive ? '#3182CE' : '#CBD5E0',
    isActive ? '#90CDF4' : '#2D3748',
  );
  const hoverBoxShadowColor = useColorModeValue(
    isActive ? '#3182CE' : '#A0AEC0',
    isActive ? '#90CDF4' : '#FFFFFF',
  );

  const boxShadow = `0px 0px 0px ${
    isActive ? '2px' : '1px'
  } ${boxShadowColor} inset`;
  const hoverBoxShadow = `0px 0px 0px ${
    isActive ? '2px' : '1px'
  } ${hoverBoxShadowColor} inset`;

  return (
    <Tag
      alignItems="center"
      cursor={onClick ? 'pointer' : undefined}
      onClick={onClick}
      flexShrink="0"
      size="lg"
      bgColor={bgColor}
      borderRadius={borderRadius}
      colorScheme={colorScheme}
      h={h}
      justifyContent="center"
      py={py}
      px="4"
      // For an inner border, boxSizing doesn't work, so use boxShadow
      boxShadow={boxShadow}
      sx={{ '&:hover': { boxShadow: hoverBoxShadow } }}
      w={w || 'max-content'}
    >
      {iconLeft ? <Box mr="1">{iconLeft}</Box> : null}

      <TagLabel
        color="defaultText"
        fontSize={fontSize}
        textStyle={textStyle}
        userSelect="none"
      >
        {label}
      </TagLabel>

      {iconRight ? <Box ml="1">{iconRight}</Box> : null}
    </Tag>
  );
}
