import { Package, type Skill } from '../api';

export const SLACK_REVIEWS_WEBHOOK_URL =
  // tslint:disable-next-line:whitespace
  import.meta.env.VITE_SLACK_REVIEWS_WEBHOOK_URL;

export const SLACK_HOT_LEADS_WEBHOOK_URL =
  // tslint:disable-next-line:whitespace
  import.meta.env.VITE_SLACK_HOT_LEADS_WEBHOOK_URL;

/**
 * @see #4-pro-added-to-notion
 */
export const SLACK_PRO_DATA_WEBHOOK_URL =
  // tslint:disable-next-line:whitespace
  import.meta.env.VITE_SLACK_PRO_DATA_WEBHOOK_URL;

export const RESCHEDULE_WEBHOOK_URL =
  // tslint:disable-next-line:whitespace
  import.meta.env.VITE_SLACK_RESCHEDULE_WEBHOOK_URL;

export const MAPBOX_ACCESS_TOKEN =
  // tslint:disable-next-line:whitespace
  import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

export const { VITE_POSTHOG_API_KEY } = import.meta.env;
export const { VITE_STRIPE_PUBLISHABLE_API_KEY } = import.meta.env;
export const { VITE_API_URL } = import.meta.env;
export const { VITE_BASE_URL = 'https://teachme.to' } = import.meta.env;

export const MAPBOX_API_URL = 'https://api.mapbox.com/search/geocode/v6';

export const { VITE_SUPABASE_API_URL } = import.meta.env;
export const { VITE_SUPABASE_PRODUCTION_REF_ID } = import.meta.env;
export const { VITE_SUPABASE_PRODUCTION_ANON_KEY } = import.meta.env;
export const { VITE_SUPABASE_STAGING_REF_ID } = import.meta.env;
export const { VITE_SUPABASE_STAGING_ANON_KEY } = import.meta.env;

export const { VITE_GTM_ID } = import.meta.env;

export const DEFAULT_SKILL_SLUG = 'pickleball';
export const TENNIS_SKILL_SLUG = 'tennis';
export const GOLF_SKILL_SLUG = 'golf';
export const SURFING_SKILL_SLUG = 'surfing';
export const BOXING_SKILL_SLUG = 'boxing';
export const FOOTBALL_SKILL_SLUG = 'football';
export const YOGA_SKILL_SLUG = 'yoga';
export const PERSONAL_TRAINING_SKILL_SLUG = 'personal-training';
export const BASEBALL_SKILL_SLUG = 'baseball';
export const BASKETBALL_SKILL_SLUG = 'basketball';
export const GUITAR_SKILL_SLUG = 'guitar';
export const PIANO_SKILL_SLUG = 'piano';
export const DANCE_SKILL_SLUG = 'dance';
export const SINGING_SKILL_SLUG = 'singing';
export const VIOLIN_SKILL_SLUG = 'violin';
export const PICKLEBALL_SKILL_SLUG = 'pickleball';
export const PET_TRAINING_SLUG = 'pet-training';

export const VITE_IMAGEKIT_URL =
  import.meta.env.VITE_IMAGEKIT_URL || 'https://media.teachme.to';

/**
 * Product note: These are deprioritized skills,
 * so they will be excluded from search.
 */
export const EXCLUDED_SKILL_SLUGS: Skill['slug'][] = [];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const LESSON_LENGTH_OPTIONS = {
  sport: {
    defaultIndex: 0,
    possibleLengths: [
      { label: '1 hour', minutes: 60, shortLabel: '1 hr' },
      { label: '1.5 hours', minutes: 90, shortLabel: '1.5 hrs' },
      { label: '2 hours', minutes: 120, shortLabel: '2 hrs' },
    ],
  },
  music: {
    defaultIndex: 0,
    possibleLengths: [
      { label: '30 minutes', minutes: 30, shortLabel: '30 min' },
      { label: '1 hour', minutes: 60, shortLabel: '1 hr' },
      { label: '1.5 hours', minutes: 90, shortLabel: '1.5 hrs' },
    ],
  },
  other: {
    defaultIndex: 0,
    possibleLengths: [{ label: '1 hour', minutes: 60, shortLabel: '1 hr' }],
  },
};

export const DEFAULT_PACKAGES_MAPPER = {
  1: Package.One,
  3: Package.Three,
  10: Package.Ten,
};
