import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import useSelectedLocation from '../../hooks/useSelectedLocation';
import { useSuccess } from '../../hooks/useSuccess';
import { useAppSelector } from '../../state/hooks';
import { selectSelectedSkill } from '../../state/slices/filtersSlice';
import { IconCopy } from '../Icons/IconCopy';
import { IconEmail } from '../Icons/IconEmail';
import { IconMessage } from '../Icons/IconMessage';

interface Props {
  heading?: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function ShareModal({ heading, isOpen, onClose }: Props) {
  const { handleSuccess } = useSuccess();
  const selectedSkill = useAppSelector(selectSelectedSkill);
  const [selectedLocation] = useSelectedLocation();
  const [link, setLink] = useState('');

  useEffect(() => {
    setLink(
      `${window.location.href}?skill=${selectedSkill?.slug}&location=${selectedLocation?.id}`,
    );
  }, [selectedLocation, selectedSkill]);

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    handleSuccess('Link copied!');
    onClose();
  };

  const handleEmail = useCallback(() => {
    const subject = `Check out these ${selectedSkill?.label} pros`;
    const body = `Hey, here are the personalized recommendations I got for the top ${selectedSkill?.label} pros near me! Link: ${link}`;

    window.location.href = `mailto:?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(body)}`;

    onClose();
  }, [link, selectedSkill, onClose]);

  const handleSms = useCallback(() => {
    const sms = `Check out these ${selectedSkill?.label} pros recommended for me! ${link}`;
    window.location.href = `sms:?&body=${encodeURIComponent(sms)}`;
    onClose();
  }, [link, selectedSkill, onClose]);

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: 'full', lg: '2xl' }}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: 'none', lg: '3xl' }}
        p={{ base: '2', md: '4' }}
      >
        <ModalCloseButton />
        <ModalHeader>{heading ?? 'Share your results'}</ModalHeader>

        <ModalBody w="full" pb="8">
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="8">
            <Button
              justifyContent="flex-start"
              variant="outline"
              colorScheme="gray"
              w="full"
              size="xl"
              leftIcon={<IconCopy />}
              onClick={handleCopy}
            >
              Copy link
            </Button>
            <Button
              justifyContent="flex-start"
              variant="outline"
              colorScheme="gray"
              w="full"
              size="xl"
              leftIcon={<IconEmail />}
              onClick={handleEmail}
            >
              Email
            </Button>
            <Button
              justifyContent="flex-start"
              variant="outline"
              colorScheme="gray"
              w="full"
              size="xl"
              leftIcon={<IconMessage />}
              onClick={handleSms}
            >
              Message
            </Button>
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
