import {
  LessonLengthOption,
  LessonLengthOptions,
} from '../components/LessonLengthMenu/interfaces';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import {
  selectSelectedLessonLength,
  selectSelectedSkill,
  setSelectedLessonLength,
} from '../state/slices/filtersSlice';
import { LESSON_LENGTH_OPTIONS } from '../utils/constants';

export default function useLessonLength(): {
  lessonLengthOptions: LessonLengthOptions;
  defaultOption: LessonLengthOption;
  isActiveFilter: boolean;
  filterLabel: string;
  setSelectedLessonLengthForCurrentCategory: (length: number) => void;
  lessonLength: number;
} {
  const selectedSkill = useAppSelector(selectSelectedSkill);
  const selectedLessonLength = useAppSelector(selectSelectedLessonLength);
  const dispatch = useAppDispatch();

  const skillCategory = selectedSkill?.category;
  const lessonLengthOptions =
    LESSON_LENGTH_OPTIONS[skillCategory] || LESSON_LENGTH_OPTIONS.other;

  const { defaultIndex } = lessonLengthOptions;

  const defaultOption = lessonLengthOptions.possibleLengths[defaultIndex];

  const isActiveFilter =
    selectedLessonLength?.[skillCategory] &&
    selectedLessonLength[skillCategory] !== defaultOption.minutes;

  const filterLabel = selectedLessonLength?.[skillCategory]
    ? lessonLengthOptions.possibleLengths.find(
        (option) => option.minutes === selectedLessonLength[skillCategory],
      )?.label || defaultOption.label
    : defaultOption.label;

  const lessonLength =
    selectedLessonLength?.[skillCategory] || defaultOption.minutes;

  const setSelectedLessonLengthForCurrentCategory = (length: number) => {
    if (!skillCategory || !length) return;
    const isPossibleLengthForSkillCategory =
      lessonLengthOptions.possibleLengths.some(
        (possibleLength) => possibleLength.minutes === length,
      );
    if (!isPossibleLengthForSkillCategory) return;
    dispatch(
      setSelectedLessonLength({
        ...selectedLessonLength,
        [skillCategory]: length,
      }),
    );
  };

  return {
    defaultOption,
    filterLabel,
    isActiveFilter,
    lessonLength,
    lessonLengthOptions,
    setSelectedLessonLengthForCurrentCategory,
  };
}
