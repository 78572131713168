import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react';

import CoachGuaranteeContent from './CoachGuaranteeContent';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  proName: string;
}

export default function CoachGuaranteeMobile({
  isOpen,
  onClose,
  proName,
}: Props) {
  return (
    <Drawer isOpen={isOpen} placement="bottom" size="md" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent borderTopRadius="2xl" p="4">
        <CoachGuaranteeContent onClose={onClose} proName={proName} />
      </DrawerContent>
    </Drawer>
  );
}
