import { HStack, Text } from '@chakra-ui/react';

import AvailabilityTime from './AvailabilityTime';

import {
  AVAILABILITY_OPTIONS_FROM,
  AVAILABILITY_OPTIONS_TO,
} from '../../../hooks/useAvailableRecommendations';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
  FiltersState,
  selectAvailabilityFilter,
  setAvailabilityFilter,
} from '../../../state/slices/filtersSlice';

export default function AvailabilityTimePicker(): JSX.Element {
  const availabilityFilter = useAppSelector(selectAvailabilityFilter);
  const dispatch = useAppDispatch();

  const dispatchAvailabilityFilter = (
    newAvailabilityFilter: FiltersState['availabilityFilter'],
  ) => {
    dispatch(setAvailabilityFilter(newAvailabilityFilter));
  };

  const optionsTo = availabilityFilter.from?.value
    ? AVAILABILITY_OPTIONS_TO.filter(
        (option) => option.value > availabilityFilter.from.value,
      )
    : AVAILABILITY_OPTIONS_TO;
  return (
    <HStack
      alignItems="center"
      spacing="2"
      justifyContent="space-between"
      w="full"
    >
      <AvailabilityTime
        onChange={(option) => {
          dispatchAvailabilityFilter({
            ...availabilityFilter,
            from: option,
            to:
              option.value >= availabilityFilter.to?.value
                ? undefined
                : availabilityFilter.to,
          });
        }}
        options={AVAILABILITY_OPTIONS_FROM}
        placeholder="06:00 am"
        value={availabilityFilter.from}
      />

      <Text textStyle="overlineBold">TO</Text>

      <AvailabilityTime
        onChange={(option) => {
          dispatchAvailabilityFilter({
            ...availabilityFilter,
            from:
              option.value <= availabilityFilter.from?.value
                ? undefined
                : availabilityFilter.from,
            to: option,
          });
        }}
        options={optionsTo}
        placeholder="10:00 pm"
        value={availabilityFilter.to}
      />
    </HStack>
  );
}
