import {
  Select as ChakraReactSelect,
  MenuPlacement,
} from 'chakra-react-select';
import { SizeProp } from 'chakra-react-select/dist/types/types';
import { useEffect, useState } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';

import useCurrentLocation from '../../hooks/useCurrentLocation';
import { useAppDispatch } from '../../state/hooks';
import { setMapLocation } from '../../state/slices/locationsSlice';
import capturePostHogEvent from '../../utils/capturePostHogEvent';
import getLocationFromAddress from '../../utils/getLocationFromAddress';
import PosthogEvent from '../../utils/posthogEvents';
import type { CurrentLocation, Location } from '../../utils/types';
import * as zipcodes from '../../utils/zipcodes';

interface LocationAutocompleteProps {
  onSelect?: (
    location: Omit<Partial<Location>, 'environmentSettings' | 'placeName'>,
  ) => void;
  onLocationChange?: (location: CurrentLocation) => void;
  menuPlacement?: MenuPlacement;
  size?: SizeProp;
  onFocus?: () => void;
  onBlur?: () => void;
}

export default function LocationAutocomplete({
  onSelect,
  onLocationChange,
  menuPlacement = 'bottom',
  size = 'lg',
  onFocus,
  onBlur,
}: LocationAutocompleteProps) {
  const MAX_SUGGESTIONS = 5;
  const { currentLocation, setCurrentLocation } = useCurrentLocation();
  const dispatch = useAppDispatch();
  const [suggestions, setSuggestions] = useState([]);
  const [locationValue, setLocationValue] = useState('');
  const placesAutoCompleteArgs = { debounce: 300, defaultValue: '' };

  const { setValue: setNormalSearchString, suggestions: normalSuggestions } =
    usePlacesAutocomplete(placesAutoCompleteArgs);

  const { setValue: setZipCodeSearchString, suggestions: zipCodeSuggestions } =
    usePlacesAutocomplete(placesAutoCompleteArgs);

  const handleLocationSelect = async (item: any) => {
    try {
      if (!item?.label) return;
      setLocationValue(item.label);

      capturePostHogEvent(PosthogEvent.OnLocationInputChange, {
        text: item.label,
      });
      const locationFromAddress = await getLocationFromAddress(item.label);
      if (!locationFromAddress) return;

      setCurrentLocation(locationFromAddress);
      dispatch(setMapLocation(locationFromAddress));
      onSelect?.(locationFromAddress);
      onLocationChange?.(locationFromAddress);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('LocationAutocomplete.handleLocationSelect', error);
    }
  };

  const handleOnFocus = (e) => {
    e.currentTarget.select();
    onFocus?.();
  };

  const handleOnBlur = () => {
    onBlur?.();
  };

  const parsePlacesAutocompleteSuggestions = (data) =>
    data?.map((option) => ({
      label: option?.description,
      value: option?.place_id,
    }));

  const mergeOptions = (item: any, list: any[]) => {
    if (!item) return list;
    const newList = list.filter((listItem) => listItem.label !== item.label);
    newList.unshift(item);
    if (newList.length > MAX_SUGGESTIONS) newList.pop();
    return newList;
  };

  useEffect(() => {
    const parsedNormalSuggestions = parsePlacesAutocompleteSuggestions(
      normalSuggestions?.data,
    );
    const parsedZipCodeSuggestions = parsePlacesAutocompleteSuggestions(
      zipCodeSuggestions?.data?.filter((sug) =>
        sug.types.includes('postal_code'),
      ),
    );
    const zipCodesFirstSuggestion = parsedZipCodeSuggestions?.[0];
    setSuggestions(
      mergeOptions(zipCodesFirstSuggestion, parsedNormalSuggestions),
    );
  }, [normalSuggestions, zipCodeSuggestions]);

  useEffect(() => {
    setNormalSearchString(locationValue);
    const isInTheUS = currentLocation.country === 'United States';
    if (isInTheUS && zipcodes.isUsZipCode(locationValue)) {
      const state = zipcodes.getUsStateByZipcode(locationValue);
      if (!state) {
        setZipCodeSearchString('');
        return;
      }
      const zipCodeSearchString = `${state} ${locationValue}`;
      setZipCodeSearchString(zipCodeSearchString);
    }
  }, [
    locationValue,
    currentLocation,
    setNormalSearchString,
    setZipCodeSearchString,
  ]);

  return (
    <ChakraReactSelect
      blurInputOnSelect
      isSearchable
      isClearable
      menuPlacement={menuPlacement}
      selectedOptionColorScheme="blue"
      chakraStyles={{
        dropdownIndicator: (provided) => ({
          ...provided,
          display: 'none',
        }),
        placeholder: (provided) => ({
          ...provided,
          textAlign: 'left',
        }),
        menuList: (provided) => ({
          ...provided,
          boxShadow: 'none',
          bgColor: '#FFF',
          borderRadius: 16,
          WebkitBoxShadow: '0px 8px 24px 0px rgba(45, 55, 72, 0.1)',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }),
      }}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      placeholder="Address, zip code or city"
      size={size}
      inputValue={locationValue}
      defaultInputValue={locationValue}
      onInputChange={(e) => setLocationValue?.(e)}
      onChange={handleLocationSelect}
      options={suggestions}
    />
  );
}
