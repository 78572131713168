/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import useDispatchDateTime from './useDispatchDateTime';
import useLessonLength from './useLessonLength';

import {
  FiltersState,
  selectSelectedSkill,
  setAtHomeLocation,
  setIsAtHome,
  setIsRecurring,
  setParticipants,
  setPkg,
} from '../state/slices/filtersSlice';
import { ListingSearchParam } from '../utils/getListingSearchParams';

interface ExtractedListingParams extends Record<ListingSearchParam, any> {
  [ListingSearchParam.SelectedListingLocation]: { locationId: string };
  [ListingSearchParam.Participants]: FiltersState['participants'];
  [ListingSearchParam.LessonLength]: number;
  [ListingSearchParam.IsAtHome]: boolean;
  [ListingSearchParam.IsRecurring]: boolean;
  [ListingSearchParam.DateTime]: FiltersState['dateTime'];
  [ListingSearchParam.Pkg]: FiltersState['pkg'];
  [ListingSearchParam.Origin]: string;
  [ListingSearchParam.AtHomeLocation]: FiltersState['atHomeLocation'];
}

export default function useExtractedListingParams() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { dispatchDateTime } = useDispatchDateTime();
  const selectedSkill = useSelector(selectSelectedSkill);
  const { setSelectedLessonLengthForCurrentCategory } = useLessonLength();

  const extractListingParams = useCallback((params) => {
    let selected_location: { locationId: string };
    try {
      selected_location = JSON.parse(
        params.get(ListingSearchParam.SelectedListingLocation),
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('useListingparams.JSON.parse', params);
    }
    const participants: FiltersState['participants'] = +params.get(
      ListingSearchParam.Participants,
    );
    const lesson_length: number = +params.get(ListingSearchParam.LessonLength);
    const is_at_home: boolean =
      params.get(ListingSearchParam.IsAtHome) === 'true';

    const is_recurring: boolean =
      params.get(ListingSearchParam.IsRecurring) === null ||
      params.get(ListingSearchParam.IsRecurring) === 'true';

    const date_time: FiltersState['dateTime'] = params.get(
      ListingSearchParam.DateTime,
    );

    const pkg = +params.get(ListingSearchParam.Pkg) as FiltersState['pkg'];

    const origin: string = params.get(ListingSearchParam.Origin);

    const paramsAtHomeLocation: string = params.get(
      ListingSearchParam.AtHomeLocation,
    );
    let at_home_location: FiltersState['atHomeLocation'];
    if (paramsAtHomeLocation) {
      try {
        at_home_location = JSON.parse(decodeURIComponent(paramsAtHomeLocation));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('paramsAtHomeLocation.JSON.parse', paramsAtHomeLocation);
      }
    }
    const paramsConfirmName = params.get(ListingSearchParam.ConfirmName);
    const confirm_name: string = paramsConfirmName
      ? decodeURIComponent(paramsConfirmName)
      : undefined;
    const confirm_email: string = params.get(ListingSearchParam.ConfirmEmail);
    const paramsConfirmPhoneNumber = params.get(
      ListingSearchParam.ConfirmPhoneNumber,
    );
    const confirm_phone: string = paramsConfirmPhoneNumber
      ? decodeURIComponent(paramsConfirmPhoneNumber)
      : undefined;

    const searchBoundingBox: string = params.get(
      ListingSearchParam.SearchBoundingBox,
    );
    let search_bounding_box: FiltersState['searchBoundingBox'];
    if (searchBoundingBox) {
      try {
        search_bounding_box = JSON.parse(decodeURIComponent(searchBoundingBox));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('paramSearchBoundingBox.JSON.parse', searchBoundingBox);
      }
    }

    const listingParams: ExtractedListingParams = {
      selected_location,
      participants,
      lesson_length,
      is_at_home,
      is_recurring,
      date_time,
      pkg,
      origin,
      at_home_location,
      confirm_name,
      confirm_email,
      confirm_phone,
      search_bounding_box,
    };

    return listingParams;
  }, []);

  // Extract current values for listing params from search params
  const extractedListingParams: ExtractedListingParams = useMemo(
    () => extractListingParams(searchParams),
    [extractListingParams, searchParams],
  );

  const loadParamsFromURL = useCallback(() => {
    const urlParams = extractListingParams(
      new URLSearchParams(window.location.search),
    );

    dispatchDateTime(urlParams.date_time);
    dispatch(setPkg(urlParams.pkg));
    dispatch(setParticipants(urlParams.participants));
    dispatch(setIsRecurring(urlParams.is_recurring));
    dispatch(setIsAtHome(urlParams.is_at_home));

    if (urlParams.at_home_location && urlParams.is_at_home) {
      dispatch(setAtHomeLocation(urlParams.at_home_location));
    }
    if (urlParams.lesson_length && selectedSkill?.category) {
      setSelectedLessonLengthForCurrentCategory(urlParams.lesson_length);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractListingParams, selectedSkill?.category]);

  return { extractedListingParams, extractListingParams, loadParamsFromURL };
}
