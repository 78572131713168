import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  Link,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { useLocation, useParams } from 'react-router-dom';

import ParamsProps from './interfaces';

import useCurrentLocation from '../../hooks/useCurrentLocation';
import capturePostHogEvent from '../../utils/capturePostHogEvent';
import { DEFAULT_SKILL_SLUG } from '../../utils/constants';
import slugify from '../../utils/slugify';
import LogoIcon from '../LogoIcon';
import { RouterLink } from '../RouterLink';

type Props = BoxProps;

export default function Footer(props: Props) {
  const location = useLocation();
  const isNotBecomeAProPage = location.pathname !== '/become-a-pro';

  const params: ParamsProps = useParams();
  const { currentLocation } = useCurrentLocation();
  const city = slugify(currentLocation?.city);
  const state = slugify(currentLocation?.state);
  const browseUrl = `/browse/${
    params?.skill ?? DEFAULT_SKILL_SLUG
  }/${state}/${city}`;
  const handleClick = (link: string) => {
    capturePostHogEvent('clickFooterLink', { action: link });
  };
  const { colorMode } = useColorMode();
  const isLight = colorMode === 'light';

  return (
    <Box
      width="100vw"
      as="footer"
      role="contentinfo"
      px="8"
      bg="bg-canvas"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Flex direction="column" w="full" align="center">
        <Flex direction="column" w="full" maxWidth="1200px">
          <Stack
            spacing="8"
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
            py={{ base: '8', md: '16' }}
            align="center"
          >
            <Stack
              spacing={{ base: '8', md: '2' }}
              align={{ base: 'center', md: 'flex-start' }}
            >
              <Stack direction="row" align="center">
                <LogoIcon size="lg" isLight={!isLight} />
                <Heading variant="h5" size="sm">
                  TeachMe.To
                </Heading>
              </Stack>
              <Text fontWeight={600} textAlign={{ base: 'center', md: 'left' }}>
                The easy way to learn to play.
              </Text>
            </Stack>
            <Stack spacing={{ base: '8', md: '2' }} direction="row">
              <Stack direction="column" align="center">
                <Stack spacing="1" minW={{ base: 'auto', md: '28' }}>
                  <Stack spacing="3" align="flex-start" justify="flex-start">
                    <RouterLink
                      to="/about"
                      onClick={() => handleClick('about')}
                    >
                      <Button
                        variant="link"
                        size="sm"
                        color={useColorModeValue('gray.600', 'gray.200')}
                        fontWeight="thin"
                        sx={{
                          textDecoration: 'none',
                          _hover: { textDecoration: 'underline' },
                        }}
                      >
                        About
                      </Button>
                    </RouterLink>
                    <RouterLink to="/faq" onClick={() => handleClick('faq')}>
                      <Button
                        variant="link"
                        size="sm"
                        minW="auto"
                        color={useColorModeValue('gray.600', 'gray.200')}
                        fontWeight="thin"
                        sx={{
                          textDecoration: 'none',
                          _hover: { textDecoration: 'underline' },
                        }}
                      >
                        FAQ
                      </Button>
                    </RouterLink>
                    <RouterLink
                      to="/pro-faq"
                      onClick={() => handleClick('pro-faq')}
                    >
                      <Button
                        variant="link"
                        size="sm"
                        minW="auto"
                        color={useColorModeValue('gray.600', 'gray.200')}
                        fontWeight="thin"
                        sx={{
                          textDecoration: 'none',
                          _hover: { textDecoration: 'underline' },
                        }}
                      >
                        Coach FAQ
                      </Button>
                    </RouterLink>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="column" align="center">
                <Stack spacing="1" minW={{ base: 'auto', md: '28' }}>
                  <Stack spacing="3" shouldWrapChildren>
                    <RouterLink
                      to={browseUrl}
                      onClick={() => handleClick('browse')}
                    >
                      <Button
                        variant="link"
                        size="sm"
                        minW="auto"
                        color={useColorModeValue('gray.600', 'gray.200')}
                        fontWeight="thin"
                        sx={{
                          textDecoration: 'none',
                          _hover: { textDecoration: 'underline' },
                        }}
                      >
                        Browse
                      </Button>
                    </RouterLink>
                    <RouterLink
                      to="/privacy"
                      onClick={() => handleClick('privacy')}
                    >
                      <Button
                        variant="link"
                        size="sm"
                        color={useColorModeValue('gray.600', 'gray.200')}
                        fontWeight="thin"
                        sx={{
                          textDecoration: 'none',
                          _hover: { textDecoration: 'underline' },
                        }}
                      >
                        Privacy
                      </Button>
                    </RouterLink>
                    <RouterLink
                      to="/terms"
                      onClick={() => handleClick('terms')}
                    >
                      <Button
                        variant="link"
                        size="sm"
                        color={useColorModeValue('gray.600', 'gray.200')}
                        fontWeight="thin"
                        sx={{
                          textDecoration: 'none',
                          _hover: { textDecoration: 'underline' },
                        }}
                      >
                        Terms
                      </Button>
                    </RouterLink>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {isNotBecomeAProPage && (
              <Stack direction="row" spacing={{ base: '8', md: '2' }}>
                <Stack minW="64">
                  <RouterLink to="/become-a-pro" w="full">
                    <Button
                      variant="primary"
                      size="xl"
                      w="full"
                      onClick={() =>
                        capturePostHogEvent('clickApply', { action: 'footer' })
                      }
                    >
                      Apply to teach
                    </Button>
                  </RouterLink>
                </Stack>
              </Stack>
            )}
            <Stack direction="column" spacing={{ base: '2', md: '2' }}>
              <ButtonGroup variant="ghost">
                <Stack
                  direction="row"
                  spacing={{ base: '-5', md: '0' }}
                  marginLeft={{ base: '5', md: '-15' }}
                  marginBottom={-3}
                  align="center"
                >
                  <Link
                    marginRight={{ base: '0', md: '-6' }}
                    my="-1"
                    href="https://instagram.com/teachmelessons"
                    isExternal
                  >
                    <IconButton
                      aria-label="Instagram"
                      icon={<FaInstagram fontSize="1.25rem" />}
                      sx={{
                        _hover: {
                          bg: 'transparent',
                          boxShadow: 'none',
                          opacity: 0.5,
                        },
                      }}
                    />
                  </Link>
                  <Link href="https://facebook.com/teachmelessons" isExternal>
                    <IconButton
                      aria-label="Facebook"
                      icon={<FaFacebookF fontSize="1.25rem" />}
                      sx={{
                        _hover: {
                          bg: 'transparent',
                          boxShadow: 'none',
                          opacity: 0.5,
                        },
                      }}
                    />
                  </Link>
                </Stack>
              </ButtonGroup>
              <Divider color={useColorModeValue('gray.600', 'gray.200')} />
              <Text
                fontSize="sm"
                color={useColorModeValue('gray.600', 'gray.200')}
              >
                &copy; {new Date().getFullYear()} Teach Me To
              </Text>
            </Stack>
          </Stack>
          <Divider />
          <Center>
            <Text
              color="muted"
              fontSize="xs"
              padding="10"
              textAlign="center"
              width="70dvw"
            >
              As a platform, we do our best to provide accurate information and
              make your booking experience seamless. However, we do not verify
              every detail and we do not background check every coach who lists
              themselves on our site. It&apos;s up to the instructors to provide
              accurate, up-to-date information. If you have any questions or
              concerns, just message us and ask!
            </Text>
          </Center>
        </Flex>
      </Flex>
    </Box>
  );
}
