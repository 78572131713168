/* eslint-disable import/no-extraneous-dependencies */
import 'core-js/actual/string/virtual/replace-all';
import './main.css';

import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { useLocalStorageValue } from '@react-hookz/web';
import 'focus-visible/dist/focus-visible';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import ScrollToTop from './components/ScrollToTop';
import { DrawerProvider } from './context/DrawerContext';
import { HoverProvider } from './context/HoverContext';
import { MainScrollProviver } from './context/MainScrollContext';
import { ModalProvider } from './context/ModalContext';
import useProReferralCode from './hooks/useProReferralCode';
import useTapfiliate from './hooks/useTapfiliate';
import useTracking from './hooks/useTracking';
import apolloClient from './infra/apolloClient';
import { captureStartSession, initGoogleTagManager } from './infra/gtmClient';
import posthogClient from './infra/posthogClient';
import sentryClient from './infra/sentryClient';
import Router from './Router';
import store from './state/store';
import { theme } from './theme';
import CustomerIOTracker from './trackers/CustomerIOTracker';
import capturePostHogEvent from './utils/capturePostHogEvent';
import PosthogEvent from './utils/posthogEvents';

initGoogleTagManager();
posthogClient.init();
sentryClient.init();

function Root() {
  const { initTapfiliate } = useTapfiliate();
  const { initProReferralCode } = useProReferralCode();
  const { capture } = useTracking();

  const [posthogUserId, setPosthogUserId] = useLocalStorageValue<string>(
    'posthogUserId',
    '',
  );

  useEffect(() => {
    initTapfiliate();
  }, [initTapfiliate]);

  useEffect(() => {
    initProReferralCode();
  }, [initProReferralCode]);

  useEffect(() => {
    // capture GTM event
    captureStartSession();

    // identify anonymos user in posthog
    let userId = posthogUserId;
    if (!userId) {
      userId = uuidv4();
      setPosthogUserId(userId);
    }
    posthog.identify(userId);
    capturePostHogEvent(PosthogEvent.StartSession);
  }, [posthogUserId, setPosthogUserId]);

  // capture tracking params (gclid and msclkid)
  useEffect(() => capture(), [capture]);

  return (
    <>
      <ApolloProvider client={apolloClient}>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <StoreProvider store={store}>
              <ModalProvider>
                <DrawerProvider>
                  <ScrollToTop />
                  <HelmetProvider>
                    <HoverProvider>
                      <MainScrollProviver>
                        <Router />
                      </MainScrollProviver>
                    </HoverProvider>
                  </HelmetProvider>
                </DrawerProvider>
              </ModalProvider>
            </StoreProvider>
          </BrowserRouter>
        </ChakraProvider>
      </ApolloProvider>
      <CustomerIOTracker />
    </>
  );
}

createRoot(document.getElementById('root') as HTMLElement).render(<Root />);
