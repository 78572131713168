/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';

function CustomerIOTracker() {
  useEffect(() => {
    const _cio = (window as any)._cio || [];
    (window as any)._cio = _cio;

    let c: number;

    const a: (f: string) => (...args: any[]) => void = function (f) {
      return function (...args: any[]) {
        _cio.push([f].concat(args));
      };
    };

    const b: string[] = [
      'load',
      'identify',
      'sidentify',
      'track',
      'page',
      'on',
      'off',
    ];

    for (c = 0; c < b.length; c += 1) {
      _cio[b[c]] = a(b[c]);
    }

    const t = document.createElement('script');
    const s = document.getElementsByTagName('script')[0];
    t.async = true;
    t.id = 'cio-tracker';
    t.setAttribute('data-site-id', 'd76bba2615c6b93517db');
    t.setAttribute('data-auto-track-page', 'false');
    t.src = 'https://assets.customer.io/assets/track.js';
    s.parentNode.insertBefore(t, s);
  }, []);

  return null;
}

export default CustomerIOTracker;
