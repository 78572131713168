import { HStack, Icon, Text } from '@chakra-ui/react';
import { HiStar } from 'react-icons/hi';

export default function NoRating() {
  return (
    <HStack spacing="1" flexWrap="wrap" align="center">
      <Icon as={HiStar} color="accent" />
      <Text textStyle="smallBold" color="accent">
        New coach
      </Text>
    </HStack>
  );
}
