import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { IconType } from 'react-icons';
import { HiOutlineHeart, HiOutlineSparkles } from 'react-icons/hi';
import { IoShieldCheckmarkOutline } from 'react-icons/io5';

import { useAppSelector } from '../../../state/hooks';
import { selectSelectedListing } from '../../../state/slices/listingsSlice';
import getFirstName from '../../../utils/user';

export function Benefit({
  Icon,
  title,
  description,
  pb,
  centered = false,
}: {
  Icon: IconType | React.FC<{ w?: number | string; h?: number | string }>;
  title: string;
  description: string;
  pb?: string;
  centered?: boolean;
}) {
  if (centered) {
    return (
      <VStack pb={pb || undefined}>
        <Box alignSelf="center">
          <Icon color="#2D3748" size="40px" h="40px" />
        </Box>
        <Text
          fontSize="14"
          color="#2D3748"
          fontWeight="extrabold"
          marginLeft="2"
          textAlign="center"
        >
          {title}
        </Text>
        <Text
          as="span"
          color="#2D3748"
          fontSize="14"
          fontWeight="normal"
          textAlign="center"
        >
          {`${description}`}
        </Text>
      </VStack>
    );
  }

  return (
    <HStack pb={pb || undefined}>
      <Box alignSelf="start">
        <Icon color="#2D3748" size="40px" h="40px" />
      </Box>
      <Text fontSize="14" color="#2D3748" fontWeight="extrabold" marginLeft="2">
        {title}
        <Text as="span" color="#2D3748" fontSize="14" fontWeight="normal">
          {` ${description}`}
        </Text>
      </Text>
    </HStack>
  );
}

export default function OurPromise() {
  const listing = useAppSelector(selectSelectedListing);
  const firstName = useMemo(() => {
    if (!listing || !listing.proName) {
      return 'your Coach';
    }
    return getFirstName(listing.proName);
  }, [listing]);


  return (
    <VStack alignItems="flex-start" px="6">
      <Heading variant="h4" pb="6">
        Our promise
      </Heading>
      <VStack spacing='1rem'>
        <Benefit
          Icon={HiOutlineSparkles}
          title={`Try ${firstName} risk free.`}
          description={`If you don't love your first lesson with ${firstName}, we'll switch you to another coach or give you a full refund.`}
        />
        <Benefit
          Icon={HiOutlineHeart}
          title="We’re here to help."
          description={`If you ever need support, you're not alone. Our customer service team is here to support you (almost) around the clock. And we're real people, not AI.`}
        />
        <Benefit
          Icon={IoShieldCheckmarkOutline}
          title="Lesson insurance is included"
          description={`for free. You're protected by a $1,000,000 policy!`}
        />
      </VStack>
    </VStack>
  );
}
