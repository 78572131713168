import { Stack } from '@chakra-ui/react';

export function Privacy() {
  return (
    <Stack spacing={4}>
      <p>1. DEFINITIONS</p>
      <p>
        Undefined terms in this Privacy Policy have the same definition as in
        our Terms of Service (&ldquo;Terms&rdquo;).
      </p>
      <p>2. PERSONAL INFORMATION WE COLLECT</p>
      <p>2.1 Information needed to use the Teach Me To Platform.</p>
      <p>
        We collect personal information about you when you use the Teach Me To
        Platform. Without it, we may not be able to provide you with all
        services requested. This information includes:
      </p>
      <p>
        Contact Information, Account, Profile Information. Such as your first
        name, last name, phone number, postal address, email address, date of
        birth, and profile photo, some of which will depend on the features you
        use.
      </p>
      <p>
        Identity Verification and Payment Information. Such as images of your
        government issued ID (as permitted by applicable laws), your ID number
        or other verification information, bank account or payment account
        information.
      </p>
      <p>2.2 Information you choose to give us.</p>
      <p>
        You can choose to provide us with additional personal information. This
        information may include:
      </p>
      <p>
        Additional Profile Information. Such as gender, preferred language(s),
        city, and personal description. Some of this information as indicated in
        your account settings is part of your public profile page and will be
        publicly visible.
      </p>
      <p>
        Address Book Contact Information. Address book contacts you import or
        enter manually.
      </p>
      <p>
        Other Information. Such as when you fill in a form, add information to
        your account, respond to surveys, post to community forums, participate
        in promotions, communicate with our customer care team and other
        Members, or share your experience with us. This may include health
        information if you choose to share it with us.
      </p>
      <p>
        2.3 Information Automatically Collected by Using the Teach Me To
        Platform and our Payment Services.
      </p>
      <p>
        When you use the Teach Me To Platform and Payment Services, we
        automatically collect personal information. This information may
        include:
      </p>
      <p>
        Geo-location Information. Such as precise or approximate location
        determined from your IP address or mobile device&rsquo;s GPS depending
        on your device settings.
      </p>
      <p>
        Usage Information. Such as the pages or content you view, searches for
        Listings, bookings you have made, and other actions on the Teach Me To
        Platform.
      </p>
      <p>
        Log Data and Device Information. Such as details about how you&rsquo;ve
        used the Teach Me To Platform (including if you clicked on links to
        third party applications), IP address, access dates and times, hardware
        and software information, device information, device event information,
        unique identifiers, crash data, cookie data, and the pages you&rsquo;ve
        viewed or engaged with before or after using the Teach Me To Platform.
        We may collect this information even if you haven&rsquo;t created a
        Teach Me To account or logged in.
      </p>
      <p>Cookies and Similar Technologies as described in our Cookie Policy.</p>
      <p>
        Payment Transaction Information. Such as payment instrument used, date
        and time, payment amount, payment instrument expiration date and billing
        postcode, PayPal email address, IBAN information, your address and other
        related transaction details.
      </p>
      <p>2.4 Personal Information We Collect from Third Parties.</p>
      <p>We collect personal information from other sources, such as:.</p>
      <p>
        Third-Party Services. If you link, connect, or login to the Teach Me To
        Platform with a third party service (e.g. Google, Facebook, WeChat), you
        direct the service to send us information such as your registration,
        friends list, and profile information as controlled by that service or
        as authorized by you via your privacy settings at that service.
      </p>
      <p>
        Background Information. For Members in the United States, to the extent
        permitted by applicable laws, we may obtain reports from public records
        of criminal convictions or sex offender registrations. For Members
        outside of the United States, to the extent permitted by applicable laws
        and with your consent where required, we may obtain the local version of
        police, background or registered sex offender checks. We may use your
        information, including your full name and date of birth, to obtain such
        reports.
      </p>
      <p>
        Enterprise Product Invitations and Account Management. Organizations
        that use our Enterprise products may submit personal information to
        facilitate account management and invitations to use enterprise
        products.
      </p>
      <p>
        Referrals and co-travelers. If you are invited to the Teach Me To
        Platform such as a co-traveler on a trip, the person who invited you can
        submit personal information about you such as your email address or
        other contact information.
      </p>
      <p>
        Other Sources. To the extent permitted by applicable law, we may receive
        additional information about you, such as references, demographic data
        or information to help detect fraud and safety issues from third party
        service providers and/or partners, and combine it with information we
        have about you. For example, we may receive background check results or
        fraud warnings from identity verification service providers for use in
        our fraud prevention and risk assessment efforts. We may receive
        information about you and your activities on and off the Teach Me To
        Platform, or about your experiences and interactions from our partners.
        We may receive health information, including but not limited to health
        information related to contagious diseases.
      </p>
      <p>3. HOW WE USE INFORMATION WE COLLECT</p>
      <p>
        3.1 Provide, Improve, and Develop the Teach Me To Platform. We use
        personal information to:
      </p>
      <p>
        enable you to access the Teach Me To Platform and make and receive
        payments,
      </p>
      <p>enable you to communicate with other Members,</p>
      <p>perform analytics, debug and conduct research,</p>
      <p>provide customer service,</p>
      <p>
        send you messages, updates, security alerts, and account notifications,
      </p>
      <p>
        if you provide us with your contacts&rsquo; information such as your
        friends or co-travellers, we may process this information: (i) to
        facilitate your referral invitations, (ii) to share your trip details
        and facilitate trip planning, (iii) for fraud detection and prevention,
        and (iv) to facilitate your requests or for any other purpose you
        authorize,
      </p>
      <p>
        personalize and customize your experience based on your interactions
        with the Teach Me To Platform, your search and booking history, your
        profile information and preferences, and other content you submit, and
      </p>
      <p>enable your use of our enterprise products.</p>
      <p>
        3.2 Create and Maintain a Trusted and Safer Environment. We use personal
        information to:
      </p>
      <p>
        detect and prevent fraud, spam, abuse, security and safety incidents,
        and other harmful activity,
      </p>
      <p>
        study and combat discrimination consistent with our Nondiscrimination
        Policy,
      </p>
      <p>conduct security investigations and risk assessments,</p>
      <p>verify or authenticate information provided by you,</p>
      <p>
        conduct checks against databases and other information sources,
        including background or police checks,
      </p>
      <p>
        comply with our legal obligations, protect the health and well-being of
        our Students, Pros, Pros&rsquo; employees and members of the public,
      </p>
      <p>resolve disputes with our Members,</p>
      <p>enforce our agreements with third parties,</p>
      <p>
        comply with law, respond to legal requests, prevent harm and protect our
        rights (see section 4.5)
      </p>
      <p>enforce our Terms of Service and other policies, and</p>
      <p>
        in connection with the activities above, we may conduct profiling based
        on your interactions with the Teach Me To Platform, your profile
        information and other content you submit to Teach Me To, and information
        obtained from third parties. In limited cases, automated processes could
        restrict or suspend access to the Teach Me To Platform if such processes
        detect activity that we think poses a safety or other risk to Teach Me
        To, our community, or third parties. If you would like to challenge the
        decisioning based on the automated process, please contact us via the
        Contact Information section below.
      </p>
      <p>
        3.3 Provide, Personalize, Measure, and Improve our Advertising and
        Marketing. We may use personal information to:
      </p>
      <p>
        send you promotional messages, marketing, advertising, and other
        information based on your preferences and social media advertising
        through social media platforms,
      </p>
      <p>personalize, measure, and improve our advertising,</p>
      <p>
        administer referral programs, rewards, surveys, sweepstakes, contests,
        or other promotional activities or events sponsored or managed by Teach
        Me To or its third-party partners,
      </p>
      <p>
        analyze characteristics and preferences to send you promotional
        messages, marketing, advertising and other information that we think
        might be of interest to you, and
      </p>
      <p>invite you to events and relevant opportunities.</p>
      <p>
        3.4 Provide Payment services. Personal information is used to enable or
        authorize third parties to use Payment Services:
      </p>
      <p>
        Detect and prevent money laundering, fraud, abuse, security incidents.
      </p>
      <p>Conduct security investigations and risk assessments.</p>
      <p>
        Comply with legal obligations (such as anti-money laundering
        regulations).
      </p>
      <p>Enforce the Payment Terms of Service and other payment policies.</p>
      <p>
        With your consent, send you promotional messages, marketing,
        advertising, and other information that may be of interest to you based
        on your preferences.
      </p>
      <p>Provide and improve the Payment Services.</p>
      <p>4. SHARING &amp; DISCLOSURE</p>
      <p>4.1 Sharing With Your Consent or at Your Direction.</p>
      <p>
        Where you provide consent, we share your information as described at the
        time of consent, such as when authorizing a third-party application or
        website to access your Teach Me To account or participating in
        promotional activities by Teach Me To partners or third parties.
      </p>
      <p>
        Where permissible with applicable law, we may use certain information
        about you, such as your email address, de-identify it, and share it with
        social media platforms, to generate leads, drive traffic to Teach Me To
        or otherwise promote our products and services.
      </p>
      <p>4.2 Sharing Between Members.</p>
      <p>
        To help facilitate bookings or other interactions between Members, we
        may need to share certain information such as:
      </p>
      <p>
        When a booking request is made or dispute is submitted, certain
        information may be shared between Student(s) and Pro(s), including
        profile, name, names of any additional Students, cancellation history,
        review information, age of Student (unless prohibited by applicable
        law), dispute outcome (when applicable) and other information you choose
        to share and submit. When a booking is confirmed, additional information
        is shared to assist with coordinating the trip, like profile photo and
        phone number. When you as a Pro have a confirmed booking, certain
        information is shared with the Student (and the additional Students they
        invite, if applicable) to coordinate the booking, such as your profile,
        full name, phone number, and Listing address.
      </p>
      <p>
        When you as a Pro invite another Member to Pro with you, you authorize
        that person to access and update your information and Member Content,
        including but not limited to certain information like your full name,
        phone number, Lesson address, calendar, Listing information, Listing
        photos, and email address.
      </p>
      <p>
        When you as a Student invite additional Students to a booking, your full
        name, travel dates, Pro name, Listing details, the Lesson address, and
        other related information will be shared with each additional Student.
      </p>
      <p>
        4.3 Information You Publish in Profiles, Listings, and other Public
        Information.
      </p>
      <p>
        You can make certain information publicly visible to others, such as:
      </p>
      <p>
        Your public profile page, which includes your profile photo, first name,
        description, and city.
      </p>
      <p>
        Listing pages that include information such as the Lesson&rsquo;s
        approximate or precise location description, calendar availability,
        profile photo, aggregated demand information (like page views over a
        period of time), and additional information you choose to share.
      </p>
      <p>Reviews, ratings and other public feedback.</p>
      <p>
        Content in a community or discussion forum, blog or social media post.
      </p>
      <p>
        We may display parts of your public profile and other Content you make
        available to the public like Listing details on third-party sites,
        platforms and apps.
      </p>
      <p>
        Information you share publicly on the Teach Me To Platform may be
        indexed through third-party search engines. In some cases, you may
        opt-out of this feature in your account settings.
      </p>
      <p>
        4.4 Complying with Law, Responding to Legal Requests, Preventing Harm
        and Protecting our Rights.
      </p>
      <p>
        We may disclose your information to courts, law enforcement,
        governmental or public authorities, tax authorities, or authorized third
        parties, if and to the extent we are required or permitted to do so by
        law or where disclosure is reasonably necessary: (i) to comply with our
        legal obligations, (ii) to comply with a valid legal request or to
        respond to claims asserted against Teach Me To, (iii) to respond to a
        valid legal request relating to a criminal investigation to address
        alleged or suspected illegal activity, or to respond to or address any
        other activity that may expose us, you, or any other of our users to
        legal or regulatory liability (more information on Teach Me To&rsquo;s
        Law Enforcement Guidelines here), (iv) to enforce and administer our
        agreements with Members, or (v) to protect the rights, property or
        personal safety of Teach Me To, its employees, its Members, or members
        of the public. For example, if permitted due to the forgoing
        circumstances, Pro tax information may be shared with tax authorities or
        other governmental agencies.
      </p>
      <p>
        Where appropriate, we may notify Members about legal requests unless:
        (i) providing notice is prohibited by the legal process itself, by court
        order we receive, or by applicable law, or (ii) we believe that
        providing notice would be futile, ineffective, create a risk of injury
        or bodily harm to an individual or group, or create or increase a risk
        of fraud upon or harm to Teach Me To, our Members, or expose Teach Me To
        to a claim of obstruction of justice.
      </p>
      <p>
        For jurisdictions where Teach Me To facilitates the collection and
        remittance of Taxes where legally permissible according to applicable
        law, we may disclose Pros&rsquo; and Students&rsquo; information about
        transactions, bookings, Lessons and occupancy Taxes to the applicable
        tax authority, such as Pro and Student names, Listing addresses,
        transaction dates and amounts, tax identification number(s), the amount
        of taxes received (or due) by Pros from Students, and contact
        information.
      </p>
      <p>
        In jurisdictions where Teach Me To facilitates or requires a
        registration, notification, permit, or license application of a Pro with
        a local governmental authority through Teach Me To in accordance with
        local law, we may share information of participating Pros with the
        relevant authority, both during the application process and,
        periodically thereafter, such as the Pro&rsquo;s full name and contact
        details, Lesson address, tax identification number, Listing details, and
        number of nights booked.
      </p>
      <p>4.5 Service Providers.</p>
      <p>
        We share personal information with affiliated and unaffiliated service
        providers to help us run our business, including service providers that
        help us: (i) verify your identity or authenticate your identification
        documents, (ii) check information against public databases, (iii)
        conduct background or police checks, fraud prevention, and risk
        assessment, (iv) perform product development, maintenance and debugging,
        (v) allow the provision of the Teach Me To Services through third-party
        platforms and software tools (e.g. through the integration with our
        APIs), (vi) provide customer service, advertising, or payments services,
        (vii) process, handle or assess insurance claims or similar claims, or
        (viii) facilitate non-profit and charitable activities consistent with
        Teach Me To&rsquo;s mission. These providers are contractually bound to
        protect your personal information and have access to your personal
        information to perform these tasks.
      </p>
      <p>4.6 Business Transfers.</p>
      <p>
        If Teach Me To undertakes or is involved in any merger, acquisition,
        reorganization, sale of assets, bankruptcy, or insolvency event, then we
        may sell, transfer or share some or all of our assets, including your
        information in connection with such transaction or in contemplation of
        such transaction (e.g., due diligence). In this event, we will notify
        you before your personal information is transferred and becomes subject
        to a different privacy policy.
      </p>
      <p>5. OTHER IMPORTANT INFORMATION</p>
      <p>5.1 Analyzing your Communications.</p>
      <p>
        We may review, scan, or analyze your communications on the Teach Me To
        Platform for reasons outlined in the &ldquo;How We Use Information We
        Collect&rdquo; section of this policy, including fraud prevention, risk
        assessment, regulatory compliance, investigation, product development,
        research, analytics, enforcing our Terms of Service, and customer
        support purposes. For example, as part of our fraud prevention efforts,
        we scan and analyze messages to mask contact information and references
        to other sites. In some cases, we may also scan, review, or analyze
        messages to debug, improve, and expand product offerings. We use
        automated methods where reasonably possible. Occasionally we may need to
        manually review communications, such as for fraud investigations and
        customer support, or to assess and improve the functionality of these
        automated tools. We will not review, scan, or analyze your messaging
        communications to send third-party marketing messages to you and we will
        not sell reviews or analyses of these communications.
      </p>
      <p>5.2 Linking Third-Party Accounts.</p>
      <p>
        You can link your Teach Me To account with certain third-party services
        like social networks. Your contacts on these third-party services are
        referred to as &ldquo;Friends.&rdquo; When you direct the data sharing
        by creating this link:
      </p>
      <p>
        some of the information provided to us from linking accounts may be
        published on your public profile,
      </p>
      <p>
        your activities on the Teach Me To Platform may be displayed to your
        Friends on the Teach Me To Platform and/or that third-party service,
      </p>
      <p>
        a link to your public profile on that third-party service may be
        included in your Teach Me To public profile,
      </p>
      <p>
        other Teach Me To users may be able to see any Friends that you may have
        in common with them, or that you are a Friend of their Friend if
        applicable,
      </p>
      <p>
        other Teach Me To users may be able to see any schools, hometowns or
        other groups you have in common with them as listed on your linked
        social networking service,
      </p>
      <p>
        information you provide to us from the linking of your accounts may be
        stored, processed and transmitted for fraud prevention and risk
        assessment purposes, and
      </p>
      <p>
        publication and display of information that you provide to the Teach Me
        To Platform through this linkage is subject to your settings and
        authorizations on the Teach Me To Platform and the third-party service.
      </p>
      <p>5.3 Third-Party Partners &amp; Integrations.</p>
      <p>
        Parts of Teach Me To may link to third-party services, not owned or
        controlled by Teach Me To, such as Google Maps/Earth. Use of these
        services is subject to the privacy policies of those providers, such as
        Google Maps/Earth Additional Terms of Use, and Google Privacy Policy
        (see here for more information on how Google uses information). Teach Me
        To does not own or control these third parties and when you interact
        with them you are providing your information to them.
      </p>
      <p>6. YOUR RIGHTS</p>
      <p>
        You can exercise any of the rights described in this section consistent
        with applicable law. See here for information on data subject rights
        requests and how to submit a request. Please note that we may ask you to
        verify your identity and request before taking further action on your
        request.
      </p>
      <p>6.1 Managing Your Information.</p>
      <p>
        You can access and update some of your personal information through your
        Account settings. If you have connected your Teach Me To Account to a
        third-party service, like Facebook or Google, you can change your
        settings and unlink from that service in your Account settings. You are
        responsible for keeping your personal information up to date.
      </p>
      <p>6.2 Data Access and Portability.</p>
      <p>
        In some jurisdictions, applicable law may entitle you to request certain
        copies of your personal information or information about how we handle
        your personal information, request copies of personal information that
        you have provided to us in a structured, commonly used, and
        machine-readable format, and/or request that we transmit this
        information to another service provider (where technically feasible).
      </p>
      <p>6.3 Data Erasure.</p>
      <p>
        In certain jurisdictions, you can request that your personal information
        be deleted. Please note that if you request the erasure of your personal
        information:
      </p>
      <p>
        We may retain your personal information as necessary for our legitimate
        business interests, such as prevention of money laundering, fraud
        detection and prevention, and enhancing safety. For example, if we
        suspend a Teach Me To Account for fraud or safety reasons, we may retain
        information from that Teach Me To Account to prevent that Member from
        opening a new Teach Me To Account in the future.
      </p>
      <p>
        We may retain and use your personal information to the extent necessary
        to comply with our legal obligations. For example, Teach Me To and Teach
        Me To Payments may keep information for tax, legal reporting and
        auditing obligations.
      </p>
      <p>
        Information you have shared with others (e.g., Reviews, forum postings)
        will continue to be publicly visible on Teach Me To, even after your
        Teach Me To Account is cancelled. However, attribution of such
        information to you will be removed. Some copies of your information
        (e.g., log records) will remain in our database, but are disassociated
        from personal identifiers.
      </p>
      <p>
        Because we take measures to protect data from accidental or malicious
        loss and destruction, residual copies of your personal information may
        not be removed from our backup systems for a limited period of time.
      </p>
      <p>7. SECURITY</p>
      <p>
        While no organization can guarantee perfect security, we are
        continuously implementing and updating administrative, technical, and
        physical security measures to help protect your information against
        unauthorized access, loss, destruction, or alteration.
      </p>
      <p>8. CHANGES TO THIS PRIVACY POLICY</p>
      <p>
        We reserve the right to modify this Privacy Policy at any time in
        accordance with applicable law. If we do so, we will post the revised
        Privacy Policy and update the &ldquo;Last Updated&rdquo; date at the
        top. In case of material changes, we will also provide you with notice
        of the modification by email at least thirty (30) days before the
        effective date. If you disagree with the revised Privacy Policy, you can
        cancel your Account. If you do not cancel your Account before the date
        the revised Privacy Policy becomes effective, your continued access to
        or use of the Teach Me To Platform will be subject to the revised
        Privacy Policy.
      </p>
      <p>9. CONTACT INFORMATION AND RESPONSIBLE Teach Me To ENTITIES</p>
      <p>
        For questions or complaints about this Privacy Policy or Teach Me
        To&rsquo;s handling of personal information (i) If you reside in the
        United States contact Teach Me To, Inc., ADDRESS (ii) for payments
        related matters please use the contact information provided in the
        Payments Terms of Service page.
      </p>
    </Stack>
  );
}
