import { ChakraBreakpoint } from '../../utils/types';

// // For reference
// const chakraDefaults = {
//   base: '0em',
//   sm: '30em',
//   md: '48em',
//   lg: '62em',
//   xl: '80em',
//   '2xl': '96em',
// };

/**
 * Based on:
 * - 768px should remain as the 'md' breakpoint for consistency with 'isMobile'
 * - 24px gutters between each card
 * - minimum left/right page margins:
 *  - 56px desktop (>= lg)
 *  - 16px mobile (<= md)
 */
export default {
  /**
   * @note For these, the breakpoints are common device widths, and
   * the image size is calculated based off of those
   */
  // 320px - (2 * 16px page margins) - (0 * 24px gutter(s)) = (1 * 288px image(s))
  base: 320,
  // 360px - (2 * 16px page margins) - (0 * 24px gutter(s)) = (1 * 328px image(s))
  '5xs': 360,
  // 375px - (2 * 16px page margins) - (0 * 24px gutter(s)) = (1 * 343px image(s))
  '4xs': 375,
  // 390px - (2 * 16px page margins) - (0 * 24px gutter(s)) = (1 * 358px image(s))
  '3xs': 390,
  // 414px - (2 * 16px page margins) - (0 * 24px gutter(s)) = (1 * 382px image(s))
  '2xs': 414,
  // 428px - (2 * 16px page margins) - (0 * 24px gutter(s)) = (1 * 396px image(s))
  xs: 428,
  // 632px - (2 * 16px page margins) - (1 * 24px gutter(s)) = (2 * 288px image(s))
  sm: 632,
  // 768px - (2 * 16px page margins) - (1 * 24px gutter(s)) = (2 * 356px image(s))
  md: 768, // isMobile breakpoint
  // 1024px - (2 * 56px page margins) - (2 * 24px gutter(s)) = (3 * 288px image(s))
  lg: 1024,

  /**
   * @note For these, the image size is 294px, and the breakpoint is calculated based off of that
   */
  // (4 * 294px image(s)) + (3 * 24px gutter(s)) + (2 * 56px page margins) = 1360px
  xl: 1360,
  '2xl': 1536,
  '3xl': 1920,
} as Record<
  ChakraBreakpoint,
  320 | 360 | 375 | 390 | 414 | 428 | 632 | 768 | 1024 | 1360 | 1536 | 1920
>;
