import { useBreakpoint } from '@chakra-ui/media-query';
import { useMemo } from 'react';

import useIsMapView from './useIsMapView';

import { numOfColumnsMapView } from '../utils/listings';
import type { ChakraBreakpoint } from '../utils/types';

const GUTTER = 24;

/**
 * Is the current breakpoint smaller than lg?
 */
function isLessThanLarge(breakpoint: ChakraBreakpoint): boolean {
  return ['base', '5xs', '4xs', '3xs', '2xs', 'xs', 'sm', 'md'].includes(
    breakpoint,
  );
}

/**
 * Get total number of columns to render
 *
 * @see src/theme/foundations/breakpoints.ts
 */
function getColumnsFromBreakpoint(breakpoint: ChakraBreakpoint): number {
  switch (breakpoint) {
    case 'base':
    case '5xs':
    case '4xs':
    case '3xs':
    case '2xs':
    case 'xs':
      return 1;
    case 'sm':
    case 'md':
    case 'lg':
    case 'xl':
    default:
      return 2;
  }
}

/**
 * Get pro card/location card media size
 *
 * @see src/theme/foundations/breakpoints.ts
 */
function getMediaSize(
  breakpoint: ChakraBreakpoint,
  numOfColumns: number,
  pageMargin: number,
): number {
  return Math.min(
    ((window.innerWidth || 0) / (isLessThanLarge(breakpoint) ? 1 : 1.5) -
      pageMargin * 2 -
      (numOfColumns - 1) * GUTTER) /
      numOfColumns,
    382,
  );
}

/**
 * Get page margins
 *
 * @see src/theme/foundations/breakpoints.ts
 */
function getPageMarginFromBreakpoint(breakpoint: ChakraBreakpoint): number {
  switch (breakpoint) {
    case 'base':
    case '5xs':
    case '4xs':
    case '3xs':
    case '2xs':
    case 'xs':
    case 'sm':
    case 'md':
      return 16;
    case 'lg':
    case 'xl':
    default:
      return 56;
  }
}

/**
 * Use breakpoints to figure out required column/media sizes
 *
 * For any changes, see `src/theme/foundations/breakpoints.ts`
 */
export default function usePageLayout(): {
  breakpoint: ChakraBreakpoint;
  gridWidth: number;
  mediaSize: number;
  numOfColumns: number;
  pageMargin: number;
} {
  const { isMapView } = useIsMapView();

  // Breakpoints are measured in pixels
  const breakpoint = useBreakpoint({
    fallback: 'base',
    ssr: false,
  }) as ChakraBreakpoint;

  // How many columns should be displayed at this breakpoint?
  const numOfColumns: number = useMemo(() => {
    const columnsFromBreakpoint = getColumnsFromBreakpoint(breakpoint);
    // If viewing map, restrict to 2 columns
    if (isMapView) {
      return Math.min(numOfColumnsMapView, columnsFromBreakpoint);
    }
    // Max 4 columns for now
    return Math.min(4, columnsFromBreakpoint);
  }, [breakpoint, isMapView]);

  // What should the page margin be?
  const pageMargin: number = useMemo(
    () => getPageMarginFromBreakpoint(breakpoint),
    [breakpoint],
  );

  // What should the pro card/location card image/video size be?
  const mediaSize: number = useMemo(
    () => getMediaSize(breakpoint, numOfColumns, pageMargin),
    [breakpoint, numOfColumns, pageMargin],
  );

  // What is the total width that all of these columns will take up?
  // Does not include mandatory page margins.
  const gridWidth: number = useMemo(
    // (Media size * number of columns) + (24px gutters)
    () => mediaSize * numOfColumns + (numOfColumns - 1) * GUTTER,
    [mediaSize, numOfColumns],
  );

  return { breakpoint, gridWidth, mediaSize, numOfColumns, pageMargin };
}
