import { Stack } from '@chakra-ui/react';

export default function PaymentTerms() {
  return (
    <Stack spacing={4}>
      <p>1. Your use of the Payment Services</p>
      <p>
        1.1 Teach Me To Payment Services. By using the Payments Services, you
        agree to comply with these Payments Terms. Teach Me To, Inc. may
        temporarily limit or suspend your access to or use of the Payment
        Services, or its features, to carry out maintenance measures that ensure
        the proper functioning of the Payment Services. Teach Me To, Inc. may
        improve, enhance and modify the Payment Services and introduce new
        Payment Services from time to time. Teach Me To, Inc. will provide
        notice to Members of any changes to the Payment Services, unless such
        changes do not materially increase the Members&rsquo; contractual
        obligations or decrease the Members&rsquo; rights under these Payments
        Terms.
      </p>
      <p>
        1.2 Third Party Services. The Payment Services may contain links to
        third-party websites or resources (&ldquo;Third-Party Services&rdquo;).
        Such Third-Party Services are subject to different terms of service and
        privacy policies, and Members should review them. Teach Me To, Inc. is
        not responsible or liable for the use of such Third-Party Services.
        Links to any Third-Party Services are not an endorsement by Teach Me To,
        Inc. of those Third-Party Services.
      </p>
      <p>
        1.3 Your Teach Me To Account. Teach Me To, Inc. may enable features that
        allow you to authorize other Members or third parties to take certain
        actions that affect your Teach Me To account. You may authorize a third
        party to use your Teach Me To account if the feature is enabled for your
        Teach Me To account. You acknowledge and agree that anyone you authorize
        to use your Teach Me To account may use the Payment Services on your
        behalf and that you will be responsible for any payments made by such
        person.
      </p>
      <p>
        1.4 Verification. You authorize Teach Me To, Inc., directly or through
        third parties, to make any inquiries we consider necessary to verify
        your identity. This may include (i) screening you against third-party
        databases or other sources, (ii) requesting reports from service
        providers, (iii) asking you to provide a form of government
        identification (e.g., driver&rsquo;s license or passport), your date of
        birth, your address, and other information; or (iv) requiring you to
        take steps to confirm ownership of your email address, Payment Method(s)
        or Payout Method(s). Teach Me To, Inc. reserves the right to terminate,
        suspend, or limit access to the Payment Services in the event we are
        unable to obtain or verify any of this information.
      </p>
      <p>
        1.5 Additional Terms. Your access to or use of certain Payment Services
        may be subject to, or require you to accept, additional terms and
        conditions. If there is a conflict between these Payments Terms and
        terms and conditions applicable to a specific Payment Service, the
        latter terms and conditions will take precedence with respect to your
        use of or access to that Payment Service, unless specified otherwise.
      </p>
      <p>2. Student Terms</p>
      <p>
        2.1 Adding a Payment Method. When you add a Payment Method to your Teach
        Me To account, you will be asked to provide billing information such as
        name, billing address, and financial instrument information either to
        Teach Me To, Inc. or its third-party payment processor(s). You authorize
        Teach Me To, Inc. and its payment service providers to collect and store
        your Payment Method information.
      </p>
      <p>
        2.2 Payment Method Verification. When you add or use a new Payment
        Method, Teach Me To, Inc. may verify the Payment Method by (i)
        authorizing your Payment Method for one or two nominal amounts via a
        payment service provider, and asking you to confirm those amounts, or
        (ii) requiring you to upload a billing statement. We may, and retain the
        right to, initiate refunds of these amounts from your Payment Method.
        When you add a Payment Method during checkout, we will automatically
        save and add that Payment Method to your Teach Me To account so it can
        be used for a future transaction. You can remove the Payment Method from
        your Teach Me To account as long as it is not associated with an active
        or future reservation.
      </p>
      <p>
        2.3 Payment Authorization. You allow Teach Me To, Inc. to charge your
        Payment Method (including charging more than one payment method), either
        directly or indirectly, for all fees due (including any applicable
        taxes) in connection with your Teach Me To account.
      </p>
      <p>
        2.4 Automatic Update of Payment Method. If your Payment Method&rsquo;s
        account information changes (e.g., account number, routing number,
        expiration date) as a result of re-issuance or otherwise, we may acquire
        that information from our financial services partners or your bank and
        automatically update your Payment Method on file.
      </p>
      <p>
        2.5 Timing of Payment. Teach Me To, Inc. generally charges the Total
        Price due after the Pro accepts your booking request. However, if you
        pay with a push Payment Method (such as Sofort), Teach Me To, Inc. will
        collect the Total Price due at the time of your booking request or after
        the Pro accepts your booking request. Teach Me To, Inc. may offer
        alternative options for the timing and manner of payment. Any additional
        fees for using those alternative payment options will be displayed via
        the Teach Me To Platform and included in the Total Price, and you agree
        to pay such fees by selecting the payment option. Additional terms and
        conditions may apply for the use of an alternative payment option. If
        Teach Me To, Inc. is unable to collect the Total Price due, as
        scheduled, Teach Me To, Inc. will collect the Total Price due at a later
        point in accordance with Section 5.3. Once the payment for your
        requested booking is successfully completed, you will receive a
        confirmation email.
      </p>
      <p>
        2.6 Booking Request Status. If a requested booking is declined either
        because it is not accepted by the Pro or you cancel the booking request
        before it is accepted by the Pro, any amounts collected by Teach Me To,
        Inc. will be refunded to you, and any pre-authorization of your Payment
        Method will be released (if applicable). The timing to receive the
        refund or for the pre-authorization to be released will vary based on
        the Payment Method and any applicable payment system (e.g., Visa,
        MasterCard, etc.) rules.
      </p>
      <p>
        2.7 Payment Restrictions. Teach Me To, Inc. reserves the right to
        decline or limit payments that we believe (i) may violate Teach Me To,
        Inc.&rsquo; risk management policies or procedures, (ii) may violate
        these Payments Terms or the Terms of Service, (iii) are unauthorized,
        fraudulent or illegal; or (iv) expose you, Teach Me To, Teach Me To,
        Inc., or others to risks unacceptable to Teach Me To, Inc.
      </p>
      <p>
        2.8 Payment Service Providers. Payment Methods may involve the use of
        third-party payment service providers. These payment service providers
        may charge you additional fees when processing payments in connection
        with the Payment Services, and Teach Me To, Inc. is not responsible for
        any such fees and disclaims all liability in this regard. Your Payment
        Method may also be subject to additional terms of use. Please review
        them before using your Payment Method.
      </p>
      <p>
        2.9 Your Payment Method, Your Responsibility. Teach Me To, Inc. is not
        responsible for any loss suffered by you as a result of incorrect
        Payment Method information provided by you.
      </p>
      <p>3. Pro Terms</p>
      <p>
        3.1 Payment Collection. Teach Me To, Inc. generally collects the Total
        Price of a booking at the time the Student&rsquo;s booking request is
        accepted by the Pro, unless noted otherwise.
      </p>
      <p>
        3.2 Adding a Payout Method. In order to receive a Payout you must have a
        valid Payout Method linked to your Teach Me To account. When you add a
        Payout Method to your Teach Me To account, you will be asked to provide
        billing information such as name, government identification, tax ID,
        billing address, and financial instrument information either to Teach Me
        To, Inc. or its third-party payment processor(s). Depending on the
        Payout Method selected additional information may be required, such as:
        residential address, name on the account, account type, routing number,
        account number, email address, payout currency, identification number
        and account information associated with a particular payment processor.
        You authorize Teach Me To, Inc. to collect and store your billing
        information and financial instrument information. Teach Me To, Inc. may
        also share your information with governmental authorities as required by
        applicable law.
      </p>
      <p>3.3 Timing of Payout</p>
      <p>
        3.3.1 Subject to and conditional upon successful receipt of the payments
        from Student, Teach Me To, Inc. will generally initiate Payouts to your
        selected Payout Method: 24 hours after the start of the Pro Services.
      </p>
      <p>
        3.3.2 Teach Me To, Inc. may offer you a different Payout time or trigger
        for payment, which may be subject to additional terms and conditions.
        Such Payout timeline may be updated once the Pro meets certain
        eligibility criteria. The time it takes to receive Payouts once released
        by Teach Me To, Inc. may depend upon the Payout Method you select.
      </p>
      <p>
        3.4 Payout. Your Payout for a booking will be the Total Price less
        applicable fees like Teach Me To service fees and applicable taxes. In
        the event of cancellation of a confirmed booking, Teach Me To, Inc. will
        remit the amount you are due (if any) as provided in the Terms of
        Service and applicable cancellation policy.
      </p>
      <p>
        3.5 Payout Restrictions. Teach Me To, Inc. may temporarily place a hold,
        suspend, or cancel any Payout for purposes of preventing unlawful
        activity or fraud, risk assessment, security, or completing an
        investigation; or if we are unable to verify your identity. Furthermore,
        Teach Me To, Inc. may temporarily place a hold on, suspend, or delay
        initiating or processing any Payout due to you under the Terms of
        Service as a result of high volume Booking cancellations or
        modifications arising from a Force Majeure Event (as defined below).
      </p>
      <p>
        3.6 Limits on Payouts. For compliance or operational reasons, Teach Me
        To, Inc. may limit the amount of a Payout. If you are due an amount
        above that limit, Teach Me To, Inc. may make a series of Payouts
        (potentially over multiple days) in order to provide your full Payout
        amount.
      </p>
      <p>
        3.7 Payment Service Providers. Payout Methods may involve the use of
        third-party payment service providers. These payment service providers
        may charge you additional fees when processing Payouts in connection
        with the Payment Services (including deducting charges from the Payout
        amount), and Teach Me To, Inc. is not responsible for any such fees and
        disclaims all liability in this regard. Your Payout Method may also be
        subject to additional terms of use. Please review them before using your
        Payout Method.
      </p>
      <p>
        3.8 Handling of Funds. Teach Me To, Inc. may combine amounts that it
        collects from Students and invest them as permitted under applicable
        laws. Teach Me To, Inc. will retain any interest it earns on those
        investments.
      </p>
      <p>
        3.9 Your Payout Method, Your Responsibility. Teach Me To, Inc. is not
        responsible for any loss suffered by you as a result of incorrect Payout
        Method information provided by you.
      </p>
      <p>
        4. Appointment of Teach Me To, Inc. as Limited Payment Collection Agent
      </p>
      <p>
        4.1 Each Pro, including each Pro Team member, hereby appoints Teach Me
        To, Inc. as the Pro&rsquo;s payment collection agent solely for the
        limited purpose of accepting and processing funds from Students
        purchasing Pro Services on the Pro&rsquo;s behalf.
      </p>
      <p>
        4.2 Each Pro, including each Pro Team member, agrees that payment made
        by a Student through Teach Me To, Inc., shall be considered the same as
        a payment made directly to the Pro, and the Pro will provide the Pro
        Service booked by the Student in the agreed-upon manner as if the Pro
        has received the payment directly from the Student. Each Pro agrees that
        Teach Me To, Inc. may refund the Student in accordance with the{' '}
        <a href="https://www.airbnb.com/terms" target="_self">
          {' '}
          Terms{' '}
        </a>{' '}
        . Each Pro understands that Teach Me To, Inc.&rsquo; obligation to pay
        the Pro is subject to and conditional upon successful receipt of the
        associated payments from Student. Teach Me To, Inc. guarantees payments
        to Pro(s) only for such amounts that have been successfully received by
        Teach Me To, Inc. from Students in accordance with these Payments Terms.
        In accepting appointment as the limited payment collection agent of the
        Pro, Teach Me To, Inc. assumes no liability for any acts or omissions of
        the Pro.
      </p>
      <p>
        4.3 Each Student acknowledges and agrees that, notwithstanding the fact
        that Teach Me To, Inc. is not a party to the agreement between you and
        the Pro(s), including each Pro Team member, Teach Me To, Inc. acts as
        each Pro&rsquo;s payment collection agent for the limited purpose of
        accepting payments from you on behalf of the Pro(s). Upon a
        Student&rsquo;s payment of the funds to Teach Me To, Inc., the
        Student&rsquo;s payment obligation to the Pro(s) for the agreed upon
        amount is extinguished, and Teach Me To, Inc. is responsible for
        remitting the funds successfully received by Teach Me To, Inc. to the
        Pro(s) in the manner described in these Payments Terms. In the event
        that Teach Me To, Inc. does not remit any such amounts, the Pro(s) will
        have recourse only against Teach Me To, Inc. and not the Student
        directly.
      </p>
      <p>5. General Terms</p>
      <p>
        5.1 Fees. Teach Me To, Inc. may charge fees for use of certain Payment
        Services and any applicable fees will be disclosed to you in the Terms
        of Service or via the Teach Me To Platform.
      </p>
      <p>
        5.2 Payment Authorizations. You authorize Teach Me To, Inc. to collect
        from you amounts due pursuant to these Payment Terms and/or the Terms of
        Service by either (i) charging the Payment Method associated with the
        relevant booking, or any other Payment Method on file that you authorize
        in your Teach Me To account (unless you have previously removed the
        authorization to charge such Payment Method(s)), or (ii) by withholding
        the amount from your future Payout. Specifically, you authorize Teach Me
        To, Inc. to collect from you:
      </p>
      <p>
        Any amount due to Teach Me To or Teach Me To, Inc. (e.g., as a result of
        your bookings, Booking Modifications, cancellations, or other actions as
        a Student, Pro or user of the Teach Me To Platform), including
        reimbursement for costs prepaid by Teach Me To or Teach Me To, Inc. on
        your behalf. Any funds collected by Teach Me To, Inc. will set off the
        amount owed by you to Teach Me To or Teach Me To, Inc. and extinguish
        your obligation to Teach Me To or Teach Me To, Inc..
      </p>
      <p>
        Any amount due to a Pro from a Student which Teach Me To collects as the
        Pro&rsquo;s payment collection agent as further set out in Section 4
        above.
      </p>
      <p>Taxes, where applicable and as set out in the Terms of Service.</p>
      <p>
        Any amount you pay through the Resolution Center in connection with your
        Teach Me To account.
      </p>
      <p>
        Overstay penalties payable under the Terms of Service, including any
        cost and expenses incurred in collecting the overstay penalty.
      </p>
      <p>
        Any service fees or cancellation fees imposed pursuant to the Terms of
        Service (e.g., if, as a Pro, you cancel a confirmed booking).
      </p>
      <p>
        Any amounts already paid to you as a Pro despite a Student cancelling a
        confirmed booking or Teach Me To deciding that it is necessary to cancel
        a booking in accordance with the Terms of Service{' '}
        <a href="https://www.airbnb.com/terms" target="_self">
          {' '}
          ,{' '}
        </a>{' '}
        Student Refund Policy, Extenuating Circumstances Policy, or other
        applicable cancellation policy. You agree that in the event you have
        already been paid, Teach Me To, Inc. will be entitled to recover the
        amount of any such Student refund from you, including by subtracting
        such refund amount out from any future Payouts due to you.
      </p>
      <p>
        In addition to any amount due as outlined above, if there are delinquent
        amounts or chargebacks associated with your Payment Method, you may be
        charged fees that are incidental to Teach Me To, Inc. collection of
        these delinquent amounts and chargebacks. Such fees or charges may
        include collection fees, convenience fees or other third-party charges.
      </p>
      <p>5.3 Collections</p>
      <p>
        5.3.1 If Teach Me To, Inc. is unable to collect any amounts you owe
        under these Payments Terms, Teach Me To, Inc. may engage in collection
        efforts to recover such amounts from you.
      </p>
      <p>
        5.3.2 Teach Me To, Inc. will deem any owed amounts overdue when: (a) for
        authorized charges, one hundred and twenty (120) days have elapsed after
        Teach Me To, Inc. first attempts to charge your Payment Method or the
        associated services have been provided, whichever is later; and (b) for
        withholdings from a Pro&rsquo;s future Payouts, two hundred and seventy
        (270) days have elapsed after the adjustment is made to the Pro&rsquo;s
        Teach Me To account or the associated services have been provided,
        whichever is later.
      </p>
      <p>
        5.3.3 Teach Me To, Inc. will deem any overdue amounts not collected to
        be in default when three hundred and sixty five (365) days have elapsed:
        (a) for authorized charges, after Teach Me To, Inc. first attempts to
        charge your Payment Method or the associated services have been
        provided, whichever is later; and (b) for withholdings from a
        Pro&rsquo;s future Payouts, after the adjustment is made to the
        Pro&rsquo;s Teach Me To account or the associated services have been
        provided, whichever is later.
      </p>
      <p>
        5.3.4 You hereby explicitly agree that all communications in relation to
        amounts owed will be made by electronic mail or by phone, as provided to
        Teach Me To and/or Teach Me To, Inc. by you. Such communications may be
        made by Teach Me To, Teach Me To, Inc., or by anyone on their behalf,
        including but not limited to a third-party collection agent.
      </p>
      <p>5.4 Payment Processing Errors</p>
      <p>
        5.4.1 Teach Me To, Inc. will take the necessary steps to rectify any
        payment processing errors that we become aware of. These steps may
        include crediting or debiting (as appropriate) the original Payout
        Method or Payment Method used or selected by you, so that you end up
        receiving or paying the correct amount. This may be performed by Teach
        Me To, Inc. or a third party such as your financial institution. We may
        also take steps to recover funds sent to you in error (including but not
        limited to an event of duplicate payments made to you due to a
        processing error), by reducing, setting off and/or debiting the amount
        of such funds from any future Payouts owed to you.
      </p>
      <p>
        5.4.2 To the extent you receive any funds in error, you agree to
        immediately return such funds to Teach Me To, Inc..
      </p>
      <p>5.5 Refunds</p>
      <p>
        5.5.1 Any refunds or credits due to a Member pursuant to the Terms of
        Service, Extenuating Circumstances Policy, and the Student Refund
        Policy, will be initiated and remitted by Teach Me To, Inc. in
        accordance with these Payments Terms.
      </p>
      <p>
        5.5.2 Subject to this Section 5.5.2, Teach Me To, Inc. will process
        refunds immediately, however, the timing to receive any refund will
        depend on the Payment Method and any applicable payment system (e.g.,
        Visa, Mastercard, etc.) rules. In the event of a Force Majeure Event
        that may affect the processing and settlement of refunds, Teach Me To,
        Inc. will initiate and process the refund as soon as is practicable.
      </p>
      <p>6. Damage Claims and Security Deposits</p>
      <p>
        6.1 If you as a Student (i) agree to pay the Pro in connection with a
        Damage Claim, or (ii) Teach Me To determines that you are responsible
        for damaging any real or personal property at a Listing pursuant to the
        Terms of Service, you authorize Teach Me To, Inc. to charge the Payment
        Method used to make the booking in order to collect any security deposit
        associated with the Listing, as well as any fees, costs and/or expenses
        associated with the Damage Claim. If Teach Me To, Inc. is unable to
        collect from the Payment Method used to make the booking, you agree that
        Teach Me To, Inc. may charge any other Payment Method on file in your
        Teach Me To account at the time of the Damage Claim (unless you have
        previously removed the authorization to charge such Payment Method(s)).
      </p>
      <p>
        6.2 You agree that Teach Me To, Inc. may seek to recover from you under
        any insurance policies you maintain and that Teach Me To, Inc. may also
        pursue against you any remedies it may have available, including, but
        not limited to, in relation to any payment requests made by Pros under
        the Teach Me To Pro Guarantee.
      </p>
      <p>7. Abandoned Property</p>
      <p>
        If Teach Me To, Inc. holds funds due to you (e.g., because we are unable
        to issue you a Payout or refund) or you do not use a gift card or gift
        credit for the relevant period of time set forth by your state, country,
        or other governing body in its unclaimed property laws, we may process
        the funds due to you in accordance with our legal obligations, including
        by reporting and escheating (sending) such funds to the appropriate
        governing body as required by applicable unclaimed property laws.
      </p>
      <p>8. Prohibited Activities</p>
      <p>
        8.1 You are solely responsible for compliance with any and all laws,
        rules, regulations, and tax obligations that may apply to your use of
        the Payment Services. In connection with your use of the Payment
        Services, you may not and you agree that you will not and will not
        assist or enable others to:
      </p>
      <p>breach or circumvent any applicable laws or regulations;</p>
      <p>
        breach or circumvent any agreements with third parties, third-party
        rights, or the Terms of Service, Policies, or Standards;
      </p>
      <p>
        use the Payment Services for any commercial or other purposes that are
        not expressly permitted by these Payments Terms;
      </p>
      <p>
        register or use any Payment Method or Payout Method with your Teach Me
        To account that is not yours or you do not have authorization to use;
      </p>
      <p>
        avoid, bypass, remove, deactivate, impair, descramble, or otherwise
        circumvent any technological measure implemented by Teach Me To, Inc. or
        any of Teach Me To, Inc.&rsquo; providers or any other third party to
        protect the Payment Services;
      </p>
      <p>
        take any action that damages or adversely affects, or could damage or
        adversely affect, the performance or proper functioning of the Payment
        Services;
      </p>
      <p>
        attempt to decipher, decompile, disassemble, or reverse engineer any of
        the software used to provide the Payment Services; or
      </p>
      <p>
        violate or infringe anyone else&rsquo;s rights or otherwise cause harm
        to anyone.
      </p>
      <p>
        8.2 You may not use or assist others to use the Payment Services to send
        or receive funds: (i) into any United States embargoed countries; or
        (ii) to anyone on the U.S. Treasury Department&rsquo;s list of Specially
        Designated Nationals or the U.S. Department of Commerce&rsquo;s Denied
        Persons List or Entity List. You represent and warrant that: (i) neither
        you nor your Pro Services are located or take place in a country that is
        subject to a U.S. Government embargo, or that has been designated by the
        U.S. Government as a &ldquo;terrorist supporting&rdquo; country; and
        (ii) you are not listed on any U.S. Government list of prohibited or
        restricted parties. In addition to complying with the above, you must
        also comply with any relevant export control laws in your local
        jurisdiction.
      </p>
      <p>9. Force Majeure</p>
      <p>
        Teach Me To, Inc. shall not be liable for any delay or failure to
        fulfill any obligation under these Payments Terms resulting from causes
        outside the reasonable control of Teach Me To or Teach Me To, Inc.,
        including, but not limited to, acts of God, natural disasters, war,
        terrorism, riots, embargoes, acts of civil or military authorities,
        fire, floods, accidents, pandemics, epidemics or disease, strikes or
        shortages of transportation facilities, fuel, energy, labor or materials
        (&ldquo;Force Majeure Event&rdquo;).
      </p>
      <p>10. Disclaimers</p>
      <p>
        10.1 If you choose to use the Payment Services, you do so voluntarily
        and at your sole risk. To the maximum extent permitted by law, the
        Payment Services are provided &ldquo;as is&rdquo;, without warranty of
        any kind, either express or implied.
      </p>
      <p>
        10.2 Notwithstanding Teach Me To, Inc.&rsquo; appointment as the limited
        payment collection agent of Pros pursuant to Section 4, Teach Me To,
        Inc. explicitly disclaims all liability for any act or omission of any
        Member or other third party. Teach Me To, Inc. does not have any duties
        or obligations as agent for each Pro except to the extent expressly set
        forth in these Payments Terms, and any additional duties or obligations
        as may be implied by law are, to the maximum extent permitted by
        applicable law, expressly excluded.
      </p>
      <p>
        10.3 If we choose to conduct identity verification on any Member, to the
        extent permitted by applicable law, we disclaim warranties of any kind,
        either express or implied, that such checks will identify prior
        misconduct by a Member or guarantee that a Member will not engage in
        misconduct in the future.
      </p>
      <p>
        10.4 The foregoing disclaimers apply to the maximum extent permitted by
        law. You may have other statutory rights or warranties which cannot
        lawfully be excluded. However, the duration of any statutorily required
        warranties shall be limited to the maximum extent (if any) permitted by
        law.
      </p>
      <p>11. Liability</p>
      <p>
        11.1 Except as provided in Section 11.2, you acknowledge and agree that,
        to the maximum extent permitted by law, the entire risk arising out of
        your access to and use of the Payment Services remains with you. If you
        permit or authorize another person to use your Teach Me To account in
        any way, you are responsible for the actions taken by that person.
        Neither Teach Me To, Inc. nor any other party involved in creating,
        producing, or delivering the Payment Services will be liable for any
        incidental, special, exemplary, or consequential damages, including lost
        profits, loss of data or loss of goodwill, service interruption,
        computer damage or system failure or the cost of substitute products or
        services, or for any damages for personal or bodily injury or emotional
        distress arising out of or in connection with (i) these Payments Terms,
        (ii) from the use of or inability to use the Payment Services, or (iii)
        from any communications, interactions, or meetings with other Members or
        other persons with whom you communicate, interact, transact, or meet
        with as a result of your use of the Payment Services, whether based on
        warranty, contract, tort (including negligence), product liability, or
        any other legal theory, and whether or not Teach Me To, Inc. has been
        informed of the possibility of such damage, even if a limited remedy set
        forth herein is found to have failed of its essential purpose. Except
        for our obligations to pay amounts to applicable Pros pursuant to these
        Payments Terms or an approved payment request under the Teach Me To Pro
        Guarantee, in no event will Teach Me To, Inc.&rsquo; aggregate liability
        arising out of or in connection with these Payments Terms and your use
        of the Payment Services including, but not limited to, from your use of
        or inability to use the Payment Services, exceed (i) the amounts you
        have paid or owe for bookings via the Teach Me To Platform as a Student
        in the twelve (12) month period prior to the event giving rise to the
        liability, or if you are a Pro, the amounts paid by Teach Me To, Inc. to
        you in the twelve (12) month period prior to the event giving rise to
        the liability, or (ii) one hundred U.S. dollars (US$100), if no such
        payments have been made, as applicable. The limitations of damages set
        forth above are fundamental elements of the basis of the bargain between
        Teach Me To, Inc. and you. Some jurisdictions do not allow the exclusion
        or limitation of liability for consequential or incidental damages, so
        the above limitation may not apply to you. If you reside outside of the
        U.S., this does not affect Teach Me To, Inc.&rsquo; liability for death
        or personal injury arising from its negligence, nor for fraudulent
        misrepresentation, misrepresentation as to a fundamental matter, or any
        other liability which cannot be excluded or limited under applicable
        law.
      </p>
      <p>12. Indemnification</p>
      <p>
        To the maximum extent permitted by applicable law, you agree to release,
        defend (at Teach Me To, Inc.&rsquo; option), indemnify, and hold Teach
        Me To, Inc. and its affiliates and subsidiaries, and their officers,
        directors, employees, and agents, harmless from and against any claims,
        liabilities, damages, losses, and expenses, including, without
        limitation, reasonable legal and accounting fees, arising out of or in
        any way connected with (i) your breach of these Payments Terms; (ii)
        your improper use of the Payment Services; (iii) your failure, or our
        failure at your direction, to accurately report, collect or remit taxes;
        or (iv) your breach of any laws, regulations, or third-party rights.
      </p>
      <p>13. Modification, Term, Termination, and other Measures</p>
      <p>
        13.1 Modification. Except as otherwise required by applicable law, Teach
        Me To, Inc. may modify these Payments Terms at any time. If we make
        material changes to these Payment Terms, we will post the revised
        Payment Terms on the Teach Me To Platform and update the &ldquo;Last
        Updated&rdquo; date at the top of these Payment Terms. If you are
        affected by the modification, we will also provide you with notice of
        the modifications at least thirty (30) days before the date they become
        effective. If you do not terminate your agreement before the date the
        revised Payment Terms become effective, your continued use of the
        Payment Services will constitute acceptance of any changes to the
        revised Payments Terms.
      </p>
      <p>
        13.2 Term. This agreement between you and Teach Me To, Inc. reflected by
        these Payment Terms is effective when you create a Teach Me To account
        or use the Payment Services and remains in effect until either you or we
        terminate this agreement in accordance with Section 13.3.
      </p>
      <p>
        13.3 Termination. You may terminate this agreement at any time by
        sending us an email or by deleting your Teach Me To account. Terminating
        this agreement will also serve as notice to cancel your Teach Me To
        account pursuant to the Terms of Service. Without limiting our rights
        specified below, Teach Me To, Inc. may terminate this agreement for
        convenience at any time by giving you thirty (30) days&apos; notice via
        email to your registered email address. Teach Me To, Inc. may also
        terminate this agreement immediately without notice if (i) you have
        materially breached your obligations under this agreement; (ii) you have
        provided inaccurate, fraudulent, outdated, or incomplete information;
        (iii) you have violated applicable laws, regulations, or third-party
        rights; or (iv) Teach Me To, Inc. believes in good faith that such
        action is reasonably necessary to protect other Members, Teach Me To,
        Teach Me To, Inc., or third parties.
      </p>
      <p>
        13.4 Suspension and Other Measures. Teach Me To, Inc. may limit or
        temporarily or permanently suspend your use of or access to the Payment
        Services (i) to comply with applicable law, or the order or request of a
        court, law enforcement, or other administrative agency or governmental
        body, (ii) if you have breached these Payments Terms, the Terms of
        Service, applicable laws, regulations or third-party rights, (iii) if
        you have provided inaccurate, fraudulent, outdated, or incomplete
        information regarding a Payment Method or Payout Method, (iv) for any
        amounts you owe under these Payments Term that are overdue or in
        default, or (v) if Teach Me To, Inc. believes in good faith that such
        action is reasonably necessary to protect the personal safety or
        property of Teach Me To, its Members, Teach Me To, Inc., or third
        parties, or to prevent fraud or other illegal activity. Further, for
        unsuccessful payment due to card expiration, insufficient funds, or
        otherwise, we may temporarily suspend your access to the Payment
        Services until we can charge a valid Payment Method
      </p>
      <p>
        13.5 Appeal. If Teach Me To, Inc. takes any of the measures described in
        Section 13.3 and 13.4 you may appeal such a decision by contacting
        customer service.
      </p>
      <p>
        13.6 Effect of Termination. If you cancel your Teach Me To account as a
        Pro or Teach Me To, Inc. takes any of the measures described above,
        Teach Me To, Inc. may provide a full refund to any Students with
        confirmed booking(s), and you will not be entitled to any compensation
        for pending or confirmed bookings that were cancelled. If you cancel
        your Teach Me To account as a Student, Teach Me To, Inc. will initiate a
        refund for any confirmed booking(s) based on the Listing&rsquo;s
        cancellation policy. If your access to or use of the Payment Services
        has been suspended or limited or this agreement has been terminated by
        us, you may not register a new Teach Me To account or attempt to access
        and use the Payment Services through an Teach Me To account of another
        Member.
      </p>
      <p>
        13.7 Survival. Sections 5 through 20 of these Payments Terms shall
        survive any termination or expiration of this agreement.
      </p>
      <p>14. Governing Law and Dispute Resolution</p>
      <p>
        14.1 If you are contracting with Teach Me To, Inc. US, these Payments
        Terms will be interpreted in accordance with the laws of the State of
        California and the United States of America, without regard to
        conflict-of-law provisions. Legal proceedings (other than small claims
        actions) that are excluded from the Arbitration Agreement in Section 15
        must be brought in state or federal court in San Francisco, California,
        unless we both agree to some other location. You and we both consent to
        venue and personal jurisdiction in San Francisco, California.
      </p>
      <p>15. United States Arbitration Agreement</p>
      <p>
        15.1 Overview of Dispute Resolution Process. Teach Me To, Inc. is
        committed to participating in a consumer-friendly dispute resolution
        process. To that end, these Payment Terms provide for a two-part process
        for individuals to whom this Section 15 applies: (1) an informal
        negotiation directly with Teach Me To&rsquo;s customer service team
        (described in paragraph 15.3, below), and if necessary (2) a binding
        arbitration administered by the American Arbitration Association
        (&ldquo;AAA&rdquo;). You and Teach Me To, Inc. each retain the right to
        seek relief in small claims court as an alternative to arbitration
      </p>
      <p>
        15.2 Mandatory Pre-Arbitration Dispute Resolution and Notification. At
        least 30 days prior to initiating an arbitration, you and Teach Me To,
        Inc. each agree to notify the other party of the dispute in writing and
        attempt in good faith to negotiate an informal resolution. You must send
        your notice of dispute to Teach Me To, Inc. by mailing it to Teach Me
        To, Inc.&rsquo; agent for service: AGENT. Teach Me To, Inc. will send
        its notice of dispute to the email address associated with your Teach Me
        To account. A notice of dispute must include: the party&rsquo;s name and
        preferred contact information, a brief description of the dispute, and
        the relief sought. If the parties are unable to resolve the dispute
        within the 30-day period, only then may either party commence
        arbitration by filing a written Demand for Arbitration (available at
        www.adr.org) with the AAA and providing a copy to the other party as
        specified in the AAA Rules (available at www.adr.org).
      </p>
      <p>
        15.3 Agreement to Arbitrate. You and Teach Me To, Inc. mutually agree
        that any dispute, claim or controversy arising out of or relating to
        these Payments Terms or the applicability, breach, termination,
        validity, enforcement or interpretation thereof, or any use of the
        Payment Services (collectively, &ldquo;Disputes&rdquo;) will be settled
        by binding individual arbitration (the &ldquo;Arbitration
        Agreement&rdquo;). If there is a dispute about whether this Arbitration
        Agreement can be enforced or applies to our Dispute, you and Teach Me
        To, Inc. agree that the arbitrator will decide that issue.
      </p>
      <p>
        15.4 Exceptions to Arbitration Agreement. You and Teach Me To, Inc. each
        agree that the following causes of action and/or claims for relief are
        exceptions to the Arbitration Agreement and will be brought in a
        judicial proceeding in a court of competent jurisdiction (as defined by
        Section 15): (i) any claim or cause of action seeking emergency
        injunctive relief based on exigent circumstances (e.g., imminent danger
        or commission of a crime, hacking, cyber-attack); or (ii) a request for
        the remedy of public injunctive relief. You and Teach Me To, Inc. agree
        that the remedy of public injunctive relief will proceed after the
        arbitration of all arbitrable claims, remedies, or causes of action, and
        will be stayed pending the outcome of the arbitration pursuant to
        section 3 of the Federal Arbitration Act.
      </p>
      <p>
        15.5 Arbitration Rules and Governing Law. This Arbitration Agreement
        evidences a transaction in interstate commerce and the Federal
        Arbitration Act governs all substantive and procedural interpretation
        and enforcement of this provision. The arbitration will be administered
        by AAA in accordance with the Consumer Arbitration Rules and/or other
        AAA arbitration rules determined to be applicable by the AAA (the
        &ldquo;AAA Rules&rdquo;) then in effect, except as modified here. The
        AAA Rules are available at www.adr.org. In order to initiate
        arbitration, a completed written demand (available at www.adr.org) must
        be filed with the AAA and provided to the other party, as specified in
        the AAA rules.
      </p>
      <p>
        15.6 Modification to AAA Rules - Arbitration Hearing/Location. In order
        to make the arbitration most convenient to you, Teach Me To, Inc. agrees
        that any required arbitration hearing may be conducted, at your option:
        (a) in the U.S county where you reside; (b) in San Francisco County; (c)
        via phone or video conference; or (d) if all parties agree, by solely
        the submission of documents to the arbitrator.
      </p>
      <p>
        15.7 Modification of AAA Rules - Attorney&rsquo;s Fees and Costs. Your
        arbitration fees and your share of arbitrator compensation shall be
        governed by the AAA Rules and, where appropriate, limited by the AAA
        Consumer Rules. If such costs are determined by the arbitrator to be
        excessive, Teach Me To, Inc. will pay all arbitration fees and expenses.
        Either party may make a request that the arbitrator award
        attorneys&rsquo; fees and costs upon proving that the other party has
        asserted a claim, cross-claim or defense that is groundless in fact or
        law, brought in bad faith or for the purpose of harassment, or is
        otherwise frivolous, as allowed by applicable law and the AAA Rules.
      </p>
      <p>
        15.8 Arbitrator&rsquo;s Decision. The arbitrator&rsquo;s decision will
        include the essential findings and conclusions upon which the arbitrator
        based the award. Judgment on the arbitration award may be entered in any
        court with proper jurisdiction. The arbitrator may award any relief
        allowed by law or the AAA Rules, but declaratory or injunctive relief
        may be awarded only on an individual basis and only to the extent
        necessary to provide relief warranted by the claimant&rsquo;s individual
        claim.
      </p>
      <p>
        15.9 Jury Trial Waiver. You and Teach Me To, Inc. acknowledge and agree
        that we are each waiving the right to a trial by jury as to all
        arbitrable Disputes.
      </p>
      <p>
        15.10 No Class Actions or Representative Proceedings. You and Teach Me
        To, Inc. acknowledge and agree that, to the fullest extent permitted by
        law, we are each waiving the right to participate as a plaintiff or
        class member in any purported class action lawsuit, class-wide
        arbitration, private attorney general action, or any other
        representative or consolidated proceeding. Unless we agree in writing,
        the arbitrator may not consolidate more than one party&rsquo;s claims
        and may not otherwise preside over any form of any class or
        representative proceeding. If there is a final judicial determination
        that applicable law precludes enforcement of the waiver contained in
        this paragraph as to any claim, cause of action or requested remedy,
        then that claim, cause of action or requested remedy, and only that
        claim, cause of action or requested remedy, will be severed from this
        agreement to arbitrate and will be brought in a court of competent
        jurisdiction. In the event that a claim, cause of action or requested
        remedy is severed pursuant to this paragraph, then you and we agree that
        the claims, causes of action or requested remedies that are not subject
        to arbitration will be stayed until all arbitrable claims, causes of
        action and requested remedies are resolved by the arbitrator.
      </p>
      <p>
        15.11 Severability. Except as provided in Section 15.10, in the event
        that any portion of this Arbitration Agreement is deemed illegal or
        unenforceable, such provision shall be severed and the remainder of the
        Arbitration Agreement will be given full force and effect.
      </p>
      <p>
        15.12 Changes to Agreement to Arbitrate. If Teach Me To, Inc. changes
        this Section 15 after the date you last accepted these Payments Terms
        (or accepted any subsequent changes to these Payments Terms), you may
        reject that change by sending us written notice (including by email)
        within thirty (30) days of the date the change is effective. Rejecting a
        new change, however, does not revoke or alter your prior consent to any
        earlier agreements to arbitrate any Dispute between you and Teach Me To,
        Inc. (or your prior consent to any subsequent changes thereto), which
        will remain in effect and enforceable as to any Dispute between you and
        Teach Me To, Inc..
      </p>
      <p>
        15.13 Survival. Except as provided in Section 15.11 and subject to
        Section 13.7, this Section 15 will survive any termination of these
        Payments Terms and will continue to apply even if you stop using the
        Payment Services or terminate your Teach Me To account.
      </p>
      <p>16. Miscellaneous</p>
      <p>
        16.1 Interpreting these Payments Terms. Except as they may be
        supplemented by additional terms and conditions, policies, guidelines,
        or standards, these Payments Terms constitute the entire agreement
        between Teach Me To, Inc. and you regarding the subject matter hereof,
        and supersede any and all prior oral or written understandings or
        agreements between Teach Me To, Inc. and you regarding the Payment
        Services. If any provision of these Payments Terms is held to be invalid
        or unenforceable, such provision will be struck and will not affect the
        validity and enforceability of the remaining provisions.
      </p>
      <p>
        16.2 No Waiver. Teach Me To, Inc.&rsquo; failure to enforce any right or
        provision in these Payments Terms will not constitute a waiver of such
        right or provision unless acknowledged and agreed to by us in writing.
        Except as expressly set forth in these Payments Terms, the exercise by
        either party of any of its remedies under these Payments Terms will be
        without prejudice to its other remedies under these Payments Terms or
        otherwise permitted under law.
      </p>
      <p>
        16.3 Assignment. You may not assign, transfer, or delegate this
        agreement or your rights and obligations hereunder without Teach Me To,
        Inc.&rsquo; prior written consent. Teach Me To, Inc. may without
        restriction assign, transfer, or delegate this agreement and any rights
        and obligations, at its sole discretion, with thirty (30) days&rsquo;
        prior notice.
      </p>
      <p>
        16.4 Notices. Unless specified otherwise, any notices or other
        communications permitted or required under this agreement, will be in
        writing and given by Teach Me To, Inc. via email or Teach Me To Platform
        notification, and depending on your notification setting, messaging
        service (including SMS and WeChat). The date of receipt will be deemed
        the date on which Teach Me To, Inc. transmits the notice.
      </p>
      <p>17. Additional Clauses for Users that are Businesses</p>
      <p>
        The following paragraphs also apply if you are using the Payment
        Services as a representative (&ldquo;Representative&rdquo;) acting on
        behalf a business, company or other legal entity (in such event, for
        purposes of the Payments Terms, &ldquo;you&rdquo; and &ldquo;your&rdquo;
        will refer and apply to that business, company or other legal entity).
      </p>
      <p>
        17.1 You accept the Payment Terms and you will be responsible for any
        act or omission of employees or third-party agents using the Payment
        Service on your behalf.
      </p>
      <p>
        17.2 You and your Representative individually affirm that you are
        authorized to provide the information described in Section 2.1 and
        Section 3.2 and your Representative has the authority to bind you to
        these Payments Terms. We may require you to provide additional
        information or documentation demonstrating your Representative&rsquo;s
        authority.
      </p>
      <p>
        17.3 You represent and warrant to us that: (i) you are duly organized,
        validly existing and in good standing under the laws of the country in
        which your business is registered and that you are registering for
        receiving the Payment Services; and (ii) you have all requisite right,
        power, and authority to enter into this agreement, perform your
        obligations, and grant the rights, licenses, and authorizations in this
        agreement.
      </p>
      <p>
        17.4 If you are using your Payment Method for the benefit of your
        employees or other authorized third-party in connection with Teach Me To
        for Work, as permitted by your account, you authorize Teach Me To, Inc.
        to charge your Payment Method for bookings requested by employees at
        your company or other permitted third-party.
      </p>
      <p>
        17.5 For any Payout Method linked to your Teach Me To account, you
        authorize Teach Me To, Inc. to store the Payout Method, remit payments
        using the Payout Method for bookings associated with your Teach Me To
        account and take any other action as permitted in the Payments Terms in
        respect of the Payout Method.
      </p>
      <p>
        17.6 If you handle, store or otherwise process payment card information
        on behalf of anyone or any third-party, you agree to comply on an
        ongoing basis with applicable data privacy and security requirements
        under the current Payment Card Industry Data Security Standard with
        regards to the use, access, and storage of such credit card information.
      </p>
      <p>18. Contacting Teach Me To, Inc.</p>
      <p>
        You may contact Teach Me To, Inc. regarding the Payment Services using
        the information below: Contact{' '}
      </p>
    </Stack>
  );
}
