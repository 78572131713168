import { SelectedLocation } from './types';

export type LatLng = [number, number];

const deg2rad = (deg: number): number => deg * (Math.PI / 180);

export const getDistance = (
  currentLocation: LatLng,
  targetLocation: LatLng,
): number => {
  try {
    const [lat1, lon1] = currentLocation;
    const [lat2, lon2] = targetLocation;
    // Radius of the earth in miles
    const earthRadiusInMiles = 3959;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    // Distance in miles
    const distanceInMiles = earthRadiusInMiles * c;
    return distanceInMiles;
  } catch (error) {
    return 0;
  }
};

export const getDistanceAway = (
  currentLocation: LatLng,
  proLocations: LatLng[],
): number => {
  const distances = proLocations?.map((location) =>
    getDistance(currentLocation, location),
  );
  const closestDistance = Math.min(...distances);

  if (closestDistance < 1) {
    return 1;
  }

  if (closestDistance === Infinity) {
    return undefined;
  }

  return Number(closestDistance?.toFixed(0));
};

export type ProLocation = Pick<
  SelectedLocation,
  'city' | 'id' | 'latitude' | 'longitude' | 'placeName' | 'state'
>;

export type ClosestLocation = {
  location: ProLocation;
  distance: number;
};

export function getClosestLocation<T extends ProLocation>(
  currentLocation: LatLng,
  proLocations: T[] = [],
): {
  location: T;
  distance: number;
} {
  const distances =
    proLocations?.map((location) => ({
      location,
      distance: getDistance(currentLocation, [
        location?.latitude,
        location?.longitude,
      ]),
    })) || [];

  const closestDistance = Math.min(...distances.map((d) => d?.distance));
  const closestLocation = distances?.find(
    (d) => d?.distance === closestDistance,
  )?.location;

  return {
    location: closestLocation,
    distance: Number(closestDistance?.toFixed(0)),
  };
}
