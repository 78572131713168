import { BoxProps } from '@chakra-ui/react';

import useIsMobile from './useIsMobile';

import { BookingStep } from '../utils/types';

const MAXIMUM_DESKTOP_BOOKING_WIDGET_HEIGHT = 736;
const SCROLLABLE_BOOKING_STEPS: BookingStep[] = [
  BookingStep.Exposed,
  BookingStep.Confirm,
];

export default function useBookingWidgetHeight(): {
  bookingWidgetHeight: BoxProps['h'];
  isBookingWidgetInnerContentScrollable: boolean;
} {
  const { isMobile } = useIsMobile();

  const bookingWidgetHeight: BoxProps['h'] = isMobile
    ? 'full'
    : Math.min(window.innerHeight, MAXIMUM_DESKTOP_BOOKING_WIDGET_HEIGHT);

  const isBookingWidgetInnerContentScrollable: boolean =
    window.innerHeight < (isMobile ? 827 : 882) ||
    SCROLLABLE_BOOKING_STEPS.some((step) =>
      window.location.pathname.endsWith(step),
    );

  return { bookingWidgetHeight, isBookingWidgetInnerContentScrollable };
}
