import type { CurrentLocation, MapBoxFeature } from './types';

export default function constructLocationFromFeature(
  feature: MapBoxFeature,
  searchedAddressName: string,
): CurrentLocation {
  if (!feature) throw new Error('No feature found');
  return {
    latitude: feature.properties.coordinates?.latitude,
    longitude: feature.properties.coordinates?.longitude,
    address: searchedAddressName || feature.properties.context?.address?.name,
    city: feature.properties.context?.place?.name,
    country: feature.properties.context?.country?.name,
    postalCode: feature.properties.context?.postcode?.name,
    state: feature.properties.context?.region?.name,
    stateShortCode: feature.properties.context?.region?.region_code_full,
  };
}
