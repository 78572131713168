import {
  Box,
  Heading,
  HStack,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { HiChatAlt2 } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';

import { useModal } from '../../../context/ModalContext';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import { useAppSelector } from '../../../state/hooks';
import { selectSelectedListing } from '../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import { BookingStep } from '../../../utils/types';
import { Show } from '../../Show';

interface Props {
  heading: string;
  headingVariant?: string;
  hideBackButton?: boolean;
  children?: React.ReactNode;
  bg?: string;
  showChatButton?: boolean;
}

export default function BookingWidgetHeader({
  heading,
  headingVariant = 'h5',
  hideBackButton = false,
  children,
  bg = 'bg-surface',
  showChatButton = true,
}: Props) {
  const navigate = useNavigate();
  const { handleOpen } = useModal();
  const selectedListing = useAppSelector(selectSelectedListing);
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();
  const params = useParams();

  const listingUrl = `/listings/${params?.skill}/${params?.slug}`;

  const isFullscreenCheckout = window.location.pathname.startsWith('/checkout');

  const handleOnChatRequest = useCallback(() => {
    capturePostHogEvent('chatRequestBubble');
    handleOpen('chatRequest', selectedListing?.proName);
  }, [handleOpen, selectedListing]);

  const onGoBack = () => {
    switch (true) {
      case params.step === BookingStep.Exposed:
      case params.step === BookingStep.SchedulePreference:
      case params.step === BookingStep.LessonLength:
      case params.step === BookingStep.Participants:
      case params.step === BookingStep.Datepicker:
      case params.step === BookingStep.Packages:
      case params.step === BookingStep.Address:
        return navigate(
          `${listingUrl}/${
            BookingStep.Confirm
          }?${getListingSearchParamsWithPartialInput()}`,
          {
            replace: true,
          },
        );
      case params.step === BookingStep.Confirm || isFullscreenCheckout: {
        return navigate(`${listingUrl}`, {
          replace: true,
        });
      }
      default:
        return navigate(-1);
    }
  };

  return (
    <VStack px="6" pt="6" pb="4" w="full" bg={bg} zIndex="4">
      <HStack alignItems="center" w="full">
        {hideBackButton ? (
          <Box w="8" />
        ) : (
          <IconButton
            w="8"
            size="xs"
            aria-label="Go back"
            icon={<BiArrowBack size={24} />}
            onClick={onGoBack}
            variant="ghost"
          />
        )}
        <Heading w="full" variant={headingVariant} textAlign="center">
          {heading}
        </Heading>
        {showChatButton ? (
          <Icon
            as={HiChatAlt2}
            color="gray.700"
            fontSize="24px"
            onClick={handleOnChatRequest}
            _hover={{ cursor: 'pointer' }}
            width={8}
            slope={4}
          />
        ) : (
          <Box w="8" />
        )}
      </HStack>

      <Show condition={!!children}>{children}</Show>
    </VStack>
  );
}
