import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  ModalContent,
  Text,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import IconAtHomeLessons from '../../../assets/svg/IconAtHomeLessons';
import WithAnimOnLayout from '../../../hocs/WithAnimOnLayout';
import useIsMobile from '../../../hooks/useIsMobile';
import useLocationPermission, {
  LocationPermissionStatus,
} from '../../../hooks/useLocationPermission';
import useProTitle from '../../../hooks/useProTitle';
import useSelectedSkill from '../../../hooks/useSelectedSkill';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import PosthogEvent from '../../../utils/posthogEvents';
import BaseModal from '../../BaseModal';
import LocationAutocomplete from '../../LocationAutocomplete';

export type LocationPermissionHeading = 'has-requested-at-home' | undefined;

interface Props {
  heading?: LocationPermissionHeading;
  isOpen: boolean;
  onClose: () => void;
  onShowQuiz: () => void;
}

export default function QuizLocationPermissionModal({
  heading,
  isOpen,
  onClose,
  onShowQuiz,
}: Props): JSX.Element {
  const { isMobile } = useIsMobile();
  const singularProTitle = useProTitle({ isPlural: false });
  const proTitle = useProTitle({ isPlural: true });
  const [isShareLocationView, setIsShareLocationView] = useState<boolean>(true);
  const hasRequestedAtHome = heading === 'has-requested-at-home';
  const [addressFocused, setAddressFocused] = useState<boolean>(false);
  const [selectedSkill] = useSelectedSkill();

  const {
    isGettingGeolocation,
    setLocationPermissionStatus,
    tryGetUserGeolocation,
    locationPermissionStatus,
  } = useLocationPermission();

  const handleStartQuiz = useCallback(() => {
    capturePostHogEvent(PosthogEvent.QuizStarted, {
      screen: 'LocationPrimer',
      skill: selectedSkill?.slug,
    });
    onShowQuiz();
  }, [onShowQuiz, selectedSkill?.slug]);

  const onToggleView = () => {
    if (isShareLocationView) {
      capturePostHogEvent(PosthogEvent.QuizClickTypeAnAddress);
    }
    setIsShareLocationView(
      (prevIsShareLocationView) => !prevIsShareLocationView,
    );
  };

  const onClick = () => {
    if (isShareLocationView) {
      capturePostHogEvent(PosthogEvent.QuizClickShareLocation);
      tryGetUserGeolocation(hasRequestedAtHome)
        // eslint-disable-next-line no-console
        .catch(console.error);
    }
  };

  const onLocationChange = () => {
    setLocationPermissionStatus(LocationPermissionStatus.ACCEPTED);
  };

  useEffect(() => {
    if (locationPermissionStatus === LocationPermissionStatus.ACCEPTED) {
      handleStartQuiz();
    }
  }, [locationPermissionStatus, handleStartQuiz]);

  const onOverlayClick = () => {
    capturePostHogEvent(PosthogEvent.QuizDismissedLocationScreen);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      capturePostHogEvent(PosthogEvent.QuizLeaveSite, {
        question: 'Location Primer',
      });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const header = (
    <>
      {`Let's find you the `}
      <br />
      right {singularProTitle}!
    </>
  );
  const subheader = `Share your location so we can show you the list of ${proTitle} who are closest to you.`;

  const handleOnFocus = () => {
    if (isMobile) setAddressFocused(true);
  };

  const handleOnBlur = () => {
    if (isMobile) setAddressFocused(false);
  };

  const Content = (
    <Flex alignItems="center" flexDir="column" justifyContent="center" w="full">
      {!isMobile && (
        <WithAnimOnLayout>
          <IconAtHomeLessons
            h={!isShareLocationView ? 151 : 218}
            w={!isShareLocationView ? 178 : 257}
          />
        </WithAnimOnLayout>
      )}
      <WithAnimOnLayout>
        <Heading
          mt="8"
          // eslint-disable-next-line no-nested-ternary
          mx={isMobile ? '8' : isShareLocationView ? '72px' : undefined}
          textAlign="center"
          variant="h3"
        >
          {header}
        </Heading>
      </WithAnimOnLayout>

      <WithAnimOnLayout>
        <Text mt="6" mx="8" textAlign="center" textStyle="caption">
          {subheader}
        </Text>
      </WithAnimOnLayout>

      <WithAnimOnLayout
        style={{
          height: isShareLocationView ? 0 : undefined,
          overflow: isShareLocationView ? 'hidden' : undefined,
          width: isMobile ? '100%' : 'calc(100% - 16px)',
        }}
      >
        <Box
          pointerEvents={isGettingGeolocation ? 'none' : undefined}
          mt="8"
          w="full"
        >
          {!isShareLocationView && (
            <LocationAutocomplete
              size={isMobile ? 'md' : 'lg'}
              onLocationChange={onLocationChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
          )}
        </Box>
      </WithAnimOnLayout>

      <WithAnimOnLayout style={{ height: isShareLocationView ? undefined : 0 }}>
        <Button
          fontSize="14px"
          fontWeight="800"
          h={isShareLocationView ? undefined : 0}
          isDisabled={isGettingGeolocation || !isShareLocationView}
          minW={isShareLocationView ? 202 : undefined}
          mt={isGettingGeolocation ? '4' : '8'}
          onClick={onClick}
          size="lg"
          isLoading={isGettingGeolocation}
        >
          {isShareLocationView ? 'Share my location' : 'Submit'}
        </Button>
      </WithAnimOnLayout>

      <WithAnimOnLayout>
        <Button
          color="#718096"
          fontSize="14px"
          fontWeight="800"
          isDisabled={isGettingGeolocation}
          minW={202}
          mt={isMobile ? '6' : '8'}
          onClick={onToggleView}
          size="lg"
          variant="link"
        >
          {isShareLocationView
            ? 'Enter an address instead'
            : 'Use my location instead'}
        </Button>
      </WithAnimOnLayout>
    </Flex>
  );

  return isMobile ? (
    <Drawer
      isOpen={isOpen}
      placement="bottom"
      size="md"
      onClose={onClose}
      onOverlayClick={onOverlayClick}
    >
      <DrawerOverlay />
      <DrawerContent
        borderTopRadius="3xl"
        px="6"
        pt="4"
        pb={addressFocused ? '28' : '12'}
      >
        {Content}
      </DrawerContent>
    </Drawer>
  ) : (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      onOverlayClick={onOverlayClick}
    >
      <ModalContent
        alignItems="center"
        borderRadius="3xl"
        justifyContent="center"
        overflow="visible"
        p="8"
        w={479}
      >
        {Content}
      </ModalContent>
    </BaseModal>
  );
}
