import { Layer, Source } from 'react-map-gl';

import { ListingsState } from '../../../state/slices/listingsSlice';

type Props = {
  listing: Partial<
    Pick<
      ListingsState['listingsInBoundingBox'][0],
      'travelAddressLatitude' | 'travelAddressLongitude' | 'travelMiles'
    >
  >;
};

export default function TravelAddressCircle({ listing }: Props): JSX.Element {
  return !listing ? null : (
    <Source
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features: [
          {
            properties: {},
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [
                listing.travelAddressLongitude || 0,
                listing.travelAddressLatitude || 0,
              ],
            },
          },
        ],
      }}
    >
      <Layer
        type="circle"
        paint={{
          'circle-radius': {
            stops: [
              [2, 0],
              [
                20, // max zoom
                ((listing.travelMiles || 0) * 1609.34) /
                  0.075 /
                  Math.cos(
                    ((listing.travelAddressLatitude || 0) * Math.PI) / 180,
                  ),
              ],
            ],
            base: 2, // min zoom
          },
          'circle-color': '#3182CE',
          'circle-opacity': 0.15,
          'circle-stroke-color': '#3182CE',
          'circle-stroke-opacity': 1,
          'circle-stroke-width': 1,
        }}
      />
    </Source>
  );
}
