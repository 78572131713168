import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';

import { Terms } from '../Policies';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function TermsModal({ isOpen, onClose }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: 'full', lg: '2xl' }}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent borderRadius={{ base: 'none', lg: '3xl' }}>
        <ModalCloseButton />
        <ModalBody w="full" py="8">
          <Terms />
        </ModalBody>
        <ModalFooter shadow="md">
          <Button colorScheme="slate" size="lg" w="full" onClick={onClose}>
            Got it!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
