import { useCallback, useMemo } from 'react';

import PlaceholderImage from '../assets/placeholder.webp';
import { VITE_IMAGEKIT_URL } from '../utils/constants';

type Props = {
  isAvatar?: boolean;
  src: string;
  height: number;
  width: number;
  quality?: string;
};

export default function useTMTImage({
  isAvatar = false,
  src,
  height,
  width,
  quality = '80',
}: Props) {
  const formatImageKitURL = useCallback((params: Props) => {
    if (!params.src) return '';
    return `${VITE_IMAGEKIT_URL}/${params.src}?tr=w-${params.width},q-${params.quality}`;
  }, []);

  const getImageSrcType = useCallback(
    (imgSrc: string): 'assets' | 'supabase' | 'imagekit' => {
      if (imgSrc?.startsWith('http')) return 'imagekit';
      return 'assets';
    },
    [],
  );

  const imageSrc = useMemo(() => {
    if (!src) {
      // Avatars need to return something falsy in order to load the initials
      return isAvatar ? null : PlaceholderImage;
    }
    const imgType = getImageSrcType(src);

    let formattedImageSrc = src;
    if (imgType === 'imagekit') {
      formattedImageSrc = formatImageKitURL({
        height: Math.floor(height),
        width: Math.floor(width),
        quality,
        src,
      });
    }

    return formattedImageSrc;
  }, [
    isAvatar,
    src,
    height,
    width,
    quality,
    formatImageKitURL,
    getImageSrcType,
  ]);

  return {
    imageSrc,
  };
}
