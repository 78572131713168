import 'core-js/features/array/at';

import {
  Button,
  Heading,
  HStack,
  Text,
  useToken,
  VStack,
} from '@chakra-ui/react';
import { HiMinus, HiPlus } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useLessonLength from '../../../hooks/useLessonLength';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import { selectSelectedListing } from '../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import PosthogEvent from '../../../utils/posthogEvents';
import { BookingStep } from '../../../utils/types';

export default function LessonLength() {
  const navigate = useNavigate();
  const selectedListing = useSelector(selectSelectedListing);
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();
  const [gray300, gray700] = useToken('colors', ['gray.300', 'gray.700']);
  const {
    lessonLength,
    lessonLengthOptions,
    setSelectedLessonLengthForCurrentCategory,
  } = useLessonLength();

  const minLessonLength = lessonLengthOptions?.possibleLengths?.[0]?.minutes;
  const maxLessonLength = lessonLengthOptions?.possibleLengths?.at(-1)?.minutes;
  const lessonLengthLabel = lessonLengthOptions?.possibleLengths?.find(
    (length) => length.minutes === lessonLength,
  )?.label;
  const listingUrl = `/listings/${selectedListing?.skill?.slug}/${selectedListing?.slug}`;

  const handleSetLessonLength = (length: number) => {
    capturePostHogEvent(PosthogEvent.ClickedEditLessonLength, {
      ...selectedListing,
    });

    setSelectedLessonLengthForCurrentCategory(length);
    navigate(
      `${listingUrl}/${
        BookingStep.Confirm
      }?${getListingSearchParamsWithPartialInput({
        lessonLength: length,
      })}`,
    );
  };

  const handleIncrement = () => {
    handleSetLessonLength(lessonLength + 30);
  };

  const handleDecrement = () => {
    handleSetLessonLength(lessonLength - 30);
  };

  return (
    <VStack alignItems="flex-start" width="full" mt={6}>
      <HStack justifyContent="space-between" width="full">
        <VStack alignItems="flex-start" justifyContent="center">
          <Heading variant="h6">LENGTH</Heading>
          <Text color="slate.500">{lessonLengthLabel} lessons</Text>
        </VStack>
        <HStack>
          <Button
            size="lg"
            variant="outline"
            onClick={handleDecrement}
            disabled={lessonLength === minLessonLength}
            borderColor="gray.300"
            padding={0}
          >
            <HiMinus
              color={lessonLength === minLessonLength ? gray300 : gray700}
            />
          </Button>
          <Button
            size="lg"
            variant="outline"
            onClick={handleIncrement}
            disabled={lessonLength === maxLessonLength}
            borderColor="gray.300"
            padding={0}
          >
            <HiPlus
              color={lessonLength === maxLessonLength ? gray300 : gray700}
            />
          </Button>
        </HStack>
      </HStack>
    </VStack>
  );
}
