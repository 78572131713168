import { theme as baseTheme, extendTheme } from '@chakra-ui/react';

import * as components from './components';
import * as foundations from './foundations';

export const theme: Record<string, any> = extendTheme({
  ...foundations,
  colors: {
    ...foundations.colors,
    ...baseTheme.colors,
    brand: baseTheme.colors.blue,
  },
  components: { ...components },
});
