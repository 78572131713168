import * as Sentry from '@sentry/react';
import { useCallback } from "react"

import sentryClient, { SentryContext } from "../infra/sentryClient";
import store from '../state/store';

export default function useSentryWithContext() {
    const captureException = useCallback(
        (exception: Error, options?: {
            context?: SentryContext;
            user?: Sentry.User;
        }) => {
            const user = sentryClient.getSentryUserFromRedux(store);
            sentryClient.captureException(exception, { ...options, user });
        },
        [],
    )

    return { captureException }
}
