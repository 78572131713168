import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  ModalContent,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { HiX } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import IconAtHomeLessons from '../../assets/svg/IconAtHomeLessons';
import WithAnimOnLayout from '../../hocs/WithAnimOnLayout';
import useIsMobile from '../../hooks/useIsMobile';
import useLocationCopy from '../../hooks/useLocationCopy';
import useLocationPermission, {
  LocationPermissionStatus,
} from '../../hooks/useLocationPermission';
import useProTitle from '../../hooks/useProTitle';
import { useAppDispatch } from '../../state/hooks';
import { setIsAtHome } from '../../state/slices/filtersSlice';
import BaseModal from '../BaseModal';
import LocationAutocomplete from '../LocationAutocomplete';

export type LocationPermissionHeading = 'has-requested-at-home' | undefined;

interface Props {
  disableNavigate: boolean;
  heading: LocationPermissionHeading;
  isOpen: boolean;
  onClose: () => void;
  onOpen?: () => void;
}

export default function LocationPermissionModal({
  disableNavigate,
  heading,
  isOpen,
  onClose: onCloseModal,
  onOpen,
}: Props): JSX.Element {
  const { isMobile } = useIsMobile();
  const proTitle = useProTitle({ isPlural: true });
  const locationName = useLocationCopy();
  const [isShareLocationView, setIsShareLocationView] = useState<boolean>(true);
  const hasRequestedAtHome = heading === 'has-requested-at-home';
  const {
    isGettingGeolocation,
    setLocationPermissionStatus,
    tryGetUserGeolocation,
  } = useLocationPermission();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLocationAutocompleteFocused, setIsLocationAutocompleteFocused] =
    useState<boolean>(false);

  const onClose = () => {
    if (isGettingGeolocation) return;

    onCloseModal();

    if (!disableNavigate) {
      navigate('/');
    }
  };

  const onToggleView = () => {
    setIsShareLocationView(
      (prevIsShareLocationView) => !prevIsShareLocationView,
    );
  };

  // eslint-disable-next-line no-nested-ternary
  const header = hasRequestedAtHome
    ? isShareLocationView
      ? `Find ${proTitle} who can come to your ${locationName}`
      : 'Type an address, zip code or city'
    : isShareLocationView
    ? `Find the nearest ${proTitle}`
    : 'Type an address, zip code or city';

  useEffect(() => {
    if (onOpen) {
      onOpen();
    }
  }, [onOpen]);

  // eslint-disable-next-line no-nested-ternary
  const subheader = hasRequestedAtHome
    ? isShareLocationView
      ? `Share your location so we can show you the list of ${proTitle} who are willing to travel to your ${locationName}.`
      : `And we'll find you the ${proTitle} who are willing to travel to your ${locationName}.`
    : isShareLocationView
    ? `Share your location so we can show you the list of ${proTitle} who are closest to you.`
    : `And we'll find you the ${proTitle} nearby.`;

  const onClick = () => {
    if (isShareLocationView) {
      tryGetUserGeolocation(hasRequestedAtHome)
        // eslint-disable-next-line no-console
        .catch(console.error)
        .finally(onCloseModal);
    }
  };

  const onLocationChange = () => {
    setLocationPermissionStatus(LocationPermissionStatus.ACCEPTED);
    if (hasRequestedAtHome) {
      dispatch(setIsAtHome(true));
    }
    onCloseModal();
  };

  const Content = (
    <Flex alignItems="center" flexDir="column" justifyContent="center" w="full">
      <WithAnimOnLayout
        style={{ alignSelf: 'flex-end', opacity: isGettingGeolocation ? 0 : 1 }}
      >
        <IconButton
          alignSelf="flex-end"
          aria-label="Go back"
          icon={<HiX size={24} />}
          onClick={onClose}
          size="sm"
          variant="ghost"
        />
      </WithAnimOnLayout>

      <WithAnimOnLayout>
        <IconAtHomeLessons
          h={isMobile || !isShareLocationView ? 151 : 218}
          w={isMobile || !isShareLocationView ? 178 : 257}
        />
      </WithAnimOnLayout>

      <WithAnimOnLayout>
        <Heading
          mt="8"
          // eslint-disable-next-line no-nested-ternary
          mx={isMobile ? '8' : isShareLocationView ? '72px' : undefined}
          textAlign="center"
          variant="h4"
        >
          {header}
        </Heading>
      </WithAnimOnLayout>

      <WithAnimOnLayout>
        <Text mt="6" mx="8" textAlign="center" textStyle="caption">
          {subheader}
        </Text>
      </WithAnimOnLayout>

      <WithAnimOnLayout
        style={{
          height: isShareLocationView ? 0 : undefined,
          overflow: isShareLocationView ? 'hidden' : undefined,
          width: isMobile ? '100%' : 'calc(100% - 16px)',
        }}
      >
        <Box
          pointerEvents={isGettingGeolocation ? 'none' : undefined}
          mb={isLocationAutocompleteFocused ? '32' : undefined}
          mt="8"
          w="full"
        >
          <LocationAutocomplete
            onBlur={() => setIsLocationAutocompleteFocused(false)}
            onFocus={() => setIsLocationAutocompleteFocused(true)}
            onLocationChange={onLocationChange}
          />
        </Box>
      </WithAnimOnLayout>

      <WithAnimOnLayout>
        <Spinner
          h={isGettingGeolocation ? undefined : 0}
          w={isGettingGeolocation ? undefined : 0}
          mt="4"
          overflow="hidden"
          opacity={isGettingGeolocation ? 1 : 0}
          position={isGettingGeolocation ? undefined : 'absolute'}
        />
      </WithAnimOnLayout>

      <WithAnimOnLayout style={{ height: isShareLocationView ? undefined : 0 }}>
        <Button
          fontSize="14px"
          fontWeight="800"
          h={isShareLocationView ? undefined : 0}
          isDisabled={isGettingGeolocation || !isShareLocationView}
          minW={isShareLocationView ? 202 : undefined}
          mt={isGettingGeolocation ? '4' : '8'}
          onClick={onClick}
          size="lg"
        >
          {isShareLocationView ? 'Share my location' : 'Submit'}
        </Button>
      </WithAnimOnLayout>

      <WithAnimOnLayout>
        <Button
          color="#718096"
          fontSize="14px"
          fontWeight="800"
          isDisabled={isGettingGeolocation}
          minW={202}
          mt={isMobile ? '6' : '8'}
          onClick={onToggleView}
          size="lg"
          variant="link"
        >
          {isShareLocationView
            ? 'Enter an address instead'
            : 'Use my location instead'}
        </Button>
      </WithAnimOnLayout>
    </Flex>
  );

  return isMobile ? (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      size="2xl"
      motionPreset="slideInBottom"
    >
      <ModalContent
        alignItems="center"
        borderRadius={{ base: '1.75rem 1.75rem 0px 0px', md: '2xl' }}
        bottom="0px"
        display="flex"
        justifyContent="center"
        mb={{ base: '0', md: 'auto' }}
        overflow="visible"
        p="8"
        position={{ base: 'fixed', md: 'unset' }}
      >
        {Content}
      </ModalContent>
    </BaseModal>
  ) : (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <ModalContent
        alignItems="center"
        borderRadius="3xl"
        justifyContent="center"
        overflow="visible"
        p="8"
        w={479}
      >
        {Content}
      </ModalContent>
    </BaseModal>
  );
}
