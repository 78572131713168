import {
  type ListingBadge,
  type ListingInBoundingBox,
  type ListingInBoundingBoxBadge,
  type ListingInBoundingBoxMedia,
  type ListingInBoundingBoxNoLocations,
  type ListingInBoundingBoxReview,
  type ListingInBoundingBoxSkill,
  type ListingStatus,
  type LocationEnvironmentSetting,
  type LocationInBoundingBox,
  type Media,
  type Review,
  type Schedule,
  type Skill,
  type UniqueListingQuery,
} from '../api';

export enum BookingStep {
  Location = 'location',
  Participants = 'participants',
  LessonLength = 'lesson-length',
  SchedulePreference = 'schedule-preference',
  Exposed = 'exposed',
  Packages = 'packages',
  Address = 'address',
  Confirm = 'confirm',

  Datepicker = 'datepicker',
  Times = 'times',
}

export type CurrentLocation = {
  id?: string;
  address: string;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  postalCode?: string;
  state: string;
  stateShortCode: string;
};

export type SelectedLocationListing = Omit<
  ListingInBoundingBoxNoLocations,
  | 'badges'
  | 'media'
  | 'rating'
  | 'reviewCount'
  | 'reviews'
  | 'skill'
  | '__typename'
> & {
  badges: Omit<
    ListingInBoundingBoxBadge,
    '__typename' | 'id' | 'badgeGroupId' | 'isExperience' | 'isFilter' | 'name'
  >[];
  media: Omit<ListingInBoundingBoxMedia, '__typename'>[];
  reviews: Omit<
    ListingInBoundingBoxReview,
    '__typename' | 'rating' | 'id' | 'name'
  >[];
  skill: Omit<ListingInBoundingBoxSkill, '__typename'>;
};

export type SelectedLocation = Omit<
  LocationInBoundingBox,
  '__typename' | 'listings' | 'address' | 'city' | 'state'
> &
  Partial<
    Pick<CurrentLocation, 'city' | 'country' | 'state' | 'stateShortCode'>
  > & {
    address?: LocationInBoundingBox['address'];
    listings?: (Omit<
      Partial<SelectedLocationListing>,
      | '__typename'
      | 'badges'
      | 'bestPricePerLesson'
      | 'media'
      | 'reviews'
      | 'travelMiles'
      | 'url'
    > &
      Partial<Pick<SelectedLocationListing, 'slug'>>)[];
    skill?: { slug: string };
    affiliateName?: LocationInBoundingBox['affiliateName'];
    LocationAffiliate?: UniqueListingQuery['findUniqueListing']['locations'][0]['LocationAffiliate'];
  };

export type SelectedListing = Omit<
  UniqueListingQuery['findUniqueListing'],
  | 'badges'
  | 'createdAt'
  | '_count'
  | 'locations'
  | 'media'
  | 'price'
  | 'reviews'
  | 'skill'
  | 'title'
  | '__typename'
  | 'updatedAt'
> & {
  badges?: (Omit<ListingInBoundingBoxBadge, '__typename' | 'badge'> & {
    badge?: UniqueListingQuery['findUniqueListing']['badges'][0]['badge'];
  })[];
  isInServiceArea?: boolean;
  locations: (Omit<
    UniqueListingQuery['findUniqueListing']['locations'][0],
    'address' | '__typename'
  > & {
    address?: ListingInBoundingBox['locations'][0]['address'];
    affiliateName?: ListingInBoundingBox['locations'][0]['affiliateName'];
  })[];
  media?: Omit<ListingInBoundingBox['media'][0], '__typename'>[];
  rating?: ListingInBoundingBox['rating'];
  reviews?: Omit<ListingInBoundingBox['reviews'][0], '__typename'>[];
  reviewCount?: ListingInBoundingBox['reviewCount'];
  skill?: Omit<
    UniqueListingQuery['findUniqueListing']['skill'],
    'createdAt' | 'updatedAt' | 'isVisible' | '__typename'
  >;
  status?: UniqueListingQuery['findUniqueListing']['status'];
  title?: UniqueListingQuery['findUniqueListing']['title'];
  travelAddressLatitude?: ListingInBoundingBox['travelAddressLatitude'];
  travelAddressLongitude?: ListingInBoundingBox['travelAddressLongitude'];
  url?: ListingInBoundingBox['url'];
  availabilityInHoursPerWeek?: ListingInBoundingBox['availabilityInHoursPerWeek'];
};

export interface ParamsProps {
  skill?: string;
  tag?: string;
}

export interface States {
  alabama: string;
  alaska: string;
  arizona: string;
  arkansas: string;
  california: string;
  colorado: string;
  connecticut: string;
  delaware: string;
  florida: string;
  georgia: string;
  hawaii: string;
  idaho: string;
  illinois: string;
  indiana: string;
  iowa: string;
  kansas: string;
  kentucky: string;
  louisiana: string;
  maine: string;
  maryland: string;
  massachusetts: string;
  michigan: string;
  minnesota: string;
  mississippi: string;
  missouri: string;
  montana: string;
  nebraska: string;
  nevada: string;
  'new-hampshire': string;
  'new-jersey': string;
  'new-mexico': string;
  'new-york': string;
  'north-carolina': string;
  'north-dakota': string;
  ohio: string;
  oklahoma: string;
  oregon: string;
  pennsylvania: string;
  'rhode-island': string;
  'south-carolina': string;
  'south-dakota': string;
  tennessee: string;
  texas: string;
  utah: string;
  vermont: string;
  virginia: string;
  washington: string;
  'west-virginia': string;
  wisconsin: string;
  wyoming: string;
  'district-of-columbia': string;
}

export interface Location {
  address: string;
  city?: string;
  id?: string;
  latitude: number;
  longitude: number;
  state?: string;
  placeName: string;
  environmentSettings: LocationEnvironmentSetting[];
}

export interface Listing {
  __typename?: 'Listing';
  badges: ListingBadge[];
  hasVideo?: boolean;
  id: string;
  locations?: SelectedLocation[];
  media: Media[];
  mediaUrl?: string;
  price?: number;
  proAvatar?: string;
  proBio?: string;
  proHeadline?: string;
  proName?: string;
  rating?: number;
  reviewCount?: number;
  reviews?: Review[];
  schedule?: Schedule;
  skill?: Skill;
  skillId?: string;
  slug?: string;
  status?: ListingStatus;
  stripeProductId?: string;
  tags?: string[];
  title?: string;
  travelMiles?: number;
  url?: string;
}

export type ChakraBreakpoint =
  | 'base'
  | '5xs'
  | '4xs'
  | '3xs'
  | '2xs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl';

export type MapBoxFeature = {
  properties: {
    coordinates: {
      longitude: number;
      latitude: number;
    };
    context: {
      address: {
        name: string;
      };
      street: {
        name: string;
      };
      neighborhood: {
        name: string;
      };
      postcode: {
        name: string;
      };
      place: {
        name: string;
      };
      district: {
        name: string;
      };
      region: {
        name: string;
        region_code_full: string;
      };
      country: {
        name: string;
      };
    };
  };
};

export type MapBoxResponse = {
  type: string;
  attribution: string;
  features: MapBoxFeature[];
};
