import { isBefore } from 'date-fns';
import * as R from 'ramda';


import Active from './Active';
import Disable from './Disable';
import Normal from './Normal';
import Period from './Period';

import { DayStyleConfig, DayType , defaultDayStyle } from '../type';

type DayProps = {
  date: Date;
  day: number;
  dayType: DayType;
  onSetDate?: (date: Date) => void;
  dayStyleConfig?: Partial<DayStyleConfig>;
  disabled?: boolean;
};

export default function Day({
  date,
  day,
  dayType = DayType.NORMAL,
  onSetDate,
  dayStyleConfig,
}: DayProps) {
  const mergedDayStyleConfig: DayStyleConfig = R.mergeRight(
    defaultDayStyle,
    dayStyleConfig || {},
  );

  if (isBefore(date, new Date())) {
    return <Disable dayStyleConfig={mergedDayStyleConfig} day={day} />;
  }

  switch (dayType) {
    case DayType.NONE:
      return <Disable dayStyleConfig={mergedDayStyleConfig} day={day} />;
    case DayType.ACTIVE:
      return <Active dayStyleConfig={mergedDayStyleConfig} day={day} />;
    case DayType.DISABLE:
      return <Disable dayStyleConfig={mergedDayStyleConfig} day={day} />;
    case DayType.PERIOD:
      return (
        <Period
          dayStyleConfig={mergedDayStyleConfig}
          day={day}
          onClick={() => onSetDate?.(date)}
        />
      );
    case DayType.NORMAL:
      return (
        <Normal
          onClick={() => onSetDate?.(date)}
          dayStyleConfig={mergedDayStyleConfig}
          day={day}
        />
      );

    default:
      return (
        <Normal
          onClick={() => onSetDate?.(date)}
          dayStyleConfig={mergedDayStyleConfig}
          day={day}
        />
      );
  }
}
