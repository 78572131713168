import { Flex } from '@chakra-ui/react';
import { memo } from 'react';

import { ListingInBoundingBox } from '../../../api';
import TMTAvatar from '../../TMTAvatar';

function getSize(isCurrentLocation: boolean, isActive: boolean): string {
  if (isCurrentLocation) return '16px';
  if (isActive) return '54px';
  return '32px';
}

type Props = {
  isActive: boolean;
  activeColor?: string;
  inactiveColor?: string;
  isCurrentLocation?: boolean;
  listing: Pick<ListingInBoundingBox, 'proAvatar' | 'proName'>;
};

const PinAtHome = memo(
  ({
    isActive = false,
    activeColor = 'white',
    inactiveColor = 'white',
    isCurrentLocation = false,
    listing,
  }: Props) => {
    const size = getSize(isCurrentLocation, isActive);
    return (
      <Flex
        borderRadius="full"
        h={size}
        w={size}
        p="3"
        align="center"
        justify="center"
        mx="auto"
        bg={isActive || isCurrentLocation ? activeColor : inactiveColor}
        overflow="hidden"
        boxShadow="0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)"
        transition="all 0.2s"
        _hover={{
          bg: 'accent',
          svg: {
            color: inactiveColor,
          },
        }}
      >
        <TMTAvatar
          name={listing?.proName}
          size={isActive ? 'md' : 'xs'}
          src={listing?.proAvatar}
        />
      </Flex>
    );
  },
);

export default PinAtHome;
