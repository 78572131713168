import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";

import { DateAvailability, useGetAvailabilityByListingIdLazyQuery } from "../../../../../api";

export default function useAvailabilitySlots() {
    const [dateAvailabilitySlots, setDateAvailabilitySlots] = useState<
        DateAvailability[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [getAvailabilityByListingId, { data, error }] =
        useGetAvailabilityByListingIdLazyQuery();

    useEffect(() => {
        if (data?.getAvailabilityByListingId?.dateAvailabilitySlots) {
            setDateAvailabilitySlots((prevSlots) => [
                ...prevSlots,
                ...data.getAvailabilityByListingId.dateAvailabilitySlots,
            ]);
            setIsLoading(false);
        }
    }, [data?.getAvailabilityByListingId?.dateAvailabilitySlots]);

    const getAvailableSlotsByDay = useCallback(
        (selectedDate: Date) => dateAvailabilitySlots.find(
            (slot) => slot.date === format(selectedDate, 'yyyy-MM-dd'),
        ),
        [dateAvailabilitySlots],
    )

    return { getAvailableSlotsByDay, isLoading, error, dateAvailabilitySlots, getAvailabilityByListingId, setIsLoading }
}
