import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import { VITE_STRIPE_PUBLISHABLE_API_KEY } from './constants';

import sentryClient from '../infra/sentryClient';
import LoadStripeError from '../types/errors/LoadStripeError';

let stripePromise: Promise<Stripe>;

// eslint-disable-next-line consistent-return
export default function loadStripePromise(): Promise<Stripe | null> {
    try {
        if (!stripePromise) {
            stripePromise = loadStripe(VITE_STRIPE_PUBLISHABLE_API_KEY)
        }
        return stripePromise;
    } catch (error: any) {
        sentryClient.captureException(new LoadStripeError(error.message));
    }
}
