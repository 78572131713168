import { createContext } from 'react';

import { QuizQuestionProps } from './Questions';

interface QuizContextType {
  currentQuestion: QuizQuestionProps;
  goToNextQuestion: () => void;
  goToPreviousQuestion: () => void;
  hasNextStep: boolean;
  hasPreviousStep: boolean;
  totalSteps: number;
  progressStep: number;
  isLastQuestion: boolean;
  resetQuiz: () => void;
}

const QuizContext = createContext<QuizContextType>(undefined);
export default QuizContext;
