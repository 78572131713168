import { useMemo } from 'react';

import { useAppSelector } from '../state/hooks';
import { selectSelectedSkill } from '../state/slices/filtersSlice';
import {
  BASEBALL_SKILL_SLUG,
  BASKETBALL_SKILL_SLUG,
  BOXING_SKILL_SLUG,
  GOLF_SKILL_SLUG,
  PICKLEBALL_SKILL_SLUG,
  TENNIS_SKILL_SLUG,
} from '../utils/constants';

export default function useLocationCopy(): string {
  const selectedSkill = useAppSelector(selectSelectedSkill);

  const locationCopy = useMemo(() => {
    switch (selectedSkill?.slug) {
      case PICKLEBALL_SKILL_SLUG:
      case TENNIS_SKILL_SLUG:
      case BASKETBALL_SKILL_SLUG:
        return 'private court';
      case GOLF_SKILL_SLUG:
        return 'private golf club';
      case BASEBALL_SKILL_SLUG:
      case BOXING_SKILL_SLUG:
        return 'private facility';
      default:
        return 'home';
    }
  }, [selectedSkill]);

  return locationCopy;
}
