import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { IconType } from 'react-icons';
import {
  HiOutlineBadgeCheck,
  HiOutlineHeart,
  HiOutlineLightningBolt,
} from 'react-icons/hi';

import useIsMobile from '../../hooks/useIsMobile';
import StudentFavoriteModalBadge from '../StudentFavoriteModalBadge';

interface Props extends Omit<ModalProps, 'children'> {
  isOpen: boolean;
  onClose: () => void;
  bg?: string;
}

const AnimatedModalContent = motion(ModalContent);

function Benefit({
  Icon,
  title,
  description,
}: {
  Icon: IconType;
  title: string;
  description: string;
}) {
  const { isMobile } = useIsMobile();

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      px="6"
      py="4"
      borderRadius="16"
      bgColor="#EBF8FF"
    >
      <HStack w="100%">
        <Text
          fontSize="20"
          color="#2B6CB0"
          fontWeight="extrabold"
          flexGrow={1}
          flexBasis={0}
        >
          {title}
        </Text>
        <Icon color="#2B6CB0" size="32" />
      </HStack>
      <Text color="#2B6CB0" fontSize={isMobile ? '14' : '16'}>
        {description}
      </Text>
    </VStack>
  );
}

const computeBenefits = (isMobile: boolean) => [
  {
    Icon: HiOutlineHeart,
    title: 'Happiest Students',
    description: isMobile
      ? 'Maintain a top 10% student rating and rebooking rate.'
      : 'Student Favorites maintain a top 10% 5-star review rate and student rebooking rate.',
  },
  {
    Icon: HiOutlineBadgeCheck,
    title: 'Personally Vetted',
    description: isMobile
      ? 'Pass our screening for world-class teaching ability and credentials.'
      : 'Our expert team vets every Student Favorite for world-class teaching ability and credentials.',
  },
  {
    Icon: HiOutlineLightningBolt,
    title: 'Incredibly Reliable',
    description: isMobile
      ? 'Deliver the most lessons without cancelling or rescheduling.'
      : 'Student Favorites deliver the most lessons without cancelling or rescheduling.',
  },
];

function StudentFavoriteModalContent({ onClose }: { onClose: () => void }) {
  const { isMobile } = useIsMobile();

  const benefits = useMemo(() => computeBenefits(isMobile), [isMobile]);

  return (
    <VStack
      alignItems="flex-start"
      w="100%"
      gap={0}
      overflow="hidden"
      pt={{
        base: '32px',
        md: '40px',
      }}
    >
      <HStack
        alignItems="center"
        w="full"
        px={{
          base: '24px',
          md: '40px',
        }}
      >
        <VStack
          alignItems="flex-start"
          gap={0}
          flexGrow={1}
          flexBasis={0}
          flexShrink={1}
        >
          <Text
            color="#2D3748"
            fontWeight="extrabold"
            fontSize="medium"
            lineHeight="18px"
          >
            TeachMe.To
          </Text>
          <Text color="#2D3748" fontWeight="extrabold" fontSize="x-large">
            Student Favorites
          </Text>
          {!isMobile && (
            <>
              <Text color="#2D3748" fontSize="16px">
                How does a coach become a Student Favorite?
              </Text>
              <Text color="muted" fontSize="16px">
                Spoiler alert: It’s not easy.
              </Text>
            </>
          )}
        </VStack>
        <StudentFavoriteModalBadge size={isMobile ? 'md' : 'lg'} />
      </HStack>
      {isMobile && (
        <>
          <Text
            px={{
              base: '24px',
              md: '40px',
            }}
            color="#2D3748"
            fontSize="16px"
          >
            How does a coach become a Student Favorite?
          </Text>
          <Text
            px={{
              base: '24px',
              md: '40px',
            }}
            color="muted"
            fontSize="16px"
          >
            Spoiler alert: It’s not easy.
          </Text>
        </>
      )}
      <VStack
        overflow="auto"
        flexGrow={1}
        px={{
          base: '24px',
          md: '40px',
        }}
      >
        <VStack
          alignItems="flex-start"
          gap={{
            base: '2',
            md: '4',
          }}
          mt={{
            base: '24px',
            md: '32px',
          }}
        >
          {benefits.map((benefit) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Benefit {...benefit} />
          ))}
        </VStack>
        <Flex
          w="full"
          pb={{
            base: '32px',
            md: '40px',
          }}
        >
          <Box
            bgGradient="linear-gradient(225deg, #3182CE 1.86%, #5EA1D2 40.76%, #182C93 81.24%), #3182CE"
            borderRadius="full"
            w="100%"
            mt="6"
          >
            <Button
              w="100%"
              bg="transparent"
              size="xl"
              rounded="full"
              onClick={onClose}
            >
              Got it
            </Button>
          </Box>
        </Flex>
      </VStack>
    </VStack>
  );
}

export default function StudentFavoriteModal({ isOpen, onClose, bg }: Props) {
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: 'full', md: 'auto' }}
      scrollBehavior="inside"
      motionPreset="slideInBottom"
      onOverlayClick={onClose}
    >
      <ModalOverlay bg={bg} />
      <AnimatedModalContent
        borderRadius="24px"
        maxH={window.innerHeight}
        maxW={{
          base: '100%',
          md: 615,
        }}
      >
        <StudentFavoriteModalContent onClose={onClose} />
      </AnimatedModalContent>
    </Modal>
  );
}
