import { Image, Stack } from '@chakra-ui/react';

import PrimerImage from '../../../assets/quiz/primer_quiz.svg';

export default function QuizPrimer() {
  return (
    <Stack w="full" display="flex" flex="1" alignItems="center">
      <Image
        objectFit="contain"
        src={PrimerImage}
        maxH="100%"
        maxW="100%"
        flex="1"
      />
    </Stack>
  );
}
