import {
  Box,
  Button,
  Heading,
  Icon,
  IconButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocalStorageValue } from '@react-hookz/web';
import { posthog } from 'posthog-js';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiX } from 'react-icons/hi';
import { MdContentCopy } from 'react-icons/md';
import * as Yup from 'yup';
import 'yup-phone-lite';

import { useCaptureEmailFromSiteMutation } from '../../api';
import { useError } from '../../hooks/useError';
import { useSuccess } from '../../hooks/useSuccess';
import capturePostHogEvent from '../../utils/capturePostHogEvent';
import {
  customerIoIdentifyWithPH,
  customerIoTrackEvent,
} from '../../utils/customerIO';
import BaseModal from '../BaseModal';
import { InputField } from '../InputField';

interface FormValues {
  email: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

type EmailCaptureModalProps = {
  heading?: string;
  subheading?: string;
  callToAction?: string;
  couponCode?: string;
};

export default function EmailCaptureModal({ isOpen, onClose }: Props) {
  const { handleSuccess } = useSuccess();
  const { handleError } = useError();
  const [captureEmail] = useCaptureEmailFromSiteMutation();
  const [modalData, setModalData] = useState<EmailCaptureModalProps>();
  const [, setUserSubmittedEmailCaptureForm] = useLocalStorageValue(
    'userSubmittedEmailCaptureForm',
    false,
  );
  const iconColor = useColorModeValue('gray.600', 'gray.200');

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const [view, setView] = useState('form');

  const schema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setModalData({
      heading: 'Get $20 off your first lesson',
      subheading: 'Enter your email below to get your exclusive discount',
      callToAction: 'Send my discount code',
      couponCode: 'WELCOME20',
    });
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const heading = modalData?.heading || 'Get $20 off your first lesson';
  const subheading =
    modalData?.subheading ||
    'Enter your email below to get your exclusive discount.';
  const callToAction = modalData?.callToAction || 'Send my discount code';
  const couponCode = modalData?.couponCode || 'WELCOME20';

  const subheadingSuccess = 'Your discount code is here!';

  useEffect(() => {
    if (isOpen) {
      capturePostHogEvent('viewEmailPopup');
    }
  }, [isOpen]);

  const handleOpenTooltip = () => {
    setIsTooltipOpen(true);
  };

  const handleCloseTooltip = () => {
    setIsTooltipOpen(false);
  };

  const onSubmit = async (values: FormValues) => {
    const posthogDistinctId = posthog.get_distinct_id();

    try {
      setIsSubmitting(true);
      const response = await captureEmail({
        variables: {
          email: values.email,
        },
      });

      if (response.data?.captureEmailFromSite?.success) {
        setUserSubmittedEmailCaptureForm(true);
        customerIoIdentifyWithPH(posthogDistinctId, values.email);
        customerIoTrackEvent('emailPopupSubmit');
        capturePostHogEvent('emailPopupSubmit', {
          email: values.email,
        });
        setView('success');
      } else {
        throw new Error('Failed to save your email');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setView('form');
    reset();
    onClose();
  };

  const onNoThanks = () => {
    capturePostHogEvent('emailPopUpDismissedWithoutSubmit');
    handleClose();
  };

  const onCopyCouponCode = () => {
    navigator.clipboard.writeText(couponCode);
    handleSuccess('Coupon code copied!');
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      size="2xl"
      motionPreset="slideInBottom"
    >
      <ModalContent
        display="flex"
        justifyContent="space-between"
        p={{ base: '0', md: '2' }}
        position={{ base: 'fixed', md: 'unset' }}
        bottom="0px"
        mb={{ base: '0', md: 'auto' }}
        borderRadius={{ base: '1.75rem 1.75rem 0px 0px', md: '2xl' }}
      >
        <ModalHeader p={1}>
          <VStack align="flex-end" spacing="0">
            <IconButton
              aria-label="Close modal"
              icon={<HiX size={24} />}
              variant="ghost"
              onClick={handleClose}
              tabIndex={-1}
            />
            <VStack
              align="center"
              spacing="4"
              w="full"
              px={{ base: '6', md: '0' }}
            >
              <Heading variant="h3" textAlign="center">
                {heading}
              </Heading>
              <Text textStyle="body" textAlign="center" color="muted">
                {view === 'form' ? subheading : subheadingSuccess}
              </Text>
            </VStack>
          </VStack>
        </ModalHeader>
        <form id="web_email_capture">
          {view === 'form' && (
            <ModalBody
              w="full"
              px={{ base: '8', md: '24' }}
              mt="6"
              mb="8"
              py="0"
            >
              <InputField
                size="md"
                borderRadius="lg"
                label="Email"
                autoComplete="email"
                inputMode="email"
                placeholder="example@email.com"
                isRequired
                isDisabled={isSubmitting}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('email')}
                error={errors?.email?.message as any}
              />
            </ModalBody>
          )}

          <ModalFooter pb="12" pt="0" justifyContent="center">
            {view === 'form' ? (
              <VStack spacing="6">
                <Tooltip
                  hasArrow
                  placement="top"
                  label="Please fill out your email to continue."
                  textAlign="center"
                  p="2"
                  isOpen={Boolean(isTooltipOpen && !isValid)}
                >
                  <Button
                    size="xl"
                    type="submit"
                    isLoading={isSubmitting}
                    onClick={handleSubmit((d) => onSubmit(d as FormValues))}
                    onMouseOver={handleOpenTooltip}
                    onMouseLeave={handleCloseTooltip}
                    backgroundColor={isValid ? 'bg-accent' : 'bg-disabled'}
                  >
                    {callToAction}
                  </Button>
                </Tooltip>
                <Button variant="link" color="#718096" onClick={onNoThanks}>
                  No, thanks
                </Button>
              </VStack>
            ) : (
              <VStack spacing="6">
                <Box position="relative" px="10" py="3" flex="1" mt="6">
                  <Box
                    border="2px dashed"
                    px="12"
                    py="3"
                    borderColor="gray.200"
                    borderRadius="lg"
                  >
                    <Text fontWeight="extrabold">{couponCode}</Text>
                  </Box>
                  <IconButton
                    onClick={onCopyCouponCode}
                    position="absolute"
                    top={0}
                    right={0}
                    bottom={0}
                    variant="outline"
                    borderColor="transparent"
                    size="md"
                    width="8"
                    borderRadius="2"
                    marginY="auto"
                    colorScheme={iconColor}
                    aria-label="Copy to clipboard"
                    icon={<Icon as={MdContentCopy} color="muted" />}
                  />
                </Box>
                <Button variant="link" color="#718096" onClick={handleClose}>
                  Close this window
                </Button>
              </VStack>
            )}
          </ModalFooter>
        </form>
      </ModalContent>
    </BaseModal>
  );
}
