import { Flex, HStack, Icon, Text, useBreakpointValue } from '@chakra-ui/react';
import {
  Select as ChakraReactSelect,
  components,
  GroupBase,
  IndicatorsContainerProps,
} from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { HiLocationMarker } from 'react-icons/hi';
import usePlacesAutocomplete from 'use-places-autocomplete';

import WithAnimOnLayout from '../../../hocs/WithAnimOnLayout';
import useSentryWithContext from '../../../hooks/useSentryWithContext';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
  selectAtHomeLocation,
  setAtHomeLocation,
} from '../../../state/slices/filtersSlice';
import getLocationFromAddress from '../../../utils/getLocationFromAddress';
import getTimezone from '../../../utils/getTimezone';

const { IndicatorsContainer: ChakraIndicatorsContainer } = components;

const IndicatorsContainer: React.ComponentType<
  IndicatorsContainerProps<unknown, boolean, GroupBase<unknown>>
> = function IndicatorsContainer(props) {
  return (
    <Flex
      alignItems="center"
      bg="white"
      borderWidth={0}
      bottom="0"
      flexDir="row"
      justifyContent="center"
      left="0"
      pl="6"
      pr="2"
      position="absolute"
      top="0"
    >
      <Icon as={HiLocationMarker} color="gray.500" fontSize="24px" />

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ChakraIndicatorsContainer {...props} />
    </Flex>
  );
};

type Props = {
  isLoading: boolean;
  locationType: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AtHomeLocationAutoComplete({
  isLoading,
  locationType,
  setIsLoading,
}: Readonly<Props>) {
  const { captureException } = useSentryWithContext();
  const [isLocationAutocompleteFocused, setIsLocationAutocompleteFocused] =
    useState<boolean>(false);
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const atHomeLocation = useAppSelector(selectAtHomeLocation);
  const dispatch = useAppDispatch();
  const [autoCompleteKey, setAutoCompleteKey] = useState<'true' | 'false'>(
    'false',
  );

  const {
    value,
    setValue: setLocationValue,
    suggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    defaultValue: atHomeLocation?.address,
  });

  // Set initial location value
  useEffect(() => {
    if (atHomeLocation?.address && !value) {
      setLocationValue(atHomeLocation?.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atHomeLocation?.address, setLocationValue]);

  const handleLocationSelect = async (item: { label: string }) => {
    try {
      if (!item?.label) return;

      setIsLoading(true);
      setLocationValue(item.label);
      setAutoCompleteKey((prev) => (prev === 'true' ? 'false' : 'true'));

      const location = await getLocationFromAddress(item.label);

      if (location) {
        dispatch(setAtHomeLocation({ ...location, timezone: getTimezone() }));
      }
    } catch (error: any) {
      captureException(error, {
        context: {
          name: 'AtHomeLocationAutoComplete',
          data: {
            error: error.message,
            location: item.label,
          },
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  let marginBottom: string;
  if (isLocationAutocompleteFocused) {
    if (isSmallScreen) {
      marginBottom = '150px';
    } else {
      marginBottom = '300px';
    }
  }

  return (
    <Flex
      flexDir="column"
      h="full"
      justifyContent="space-between"
      pb="4"
      px="6"
      w="full"
    >
      <Text color="#718096" mb="4" textAlign="center" textStyle="body">
        To have the lesson at your favorite {locationType}, please enter the
        address so your instructor will know where you want the lesson.
      </Text>

      <WithAnimOnLayout style={{ marginBottom }}>
        <HStack spacing={1}>
          <Text color="defaultText" textStyle="caption">
            Lesson Address
          </Text>
          <Text color="red.500" textStyle="caption">
            *
          </Text>
        </HStack>

        <ChakraReactSelect
          isLoading={isLoading}
          isSearchable
          isClearable
          menuPlacement="bottom"
          selectedOptionColorScheme="blue"
          chakraStyles={{
            dropdownIndicator: (provided) => ({
              ...provided,
              display: 'none',
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              borderWidth: 0,
            }),
            menuList: (provided) => ({
              ...provided,
              boxShadow: 'none',
            }),
            placeholder: (provided) => ({
              ...provided,
              textAlign: 'left',
            }),
            valueContainer: (provided) => ({
              ...provided,
              marginRight: 8,
              paddingBottom: 4,
              paddingLeft: 14,
              paddingRight: 14,
              paddingTop: 4,
            }),
          }}
          blurInputOnSelect
          onBlur={() => setIsLocationAutocompleteFocused(false)}
          onFocus={() => setIsLocationAutocompleteFocused(true)}
          placeholder={atHomeLocation?.address || 'Enter lesson address'}
          size="lg"
          inputValue={value}
          defaultInputValue={value}
          components={{ IndicatorsContainer }}
          // key so that when input is blurred, text does not disappear
          key={autoCompleteKey}
          onInputChange={(e: any) => {
            setLocationValue?.(e);
          }}
          onChange={handleLocationSelect}
          options={suggestions?.data?.map((option) => ({
            label: option?.description,
            value: option?.place_id,
          }))}
        />
      </WithAnimOnLayout>
    </Flex>
  );
}
