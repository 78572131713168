import {
  Flex,
  SkeletonText,
  Text,
  TextDecorationProps,
} from '@chakra-ui/react';

interface Props {
  isDiscount?: boolean;
  isLoading: boolean;
  label: string;
  onClick?(): void;
  textDecorationLine?: TextDecorationProps['textDecorationLine'];
  value: string;
}

export default function BreakdownRow({
  isDiscount = false,
  isLoading,
  label,
  onClick,
  textDecorationLine,
  value,
}: Props) {
  return (
    <Flex align="center" justify="space-between" mt="4">
      <Text
        flex={1}
        _hover={{
          color: onClick ? 'defaultText' : undefined,
          cursor: onClick ? 'pointer' : undefined,
        }}
        onClick={onClick}
        textDecorationLine={textDecorationLine}
        textStyle="small"
      >
        {label}
      </Text>

      <SkeletonText isLoaded={!isLoading} noOfLines={1}>
        <Text
          color={isDiscount ? 'accent' : 'defaultText'}
          noOfLines={1}
          textStyle={isDiscount ? 'bold' : 'small'}
        >
          {value}
        </Text>
      </SkeletonText>
    </Flex>
  );
}
