import { Box, Flex, Skeleton } from '@chakra-ui/react';
import { useEffect } from 'react';

import { QuestionProps } from './interface';

import useExperienceBadges from '../../../hooks/useExperienceBadges';
import useLocalSelectedBadges from '../../../hooks/useLocalSelectedBadges';
import { FiltersState } from '../../../state/slices/filtersSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import QuizButton from '../QuizButton';

type Props = {
  goToNextQuestion(): void;
} & QuestionProps;

export default function QuizExperience({
  goToNextQuestion,
  onSelectAnswer,
}: Props) {
  const { localSelectedBadges, toggleLocalSelectedBadge } =
    useLocalSelectedBadges();
  const {
    experienceBadgeGroupsInAllListings,
    loading: loadingExperienceBadgeGroups,
  } = useExperienceBadges();

  const onToggleLocalBadge = (
    badge: FiltersState['localSelectedBadges'][0],
  ) => {
    toggleLocalSelectedBadge(badge);
  };

  // If no exp badges exist in any of the listing results, skip over the question
  useEffect(() => {
    if (
      !loadingExperienceBadgeGroups &&
      (!experienceBadgeGroupsInAllListings?.length ||
        experienceBadgeGroupsInAllListings.every(
          (experienceBadgeGroup) => !experienceBadgeGroup.badges?.length,
        ))
    ) {
      capturePostHogEvent('quizNextForced', {
        question: 'What experience matters to you?',
      });
      goToNextQuestion();
    }
  }, [
    experienceBadgeGroupsInAllListings,
    goToNextQuestion,
    loadingExperienceBadgeGroups,
  ]);

  useEffect(() => {
    if (
      experienceBadgeGroupsInAllListings.length &&
      experienceBadgeGroupsInAllListings[0]?.badges?.length
    ) {
      const experienceBadges = experienceBadgeGroupsInAllListings[0];
      const badgeNames = localSelectedBadges
        .filter((badge) => experienceBadges?.badges.includes(badge))
        .map((badge) => badge.name)
        .join(';');

      const options = experienceBadges?.badges
        ?.map((badge) => badge.name)
        .join(';');

      onSelectAnswer(badgeNames, options);
    }
  }, [experienceBadgeGroupsInAllListings, localSelectedBadges, onSelectAnswer]);

  return (
    <Skeleton
      isLoaded={!loadingExperienceBadgeGroups}
      h={loadingExperienceBadgeGroups ? '200px' : undefined}
      w="full"
    >
      <Box w="full">
        <Flex justifyContent="center" w="full" wrap="wrap" gap={2}>
          {!!experienceBadgeGroupsInAllListings?.length &&
            experienceBadgeGroupsInAllListings[0]?.badges?.map((badge) => (
              <QuizButton
                badge={badge}
                key={badge.id}
                isSelected={localSelectedBadges.some((b) => b.id === badge.id)}
                onToggleBadge={onToggleLocalBadge}
                h="32px"
              />
            ))}
        </Flex>
      </Box>
    </Skeleton>
  );
}
