import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Portal,
  Show,
  Skeleton,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';

import CheckoutBackButton from './components/CheckoutBackButton';
import CheckoutCard from './components/CheckoutCard';
import CheckoutDetailsError from './components/CheckoutDetailsError';
import CheckoutItem, { CheckoutItemAction } from './components/CheckoutItem';
import useNeedGear from './components/Gear/hooks/useNeedGear';
import CheckoutLessonLength from './components/LessonLength/components/CheckoutLessonLength';
import CheckoutLocation from './components/Location/components/CheckoutLocation';
import ChooseLocationModal from './components/Location/components/ChooseLocationModal';
import CheckoutNumberOfLessons from './components/NumberOfLessons/components/CheckoutNumberOfLessons';
import NumberOfLessonsModal from './components/NumberOfLessons/components/NumberOfLessonsModal';
import CheckoutOurPromise from './components/OurPromise/components/CheckoutOurPromise';
import CheckoutParticipants from './components/Participants/components/CheckoutParticipants';
import PaymentButtons from './components/Payment/components/PaymentButtons';
import PaymentProvider from './components/Payment/components/PaymentProvider';
import CheckoutDateAndTime from './components/Schedule/components/CheckoutDateAndTime';
import ChooseScheduleModal from './components/Schedule/components/ChooseScheduleModal';
import CheckoutStudentInfo from './components/StudentInfo/components/CheckoutStudentInfo';
import useCheckoutSubmit from './hooks/useCheckoutSubmit';
import useGetAtHomeListingBySlug from './hooks/useGetAtHomeListingBySlug';
import useGetListingBySlug from './hooks/useGetListingBySlug';
import useSkillsBySlug from './hooks/useSkillBySlug';

import { ListingStatus } from '../../api';
import BookingWidget from '../../components/BookingWidget';
import CoachInfo from '../../components/BookingWidget/Confirm/CoachInfo';
import YourTotal from '../../components/BookingWidget/Confirm/YourTotal';
import Page from '../../components/Page';
import useCurrentLocation from '../../hooks/useCurrentLocation';
import useDispatchDateTime from '../../hooks/useDispatchDateTime';
import useExtractedListingParams from '../../hooks/useExtractedListingParams';
import useIsAtHomeOrListingExistsWithNoLocations from '../../hooks/useIsAtHomeOrListingExistsWithNoLocations';
import useLessonLength from '../../hooks/useLessonLength';
import usePageLayout from '../../hooks/usePageLayout';
import useSelectedLocation from '../../hooks/useSelectedLocation';
import useTapfiliate from '../../hooks/useTapfiliate';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  selectIsRecurring,
  selectSelectedSkill,
  setAtHomeLocation,
  setConfirmEmail,
  setConfirmName,
  setConfirmPhoneNumber,
  setIsAtHome,
  setIsRecurring,
  setParticipants,
  setPkg,
  setSelectedSkill,
} from '../../state/slices/filtersSlice';
import {
  selectSelectedListing,
  setSelectedListing,
} from '../../state/slices/listingsSlice';
import capturePostHogEvent from '../../utils/capturePostHogEvent';
import { getClosestLocation } from '../../utils/getDistanceAway';
import PosthogEvent from '../../utils/posthogEvents';
import slugify from '../../utils/slugify';
import getFirstName from '../../utils/user';

function Checkout() {
  const params = useParams();
  const navigate = useNavigate();
  const { pageMargin } = usePageLayout();
  const dispatch = useAppDispatch();

  const skillSlug = slugify(params.skill);
  const listingSlug = slugify(params.slug);
  const [hasError, setHasError] = useState(false);
  const [isInServiceArea, setIsInServiceArea] = useState<boolean>(true);

  const selectedListing = useAppSelector(selectSelectedListing);
  const selectedSkill = useAppSelector(selectSelectedSkill);
  const isRecurring = useAppSelector(selectIsRecurring);
  const isAtHomeOrListingExistsWithNoLocations =
    useIsAtHomeOrListingExistsWithNoLocations();

  const { findSkill } = useSkillsBySlug(skillSlug);

  const { findUniqueListing } = useGetListingBySlug(listingSlug);

  const { getAtHomeListingBySkillId } = useGetAtHomeListingBySlug(listingSlug);
  const {
    isOpen: isOpenLocationModal,
    onOpen: onOpenLocationModal,
    onClose: onCloseLocationModal,
  } = useDisclosure();
  const {
    isOpen: isOpenScheduleModal,
    onOpen: onOpenScheduleModal,
    onClose: onCloseScheduleModal,
  } = useDisclosure();
  const {
    isOpen: isOpenNumberOfLessonsModal,
    onOpen: onOpenNumberOfLessonsModal,
    onClose: onCloseNumberOfLessonsModal,
  } = useDisclosure();

  const { toggleNeedGear, needGear } = useNeedGear();
  const { dispatchDateTime } = useDispatchDateTime();
  const { currentLocation } = useCurrentLocation();
  const [selectedLocation, setSelectedLocation] = useSelectedLocation();
  const { setSelectedLessonLengthForCurrentCategory } = useLessonLength();
  const { extractListingParams, extractedListingParams } =
    useExtractedListingParams();
  const { initTapfiliate } = useTapfiliate();

  const initListing = useCallback(async () => {
    const hasValidSlug = listingSlug && skillSlug;
    if (!hasValidSlug) return;

    try {
      const skillResponse = await findSkill();
      const skill = skillResponse.data?.findManySkill?.[0];
      if (!skill) throw new Error('Skill not found');

      const listing = isAtHomeOrListingExistsWithNoLocations
        ? await getAtHomeListingBySkillId(skill.id)
        : (await findUniqueListing())?.data?.findUniqueListing;
      if (!listing) throw new Error('Listing not found');

      setIsInServiceArea(
        isAtHomeOrListingExistsWithNoLocations
          ? !!listing?.isInServiceArea
          : true,
      );
      const isNotRealListing = listing.status !== ListingStatus.Real;
      if (isNotRealListing) {
        navigate('/', { replace: true });
      }

      const hasExclusivePack = listing?.exclusivePack && !isRecurring;
      if (hasExclusivePack) {
        dispatch(setPkg(listing.exclusivePack));
      }

      dispatch(setSelectedSkill(skill));
      dispatch(setIsAtHome(isAtHomeOrListingExistsWithNoLocations));

      dispatch(
        setSelectedListing({
          ...listing,
          media: listing?.media?.map((m) => ({
            id: m.id,
            isFeatured: m.isFeatured,
            src: m.src,
            type: m.type,
            updatedAt: m.updatedAt,
            preview: m.preview,
          })),
          badges: listing?.badges?.map((b) => ({ ...b, ...b.badge })),
          travelMiles: listing.travelMiles || 0,
        }),
      );
    } catch (error) {
      console.error('Checkout/ListingDetails error', { error });
      setHasError(true);
    }
  }, [
    listingSlug,
    skillSlug,
    findSkill,
    getAtHomeListingBySkillId,
    findUniqueListing,
    dispatch,
    navigate,
    isRecurring,
    isAtHomeOrListingExistsWithNoLocations,
  ]);

  const syncUrlParams = useCallback(() => {
    const urlParams = extractListingParams(
      new URLSearchParams(window.location.search),
    );

    dispatchDateTime(urlParams.date_time);
    dispatch(setPkg(urlParams.pkg));
    dispatch(setParticipants(urlParams.participants));
    dispatch(setIsRecurring(urlParams.is_recurring));
    dispatch(setIsAtHome(urlParams.is_at_home));

    if (urlParams.confirm_name) {
      dispatch(setConfirmName(urlParams.confirm_name));
    }
    if (urlParams.confirm_email) {
      dispatch(setConfirmEmail(urlParams.confirm_email));
    }
    if (urlParams.confirm_phone) {
      dispatch(setConfirmPhoneNumber(urlParams.confirm_phone));
    }
    if (urlParams.at_home_location && urlParams.is_at_home) {
      dispatch(setAtHomeLocation(urlParams.at_home_location));
    }
    if (urlParams.lesson_length && selectedSkill?.category) {
      setSelectedLessonLengthForCurrentCategory(urlParams.lesson_length);
    }
    if (urlParams.origin) {
      try {
        initTapfiliate(new URL(urlParams.origin).search);
      } catch (err) {
        console.error('paramsOrigin err', { urlParams, err });
      }
    }
  }, [
    dispatch,
    dispatchDateTime,
    extractListingParams,
    initTapfiliate,
    selectedSkill?.category,
    setSelectedLessonLengthForCurrentCategory,
  ]);

  const loadInitialLocation = useCallback(() => {
    if (!selectedListing) return;

    if (extractedListingParams?.selected_location?.locationId) {
      const selectedListingLocation =
        selectedListing.locations.find((l) => l.id === selectedLocation?.id) ||
        selectedListing.locations.find(
          (l) => l.id === extractedListingParams?.selected_location?.locationId,
        );

      if (selectedListingLocation) {
        setSelectedLocation(selectedListingLocation);
        return;
      }
    }

    const isListingLocation =
      selectedLocation?.id &&
      selectedListing.locations.find((l) => l.id === selectedLocation?.id);
    if (isListingLocation) return;

    const { location: closestLocation } = getClosestLocation(
      [currentLocation?.latitude, currentLocation?.longitude],
      selectedListing?.locations,
    );
    if (closestLocation?.id === selectedLocation?.id) {
      return;
    }
    setSelectedLocation({
      id: closestLocation?.id,
      placeName: closestLocation?.placeName,
      address: closestLocation?.address,
      environmentSettings: closestLocation?.environmentSettings,
      latitude: closestLocation?.latitude,
      longitude: closestLocation?.longitude,
      listings: [selectedListing],
      fee: closestLocation?.fee,
    });
  }, [
    currentLocation?.latitude,
    currentLocation?.longitude,
    extractedListingParams.selected_location?.locationId,
    selectedListing,
    selectedLocation?.id,
    setSelectedLocation,
  ]);

  useEffect(() => {
    capturePostHogEvent(PosthogEvent.ViewFullScreenCheckout, {
      ...selectedListing,
    });

    initListing();
    syncUrlParams();
    // Prevent running into render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initListing]);

  useEffect(() => {
    loadInitialLocation();
  }, [loadInitialLocation]);

  const {
    handleCheckoutWithPaymentRequest,
    handleCheckoutWithoutPaymentRequest,
    isLoading: isSubmitting,
    canSubmitForm,
    paymentRequest,
  } = useCheckoutSubmit();

  const pageTitle = `Complete checkout for your lessons with ${getFirstName(
    selectedListing?.proName,
  )}`;

  if (isMobile) {
    return (
      <BookingWidget
        listing={selectedListing}
        listingUrl={`/listings/${selectedListing?.skill?.slug}/${selectedListing?.slug}`}
      />
    );
  }

  return (
    <Page
      showHeader
      showFooter={false}
      hideCoachesLocationButton
      showHeaderMenu={false}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={selectedListing?.proBio} />
        <link
          rel="canonical"
          href={`${window.location.origin}${window.location.pathname}`}
        />
      </Helmet>
      {!hasError ? (
        <Flex
          w="full"
          py={{ base: '10', sm: '10', md: '10', lg: '16' }}
          px={`${pageMargin}px`}
          maxW="1375px"
          overflowX="scroll"
        >
          <HStack alignItems="flex-start" w="full">
            <Show breakpoint="(min-width: 835px)">
              <CheckoutBackButton />
            </Show>

            <VStack
              ml={{ base: '0', sm: '0', md: '0', lg: '10' }}
              mt={{ base: '2', sm: '2', md: '2', lg: '1' }}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Show breakpoint="(max-width: 834px)">
                <CheckoutBackButton />
              </Show>
              <Skeleton
                isLoaded={Boolean(
                  selectedSkill?.slug && selectedListing?.proName,
                )}
              >
                <Heading as="h1" color="slate.500" noOfLines={1} variant="h3">
                  {`Book lessons with ${selectedListing?.proName}`}
                </Heading>
              </Skeleton>

              <HStack mt="8" alignItems="flex-start">
                <VStack
                  flex={{ base: 1, sm: 1, md: 1, lg: 1.3 }}
                  alignItems="flex-start"
                >
                  <Heading variant="h4">Your lessons</Heading>

                  <CheckoutLocation
                    skillSlug={skillSlug}
                    isAtHome={isAtHomeOrListingExistsWithNoLocations}
                    onPress={onOpenLocationModal}
                  />

                  <CheckoutDateAndTime onPress={onOpenScheduleModal} />

                  <CheckoutNumberOfLessons
                    onPress={onOpenNumberOfLessonsModal}
                  />

                  <CheckoutItem
                    title="Need equipment?"
                    subtitle="I need equipment for the lesson"
                  >
                    <CheckoutItemAction.Switch
                      isChecked={needGear}
                      onChange={toggleNeedGear}
                    />
                  </CheckoutItem>

                  <Flex
                    w="full"
                    gap={{
                      base: '0',
                      sm: '0',
                      md: '0',
                      lg: '14',
                    }}
                    direction={{
                      base: 'column',
                      sm: 'column',
                      md: 'column',
                      lg: 'row',
                    }}
                  >
                    <CheckoutLessonLength />
                    <CheckoutParticipants />
                  </Flex>

                  <Divider mt="4" mb="10" />

                  <CheckoutStudentInfo />

                  <PaymentButtons
                    isLoading={isSubmitting}
                    isDisabled={!canSubmitForm}
                    isRecurring={isRecurring}
                    paymentRequest={paymentRequest}
                    onCheckoutWithPaymentRequest={
                      handleCheckoutWithPaymentRequest
                    }
                    onCheckoutWithoutPaymentRequest={
                      handleCheckoutWithoutPaymentRequest
                    }
                  />
                </VStack>

                <VStack
                  flex={1}
                  pl={{
                    base: '30px',
                    sm: '30px',
                    md: '30px',
                    lg: '100px',
                  }}
                >
                  <CheckoutCard>
                    <CoachInfo />
                    <Box px="6">
                      <Divider my="6" />
                    </Box>
                    <YourTotal
                      hideBreakdown
                      title="Pricing information"
                      hasBreakdownDivider
                    />
                  </CheckoutCard>

                  <CheckoutCard>
                    <CheckoutOurPromise />
                  </CheckoutCard>
                </VStack>
              </HStack>
            </VStack>
          </HStack>
        </Flex>
      ) : (
        <CheckoutDetailsError />
      )}

      <Portal>
        <ChooseLocationModal
          isOpen={isOpenLocationModal}
          onClose={onCloseLocationModal}
          isInServiceArea={isInServiceArea}
        />
        <ChooseScheduleModal
          isOpen={isOpenScheduleModal}
          onClose={onCloseScheduleModal}
        />
        <NumberOfLessonsModal
          isOpen={isOpenNumberOfLessonsModal}
          onClose={onCloseNumberOfLessonsModal}
        />
      </Portal>
    </Page>
  );
}

export default function CheckoutWrapper() {
  return (
    <PaymentProvider>
      <Checkout />
    </PaymentProvider>
  );
}
