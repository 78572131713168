import {
  Box,
  Center,
  Flex,
  IconButton,
  LayoutProps,
  SlideFade,
  Spinner,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HiOutlineArrowsExpand } from 'react-icons/hi';
import Map, {
  MapRef,
  Marker,
  ViewState,
  ViewStateChangeEvent,
} from 'react-map-gl';
import { MarkerEvent } from 'react-map-gl/dist/esm/types';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ListingInBoundingBoxNoLocations,
  LocationAffiliateName,
  UniqueListingQuery,
} from '../../../api';
import TopGolfIconWhite from '../../../assets/top-golf/top-golf-icon-white.svg';
import LocationFeeMarkerIcon from '../../../components/LocationFeeMarkerIcon';
import Pin from '../../../components/Map/components/Pin';
import PinAtHome from '../../../components/Map/components/PinAtHome';
import TravelAddressCircle from '../../../components/Map/components/TravelAddressCircle';
import useInitialViewStateZoom from '../../../hooks/useInitialViewStateZoom';
import useIsAtHomeOrListingExistsWithNoLocations from '../../../hooks/useIsAtHomeOrListingExistsWithNoLocations';
import useIsMobile from '../../../hooks/useIsMobile';
import useMapInitialViewState from '../../../hooks/useMapInitialViewState';
import useSelectedLocation from '../../../hooks/useSelectedLocation';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import { MAPBOX_ACCESS_TOKEN } from '../../../utils/constants';
import PosthogEvent from '../../../utils/posthogEvents';
import { SelectedLocation } from '../../../utils/types';

type Props = {
  height: LayoutProps['h'];
  isInteractive?: boolean;
  listing: Pick<
    UniqueListingQuery['findUniqueListing'],
    'id' | 'proAvatar' | 'proName' | 'slug'
  > &
    Partial<
      Pick<
        ListingInBoundingBoxNoLocations,
        'travelAddressLatitude' | 'travelAddressLongitude' | 'travelMiles'
      >
    > & {
      locations?: SelectedLocation[];
    };
  minH?: LayoutProps['minH'];
  width: LayoutProps['w'];
  parentComponent?: 'ChooseYourLocation' | 'ListingDetails' | 'CheckoutWidget';
};

export default function MediaMiniMap({
  height,
  isInteractive = true,
  listing,
  minH,
  width,
  parentComponent = 'ChooseYourLocation',
}: Props) {
  const bgColor = useColorModeValue('#EDF2F7', '#4A5568');
  const [selectedLocation, setSelectedLocation] = useSelectedLocation();
  const [selectedHit, setSelectedHit] = useState<SelectedLocation>();
  const portalRef = useRef();
  const mapRef = useRef<MapRef>();
  const routerLocation = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const { initialViewStateZoom } = useInitialViewStateZoom();
  const atHomeLocationPin = useMemo(
    () => ({
      id: listing?.id || '0',
      latitude: listing?.travelAddressLatitude || 0,
      longitude: listing?.travelAddressLongitude || 0,
    }),
    [
      listing?.id,
      listing?.travelAddressLatitude,
      listing?.travelAddressLongitude,
    ],
  );
  const isAtHomeOrListingExistsWithNoLocations =
    useIsAtHomeOrListingExistsWithNoLocations();

  const mapCenterPin = isAtHomeOrListingExistsWithNoLocations
    ? atHomeLocationPin
    : selectedLocation;

  const { center, initialViewState } = useMapInitialViewState({ listing });

  const [viewState, setViewState] = useState<
    Pick<ViewState, 'latitude' | 'longitude' | 'zoom'>
  >({
    zoom: initialViewStateZoom,
    latitude: center?.latitude || 0,
    longitude: center?.longitude || 0,
  });

  const onViewOtherLocations = () => {
    navigate(
      `/choose-your-location/${listing?.slug}?pathname=${window.location.pathname}`,
    );
  };

  const onDeselectHit = () => {
    setSelectedHit(undefined);
  };

  useEffect(() => {
    if (!selectedLocation?.id) return;
    if (
      isAtHomeOrListingExistsWithNoLocations ||
      selectedLocation?.id === atHomeLocationPin?.id
    ) {
      onDeselectHit();
    } else {
      setSelectedHit(selectedLocation);
    }
  }, [
    isAtHomeOrListingExistsWithNoLocations,
    atHomeLocationPin,
    selectedLocation,
    setSelectedHit,
  ]);

  const handleChooseThisLocation = useCallback(
    (location: SelectedLocation) =>
      (event: MarkerEvent<mapboxgl.Marker, MouseEvent>) => {
        event.originalEvent.stopPropagation();
        if (!isInteractive || isAtHomeOrListingExistsWithNoLocations) return;
        capturePostHogEvent(
          parentComponent === 'CheckoutWidget'
            ? PosthogEvent.BookingWidgetChangeLocation
            : PosthogEvent.ClickMapCardChooseLocation,
          {
            action: 'MediaMiniMap',
            id: location?.id,
            latitude: location?.latitude,
            longitude: location?.longitude,
            placeName: location?.placeName,
          },
        );
        setSelectedLocation(location);
        setSelectedHit(location);
        if (routerLocation.pathname.startsWith('/locations/')) {
          navigate('/choose-a-pro');
        }
      },
    [
      isAtHomeOrListingExistsWithNoLocations,
      isInteractive,
      navigate,
      routerLocation.pathname,
      setSelectedHit,
      setSelectedLocation,
      parentComponent,
    ],
  );

  const onMapClick = () => {
    onDeselectHit();
    onViewOtherLocations();
  };

  const pins = useMemo(() => {
    const pinLocations = (
      isAtHomeOrListingExistsWithNoLocations
        ? [mapCenterPin]
        : listing?.locations || []
    ).filter(Boolean);
    return pinLocations.map((location: Props['listing']['locations'][0]) => {
      const isSelectedLocation = location.id === mapCenterPin?.id;
      const isSelectedHit = location.id === selectedHit?.id;
      const isActive = Boolean(
        isSelectedHit ||
          isSelectedLocation ||
          isAtHomeOrListingExistsWithNoLocations,
      );
      const isTopGolfLocation =
        location.LocationAffiliate?.name === LocationAffiliateName.TopGolf;
      return isSelectedHit && !isAtHomeOrListingExistsWithNoLocations ? null : (
        <Box
          key={location.id || `${location.latitude},${location.longitude}`}
          zIndex={isSelectedHit ? 1 : -1}
        >
          <Marker
            anchor="center"
            latitude={location.latitude}
            longitude={location.longitude}
            offset={[0, 0]}
            onClick={handleChooseThisLocation(location)}
            style={{ cursor: 'pointer' }}
          >
            {isAtHomeOrListingExistsWithNoLocations ? (
              <>
                <TravelAddressCircle listing={listing} />
                <PinAtHome isActive={false} listing={listing} />
                <LocationFeeMarkerIcon
                  isActive={isActive}
                  location={location}
                />
              </>
            ) : (
              <>
                <Pin
                  activeColor={isTopGolfLocation ? '#15212D' : undefined}
                  inactiveColor={isTopGolfLocation ? '#15212D' : undefined}
                  isActive={isActive}
                  src={isTopGolfLocation ? TopGolfIconWhite : undefined}
                />
                <LocationFeeMarkerIcon
                  isActive={isActive}
                  location={location}
                />
              </>
            )}
          </Marker>
        </Box>
      );
    });
  }, [
    handleChooseThisLocation,
    isAtHomeOrListingExistsWithNoLocations,
    listing,
    mapCenterPin,
    selectedHit?.id,
  ]);

  // update the map from a viewState change
  const updateMap = (
    nextViewState: Pick<ViewState, 'latitude' | 'longitude' | 'zoom'>,
  ) => {
    setViewState((prev) => ({
      ...prev,
      latitude: nextViewState?.latitude ?? 0,
      longitude: nextViewState?.longitude ?? 0,
      zoom: nextViewState?.zoom ?? prev.zoom ?? 9,
    }));
  };

  const listingLocationsExist = Boolean(listing?.locations);
  useEffect(() => {
    if (!listingLocationsExist || !center) return;
    setViewState((prev) => ({
      ...prev,
      latitude: center.latitude ?? 0,
      longitude: center.longitude ?? 0,
      zoom: initialViewStateZoom,
    }));
  }, [center, initialViewStateZoom, listingLocationsExist]);

  // Map component callbacks
  const handleMapMove = (event: ViewStateChangeEvent) => {
    updateMap(event?.viewState);
  };

  const isSelectedHitTopGolfLocation =
    selectedHit?.LocationAffiliate?.name === LocationAffiliateName.TopGolf;

  if (!(center?.latitude || center?.longitude)) {
    return (
      <Flex
        alignItems="center"
        bg={bgColor}
        borderRadius="16px"
        h={height}
        justifyContent="center"
        minH={minH}
        overflow="hidden"
        pos="relative"
        w={isMobile ? '100%' : width}
      >
        <Center>
          <Spinner size="xl" />
        </Center>
      </Flex>
    );
  }

  return (
    <Flex
      alignItems="center"
      bg={bgColor}
      borderRadius="16px"
      h={height}
      justifyContent="center"
      minH={minH}
      overflow="hidden"
      pos="relative"
      w={isMobile ? '100%' : width}
    >
      <Box
        borderRadius="16px"
        h="full"
        overflow="hidden"
        position="relative"
        w="100%"
      >
        <Map
          initialViewState={initialViewState}
          latitude={viewState.latitude || 0}
          longitude={viewState.longitude || 0}
          mapStyle="mapbox://styles/tmt-clayton/clbf9voiu000a15qvkk0gzgcq"
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          onClick={onMapClick}
          onMove={handleMapMove}
          ref={mapRef}
          scrollZoom={isInteractive}
          style={{
            borderRadius: '16px',
            marginBottom: '0',
            overflow: 'hidden',
          }}
        >
          {pins}

          {/* selected pin */}
          {selectedHit?.id ? (
            <Marker
              anchor="center"
              latitude={selectedHit.latitude ?? 0}
              longitude={selectedHit.longitude ?? 0}
              offset={[0, 0]}
              style={{ zIndex: 2 }}
            >
              <Pin
                activeColor={
                  isSelectedHitTopGolfLocation ? '#15212D' : undefined
                }
                inactiveColor={
                  isSelectedHitTopGolfLocation ? '#15212D' : undefined
                }
                isActive
                src={
                  isSelectedHitTopGolfLocation ? TopGolfIconWhite : undefined
                }
              />
              <LocationFeeMarkerIcon isActive location={selectedHit} />
            </Marker>
          ) : null}
        </Map>

        <Box mx="0" position="absolute" right="2" top="2" zIndex="3">
          <IconButton
            aria-label="Expand"
            borderWidth={0}
            boxShadow="0px 0px 0px 0.7px rgba(0, 0, 0, 0.10)"
            icon={<HiOutlineArrowsExpand size={24} />}
            onClick={onMapClick}
            size="md"
            variant="outline"
          />
        </Box>

        <Box
          bottom="0"
          left="0"
          mx="0"
          position="absolute"
          right="0"
          zIndex="4"
        >
          <SlideFade in={!!selectedHit}>
            <Stack
              pointerEvents={selectedHit ? 'auto' : 'none'}
              bottom="0"
              left="0"
              right="0"
              display="flex"
              m="4"
              position="absolute"
              ref={portalRef}
              bg="white"
              borderRadius="16px"
            />
          </SlideFade>
        </Box>
      </Box>

      {!isInteractive && (
        <Box bottom="0" left="0" pos="absolute" right="0" top="0" zIndex={2} />
      )}
    </Flex>
  );
}
