import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import BreakdownRow from './BreakdownRow';

import { PricePayload } from '../../../api';
import { useModal } from '../../../context/ModalContext';
import useLessonLength from '../../../hooks/useLessonLength';
import usePickleheadsDiscount from '../../../hooks/usePickleheadsDiscount';
import usePricing from '../../../hooks/usePricing';
import { useAppSelector } from '../../../state/hooks';
import { selectParticipants } from '../../../state/slices/filtersSlice';
import { Listing } from '../../../utils/types';

type Props = {
  listing: Pick<Listing, 'id'>;
};

export default function RecurringBreakdown({ listing }: Props): JSX.Element {
  const [recurringPrice, setRecurringPrice] = useState<PricePayload | null>();
  const participants = useAppSelector(selectParticipants);
  const { handleOpen } = useModal();
  const { lessonLength } = useLessonLength();
  const { isPickleheadsReferral, pickleheadsDiscountAmount } =
    usePickleheadsDiscount();

  const { getSubscriptionPrice, isLoading: isPricingLoading } = usePricing();

  useEffect(() => {
    if (!listing?.id) return;
    getSubscriptionPrice({ listingId: listing.id }).then(setRecurringPrice);
  }, [listing, participants, getSubscriptionPrice]);

  const packageText = `Weekly ${lessonLength} minute lessons`;

  const participantText =
    participants === 1 ? '1 student' : `${participants} students`;

  return (
    <Flex flexDir="column">
      <BreakdownRow
        isLoading={isPricingLoading}
        label={
          participants === 1
            ? `${packageText}`
            : `${packageText} (${participantText})`
        }
        value={`$${recurringPrice?.packagePriceWithoutFees} / month`}
      />

      {!!recurringPrice?.locationFee && (
        <BreakdownRow
          isLoading={isPricingLoading}
          label="Venue reservation"
          onClick={() => handleOpen('locationFee')}
          textDecorationLine="underline"
          value={`$${recurringPrice.locationFee} / month`}
        />
      )}

      <BreakdownRow
        isLoading={isPricingLoading}
        label="One-time introduction fee"
        value={`$${recurringPrice?.introFee}`}
      />

      <BreakdownRow
        isLoading={isPricingLoading}
        label="Taxes and fees"
        value={`$${recurringPrice?.checkoutFee} / month`}
      />

      {!!isPickleheadsReferral && (
        <BreakdownRow
          isDiscount
          isLoading={isPricingLoading}
          label="Pickleheads discount"
          value={`- $${pickleheadsDiscountAmount}`}
        />
      )}
    </Flex>
  );
}
