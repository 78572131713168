import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { HiChevronLeft, HiChevronRight, HiX } from 'react-icons/hi';

import * as Policies from '../Policies';
import { RouterLink } from '../RouterLink';
import { Show } from '../Show';

interface ListItemProps {
  label: string;
  onClick: () => void;
}

function ListItem({ label, onClick }: ListItemProps) {
  return (
    <Flex
      borderBottom="1px"
      borderColor={useColorModeValue('gray.200', 'gray.600')}
      align="center"
      justify="space-between"
      p="6"
      _hover={{
        bg: useColorModeValue('gray.50', 'gray.600'),
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Text>{label}</Text>
      <HiChevronRight size={24} />
    </Flex>
  );
}

type Policy =
  | 'additionalProTos'
  | 'tos'
  | 'paymentTos'
  | 'studentRefund'
  | 'studentRelease'
  | 'cancellation'
  | 'lessonRequirements'
  | 'privacy'
  | 'studentFaq'
  | 'proFaq';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  initialPolicy: Policy;
}

export default function PolicyDrawer({
  isOpen,
  onClose,
  initialPolicy = null,
}: Props) {
  const btnRef = useRef();
  const [selectedPolicy, setSelectedPolicy] = useState<Policy>(
    initialPolicy || null,
  );

  useEffect(() => {
    setSelectedPolicy(initialPolicy);
  }, [initialPolicy]);

  const handleClick = (policy: Policy | null) => () => {
    setSelectedPolicy(policy);
  };

  const renderPolicy = () => {
    switch (selectedPolicy) {
      case 'additionalProTos':
        return <Policies.AdditionalProTerms />;
      case 'tos':
        return <Policies.Terms />;
      case 'paymentTos':
        return <Policies.PaymentTerms />;
      case 'studentRefund':
        return <Policies.StudentRefundPolicy />;
      case 'studentRelease':
        return <Policies.StudentRefundPolicy />;
      case 'cancellation':
        return <Policies.Cancellation />;
      case 'lessonRequirements':
        return <Policies.LessonRequirements />;
      case 'privacy':
        return <Policies.Privacy />;

      default:
        return null;
    }
  };

  const renderHeader = () => {
    switch (selectedPolicy) {
      case 'tos':
        return 'Terms of Service';
      case 'paymentTos':
        return 'Payment Terms of Service';
      case 'studentRefund':
        return 'Student Refund Policy';
      case 'studentRelease':
        return 'Student Release and Waiver';
      case 'additionalProTos':
        return 'Additional Terms for Pros';
      case 'cancellation':
        return 'Cancellation and Rescheduling Policy';
      case 'lessonRequirements':
        return 'Lesson Standards and Requirements';
      case 'privacy':
        return 'Privacy Policy';
      case 'studentFaq':
        return 'Student FAQ';

      default:
        return null;
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="left"
      size={selectedPolicy ? 'lg' : 'md'}
      isFullHeight
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        {selectedPolicy ? (
          <Flex direction="row" align="baseline" justify="space-between" mr="4">
            <DrawerHeader
              display="flex"
              flex="1"
              flexDirection="row"
              alignItems="center"
            >
              <IconButton
                aria-label="Go back"
                icon={<HiChevronLeft size={24} />}
                variant="ghost"
                onClick={handleClick(null)}
              />

              {renderHeader()}
            </DrawerHeader>
          </Flex>
        ) : (
          <Flex
            direction="row"
            align="baseline"
            justify="space-between"
            mt="8"
            mr="4"
          >
            <DrawerHeader>Help</DrawerHeader>
            <IconButton
              aria-label="Close"
              variant="ghost"
              colorScheme="gray"
              icon={<HiX size={24} />}
              onClick={onClose}
            />
          </Flex>
        )}
        <Show condition={!selectedPolicy}>
          <>
            <Box px="6" mt="12" mb="4">
              <Text fontSize="xs" color="muted" fontWeight="bold">
                FREQUENTLY ASKED QUESTIONS
              </Text>
            </Box>
            <Divider color="#E2E8F0" />
          </>
          <RouterLink to="/faq">
            <ListItem label="Student FAQ" onClick={onClose} />
          </RouterLink>
          <RouterLink to="/pro-faq">
            <ListItem label="Pro FAQ" onClick={onClose} />
          </RouterLink>
          <>
            <Box px="6" mt="12" mb="4">
              <Text fontSize="xs" color="muted" fontWeight="bold">
                TERMS AND POLICIES
              </Text>
            </Box>
            <Divider color="#E2E8F0" />
          </>
        </Show>
        <DrawerBody p="0">
          {selectedPolicy ? (
            <Box p="8" pt="0">
              {renderPolicy()}
            </Box>
          ) : (
            <>
              <ListItem label="Terms of Service" onClick={handleClick('tos')} />
              <ListItem
                label="Payment Terms of Service"
                onClick={handleClick('paymentTos')}
              />
              <ListItem
                label="Student Refund Policy"
                onClick={handleClick('studentRefund')}
              />
              <ListItem
                label="Student Release and Waiver"
                onClick={handleClick('studentRelease')}
              />
              <ListItem
                label="Additional Terms for Pros"
                onClick={handleClick('additionalProTos')}
              />
              <ListItem
                label="Cancellation and Rescheduling Policy"
                onClick={handleClick('cancellation')}
              />
              <ListItem
                label="Lesson Standards and Requirements"
                onClick={handleClick('lessonRequirements')}
              />
              <ListItem
                label="Privacy Policy"
                onClick={handleClick('privacy')}
              />
              <Link href="mailto:nick@teachme.to">
                <ListItem
                  label="Contact us"
                  // tslint:disable-next-line:no-empty
                  onClick={() => {}}
                />
              </Link>
            </>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
