import { useMemo } from 'react';

import useCurrentLocation from './useCurrentLocation';

import { getDistanceAway, type LatLng } from '../utils/getDistanceAway';

export default function useDistanceAwayFromCurrentLocation(
  latLngs: LatLng[],
): string {
  const { currentLocation } = useCurrentLocation();

  const distanceAway = useMemo<string>(() => {
    const distance = getDistanceAway(
      [currentLocation?.latitude || 0, currentLocation?.longitude || 0],
      latLngs,
    );
    return Intl.NumberFormat().format(distance);
  }, [currentLocation, latLngs]);

  return distanceAway;
}
