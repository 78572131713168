import 'core-js/features/array/at';

import useLessonLength from '../../../../../hooks/useLessonLength';
import { useAppSelector } from '../../../../../state/hooks';
import { selectSelectedListing } from '../../../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../../../utils/capturePostHogEvent';
import PosthogEvent from '../../../../../utils/posthogEvents';
import CheckoutItem, { CheckoutItemAction } from '../../CheckoutItem';

export default function CheckoutLessonLength() {
  const selectedListing = useAppSelector(selectSelectedListing);
  const {
    lessonLength,
    lessonLengthOptions,
    setSelectedLessonLengthForCurrentCategory,
  } = useLessonLength();

  const lessonLengthLabel = lessonLengthOptions?.possibleLengths?.find(
    (length) => length.minutes === lessonLength,
  )?.label;

  const handleSetLessonLength = (length: number) => {
    capturePostHogEvent(PosthogEvent.ClickedEditLessonLength, {
      ...selectedListing,
    });

    setSelectedLessonLengthForCurrentCategory(length);
  };

  const handleIncrement = () => {
    handleSetLessonLength(lessonLength + 30);
  };

  const handleDecrement = () => {
    handleSetLessonLength(lessonLength - 30);
  };

  const minLessonLength = lessonLengthOptions?.possibleLengths?.[0]?.minutes;
  const maxLessonLength = lessonLengthOptions?.possibleLengths?.at(-1)?.minutes;

  return (
    <CheckoutItem
      title="Length"
      subtitle={`${lessonLengthLabel} lessons`}
      width={{ md: 'full', lg: '250px' }}
    >
      <CheckoutItemAction.PlusMinus
        isPlusDisabled={lessonLength === maxLessonLength}
        isMinusDisabled={lessonLength === minLessonLength}
        handleDecrement={handleDecrement}
        handleIncrement={handleIncrement}
      />
    </CheckoutItem>
  );
}
