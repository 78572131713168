import { Box, Button, Heading, Text } from '@chakra-ui/react';

import BookingWidgetBottomSheet from '../BookingWidgetSheet';

type ScheduleLaterSheetProps = {
  isOpen: boolean;
  onClose: () => void;
};
export default function ScheduleLaterSheet({
  isOpen,
  onClose,
}: ScheduleLaterSheetProps): JSX.Element {
  return (
    <BookingWidgetBottomSheet.Root isOpen={isOpen} onClose={onClose}>
      <BookingWidgetBottomSheet.Content>
        <Box px="6" pb="2">
          <Heading variant="h4" textAlign="center" mb="4">
            Schedule later
          </Heading>
          <Box w="full" p="4" mb="4" bg="on-accent-muted" borderRadius="2xl">
            <Text textStyle="small" color="blue.500" textAlign="center">
              Great! You&apos;ll be able to schedule your lessons with your
              coach after booking.
            </Text>
          </Box>
        </Box>
        <BookingWidgetBottomSheet.BottomBar>
          <Button
            variant="primary"
            size="xl"
            w="full"
            rounded="full"
            mt="0"
            mb="0"
            onClick={onClose}
          >
            Sounds good!
          </Button>
        </BookingWidgetBottomSheet.BottomBar>
      </BookingWidgetBottomSheet.Content>
    </BookingWidgetBottomSheet.Root>
  );
}
