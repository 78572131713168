import { Box, Icon } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';
import { HiX } from 'react-icons/hi';

import { BottomBar } from '../../BottomBar';

type BackdropProps = {
  isOpen: boolean;
  onClick: () => void;
};

function Backdrop({ isOpen, onClick }: BackdropProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="backdrop"
        >
          <Box
            position="absolute"
            zIndex="141"
            bg="#00000066"
            w="full"
            top={0}
            bottom={0}
            right={0}
            left={0}
            borderRadius={0}
            pointerEvents="auto"
            onClick={onClick}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function Content({ children }: { children: ReactNode }) {
  return (
    <motion.div layout>
      <Box bg="white" w="full" pt="8" pointerEvents="all" borderTopRadius="2xl">
        <motion.div layout="preserve-aspect">{children}</motion.div>
      </Box>
    </motion.div>
  );
}

function SheetBottomBar({ children }: { children: ReactNode }) {
  return (
    <BottomBar
      borderTopColor="border-default"
      borderTopWidth={1}
      boxShadow=""
      position="sticky"
      w="full"
      bottom="0"
      left="0"
      right="0"
      align="center"
      justify="center"
      mt="0 !important;"
    >
      {children}
    </BottomBar>
  );
}

type BookingWidgetSheetProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
};

function BookingWidgetSheet({
  isOpen,
  onClose,
  children,
}: BookingWidgetSheetProps) {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    }
    setInternalIsOpen(isOpen);
  }, [isOpen]);

  const onCloseSheet = () => {
    setInternalIsOpen(false);
  };

  const onDismiss = () => {
    setIsMounted(false);
    onClose();
  };

  if (!isMounted) return null;

  return (
    <Box
      position="absolute"
      zIndex="140"
      w="full"
      top={0}
      bottom={0}
      right={0}
      left={0}
      overflow="hidden"
    >
      <Backdrop isOpen={internalIsOpen} onClick={onCloseSheet} />
      <Box
        w="full"
        h="full"
        display="flex"
        flexDirection="column"
        zIndex="142"
        position="relative"
      >
        <Box flex={1} pointerEvents="none" />
        <AnimatePresence onExitComplete={onDismiss}>
          {internalIsOpen && (
            <motion.div
              initial={{
                opacity: 0,
                translateY: '100%',
                transformOrigin: '50% 100%',
              }}
              animate={{
                opacity: 1,
                translateY: 0,
                transformOrigin: '50% 100%',
              }}
              transition={{ duration: 0.25, ease: 'easeOut' }}
              key="content"
              exit={{
                opacity: 0,
                transition: { duration: 0.2, ease: 'easeOut' },
                height: 0,
              }}
            >
              <Icon
                as={HiX}
                onClick={onClose}
                pos="absolute"
                right="6"
                top="6"
              />

              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  );
}

const BookingWidgetBottomSheet = {
  Root: BookingWidgetSheet,
  Content,
  BottomBar: SheetBottomBar,
};

export default BookingWidgetBottomSheet;
