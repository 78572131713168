import { addMinutes, format } from 'date-fns';
import { useMemo } from 'react';

import useLessonLength from '../../../../../hooks/useLessonLength';
import { useAppSelector } from '../../../../../state/hooks';
import {
  selectDateTime,
  selectIsRecurring,
} from '../../../../../state/slices/filtersSlice';
import { selectSelectedListing } from '../../../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../../../utils/capturePostHogEvent';
import PosthogEvent from '../../../../../utils/posthogEvents';
import CheckoutItem, { CheckoutItemAction } from '../../CheckoutItem';

type Props = {
  onPress: () => void;
};

export default function CheckoutDateAndTime({ onPress }: Props) {
  const isRecurring = useAppSelector(selectIsRecurring);
  const selectedListing = useAppSelector(selectSelectedListing);
  const { lessonLength } = useLessonLength();
  const dateTime = useAppSelector(selectDateTime);

  const lessonStartAt = useMemo(
    () => (dateTime ? new Date(dateTime) : undefined),
    [dateTime],
  );

  const lessonEndAt = useMemo(
    () => (lessonStartAt ? addMinutes(lessonStartAt, lessonLength) : undefined),
    [lessonStartAt, lessonLength],
  );

  const handleEdit = () => {
    capturePostHogEvent(PosthogEvent.ClickedEditTime, {
      ...selectedListing,
      dateTime,
    });

    onPress();
  };

  const formattedLessonDateAndTime = useMemo(() => {
    if (!lessonStartAt) return 'Schedule with coach after checkout';
    return `${format(lessonStartAt, `eee LLL d 'from' h:mm`)} - ${format(
      lessonEndAt,
      'h:mm a',
    )}`;
  }, [lessonEndAt, lessonStartAt]);

  return (
    <CheckoutItem
      title={`Date and time ${isRecurring ? '(first lesson)' : ''}`}
      subtitle={formattedLessonDateAndTime}
    >
      <CheckoutItemAction.Button
        hasSelected={Boolean(lessonStartAt)}
        onPress={handleEdit}
      />
    </CheckoutItem>
  );
}
