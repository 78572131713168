/* eslint-disable import/prefer-default-export */
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  Text,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';

interface Props extends TextareaProps {
  label?: string;
  info?: string;
  error?: string;
  container?: FormControlProps;
  labelFontSize?: string;
  labelFontWeight?: string;
  showError?: boolean;
}

export const TextArea = forwardRef(
  (
    {
      label,
      error,
      info,
      container,
      labelFontSize,
      labelFontWeight,
      showError = true,
      // eslint-disable-next-line react/jsx-props-no-spreading
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormControl isInvalid={!!error} {...container}>
      {label ? (
        <FormLabel fontSize={labelFontSize} fontWeight={labelFontWeight} mb="0">
          {label}
        </FormLabel>
      ) : null}
      <InputGroup size="lg">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Textarea ref={ref} resize="none" {...rest} />
      </InputGroup>
      {info ? (
        <Text fontSize="md" fontWeight="semibold" color="gray.400" mt="1">
          {info}
        </Text>
      ) : null}
      {error && showError ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  ),
);
