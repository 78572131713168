import { useLocalStorageValue } from '@react-hookz/web';
import { useCallback } from 'react';

import { CreateBookingInput } from '../api';

export enum EVENTS {
  CREATE_CHECKOUT = 'CREATE_CHECKOUT',
}

export default function useInAppMember() {
  const [isInAppMember, setIsInAppMember] = useLocalStorageValue<boolean>(
    'in_app_member',
    false,
  );

  const createInAppCheckout = async (payload: CreateBookingInput) => {
    const iframe = (window as any).ReactNativeWebView;
    iframe.postMessage(
      JSON.stringify({
        event: EVENTS.CREATE_CHECKOUT,
        payload,
      }),
      '*',
    );
  };

  const captureIsInAppMember = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const memberParam = urlParams.get('in_app_member');
    const isMemberParam = memberParam === 'true';
    if (isMemberParam) setIsInAppMember(isMemberParam);
  }, [setIsInAppMember]);

  return {
    isInAppMember,
    createInAppCheckout,
    captureIsInAppMember,
  };
}
