import { IconButton } from '@chakra-ui/react';
import { useCallback } from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import useListingSearchParams from '../../../hooks/useListingSearchParams';
import { useAppSelector } from '../../../state/hooks';
import { selectSelectedListing } from '../../../state/slices/listingsSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import PosthogEvent from '../../../utils/posthogEvents';
import { BookingStep } from '../../../utils/types';

export default function CheckoutBackButton() {
  const navigate = useNavigate();
  const selectedListing = useAppSelector(selectSelectedListing);
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const goBack = useCallback(() => {
    capturePostHogEvent(PosthogEvent.GoBackToListing, {
      ...selectedListing,
    });

    const listingUrl = `/listings/${selectedListing?.skill?.slug}/${selectedListing?.slug}`;

    navigate(
      `${listingUrl}/${
        BookingStep.Confirm
      }?${getListingSearchParamsWithPartialInput()}`,
      {
        replace: true,
      },
    );
  }, [getListingSearchParamsWithPartialInput, navigate, selectedListing]);

  return (
    <IconButton
      isRound
      variant="ghost"
      borderColor="border-default"
      aria-label="Back"
      size="lg"
      onClick={goBack}
      icon={<HiArrowLeft color="#2D3748" size="32px" />}
    />
  );
}
