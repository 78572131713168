import { useSkillsLazyQuery } from "../../../api";

export default function useSkillsBySlug(slug: string) {
    const [findSkill] = useSkillsLazyQuery({
        initialFetchPolicy: 'cache-and-network',
        fetchPolicy: 'cache-and-network',
        variables: {
            where: {
                slug: {
                    equals: slug,
                },
            },
        },
    });

    return {
        findSkill
    }
}
