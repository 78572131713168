import ConfirmListingPayments from './ConfirmListingPayments';

import useIsMobile from '../../../hooks/useIsMobile';
import { Listing } from '../../../utils/types';
import BookingWidgetFooter from '../BookingWidgetFooter';

interface Props {
  validation: { isValid: boolean; errors: Record<string, string> };
  isSubmitting: boolean;
  listing: Pick<Listing, 'id' | 'title'>;
  onCheckoutWithoutPaymentRequest(ev: any): void;
  onCheckoutWithPaymentRequest(): void;
}

export default function ConfirmFooter({
  validation,
  isSubmitting,
  listing,
  onCheckoutWithoutPaymentRequest,
  onCheckoutWithPaymentRequest,
}: Props) {
  const { isMobile } = useIsMobile();

  return (
    <BookingWidgetFooter position={isMobile ? 'relative' : 'sticky'}>
      <ConfirmListingPayments
        validation={validation}
        isSubmitting={isSubmitting}
        listing={listing}
        onCheckoutWithoutPaymentRequest={onCheckoutWithoutPaymentRequest}
        onCheckoutWithPaymentRequest={onCheckoutWithPaymentRequest}
      />
    </BookingWidgetFooter>
  );
}
