import axios from 'axios';

import { MAPBOX_ACCESS_TOKEN, MAPBOX_API_URL } from './constants';
import constructLocationFromFeature from './constructLocationFromFeature';
import type { CurrentLocation, MapBoxResponse } from './types';

export default async function getLocationFromCoords({
  address,
  latitude,
  longitude,
}: {
  address: string;
  latitude: number;
  longitude: number;
}): Promise<CurrentLocation> {
  try {
    if (!latitude && !longitude) throw new Error('No lat/long provided.');

    const response = await axios.get<MapBoxResponse>(
      `${MAPBOX_API_URL}/reverse?longitude=${longitude}&latitude=${latitude}&access_token=${MAPBOX_ACCESS_TOKEN}&limit=1`,
    );

    const feature = response?.data?.features?.[0];
    if (!feature) throw new Error('No feature found');

    return constructLocationFromFeature(feature, address);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('getLocationFromCoords', { latitude, longitude, err });
    return undefined;
  }
}
