import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { IconType } from 'react-icons';
import {
  HiOutlineCurrencyDollar,
  HiOutlineHeart,
  HiOutlineSparkles,
} from 'react-icons/hi';

import useIsMobile from '../../hooks/useIsMobile';
import MembersPriceBadge from '../MembersPriceBadge';

interface Props extends Omit<ModalProps, 'children'> {
  isOpen: boolean;
  onClose: () => void;
  bg?: string;
  proName: string;
  params: Record<string, any>;
}

const AnimatedModalContent = motion(ModalContent);

function Benefit({
  Icon,
  title,
  description,
}: {
  Icon: IconType;
  title: string;
  description: string;
}) {
  const { isMobile } = useIsMobile();

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      px="6"
      py="4"
      borderRadius="16"
      bgColor="#FFF5D4"
    >
      <HStack w="100%">
        <Text
          fontSize="20"
          color="#AA6B21"
          fontWeight="extrabold"
          flexGrow={1}
          flexBasis={0}
        >
          {title}
        </Text>
        <Icon color="#AA6B21" size="32" />
      </HStack>
      <Text color="#2D3748" fontSize={isMobile ? '14' : '16'}>
        {description}
      </Text>
    </VStack>
  );
}

const computeBenefits = (
  isMobile: boolean,
  proName: string,
  memberPrice: number,
) => [
  {
    Icon: HiOutlineCurrencyDollar,
    title: 'Lowest lesson rate',
    description: `All future lessons with ${proName} will cost just $${memberPrice} per lesson!`,
  },
  {
    Icon: HiOutlineSparkles,
    title: isMobile
      ? 'Every coach and skill'
      : 'Applies to every coach and skill',
    description:
      'The members rate applies to all 15+ skills in our growing national coach network!',
  },
  {
    Icon: HiOutlineHeart,
    title: 'Priority support',
    description:
      'Our world class support team is there to help 24/7 with priority support.',
  },
];

function MembersPriceModalContent({
  onClose,
  proName,
  membersPrice,
}: {
  onClose: () => void;
  proName: string;
  membersPrice: number;
}) {
  const { isMobile } = useIsMobile();

  const benefits = useMemo(
    () => computeBenefits(isMobile, proName, membersPrice),
    [isMobile, proName, membersPrice],
  );

  return (
    <VStack
      alignItems="flex-start"
      w="100%"
      gap={0}
      overflow="hidden"
      pt={{
        base: '32px',
        md: '40px',
      }}
    >
      <HStack
        alignItems="center"
        w="full"
        px={{
          base: '24px',
          md: '40px',
        }}
      >
        <VStack
          alignItems="flex-start"
          gap={0}
          flexGrow={1}
          flexBasis={0}
          flexShrink={1}
        >
          <Text
            color="#2D3748"
            fontWeight="extrabold"
            fontSize="medium"
            lineHeight="18px"
          >
            TeachMe.To
          </Text>
          <Text color="#2D3748" fontWeight="extrabold" fontSize="x-large">
            Member Perks
          </Text>
          {!isMobile && (
            <Text color="#2D3748" fontSize="16px">
              Your first TeachMe.To lessons purchase unlocks a few awesome
              perks!
            </Text>
          )}
        </VStack>
        <MembersPriceBadge shadowed size={isMobile ? 'md' : 'lg'} />
      </HStack>
      {isMobile && (
        <Text
          px={{
            base: '24px',
            md: '40px',
          }}
          color="#2D3748"
          fontSize="16px"
        >
          Your first TeachMe.To lessons purchase unlocks a few awesome perks!
        </Text>
      )}
      <VStack
        overflow="auto"
        flexGrow={1}
        px={{
          base: '24px',
          md: '40px',
        }}
      >
        <VStack
          alignItems="flex-start"
          gap={{
            base: '2',
            md: '4',
          }}
          mt={{
            base: '24px',
            md: '32px',
          }}
        >
          {benefits.map((benefit) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Benefit {...benefit} />
          ))}
        </VStack>
        <Flex
          w="full"
          pb={{
            base: '32px',
            md: '40px',
          }}
        >
          <Box
            bgGradient="linear-gradient(225deg, #D4AF37 1.88%, #F5C76E 40.14%, #AA6B21 75.63%)"
            borderRadius="full"
            w="100%"
            mt="6"
          >
            <Button
              w="100%"
              bg="transparent"
              _hover={{ bg: 'transparent' }}
              size="xl"
              rounded="full"
              onClick={onClose}
            >
              Got it
            </Button>
          </Box>
        </Flex>
      </VStack>
    </VStack>
  );
}

export default function MembersPriceModal({
  isOpen,
  onClose,
  bg,
  proName,
  params,
  ...rest
}: Props) {
  const { membersPrice } = params;

  if (!membersPrice) return null;

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: 'full', md: 'auto' }}
      scrollBehavior="inside"
      motionPreset="slideInBottom"
      onOverlayClick={onClose}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <ModalOverlay bg={bg} />
      <AnimatedModalContent
        borderRadius="24px"
        maxH={window.innerHeight}
        maxW={{
          base: '100%',
          md: 615,
        }}
      >
        <MembersPriceModalContent
          onClose={onClose}
          proName={proName}
          membersPrice={membersPrice}
        />
      </AnimatedModalContent>
    </Modal>
  );
}
