import { Box, Button, Center, Spinner, VStack } from '@chakra-ui/react';
import { Select as ChakraReactSelect } from 'chakra-react-select';
import usePlacesAutocomplete from 'use-places-autocomplete';

import useCurrentLocation from '../../../hooks/useCurrentLocation';
import useLocationPermission from '../../../hooks/useLocationPermission';
import { useAppDispatch } from '../../../state/hooks';
import { setMapLocation } from '../../../state/slices/locationsSlice';
import getLocationFromAddress from '../../../utils/getLocationFromAddress';

export default function QuizUserLocation({
  onChangeLocation,
}: {
  onChangeLocation: () => void;
}) {
  const { currentLocation, setCurrentLocation } = useCurrentLocation();

  const postalCode: string =
    // Replace spaces with non-breaking spaces
    (currentLocation?.postalCode || '').replaceAll(' ', '\u00A0') || '';

  const { isGettingGeolocation, tryGetUserGeolocation } =
    useLocationPermission();
  const {
    value,
    setValue: setLocationValue,
    suggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    defaultValue: postalCode,
  });

  const dispatch = useAppDispatch();

  const onLocationInputChange = (e) => {
    setLocationValue?.(e);
  };

  const handleLocationSelect = async (item: any) => {
    try {
      if (!item?.label) return;
      setLocationValue(item.label);

      const locationFromAddress = await getLocationFromAddress(item.label);
      if (!locationFromAddress) return;

      setCurrentLocation(locationFromAddress);
      dispatch(setMapLocation(locationFromAddress));
      onChangeLocation?.();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('LocationAutocomplete.handleLocationSelect', error);
    }
  };

  const geUserLocation = () => {
    tryGetUserGeolocation(false)
      // eslint-disable-next-line no-console
      .catch(console.error);
  };

  return (
    <Box w="full" mb="4">
      {isGettingGeolocation ? (
        <Center minH="82px">
          <Spinner color="muted" />
        </Center>
      ) : (
        <VStack alignItems="stretch" spacing="6" w="full">
          <ChakraReactSelect
            blurInputOnSelect
            isSearchable
            isClearable
            tabIndex={-1}
            autoFocus={false}
            menuPlacement="auto"
            selectedOptionColorScheme="blue"
            chakraStyles={{
              dropdownIndicator: (provided) => ({
                ...provided,
                display: 'none',
              }),
              placeholder: (provided) => ({
                ...provided,
                textAlign: 'left',
              }),
              menuList: (provided) => ({
                ...provided,
                boxShadow: 'none',
              }),
            }}
            onFocus={(e) => e.currentTarget.select()}
            placeholder="Address, zip code or city"
            size="md"
            inputValue={value}
            defaultInputValue={value}
            onInputChange={onLocationInputChange}
            onChange={handleLocationSelect}
            options={
              suggestions?.data?.map((option) => ({
                label: option?.description,
                value: option?.place_id,
              })) as any[]
            }
          />

          <Button
            color="brand.600"
            fontSize="14px"
            fontWeight="800"
            isDisabled={isGettingGeolocation}
            minW={202}
            onClick={geUserLocation}
            size="lg"
            variant="link"
          >
            Share my location instead
          </Button>
        </VStack>
      )}
    </Box>
  );
}
