import { useLocalStorageValue } from '@react-hookz/web';
import { useCallback, useEffect, useMemo, useState } from 'react';

type ReferralReward = {
  referralCode?: string;
  redeemed?: boolean;
};

export default function useStudentReferral() {
  const [shouldShowReferralRewardModal, setShouldShowReferralRewardModal] =
    useState(false);
  const [referralReward, setReferralReward] =
    useLocalStorageValue<ReferralReward>('referral', {
      referralCode: undefined,
      redeemed: false,
    });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get('referral_code');
    const showModal =
      referralCode && referralReward.referralCode !== referralCode;
    setShouldShowReferralRewardModal(showModal);
    if (showModal) {
      setReferralReward({
        redeemed: false,
        referralCode,
      });
    }
  }, [referralReward, setReferralReward, setShouldShowReferralRewardModal]);

  const redeemReferral = useCallback(() => {
    setReferralReward({ ...referralReward, redeemed: true });
  }, [referralReward, setReferralReward]);

  const resetReferralCode = useCallback(() => {
    setShouldShowReferralRewardModal(false);
    setReferralReward({
      referralCode: undefined,
      redeemed: undefined,
    });
  }, [setReferralReward]);

  const hasFreeLesson = useMemo(
    () => referralReward.referralCode?.length > 0 && referralReward.redeemed,
    [referralReward],
  );

  return {
    referralCode: referralReward.referralCode,
    redeemed: referralReward.redeemed,
    shouldShowReferralRewardModal,
    hasFreeLesson,
    resetReferralCode,
    redeemReferral,
  };
}
