import {
  Box,
  Button,
  Center,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { add, addDays, format, set } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FlatList } from 'react-native-web';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ConfirmationSheet from './ConfirmationSheet';

import {
  DateAvailability,
  LessonStatus,
  useGetAvailabilityByListingIdLazyQuery,
  useScheduleLessonMutation,
} from '../../../api';
import useDispatchDateTime from '../../../hooks/useDispatchDateTime';
import useLessonLength from '../../../hooks/useLessonLength';
import useListingSearchParams from '../../../hooks/useListingSearchParams';
import { useAppSelector } from '../../../state/hooks';
import {
  selectDateTime,
  selectIsRecurring,
  selectParticipants,
  selectPkg,
  setDateTime,
} from '../../../state/slices/filtersSlice';
import capturePostHogEvent from '../../../utils/capturePostHogEvent';
import PosthogEvent from '../../../utils/posthogEvents';
import { BookingStep, type Listing } from '../../../utils/types';
import ExposedTime from '../../ExposedTime';
import { Show } from '../../Show';
import BookingWidgetBody from '../BookingWidgetBody';
import BookingWidgetFooter from '../BookingWidgetFooter';
import BookingWidgetHeader from '../BookingWidgetHeader';

interface Props {
  variant: 'normal' | 'rebook' | 'schedule';
  proName: string;
  listing: {
    id: Listing['id'];
    slug: Listing['slug'];
    skill?: Pick<Listing['skill'], 'slug'>;
  };
  lessonId?: string;
  nextUrl: string;
  listingUrl: string;
  onOpen: () => void;
}

export default function ExposedTimes({
  variant = 'normal',
  proName,
  listing,
  lessonId,
  nextUrl,
  listingUrl,
  onOpen,
}: Props) {
  const { isOpen, onOpen: onSheetOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const dateTime = useAppSelector(selectDateTime);
  const isRecurring = useAppSelector(selectIsRecurring);
  const pkg = useAppSelector(selectPkg);
  const participants = useAppSelector(selectParticipants);
  const { dispatchDateTime } = useDispatchDateTime();
  const { getListingSearchParamsWithPartialInput } = useListingSearchParams();

  const [getAvailabilityByListingId, { data, error }] =
    useGetAvailabilityByListingIdLazyQuery();
  const [scheduleLesson] = useScheduleLessonMutation();

  const [dateAvailabilitySlots, setDateAvailabilitySlots] = useState<
    DateAvailability[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { lessonLength } = useLessonLength();

  useEffect(() => {
    capturePostHogEvent('viewExposedTimes');
  }, []);

  useEffect(() => {
    if (!listing?.id) return;
    getAvailabilityByListingId({
      variables: {
        listingId: listing?.id,
        date: new Date().toISOString(),
        lessonLength,
      },
    });
  }, [listing?.id, getAvailabilityByListingId, lessonLength]);

  useEffect(() => {
    if (data?.getAvailabilityByListingId?.dateAvailabilitySlots) {
      setDateAvailabilitySlots((prevSlots) => [
        ...prevSlots,
        ...data.getAvailabilityByListingId.dateAvailabilitySlots,
      ]);
      setIsLoading(false);
    }
  }, [data?.getAvailabilityByListingId?.dateAvailabilitySlots]);

  const handleSelect = useCallback(
    async (nextDate?: Date) => {
      dispatchDateTime(nextDate);
      onSheetOpen();
    },
    [dispatchDateTime, onSheetOpen],
  );

  const handleConfirm = useCallback(async () => {
    const listingSearchParams = getListingSearchParamsWithPartialInput({
      dateTime,
    });

    if (variant === 'normal' || variant === 'rebook') {
      capturePostHogEvent(PosthogEvent.ChooseExposedTime, {
        date: dateTime,
      });

      const from = searchParams.get('from');
      if (from) {
        navigate(
          `/listings/${listing?.skill?.slug}/${listing?.slug}/${from}?expanded=summary&${listingSearchParams}`,
          { replace: true },
        );
      } else {
        const nextUrlWithSearchParams = `${nextUrl}?${listingSearchParams}`;
        navigate(nextUrlWithSearchParams);
      }
      return;
    }

    if (variant === 'schedule') {
      try {
        setIsSubmitting(true);
        const endTime = !dateTime
          ? undefined
          : add(toDate(dateTime), {
              minutes: lessonLength,
            });
        await scheduleLesson({
          variables: {
            where: {
              id: lessonId,
            },
            data: {
              status: LessonStatus.Scheduled,
              startAt: !dateTime
                ? undefined
                : format(new Date(dateTime), 'yyyy-MM-dd HH:mm:ss'),
              endAt: !endTime
                ? undefined
                : format(endTime, 'yyyy-MM-dd HH:mm:ss'),
              lessonLength,
            },
          },
        });
        setIsSubmitting(false);
        navigate(`${nextUrl}?${listingSearchParams}`);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        setIsSubmitting(false);
      }
    }
  }, [
    getListingSearchParamsWithPartialInput,
    lessonId,
    listing?.skill?.slug,
    listing?.slug,
    navigate,
    nextUrl,
    searchParams,
    scheduleLesson,
    variant,
    lessonLength,
    dateTime,
  ]);

  const handleLoadMore = useCallback(async () => {
    try {
      const lastDate =
        dateAvailabilitySlots[dateAvailabilitySlots.length - 1]?.date;

      await getAvailabilityByListingId({
        variables: {
          listingId: listing?.id,
          date: addDays(new Date(lastDate), 1).toISOString(),
          lessonLength,
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, [
    dateAvailabilitySlots,
    getAvailabilityByListingId,
    listing?.id,
    lessonLength,
  ]);

  const goToBrowse = useCallback(() => {
    capturePostHogEvent('clickOtherPros');
    navigate('/choose-a-pro');
  }, [navigate]);

  const renderItem = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ item }: { item: DateAvailability }) => {
      const { date, availability } = item;

      if (!date) {
        return null;
      }
      const heading = format(toDate(date), 'EEEE, MMMM d');
      if (
        !availability?.length ||
        availability?.every((slot) => !slot.available)
      ) {
        return (
          <Box
            bg="bg-muted"
            py="6"
            px="6"
            borderRadius="16px"
            my="2"
            mx={{ base: 0, md: 2 }}
          >
            <Stack direction="row" align="center" justify="space-between">
              <Text textStyle="smallBold" color="muted">
                {heading}
              </Text>
              <Text textStyle="overline" color="muted">
                Fully booked
              </Text>
            </Stack>
          </Box>
        );
      }
      return (
        <Stack
          borderColor="#CBD5E0"
          borderRadius="16px"
          borderWidth={1}
          mx={{ base: 0, md: '2' }}
          my="2"
        >
          <Stack
            align="center"
            borderBottomColor="gray.300"
            borderBottomWidth={1}
            direction="row"
            justify="space-between"
            py="4"
            px="6"
          >
            <Text textStyle="smallBold">{heading}</Text>
          </Stack>

          {availability?.map((slot, index, arr) => {
            const [hours, minutes] = slot.time.split(':');
            const startAt = set(toDate(date), {
              hours: parseInt(hours, 10),
              minutes: parseInt(minutes, 10),
            });

            return (
              <ExposedTime
                hasDivider={index < arr.length - 1}
                isDisabled={!slot?.available}
                isLoading={isSubmitting}
                key={+index}
                onClick={() => handleSelect(startAt)}
                time={format(startAt, 'h:mm a')}
              />
            );
          })}
        </Stack>
      );
    },
    [handleSelect, isSubmitting],
  );

  const numOfAvailableSlots: number = useMemo(
    () =>
      dateAvailabilitySlots.reduce((acc, dateAvailabilitySlot) => {
        const numOfAvailableSlotsOnDate =
          dateAvailabilitySlot.availability.filter((a) => a.available).length;
        return acc + numOfAvailableSlotsOnDate;
      }, 0),
    [dateAvailabilitySlots],
  );

  const renderFooterComponent = useCallback(
    () => (
      <Show condition={numOfAvailableSlots > 0}>
        <Center py="4">
          <Spinner />
        </Center>
      </Show>
    ),
    [numOfAvailableSlots],
  );

  const showCalendar = () => {
    capturePostHogEvent('clickCalendarIcon');
    navigate(`${listingUrl}/${BookingStep.Datepicker}`);
  };

  const onClickScheduleInApp = () => {
    capturePostHogEvent('clickScheduleInApp');
    dispatch(setDateTime(''));
    const listingSearchParams = getListingSearchParamsWithPartialInput({
      pkg,
      isRecurring,
      participants,
      lessonLength,
      dateTime: '',
    });
    const nextUrlWithSearchParams = `${listingUrl}/${BookingStep.Confirm}?${listingSearchParams}`;
    navigate(nextUrlWithSearchParams, { replace: true });
  };

  const onConfirmConfirmationSheet = () => {
    onClose();
    setTimeout(() => {
      handleConfirm();
    }, 200);
  };

  const renderHeaderComponent = useCallback(
    () => (
      <Show condition={isRecurring}>
        <Box
          borderRadius="xl"
          backgroundColor="purple.100"
          py="4"
          px="8"
          mx="2"
          mb="4"
        >
          <Text textStyle="smallBold" textColor="purple.600" textAlign="center">
            Lessons will be repeated weekly on the chosen day and time.
          </Text>
        </Box>
      </Show>
    ),
    [isRecurring],
  );

  return (
    <>
      <BookingWidgetHeader heading="Schedule your first lesson" />

      <BookingWidgetBody maxH={window.innerHeight}>
        <Show condition={isLoading}>
          <Stack h="90dvh" align="center" justify="center">
            {error ? (
              <>
                <Text textAlign="center">Error loading times.</Text>
                <Text textAlign="center">
                  Please try again later or contact customer support for help.
                </Text>
              </>
            ) : (
              <>
                <Spinner size="lg" />
                <Text textAlign="center">Loading times...</Text>
              </>
            )}
          </Stack>
        </Show>

        {!isLoading && numOfAvailableSlots > 0 && (
          <>
            <Box mb="2" />
            <FlatList
              data={dateAvailabilitySlots}
              keyExtractor={(slot: DateAvailability, index: number) =>
                slot.date + index
              }
              ListFooterComponent={renderFooterComponent}
              ListHeaderComponent={renderHeaderComponent}
              onEndReached={handleLoadMore}
              onEndReachedThreshold={0.8}
              renderItem={renderItem}
              showsVerticalScrollIndicator={false}
            />
          </>
        )}

        <Show condition={!isLoading && numOfAvailableSlots === 0}>
          <Stack h="60dvh" align="center" justify="center">
            <Stack align="center" justify="center" pb="8" w="full" spacing="1">
              <Text textStyle="body">Whoops! Sorry about that.</Text>
              <Text textStyle="body">{proName} is fully booked.</Text>
            </Stack>

            <Button
              color="defaultText"
              onClick={goToBrowse}
              rounded="full"
              variant="outline"
            >
              Check out our other great pros
            </Button>

            <Text textStyle="overline" color="muted">
              Or
            </Text>

            <Button
              color="defaultText"
              onClick={onOpen}
              rounded="full"
              variant="outline"
            >
              Request a time with {proName}
            </Button>
          </Stack>
        </Show>
      </BookingWidgetBody>

      <BookingWidgetFooter
        bottomCtaLabel=""
        bottomCtaVariant=""
        isBottomCtaClickable
        onClick={() => null}
      >
        <Button
          py="4"
          w="full"
          size="xl"
          rounded="full"
          variant="secondary"
          onClick={showCalendar}
        >
          View calendar
        </Button>
        <Button
          py="4"
          w="full"
          size="xl"
          rounded="full"
          variant="primary"
          onClick={onClickScheduleInApp}
        >
          Schedule in app
        </Button>
      </BookingWidgetFooter>
      <ConfirmationSheet
        listing={listing}
        isOpen={isOpen}
        onConfirm={onConfirmConfirmationSheet}
        onClose={onClose}
        isRecurring={isRecurring}
      />
    </>
  );
}
