import { Stack } from '@chakra-ui/react';

export default function AdditionalProTerms() {
  return (
    <Stack spacing={4}>
      <p>
        IF YOU RESIDE IN, OR THE ORGANIZATION YOU ARE ACTING FOR IS ESTABLISHED
        IN, THE UNITED STATES, PLEASE NOTE: THE ARBITRATION CLAUSE AND CLASS
        ACTION WAIVER CONTAINED IN THE TEACH ME TO TERMS OF SERVICE APPLY TO ANY
        DISPUTE RELATING TO THESE ADDITIONAL TERMS FOR PROS.
      </p>
      <p>
        You agree to these Additional Terms for Pros (&quot;Pro Terms&quot;)
        when you submit a Listing for an Lesson on the Teach Me To Platform.
      </p>
      <p>
        These Pro Terms supplement the Teach Me To Terms of Service
        (&quot;Terms&quot;) and the Teach Me To Payments Terms of Service
        (&quot;Payments Terms&quot;), and, except as explicitly stated herein,
        the Terms and Payments Terms apply in full to all Pros, Lesson Listings,
        and Lessons. All capitalized terms not defined here have the meaning
        given to them in the Terms. Nothing in these Pro Terms shall be
        construed to amend or contradict the Terms unless explicitly stated. If
        there is a conflict between the Terms and the Pro Terms, these Pro Terms
        control to the extent of the conflict.
      </p>
      <p>
        1. Listing a Lesson. To list a Lesson, you must create a Listing and
        submit the Lesson to Teach Me To. Lessons must at all times meet the
        standards and requirements for Lessons. When listing an Lesson you must,
        where applicable, fully educate and inform Students about (i) any risks
        inherent or incidental to the Lesson, (ii) any requirements for
        participation, such as the minimum age, related skills, or level of
        fitness, and (iii) anything else they may need to know to safely
        participate in the Lesson (including dress codes, equipment, special
        certifications or licenses, etc.). Teach Me To reserves the right to
        decide, in its sole discretion, whether a submitted Lesson will be
        published on the Teach Me To Platform.&nbsp;
      </p>
      <p>
        2. Teaching Teach Me To Students. Once your Lesson is published on the
        Teach Me To Platform, you will have the ability to add dates and times
        when you offer your Lesson through the Teach Me To Platform (an
        &quot;Instance&quot;). By making an Instance of your Lesson available on
        the Teach Me To Platform, you agree that only people who book through
        Teach Me To can attend that Instance of the Lesson. You further agree
        that you will not allow people to attend any Instance of your Lesson
        available on the Teach Me To Platform unless that person booked through
        Teach Me To (or was added as an additional Student for a spot booked
        through the Teach Me To Platform).
      </p>
      <p>
        3. Equipment. You are responsible for providing all equipment, including
        supplies, vehicles, venues and other materials (&quot;Equipment&quot;)
        necessary to host your Lesson unless explicitly specified in your
        listing. You are solely responsible for ensuring that the Equipment used
        in your Lesson is in good working order and conforms to all laws
        pertaining to safety, equipment, inspection, and operational capability.
        Except as otherwise required by law, you assume all risk of damage or
        loss to your Equipment.
      </p>
      <p>
        4. Additional Pro Responsibilities. You are responsible for (i)
        understanding and complying with all laws, rules and regulations that
        may apply to your Lesson, (ii) obtaining any required licenses, permits,
        or registrations prior to providing your Lesson; and (iii) ensuring that
        your Listing and/or Teaching of an Lesson will not breach any agreement
        you may have with any third party. You can find additional information
        about some of the legal obligations that may apply to you on our
        Responsible Teaching pages.
      </p>
      <p>
        5. Termination. As a Pro, you may terminate this agreement at any time
        by sending us an email or by removing all of your Lessons from the Teach
        Me To Platform. Teach Me To may terminate this agreement immediately and
        without notice and stop providing access to the Teach Me To Platform if
        you materially breach this agreement and/or our other Terms or Policies,
        you violate applicable laws, or we reasonably believe termination is
        advisable to protect Teach Me To, its Members or third parties. If your
        Lesson Listing has been inactive for 120 or more days, we may remove it
        from Teach Me To.
      </p>
      <p>
        6. Teaching as a Team or Organization. You must provide your Lesson in
        person and may not allow any third party to provide the Lesson on your
        behalf, unless authorized by Teach Me To. Any member of a team,
        business, or organization that interacts with Students must be added to
        a Lesson via the method designated by the Teach Me To Platform. See
        Section 6.5 of the Terms for information about Teaching as part of a
        team, business or organization as well as the Listing Management Terms
        of Service, which are incorporated herein.
      </p>
      <p>
        7. Insurance. We may require that you obtain your own insurance in order
        to publish a Lesson on the Teach Me To Platform. In such cases, you
        agree to obtain and maintain insurance for you, the members of your team
        or organization, and/or your Lesson with the coverage and in the amounts
        determined by us in our sole discretion for any and all activities that
        take place on your Lesson. You agree to cooperate with Teach Me To to
        verify such insurance coverage. In the event that Teach Me To has
        obtained its own liability insurance that covers your Lessons, your
        insurance will be the primary source of coverage and Teach Me To&apos;s
        insurance will operate as excess or secondary insurance for any amounts
        exceeding your coverage. Our procurement of such secondary insurance
        coverage does not relieve you of your obligation to obtain insurance in
        amounts required by us.&nbsp;
      </p>
      <p>
        8. Severability. If any provision or part of a provision in these terms
        is held to be invalid, void, or unenforceable, such provision (or the
        part of it that is making it invalid, void or unenforceable) will be
        struck and not affect the validity of and enforceability of the
        remaining provisions.
      </p>
      <p>
        9. Modifications to these Pro Terms. Teach Me To reserves the right to
        modify these Pro Terms at any time in accordance with our Terms.
      </p>
      <p>
        10. Survival. If you or we terminate these Pro Terms, the clauses of
        these Pro Terms that reasonably should survive such termination will
        remain in effect. When these Pro Terms are terminated, you are not
        entitled to a restoration of your account (if your account is cancelled)
        or of the Member Content.{' '}
      </p>
    </Stack>
  );
}
