import { Center, CenterProps, Text } from '@chakra-ui/react';

import { DayStyleConfig } from '../type';

type NormalProps = {
  day?: number | string;
  dayStyleConfig: DayStyleConfig;
} & CenterProps;

export default function Normal({ day, dayStyleConfig, ...props }: NormalProps) {
  const { size, activeBgColor, activeColor, fontSize } = dayStyleConfig;

  return (
    <Center
      {...props}
      m="auto"
      h={size}
      w={size}
      cursor="pointer"
      pos="relative"
      _hover={{
        color: activeColor,
        _before: {
          bgColor: activeBgColor,
        },
      }}
      _before={{
        content: '""',
        pos: 'absolute',
        zIndex: '0',
        borderRadius: 'full',
        h: 'full',
        w: 'full',
        transition: 'all 300ms ease',
      }}
    >
      <Text pos="relative" fontSize={fontSize} color="inherit" zIndex="1">
        {day}
      </Text>
    </Center>
  );
}
